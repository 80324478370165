export const totalInvoiceValueWithTax = (total, taxPercentage) => {
  return (Number(total) + Number(taxAmount(taxPercentage, total))).toFixed(2);
};

export const taxAmount = (taxPercentage, total) => {
  return Number(
    (Number(total) * Number(taxPercentage).toFixed(2) || 0) / 100
  ).toFixed(2);
};

export const filterAgencyItems = data => {
  return data.filter(item => item.feeType === "agency_service_fee");
};

export const filterWithoutAgencyItems = data => {
  return data.filter(item => ["artwork", "usage"].includes(item.feeType));
};

export const filterExtraAgencyItems = data => {
  return data.filter(
    item => item.feeType === "agency_service_fee" && item.kind === "extra"
  );
};

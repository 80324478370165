import React, { useState, useEffect } from "react";
import { Search } from "@bigbinary/neeto-icons";
import { Typography, Input, Avatar } from "@bigbinary/neetoui";
import { groupByInitial } from "common/helper";
import { Link } from "react-router-dom";
import classNames from "classnames";
import useDebounce from "common/debounce";

const Directory = ({
  accountList,
  accountDetail,
  loadAccountListResponse,
  totalRecords,
}) => {
  const [searchParams, setSearchParams] = useState();
  const debouncedSearchTerm = useDebounce(searchParams, 500);

  useEffect(() => {
    if (debouncedSearchTerm != undefined) {
      loadAccountListResponse(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  return (
    <aside className="flex-shrink-0 hidden border-r neeto-ui-border-gray-200 xl:order-first xl:flex xl:flex-col w-80">
      <div className="px-6 py-4 space-y-3">
        <div className="flex flex-col space-y-1">
          <Typography style="h4" weight="semibold" lineHeight="none">
            Directory
          </Typography>
          <Typography style="body2" className="neeto-ui-text-gray-600">
            Search directory of {totalRecords} accounts
          </Typography>
        </div>
        <form
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <Input
            placeholder="Search name"
            prefix={<Search size={16} />}
            value={searchParams}
            onChange={e => setSearchParams(e.target.value)}
          />
        </form>
      </div>

      <nav className="flex-1 min-h-0 pb-4 space-y-2" aria-label="Directory">
        {Object.entries(groupByInitial(accountList, "name")).map(
          (accountGrp, index) => {
            return (
              <div className="px-3" key={index}>
                <ul className="relative z-0 space-y-2">
                  {accountGrp[1].map((account, index) => {
                    return (
                      <li key={index}>
                        <Link
                          className="no-underline focus:outline-none"
                          to={`/super_admin/accounts/${account.id}`}
                        >
                          <div
                            className={classNames(
                              "relative flex items-center p-3 space-x-3 rounded-md hover:neeto-ui-bg-gray-200",
                              {
                                "neeto-ui-bg-gray-200":
                                  account.id === accountDetail.id,
                              }
                            )}
                          >
                            <div className="flex-shrink-0">
                              <Avatar
                                size="large"
                                user={{ name: account.name }}
                              />
                            </div>
                            <div className="flex flex-col flex-shrink-1">
                              <Typography
                                className="block w-full break-words neeto-ui-text-gray-800"
                                component="span"
                                lineHeight="tight"
                                style="h5"
                                weight="medium"
                              >
                                {account.name}
                              </Typography>
                            </div>
                          </div>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          }
        )}
      </nav>
    </aside>
  );
};

export default Directory;

import axios from "axios";

export const getEstimatePdf = token => {
  return axios.get(`/api/v1/email_templates/pdfs/estimates/1?token=${token}`, {
    responseType: "blob",
  });
};

export const getOveragePdf = token => {
  return axios.get(`/api/v1/email_templates/pdfs/overages/1?token=${token}`, {
    responseType: "blob",
  });
};

export const getBookingFormPdf = (token, artistId) => {
  return axios.get(
    `/api/v1/email_templates/pdfs/booking_forms/1?token=${token}&artist_id=${artistId}`, {
      responseType: "blob",
    }
  );
};

export const getLicensePdf = token => {
  return axios.get(`/api/v1/email_templates/pdfs/licenses/1?token=${token}`, {
    responseType: "blob",
  });
};

export const getRciPdf = token => {
  return axios.get(`/api/v1/email_templates/pdfs/rcis/1?token=${token}`, {
    responseType: "blob",
  });
};

export const getInvoicePdf = token => {
  return axios.get(`/api/v1/email_templates/pdfs/invoices/1?token=${token}`, {
    responseType: "blob",
  });
};

import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  Spinner,
  Typography,
  Input,
  Select,
} from "@bigbinary/neetoui";
import { toast } from "react-toastify";
import { showToastrError } from "common";
import { createEstimateLineItem } from "apis/settings/estimate_line_items";
import { getXeroAccountCodes } from "apis/settings/xero_account_codes";
import { getLineItemCategories } from "apis/settings/line_item_categories";
import {
  dropDownListGenerator,
  dropDownListGeneratorForXeroCodes,
} from "common/helper";

const AddLineItem = ({
  loadEstimateLineItemData,
  addModalState,
  setAddModalState,
  setLineItemListLoad,
}) => {
  const inputRef = useRef(null);
  const [name, setName] = useState("");
  const [xeroAccountCode, setXeroAccountCode] = useState("");
  const [lineItemCategoryId, setLineItemCategoryId] = useState("");
  const [lineItemCategoryList, setLineItemCategoryList] = useState([]);
  const [lineItemCategoryListLoad, setLineItemCategoryListLoad] = useState(
    true
  );
  const [xeroAccountCodes, setXeroAccountCodes] = useState([]);
  const [xeroAccountCodesLoad, setXeroAccountCodesLoad] = useState(true);

  useEffect(() => {
    if (addModalState) {
      getXeroAccountCodeList();
      loadLineItemCategoryList();
    }
  }, [addModalState]);

  const resetAllStates = () => {
    setName("");
    setLineItemCategoryId("");
    setXeroAccountCode("");
  };

  const getXeroAccountCodeList = async () => {
    try {
      setXeroAccountCodesLoad(true);
      const { data } = await getXeroAccountCodes();
      setXeroAccountCodes(data.xeroAccountCodes || []);
      setXeroAccountCodesLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const createEstimateLineItemEntry = async () => {
    try {
      setLineItemListLoad(true);
      await createEstimateLineItem({
        estimate_line_item: {
          name: name,
          lineItemCategoryId: lineItemCategoryId,
          xeroAccountCode: xeroAccountCode,
        },
      });
      resetAllStates();
      setAddModalState(false);
      loadEstimateLineItemData();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadLineItemCategoryList = async () => {
    try {
      const lineItemCategoryResponse = await getLineItemCategories();
      setLineItemCategoryList(
        dropDownListGenerator(
          lineItemCategoryResponse.data.lineItemCategories || []
        )
      );
      setLineItemCategoryListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  return (
    <>
      <Modal
        isOpen={addModalState}
        onClose={() => setAddModalState(false)}
        initialFocusRef={inputRef}
      >
        <Modal.Header>
          <Typography style="h2" weight="semibold">
            Add Line Item
          </Typography>
        </Modal.Header>

        <Modal.Body>
          {!lineItemCategoryListLoad ? (
            <div className="flex flex-col space-y-6">
              <Select
                innerRef={inputRef}
                label="Category Name"
                name="lineItemCategoryId"
                id="lineItemCategoryId"
                options={lineItemCategoryList}
                value={lineItemCategoryList.find(
                  category => category.value === lineItemCategoryId
                )}
                required={true}
                onChange={opt => {
                  setLineItemCategoryId(opt.value);
                }}
              />

              <Input
                label="Name"
                required={true}
                name="name"
                value={name}
                onChange={e => setName(e.target.value)}
              />

              <Select
                label="Xero Account Code"
                name="xeroAccountCode"
                isLoading={xeroAccountCodesLoad}
                isDisabled={xeroAccountCodesLoad}
                id="xeroAccountCode"
                options={dropDownListGeneratorForXeroCodes(xeroAccountCodes)}
                value={dropDownListGeneratorForXeroCodes(xeroAccountCodes).find(
                  accountCode => accountCode.code === xeroAccountCode
                )}
                onChange={opt => {
                  setXeroAccountCode(opt.code);
                }}
              />
            </div>
          ) : (
            <Spinner />
          )}
        </Modal.Body>

        <Modal.Footer className="space-x-2">
          <Button
            label="Save Changes"
            onClick={() => createEstimateLineItemEntry()}
          />
          <Button
            style="text"
            label="Cancel"
            onClick={() => setAddModalState(false)}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddLineItem;

import React from "react";
import classnames from "classnames";
import { Button } from "@bigbinary/neetoui";

export default function EmptyState({
  image,
  title = "",
  description = "",
  primaryButtonProps = null,
  secondaryButtonProps = null,
  className = "",
}) {
  return (
    <div
      className={classnames([
        "m-auto flex h-full max-w-md flex-col items-center justify-center",
        className,
      ])}
    >
      {image && (
        <div className="flex items-center justify-center mx-auto mb-4">
          <img src={image} width="auto" height="300" />
        </div>
      )}
      <h3
        className="mb-1 text-xl font-semibold text-center"
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      ></h3>
      <p
        className="mb-6 text-sm font-normal leading-relaxed text-center text-gray-600"
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      ></p>
      <div className="flex flex-row items-center justify-center space-x-2">
        {primaryButtonProps && <Button {...primaryButtonProps} />}
        {secondaryButtonProps && (
          <Button style="secondary" {...secondaryButtonProps} />
        )}
      </div>
    </div>
  );
}

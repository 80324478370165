import React, { useState, useEffect } from "react";
import { Input, DatePicker } from "@bigbinary/neetoui";
import * as dayjs from "dayjs";
import CountryPhone from "components/Common/CountryPhone";
import { countries } from "countries-list";

const AddTeamMember = ({ artistDetail, formik }) => {
  const [selectedCountry, setSelectedCountry] = useState();

  useEffect(() => {
    if (artistDetail) {
      let countryObj = Object.entries(countries).find(item => {
        return (
          item[0].toLowerCase() === artistDetail.country?.toLowerCase() ||
          item[1].name.toLowerCase() === artistDetail.country?.toLowerCase()
        );
      });

      countryObj && setSelectedCountry(countryObj[1]);
    }
  }, [artistDetail]);

  return (
    <div className="flex flex-col">
      <form className="flex flex-col space-y-6">
        <Input
          label="First Name"
          name="firstName"
          onChange={formik.handleChange}
          value={formik.values.firstName}
          required={true}
          error={
            Boolean(formik.touched.firstName && formik.errors.firstName) &&
            formik.errors.firstName
          }
          {...formik.getFieldProps("firstName")}
        />

        <Input
          label="Last Name"
          name="lastName"
          onChange={formik.handleChange}
          value={formik.values.lastName}
          required={true}
          error={
            Boolean(formik.touched.lastName && formik.errors.lastName) &&
            formik.errors.lastName
          }
          {...formik.getFieldProps("lastName")}
        />

        <Input
          label="Pronouns"
          name="pronouns"
          value={formik.values.pronouns}
          onChange={formik.handleChange}
          error={
            Boolean(formik.touched.pronouns && formik.errors.pronouns) &&
            formik.errors.pronouns
          }
          {...formik.getFieldProps("pronouns")}
        />

        <Input
          label="Email"
          name="email"
          onChange={formik.handleChange}
          value={formik.values.email}
          required={true}
          error={
            Boolean(formik.touched.email && formik.errors.email) &&
            formik.errors.email
          }
          {...formik.getFieldProps("email")}
        />

        <Input
          label="Designation"
          name="tite"
          onChange={formik.handleChange}
          value={formik.values.title}
          error={
            Boolean(formik.touched.title && formik.errors.title) &&
            formik.errors.title
          }
          {...formik.getFieldProps("title")}
        />

        <DatePicker
          label="DOB"
          name="dateOfBirth"
          dateFormat="DD/MM/YYYY"
          value={
            formik.values.dateOfBirth ? dayjs(formik.values.dateOfBirth) : null
          }
          disabledDate={current =>
            current && current.valueOf() > dayjs().toDate()
          }
          onChange={date => {
            if (date) {
              formik.setFieldValue("dateOfBirth", dayjs(date).toDate());
            } else {
              formik.setFieldValue("dateOfBirth", null);
            }
          }}
          getPopupContainer={triggerNode => triggerNode.parentNode}
        />

        <CountryPhone
          formik={formik}
          required={false}
          selectedCountry={selectedCountry}
        />
      </form>
    </div>
  );
};

export default AddTeamMember;

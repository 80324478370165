import React, { useState, useEffect } from "react";
import { MenuHorizontal } from "@bigbinary/neeto-icons";
import * as R from "ramda";
import { Button, Typography, Dropdown, Table } from "@bigbinary/neetoui";
import * as dayjs from "dayjs";
import EmptyState from "components/Common/EmptyState";
import { getRandomNotFoundImage } from "common/helper";

const RenderNotes = ({ notes }) => {
  const [showNote, setShowNote] = useState(false);

  return (
    <div>
      {notes?.length > 0 ? (
        <div className="flex flex-col space-y-1">
          {showNote ? (
            <Typography style="body2" className="text-gray-800 w-96" dangerouslySetInnerHTML={{
              __html: notes?.split("\n").join("<br>")}}>
            </Typography>
          ) : (
            <Typography style="body2" className="text-gray-800" dangerouslySetInnerHTML={{
              __html: notes?.substring(0, 200)?.split("\n")?.join("<br>")}}>
            </Typography>
          )}
          {notes?.length > 200 && (
            <Button
              style="link"
              onClick={() => setShowNote(!showNote)}
              label={showNote ? "Less" : "More"}
            />
          )}
        </div>
      ) : (
        "No notes"
      )}
    </div>
  );
};

const List = ({
  setAddModalState,
  setDeleteAlertState,
  catchupNoteList,
  loadCatchupNoteDetailResponse,
  setCatchupNoteId,
}) => {
  const { Menu, MenuItem } = Dropdown;
  const [emptyImage, setEmptyImage] = useState();

  useEffect(() => {
    setEmptyImage(getRandomNotFoundImage());
  }, [])

  const COLUMN_DATA = [
    {
      title: "Note",
      dataIndex: "note",
      key: "note",
      width: "50%",
      render: note => <RenderNotes notes={note} />,
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      width: "20%",
      render: createdBy => <>{createdBy}</>,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "20%",
      render: createdAt => dayjs(createdAt).format("DD/MM/YYYY"),
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      fixed: "right",
      width: "10%",
      render: (_, rowData) => (
        <Dropdown
          autoWidth
          closeOnSelect
          buttonStyle="text"
          strategy="fixed"
          appendTo={() => document.body}
          icon={MenuHorizontal}
        >
          <Menu>
            <MenuItem.Button
              onClick={() => {
                loadCatchupNoteDetailResponse(rowData.id);
                setAddModalState(true);
              }}
            >
              Edit
            </MenuItem.Button>
            <MenuItem.Button
              style="danger"
              onClick={() => {
                setCatchupNoteId(rowData.id);
                setDeleteAlertState(true);
              }}
            >
              Delete
            </MenuItem.Button>
          </Menu>
        </Dropdown>
      ),
    },
  ];

  return (
    <>
      {!R.isEmpty(catchupNoteList) ? (
        <Table fixedHeight columnData={COLUMN_DATA} rowData={catchupNoteList} />
      ) : (
        <EmptyState image={emptyImage} title="No Notes Found" />
      )}
    </>
  );
};

export default List;

import React from "react";
import * as dayjs from "dayjs";

const CommencementFee = ({
  commencementFeeData,
  invoice,
  totalInvoice,
  ammountWithCurrency,
  description,
}) => {
  const setResourceForDetails = name => {
    switch (name) {
      case "[invoice_percentage]":
        return invoice?.percentage;
    }
  };

  const feeDetails = () => {
    const detailComponents = description?.split("&lt;&gt;");
    let details = "";

    detailComponents?.map(component => {
      if (component.includes("[")) {
        details += setResourceForDetails(component);
      } else {
        details += component;
      }
    });

    return details;
  };

  return (
    <div className="flex flex-col items-start justify-between w-full py-12 lg:flex-row">
      <div
        className="flex flex-col items-start justify-start w-full h-full pr-0 lg:pr-12 lg:w-1/3"
        style={{ fontFamily: "Modern Gothic" }}
      >
        <div className="flex flex-col items-start justify-start mb-8 lg:mb-0">
          <h2 className="text-xl font-medium">
            {invoice?.percentage}% Commencement Fee
          </h2>
          <p
            className="mt-6"
            dangerouslySetInnerHTML={{
              __html: feeDetails()?.split("\n").join("<br />"),
            }}
          ></p>
        </div>
      </div>
      <div className="flex flex-col items-start justify-start w-full lg:w-2/3">
        <div
          className="flex flex-col items-start justify-start w-full space-y-2"
          style={{ fontFamily: "Modern Gothic" }}
        >
          {commencementFeeData
            .filter(data => data !== undefined)
            .map((data, index) => (
              <div
                key={index}
                className="flex flex-col items-center justify-between w-full px-6 py-4 space-y-2 font-medium text-black lg:space-y-0 lg:flex-row rounded_container"
                style={{ backgroundColor: data.bgColor }}
              >
                <span className="text-base lg:text-xl">{data.title}</span>
                <span className="text-xl">{data.amount}</span>
              </div>
            ))}
          <div className="flex flex-col items-center justify-start w-full px-6 pt-6 pb-12 mt-2 space-y-4 font-medium text-white bg-black lg:items-start rounded_container">
            <span className="text-base text-center lg:text-xl lg:text-left">
              Total due by {dayjs(invoice?.dueDate).format("DD MMMM YYYY")} to
              commence project
            </span>
            <span className="self-center text-4xl leading-snug lg:leading-loose lg:text-5xl">
              {ammountWithCurrency(totalInvoice())}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommencementFee;

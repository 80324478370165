import axios from "axios";

const getInvoicesUrl = (jobId, id) => {
  if (id) {
    return `/api/v1/job_module/jobs/${jobId}/invoices/${id}`;
  }

  return `/api/v1/job_module/jobs/${jobId}/invoices`;
};

export const getInvoices = jobId => {
  return axios.get(getInvoicesUrl(jobId));
};

export const getInvoice = (jobId, id) => {
  return axios.get(getInvoicesUrl(jobId, id));
};

export const createInvoice = (jobId, payload) => {
  return axios.post(getInvoicesUrl(jobId), payload);
};

export const destroyInvoice = (jobId, id) => {
  return axios.delete(getInvoicesUrl(jobId, id));
};

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { showToastrError } from "common";
import { getJob } from "apis/jobs/booking_form";
import { getInvoices, getInvoice } from "apis/jobs/invoices";
import { getEstimateRevisions } from "apis/jobs/estimate_revisions";
import { Warning } from "@bigbinary/neeto-icons";
import { Callout, Spinner } from "@bigbinary/neetoui";
import { Header, Container } from "@bigbinary/neetoui/layouts";
import { getRandomNotFoundImage } from "common/helper";
import Details from "./Details";
import List from "./List";
import Tabs from "../Tabs";
import { jobWithNameNumber, getRevisionId } from "./../../common/helper";
import EmptyState from "components/Common/EmptyState";

const Invoices = () => {
  const { id } = useParams();
  const [invoiceList, setInvoiceList] = useState([]);
  const [invoiceListLoad, setInvoiceListLoad] = useState(true);
  const [invoice, setInvoice] = useState({});
  const [invoiceDetailsLoad, setInvoiceDetailsLoad] = useState(true);
  const [estimateRevisions, setEstimateRevisions] = useState([]);
  const [estimateRevisionsLoad, setEstimateRevisionsLoad] = useState(true);
  const [job, setJob] = useState({});
  const [jobDetailsLoad, setJobDetailsLoad] = useState(true);
  const [invoiceId, setInvoiceId] = useState();
  const [emptyImage, setEmptyImage] = useState();

  useEffect(() => {
    setEmptyImage(getRandomNotFoundImage());
  }, []);

  useEffect(() => {
    if (id) {
      loadJobDetailResponse();
      loadEstimateRevisionsResponse();
      loadInvoiceListResponse();
    }
  }, [id]);

  useEffect(() => {
    if (invoiceId) {
      setInvoiceDetailsLoad(true);
      loadInvoiceDetailResponse();
    }
  }, [invoiceId]);

  const loadJobDetailResponse = async () => {
    try {
      const response = await getJob(id);
      setJob(response.data.job);
      setJobDetailsLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadEstimateRevisionsResponse = async () => {
    try {
      const response = await getEstimateRevisions(id);
      setEstimateRevisions(response.data.estimateRevisions);
      setEstimateRevisionsLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadInvoiceListResponse = async () => {
    try {
      const response = await getInvoices(id);
      setInvoiceList(response.data.invoices);
      setInvoiceId(response.data.invoices[0]?.id);
      setInvoiceListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadInvoiceDetailResponse = async () => {
    try {
      const response = await getInvoice(id, invoiceId);
      setInvoice(response.data.invoice);
      setInvoiceDetailsLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  if (estimateRevisionsLoad || jobDetailsLoad || invoiceListLoad) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <Container isHeaderFixed>
      {invoiceList.length > 0 ? (
        <Header
          title={invoice.serialNumber}
          breadcrumbs={[
            { text: "Jobs", link: "/jobs" },
            { text: jobWithNameNumber(job), link: `/jobs/${id}/overview` },
            { text: "Invoices", link: `/invoices` },
          ]}
        />
      ) : (
        <Header
          title={"Invoices"}
          breadcrumbs={[
            { text: "Jobs", link: "/jobs" },
            {
              text: jobWithNameNumber(job),
              link: `/jobs/${id}/overview`,
            },
          ]}
        />
      )}

      <Tabs jobId={id} revisionId={getRevisionId(estimateRevisions)} />

      {invoiceList.length > 0 ? (
        <div
          className="flex w-full overflow-hidden"
          style={{ height: "calc(100vh - 134px)" }}
        >
          <div className="w-8/12 p-6 space-y-4 overflow-y-scroll border-r neeto-ui-bg-gray-100 neeto-ui-border-gray-300">
            {job.note && (
              <Callout style="warning" icon={Warning}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: job.note.split("\n").join("<br/>"),
                  }}
                />
              </Callout>
            )}
            <Details
              invoice={invoice}
              invoiceDetailsLoad={invoiceDetailsLoad}
            />
          </div>
          <div className="w-4/12 p-6 space-y-6 overflow-y-scroll neeto-ui-bg-white">
            <List
              jobId={id}
              invoiceList={invoiceList}
              loadInvoiceListResponse={loadInvoiceListResponse}
              invoiceId={invoiceId}
              setInvoiceId={setInvoiceId}
              setInvoiceDetailsLoad={setInvoiceDetailsLoad}
            />
          </div>
        </div>
      ) : (
        <div
          className="flex w-full overflow-hidden"
          style={{ height: "calc(100vh - 134px)" }}
        >
          <EmptyState
            image={emptyImage}
            title="No Invoices Found"
            description="We couldn't find any invoices for this job."
          />
        </div>
      )}
    </Container>
  );
};

export default Invoices;

import React from "react";

const Container = ({ children, topBG, bgColor }) => {
  return (
    <div
      className="bg-white flex flex-col h-screen w-full"
      style={{
        backgroundColor: bgColor,
        backgroundImage: `url(${topBG})`,
        backgroundSize: "100%",
      }}
    >
      {children}
    </div>
  );
};

export default Container;

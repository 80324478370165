import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { Select, Typography, Spinner } from "@bigbinary/neetoui";
import * as dayjs from "dayjs";
import {
  getApprovedJobsByWeeks,
  getInvoicesByWeeks,
  getJobEnquiriesByWeeks,
  getWeeklyClosedActionTags,
} from "apis/dashboards";
import { showToastrError } from "common";
import { humanize, sortByStringInt } from "common/helper";
import { YEARS_LIST, CHART_LIST, DEFAULT_CHART_DATA } from "./constants";
import NoChartData from "components/Common/NoChartData";

const ChartView = ({ location }) => {
  dayjs.extend(require('dayjs/plugin/weekOfYear'))
  const [selectedYear, setSelectedYear] = useState(dayjs().year() - 1);
  const [selectedChart, setSelectedChart] = useState("weekly_invoice_targets");
  const [dataLoader, setDataLoader] = useState(true);
  const [responseData, setResponseData] = useState({});
  const [chartData, setChartData] = useState(DEFAULT_CHART_DATA);

  useEffect(() => {
    if (selectedYear && selectedChart) {
      if (selectedChart == "weekly_invoice_targets") {
        getWeeklyInvoicesData();
      } else if (selectedChart == "weekly_job_approval_targets") {
        getWeeklyApprovedJobsData();
      } else if (selectedChart == "weekly_job_enquiry") {
        getWeeklyEnquiriedJobsData();
      } else if (selectedChart == "weekly_action_tags") {
        getWeeklyClosedActionTagsRecords();
      }
    }
  }, [selectedYear, selectedChart, location]);

  useEffect(() => {
    if (responseData?.series) {
      setChartData({
        series: sortByStringInt(responseData.series, "name"),
        noData: responseData.no_data,
        options: {
          xaxis: {
            categories: responseData.categories,
          },
          ...chartData.options,
          annotations: {
            xaxis: [
              {
                x: dayjs().week(),
                strokeDashArray: 0,
                borderColor: "#4558f9",
                label: {
                  style: {
                    color: "#ffffff",
                    background: "#4558f9",
                  },
                  orientation: "horizontal",
                  text: "Current Week",
                },
              },
            ],
          },
        },
      });
    }
  }, [responseData]);

  const getWeeklyInvoicesData = async () => {
    setDataLoader(true);
    try {
      const response = await getInvoicesByWeeks(location, selectedYear);
      setResponseData(response.data.chartData);
      setDataLoader(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const getWeeklyApprovedJobsData = async () => {
    setDataLoader(true);
    try {
      const response = await getApprovedJobsByWeeks(location, selectedYear);
      setResponseData(response.data.chartData);
      setDataLoader(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const getWeeklyEnquiriedJobsData = async () => {
    setDataLoader(true);
    try {
      const response = await getJobEnquiriesByWeeks(location, selectedYear);
      setResponseData(response.data.chartData);
      setDataLoader(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const getWeeklyClosedActionTagsRecords = async () => {
    setDataLoader(true);
    try {
      const response = await getWeeklyClosedActionTags(location, selectedYear);
      setResponseData(response.data.chartData);
      setDataLoader(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  return (
    <div
      className="flex w-full px-6"
      style={{ height: "calc(100vh - 427px - 24px" }}
    >
      <div className="relative flex flex-col w-full p-4 mt-4 border rounded-lg neeto-ui-bg-white neeto-ui-border-gray-300 hover:neeto-ui-border-primary-500 hover:shadow">
        <div className="flex justify-between w-full mb-2">
          <Typography style="h5" weight="semibold" className="text-gray-900">
            {humanize(selectedChart)}
          </Typography>
          <div className="flex items-center justify-end space-x-3">
            <Select
              placeholder="Select a chart"
              className="w-60"
              options={CHART_LIST}
              value={CHART_LIST.find(chart => chart.value == selectedChart)}
              onChange={opt => setSelectedChart(opt.value)}
            />
            <Select
              placeholder="Select a year"
              className="w-24"
              options={YEARS_LIST}
              value={YEARS_LIST.find(year => year.value == selectedYear)}
              onChange={opt => setSelectedYear(opt.value)}
            />
          </div>
        </div>

        {dataLoader ? (
          <div className="absolute inset-0 flex flex-col items-center justify-center w-full h-full bg-gray-200 bg-opacity-75 rounded-lg">
            <div className="flex items-center justify-center h-full">
              <Spinner />
            </div>
          </div>
        ) : chartData.noData ? (
          <NoChartData />
        ) : (
          <Chart
            options={chartData.options}
            series={chartData.series}
            type="area"
            width="100%"
            height="85%"
          />
        )}
      </div>
    </div>
  );
};

export default ChartView;

import axios from "axios";

export const getClientsByJobs = (preferredLocationId, preferredPeriod) => {
  return axios.get(
    `/api/v1/reports/top_clients_by_jobs?start_date=${preferredPeriod}&location_id=${preferredLocationId}`
  );
};

export const exportClients = (preferredLocationId, preferredPeriod) => {
  return axios.get(
    `/api/v1/reports/top_clients_by_jobs/export?start_date=${preferredPeriod}&location_id=${preferredLocationId}`
  );
};

import * as Yup from "yup";
import { MAX_STRING_LENGTH } from "common/constants";

export const CATEGORY_VALIDATION_SCHEMA = Yup.object({
  name: Yup.string()
    .required("Name can't be empty.")
    .max(
      MAX_STRING_LENGTH,
      `Name must be at most ${MAX_STRING_LENGTH} characters.`
    ),
});

export const CATEGORY_INITIAL_VALUE = {
  name: "",
};

export const DETAIL_VALIDATION_SCHEMA = Yup.object({
  name: Yup.string().required("Name can't be empty."),
});

export const DETAIL_INITIAL_VALUE = {
  name: "",
};

export const EXCLUSIVITY_PERIOD_VALIDATION_SCHEMA = Yup.object({
  name: Yup.string()
    .required("Name can't be empty.")
    .max(
      MAX_STRING_LENGTH,
      `Name must be at most ${MAX_STRING_LENGTH} characters.`
    ),
});

export const EXCLUSIVITY_PERIOD_INITIAL_VALUE = {
  name: "",
};

export const PERIOD_VALIDATION_SCHEMA = Yup.object({
  name: Yup.string()
    .required("Name can't be empty.")
    .max(
      MAX_STRING_LENGTH,
      `Name must be at most ${MAX_STRING_LENGTH} characters.`
    ),
});

export const PERIOD_INITIAL_VALUE = {
  name: "",
};

export const TERRITORY_VALIDATION_SCHEMA = Yup.object({
  name: Yup.string()
    .required("Name can't be empty.")
    .max(
      MAX_STRING_LENGTH,
      `Name must be at most ${MAX_STRING_LENGTH} characters.`
    ),
});

export const TERRITORY_INITIAL_VALUE = {
  name: "",
};

export const QUANTITY_VALIDATION_SCHEMA = Yup.object({
  name: Yup.string()
    .required("Name can't be empty.")
    .max(
      MAX_STRING_LENGTH,
      `Name must be at most ${MAX_STRING_LENGTH} characters.`
    ),
});

export const QUANTITY_INITIAL_VALUE = {
  name: "",
};

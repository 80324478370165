import React, { useState } from "react";
import { Tab } from "@bigbinary/neetoui";
import { ACCOUNT_DETAILS_TABS } from "./constants";
import Profile from "./Profile";
import Users from "./Users";

const renderTabs = (tabs, accountDetail, loadAccountDetailResponse) => {
  switch (tabs.label) {
    case "Profile":
      return <Profile accountDetail={accountDetail} loadAccountDetailResponse={loadAccountDetailResponse} />;
    case "Users":
      return <Users accountDetail={accountDetail} loadAccountDetailResponse={loadAccountDetailResponse} />;
  }
};

const Details = ({ accountDetail, loadAccountDetailResponse }) => {
  const [currentTab, setcurrentTab] = useState(ACCOUNT_DETAILS_TABS.Profile);

  return (
    <div className="w-full overflow-auto">
      <div className="flex items-center">
        <Tab className="px-6 border-b">
          {Object.keys(ACCOUNT_DETAILS_TABS).map((tab, index) => {
            const { label, icon } = ACCOUNT_DETAILS_TABS[tab];
            return (
              <Tab.Item
                icon={icon}
                key={index}
                active={currentTab.label === label}
                onClick={() => setcurrentTab(ACCOUNT_DETAILS_TABS[tab])}
              >
                {label}
              </Tab.Item>
            );
          })}
        </Tab>
      </div>

      {renderTabs(currentTab, accountDetail, loadAccountDetailResponse)}
    </div>
  );
};

export default Details;

import React from "react";
import { Typography } from "@bigbinary/neetoui";

const Items = ({
  key,
  itemGroup,
  handleLineItemClick,
  handleLineItemCategoryClick,
  lineItemList,
}) => {
  return (
    <div className="relative" key={key}>
      <div className="sticky top-0 z-10 px-2 py-1 border-t border-b border-gray-200 bg-gray-50">
        <Typography
          style="h6"
          className="font-semibold text-gray-600 uppercase"
          onClick={() =>
            handleLineItemCategoryClick(
              lineItemList.find(
                item => item.lineItemCategoryName === itemGroup[0]
              )?.lineItemCategoryId
            )
          }
        >
          {itemGroup[0]}
        </Typography>
      </div>
      <ul className="relative z-0 divide-y divide-gray-200">
        {itemGroup[1].map((item, index) => {
          return (
            <li
              key={index}
              className="cursor-pointer"
              onClick={() => handleLineItemClick(item)}
            >
              <div className="relative flex items-center p-2 space-x-3 jw-artists-directory hover:bg-gray-100">
                <div className="flex-1 min-w-0">
                  <p
                    className="text-gray-900 truncate"
                    style={{ fontSize: "13px" }}
                  >
                    {item.name}
                  </p>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Items;

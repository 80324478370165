import axios from "axios";

export const sendDraftMessage = (jobId, messageId, payload) => {
  return axios.put(
    `/api/v1/job_module/jobs/${jobId}/draft_message_threads/${messageId}`,
    payload
  );
};

export const getDraftMessageThread = (jobId, messageId) => {
  return axios.get(
    `/api/v1/job_module/jobs/${jobId}/draft_message_threads/${messageId}`
  );
};

export const destroyDraftMessageThread = (jobId, messageId) => {
  return axios.delete(
    `/api/v1/job_module/jobs/${jobId}/draft_message_threads/${messageId}`
  );
};

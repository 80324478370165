import React, { useRef } from "react";
import { Pane, Typography, Input, Button, Select } from "@bigbinary/neetoui";
import { dropDownListGenerator } from "common/helper";
import { XERO_TAX_FORMATS } from "../constants";

const FormPane = ({
  isOpen,
  onClose,
  title,
  formik,
  onSubmit,
  currencyList,
  buttonLoader,
  taxTypeList,
}) => {
  const inputRef = useRef();
  const dropdownList = dropDownListGenerator(currencyList);
  const taxDropdownList = dropDownListGenerator(taxTypeList);

  return (
    <Pane isOpen={isOpen} onClose={onClose} initialFocusRef={inputRef}>
      <Pane.Header>
        <Typography style="h2" weight="semibold">
          {title}
        </Typography>
      </Pane.Header>
      <Pane.Body>
        <div className="flex flex-col w-full space-y-6">
          <Input
            label="Name"
            ref={inputRef}
            required={true}
            onChange={formik.handleChange}
            value={formik.values.name}
            error={
              Boolean(formik.touched.name && formik.errors.name) &&
              formik.errors.name
            }
            {...formik.getFieldProps("name")}
          />
          <Input
            label="Short Name"
            required={true}
            onChange={formik.handleChange}
            value={formik.values.shortName}
            error={
              Boolean(formik.touched.shortName && formik.errors.shortName) &&
              formik.errors.shortName
            }
            {...formik.getFieldProps("shortName")}
          />
          <Select
            label="Currency"
            name="currency"
            required={true}
            placeholder="Select a Currency"
            helpText="This would be used as default currency."
            options={dropdownList}
            value={dropdownList.find(
              currency => currency.value === formik.values.currencyId
            )}
            error={
              Boolean(formik.touched.currencyId && formik.errors.currencyId) &&
              formik.errors.currencyId
            }
            onChange={async opt => {
              await formik.setFieldValue("currencyId", opt.value);
              await formik.setFieldTouched("currencyId", true);
            }}
          />
          <Select
            label="Xero Tax Format"
            name="tag"
            id="tag"
            placeholder="Select a Xero Tax Format"
            options={XERO_TAX_FORMATS}
            value={XERO_TAX_FORMATS.find(
              format => format.value === formik.values.tag
            )}
            required={true}
            error={
              Boolean(formik.touched.tag && formik.errors.tag) &&
              formik.errors.tag
            }
            onChange={async opt => {
              await formik.setFieldValue("tag", opt.value);
              await formik.setFieldTouched("tag", true);
            }}
          />

          <Select
            label="Tax List"
            name="taxTypeId"
            id="taxTypeId"
            placeholder="Select a Tax"
            options={taxDropdownList}
            value={taxDropdownList.find(
              format => format.value === formik.values.taxTypeId
            )}
            error={
              Boolean(formik.touched.taxTypeId && formik.errors.taxTypeId) &&
              formik.errors.taxTypeId
            }
            onChange={async opt => {
              await formik.setFieldValue("taxTypeId", opt.value);
              await formik.setFieldTouched("taxTypeId", true);
            }}
          />
        </div>
      </Pane.Body>
      <Pane.Footer className="flex space-x-2">
        <Button
          label="Save changes"
          onClick={() => onSubmit()}
          loading={buttonLoader}
        />
        <Button label="Cancel" style="text" onClick={() => onClose()} />
      </Pane.Footer>
    </Pane>
  );
};

export default FormPane;

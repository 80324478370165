import axios from "axios";

const getChatTemplatesUrl = id => {
  if (id) {
    return `/api/v1/settings/chat_templates/${id}`;
  }

  return `/api/v1/settings/chat_templates`;
};

export const getChatTemplates = (searchParams = "") => {
  let query = "";
  if (searchParams.length > 0) query = `q[name_i_cont_any]=${searchParams}`;
  return axios.get(`${getChatTemplatesUrl()}?${query}`);
};

export const createChatTemplate = payload => {
  return axios.post(getChatTemplatesUrl(), payload);
};

export const updateChatTemplate = (id, payload) => {
  return axios.put(getChatTemplatesUrl(id), payload);
};

export const destroyChatTemplate = id => {
  return axios.delete(getChatTemplatesUrl(id));
};

import axios from "axios";

const getLineItemCategoriesUrl = id => {
  if (id) {
    return `/api/v1/settings/line_item_categories/${id}`;
  }

  return `/api/v1/settings/line_item_categories`;
};

export const getLineItemCategories = () => {
  return axios.get(getLineItemCategoriesUrl());
};

export const createLineItemCategory = payload => {
  return axios.post(getLineItemCategoriesUrl(), payload);
};

export const updateLineItemCategory = (id, payload) => {
  return axios.put(getLineItemCategoriesUrl(id), payload);
};

export const destroyLineItemCategory = id => {
  return axios.delete(getLineItemCategoriesUrl(id));
};

import axios from "axios";

const getKillFeesUrl = id => {
  if (id) {
    return `/api/v1/settings/kill_fees/${id}`;
  }

  return `/api/v1/settings/kill_fees`;
};

export const getKillFees = (searchParams = "") => {
  let query = "";
  if (searchParams.length > 0) query = `q[name_i_cont_any]=${searchParams}`;
  return axios.get(`${getKillFeesUrl()}?${query}`);
};

export const createKillFee = payload => {
  return axios.post(getKillFeesUrl(), payload);
};

export const updateKillFee = (id, payload) => {
  return axios.put(getKillFeesUrl(id), payload);
};

export const destroyKillFee = id => {
  return axios.delete(getKillFeesUrl(id));
};

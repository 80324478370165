import React, { useState, useEffect } from "react";
import { Input, Label, Switch } from "@bigbinary/neetoui";
import SingleImageUploader from "components/Common/SingleImageUploader";

const AccountForm = ({ formik }) => {
  const [accountType, setAccountType] = useState(true);
  const [accountPlan, setAccountPlan] = useState(false);

  useEffect(() => {
    if (formik.values.accountType === "test") {
      setAccountType(true);
    } else {
      setAccountType(false);
    }
  }, [formik.values.accountType]);

  useEffect(() => {
    if (formik.values.plan === "suspended") {
      setAccountPlan(true);
    } else {
      setAccountPlan(false);
    }
  }, [formik.values.plan]);

  return (
    <div className="w-full pb-8">
      <form className="space-y-6">
        <Input
          label="Name"
          name="name"
          onChange={formik.handleChange}
          value={formik.values.name}
          required={true}
          error={
            Boolean(formik.touched.name && formik.errors.name) &&
            formik.errors.name
          }
          {...formik.getFieldProps("name")}
        />

        <Input
          label="Subdomain"
          name="subdomain"
          onChange={formik.handleChange}
          value={formik.values.subdomain}
          required={true}
          error={
            Boolean(formik.touched.subdomain && formik.errors.subdomain) &&
            formik.errors.subdomain
          }
          {...formik.getFieldProps("subdomain")}
        />

        <div className="flex flex-col space-y-2">
          <Label>App Logo</Label>
          <SingleImageUploader
            name="App Logo"
            type="app_logo"
            formValue={formik.values}
            setFormValue={formik.setFieldValue}
          />
        </div>

        <div className="flex flex-col space-y-2">
          <Label>Large App Logo (for Templates)</Label>
          <SingleImageUploader
            name="App Logo For Templates"
            type="large_app_logo"
            formValue={formik.values}
            setFormValue={formik.setFieldValue}
          />
        </div>

        <Input
          label="Seats Count"
          type="number"
          name="seatsCount"
          onChange={formik.handleChange}
          value={formik.values.seatsCount}
          required={true}
          error={
            Boolean(formik.touched.seatsCount && formik.errors.seatsCount) &&
            formik.errors.seatsCount
          }
          {...formik.getFieldProps("seatsCount")}
        />

        <Switch
          label="Test Account"
          checked={accountType}
          onChange={() => {
            setAccountType(!accountType);
            if (!accountType) {
              formik.setFieldValue("accountType", "test");
            } else {
              formik.setFieldValue("accountType", "live");
            }
          }}
        />

        <Switch
          label="Suspended"
          checked={accountPlan}
          onChange={() => {
            setAccountPlan(!accountPlan);
            if (!accountPlan) {
              formik.setFieldValue("plan", "suspended");
            } else {
              formik.setFieldValue("plan", "active");
            }
          }}
        />

        <Switch
          label="Studio"
          checked={formik.values.studio}
          onChange={() => {
            formik.setFieldValue("studio", !formik.values.studio)
          }}
        />
      </form>
    </div>
  );
};

export default AccountForm;

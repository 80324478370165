import React, { useCallback, useRef, useState, useEffect } from "react";
import { Upload } from "@bigbinary/neeto-icons";
import { Button } from "@bigbinary/neetoui";
import { useDropzone } from "react-dropzone";

const AttachmentUploader = ({
  name,
  formValue,
  setFormValue,
  type,
  accept = "image/png, image/gif, image/jpeg, image/jpg",
  setDetailInitial = () => {},
  clearAttachment,
  setClearAttachment = () => {},
  initial,
  setInitial = () => {},
  kind = "contract",
}) => {
  const imageRef = useRef(null);
  const [image, setImg] = useState();

  useEffect(() => {
    return () => {};
  }, []);

  useEffect(() => {
    if (!initial && kind !== "csv") {
      setFormValue("attachments", { ...formValue, ...image });
    }

    if (!initial && kind === "csv" && image?.file_data && image?.filename) {
      setFormValue("file_data", image?.file_data);
      setFormValue("filename", image?.filename);
    }
  }, [image]);

  useEffect(() => {
    if (initial) {
      setImg(formValue);
    }
  }, [formValue]);

  const handleClick = () => {
    imageRef.current.click();
  };

  const handleRemoveClick = () => {
    setInitial(false);
    setDetailInitial(false);
    setImg({ ...image, filename: null, file_data: null, _destroy: 1 });
  };

  const onDrop = useCallback(files => {
    setInitial(false);
    setClearAttachment(false);
    files.map(file => {
      const reader = new FileReader();
      reader.onload = () => {
        setImg({
          filename: file.name,
          attachable_kind: kind,
          file_data: reader.result,
          fileType: file.type,
        });
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: accept,
  });

  const DragDropBaseClass =
    "mb-6 flex justify-center p-2 border neeto-ui-border-primary-500 border-dashed rounded-md transition duration-500 ease-in-out hover:bg-gray-50 cursor-pointer";

  return (
    <div className={DragDropBaseClass}>
      {image?._destroy === 1 ||
      (!image?.file_data && !image?.filename) ||
      clearAttachment ? (
        <div
          className="flex flex-col items-center p-4 space-y-3"
          {...getRootProps()}
        >
          <Upload size={32} className="neeto-ui-text-primary-500" />
          <input
            type="file"
            accept={accept}
            id="photo"
            className="hidden"
            ref={imageRef}
            {...getInputProps()}
          />
          {isDragActive ? (
            <p className={"flex justify-center"}>Drop the file here</p>
          ) : (
            <div className="flex flex-col items-center space-y-1">
              <div className="flex items-center justify-center w-full text-sm neeto-ui-text-gray-800">
                <p>Drag and drop or&nbsp;</p>
                <label
                  htmlFor="file-upload"
                  className="font-medium text-purple-500 bg-transparent rounded-md cursor-pointer"
                >
                  <Button
                    style="link"
                    label="Browse"
                    className="underline"
                    onClick={handleClick}
                  />
                </label>
                <label
                  htmlFor="file-upload"
                  className="font-medium text-purple-500 bg-transparent rounded-md cursor-pointer"
                ></label>
              </div>
              <p className="neeto-ui-typography neeto-ui-text-body3 neeto-ui-text-gray-500">
                {name}
              </p>
            </div>
          )}
        </div>
      ) : (
        <div
          className="relative flex justify-center m-5 overflow-hidden"
          style={{ maxHeight: "500px" }}
        >
          {image?.fileType === "application/pdf" ||
          image?.fileType === "text/csv" ? (
            <p>{image?.filename}</p>
          ) : (
            <img
              src={image?.file_data || image?.src}
              className={`block object-contain ${
                type === "thumbnail" ? "w-auto" : "w-44"
              }`}
            />
          )}
          <div
            className="absolute top-0 right-0 flex items-center justify-center w-4 h-4 text-white bg-red-500 rounded-full cursor-pointer"
            onClick={() => handleRemoveClick()}
          >
            <i className="ri-subtract-line" />
          </div>
        </div>
      )}
    </div>
  );
};

export default AttachmentUploader;

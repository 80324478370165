import React from "react";
import { Button, Table, Typography } from "@bigbinary/neetoui";
import { displayData, artistModuleName } from "common/helper";

const Artists = ({ list, title }) => {
  const renderSerial = rowData => {
    return (
      <Button
        style="link"
        to={`/${artistModuleName().toLowerCase()}/${rowData.id}`}
        label={rowData.serialNumber}
      />
    );
  };

  const renderEmail = rowData => {
    return (
      <Button
        style="link"
        href={`mailto:${rowData.email}`}
        label={rowData.email}
      />
    );
  };

  const renderAddress = rowData => {
    return (
      <div
        className="w-56 text-sm text-gray-900 truncate"
        dangerouslySetInnerHTML={{
          __html: rowData?.fullAddress?.split("\n").join("<br/>"),
        }}
      />
    );
  };

  const COLUMN_DATA = [
    {
      title: "Serial",
      dataIndex: "serialNumber",
      key: "serialNumber",
      width: 100,
      fixed: "left",
      render: (_, rowData) => renderSerial(rowData),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 200,
      fixed: "left",
      render: (_, rowData) => displayData(rowData, "name"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 200,
      render: (_, rowData) => renderEmail(rowData),
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: 200,
      render: (_, rowData) => displayData(rowData, "phoneNumber"),
    },
    {
      title: "Tax Status",
      dataIndex: "taxStatus",
      key: "taxStatus",
      width: 150,
      render: (_, rowData) => displayData(rowData, "taxType"),
    },
    {
      title: "Address",
      dataIndex: "fullAddress",
      key: "fullAddress",
      width: 200,
      render: (_, rowData) => renderAddress(rowData),
    },
  ];
  return (
    <div className="flex flex-col w-full mt-4 p-4 rounded bg-white">
      <Typography
        style="h3"
        weight="semibold"
        className="mb-4 mt-2 text-gray-800 px-3"
      >
        {title}
      </Typography>
      <Table fixedHeight columnData={COLUMN_DATA} rowData={list} />
    </div>
  );
};

export default Artists;

import React from "react";
import { Pane, Typography, Input, Button, Label } from "@bigbinary/neetoui";
import { QUILL_TOOLBAR_STYLE } from "common/constants";
import ReactQuill from "react-quill";

const FormPane = ({
  isOpen,
  onClose,
  title,
  formik,
  onSubmit,
  buttonLoader,
}) => {
  return (
    <Pane isOpen={isOpen} onClose={onClose} size="large">
      <Pane.Header>
        <Typography style="h2" weight="semibold">
          {title}
        </Typography>
      </Pane.Header>
      <Pane.Body>
        <div className="flex flex-col w-full space-y-6">
          <Input
            label="Name"
            required={true}
            onChange={formik.handleChange}
            value={formik.values.name}
            error={
              Boolean(formik.touched.name && formik.errors.name) &&
              formik.errors.name
            }
            {...formik.getFieldProps("name")}
          />

          <div className="flex flex-col space-y-2 large-ql-editor">
            <Label>Body</Label>
            <ReactQuill
              value={formik.values.body}
              onChange={text => formik.setFieldValue("body", text)}
              modules={{ toolbar: QUILL_TOOLBAR_STYLE }}
              placeholder="Enter your message"
            />
          </div>

          <div className="mt-4">
            <p className="font-semibold mb-3 text-lg">
              Please use the below tags to get dynamic values with respect to
              job.
            </p>

            <div className="mb-2">
              <span className="inline-block px-2 py-1.5 border border-solid border-grey-300 rounded text-xs">{`<>[project_scope]<>`}</span>
            </div>

            <div className="mb-2">
              <span className="inline-block px-2 py-1.5 border border-solid border-grey-300 rounded text-xs">{`<>[delivery_scope]<>`}</span>
            </div>

            <div className="mb-2">
              <span className="inline-block px-2 py-1.5 border border-solid border-grey-300 rounded text-xs">{`<>[schedule]<>`}</span>
            </div>

            <div className="mb-2">
              <span className="inline-block px-2 py-1.5 border border-solid border-grey-300 rounded text-xs">{`<>[client_contacts]<>`}</span>
            </div>

            <div className="mb-2">
              <span className="inline-block px-2 py-1.5 border border-solid border-grey-300 rounded text-xs">{`<>[artists]<>`}</span>
            </div>

            <div className="mb-2">
              <span className="inline-block px-2 py-1.5 border border-solid border-grey-300 rounded text-xs">{`<>[client_name]<>`}</span>
            </div>
          </div>
        </div>
      </Pane.Body>
      <Pane.Footer className="flex space-x-2">
        <Button
          label="Save changes"
          onClick={() => onSubmit()}
          loading={buttonLoader}
        />
        <Button label="Cancel" style="text" onClick={() => onClose()} />
      </Pane.Footer>
    </Pane>
  );
};

export default FormPane;

import React, { useState } from "react";
import { Delete } from "@bigbinary/neeto-icons";
import { Label, Button, Tag, Alert, Typography } from "@bigbinary/neetoui";
import { useUserState } from "contexts/user";
import { showToastrError } from "common";
import { numberWithCommas } from "common/helper";
import { getFinalInvoice, getCommencementInvoice } from "../../common/helper";
import { destroyInvoice } from "apis/jobs/invoices";
import Card from "components/Common/Card";

const InvoiceDetails = ({
  invoiceList,
  rciList,
  loadInvoiceListResponse,
  jobDetail,
  loadJobDetailResponse,
}) => {
  const { user } = useUserState();
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [btnload, setBtnLoad] = useState(false);
  const [invoiceId, setInvoiceId] = useState();
  const commencementInvoice = getCommencementInvoice(invoiceList || []);
  const finalInvoice = getFinalInvoice(invoiceList || []);
  const commencementRci = getCommencementInvoice(rciList || []);
  const finalRci = getFinalInvoice(rciList || []);

  const deleteInvoiceEntry = async () => {
    try {
      setBtnLoad(true);
      await destroyInvoice(jobDetail.id, invoiceId);
      setDeleteAlertOpen(false);
    } catch (error) {
      if (error.response.data.id) {
        setDeleteAlertOpen(false);
      }
    } finally {
      loadInvoiceListResponse();
      loadJobDetailResponse();
      setBtnLoad(false);
    }
  };

  return (
    <>
      <Card title={"Invoice Details"}>
        {commencementInvoice || finalInvoice || jobDetail.purchaseOrder ? (
          <div className="flex flex-col space-y-2">
            {commencementInvoice && (
              <div className="grid items-center grid-cols-5 gap-4">
                <div className="flex items-center col-span-2">
                  <Label>Commencement Invoice</Label>
                </div>
                <div className="flex items-center">
                  <span className="font-semibold text-right">
                    {`${numberWithCommas(
                      Number(commencementInvoice?.total || 0).toFixed(2)
                    )} ${jobDetail.currency}`}
                  </span>
                </div>
                <div className="flex items-center">
                  {commencementInvoice?.status === "paid" ? (
                    <Tag style="success" label="Paid" />
                  ) : (
                    <Tag style="danger" label="Unpaid" />
                  )}
                </div>
                <div className="flex items-center justify-end">
                  <Button
                    style="link"
                    to={`/invoices/${commencementInvoice?.id}`}
                    label={commencementInvoice?.serialNumber}
                  />

                  {(user.access === "admin" || user.access === "super_admin") &&
                    commencementInvoice && (
                      <Button
                        size="small"
                        style="danger-text"
                        icon={Delete}
                        disabled={finalInvoice || commencementRci || finalRci}
                        className="ml-2"
                        onClick={() => {
                          setDeleteAlertOpen(true);
                          setInvoiceId(commencementInvoice.id);
                        }}
                      />
                    )}
                </div>
              </div>
            )}

            {finalInvoice && (
              <div className="grid items-center grid-cols-5 gap-4">
                <div className="flex items-center col-span-2">
                  <Label>Final Invoice</Label>
                </div>
                <div className="flex items-center">
                  <span className="font-semibold text-right">{`${numberWithCommas(
                    Number(finalInvoice?.total || 0).toFixed(2)
                  )} ${jobDetail.currency}`}</span>
                </div>
                <div className="flex items-center">
                  {finalInvoice?.status === "paid" ? (
                    <Tag style="success" label="Paid" />
                  ) : (
                    <Tag style="danger" label="Unpaid" />
                  )}
                </div>
                <div className="flex items-center justify-end">
                  <Button
                    style="link"
                    to={`/invoices/${finalInvoice?.id}`}
                    label={finalInvoice?.serialNumber}
                  />

                  {(user.access === "admin" || user.access === "super_admin") &&
                    finalInvoice && (
                      <Button
                        size="small"
                        style="danger-text"
                        icon={Delete}
                        className="ml-2"
                        onClick={() => {
                          setDeleteAlertOpen(true);
                          setInvoiceId(finalInvoice.id);
                        }}
                      />
                    )}
                </div>
              </div>
            )}
            {jobDetail.purchaseOrder && (
              <div className="flex items-center space-x-2">
                <Label>PO Number:</Label>
                <Typography style="body2">{jobDetail.purchaseOrder}</Typography>
              </div>
            )}
          </div>
        ) : (
          <div className="flex items-center justify-center h-16">
            <span className="text-sm text-gray-600">No Invoices Found</span>
          </div>
        )}
      </Card>

      <Alert
        isOpen={deleteAlertOpen}
        title="Delete Invoice"
        message="Are you sure you want to delete the Invoice?"
        onClose={() => setDeleteAlertOpen(false)}
        onSubmit={() => deleteInvoiceEntry()}
        isSubmitting={btnload}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, delete"
      />
    </>
  );
};

export default InvoiceDetails;

import React, { useState } from "react";
import { MenuHorizontal } from "@bigbinary/neeto-icons";
import { Table, Dropdown } from "@bigbinary/neetoui";

const TaxTypeList = ({
  taxTypeList,
  setModalState,
  setDeleteModalState,
  setTaxTypeDetailName,
  setTaxTypeDetail,
}) => {
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const { Menu, MenuItem } = Dropdown;

  const COLUMN_DATA = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "20%",
      render: (_, rowData) => <>{rowData.name ? rowData.name : "-"}</>,
      fixed: "left",
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
      width: "20%",
      render: (_, rowData) => <>{rowData.rate ? rowData.rate : "-"}</>,
      fixed: "left",
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: "10%",
      render: (_, rowData) => (
        <Dropdown
          autoWidth
          closeOnSelect
          buttonStyle="text"
          strategy="fixed"
          icon={MenuHorizontal}
        >
          <Menu>
            <MenuItem.Button
              onClick={() => {
                setTaxTypeDetail(rowData);
                setModalState(true);
              }}
            >
              Edit
            </MenuItem.Button>
            <MenuItem.Button
              style="danger"
              onClick={() => {
                setTaxTypeDetail(rowData);
                setTaxTypeDetailName(rowData.name);
                setDeleteModalState(true);
              }}
            >
              Delete
            </MenuItem.Button>
          </Menu>
        </Dropdown>
      ),
    },
  ];

  return <Table
    fixedHeight
    columnData={COLUMN_DATA} rowData={taxTypeList}
    currentPageNumber={pageIndex}
    defaultPageSize={pageSize}
    handlePageChange={(page, pageSize) => {
      setPageIndex(page);
      setPageSize(pageSize);
    }}
  />;
};

export default TaxTypeList;

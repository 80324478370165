import React, { useState, useEffect, useRef } from "react";
import { Search } from "@bigbinary/neeto-icons";
import {
  Button,
  Table,
  Dropdown,
  Tag,
  Spinner,
  Typography,
  Input,
} from "@bigbinary/neetoui";
import { SubHeader } from "@bigbinary/neetoui/layouts";
import * as dayjs from "dayjs";
import * as R from "ramda";
import { showToastrError } from "common";
import useDebounce from "common/debounce";
import { humanize, getJobsStatusColor, getRandomNotFoundImage, artistModuleName } from "common/helper";
import { DEFAULT_PAGE_SIZE } from "common/constants";
import { getOrganisationJobs } from "apis/organisations/jobs";
import EmptyState from "components/Common/EmptyState";
import NewJob from "components/Dashboard/Jobs/NewJob";

const Jobs = ({ organisationDetail }) => {
  const debouncedSearchTerm = useDebounce(searchParams, 500);
  const initialFocusRef = useRef(null);
  const [newJobPane, setNewJobPane] = useState(false);
  const [searchParams, setSearchParams] = useState();
  const [jobList, setJobList] = useState([]);
  const [jobListLoading, setJobListLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [sortProps, setSortProps] = useState();
  const [initial, setInitial] = useState(true);
  const { Menu, MenuItem } = Dropdown;
  const [emptyImage, setEmptyImage] = useState();

  useEffect(() => {
    setEmptyImage(getRandomNotFoundImage())
  }, [])

  useEffect(() => {
    if (organisationDetail.id) {
      loadJobListResponse();
    }
  }, [organisationDetail.id]);

  useEffect(() => {
    if (pageIndex && !initial) {
      loadJobListResponse();
    }
  }, [pageIndex]);

  useEffect(() => {
    if (sortProps && !initial) {
      loadJobListResponse();
    }
  }, [sortProps]);

  useEffect(() => {
    if (debouncedSearchTerm != undefined) {
      loadJobListResponse(searchParams);
    }
  }, [debouncedSearchTerm]);

  const loadJobListResponse = async () => {
    try {
      const response = await getOrganisationJobs(
        organisationDetail.id,
        searchParams,
        sortProps,
        pageIndex || 1,
        DEFAULT_PAGE_SIZE
      );
      setJobList(response.data.jobs);
      setTotalRecords(response.data.totalRecords);
      setJobListLoading(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const COLUMN_DATA = [
    {
      key: "serialNumber",
      dataIndex: "serialNumber",
      title: "Job No.",
      width: 100,
      sorter: true,
      field: "serial",
      render: serialNumber => <>{serialNumber}</>,
    },
    {
      key: "name",
      dataIndex: "name",
      title: "Name",
      width: 200,
      sorter: true,
      field: "name",
      render: (_, rowData) => {
        return (
          <Button
            style="link"
            to={`/jobs/${rowData.id}/overview`}
            label={rowData.name}
          />
        );
      },
    },
    {
      title: artistModuleName(),
      dataIndex: "artists",
      key: "artists",
      field: "artists_full_name",
      width: 180,
      render: (_, rowData) => {
        return (
          <Dropdown
            autoWidth
            closeOnSelect
            trigger="hover"
            position="auto"
            customTarget={
              <p className="w-32 truncate">
                {rowData.artists.map((artist, index) => {
                  return (
                    <>
                      {artist.name}
                      {index !== rowData.artists.length - 1 ? ", " : ""}
                    </>
                  );
                })}
              </p>
            }
          >
            <Menu>
              {rowData.artists.map(artist => {
                return (
                  <MenuItem.Button
                    className="no-underline"
                    key={artist.id}
                    to={`/${artistModuleName().toLowerCase()}/${artist.id}`}
                  >
                    {artist.name}
                  </MenuItem.Button>
                );
              })}
            </Menu>
          </Dropdown>
        );
      },
    },
    {
      title: "Staff",
      dataIndex: "staffFullName",
      key: "staffFullName",
      width: 180,
      field: "user_full_name",
      render: staffFullName => <>{staffFullName}</>,
    },
    {
      title: "Enquired On",
      dataIndex: "enquiryDate",
      key: "enquiryDate",
      sorter: true,
      width: 180,
      field: "enquiry_date",
      render: enquiryDate => dayjs(enquiryDate).format("DD/MM/YYYY"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: true,
      field: "status",
      width: 130,
      render: status => {
        return (
          <Tag
            type="solid"
            style={getJobsStatusColor(status)}
            label={humanize(status)}
          />
        );
      },
    },
  ];

  const handleTableChange = (_, __, sorter) => {
    setInitial(false);
    setSortProps(sorter);
  };

  if (!organisationDetail.id) {
    return (
      <div
        className="flex items-center justify-center p-2"
        style={{ height: "calc(100vh - 134px)" }}
      >
        <EmptyState
          image={emptyImage}
          title="Please click on any organisation to access the details."
        />
      </div>
    );
  }

  if (jobListLoading) {
    return (
      <div
        className="flex items-center justify-center w-full"
        style={{ height: "calc(100vh - 134px)" }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col" style={{ height: "calc(100vh - 134px)" }}>
        <SubHeader
          className="px-6 pt-4"
          leftActionBlock={
            <Typography style="h4" component="h4" weight="semibold">
              {totalRecords > 1
                ? `${totalRecords} Jobs`
                : `${totalRecords} Job`}
            </Typography>
          }
          rightActionBlock={
            <div className="flex justify-end space-x-3">
              <Input
                size="small"
                type="search"
                placeholder="Search"
                className="neeto-ui-header__search-input"
                prefix={<Search size={16} />}
                value={searchParams}
                onChange={e => setSearchParams(e.target.value)}
                clear={() => setSearchParams("")}
              />
              <Button
                size="small"
                label="Add Job"
                className="px-3"
                onClick={() => {
                  // history.push("/jobs/create", {
                  //   organisationId: organisationDetail.id,
                  // });
                  setNewJobPane(true);
                }}
              />
            </div>
          }
        />
        <div className="px-4" style={{ height: "calc(100vh - 280px)" }}>
          {!R.isEmpty(jobList) ? (
            <Table
              columnData={COLUMN_DATA}
              rowData={jobList}
              fixedHeight
              loading={jobListLoading}
              totalCount={totalRecords}
              currentPageNumber={pageIndex}
              defaultPageSize={pageSize}
              handlePageChange={(page, pageSize) => {
                setInitial(false);
                setPageIndex(page);
                setPageSize(pageSize);
              }}
              onChange={(pagination, filters, sorter) =>
                handleTableChange(pagination, filters, sorter)
              }
              paginationProps={{
                showSizeChanger: false,
              }}
            />
          ) : (
            <EmptyState
              image={emptyImage}
              title="No Jobs Found"
              primaryButtonProps={{
                label: "New Job",
                onClick: () => setNewJobPane(true),
              }}
            />
          )}
        </div>
      </div>

      <NewJob
        isOpen={newJobPane}
        onClose={() => setNewJobPane(false)}
        orgId={organisationDetail.id}
        initialFocusRef={initialFocusRef}
      />
    </>
  );
};

export default Jobs;

import * as Yup from "yup";
import { MAX_STRING_LENGTH } from "common/constants";

export const INITIAL_VALUE = {
  to: [],
  cc: [],
  bcc: [],
  subject: "",
  body: "",
};

export const VALIDATION_SCHEMA = Yup.object({
  to: Yup.array().min(1, "Recipient email can't be empty."),
  cc: Yup.array().nullable(),
  bcc: Yup.array().nullable(),
  subject: Yup.string()
    .required("Subject can't be empty.")
    .max(
      MAX_STRING_LENGTH,
      `Subject must be at most ${MAX_STRING_LENGTH} characters.`
    ),
});

export const CURRENCY_SYMBOL = {
  AUD: "$",
  USD: "$", // US Dollar
  EUR: "€", // Euro
  CRC: "₡", // Costa Rican Colón
  GBP: "£", // British Pound Sterling
  ILS: "₪", // Israeli New Sheqel
  INR: "₹", // Indian Rupee
  JPY: "¥", // Japanese Yen
  KRW: "₩", // South Korean Won
  NGN: "₦", // Nigerian Naira
  PHP: "₱", // Philippine Peso
  PLN: "zł", // Polish Zloty
  PYG: "₲", // Paraguayan Guarani
  THB: "฿", // Thai Baht
  UAH: "₴", // Ukrainian Hryvnia
  VND: "₫", // Vietnamese Dong
};

export const getEmails = (email, list) => {
  if (email.constructor === Array) {
    return list.filter(recipient => email.includes(recipient.email));
  } else {
    return list.filter(recipient => recipient.email === email);
  }
};

import React, { useState } from "react";
import {
  Button,
  Typography,
  Input,
  Modal,
  Label,
  Alert,
  Toastr
} from "@bigbinary/neetoui";
import { QUILL_TOOLBAR_STYLE, QUILL_KEYBOARD_BINDINGS } from "common/constants";
import { showToastrError } from "common";
import { createRecipient } from "apis/jobs/recipients";
import ReactQuill from "react-quill";
import { THREADS_LIST } from "./constants";
import AttachmentCard from "../AttachmentCard";

const ForwardPreview = ({
  shareOldThreadAlertOpen,
  setShareOldThreadAlertOpen,
  formik,
  sendOldThreadsToNewRecipient,
  buttonLoader,
  recipientEmail,
  jobId,
  threadDetail,
  addRecipientAlertOpen,
  setAddRecipientAlertOpen,
  setCurrentTab,
  setRecipientEmail,
  onClose,
  newThreadId,
  setActiveThred,
}) => {
  const [alertButtonLoader, setAlertButtonLoader] = useState(false);

  const addNewRecipientResponse = async () => {
    try {
      setAlertButtonLoader(true);
      const response = await createRecipient(jobId, threadDetail.id, {
        recipient: {
          email: recipientEmail.email,
          name: recipientEmail.name,
          designation: recipientEmail.designation,
          recipientResourceableType: recipientEmail.resourceable,
          recipientResourceableId: recipientEmail.value,
        },
      });
      Toastr.info(response.data.notice);
      setAddRecipientAlertOpen(false);
      setShareOldThreadAlertOpen(false);
      setCurrentTab(THREADS_LIST.Forward);
      setRecipientEmail();
      setActiveThred(newThreadId);
      onClose()
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setAlertButtonLoader(false);
    }
  };

  return (
    <>
      <Modal
        isOpen={shareOldThreadAlertOpen}
        onClose={() => setShareOldThreadAlertOpen(false)}
        size="fullScreen"
        className="w-screen h-screen rounded-none"
      >
        <Modal.Header>
          <Typography style="h2" className="font-semibold">
            Share Threads
          </Typography>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div
            className="flex flex-col overflow-y-auto forwarded-full-screen-ql-editor"
            style={{
              maxHeight: "calc(100vh - 160px)",
              padding: "0px 24px 0px 24px",
            }}
          >
            <Input
              label="Subject"
              name="subject"
              required={true}
              value={formik.values.subject}
              disabled={true}
            />

            <div className="mt-4">
              <Label className="mb-2" required>
                Body
              </Label>
              <ReactQuill
                value={formik.values.htmlBody}
                onChange={text => formik.setFieldValue("htmlBody", text)}
                modules={{
                  toolbar: QUILL_TOOLBAR_STYLE,
                  keyboard: QUILL_KEYBOARD_BINDINGS,
                }}
                placeholder="Enter your message"
              />
              {formik.touched.htmlBody &&
                formik.errors.htmlBody?.length > 0 && (
                  <p className="text-red-700 text-xs">
                    {formik.errors.htmlBody}
                  </p>
                )}
            </div>

            {formik.values.attachments_attributes?.length > 0 && (
              <div className="grid grid-cols-6 gap-2 mt-3">
                {formik.values.attachments_attributes.map(
                  (attachment, index) => {
                    return (
                      <AttachmentCard
                        key={index}
                        cardUrl={attachment.url}
                        smallUrl={attachment.smallUrl}
                        name={attachment.name}
                        type={attachment.type.split("/")[1]}
                        attachmentId={attachment.id}
                        allowDownload={false}
                      />
                    );
                  }
                )}
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="space-x-2">
          <Button
            label="Send"
            onClick={() => {
              sendOldThreadsToNewRecipient();
            }}
            loading={buttonLoader}
          />
          <Button
            label="Cancel"
            style="text"
            onClick={() => setShareOldThreadAlertOpen(false)}
          />
        </Modal.Footer>
      </Modal>

      <Alert
        isOpen={addRecipientAlertOpen}
        title="Add Recipient Alert"
        onClose={() => {
          setAddRecipientAlertOpen(false);
          setShareOldThreadAlertOpen(false);
        }}
        message="Are you sure you want to add this recipient for future conversation?"
        onSubmit={() => addNewRecipientResponse()}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, Proceed"
        isSubmitting={alertButtonLoader}
      />
    </>
  );
};

export default ForwardPreview;

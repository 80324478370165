import * as Yup from "yup";
import { User, Group } from "@bigbinary/neeto-icons";

export const ACCOUNT_DETAILS_TABS = {
  Profile: {
    icon: User,
    label: "Profile",
  },
  Users: {
    icon: Group,
    label: "Users",
  },
};

export const USERS_INITIAL_VALUE = {
  firstName: "",
  lastName: "",
  title: "",
  email: "",
  access: "",
  locationId: "",
  phoneNumber: "",
};

export const USERS_VALIDATION_SCHEMA = Yup.object({
  firstName: Yup.string().required("First Name can't be empty"),
  lastName: Yup.string().required("Last Name can't be empty"),
  title: Yup.string().required("Title can't be empty"),
  email: Yup.string().email("Invalid email").required("Email can't be empty"),
  access: Yup.string()
    .oneOf(["admin", "team", "super_admin"])
    .required("Access can't be empty"),
  locationId: Yup.string().required("Location can't be empty"),
});

export const ACCESS_LIST = [
  { label: "Admin", value: "admin" },
  { label: "Team", value: "team" },
];

export const XERO_TAX_FORMATS = [
  { label: "AU", value: "AU" },
  { label: "UK", value: "UK" },
  { label: "USA", value: "USA" },
];

import React from "react";
import { Button, Dropdown } from "@bigbinary/neetoui";
import { Delete, Down, MenuHorizontal, Up } from "@bigbinary/neeto-icons";
import { Typography } from "@bigbinary/neetoui";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import EstimateAccordian from "components/Common/EstimateAccordian";
import { moveItem } from "apis/settings/move_items";
import { updateEstimateRevisionItemPosition } from "apis/jobs/estimate_revision_items";
import classNames from "classnames";
import { showToastrError } from "common";
import { sumAmount, numberWithCommas } from "common/helper";
import LineItem from "./LineItem";
import EstimateForm from "./Form";

const EstimateAccordion = ({
  estimateListItems,
  onAccordionClick,
  jobDetail,
  deleteItemClickFunction,
  artistList,
  saveEstimateRecord,
  openedItem,
  isDisable,
  rciList,
  setOpenedParent,
  openedParent,
  revisionId,
  revisionLineItemCatgoryList,
  loadRevisionLineItemCategoryList,
  destroyEstimateRevisionLineItemCategory,
  loadEstimateRevisionItemList,
}) => {
  const { Menu, MenuItem } = Dropdown;

  const moveParentLineItem = async (
    revisionLineItemCategoryId,
    nature,
    place
  ) => {
    try {
      await moveItem(revisionLineItemCategoryId, {
        move_item: {
          resource: "revision_line_item_categories",
          nature: nature,
          place: place,
        },
      });
      loadRevisionLineItemCategoryList();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const rciAttached = parentId => {
    let rciAssociatedRevisionItemIds = rciList
      .map(rci => rci.rciItems)
      .flat()
      .map(item => item.estimateRevisionItemId);
    let childrenIdsOfSpecificParent = estimateListItems
      .filter(item => item.lineItemCategory.id === parentId)
      .map(item => item.id);

    return (
      rciAssociatedRevisionItemIds.filter(item =>
        childrenIdsOfSpecificParent.includes(item)
      )?.length > 0
    );
  };

  const itemPositionUpdated = async (revisionId, id, type, difference) => {
    try {
      await updateEstimateRevisionItemPosition(
        revisionId,
        id,
        type,
        difference
      );
      loadEstimateRevisionItemList();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const placeDifference = result => {
    let sourceIndex = result.source.index;
    let destinationIndex = result.destination.index;
    let placeChangeType = "";
    let difference = 0;

    if (sourceIndex > destinationIndex) {
      placeChangeType = "up";
      difference = sourceIndex - destinationIndex;
    } else {
      placeChangeType = "down";
      difference = destinationIndex - sourceIndex;
    }

    return { placeChangeType: placeChangeType, difference: difference };
  };

  const itemReorderFunction = result => {
    if (!result.destination) return;
    const { placeChangeType, difference } = placeDifference(result);
    itemPositionUpdated(
      revisionId,
      result.draggableId,
      placeChangeType,
      difference
    );
  };

  return (
    <div className="w-full space-y-2">
      {revisionLineItemCatgoryList.map(parent => {
        let children = estimateListItems.filter(
          item => item.lineItemCategory?.id === parent.id
        );
        const totalOfParent = sumAmount(children, "amount");
        return (
          <EstimateAccordian.Item
            key={parent.id}
            draggable={false}
            clickable={false}
            noChildren={children?.length === 0}
            className={classNames(
              ["border neeto-ui-border-gray-300 cursor-default"],
              {
                "bg-white": !isDisable || !rciAttached(parent.id),
              },
              {
                "bg-gray-100": isDisable || rciAttached(parent.id),
              },
              { "neeto-ui-border-primary-500": parent.id === openedParent }
            )}
            isOpen={parent.id === openedParent}
            CustomTitle={({ isOpen }) => {
              return (
                <div className="grid w-full grid-cols-12 gap-4 ml-8">
                  <div className="flex flex-col items-start justify-center col-span-4 space-y-2">
                    <Typography style="h5" weight="semibold">
                      {parent.lineItemCategoryName}
                    </Typography>
                  </div>
                  <div className="col-span-2"></div>
                  <div className="flex self-center justify-end col-span-3 whitespace-no-wrap">
                    <Typography style="h5" weight="semibold">
                      {!isOpen && (
                        <div className="flex items-center py-2 font-semibold">
                          {numberWithCommas(Number(totalOfParent).toFixed(2))}{" "}
                          {jobDetail.currency}
                        </div>
                      )}
                    </Typography>
                  </div>
                  <div className="flex items-end self-end justify-end col-span-2 space-x-2">
                    <Dropdown
                      autoWidth
                      closeOnSelect
                      buttonProps={{
                        size: "small",
                        style: "text",
                      }}
                      strategy="fixed"
                      appendTo={() => document.body}
                      icon={MenuHorizontal}
                      disabled={rciAttached(parent.id)}
                    >
                      <Menu>
                        <MenuItem.Button
                          onClick={() => {
                            moveParentLineItem(parent.id, "higher", null);
                          }}
                        >
                          Move Higher
                        </MenuItem.Button>
                        <MenuItem.Button
                          onClick={() => {
                            moveParentLineItem(parent.id, "lower", null);
                          }}
                        >
                          Move Lower
                        </MenuItem.Button>
                        <MenuItem.Button
                          onClick={() => {
                            moveParentLineItem(parent.id, null, "top");
                          }}
                        >
                          Move to Top
                        </MenuItem.Button>
                        <MenuItem.Button
                          onClick={() => {
                            moveParentLineItem(parent.id, null, "bottom");
                          }}
                        >
                          Move to Bottom
                        </MenuItem.Button>
                      </Menu>
                    </Dropdown>
                    <Button
                      style="text"
                      size="small"
                      icon={isOpen ? Up : Down}
                      iconPosition="right"
                      onClick={() =>
                        setOpenedParent(() => {
                          if (openedParent == parent.id) {
                            setOpenedParent();
                          } else {
                            setOpenedParent(parent.id);
                          }
                        })
                      }
                    />
                  </div>
                  <div className="flex items-center justify-end col-span-1">
                    <Button
                      size="small"
                      style="danger-text"
                      icon={Delete}
                      disabled={isDisable || rciAttached(parent.id)}
                      onClick={() =>
                        destroyEstimateRevisionLineItemCategory(parent.id)
                      }
                    />
                  </div>
                </div>
              );
            }}
          >
            <DragDropContext onDragEnd={itemReorderFunction}>
              <Droppable droppableId="droppable">
                {provided => (
                  <div
                    className="mb-2 space-y-2"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {children.map((revisionItem, index) => {
                      return (
                        <LineItem
                          key={index}
                          deleteItemClickFunction={deleteItemClickFunction}
                          isOpen={openedItem === revisionItem.id}
                          rciAttached={rciList
                            .map(rci => rci.rciItems)
                            .flat()
                            .find(
                              item =>
                                item.estimateRevisionItemId === revisionItem.id
                            )}
                          revisionItem={revisionItem}
                          jobDetail={jobDetail}
                          index={index}
                          isDisable={isDisable}
                          onAccordionClick={onAccordionClick}
                          extraPadding={true}
                        >
                          <EstimateForm
                            itemObject={revisionItem}
                            index={index}
                            artistList={artistList}
                            saveEstimateRecord={saveEstimateRecord}
                            itemArray={estimateListItems}
                            jobDetail={jobDetail}
                            rciAttached={rciList
                              .map(rci => rci.rciItems)
                              .flat()
                              .find(
                                item =>
                                  item.estimateRevisionItemId ===
                                  revisionItem.id
                              )}
                          />
                        </LineItem>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </EstimateAccordian.Item>
        );
      })}
      <DragDropContext onDragEnd={itemReorderFunction}>
        <Droppable droppableId="droppable">
          {provided => (
            <div
              className="mb-2 space-y-2"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {estimateListItems
                .filter(item => item.lineItemCategory?.id === null)
                .map((revisionItem, index) => (
                  <LineItem
                    key={index}
                    deleteItemClickFunction={deleteItemClickFunction}
                    isOpen={openedItem === revisionItem.id}
                    rciAttached={rciList
                      .map(rci => rci.rciItems)
                      .flat()
                      .find(
                        item => item.estimateRevisionItemId === revisionItem.id
                      )}
                    revisionItem={revisionItem}
                    jobDetail={jobDetail}
                    index={index}
                    isDisable={isDisable}
                    onAccordionClick={onAccordionClick}
                  >
                    <EstimateForm
                      itemObject={revisionItem}
                      index={index}
                      artistList={artistList}
                      saveEstimateRecord={saveEstimateRecord}
                      itemArray={estimateListItems}
                      jobDetail={jobDetail}
                      rciAttached={rciList
                        .map(rci => rci.rciItems)
                        .flat()
                        .find(
                          item =>
                            item.estimateRevisionItemId === revisionItem.id
                        )}
                    />
                  </LineItem>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default EstimateAccordion;

import React, { useContext, useState, useEffect } from "react";
import { Delete, Down, Up } from "@bigbinary/neeto-icons";
import { Label, Tag, Typography, Button } from "@bigbinary/neetoui";
import classnames from "classnames";
import { numberWithCommas } from "common/helper";
import EstimateAccordian from "components/Common/EstimateAccordian";
import { EstimateContext } from "../../../Context";
import classNames from "classnames";

const LineItem = ({
  deleteItemClickFunction,
  isOpen,
  revisionItem,
  jobDetail,
  index,
  isDisable,
  greyColoured,
  children,
  onAccordionClick,
  rciAttached,
  extraPadding = false,
}) => {
  const [buttonStatus, setButtonStatus] = useState();
  const formref = useContext(EstimateContext);

  useEffect(() => {
    if (isDisable || rciAttached) {
      setButtonStatus("Locked");
    } else if (isOpen) {
      setButtonStatus("Save");
    } else if (!revisionItem.newItem) {
      setButtonStatus("Saved");
    } else {
      setButtonStatus("New");
    }
  }, [revisionItem, isOpen, isDisable]);

  const OnClickCheckFormStatus = async () => {
    if (isOpen && !isDisable) {
      let error = await formref?.current?.validateForm();
      if (Object.keys(error).length === 0 && error.constructor === Object) {
        await formref?.current?.handleSubmit();
        await onAccordionClick();
      }
    } else if (!isOpen) {
      onAccordionClick(revisionItem.id);
    } else {
      onAccordionClick();
    }
  };

  let style = "danger";
  if (revisionItem.status == "assigned") style = "success";
  if (revisionItem.status == "multiple") style = "primary";

  return (
    <EstimateAccordian.Item
      onClick={() => OnClickCheckFormStatus()}
      isOpen={isOpen}
      key={index}
      draggableIndex={index}
      draggable={!isDisable && !rciAttached}
      draggableId={revisionItem.id}
      className={classnames(
        ["border border-gray-300"],
        { "bg-white": !greyColoured || !rciAttached },
        { "bg-gray-100": greyColoured || rciAttached },
        { "mx-2": extraPadding }
      )}
      CustomTitle={({ isOpen }) => {
        return (
          <div className="grid w-full grid-cols-12 gap-4 ml-8">
            <div className="flex flex-col items-start col-span-3 space-y-2">
              <Typography style="h5" weight="semibold">
                {revisionItem.estimateLineItem.name}
              </Typography>
              {revisionItem?.status ? (
                <Tag
                  label={revisionItem?.status}
                  style={style}
                  className="capitalize"
                />
              ) : (
                ""
              )}
            </div>
            <div className="flex self-center col-span-4">
              {revisionItem.note && (
                <div className="flex w-full space-x-2">
                  <Label>Note:</Label>
                  <Typography style="body2" className="italic truncate">
                    {revisionItem.note}
                  </Typography>
                </div>
              )}
            </div>
            <div className="flex self-center justify-end col-span-2 whitespace-no-wrap">
              {!isOpen && (
                <Typography style="h5" weight="semibold">
                  {numberWithCommas(Number(revisionItem?.amount).toFixed(2))}{" "}
                  {jobDetail.currency}
                </Typography>
              )}
            </div>
            <div className="flex items-center justify-end col-span-2">
              <Button
                size="small"
                style="text"
                label={buttonStatus}
                disabled={isDisable || rciAttached}
                className={classNames({
                  "bg-transparent": isDisable || rciAttached,
                })}
                icon={isOpen ? Up : Down}
                iconPosition="right"
              />
            </div>
            <div className="flex items-center justify-end">
              {revisionItem.estimateLineItemName !== "Usage" && (
                <Button
                  icon={Delete}
                  size="small"
                  style="danger-text"
                  disabled={isDisable || rciAttached}
                  onClick={e => {
                    e.stopPropagation();
                    deleteItemClickFunction(revisionItem.id);
                  }}
                />
              )}
            </div>
          </div>
        );
      }}
    >
      {children}
    </EstimateAccordian.Item>
  );
};

export default LineItem;

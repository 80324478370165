import React from "react";
import * as dayjs from "dayjs";
import Footer from "../Shared/footer";

const BookingFormMailerPreview = ({
  formik,
  docUrl,
  jobDetail,
  companyDetails,
  revision,
  defaultTemplateSetting,
  artistId,
  emailBackground,
}) => {
  const issueDate = jobDetail.artistsJobs.find(el => el.artistId == artistId)
    .issueDate;

  return (
    <div className="text-base bg-white email-body">
      <div className="p-6">
        <div className="flex justify-between mb-8">
          <h2 className="text-xl font-semibold">Booking Form</h2>
          <div className="text-right email-logo-container">
            <img
              src={
                defaultTemplateSetting?.attachments.find(
                  attachment =>
                    attachment.attachable_kind === "email_header_logo"
                )?.src
              }
            />
          </div>
        </div>
        <div
          className="grid grid-cols-2 gap-4"
          style={{ fontFamily: "Courier" }}
        >
          <div className="flex flex-col divide-y divide-black">
            <div className="flex flex-col py-2 space-y-1">
              <p>Job Number</p>
              <p>{jobDetail?.serialNumber}</p>
            </div>
            <div className="flex flex-col py-2 space-y-1">
              <p>Estimate Number</p>
              <p>{revision?.estimateSerialNumber}</p>
            </div>
            <div className="flex flex-col py-2 space-y-1">
              <p>Issued On</p>
              <p>{dayjs(issueDate).format("DD MMMM YYYY")}</p>
            </div>
            <div className="flex flex-col py-2 space-y-1">
              <p>Project</p>
              <p>{jobDetail?.name}</p>
            </div>
          </div>
          <div className="flex flex-col divide-y divide-gray-800">
            <div className="flex flex-col py-2 space-y-1">
              <p>Producer</p>
              <p>{jobDetail?.staffFullName}</p>
            </div>
            <div className="flex flex-col py-2 space-y-1">
              <p>Client</p>
              <p>{jobDetail?.organisationName}</p>
            </div>
            <div className="flex flex-col py-2 space-y-1">
              <p>End Client</p>
              <p>{jobDetail?.parentOrganisation}</p>
            </div>
            <div className="flex flex-col py-2 space-y-1">
              <p>Talent</p>
              <p>{jobDetail?.artistName}</p>
            </div>
          </div>
        </div>
        <hr className="my-8 border-black" />
        <p
          className="text-2xl leading-7 text-black"
          style={{ fontFamily: "Arial" }}
          dangerouslySetInnerHTML={{ __html: formik.values.body }}
        ></p>

        <img src={jobDetail?.staffSignature} className="w-32 mt-6" />

        <div style={{ width: "100%" }} className="mt-16">
          <div style={{ margin: "64px 0 24px 0" }}>
            <div className="mailer-bg">
              <div style={{ padding: "160px 0 0" }}>
                <a
                  href={docUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="block w-full py-4 font-bold text-center text-black no-underline rounded-b-lg"
                  style={{
                    background: "#D6EEFF",
                  }}
                >
                  View Booking Form
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer
        companyDetails={companyDetails}
        account={jobDetail.account}
        footerLogo={
          defaultTemplateSetting?.attachments.find(
            attachment => attachment.attachable_kind === "email_footer_logo"
          )?.src
        }
        emailBackground={emailBackground}
      />
    </div>
  );
};

export default BookingFormMailerPreview;

import axios from "axios";

const getLicensesUrl = (revisionId, id) => {
  if (id) {
    return `/api/v1/job_module/estimate_revisions/${revisionId}/licenses/${id}`;
  }

  return `/api/v1/job_module/estimate_revisions/${revisionId}/licenses`;
};

export const getLicenses = revisionId => {
  return axios.get(getLicensesUrl(revisionId));
};

export const createLicense = (revisionId, payload) => {
  return axios.post(getLicensesUrl(revisionId), payload);
};

export const updateLicense = (revisionId, licenseId, payload) => {
  return axios.put(getLicensesUrl(revisionId, licenseId), payload);
};

export const destroyLicense = (revisionId, licenseId) => {
  return axios.delete(getLicensesUrl(revisionId, licenseId));
};

export const cloneLicenses = (revisionId, payload) => {
  return axios.post(
    `/api/v1/job_module/estimate_revisions/${revisionId}/clone_licenses`,
    payload
  );
};

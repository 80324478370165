import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { numberWithCommas, sumAmount } from "common/helper";
import { BorderedTable } from "../../Shared";

const CostSummary = ({
  title,
  desc,
  invoice,
  ammountWithCurrency,
  costSummaryData,
}) => {
  useEffect(() => {
    AOS.init({
      once: false,
    });
  }, []);

  let samt = 0;
  window.addEventListener("scroll", function () {
    samt <= 10 ? samt++ : AOS.refresh();
  });

  const records = invoice?.invoiceItems.filter(
    item => item.feeType !== "usage" && item.feeType !== "agency_service_fee"
  );
  let lineItemTable = [
    {
      option: {
        hasCheckbox: false,
        hasTitle: false,
        title: "",
        description: "",
      },
      table: {
        heads: ["Item", "Qty", "Metric", "Unit Price", "Cost"],
        rows: records?.map(item => {
          return {
            values: [
              <>
                <strong>{item.name}</strong> <br />
                {item.note}
              </>,
              <>{numberWithCommas(Number(item.quantity).toFixed(2))}</>,
              <>{item.metrics}</>,
              <>
                {item.rateTbc
                  ? "TBC"
                  : item.rateNa
                  ? "NA"
                  : ammountWithCurrency(item.customRate)}{" "}
              </>,
              <>
                {item.rateTbc
                  ? "TBC"
                  : item.rateNa
                  ? "NA"
                  : ammountWithCurrency(item.amount)}
              </>,
            ],
          };
        }),
      },
    },
  ];
  return (
    <div className="flex flex-col items-start justify-between w-full py-12 lg:flex-row">
      <div
        className="flex flex-col items-start justify-start w-full h-full pr-0 lg:pr-12 lg:w-1/3"
        style={{ fontFamily: "Modern Gothic" }}
      >
        <div className="flex flex-col items-start justify-start mb-8 lg:mb-0">
          <h2 className="text-xl font-medium">{title}</h2>
          <p className="mt-6 text-base font-normal leading-5" dangerouslySetInnerHTML={{ __html: desc?.split("\n")
          .join("<br />") }}></p>
        </div>
      </div>
      <div className="flex flex-col items-start justify-start w-full lg:w-2/3">
        <div
          className="flex flex-col items-start justify-start w-full space-y-2"
          style={{ fontFamily: "Modern Gothic" }}
          data-aos="fade-down"
          data-aos-easing="ease-in-out"
        >
          {records?.length > 0 && !invoice?.final && (
            <BorderedTable
              title="Others"
              id={1}
              data={lineItemTable}
              bgColor="#FFEFFF"
            />
          )}

          {invoice?.final && (
            <div
              className="flex flex-col items-center justify-between w-full px-6 py-4 space-y-2 font-medium lg:space-y-0 lg:flex-row rounded_container"
              style={{ backgroundColor: "#FFEFFF" }}
            >
              <span className="text-base lg:text-xl">Creation Fees</span>
              <span className="text-xl">
                {ammountWithCurrency(sumAmount(records, "amount"))}
              </span>
            </div>
          )}

          {costSummaryData.map((data, index) => (
            <div
              key={index}
              className="flex flex-col items-center justify-between w-full px-6 py-4 space-y-2 font-medium lg:space-y-0 lg:flex-row rounded_container"
              style={{ backgroundColor: data.bgColor, color: data.textColor }}
            >
              <span className="text-base lg:text-xl">{data.title}</span>
              <span className="text-xl">{data.amount}</span>
            </div>
          ))}
          {invoice?.final && (
            <div className="flex flex-col items-center justify-between w-full px-6 py-4 mt-2 space-y-2 font-medium text-black bg-white border border-black lg:space-y-0 lg:flex-row rounded_container">
              <span className="text-base lg:text-xl">
                Fees Already Invoiced {100 - invoice?.percentage}%
              </span>
              <span className="text-xl">
                -{ammountWithCurrency(invoice?.alreadyPaidAmount)}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CostSummary;

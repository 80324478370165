import { User, Info } from "@bigbinary/neeto-icons";
import { artistModuleName } from "common/helper";

export const TAB_CONSTANTS = () => {
  const artistModule = artistModuleName();
  return {
    ArtistDetails: {
      label: `${artistModule} Details`,
      icon: User,
    },
    InvoiceDetails: {
      label: "Invoice Details",
      icon: Info,
    },
  };
};

export const TAX_STATUS = [
  {
    label: "GST Registered",
    value: "GST Registered",
  },
  {
    label: "GST Unregistered",
    value: "GST Unregistered",
  },
  {
    label: "VAT Unregistered",
    value: "VAT Unregistered",
  },
  {
    label: "N/A",
    value: "N/A",
  },
];

import React, { useState, useEffect } from "react";
import { Input, Spinner, Select, Switch } from "@bigbinary/neetoui";
import AsyncPaginateSelect from "components/Common/AsyncPaginateSelect";
import { getOrganisations } from "apis/organisations/organisations";
import { getOrganisationTypes } from "apis/settings/organisation_types";
import { getIndustrySectors } from "apis/settings/industry_sectors";
import { getJobTypes } from "apis/settings/job_types";
import { getActiveCrmActionTags } from "apis/settings/crm_action_tags";
import { getCountries } from "apis/settings/countries";
import { getStates } from "apis/settings/states";
import { useUserState } from "contexts/user";
import { getStaffs } from "apis/staffs/staffs";
import { showToastrError } from "common";
import { dropDownListGenerator, removeDuplicacy, sleep } from "common/helper";
import { ACTION_TAG_STATUS_LIST } from "./constants";

const Form = ({ ref, formik }) => {
  const { user } = useUserState();
  const [initialDataLoad, setInitialDataLoad] = useState(true);
  const [organisationOptions, setOrganisationOptions] = useState([]);
  const [staffOptions, setStaffOptions] = useState([]);
  const [organisationTypeOptions, setOrganisationTypeOptions] = useState([]);
  const [jobTypeOptions, setJobTypeOptions] = useState([]);
  const [industrySectorOptions, setIndustrySectiorOptions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [orgPage, setOrgPage] = useState(1);
  const [staffPage, setStaffPage] = useState(1);
  const [orgInitial, setOrgInitial] = useState(true);
  const [staffInitial, setStaffInitial] = useState(true);
  const [asyncDataLoad, setAsyncDataLoad] = useState(true);
  const [crmActionTagList, setCrmActionTagList] = useState([]);

  useEffect(() => {
    formik.resetForm();
    loadInitialData();

    if (user && !formik.values.id) {
      formik.setFieldValue("userId", user.id);
    }
  }, []);

  useEffect(async () => {
    await loadStateList();
  }, [formik.values.countries]);

  useEffect(() => {
    if (
      staffInitial &&
      formik.values.staffIds?.length > 0 &&
      staffOptions.length > 0
    ) {
      searchStaff(formik.values.staffIds);
      setStaffInitial(false);
    }
  }, [formik.values.staffIds, staffOptions]);

  useEffect(() => {
    if (
      formik.values.organisationIds?.length > 0 &&
      orgInitial &&
      organisationOptions.length > 0
    ) {
      searchOrganisation(formik.values.organisationIds);
      setOrgInitial(false);
    }
  }, [formik.values.organisationIds, organisationOptions]);

  const loadInitialData = async () => {
    await loadJobTypeList();
    await loadCountryList();
    await loadOrganisationTypeList();
    await loadCrmActionTagListResponse();
    await setInitialDataLoad(false);
    await loadOrganisationList();
    await loadStaffList();
    await loadIndustrySectorList();
    await setAsyncDataLoad(false);
  };

  const loadOrganisationTypeList = async () => {
    try {
      const response = await getOrganisationTypes();
      setOrganisationTypeOptions(
        dropDownListGenerator(response.data.organisationTypes)
      );
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadJobTypeList = async () => {
    try {
      const response = await getJobTypes();
      setJobTypeOptions(dropDownListGenerator(response.data.jobTypes));
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadCountryList = async () => {
    try {
      const response = await getCountries();
      setCountries(response.data.countries);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadStateList = async () => {
    try {
      const response = await getStates(
        formik.values.countries?.join("<>") || ""
      );
      setStates(response.data.states);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadOrganisationList = async search => {
    await sleep(1000);

    try {
      let filteredOptions;
      let totalRecords;

      if (!search) {
        const response = await getOrganisations("", orgPage);
        setOrganisationOptions([
          ...organisationOptions,
          ...response.data.organisations,
        ]);
        totalRecords = response.data.totalRecords;
        setOrgPage(orgPage + 1);
        filteredOptions = response.data.organisations;
      } else {
        const searchLower = search.toLowerCase();

        const response = await getOrganisations(searchLower, orgPage);
        setOrganisationOptions([
          ...organisationOptions,
          ...response.data.organisations,
        ]);
        setOrgPage(orgPage + 1);
        filteredOptions = response.data.organisations;
        totalRecords = filteredOptions.length;
      }
      return {
        options: dropDownListGenerator(removeDuplicacy(filteredOptions)),
        hasMore: filteredOptions.length === totalRecords ? false : true,
      };
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadStaffList = async search => {
    await sleep(1000);

    try {
      let filteredOptions;
      let totalRecords;

      if (!search) {
        const response = await getStaffs("", staffPage);
        setStaffOptions(
          removeDuplicacy([...staffOptions, ...response.data.staffs])
        );
        totalRecords = response.data.totalRecords;
        setStaffPage(staffPage + 1);
        filteredOptions = response.data.staffs;
      } else {
        const searchLower = search.toLowerCase();

        const response = await getStaffs(searchLower, staffPage);
        setStaffOptions([...staffOptions, ...response.data.staffs]);
        setStaffPage(staffPage + 1);
        filteredOptions = response.data.staffs;
        totalRecords = filteredOptions.length;
      }

      return {
        options: dropDownListGenerator(removeDuplicacy(filteredOptions)),
        hasMore: filteredOptions.length === totalRecords ? false : true,
      };
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const searchStaff = async staffIds => {
    try {
      const searchResponse = await getStaffs("", 1, staffIds);
      setStaffOptions([...staffOptions, ...searchResponse.data.staffs]);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const searchOrganisation = async organisationIds => {
    try {
      const searchResponse = await getOrganisations("", 1, organisationIds);
      setOrganisationOptions([
        ...organisationOptions,
        ...searchResponse.data.organisations,
      ]);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadCrmActionTagListResponse = async () => {
    try {
      const crmActionTagListResponse = await getActiveCrmActionTags();
      setCrmActionTagList(
        dropDownListGenerator(crmActionTagListResponse.data.crmActionTags || [])
      );
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadIndustrySectorList = async () => {
    try {
      const response = await getIndustrySectors();
      setIndustrySectiorOptions(
        dropDownListGenerator(response.data.industrySectors)
      );
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  if (initialDataLoad) {
    return (
      <div className="flex justify-center w-full h-full">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full pb-8 space-y-6">
      <Input
        required={true}
        label="Name"
        name="name"
        ref={ref}
        onChange={formik.handleChange}
        value={formik.values.name}
        error={
          Boolean(formik.touched.name && formik.errors.name) &&
          formik.errors.name
        }
        {...formik.getFieldProps("name")}
      />

      <AsyncPaginateSelect
        label="Producer"
        cacheOptions
        isDisabled={asyncDataLoad}
        isLoading={asyncDataLoad}
        id="userId"
        placeholder="Select Producer"
        value={dropDownListGenerator(
          removeDuplicacy(staffOptions),
          "name"
        ).find(staff => formik.values.userId === staff.value)}
        required={true}
        error={
          Boolean(formik.touched.userId && formik.errors.userId) &&
          formik.errors.userId
        }
        onChange={staff => {
          formik.setFieldValue("userId", staff.value);
          formik.setFieldTouched("userId", true);
        }}
        loadOptions={loadStaffList}
      />

      <AsyncPaginateSelect
        label="Client"
        isDisabled={asyncDataLoad}
        isLoading={asyncDataLoad}
        isMulti
        cacheOptions
        loadOptions={loadOrganisationList}
        value={
          dropDownListGenerator(
            removeDuplicacy(organisationOptions)
          ).filter(org => formik.values.organisationIds?.includes(org.value)) ||
          null
        }
        onChange={orgs => {
          if (orgs?.length > 0) {
            let orgIds = orgs.map(org => org.value);
            formik.setFieldValue("organisationIds", orgIds);
          } else {
            formik.setFieldValue("organisationIds", []);
          }
          formik.setFieldTouched("organisationIds", true);
        }}
        name="organisationId"
        id="organisationId"
        placeholder="Select a Organisation"
        error={
          Boolean(
            formik.touched.organisationIds && formik.errors.organisationIds
          ) && formik.errors.organisationIds
        }
      />

      <Select
        label="Organisation Types"
        name="organisationTypeIds"
        id="organisationTypeIds"
        className="no-color-badge"
        isMulti
        placeholder="Select a Organisation type"
        value={organisationTypeOptions.filter(organisationType =>
          formik.values.organisationTypeIds?.includes(organisationType.value)
        )}
        error={
          Boolean(
            formik.touched.organisationTypeIds &&
              formik.errors.organisationTypeIds
          ) && formik.errors.organisationTypeIds
        }
        options={organisationTypeOptions}
        onChange={types => {
          if (types?.length > 0) {
            formik.setFieldValue(
              "organisationTypeIds",
              types.map(type => type.value)
            );
          } else {
            formik.setFieldValue("organisationTypeIds", []);
          }
          formik.setFieldTouched("organisationTypeIds", true);
        }}
      />

      <Select
        label="Industry Sectors"
        name="industrySectorIds"
        id="industrySectorIds"
        className="no-color-badge"
        isMulti
        placeholder="Select Industry Sector"
        value={industrySectorOptions.filter(sector =>
          formik.values.industrySectorIds?.includes(sector.value)
        )}
        error={
          Boolean(
            formik.touched.industrySectorIds && formik.errors.industrySectorIds
          ) && formik.errors.industrySectorIds
        }
        options={industrySectorOptions}
        onChange={sector => {
          if (sector?.length > 0) {
            let sectorIds = sector.map(sector => sector.value);
            formik.setFieldValue("industrySectorIds", sectorIds);
          } else {
            formik.setFieldValue("industrySectorIds", []);
          }
          formik.setFieldTouched("industrySectorIds", true);
        }}
      />

      <AsyncPaginateSelect
        isDisabled={asyncDataLoad}
        isLoading={asyncDataLoad}
        label="Staffs"
        isMulti
        id="staffs"
        placeholder="Select Staffs"
        value={dropDownListGenerator(
          removeDuplicacy(staffOptions),
          "name"
        ).filter(staff => formik.values.staffIds?.includes(staff.value))}
        isClearable={true}
        error={
          Boolean(formik.touched.staffIds && formik.errors.staffIds) &&
          formik.errors.staffIds
        }
        onChange={staffs => {
          if (staffs?.length > 0) {
            let staffIds = staffs.map(staff => staff.value);
            formik.setFieldValue("staffIds", staffIds);
          } else {
            formik.setFieldValue("staffIds", []);
          }
          formik.setFieldTouched("staffIds", true);
        }}
        loadOptions={loadStaffList}
      />

      <Select
        label="Country"
        name="countries"
        id="countries"
        className="no-color-badge"
        isMulti
        placeholder="Select a Country"
        value={countries.filter(country =>
          formik.values.countries?.includes(country.value)
        )}
        error={
          Boolean(formik.touched.countries && formik.errors.countries) &&
          formik.errors.countries
        }
        options={countries}
        onChange={countries => {
          if (countries?.length > 0) {
            let countryValues = countries.map(country => country.value);
            formik.setFieldValue("countries", countryValues);
          } else {
            formik.setFieldValue("countries", []);
          }
          formik.setFieldTouched("countries", true);
        }}
      />

      <Select
        label="State"
        name="states"
        id="states"
        className="no-color-badge"
        isMulti
        placeholder="Select a State"
        value={states.filter(state =>
          formik.values.states?.includes(state.value)
        )}
        error={
          Boolean(formik.touched.states && formik.errors.states) &&
          formik.errors.states
        }
        options={states}
        onChange={states => {
          if (states?.length > 0) {
            let stateValues = states.map(state => state.value);
            formik.setFieldValue("states", stateValues);
          } else {
            formik.setFieldValue("states", []);
          }
          formik.setFieldTouched("states", true);
        }}
      />

      <Input
        label="Position"
        name="contactPositions"
        onChange={e => {
          formik.setFieldValue("contactPositions", e.target.value);
        }}
        value={formik.values.contactPositions}
      />

      <Select
        label="Job Types"
        name="jobTypeIds"
        id="jobTypeIds"
        className="no-color-badge"
        isMulti
        placeholder="Select a Job type"
        value={jobTypeOptions.filter(jobType =>
          formik.values.jobTypeIds?.includes(jobType.value)
        )}
        error={
          Boolean(formik.touched.jobTypeIds && formik.errors.jobTypeIds) &&
          formik.errors.jobTypeIds
        }
        options={jobTypeOptions}
        onChange={types => {
          if (types?.length > 0) {
            let typeIds = types.map(type => type.value);
            formik.setFieldValue("jobTypeIds", typeIds);
          } else {
            formik.setFieldValue("jobTypeIds", []);
          }
          formik.setFieldTouched("jobTypeIds", true);
        }}
      />

      <Select
        label="Activity Tag (1 Max)"
        name="actionTagIds"
        id="actionTagIds"
        className="no-color-badge"
        isMulti
        placeholder="Select an Action tag"
        value={crmActionTagList.filter(actionTag =>
          formik.values.actionTagIds?.includes(actionTag.value)
        )}
        error={
          Boolean(formik.touched.actionTagIds && formik.errors.actionTagIds) &&
          formik.errors.actionTagIds
        }
        options={crmActionTagList}
        onChange={tags => {
          if (tags?.length > 0) {
            let tagIds = tags.map(tag => tag.value);
            formik.setFieldValue("actionTagIds", tagIds);
          } else {
            formik.setFieldValue("actionTagIds", []);
          }
          formik.setFieldTouched("actionTagIds", true);
        }}
      />

      <Select
        label="Activity Tag Status"
        name="actionTagStatuses"
        id="actionTagStatuses"
        className="no-color-badge"
        isMulti
        placeholder="Select an Action tag status"
        value={ACTION_TAG_STATUS_LIST.filter(status =>
          formik.values.actionTagStatuses?.includes(status.value)
        )}
        error={
          Boolean(
            formik.touched.actionTagStatuses && formik.errors.actionTagStatuses
          ) && formik.errors.actionTagStatuses
        }
        options={ACTION_TAG_STATUS_LIST}
        onChange={statuses => {
          if (statuses?.length > 0) {
            let statusIds = statuses.map(status => status.value);
            formik.setFieldValue("actionTagStatuses", statusIds);
          } else {
            formik.setFieldValue("actionTagStatuses", []);
          }
          formik.setFieldTouched("actionTagStatuses", true);
        }}
      />

      <Input
        type="number"
        label="Days Since Last Activity"
        name="daysSinceLastActivity"
        onChange={formik.handleChange}
        value={formik.values.daysSinceLastActivity}
        error={
          Boolean(
            formik.touched.daysSinceLastActivity &&
              formik.errors.daysSinceLastActivity
          ) && formik.errors.daysSinceLastActivity
        }
        {...formik.getFieldProps("daysSinceLastActivity")}
      />

      <Switch
        label="Default"
        checked={formik.values.default}
        onChange={() => {
          formik.setFieldValue("default", !formik.values.default);
        }}
      />

      <Switch
        label="Search inactive contacts"
        checked={formik.values.inactiveContactSearch}
        onChange={() => {
          formik.setFieldValue(
            "inactiveContactSearch",
            !formik.values.inactiveContactSearch
          );
        }}
      />
      <Switch
        label="Search unassigned contacts"
        checked={formik.values.notAssignedContactSearch}
        onChange={() => {
          formik.setFieldValue(
            "notAssignedContactSearch",
            !formik.values.notAssignedContactSearch
          );
        }}
      />
    </div>
  );
};

export default Form;

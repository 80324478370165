import axios from "axios";

export const newXeroSession = () => {
  return axios.get(`/api/v1/settings/xero_sessions/new`);
};

export const destroyXeroSession = () => {
  return axios.delete(`/api/v1/settings/xero_sessions`);
};

export const getXeroSession = () => {
  return axios.get(`/api/v1/settings/xero_sessions`);
};

export const createXeroSession = payload => {
  return axios.post(`/api/v1/settings/xero_sessions`, payload);
};

export const pushInvoiceToXero = invoiceId => {
  return axios.post(
    `/api/v1/invoice_module/invoices/${invoiceId}/push_to_xeros`
  );
};

export const pushRciToXero = rciId => {
  return axios.post(
    `/api/v1/job_module/rcis_module/rcis/${rciId}/push_to_xeros`
  );
};

import axios from "axios";

const getKillFeesUrl = (jobId, id) => {
  if (id) {
    return `/api/v1/job_module/jobs/${jobId}/kill_fees/${id}`;
  }

  return `/api/v1/job_module/jobs/${jobId}/kill_fees`;
};

export const getKillFees = jobId => {
  return axios.get(getKillFeesUrl(jobId));
};

export const destroyKillFee = (jobId, id) => {
  return axios.delete(getKillFeesUrl(jobId, id));
};

export const updateKillFeePosition = (jobId, id, type, difference) => {
  return axios.put(
    `${getKillFeesUrl(
      jobId,
      id
    )}/change_position?type=${type}&difference=${difference}`
  );
};

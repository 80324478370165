import React, { useState, useEffect, useRef } from "react";
import { Filter } from "@bigbinary/neeto-icons";
import {
  Button,
  Spinner,
  Typography,
  Dropdown,
  Table,
  Callout,
} from "@bigbinary/neetoui";
import { Container, Header, SubHeader } from "@bigbinary/neetoui/layouts";
import * as dayjs from "dayjs";
import { getInvoices } from "apis/invoices/invoices";
import { useUserState } from "contexts/user";
import { showToastrError } from "common";
import {
  DEFAULT_PAGE_SIZE,
  numberWithCommas,
  getRandomNotFoundImage,
} from "common/helper";
import { COLUMN_DATA } from "./constants";
import * as R from "ramda";
import useDebounce from "common/debounce";
import InvoiceFilter from "./InvoiceFilter";
import EmptyState from "components/Common/EmptyState";
import LocationDropdown from "./../LocationDropdown";

const InvoiceListing = () => {
  const { user } = useUserState();
  const inputRef = useRef(null);
  const [searchParams, setSearchParams] = useState();
  const debouncedSearchTerm = useDebounce(searchParams, 500);
  const [invoiceList, setInvoiceList] = useState([]);
  const [invoiceListLoading, setInvoiceListLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [unpaidInvoicesCount, setUnpaidInvoicesCount] = useState(0);
  const [totalUnpaidAmount, setTotalUnpaidAmount] = useState(0);
  const [xeroTokenExpired, setXeroTokenExpired] = useState(false);
  const [preferredLocation, setPreferredLocation] = useState();
  const [preferredPeriod, setPreferredPeriod] = useState("All Time");
  const [sortProps, setSortProps] = useState();
  const [pageIndex, setPageIndex] = useState();
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [filterPane, setFilterPane] = useState(false);
  const [statusFilter, setStatusFilter] = useState();
  const [amountFilter, setAmountFilter] = useState();
  const [initial, setInitial] = useState(true);
  const { Menu, MenuItem } = Dropdown;
  const [emptyImage, setEmptyImage] = useState();

  useEffect(() => {
    setEmptyImage(getRandomNotFoundImage());
  }, []);

  useEffect(() => {
    if (user) {
      setPreferredLocation(user.location_id);
    }
  }, [user]);

  useEffect(() => {
    if (!R.isNil(preferredLocation) && !R.isEmpty(preferredLocation)) {
      loadInvoiceListResponse(true);
    }
  }, [preferredLocation]);

  useEffect(() => {
    if (pageIndex && !initial) {
      loadInvoiceListResponse();
    }
  }, [pageIndex]);

  useEffect(() => {
    if (sortProps?.column && !initial) {
      loadInvoiceListResponse();
    }
  }, [sortProps]);

  useEffect(() => {
    if (debouncedSearchTerm != undefined) {
      loadInvoiceListResponse();
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (!R.isNil(statusFilter?.value)) {
      loadInvoiceListResponse();
    }
  }, [statusFilter]);

  useEffect(() => {
    if (!R.isNil(amountFilter)) {
      loadInvoiceListResponse();
    }
  }, [amountFilter]);

  useEffect(() => {
    if (preferredPeriod && !initial) {
      loadInvoiceListResponse();
    }
  }, [preferredPeriod]);

  const loadInvoiceListResponse = async (load = false) => {
    if (load) {
      setInvoiceListLoading(true);
    }

    try {
      const response = await getInvoices(
        searchParams,
        sortProps,
        pageIndex || 1,
        DEFAULT_PAGE_SIZE,
        statusFilter?.value,
        amountFilter,
        preferredLocation === "All" ? "" : preferredLocation,
        preferredPeriod === "All Time"
          ? ""
          : preferredPeriod === "7 days"
          ? dayjs().subtract(7, "day").format("YYYY-MM-DD")
          : dayjs().subtract(preferredPeriod, "month").format("YYYY-MM-DD")
      );
      setInvoiceList(response.data.invoices);
      setUnpaidInvoicesCount(response.data.unpaidInvoicesCount);
      setTotalUnpaidAmount(response.data.totalUnpaidAmount);
      setTotalRecords(response.data.totalRecords);
      setXeroTokenExpired(response.data.xeroTokenExpired);
      setInvoiceListLoading(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  if (invoiceListLoading) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <Container isHeaderFixed>
      <Header
        title="Invoices"
        searchProps={{
          value: searchParams,
          onChange: e => setSearchParams(e.target.value),
          clear: () => setSearchParams(""),
        }}
        actionBlock={
          <LocationDropdown
            location={preferredLocation}
            setLocation={setPreferredLocation}
          />
        }
      />
      <SubHeader
        className="px-6"
        leftActionBlock={
          <Typography style="h4" component="h4" weight="semibold">
            {totalRecords > 1
              ? `${totalRecords} Invoices`
              : `${totalRecords} Invoice`}
          </Typography>
        }
        rightActionBlock={
          <div className="flex flex-row space-x-3">
            <Dropdown
              label={
                preferredPeriod === "All Time"
                  ? "All Time"
                  : preferredPeriod === "7 days"
                  ? `Last 7 days`
                  : `Last ${preferredPeriod} months`
              }
              buttonStyle="text"
              position="bottom-end"
              closeOnSelect
            >
              <Menu>
                <MenuItem.Button
                  onClick={() => {
                    setInitial(false);
                    setPreferredPeriod("7 days");
                  }}
                >
                  Last 7 days
                </MenuItem.Button>
                <MenuItem.Button
                  onClick={() => {
                    setInitial(false);
                    setPreferredPeriod(4);
                  }}
                >
                  Last 4 months
                </MenuItem.Button>
                <MenuItem.Button
                  onClick={() => {
                    setInitial(false);
                    setPreferredPeriod(6);
                  }}
                >
                  Last 6 months
                </MenuItem.Button>
                <MenuItem.Button
                  onClick={() => {
                    setInitial(false);
                    setPreferredPeriod(12);
                  }}
                >
                  Last 12 months
                </MenuItem.Button>
                <MenuItem.Button
                  onClick={() => {
                    setInitial(false);
                    setPreferredPeriod(24);
                  }}
                >
                  Last 24 months
                </MenuItem.Button>
                <MenuItem.Button
                  onClick={() => {
                    setInitial(false);
                    setPreferredPeriod(36);
                  }}
                >
                  Last 36 months
                </MenuItem.Button>
                <MenuItem.Button
                  onClick={() => {
                    setInitial(false);
                    setPreferredPeriod("All Time");
                  }}
                >
                  All Time
                </MenuItem.Button>
              </Menu>
            </Dropdown>
            <Button
              style="secondary"
              icon={Filter}
              label="Filter"
              iconPosition="left"
              onClick={() => setFilterPane(true)}
            />
          </div>
        }
      />
      {!R.isNil(xeroTokenExpired) && !xeroTokenExpired && (
        <div className="px-6 w-full">
          <Callout style="info" className="mb-4">
            Xero Logged in!
          </Callout>
        </div>
      )}

      <div
        className="w-full px-6 mb-6"
        style={
          !R.isNil(xeroTokenExpired) && !xeroTokenExpired
            ? { height: "calc(100vh - 137px - 53px - 24px - 80px)" }
            : { height: "calc(100vh - 137px - 53px - 50px)" }
        }
      >
        {!R.isEmpty(invoiceList) ? (
          <Table
            fixedHeight
            columnData={COLUMN_DATA}
            rowData={invoiceList}
            totalCount={totalRecords}
            currentPageNumber={pageIndex}
            defaultPageSize={pageSize}
            handlePageChange={(page, pageSize) => {
              setInitial(false);
              setPageIndex(page);
              setPageSize(pageSize);
            }}
            onChange={(pagination, filters, sorter) => {
              setInitial(false);
              setSortProps(sorter);
            }}
            paginationProps={{
              showSizeChanger: false,
            }}
          />
        ) : (
          <EmptyState
            image={emptyImage}
            title="No Invoices Found"
            description="We couldn’t find any invoice. Try creating one."
          />
        )}
      </div>
      <div
        className="fixed bottom-0 flex justify-between px-6 py-4 text-right bg-white border-t neeto-ui-border-gray-200"
        style={{ width: "calc(100vw - 224px)" }}
      >
        <div className="flex items-center space-x-2">
          <Typography style="h4" className="neeto-ui-text-gray-800">
            Total Unpaid Invoices:
          </Typography>
          <Typography
            style="h4"
            weight="bold"
            className="neeto-ui-text-error-600"
          >
            {numberWithCommas(unpaidInvoicesCount)}
          </Typography>
        </div>
        <div className="flex items-center space-x-2">
          <Typography style="h4" className="neeto-ui-text-gray-800">
            Total Outstanding Amount:
          </Typography>
          <Typography
            style="h4"
            weight="bold"
            className="neeto-ui-text-error-600"
          >
            {numberWithCommas(Number(totalUnpaidAmount).toFixed(2))}
          </Typography>
        </div>
      </div>
      <InvoiceFilter
        filterPane={filterPane}
        setFilterPane={setFilterPane}
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
        amountFilter={amountFilter}
        setAmountFilter={setAmountFilter}
        inputRef={inputRef}
      />
    </Container>
  );
};

export default InvoiceListing;

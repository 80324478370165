import axios from "axios";
import { DEFAULT_PAGE_SIZE } from "common/helper";

export const getContactPositions = (
  searchParams,
  pageIndex,
  positions = ""
) => {
  let searchQuery = `q[position_array]=${positions}`;
  let titleQuery = `q[title_i_cont_any]=${searchParams}`;
  let paginationQuery = `page=${pageIndex}&per_page=${DEFAULT_PAGE_SIZE}`;
  let query = paginationQuery;
  if (positions.length > 0) query += `&${searchQuery}`;
  if (searchParams.length > 0) query += `&${titleQuery}`;

  return axios.get(`/api/v1/settings/contact_positions?${query}`);
};

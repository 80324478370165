import React, { useEffect, useState } from "react";

const HeaderImage = ({ jobDetail, emailBackground }) => {
  const [templateBannerImgSrc, setTemplateBannerImgSrc] = useState("");

  useEffect(() => {
    if (jobDetail?.logoOnlyTemplate) {
      setTemplateBannerImgSrc(
        jobDetail?.account.attachments?.find(
          attachment => attachment.attachable_kind === "large_app_logo"
        )?.src
      );
    } else {
      if (jobDetail?.overrideHeaderImage?.src) {
        setTemplateBannerImgSrc(jobDetail.overrideHeaderImage?.src);
      } else {
        setTemplateBannerImgSrc(
          emailBackground?.attachments?.find(
            attachment => attachment.attachable_kind === "header"
          )?.src
        );
      }
    }
  }, [emailBackground, jobDetail]);

  return (
    <>
      {jobDetail?.logoOnlyTemplate ? (
        <div
          className="flex flex-col items-center justify-center w-full"
          style={{ height: "500px" }}
        >
          <img src={templateBannerImgSrc} className="w-auto h-60" />
        </div>
      ) : (
        <img
          src={templateBannerImgSrc}
          className="object-cover w-full h-full"
        />
      )}
    </>
  );
};

export default HeaderImage;

import React, { useEffect, useState } from "react";
import { Input } from "@bigbinary/neetoui";
import { countries } from "countries-list";
import {
  isValidPhoneNumber,
  parsePhoneNumber,
  ParseError,
} from "libphonenumber-js";

import CountrySelect from "./CountrySelect";
import { humanize } from "common/helper";

const CountryPhone = ({
  formik,
  required,
  selectedCountry,
  attribute = "phoneNumber",
}) => {
  const [orgPhone, setOrgPhone] = useState("");
  const [countryState, setCountryState] = useState("");
  const [codeState, setCodeState] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorState, setErrorState] = useState(false);

  useEffect(() => {
    if (selectedCountry) {
      setCodeState(selectedCountry.phone);

      if (
        selectedCountry.value == "United States" ||
        selectedCountry.name == "United States"
      ) {
        setCountryState("US");
      } else {
        handleCountryCodeChange(selectedCountry.phone);
      }
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (isValidPhoneNumber(phoneNumber, countryState)) {
      formik.setFieldValue(attribute, orgPhone);
    } else {
      formik.setFieldValue(attribute, "");
    }
  }, [orgPhone]);

  useEffect(() => {
    if (formik.values[attribute]?.length > 1) {
      try {
        const phoneNumber = parsePhoneNumber(
          formik.values[attribute][0] === "+"
            ? `${formik.values[attribute]}`
            : `+${formik.values[attribute]}`
        );
        setPhoneNumber(phoneNumber?.nationalNumber);
        setCodeState(phoneNumber?.countryCallingCode);
        setCountryState(phoneNumber?.country || "AU");
      } catch (error) {
        if (error instanceof ParseError) {
          setErrorState(false);
          let countryWithMatchName = Object.entries(countries).find(item => {
            return (
              item[0].toLowerCase() === formik.values.country?.toLowerCase() ||
              item[1].name.toLowerCase() ===
                formik.values.country?.toLowerCase()
            );
          }) || ["AU"];
          const phoneNumber = parsePhoneNumber(
            `${formik.values[attribute]}`.replace("+", ""),
            countryWithMatchName[0]
          );
          setPhoneNumber(phoneNumber.nationalNumber);
          setCodeState(phoneNumber.countryCallingCode);
          setCountryState(phoneNumber.country);
        } else {
          throw error;
        }
      }
    } else if (formik.values[attribute] === null) {
      setPhoneNumber("");
    }
  }, [formik.values[attribute], formik.values.country]);

  useEffect(() => {
    let error = isValidPhoneNumber(phoneNumber, countryState);

    phoneNumber.length > 1
      ? setErrorState(error)
      : required
      ? setErrorState(false)
      : setErrorState(true);

    if (!required && phoneNumber.length === 0) {
      setOrgPhone("");
    } else {
      setOrgPhone(`${codeState}${phoneNumber}`);
    }
  }, [countryState, codeState, phoneNumber]);

  const handleCountryCodeChange = value => {
    const country = Object.entries(countries).find(el => el[1].phone == value);
    country && setCountryState(country[0]);
  };

  const onCountryChange = country => {
    setCountryState(country.name);
    setCodeState(country.phone);
  };

  const onPhoneChange = value => {
    setPhoneNumber(value);
  };

  const onCodeChange = value => {
    setCodeState(value);

    handleCountryCodeChange(value);
  };

  return (
    <div className="grid w-full grid-cols-6 gap-4">
      <CountrySelect
        label="Country"
        onCountryChange={onCountryChange}
        country={countryState}
      />
      <Input
        label="Code"
        value={codeState}
        onChange={e => onCodeChange(e.target.value)}
      />
      <Input
        className="col-span-4"
        label={humanize(attribute)}
        value={phoneNumber}
        onChange={e => onPhoneChange(e.target.value)}
        required={required}
        error={
          (phoneNumber && !errorState) ||
          (Boolean(formik.touched[attribute] && formik.errors[attribute]) &&
            formik.errors[attribute])
        }
      />
    </div>
  );
};

export default CountryPhone;

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { setAuthHeaders } from "apis/axios";
import { useHistory } from "react-router-dom";
import { Spinner } from "@bigbinary/neetoui";
import { useAuthDispatch } from "contexts/auth";
import { useUserDispatch, useUserState } from "contexts/user";
import authenticationApi from "apis/authentication";

const Redirect = () => {
  const { env_variables } = useUserState();
  const location = useLocation();
  const token = location?.search?.split("?token=")[1];
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  const authDispatch = useAuthDispatch();
  const userDispatch = useUserDispatch();

  useEffect(() => {
    handleSubmit();
  }, []);

  const handleSubmit = async () => {
    try {
      const {
        data: { auth_token, user, is_admin, env_variables },
      } = await authenticationApi.auth({
        user: {
          token,
        },
      });
      authDispatch({
        type: "LOGIN",
        payload: {
          auth_token,
          email: user.email,
          is_admin,
          rememberMe: user.remember_me,
        },
      });
      userDispatch({
        type: "SET_USER",
        payload: { user: user, env_variables: env_variables },
      });
      setAuthHeaders();
      history.push("/");
    } catch (error) {
      window.location.href = `${window.location.protocol}//auth.${env_variables.root_domain}/login`;
    } finally {
      setLoading(false);
    }
  };

  {
    return loading ? <Spinner /> : null;
  }
};

export default Redirect;

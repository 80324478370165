import axios from "axios";

const getDefaultUsageUrl = id => {
  if (id) {
    return `/api/v1/settings/licenses/${id}`;
  }

  return `/api/v1/settings/licenses`;
};

export const getDefaultUsages = () => {
  return axios.get(getDefaultUsageUrl());
};

export const getDefaultUsage = id => {
  return axios.get(getDefaultUsageUrl(id));
};

export const createDefaultUsage = payload => {
  return axios.post(getDefaultUsageUrl(), payload);
};

export const updateDefaultUsage = (id, payload) => {
  return axios.put(getDefaultUsageUrl(id), payload);
};

export const destroyDefaultUsage = id => {
  return axios.delete(getDefaultUsageUrl(id));
};

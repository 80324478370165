import { useEffect } from "react";

const WindowOpener = ({ url, bridge, handleOnClose }) => {
  let popup = null;
  let timer = null;
  let name = "Xero Login";
  let opts = `dependent=${1}, alwaysOnTop=${1}, alwaysRaised=${1}, alwaysRaised=${1}, width=${800}, height=${650}`;

  const watcher = () => {
    if (popup === null) {
      clearInterval(timer);
      timer = null;
    } else if (popup !== null && !popup.closed) {
      popup.focus();
    } else if (popup !== null && popup.closed) {
      clearInterval(timer);
      handleOnClose(false);
      window.focus();
      timer = null;
      popup = null;
    }
  };

  window.addEventListener(
    "message",
    event => {
      bridge(event.data);
    },
    false
  );

  const onClickHandler = () => {
    if (popup && !popup.closed) {
      popup.focus();
      return;
    }

    popup = window.open(url, name, opts);

    if (timer === null) {
      timer = setInterval(watcher, 2000);
    }
  };

  useEffect(() => {
    onClickHandler();
  }, []);

  return null;
};

export default WindowOpener;

import React from "react";
import { Input, Callout, Select } from "@bigbinary/neetoui";
import { BANK_FORMAT_LIST } from "./constants";

const BankDetail = ({ formik }) => {
  return (
    <div className="flex flex-col w-full pb-8 mt-6 space-y-6">
      <Callout style="info">
        We need these mandatory details for issuing an invoice!
      </Callout>
      <Select
        label="Bank Format"
        name="bankFormat"
        id="bankFormat"
        placeholder="Select a Format"
        options={BANK_FORMAT_LIST}
        value={BANK_FORMAT_LIST.find(
          format => format.value === formik.values.bankFormat
        )}
        error={
          Boolean(formik.touched.bankFormat && formik.errors.bankFormat) &&
          formik.errors.bankFormat
        }
        onChange={async opt => {
          await formik.setFieldValue("bankFormat", opt.value);
        }}
      />
      {formik.values.bankFormat === "AU" && (
        <Input
          label="ABN"
          name="abn"
          onChange={formik.handleChange}
          value={formik.values.abn}
          error={
            Boolean(formik.touched.abn && formik.errors.abn) &&
            formik.errors.abn
          }
          {...formik.getFieldProps("abn")}
        />
      )}

      {formik.values.bankFormat === "UK" && (
        <Input
          label="VAT"
          name="vatCode"
          onChange={formik.handleChange}
          value={formik.values.vatCode}
          error={
            Boolean(formik.touched.vatCode && formik.errors.vatCode) &&
            formik.errors.vatCode
          }
          {...formik.getFieldProps("vatCode")}
        />
      )}
      <Input
        label="Bank Name"
        name="bankName"
        onChange={formik.handleChange}
        value={formik.values.bankName}
        error={
          Boolean(formik.touched.bankName && formik.errors.bankName) &&
          formik.errors.bankName
        }
        {...formik.getFieldProps("bankName")}
      />

      {(formik.values.bankFormat === "AU" ||
        formik.values.bankFormat === "USA") && (
        <Input
          label="Branch Name"
          name="branchName"
          onChange={formik.handleChange}
          value={formik.values.branchName}
          error={
            Boolean(formik.touched.branchName && formik.errors.branchName) &&
            formik.errors.branchName
          }
          {...formik.getFieldProps("branchName")}
        />
      )}

      <div className="grid grid-cols-2 gap-4">
        <Input
          label="Swift Code"
          name="swiftCode"
          onChange={formik.handleChange}
          value={formik.values.swiftCode}
          error={
            Boolean(formik.touched.swiftCode && formik.errors.swiftCode) &&
            formik.errors.swiftCode
          }
          {...formik.getFieldProps("swiftCode")}
        />

        {formik.values.bankFormat === "AU" && (
          <Input
            label="BSB Code"
            name="bsbCode"
            onChange={formik.handleChange}
            value={formik.values.bsbCode}
            error={
              Boolean(formik.touched.bsbCode && formik.errors.bsbCode) &&
              formik.errors.bsbCode
            }
            {...formik.getFieldProps("bsbCode")}
          />
        )}

        {formik.values.bankFormat === "USA" && (
          <Input
            label="Routing Code"
            name="routingCode"
            onChange={formik.handleChange}
            value={formik.values.routingCode}
            error={
              Boolean(
                formik.touched.routingCode && formik.errors.routingCode
              ) && formik.errors.routingCode
            }
            {...formik.getFieldProps("routingCode")}
          />
        )}
      </div>

      {formik.values.bankFormat === "UK" && (
        <div className="grid grid-cols-2 gap-4">
          <Input
            label="Sort Code"
            name="sortCode"
            onChange={formik.handleChange}
            value={formik.values.sortCode}
            error={
              Boolean(formik.touched.sortCode && formik.errors.sortCode) &&
              formik.errors.sortCode
            }
            {...formik.getFieldProps("sortCode")}
          />

          <Input
            label="IBAN"
            name="iban"
            onChange={formik.handleChange}
            value={formik.values.iban}
            error={
              Boolean(formik.touched.iban && formik.errors.iban) &&
              formik.errors.iban
            }
            {...formik.getFieldProps("iban")}
          />
        </div>
      )}

      <div className="grid grid-cols-2 gap-4">
        <Input
          label="Account Name"
          name="accountName"
          onChange={formik.handleChange}
          value={formik.values.accountName}
          error={
            Boolean(formik.touched.accountName && formik.errors.accountName) &&
            formik.errors.accountName
          }
          {...formik.getFieldProps("accountName")}
        />

        <Input
          label="Account Number"
          name="accountNumber"
          onChange={formik.handleChange}
          value={formik.values.accountNumber}
          error={
            Boolean(
              formik.touched.accountNumber && formik.errors.accountNumber
            ) && formik.errors.accountNumber
          }
          {...formik.getFieldProps("accountNumber")}
        />
      </div>
    </div>
  );
};

export default BankDetail;

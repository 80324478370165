import React from "react";
import classnames from "classnames";
import { Reorder } from "@bigbinary/neeto-icons";

import { Draggable } from "react-beautiful-dnd";

const JobAccordion = ({ children, className = "" }) => (
  <div className={className}>{children}</div>
);

const Item = ({
  isOpen = false,
  onClick = () => {},
  draggableIndex,
  children,
  CustomTitle,
  className,
  deleteComponent = false,
  draggable = false,
  draggableId = "",
  clickable = true,
  noChildren = false,
  showBtn = false,
}) => {
  const Title = CustomTitle;
  const DeleteComponent = deleteComponent;
  const paddingOnRight = deleteComponent ? "pr-10" : "";

  const ItemComponent = ({ provided }) => {
    return (
      <div className={classnames(["relative"], paddingOnRight)}>
        <div
          className={classnames(
            [
              "flex items-center space-x-3 justify-between px-3 py-3 rounded-md relative",
            ],
            className,
            { "border-b-0 rounded-b-none": isOpen && !noChildren },
            { "cursor-pointer": clickable }
          )}
          onClick={e => {
            onClick(e);
          }}
        >
          {draggable && (
            <div {...provided.dragHandleProps}>
              <Reorder size={20} color="#87929d" />
            </div>
          )}
          <Title isOpen={isOpen} />
        </div>
        {isOpen && (
          <div
            className={classnames(["rounded-b-md"], className, {
              "border-t-0 rounded-b-md": isOpen,
              "pb-8": showBtn,
              "border-b-0": isOpen && noChildren,
            })}
          >
            {children}
          </div>
        )}
        {deleteComponent && <DeleteComponent />}
      </div>
    );
  };

  if (draggable) {
    return (
      <Draggable
        key={draggableId}
        draggableId={draggableId}
        index={draggableIndex}
      >
        {provided => (
          <div ref={provided.innerRef} {...provided.draggableProps}>
            <ItemComponent provided={provided} />
          </div>
        )}
      </Draggable>
    );
  } else {
    return <ItemComponent />;
  }
};

JobAccordion.Item = Item;

export default JobAccordion;

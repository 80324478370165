import React from "react";
import { Tab } from "@bigbinary/neetoui";
import { useHistory } from "react-router-dom";
import { JOB_DETAILS_TAB } from "../../constants";
import { useContext } from "react";
import { EstimateContext } from "../Context";

const Tabs = ({ jobId, revisionId = "" }) => {
  const history = useHistory();
  const ref = useContext(EstimateContext);

  const activeTab = tab => {
    if (tab == "RCIs") {
      return (
        tab.toLowerCase() ===
          window.location.href.split("/").slice(-2).reverse().pop() ||
        tab.toLowerCase() === window.location.href.split("/").pop()
      );
    } else if (tab == "Post Production" || tab == "Booking Form") {
      return (
        tab.toLowerCase() ===
        window.location.href.split("/").pop().replace("_", " ")
      );
    } else {
      return tab.toLowerCase() === window.location.href.split("/").pop();
    }
  };

  return (
    <>
      <Tab className="w-full px-6">
        {JOB_DETAILS_TAB.tabs.map((tab, index) => {
          return (
            <Tab.Item
              key={index}
              active={activeTab(tab)}
              className="px-3"
              onClick={() => {
                if (ref?.current && ref?.current?.dirty) {
                  ref?.current?.validateForm().then(error => {
                    if (
                      Object.keys(error).length === 0 &&
                      error.constructor === Object
                    ) {
                      ref?.current?.handleSubmit();
                      history.push(
                        `/jobs/${jobId}/revisions/${revisionId}/${tab.toLowerCase()}`
                      );
                    }
                  });
                } else {
                  if (tab === "Overview") {
                    history.push(`/jobs/${jobId}/overview`);
                  } else if (tab === "RCIs") {
                    history.push(`/jobs/${jobId}/rcis`);
                  } else if (tab === "Resources") {
                    history.push(`/jobs/${jobId}/resources`);
                  } else if (tab === "Messages") {
                    history.push(`/jobs/${jobId}/messages`);
                  } else if (tab === "Logs") {
                    history.push(`/jobs/${jobId}/logs`);
                  } else if (tab === "Invoices") {
                    history.push(`/jobs/${jobId}/invoices`);
                  } else if (tab === "Post Production") {
                    history.push(`/jobs/${jobId}/post_production`);
                  } else if (tab === "Booking Form") {
                    history.push(
                      `/jobs/${jobId}/revisions/${revisionId}/booking_form`
                    );
                  } else if (tab === "License") {
                    history.push(
                      `/jobs/${jobId}/revisions/${revisionId}/${tab.toLowerCase()}`
                    );
                  } else {
                    history.push(
                      `/jobs/${jobId}/revisions/${revisionId}/${tab.toLowerCase()}`
                    );
                  }
                }
              }}
            >
              {tab}
            </Tab.Item>
          );
        })}
      </Tab>
    </>
  );
};

export default Tabs;

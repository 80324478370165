import React from "react";
import { Input, Callout, Label } from "@bigbinary/neetoui";
import { Editor } from "@tinymce/tinymce-react";
import { useUserState } from "contexts/user";

const BasecampDetail = ({ formik }) => {
  const { env_variables } = useUserState();

  return (
    <div className="flex flex-col w-full pb-8 mt-6 space-y-6">
      <Callout style="info">
        We need these details for pushing job to basecamp!
      </Callout>

      <Input
        label="Basecamp Post Space"
        name="basecampPostSpace"
        onChange={formik.handleChange}
        value={formik.values.basecampPostSpace}
        error={
          Boolean(
            formik.touched.basecampPostSpace && formik.errors.basecampPostSpace
          ) && formik.errors.basecampPostSpace
        }
        {...formik.getFieldProps("basecampPostSpace")}
      />

      <div className="grid grid-cols-2 gap-4">
        <Input
          label="Basecamp Bot Name"
          name="basecampBotName"
          onChange={formik.handleChange}
          value={formik.values.basecampBotName}
          error={
            Boolean(
              formik.touched.basecampBotName && formik.errors.basecampBotName
            ) && formik.errors.basecampBotName
          }
          {...formik.getFieldProps("basecampBotName")}
        />

        <Input
          label="Basecamp Bot Email"
          name="basecampBotEmail"
          onChange={formik.handleChange}
          value={formik.values.basecampBotEmail}
          error={
            Boolean(
              formik.touched.basecampBotEmail && formik.errors.basecampBotEmail
            ) && formik.errors.basecampBotEmail
          }
          {...formik.getFieldProps("basecampBotEmail")}
        />
      </div>

      <div className="grid grid-cols-2 gap-4">
        <Input
          label="Basecamp Head Name"
          name="basecampHeadName"
          onChange={formik.handleChange}
          value={formik.values.basecampHeadName}
          error={
            Boolean(
              formik.touched.basecampHeadName && formik.errors.basecampHeadName
            ) && formik.errors.basecampHeadName
          }
          {...formik.getFieldProps("basecampHeadName")}
        />

        <Input
          label="Basecamp Head Email"
          name="basecampHeadEmail"
          onChange={formik.handleChange}
          value={formik.values.basecampHeadEmail}
          error={
            Boolean(
              formik.touched.basecampHeadEmail &&
                formik.errors.basecampHeadEmail
            ) && formik.errors.basecampHeadEmail
          }
          {...formik.getFieldProps("basecampHeadEmail")}
        />
      </div>

      <div className="flex flex-col space-y-2">
        <Label>Basecamp Introduction</Label>
        <Editor
          apiKey={env_variables.tinymce_api_key}
          init={{
            height: 300,
            plugins: "lists advlist wordcount lineheight",
            toolbar:
              "formatgroup | undo redo| blocks | bold italic underline forecolor backcolor fontsize | alignleft aligncenter alignright alignjustify | numlist bullist | outdent indent hr | blockquote",
            font_size_formats: "8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt",
          }}
          value={formik.values.basecampIntroText}
          onEditorChange={content =>
            formik.setFieldValue("basecampIntroText", content)
          }
        />
      </div>
    </div>
  );
};

export default BasecampDetail;

import React, { useCallback, useRef, useState, useEffect } from "react";
import { Button } from "@bigbinary/neetoui";
import { ImageUpload } from "@bigbinary/neeto-icons";
import { useDropzone } from "react-dropzone";
import ContactCard from "./ContactCard";

const ContactUpload = ({ formValue, setFormValue }) => {
  const imageRef = useRef(null);

  const [image, setImg] = useState();

  useEffect(() => {
    setFormValue("attachments_attributes", [
      ...(formValue.attachments_attributes || []),
      image,
    ]);
  }, [image]);

  const handleClick = () => {
    imageRef.current.click();
  };

  const onDrop = useCallback(files => {
    files.map(file => {
      const reader = new FileReader();

      reader.onload = () => {
        setImg({
          file_data: reader.result,
          filename: file.name,
          attachable_kind: "contact",
        });
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const loadContactCards = img => (
    <div className="relative flex justify-center m-5 overflow-hidden">
      <ContactCard
        type={img.type?.split("/")[1]}
        name={img.filename}
        allowDownload={false}
      />
      <div
        className="absolute top-0 flex items-center justify-center w-4 h-4 text-white bg-red-500 rounded-full cursor-pointer right-2"
        onClick={() => setImg()}
      >
        <Button style="icon" icon="ri-subtract-line" className="text-white" />
      </div>
    </div>
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: ".vcf",
  });

  const DragDropBaseClass =
    "mb-6 flex justify-center p-2 border neeto-ui-border-primary-500 border-dashed rounded-md transition duration-500 ease-in-out hover:bg-gray-50 cursor-pointer";

  return (
    <div className={DragDropBaseClass}>
      {!image ? (
        <div
          className="flex flex-col items-center p-4 space-y-3"
          {...getRootProps()}
        >
          <ImageUpload size={32} className="neeto-ui-text-primary-500" />
          <input
            type="file"
            accept=".vcf"
            id="photo"
            className="hidden"
            ref={imageRef}
            {...getInputProps()}
          />
          {isDragActive ? (
            <p className={"flex justify-center"}>Drop the file here</p>
          ) : (
            <div className="flex flex-col items-center space-y-1">
              <div className="flex items-center justify-center w-full text-sm neeto-ui-text-gray-800">
                <p>Drag and drop or&nbsp;</p>
                <label
                  htmlFor="file-upload"
                  className="font-medium text-purple-500 bg-transparent rounded-md cursor-pointer"
                >
                  <Button
                    style="link"
                    label="Browse"
                    className="underline"
                    onClick={handleClick}
                  />
                </label>
                <p>&nbsp;contact VCF File</p>
                <label
                  htmlFor="file-upload"
                  className="font-medium text-purple-500 bg-transparent rounded-md cursor-pointer"
                ></label>
              </div>
            </div>
          )}
        </div>
      ) : (
        loadContactCards(image)
      )}
    </div>
  );
};

export default ContactUpload;

import axios from "axios";
import { direction } from "common/helper";

export const getJobs = (
  searchParams = "",
  sortProps = {},
  pageIndex,
  perPage,
  category
) => {
  let paginationQuery = `page=${pageIndex}&per_page=${perPage}`;
  let searchQuery = `q[name_i_cont_any]=${searchParams}&q[job_serial_number_i_cont_any]=${searchParams}&q[artists_full_name_i_cont_any]=${searchParams}&q[m]=or&q[status_i_cont_any]=${searchParams}&q[organisation_name_i_cont_any]=${searchParams}`;
  let sortQuery = `q[s]=${sortProps?.column?.field}+${direction(sortProps?.order)}`;
  let query = paginationQuery;
  if (searchParams.length > 0) query += `&${searchQuery}`;
  if (sortProps?.column && sortProps?.order) query += `&${sortQuery}`;
  if (category?.length > 0) query += `&category=${category}`;

  return axios.get(`/api/v1/my_jobs/jobs?${query}`);
};

export const getClosedTagsInCurrentWeek = () => {
  return axios.get(`/api/v1/my_jobs/closed_action_tags`)
}

export const getContactsAddedInCurrentWeek = () => {
  return axios.get(`/api/v1/my_jobs/contacts`)
}

import React, { useState, useEffect } from "react";
import { Spinner, Table } from "@bigbinary/neetoui";
import { Header, Container } from "@bigbinary/neetoui/layouts";
import * as dayjs from "dayjs";
import * as R from "ramda";
import { useParams } from "react-router-dom";
import { getJob, getActivityLogs } from "apis/jobs/activity_logs";
import { getEstimateRevisions } from "apis/jobs/estimate_revisions";
import { showToastrError } from "common";
import EmptyState from "components/Common/EmptyState";
import Tabs from "../Tabs";
import { DEFAULT_PAGE_SIZE, getRandomNotFoundImage } from "common/helper";
import { jobWithNameNumber, getRevisionId } from "./../../common/helper";

const Logs = () => {
  const { id } = useParams();
  const [job, setJob] = useState({});
  const [jobDetailsLoad, setJobDetailsLoad] = useState(true);
  const [estimateRevisions, setEstimateRevisions] = useState([]);
  const [estimateRevisionsLoad, setEstimateRevisionsLoad] = useState(true);
  const [activityLogs, setActivityLogs] = useState([]);
  const [activityLogLoading, setActivityLogLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [initialLoad, setInitialLoad] = useState(true);
  const [emptyImage, setEmptyImage] = useState();

  useEffect(() => {
    setEmptyImage(getRandomNotFoundImage())
  }, [])

  useEffect(() => {
    if (id) {
      loadJobDetailResponse();
      loadEstimateRevisionsResponse();
      loadActivityLogResponse();
    }
  }, [id]);

  useEffect(() => {
    if (pageIndex && !initialLoad) {
      loadActivityLogResponse();
    }
  }, [pageIndex]);

  const loadJobDetailResponse = async () => {
    try {
      const response = await getJob(id);
      setJob(response.data.job);
      setJobDetailsLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadEstimateRevisionsResponse = async () => {
    try {
      const response = await getEstimateRevisions(id);
      setEstimateRevisions(response.data.estimateRevisions);
      setEstimateRevisionsLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadActivityLogResponse = async () => {
    try {
      const response = await getActivityLogs(id, pageIndex, pageSize);
      setActivityLogs(response.data.activityLogs);
      setTotalRecords(response.data.totalRecords);
      setActivityLogLoading(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const COLUMN_DATA = [
    {
      title: "Note",
      dataIndex: "note",
      key: "note",
      width: "60%",
      fixed: "left",
      render: note => (
        <>
          {
            <div
              dangerouslySetInnerHTML={{
                __html: note?.split("\n")?.join("<br>"),
              }}
            ></div>
          }
        </>
      ),
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      render: createdBy => <>{createdBy}</>,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: createdAt => dayjs(createdAt).format("DD/MM/YYYY"),
    },
  ];

  if (jobDetailsLoad || estimateRevisionsLoad || activityLogLoading) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <Container isHeaderFixed>
      <Header
        title={"Logs"}
        breadcrumbs={[
          { text: "Jobs", link: "/jobs" },
          { text: jobWithNameNumber(job), link: `/jobs/${id}/overview` },
        ]}
      />

      <Tabs jobId={id} revisionId={getRevisionId(estimateRevisions)} />
      <div
        className="flex w-full overflow-hidden"
        style={{ height: "calc(100vh - 134px)" }}
      >
        {!R.isEmpty(activityLogs) ? (
          <Table
            columnData={COLUMN_DATA}
            rowData={activityLogs}
            fixedHeight
            totalCount={totalRecords}
            currentPageNumber={pageIndex}
            defaultPageSize={pageSize}
            handlePageChange={(page, pageSize) => {
              setInitialLoad(false);
              setPageIndex(page);
              setPageSize(pageSize);
            }}
            paginationProps={{
              showSizeChanger: false,
            }}
          />
        ) : (
          <EmptyState image={emptyImage} title="No Activities Found" />
        )}
      </div>
    </Container>
  );
};

export default Logs;

import React, { useState, useEffect } from "react";
import { CloseCircle, Plus } from "@bigbinary/neeto-icons";
import {
  Pane,
  Button,
  Typography,
  Avatar,
  Alert,
  Toastr,
  Modal,
  Select,
} from "@bigbinary/neetoui";
import { destroyRecipient, createRecipient } from "apis/jobs/recipients";
import { updateMessageThread } from "apis/jobs/message_threads";
import { showToastrError } from "common";

const MemberItem = ({
  member,
  setRecipientId,
  setDeleteAlertOpen,
  setMember,
  forwardThread,
}) => {
  const getRecipientEmailWithType = () => {
    if (member.recipientType == "default") {
      return `${member.email} (To)`;
    } else if (member.recipientType == "cc_email") {
      return `${member.email} (CC)`;
    } else if (member.recipientType == "bcc_email") {
      return `${member.email} (BCC)`;
    } else {
      return member.email;
    }
  };

  return (
    <div className="flex items-center justify-between w-full">
      <div className="flex items-center justify-start space-x-3">
        <Avatar
          user={{ name: member.name, imageUrl: member.thumbUrl }}
          size="large"
        />
        <div className="flex flex-col items-start">
          <Typography style="h5" weight="medium">
            {member.name} • {member.designation}
          </Typography>
          <Typography
            style="body3"
            weight="normal"
            className="neeto-ui-text-gray-600"
          >
            {getRecipientEmailWithType()}
          </Typography>
        </div>
      </div>
      {!forwardThread && (
        <Button
          icon={CloseCircle}
          style="danger-text"
          tooltipProps={{
            content: "Remove",
            position: "auto",
          }}
          onClick={() => {
            setMember(member);
            setRecipientId(member.id);
            setDeleteAlertOpen(true);
          }}
        />
      )}
    </div>
  );
};

const RecipientsPane = ({
  isOpen,
  onClose,
  recipients,
  jobId,
  threadId,
  jobDetail,
  forwardThread,
  loadThreadDetailResponse,
}) => {
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [recipientId, setRecipientId] = useState();
  const [addRecipientModal, setAddRecipientModal] = useState(false);
  const [newRecipient, setNewRecipient] = useState();
  const [newRecipientOptions, setNewRecipientOptions] = useState([]);
  const [member, setMember] = useState();

  useEffect(() => {
    if (jobDetail && recipients) {
      setNewRecipientOptions(
        jobDetail.allRecipients.filter(
          recipient => !recipients.find(rec => rec.email == recipient.email)
        )
      );
    }
  }, [jobDetail, recipients]);

  const addNewRecipientResponse = async () => {
    try {
      setButtonLoader(true);
      const response = await createRecipient(jobId, threadId, {
        recipient: {
          email: newRecipient.email,
          name: newRecipient.name,
          designation: newRecipient.designation,
          recipientResourceableType: newRecipient.resourceable,
          recipientResourceableId: newRecipient.value,
        },
      });
      Toastr.info(response.data.notice);
      setAddRecipientModal(false);
      loadThreadDetailResponse();
      onClose();
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setButtonLoader(false);
    }
  };

  const destroyRecipientEntry = async () => {
    try {
      if (member.productionEmail) {
        updateMessageThreadResponse();
        Toastr.info("Recipient has been successfully deleted.");
      } else {
        const response = await destroyRecipient(jobId, threadId, recipientId);
        Toastr.info(response.data.notice);
      }
      setDeleteAlertOpen(false);
      loadThreadDetailResponse();
      onClose();
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setButtonLoader(false);
    }
  };

  const updateMessageThreadResponse = async () => {
    try {
      await updateMessageThread(jobId, threadId, {
        message_thread: { productionEmail: false },
      });
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  return (
    <>
      <Pane isOpen={isOpen} onClose={onClose}>
        <Pane.Header>
          <Typography style="h2" weight="semibold">
            People who are notified
          </Typography>
        </Pane.Header>
        <Pane.Body>
          <div className="flex flex-col w-full overflow-y-auto h-full">
            <Typography
              style="body3"
              weight="normal"
              className="mb-3 neeto-ui-text-gray-700"
            >
              These people will receive emails when there’s a new reply or
              message.
            </Typography>
            <div className="flex flex-col w-full mb-5 space-y-4">
              {recipients.map(member => {
                return (
                  <MemberItem
                    key={member.id}
                    member={member}
                    setRecipientId={setRecipientId}
                    setDeleteAlertOpen={setDeleteAlertOpen}
                    setMember={setMember}
                    forwardThread={forwardThread}
                  />
                );
              })}
            </div>

            {!forwardThread && (
              <div className="flex justify-between items-center w-full">
                <Typography style="body1">
                  Add more people to this thread
                </Typography>
                <div className="flex items-start">
                  <Button
                    icon={Plus}
                    iconPosition="left"
                    style="secondary"
                    size="small"
                    onClick={() => setAddRecipientModal(true)}
                  />
                </div>
              </div>
            )}
          </div>
        </Pane.Body>
      </Pane>

      <Alert
        isOpen={deleteAlertOpen}
        title="Delete Recipient"
        message="Are you sure you want to delete the recipient?"
        onClose={() => setDeleteAlertOpen(false)}
        onSubmit={() => destroyRecipientEntry()}
        isSubmitting={buttonLoader}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, delete"
      />

      <Modal
        isOpen={addRecipientModal}
        onClose={() => setAddRecipientModal(false)}
      >
        <Modal.Header>
          <Typography style="h2" weight="semibold">
            Add Recipient
          </Typography>
        </Modal.Header>
        <Modal.Body>
          <div className="flex flex-col w-full space-y-6">
            <Select
              options={newRecipientOptions}
              onChange={opt => setNewRecipient(opt)}
              required={true}
              placeholder="Select a recipient"
              label="Recipient"
              helpText="Adding this person will send them all the future messages and let them participate in the upcoming conversation."
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="flex gap-x-2">
          <Button
            label="Save changes"
            onClick={() => addNewRecipientResponse()}
            loading={buttonLoader}
          />
          <Button
            label="Cancel"
            style="text"
            onClick={() => setAddRecipientModal(false)}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RecipientsPane;

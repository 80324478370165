import React from "react";
import CobblerBlack from "images/CobblerBlack.png";
import { Button, Input, Toastr } from "@bigbinary/neetoui";
import { resetPassword } from "apis/password_reset";
import { showToastrError } from "common";
import { useFormik } from "formik";
import {
  FORGOT_PASSWORD_INITIAL_VALUE,
  FORGOT_PASSWORD_SCHEMA,
} from "./constants";

const ResetPassword = ({ history }) => {
  const formik = useFormik({
    initialValues: FORGOT_PASSWORD_INITIAL_VALUE,
    validationSchema: FORGOT_PASSWORD_SCHEMA,
    onSubmit: () => {
      resetPasswordData();
    },
  });

  const resetPasswordData = async () => {
    try {
      const response = await resetPassword({ user: formik.values });
      Toastr.info(response.data.notice)
      history.push("/");
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  return (
    <div className="flex flex-grow h-screen neeto-ui-bg-white">
      <div
        className="relative w-full p-8 m-10 border border-black"
        style={{ borderRadius: 20 }}
      >
        <img
          src={CobblerBlack}
          alt="Cobbler Logo"
          className="absolute w-auto h-7 left-8 bottom-8"
        />
        <Button
          style="link"
          className="absolute text-black underline left-8 top-8 hover:text-gray-800"
          label="Back to login"
          onClick={() => {
            history.push("/login");
          }}
        />
        <div className="flex flex-col items-center justify-center flex-grow w-5/12 h-full py-20 mx-auto">
          <h2 className="mb-10 text-3xl font-semibold text-center text-black">
            Forgot Password
          </h2>

          <form
            className="flex flex-col items-center w-full signin-form__wrapper"
            onSubmit={() => {}}
          >
            <Input
              required
              type="text"
              className="w-full mb-8"
              value={formik.values.email}
              label="Email"
              helpText="Enter your email address and we 'll send you a link to
              reset your password"
              id="email"
              error={
                Boolean(formik.touched.email && formik.errors.email) &&
                formik.errors.email
              }
              {...formik.getFieldProps("email")}
            />
            <div>
              <Button
                fullWidth
                type="submit"
                className="px-12 py-4 text-2xl font-medium rounded-full signin-btn"
                label="Send Password Reset Insutrctions"
                onClick={e => {
                  e.preventDefault();
                  formik.handleSubmit();
                }}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;

import * as Yup from "yup";
import * as dayjs from "dayjs";

export const RCI_INITIAL_VALUE = {
  artistId: "",
  percentage: 0,
  final: null,
  currencyId: "",
  exchangeRate: 1,
  issueDate: dayjs().format("YYYY-MM-DD"),
};

export const RCI_VALIDATION_SCHEMA = Yup.object({
  artistId: Yup.string().required("can't be empty"),
  final: Yup.string().required("Type can't be empty"),
  exchangeRate: Yup.number(),
});

export const RCI_EXTRA_VALUE = {
  name: "",
  extraType: "",
  amount: "",
};

export const RCI_EXTRA_VALIDATION_SCHEMA = Yup.object({
  name: Yup.string().required("Name can't be empty"),
  extraType: Yup.string().required("Exta type can't be empty"),
  amount: Yup.number().required("Amount can't be empty"),
});

export const EXTRA_TYPE_VALUE_LIST = [
  { value: null, label: "Please select one" },
  { value: "addition", label: "Addition" },
  { value: "deduction", label: "Deduction" },
];

export const TAX_TYPE_LIST = [
  { label: "GST", value: "GST" },
  { label: "VAT", value: "VAT" },
];

import React, { useState, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Select, Label, Switch, Typography } from "@bigbinary/neetoui";
import { useUserState } from "contexts/user";
import { showToastrError } from "common";
import { dropDownListGenerator } from "common/helper";
import { getJobTypes } from "apis/settings/job_types";
import SingleImageUploader from "components/Common/SingleImageUploader";
import {
  DEFAULT_EDITOR_TOOLBAR,
  DEFAULT_EDITOR_FONT_SIZES,
  DEFAULT_EDITOR_PLUGINS,
  DEFAULT_EDITOR_HEIGHT,
} from "common/constants";

const EntryForm = ({ formik }) => {
  const { env_variables } = useUserState();
  const [jobTypeList, setJobTypeList] = useState([]);

  const handleCancellationOverrideChange = content => {
    formik.setFieldValue("cancellationFeeOverride", content);
  };

  const handleCancellationTextChangeInPdf = content => {
    formik.setFieldValue("defaultCancellationTextInPdf", content);
  };

  const handleTermsHeaderChangeInPdf = content => {
    formik.setFieldValue("termsHeaderTextInPdf", content);
  };

  const handleTermsHeaderChange = content => {
    formik.setFieldValue("termsHeaderText", content);
  };

  const handleDefaultCancellationTextChange = content => {
    formik.setFieldValue("defaultCancellationText", content);
  };

  const handlePaymentTermsChange = content => {
    formik.setFieldValue("paymentTerms", content);
  };

  useEffect(() => {
    loadJobTypeList();
  }, []);

  const loadJobTypeList = async () => {
    try {
      const response = await getJobTypes();
      setJobTypeList(dropDownListGenerator(response.data.jobTypes) || []);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  return (
    <div className="flex flex-col w-full pb-12 space-y-6">
      <Select
        label="Job Type"
        name="jobTypeId"
        id="jobTypeId"
        placeholder="Select a job type"
        value={jobTypeList.find(
          jobType => jobType.value === formik.values.jobTypeId
        )}
        error={Boolean(formik.errors.jobTypeId) && formik.errors.jobTypeId}
        required={!formik.values.default}
        options={jobTypeList}
        onChange={async opt => {
          await formik.setFieldValue("jobTypeId", opt.value);
          formik.setFieldTouched("jobTypeId", true);
        }}
      />

      {!formik.values.jobTypeId && (
        <>
          <div className="flex flex-col space-y-2">
            <Label>Email Header / PDF Header</Label>
            <SingleImageUploader
              name="Email Header"
              type="email_header_logo"
              formValue={formik.values}
              setFormValue={formik.setFieldValue}
            />
            <Typography style="body3" className="text-gray-500">
              This logo should be top-right aligned. Image resolution should be
              200px width and 60px height.
            </Typography>
          </div>

          <div className="flex flex-col space-y-2">
            <Label>Template Header / Template Footer / PDF Footer</Label>
            <SingleImageUploader
              name="PDF Footer"
              type="pdf_footer_logo"
              formValue={formik.values}
              setFormValue={formik.setFieldValue}
            />
            <Typography style="body3" className="text-gray-500">
              This logo should be top-left aligned. Image resolution should be
              200px width and 60px height.
            </Typography>
          </div>

          <div className="flex flex-col space-y-2">
            <Label>Email Footer Only</Label>
            <SingleImageUploader
              name="Email Footer"
              type="email_footer_logo"
              formValue={formik.values}
              setFormValue={formik.setFieldValue}
            />
            <Typography style="body3" className="text-gray-500">
              This logo should be top-left aligned and wihte coloured. Image
              resolution should be 200px width and 60px height.
            </Typography>
          </div>

          <div className="flex flex-col space-y-2">
            <Label>Default Cancellation Text</Label>

            <Editor
              apiKey={env_variables.tinymce_api_key}
              init={{
                height: DEFAULT_EDITOR_HEIGHT,
                plugins: DEFAULT_EDITOR_PLUGINS,
                toolbar: DEFAULT_EDITOR_TOOLBAR,
                font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
              }}
              value={formik.values.defaultCancellationText}
              onEditorChange={handleDefaultCancellationTextChange}
            />
          </div>

          <div className="flex flex-col space-y-2">
            <Label>Default Cancellation Text In PDF</Label>

            <Editor
              apiKey={env_variables.tinymce_api_key}
              init={{
                height: DEFAULT_EDITOR_HEIGHT,
                plugins: DEFAULT_EDITOR_PLUGINS,
                toolbar: DEFAULT_EDITOR_TOOLBAR,
                font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
              }}
              value={formik.values.defaultCancellationTextInPdf}
              onEditorChange={handleCancellationTextChangeInPdf}
            />
          </div>

          <div className="flex flex-col space-y-2">
            <Label>Payment Terms</Label>

            <Editor
              apiKey={env_variables.tinymce_api_key}
              init={{
                height: DEFAULT_EDITOR_HEIGHT,
                plugins: DEFAULT_EDITOR_PLUGINS,
                toolbar: DEFAULT_EDITOR_TOOLBAR,
                font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
              }}
              value={formik.values.paymentTerms}
              onEditorChange={handlePaymentTermsChange}
            />
          </div>

          <div className="flex flex-col space-y-2">
            <Label>T&C Header</Label>

            <Editor
              apiKey={env_variables.tinymce_api_key}
              init={{
                height: DEFAULT_EDITOR_HEIGHT,
                plugins: DEFAULT_EDITOR_PLUGINS,
                toolbar: DEFAULT_EDITOR_TOOLBAR,
                font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
              }}
              value={formik.values.termsHeaderText}
              onEditorChange={handleTermsHeaderChange}
            />
          </div>

          <div className="flex flex-col space-y-2">
            <Label>T&C Header In PDF</Label>

            <Editor
              apiKey={env_variables.tinymce_api_key}
              init={{
                height: DEFAULT_EDITOR_HEIGHT,
                plugins: DEFAULT_EDITOR_PLUGINS,
                toolbar: DEFAULT_EDITOR_TOOLBAR,
                font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
              }}
              value={formik.values.termsHeaderTextInPdf}
              onEditorChange={handleTermsHeaderChangeInPdf}
            />
          </div>

          <Switch
            label="Default"
            checked={formik.values.default}
            onChange={() => {
              formik.setFieldValue("default", !formik.values.default);
            }}
          />
        </>
      )}

      {formik.values.jobTypeId && (
        <>
          <div className="flex flex-col space-y-2">
            <Label>Template Logo</Label>
            <SingleImageUploader
              name="Template Logo"
              type="template_logo"
              formValue={formik.values}
              setFormValue={formik.setFieldValue}
            />
            <Typography style="body3" className="text-gray-500">
              This logo should be top-left aligned. Image resolution should be
              200px width and 60px height.
            </Typography>
          </div>

          <div className="flex flex-col space-y-2">
            <Label>Cancellation Fee Override</Label>
            <Editor
              apiKey={env_variables.tinymce_api_key}
              init={{
                height: DEFAULT_EDITOR_HEIGHT,
                plugins: DEFAULT_EDITOR_PLUGINS,
                toolbar: DEFAULT_EDITOR_TOOLBAR,
                font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
              }}
              value={formik.values.cancellationFeeOverride}
              onEditorChange={handleCancellationOverrideChange}
            />
          </div>

          <div className="flex flex-col space-y-2">
            <Label>Cancellation Fee Override In PDF</Label>
            <Editor
              apiKey={env_variables.tinymce_api_key}
              init={{
                height: DEFAULT_EDITOR_HEIGHT,
                plugins: DEFAULT_EDITOR_PLUGINS,
                toolbar: DEFAULT_EDITOR_TOOLBAR,
                font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
              }}
              value={formik.values.cancellationFeeOverrideInPdf}
              onEditorChange={handleCancellationTextChangeInPdf}
            />
          </div>

          <Switch
            label="Default"
            checked={formik.values.default}
            onChange={() => {
              formik.setFieldValue("default", !formik.values.default);
            }}
          />
          <Switch
            label="Display Kill Fee"
            checked={formik.values.showKillFee}
            onChange={() => {
              formik.setFieldValue("showKillFee", !formik.values.showKillFee);
            }}
          />
        </>
      )}
    </div>
  );
};

export default EntryForm;

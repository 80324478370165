import React from "react";
import classNames from "classnames";

const PaymentTerm = ({ paymentTermData, invoice, companyDetail }) => {
  return (
    <div className="flex flex-col items-start justify-between w-full py-12 lg:flex-row">
      <div
        className="flex flex-col items-start justify-start w-full pr-0 lg:pr-12 lg:w-1/3 lg:h-full"
        style={{ fontFamily: "Modern Gothic" }}
      >
        <div className="flex flex-col items-start justify-start mb-10 lg:mb-0">
          <h2 className="text-xl font-medium">Payment Term Options</h2>
          <p className="mt-6 text-base font-normal leading-5">
            {invoice?.earlyBirdFee
              ? `Receive a ${companyDetail?.earlyBirdFeePercentage}% discount.`
              : ""}
          </p>
        </div>
      </div>
      <div
        className="w-full h-full lg:w-2/3"
        style={{ fontFamily: "Modern Gothic" }}
      >
        <div className="grid w-full grid-cols-1 gap-4 lg:grid-cols-3">
          {paymentTermData
            .filter(data => data !== undefined)
            .map((item, index) => (
              <div
                key={index}
                className={classNames(
                  "flex flex-col items-start justify-start w-full p-4 divide-y rounded_container",
                  {
                    "text-white divide-white": item.bgColor === "#000000",
                    "text-black divide-black": item.bgColor != "#000000",
                  }
                )}
                style={{ backgroundColor: item.bgColor && item.bgColor }}
              >
                <h2
                  className={`${`mb-2 text-xl font-medium' ${
                    item.bgColor === "#000000" ? "text-white" : "text-black"
                  }`}`}
                >
                  {item.title}
                </h2>
                <div className="flex flex-col items-start justify-center w-full pt-2 pb-8">
                  <span className="self-start mb-6 text-sm font-normal text-left">
                    You pay –
                  </span>
                  <span className="self-center text-2xl font-medium leading-7">
                    {item.price}
                  </span>
                </div>
                <div className="flex flex-row items-center justify-start w-full py-2">
                  <span className="text-sm font-normal text-left">
                    If paid {item.id === "lateFee" ? "after" : "by"}{" "}
                    {item.due_date}
                  </span>
                </div>
                <div className="flex flex-row items-center justify-between w-full py-2">
                  <span className="text-sm font-bold">
                    {item.title === "Late Payment Terms"
                      ? "Penalty"
                      : "Discount"}
                  </span>
                  <span className="text-sm font-normal">
                    {item.title === "Late Payment Terms"
                      ? item.penalty
                      : item.discount}
                  </span>
                </div>
                <div className="flex flex-row items-center justify-between w-full py-2">
                  <span className="text-sm font-bold">Adjusted Total</span>
                  <span className="text-sm font-normal">{item.total}</span>
                </div>
                {item.taxTypeName && (
                  <div className="flex flex-row items-center justify-between w-full py-2">
                    <span className="text-sm font-bold">
                      {item.taxTypeName}
                    </span>
                    <span className="text-sm font-normal">{item.tax}</span>
                  </div>
                )}
                <div className="flex flex-row items-center justify-between w-full py-2">
                  <span className="text-sm font-bold">Total Fee</span>
                  <span className="text-sm font-normal">{item.total_fee}</span>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default PaymentTerm;

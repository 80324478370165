import React, { useState, useEffect } from "react";
import { MenuHorizontal } from "@bigbinary/neeto-icons";
import {
  Button,
  Spinner,
  Dropdown,
  Table,
  Input,
  Select,
  Pane,
  Alert,
  Typography,
} from "@bigbinary/neetoui";
import { Header, Container } from "@bigbinary/neetoui/layouts";
import { isEmpty } from "ramda";
import { showToastrError } from "common";
import { dropDownListGenerator, getRandomNotFoundImage } from "common/helper";
import {
  getTargetAmountGroupedByYear,
  getTargetAmounts,
  createTargetAmount,
  destroyTargetAmount,
} from "apis/settings/target_amounts";
import { getLocations } from "apis/settings/locations";
import TargetAmountListForSpecificYear from "./TargetAmountListForSpecificYear";
import EmptyState from "components/Common/EmptyState";

const TargetAmounts = ({ title }) => {
  const [targetAmountList, setTargetAmountList] = useState([]);
  const [targetAmountListLoad, setTargetAmountListLoad] = useState(true);
  const [targetAmountYearList, setTargetAmountYearList,] = useState([]);
  const [targetAmountYearListLoad, setTargetAmountYearListLoad] = useState(true);
  const [locationList, setLocationList] = useState([]);
  const [locationListLoad, setLocationListLoad] = useState(true);
  const [modalState, setModalState] = useState(false);
  const [listModalState, setListModalState] = useState(false);
  const [deleteModalState, setDeleteModalState] = useState(false);
  const [year, setYear] = useState("");
  const [week, setWeek] = useState("");
  const [locationId, setLocationId] = useState("");
  const [locationName, setLocationName] = useState("");
  const [targetAmountId, setTargetAmountId] = useState("");
  const [invoiceAmount, setInvoiceAmount] = useState("");
  const [approvalAmount, setApprovalAmount] = useState("");
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [actionTagClosureCount, setActionTagClosureCount] = useState();
  const { Menu, MenuItem } = Dropdown;
  const [emptyImage, setEmptyImage] = useState()

  useEffect(() => {
    loadTargetAmountData();
    setEmptyImage(getRandomNotFoundImage());
  }, []);

  useEffect(() => {
    if (modalState) {
      loadLocationList();
    }
  }, [modalState]);

  const loadLocationList = async () => {
    try {
      const response = await getLocations();
      setLocationList(
        dropDownListGenerator(response.data.locations, "shortName")
      );
      setLocationListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const loadTargetAmountData = async (locationId = null, year = null) => {
    try {
      if (locationId && year) {
        const targetAmountResponse = await getTargetAmounts(locationId, year);
        setTargetAmountYearList(targetAmountResponse.data.targetAmounts || []);
        setLocationId(locationId);
        setYear(year);
        setTargetAmountYearListLoad(false);
        setListModalState(true);
      } else {
        const targetAmountResponse = await getTargetAmountGroupedByYear();
        setTargetAmountList(targetAmountResponse.data.targetAmounts || []);
      }
      setTargetAmountListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const resetAllStates = () => {
    loadTargetAmountData();
    setTargetAmountId("");
    setModalState(false);
    setDeleteModalState(false);
    setYear("");
    setWeek("");
    setLocationId("");
    setInvoiceAmount("");
    setApprovalAmount("");
  };

  const createTargetAmountEntry = async () => {
    try {
      await createTargetAmount({
        target_amount: {
          year: parseInt(year),
          week: 1,
          locationId: locationId,
          invoice_amount: invoiceAmount,
          approval_amount: approvalAmount,
          actionTagClosureCount: actionTagClosureCount
        },
      });
      resetAllStates();
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const destroyTargetAmountEntry = async () => {
    try {
      await destroyTargetAmount(1, locationId, year);
      resetAllStates();
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const COLUMN_DATA = [
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
      width: "45%",
      render: (_, rowData) => (
        <Button
          style="link"
          label={rowData.year}
          onClick={() => {
            setLocationName(rowData.location);
            loadTargetAmountData(rowData.locationId, rowData.year);
          }}
        />
      ),
      fixed: "left",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      width: "45%",
      render: (_, rowData) => <>{rowData.location}</>,
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: "10%",
      render: (_, rowData) => (
        <Dropdown
          autoWidth
          closeOnSelect
          buttonStyle="text"
          strategy="fixed"
          icon={MenuHorizontal}
        >
          <Menu>
            <MenuItem.Button
              style="danger"
              onClick={() => {
                setLocationId(rowData.locationId);
                setYear(rowData.year);
                setTargetAmountId(rowData.id);
                setDeleteModalState(true);
              }}
            >
              Delete
            </MenuItem.Button>
          </Menu>
        </Dropdown>
      ),
    },
  ];

  if (targetAmountListLoad) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <Container>
      <Header
        title={title}
        actionBlock={
          <Button
            label="Add Target Amount"
            onClick={() => {
              setYear("");
              setLocationId("");
              setInvoiceAmount("");
              setApprovalAmount("");
              setTargetAmountId("");
              setModalState(true);
            }}
          />
        }
      />
      <div className="w-full" style={{ height: "calc(100vh - 124px)" }}>
        {!isEmpty(targetAmountList) ? (
          <Table
            fixedHeight
            columnData={COLUMN_DATA}
            rowData={targetAmountList}
            currentPageNumber={pageIndex}
            defaultPageSize={pageSize}
            handlePageChange={(page, pageSize) => {
              setPageIndex(page);
              setPageSize(pageSize);
            }}
          />
        ) : (
          <EmptyState
            image={emptyImage}
            title="No Target Amounts Found"
            description="We couldn’t find any target amounts. Try creating one."
            primaryButtonProps={{
              label: "Add Target Amount",
              onClick: () => {
                setYear("");
                setLocationId("");
                setInvoiceAmount("");
                setApprovalAmount("");
                setTargetAmountId("");
                setModalState(true);
              },
            }}
          />
        )}
      </div>

      <TargetAmountListForSpecificYear
        listModalState={listModalState}
        setListModalState={setListModalState}
        targetAmountYearList={targetAmountYearList}
        targetAmountYearListLoad={targetAmountYearListLoad}
        loadTargetAmountData={loadTargetAmountData}
        selectedYear={year}
        selectedLocationName={locationName}
      />

      <Pane isOpen={modalState} onClose={() => setModalState(false)}>
        <Pane.Header>
          <Typography style="h2" weight="semibold">
            {targetAmountId ? "Edit Target Amount" : "Add Target Amount"}
          </Typography>
        </Pane.Header>
        <Pane.Body>
          {locationListLoad ? (
            <Spinner />
          ) : (
            <div className="flex flex-col w-full space-y-6">
              <Input
                label="Year"
                name="year"
                type="number"
                value={year}
                onChange={e => setYear(e.target.value)}
              />

              {targetAmountId && (
                <Input
                  label="Week"
                  type="number"
                  name="week"
                  value={week}
                  onChange={e => setWeek(e.target.value)}
                />
              )}

              <Select
                label="Location"
                value={locationList.find(
                  location => location.value === locationId
                )}
                onChange={option => {
                  setLocationId(option.value);
                }}
                options={locationList}
              />

              <Input
                type="number"
                label="Invoice Amount"
                name="invoiceAmount"
                value={invoiceAmount}
                onChange={e => setInvoiceAmount(e.target.value)}
              />

              <Input
                type="number"
                label="Approval Amount"
                name="approvalAmount"
                value={approvalAmount}
                onChange={e => setApprovalAmount(e.target.value)}
              />

              <Input
                label="Action Tag Closure Count"
                name="actionTagClosureCount"
                type="number"
                value={actionTagClosureCount}
                onChange={e => setActionTagClosureCount(e.target.value)}
              />
            </div>
          )}
        </Pane.Body>
        <Pane.Footer className="flex space-x-2">
          <Button
            label="Save changes"
            onClick={() => {
              createTargetAmountEntry();
            }}
          />
          <Button
            label="Cancel"
            style="text"
            onClick={() => setModalState(false)}
          />
        </Pane.Footer>
      </Pane>

      <Alert
        isOpen={deleteModalState}
        title="Delete Target Amount"
        message="Are you sure you want to delete the Target Amount?"
        onClose={() => setDeleteModalState(false)}
        onSubmit={() => destroyTargetAmountEntry()}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, delete"
      />
    </Container>
  );
};

export default TargetAmounts;

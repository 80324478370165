import React, { useRef } from "react";
import { Pane, Typography, Button } from "@bigbinary/neetoui";
import AddUsage from "./AddUsage";

const UsageTemplatePane = ({ isOpen, onClose, title }) => {
  const inputRef = useRef(null);
  return (
    <Pane
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      size="large"
      initialFocusRef={inputRef}
    >
      <Pane.Header>
        <Typography style="h2" weight="semibold">
          {title}
        </Typography>
      </Pane.Header>
      <AddUsage inputRef={inputRef} onClose={() => onClose()} />
    </Pane>
  );
};

export default UsageTemplatePane;

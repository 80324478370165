import React from "react";
import CobblerBlue from "images/CobblerBlue.png";

const Footer = ({ recipients, attachmentsCount }) => {
  var recipientsToSenctence =
    recipients.length > 1
      ? recipients.slice(0, recipients.length - 1).join(", ") +
        " and " +
        recipients.slice(-1)
      : recipients.join(", ");

  return (
    <div className="border-t-2">
      <div className="flex pt-5 pb-3">
        <img src={CobblerBlue} height={20} width={20} className="mr-3" />
        <p className="font-bold">
          Reply directly to this email to continue the conversation.
        </p>
      </div>
      <div className="text-sm font-normal" style={{ color: "#68737D" }}>
        This message was sent through Cobbler to {recipientsToSenctence}.<br />
        If you want to stop receiving emails about this job, you can{" "}
        <a href="https://auth.cobbler.app">remove yourself </a>from the list.
      </div>

      {attachmentsCount > 0 && (
        <p className="mt-2 text-sm font-normal" style={{ color: "#68737D" }}>
          <span className="underline">See here</span> for all files associated
          with this job.
        </p>
      )}
    </div>
  );
};

export default Footer;

import React from "react";
import { Down } from "@bigbinary/neeto-icons";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import classnames from "classnames";

import Collapse from "./Collapse";

const Item = ({
  id,
  title,
  isOpen = false,
  onClick = () => {},
  children,
  className = "",
  titleProps = {},
  iconProps = {},
}) => (
  <div
    className={classnames("neeto-ui-accordion__wrapper", {
      [className]: className,
    })}
  >
    <div
      tabIndex={0}
      role="button"
      onClick={onClick}
      id={`neeto-ui-accordion-item-${id}`}
      aria-disabled={isOpen}
      aria-expanded={isOpen}
      aria-controls={`neeto-ui-accordion-section-${id}`}
      className={classnames(
        "neeto-ui-accordion__item flex justify-between items-center",
        {
          "neeto-ui-accordion__item--open": isOpen,
        }
      )}
      onKeyDown={e => {
        switch (e.key) {
          case " ":
          case "Enter":
            onClick();
            break;
          default:
        }
      }}
    >
      <div
        {...titleProps}
        className={classnames(
          "neeto-ui-accordion__item-handle flex flex-grow items-center break-words text-black"
        )}
      >
        {title}
      </div>
      {
        <motion.div
          variants={{ open: { rotate: 180 }, collapsed: { rotate: 0 } }}
          animate={isOpen ? "open" : "collapsed"}
          transition={{ duration: 0.3 }}
          className="flex-grow-0 ml-2 neeto-ui-accordion__item-toggle-icon"
        >
          <Down size={20} className="text-black" {...iconProps} />
        </motion.div>
      }
    </div>

    <Collapse
      role="region"
      open={isOpen}
      className="neeto-ui-accordion__drop"
      id={`neeto-ui-accordion-section-${id}`}
      aria-labelledby={`neeto-ui-accordion-item-${id}`}
    >
      {children}
    </Collapse>
  </div>
);

Item.displayName = "Accordion.Item";

Item.propTypes = {
  /**
   * To specify a unique ID to the AccordionItem.
   */
  id: PropTypes.number,
  /**
   * To add title to the AccordionItem.
   */
  title: PropTypes.string,
  /**
   * To specify whether the Accordion item is open or not.
   */
  isOpen: PropTypes.bool,
  /**
   * To specify the action to be triggered on click of the AccordionItem.
   */
  onClick: PropTypes.func,
  /**
   * To specify the content to be rendered inside the AccordionItem.
   */
  children: PropTypes.node,
  /**
   * To pass props to Accordion title.
   */
  titleProps: PropTypes.object,
  /**
   * To pass props to Accordion toggle icon.
   */
  iconProps: PropTypes.object,
  /**
   * To provide external classnames to Accordion item.
   */
  className: PropTypes.string,
};

export default Item;

import axios from "axios";

const getEstimateLineItemsUrl = id => {
  if (id) {
    return `/api/v1/settings/estimate_line_items/${id}`;
  }

  return `/api/v1/settings/estimate_line_items`;
};

export const getEstimateLineItems = () => {
  return axios.get(getEstimateLineItemsUrl());
};

export const createEstimateLineItem = payload => {
  return axios.post(getEstimateLineItemsUrl(), payload);
};

export const updateEstimateLineItem = (id, payload) => {
  return axios.put(getEstimateLineItemsUrl(id), payload);
};

export const destroyEstimateLineItem = id => {
  return axios.delete(getEstimateLineItemsUrl(id));
};

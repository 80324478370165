import React from "react";
import CobblerLogo from "images/CobblerBlack.png";

const Footer = ({
  jobDetail,
  companyDetails,
  footerLogo,
  illustratorAuthorLink,
  illustratorAuthorName,
}) => {
  return (
    <>
      <div className="logo-container mb-6">
        <img src={footerLogo?.src} alt="footer-logo" />
      </div>

      {companyDetails.map(companyDetail => {
        return (
          <div
            className="flex flex-col items-center lg:items-start justify-end mb-4 space-y-1"
            key={companyDetail.id}
          >
            <p className="text-base font-medium font-bold leading-5">
              {companyDetail.locationShortName}
            </p>
            <p
              className="text-base leading-5"
              dangerouslySetInnerHTML={{
                __html: companyDetail?.streetAddress
                  ?.split("\n")
                  .join("<p style='line-height: 0.25rem'>&nbsp;</p>"),
              }}
            ></p>
            <p className="text-base leading-5">
              {[
                companyDetail?.suburb,
                companyDetail?.state,
                companyDetail?.postcode,
              ]
                .filter(el => el !== null)
                .join(", ")}
            </p>
            <p className="text-base leading-5">
              {companyDetail?.locationName}{" "}
            </p>
            <p className="text-base leading-5">{companyDetail.phoneNumber}</p>
          </div>
        );
      })}

      {!jobDetail.logoOnlyTemplate &&
        !jobDetail.overrideHeaderImage?.src &&
        illustratorAuthorName &&
        illustratorAuthorLink && (
          <div className="flex flex-col items-start space-y-2">
            <p className="text-base font-normal">
              Header Artwork: &nbsp;
              <a className="underline" href={illustratorAuthorLink}>
                {illustratorAuthorName}
              </a>
            </p>
          </div>
        )}
      <div className="flex flex-col items-start space-y-2">
        <p className="text-base font-normal">Made with:</p>
        <div className="w-auto h-8">
          <a href="https://cobbler.app">
            <img src={CobblerLogo} className="w-32 mx-auto mt-2" />
          </a>
        </div>
      </div>
    </>
  );
};

export default Footer;

import React, { useEffect } from "react";
import { useFormik } from "formik";
import {
  Modal,
  Typography,
  Spinner,
  Button,
  Textarea,
  Radio,
  Select,
  DatePicker,
} from "@bigbinary/neetoui";
import * as dayjs from "dayjs";
import { createContactActivityLog } from "apis/contacts/contact_activity_logs";
import { showToastrError } from "common";
import { dropDownListGenerator } from "common/helper";
import { createCrmActionTag } from "apis/settings/crm_action_tags";
import {
  ACTIVITY_LOG_VALIDATION_SCHEMA,
  INITIAL_ACTIVITY_LOG_VALUE,
} from "./constants";

const ActivityModal = ({
  activityModalState,
  contactDetail,
  setActivityModalState,
  crmActionTagList,
  crmActionTagListLoad,
  loadContactListResponse,
  loadCrmActionTagListResponse,
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_ACTIVITY_LOG_VALUE,
    validationSchema: ACTIVITY_LOG_VALIDATION_SCHEMA,
    onSubmit: () => {
      onSubmit();
    },
  });

  useEffect(() => {
    if (formik.values.status === "open") {
      formik.setFieldValue("closureDate", null);
    } else if (formik.values.status === "completed") {
      formik.setFieldValue("issueDate", null);
    } else {
      formik.setFieldValue("issueDate", null);
      formik.setFieldValue("closureDate", null);
    }
  }, [formik.values.status])

  const onSubmit = async () => {
    try {
      await createContactActivityLog(contactDetail.id, {
        contact_activity_log: { ...formik.values },
      });
      setActivityModalState(false);
      loadContactListResponse();
      formik.resetForm();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const createCrmActionTagEntry = async tagName => {
    try {
      const response = await createCrmActionTag({
        crm_action_tag: {
          name: tagName,
          status: "open",
        },
      });
      loadCrmActionTagListResponse();
      formik.setFieldValue("crmActionTagId", response.data.id);
      formik.setFieldValue("status", response.data.status);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  return (
    <Modal
      isOpen={activityModalState}
      onClose={() => setActivityModalState(false)}
    >
      <Modal.Header>
        <Typography style="h2" weight="semibold">
          Add Log
        </Typography>
      </Modal.Header>
      <Modal.Body>
        {crmActionTagListLoad ? (
          <div className="flex items-center justify-center w-full h-full">
            <Spinner />
          </div>
        ) : (
          <div className="flex flex-col space-y-6">
            <Select
              isClearable
              isCreateable
              isSearchable
              id="crmActionTagId"
              label="CRM Action Tag"
              name="crmActionTagId"
              placeholder="Select CRM Action Tag"
              strategy="fixed"
              value={dropDownListGenerator(crmActionTagList).find(
                crmActionTag =>
                  crmActionTag.value === formik.values.crmActionTagId
              )}
              error={
                Boolean(
                  formik.touched.crmActionTagId && formik.errors.crmActionTagId
                ) && formik.errors.crmActionTagId
              }
              options={dropDownListGenerator(crmActionTagList)}
              onChange={option => {
                if (option) {
                  formik.setFieldValue("crmActionTagId", option.value);
                  formik.setFieldValue("status", option.status);
                } else {
                  formik.setFieldValue("crmActionTagId", null);
                }

                formik.setFieldTouched("crmActionTagId", true);
              }}
              onCreateOption={inputValue => {
                createCrmActionTagEntry(inputValue);
              }}
            />

            <Radio label="Status">
              <Radio.Item
                label="Open"
                name="status"
                checked={formik.values.status === "open"}
                onChange={() => formik.setFieldValue("status", "open")}
              />
              <Radio.Item
                label="Completed"
                name="status"
                checked={formik.values.status === "completed"}
                onChange={() => formik.setFieldValue("status", "completed")}
              />
            </Radio>

            {formik.values.status === "open" && (
              <DatePicker
                label="Issue Date"
                name="issueDate"
                dateFormat="DD/MM/YYYY"
                disabledDate={current =>
                  current && current.valueOf() > dayjs().toDate()
                }
                allowClear={false}
                value={formik.values.issueDate
                  ? dayjs(formik.values.issueDate)
                  : null}
                onChange={date => {
                  formik.setFieldValue("issueDate", date.format("YYYY-MM-DD"));
                }}
                getPopupContainer={triggerNode => triggerNode.parentNode}
              />
            )}

            {formik.values.status === "completed" && (
              <DatePicker
                label="Closure Date"
                name="closureDate"
                dateFormat="DD/MM/YYYY"
                allowClear={false}
                value={formik.values.closureDate
                  ? dayjs(formik.values.closureDate)
                  : null}
                onChange={date => {
                  formik.setFieldValue("closureDate", date.format("YYYY-MM-DD"));
                }}
                getPopupContainer={triggerNode => triggerNode.parentNode}
              />
            )}

            <Textarea
              label="Notes"
              name="notes"
              value={formik.values.note}
              onChange={formik.handleChange}
              error={
                Boolean(formik.touched.note && formik.errors.note) &&
                formik.errors.note
              }
              className="mt-2"
              row="4"
              {...formik.getFieldProps("note")}
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="space-x-2">
        <Button label="Save changes" onClick={() => formik.handleSubmit()} />
        <Button
          style="text"
          label="Cancel"
          onClick={() => setActivityModalState(false)}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ActivityModal;

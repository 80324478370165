import axios from "axios";
import { direction } from "common/helper";

export const getStaffs = (
  accountId,
  searchParams = "",
  sortProps = {},
  pageIndex,
  perPage
) => {
  let paginationQuery = `page=${pageIndex}&per_page=${perPage}`;
  let searchQuery = `q[full_name_i_cont_any]=${searchParams}&q[title_i_cont_any]=${searchParams}&q[m]=or`;
  let sortQuery = `q[s]=${sortProps?.column?.field}+${direction(sortProps?.order)}`;
  let query = paginationQuery;
  if (searchParams.length > 0) query += `&${searchQuery}`;
  if (sortProps?.column && sortProps?.order) query += `&${sortQuery}`;

  return axios.get(`/api/v1/super_admins/accounts/${accountId}/users?${query}`);
};

export const createStaff = (accountId, payload) => {
  return axios.post(
    `/api/v1/super_admins/accounts/${accountId}/users`,
    payload
  );
};

export const updateStaff = (accountId, userId, payload) => {
  return axios.put(
    `/api/v1/super_admins/accounts/${accountId}/users/${userId}`,
    payload
  );
};

export const getStaff = (accountId, userId) => {
  return axios.get(
    `/api/v1/super_admins/accounts/${accountId}/users/${userId}`
  );
};

export const destroyStaff = (accountId, userId) => {
  return axios.delete(
    `/api/v1/super_admins/accounts/${accountId}/users/${userId}`
  );
};

import axios from "axios";

const getCrmActionTagsUrl = id => {
  if (id) {
    return `/api/v1/settings/crm_action_tags/${id}`;
  }

  return `/api/v1/settings/crm_action_tags`;
};

export const getCrmActionTags = () => {
  return axios.get(getCrmActionTagsUrl());
};

export const getActiveCrmActionTags = () => {
  return axios.get(`${getCrmActionTagsUrl()}/active`);
};

export const createCrmActionTag = payload => {
  return axios.post(getCrmActionTagsUrl(), payload);
};

export const updateCrmActionTag = (id, payload) => {
  return axios.put(getCrmActionTagsUrl(id), payload);
};

export const destroyCrmActionTag = id => {
  return axios.delete(getCrmActionTagsUrl(id));
};

import React, { useEffect } from "react";
import Scrollspy from "react-scrollspy";
import { TimelineTable } from "../Shared";

import AOS from "aos";
import "aos/dist/aos.css";

const ProjectPhases = ({ data, description }) => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="flex flex-col items-start justify-between w-full py-12 lg:flex-row">
      <div
        className="static top-0 flex flex-col items-start justify-start w-full pr-0 lg:pr-12 lg:sticky lg:top-8 lg:w-1/3 lg:h-full"
        style={{ fontFamily: "Modern Gothic" }}
      >
        <div className="flex flex-col items-start justify-start mb-8 lg:mb-0">
          <h2 className="text-xl font-medium">Milestones</h2>
          <p className="mt-6 text-base font-normal leading-5" dangerouslySetInnerHTML={{ __html: description?.split("\n")
          .join("<br />") }}></p>
        </div>
        <div className="flex-col items-start justify-start hidden w-full mt-16 lg:flex">
          <Scrollspy
            items={data.map(phase => phase.name)}
            currentClassName="jw-navbar-link--active"
            style={{ width: "100%" }}
            className="flex flex-col items-start justify-start w-full border-t border-b border-gray-400 divide-y divide-gray-400"
            offset={-300}
          >
            {data.map(({ id, name }, index) => (
              <li key={index} className="w-full">
                <a
                  href={`#${id}`}
                  data-to-scrollspy-id={`${id}`}
                  className="flex items-center justify-start w-full py-2 text-base font-normal no-underline cursor-pointer hover:text-black focus:text-black jw-navbar-link"
                >
                  <span className="w-5">{index + 1}.</span>
                  <span className="ml-4">{name}</span>
                </a>
              </li>
            ))}
          </Scrollspy>
        </div>
      </div>
      <div className="flex flex-col items-start justify-start w-full h-full space-y-2 lg:w-2/3">
        {data.map((item, index) => (
          <div
            data-aos="fade-down"
            data-aos-easing="ease-in-out"
            key={index}
            id={`${item.id}`}
            className="flex flex-col w-full"
          >
            <TimelineTable
              id={index + 1}
              title={item.name}
              data={[item]}
              bgColor="#E6DCFA"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectPhases;

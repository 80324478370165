import React from "react";
import * as dayjs from "dayjs";
import DatePicker from "react-datepicker";
import { Label } from "@bigbinary/neetoui";
import "react-datepicker/dist/react-datepicker.css";

const DobPicker = ({ formik, label, className = "" }) => {
  const years = Array.range(1900, dayjs().year() + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div className="neeto-ui-input__wrapper">
      <Label>{label}</Label>
      <div className="neeto-ui-input neeto-ui-input--medium">
        <DatePicker
          renderCustomHeader={({ date, changeYear, changeMonth }) => (
            <div
              style={{
                margin: 10,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <select
                className="form-input"
                value={dayjs(date).year()}
                onChange={({ target: { value } }) => changeYear(value)}
              >
                {years.map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <select
                className="form-input"
                value={months[dayjs(date).month()]}
                onChange={({ target: { value } }) =>
                  changeMonth(months.indexOf(value))
                }
              >
                {months.map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          )}
          selected={
            formik.values.dateOfBirth
              ? dayjs(formik.values.dateOfBirth).toDate()
              : null
          }
          onChange={date => formik.setFieldValue("dateOfBirth", date)}
          className={`${className}`}
        />
      </div>
    </div>
  );
};

export default DobPicker;

import React from "react";
import * as dayjs from "dayjs";
import HeaderImage from "./HeaderImage";

const Welcome = ({
  jobDetail,
  revision,
  emailBackground,
  jobLogo,
  templateLogo,
}) => {
  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-col items-start justify-between w-full mb-12 space-x-0 space-y-4 lg:flex-row lg:space-y-0">
        <div
          className="flex flex-row items-center justify-between w-full h-full lg:items-start lg:flex-col lg:w-1/3"
          style={{ fontFamily: "Modern Gothic" }}
        >
          <span className="mb-0 text-xl font-medium leading-6 lg:mb-10">
            Project Estimate
          </span>
          {!jobDetail?.logoOnlyTemplate && (
            <div
              className={`logo-container ${
                templateLogo() ? "template-logo-container" : ""
              }`}
            >
              <img src={jobLogo()} alt="logo" />
            </div>
          )}
        </div>
        <div className="hidden w-full grid-cols-3 gap-4 font-light lg:grid lg:w-2/3 lg:gap-6">
          <div
            className="flex flex-col justify-start divide-y divide-black"
            style={{ fontFamily: "Modern Gothic Mono" }}
          >
            <div className="flex flex-col pb-2 text-sm">
              <span>Job Number</span>
              <span>{jobDetail?.serialNumber}</span>
            </div>
            <div className="flex flex-col py-2 text-sm">
              <span>Estimate Number</span>
              <span>{revision?.serialNumber}</span>
            </div>
            <div className="flex flex-col pt-2 pb-2 text-sm">
              <span>Project</span>
              <span>{jobDetail?.name}</span>
            </div>
          </div>
          <div
            className="flex flex-col justify-start divide-y divide-black"
            style={{ fontFamily: "Modern Gothic Mono" }}
          >
            <div className="flex flex-col pb-2 text-sm">
              <span>Issued On</span>
              <span>
                {dayjs(jobDetail?.estimateIssueDate).format("DD MMMM YYYY")}
              </span>
            </div>

            <div className="flex flex-col py-2 text-sm">
              <span>Client</span>
              <span>{jobDetail?.organisationName}</span>
            </div>
            <div className="flex flex-col pt-2 text-sm">
              <span>End Client</span>
              <span>{jobDetail?.parentOrganisation}</span>
            </div>
          </div>
          <div
            className="flex flex-col justify-start divide-y divide-black"
            style={{ fontFamily: "Modern Gothic Mono" }}
          >
            <div className="flex flex-col pb-2 text-sm">
              <span>Producer</span>
              <span>{jobDetail?.staffFullName}</span>
            </div>
            <div className="flex flex-col pt-2 text-sm">
              <span>Talent/s</span>
              <span>{jobDetail?.artistFullNames}</span>
            </div>
          </div>
        </div>
        <div className="grid w-full grid-cols-2 gap-2 font-light lg:hidden">
          <div
            className="flex flex-col justify-start divide-y divide-black"
            style={{ fontFamily: "Modern Gothic Mono" }}
          >
            <div className="flex flex-col pb-2 text-sm">
              <span>Job Number</span>
              <span>{jobDetail?.serialNumber}</span>
            </div>
            <div className="flex flex-col py-2 text-sm">
              <span>Estimate Number</span>
              <span>{revision?.serialNumber}</span>
            </div>
            <div className="flex flex-col py-2 text-sm">
              <span>Issued On</span>
              <span>
                {dayjs(jobDetail?.estimateIssueDate).format("DD MMMM YYYY")}
              </span>
            </div>
            <div className="flex flex-col pt-2 text-sm">
              <span>Project</span>
              <span>{jobDetail?.name}</span>
            </div>
          </div>
          <div
            className="flex flex-col justify-start divide-y divide-black"
            style={{ fontFamily: "Modern Gothic Mono" }}
          >
            <div className="flex flex-col pb-2 text-sm">
              <span>Producer</span>
              <span>{jobDetail?.staffFullName}</span>
            </div>
            <div className="flex flex-col py-2 text-sm">
              <span>Client</span>
              <span>{jobDetail?.organisationName}</span>
            </div>
            <div className="flex flex-col py-2 text-sm">
              <span>End Client</span>
              <span>{jobDetail?.parentOrganisation}</span>
            </div>
            <div className="flex flex-col pt-2 text-sm">
              <span>Talent/s</span>
              <span>{jobDetail?.artistFullNames}</span>
            </div>
          </div>
        </div>
      </div>
      <HeaderImage jobDetail={jobDetail} emailBackground={emailBackground} />
    </div>
  );
};

export default Welcome;

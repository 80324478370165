import React, { useState, useEffect } from "react";
import {
  Label,
  Input,
  Typography,
  Textarea,
  DatePicker,
  Accordion,
} from "@bigbinary/neetoui";
import * as dayjs from "dayjs";
import { numberWithCommas } from "common/helper";
import { showToastrError } from "common";
import { updateEstimateRevision } from "apis/jobs/estimate_revisions";
import { updateEstimateRevisionItem } from "apis/jobs/estimate_revision_items";
import { sumAmount } from "common/helper";
import AgencyBreakdown from "./AgencyBreakdown";
import ParentLineItemBreakdown from "./ParentLineItemBreakdown";
import { percentageCalculator } from "./utils";
import {
  filterCreativeItems,
  filterUsageItems,
  filterAgencyItems,
  filterExtraItems,
  filterEstimateItems,
  filterEstimateListItems,
} from "../../../common/helper";

const DetailsFooter = ({
  jobDetail,
  revisionId,
  isAsfDisable,
  estimateItems,
  currentRevision,
  finalInvoice,
  loadEstimateRevisionItemList,
  updateServiceFee,
  setUpdateServiceFee,
  revisionLineItemCatgoryList,
}) => {
  const extraItems = filterExtraItems(estimateItems);
  const extraListItems = filterEstimateListItems(extraItems);
  const creativeEstimateItems = filterCreativeItems(estimateItems);
  const creativeExtraItems = filterCreativeItems(
    filterExtraItems(estimateItems)
  );
  const usageEstimateItem = filterUsageItems(estimateItems)[0];
  const asfEstimateItem = filterAgencyItems(
    filterEstimateItems(estimateItems)
  )[0];
  const asfExtraItem = filterAgencyItems(filterExtraItems(estimateItems))[0];

  const totalCreativeAmount = sumAmount(creativeEstimateItems, "amount");
  const totalExtraCreativeAmount = sumAmount(creativeExtraItems, "amount");
  const usageAmount = usageEstimateItem?.amount || 0.0;
  const totalCreativeUsageAmount = totalCreativeAmount + usageAmount;
  const totalAgencyFee = sumAmount(estimateItems, "agencyFee");
  const totalAsfFee =
    Number(asfEstimateItem?.agencyFee) + Number(asfExtraItem?.agencyFee);

  const [serviceFeePercentage, setServiceFeePercentage] = useState(0);
  const [extraNote, setExtraNote] = useState("");
  const [issueDate, setIssueDate] = useState();
  const [serviceFee, setServiceFee] = useState();
  const [adjustAmount, setAdjustAmount] = useState(0);

  const serviceAmount = percentage => {
    return Number(((totalExtraCreativeAmount * percentage) / 100).toFixed(2));
  };

  const subtotal = () => {
    return (
      totalCreativeUsageAmount +
      serviceAmount(serviceFeePercentage) +
      Number(asfEstimateItem?.agencyFee) +
      Number(asfExtraItem?.adjustAmount)
    )
  }

  const totalAmount = () => {
    return (
      totalCreativeUsageAmount +
      serviceAmount(serviceFeePercentage) +
      Number(asfEstimateItem?.agencyFee) +
      Number(asfExtraItem?.adjustAmount) +
      Number(currentRevision?.taxOnEstimateExtra)
    );
  };

  const totalAmountWithoutTax = () => {
    return (
      totalCreativeUsageAmount +
      serviceAmount(serviceFeePercentage) +
      Number(asfEstimateItem?.agencyFee) +
      Number(asfExtraItem?.adjustAmount)
    );
  };

  useEffect(() => {
    if (estimateItems.length > 0) {
      setServiceFeePercentage(Number(asfExtraItem.markupPercentage));
      setServiceFee(serviceAmount(asfExtraItem.markupPercentage));
      setAdjustAmount(asfExtraItem.adjustAmount);
    }
  }, [estimateItems]);

  useEffect(() => {
    if (updateServiceFee) {
      updateAgencyServiceFeeEntry();
    }
  }, [updateServiceFee]);

  useEffect(() => {
    if (currentRevision) {
      setExtraNote(currentRevision.extraNote);
      setIssueDate(currentRevision.overageIssueDate);
    }
  }, [currentRevision]);

  const onServiceFeeChange = value => {
    setServiceFee(value);
    const percentage = ((value / totalExtraCreativeAmount) * 100).toFixed(4);
    setServiceFeePercentage(Number(percentage));
  };

  const updateAgencyServiceFeeEntry = async () => {
    try {
      await updateEstimateRevisionItem(revisionId, asfExtraItem.id, {
        estimate_revision_item: {
          markupPercentage:
            totalExtraCreativeAmount == 0 ? 0 : serviceFeePercentage,
          rate:
            totalExtraCreativeAmount == 0
              ? 0
              : serviceAmount(serviceFeePercentage),
          adjustAmount: adjustAmount,
        },
      });

      await loadEstimateRevisionItemList();
      await setUpdateServiceFee(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const updateEstimate = async (attr, value) => {
    try {
      let payload = { estimate_revision: { [attr]: value } };
      await updateEstimateRevision(jobDetail.id, revisionId, payload);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  // TODO:  duplicate method. used at booking form
  const artistFeeList = () => {
    return estimateItems
      .map(item => {
        if (item.artistId) {
          return {
            id: item.artistId,
            name: item.artistName,
            fee: item.artistFee,
          };
        } else {
          return item.artistMarkupPercentages.map(artistMarkupPercentage => {
            return {
              id: artistMarkupPercentage.artistId,
              name: artistMarkupPercentage.artistName,
              fee: artistMarkupPercentage.artistFee,
            };
          });
        }
      })
      .flat();
  };

  // duplicate method. used at booking form
  const groupedArtistFeeList = () => {
    const artistFees = artistFeeList();

    return artistFees.reduce((artistFees, { id, name, fee }) => {
      if (!artistFees[id]) artistFees[id] = [];
      artistFees[id].push({
        id: id,
        name: name,
        fee: fee,
      });
      return artistFees;
    }, {});
  };

  // duplicate method. used at booking form
  const artistTotalFeeList = () => {
    const artistsFeeList = groupedArtistFeeList();

    return Object.keys(artistsFeeList).map(artistId => {
      const sum = artistsFeeList[artistId].reduce(
        (partialSum, data) => partialSum + Number(data.fee),
        0
      );

      return {
        artistId: artistId,
        name: artistsFeeList[artistId][0].name,
        totalFee: Number(sum).toFixed(2),
      };
    });
  };

  return (
    <div>
      <div className="mt-8">
        <div className="flex justify-between px-4 py-2 border-b border-gray-200">
          <Label className="font-medium">Creative</Label>
          <Label className="font-bold">
            {`${numberWithCommas(Number(totalCreativeAmount).toFixed(2))} ${
              jobDetail.currency
            }`}
          </Label>
        </div>
        <div className="flex justify-between px-4 py-2 border-b border-gray-200">
          <Label className="font-medium">Usage</Label>
          <Label className="font-bold">
            {`${numberWithCommas(Number(usageAmount).toFixed(2))} ${
              jobDetail.currency
            }`}
          </Label>
        </div>
        <div className="flex justify-between px-4 py-2 border-b border-gray-200">
          <Label className="font-medium">
            Estimate Agency Service Fee (
            {Number(asfEstimateItem?.markupPercentage).toFixed(2)}
            %)
          </Label>
          <Label className="font-bold">
            {`${numberWithCommas(
              Number(asfEstimateItem?.agencyFee).toFixed(2)
            )} ${jobDetail.currency}`}
          </Label>
        </div>

        <div className="flex justify-between px-4 py-2 border-b border-gray-200">
          <div className="flex items-end">
            <div className="flex items-center space-x-1">
              <Label className="font-medium"> Extra Agency Service Fee (</Label>
              <Input
                nakedInput
                className="w-12 input-text-right"
                disabled={isAsfDisable || extraListItems.length === 0}
                type="number"
                size="small"
                name="agencyServiceFeePercentage"
                value={serviceFeePercentage}
                onChange={e => {
                  setServiceFee(serviceAmount(e.target.value));
                  setServiceFeePercentage(e.target.value);
                }}
                onBlur={() => {
                  updateAgencyServiceFeeEntry();
                }}
              />
              <Label className="font-medium"> %)</Label>
            </div>
          </div>
          <div className="flex flex-col space-y-2">
            <div className="flex w-40 space-x-1">
              <Input
                className="w-20 input-text-right"
                nakedInput
                disabled={isAsfDisable || extraListItems.length === 0}
                type="number"
                size="small"
                name="agencyServiceFee"
                value={serviceFee}
                onChange={e => onServiceFeeChange(e.target.value)}
                onBlur={() => {
                  updateAgencyServiceFeeEntry();
                }}
              />
              <Label className="font-bold">{jobDetail.currency}</Label>
            </div>

            <div className="flex items-center space-x-1">
              <Label className="font-bold">+/-</Label>
              <Input
                nakedInput
                type="number"
                className="w-20 input-text-right"
                placeholder="Adjust"
                step="0.01"
                size="small"
                disabled={isAsfDisable}
                value={adjustAmount}
                onChange={e => setAdjustAmount(e.target.value)}
                onBlur={() => updateAgencyServiceFeeEntry()}
              />
              <Label className="font-bold">{jobDetail.currency}</Label>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-between px-4 py-3 mt-3 bg-gray-200 border-b border-gray-200 rounded-t-md">
        <Label className="font-semibold">Total Agency Service Fee</Label>
        <Label className="font-bold">
          {`${numberWithCommas(Number(totalAsfFee).toFixed(2))} ${
            jobDetail.currency
          }`}
        </Label>
      </div>

      <div className="flex justify-between px-4 py-3 bg-gray-200 border-b border-gray-200">
        <Label className="font-semibold">Subtotal</Label>
        <Label className="font-bold">
          {` ${numberWithCommas(Number(subtotal()).toFixed(2))} ${
            jobDetail.currency
          }`}
        </Label>
      </div>

      {jobDetail.taxTypeId && (
        <div className="flex justify-between px-4 py-3 bg-gray-200 border-b border-gray-200">
          <Label className="font-semibold">{jobDetail.taxType}</Label>
          <Label className="font-bold">
            {`${numberWithCommas(Number(currentRevision?.taxOnEstimateExtra).toFixed(2))} ${
              jobDetail.currency
            }`}
          </Label>
        </div>
      )}

      <div className="flex justify-between px-4 py-4 bg-gray-300 rounded-b-md">
        <Label className="font-semibold">Total</Label>
        <Label className="font-bold">
          {` ${numberWithCommas(Number(totalAmount()).toFixed(2))} ${
            jobDetail.currency
          }`}
        </Label>
      </div>

      <div className="px-4 mt-8">
        <div className="py-3 border-b border-gray-200">
          <Typography style="h5" weight="bold">
            Breakdown
          </Typography>
        </div>

        <ParentLineItemBreakdown
          revisionLineItemCatgoryList={revisionLineItemCatgoryList}
          estimateListItems={estimateItems}
          jobDetail={jobDetail}
        />

        <AgencyBreakdown
          totalAgencyFee={totalAgencyFee}
          totalAmount={totalAmountWithoutTax()}
          asfEstimateItem={asfEstimateItem}
          asfExtraItem={asfExtraItem}
          creativeEstimateItems={creativeEstimateItems}
          jobDetail={jobDetail}
          estimateItems={estimateItems}
        />

        {artistTotalFeeList().map((artist) => {
          let artistMarkupPercentages = []
          artistMarkupPercentages = estimateItems.map(item => [...artistMarkupPercentages, ...item.artistMarkupPercentages])
          const artistMarkupPercentageList = artistMarkupPercentages.flat().filter(markup => markup.artistId === artist.artistId)
          var sum = 0
          artistMarkupPercentageList.length > 0 ? artistMarkupPercentageList.map((markup) => {
            if (markup.artistExpense) {
              sum = sum + Number(markup.artistFee)
            }
          }) : []

          return (
            <Accordion className="px-0 border-b border-gray-200">
              <Accordion.Item
                className="border-b border-gray-200"
                title={
                  <div className="flex justify-between w-full mr-3">
                    <p className="group">
                      <span className="font-bold">{artist.name}</span>{" "}
                      <span className="hidden group-hover:inline font-bold">
                        ({percentageCalculator(totalAmountWithoutTax(), artist.totalFee)}%)
                      </span>
                    </p>
                    <p className="font-bold">{`${numberWithCommas(Number(artist.totalFee).toFixed(2))} ${
                      jobDetail.currency
                    }`}</p>
                  </div>
                }
              >
                <div className="flex flex-col p-4 space-y-2 bg-white rounded-md">
                  <div className="flex justify-between">
                    <Label className="font-semibold">Expenses ({percentageCalculator(artist.totalFee, sum)}%)</Label>
                    <Label className="font-bold mr-3">{sum} {
                      jobDetail.currency
                    }</Label>
                  </div>
                  <div className="flex justify-between">
                    <Label className="font-semibold">Fees ({percentageCalculator(artist.totalFee, artist.totalFee - Number(sum))}%)</Label>
                    <Label className="font-bold mr-3">{`${Number(artist.totalFee - Number(sum)).toFixed(2)} ${
                      jobDetail.currency
                    }`}</Label>
                  </div>
                </div>
              </Accordion.Item>
            </Accordion>
          );
        })}
      </div>
      <div className="w-full px-4 my-6">
        <DatePicker
          label="Issue Date"
          name="overageIssueDate"
          format="DD/MM/YYYY"
          className="w-4/12 mb-4"
          allowClear={false}
          value={dayjs(issueDate)}
          onChange={date => {
            setIssueDate(date.format("YYYY-MM-DD"));
            updateEstimate("overageIssueDate", date.format("YYYY-MM-DD"));
          }}
        />

        <Textarea
          label="Extras Note"
          value={extraNote}
          disabled={finalInvoice}
          onChange={e => setExtraNote(e.target.value)}
          onBlur={() => updateEstimate("extraNote", extraNote)}
        />
      </div>
    </div>
  );
};

export default DetailsFooter;

import React from "react";

const Sidebar = ({ title }) => {
  return (
    <div className="flex flex-col items-start justify-start lg:p-8 p-4 lg:w-1/4 w-full">
      <div style={{ fontFamily: "Modern Gothic" }}>
        <span className="lg:text-3xl text-2xl font-medium">{title}</span>
      </div>
    </div>
  );
};

export default Sidebar;

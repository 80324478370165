import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { useUserState } from "contexts/user";
import { Header, Container } from "@bigbinary/neetoui/layouts";
import { Button, Spinner, Toastr, Modal, Alert, Typography } from "@bigbinary/neetoui";
import { getEmailBackgroundDetails } from "apis/mailers/email_backgrounds";
import { getCompanyDetails } from "apis/mailers/company_details";
import { getInvoice } from "apis/mailers/invoices";
import { sendInvoiceEmail } from "apis/invoices/send_invoices";
import { getJob } from "apis/mailers/invoices";
import { getSettings } from "apis/mailers/template_settings";
import { showToastrError } from "common";
import { useFormik } from "formik";

import { EMAIL_DISABLED_MESSAGE } from "common/constants";
import { INITIAL_VALUE, VALIDATION_SCHEMA, getEmails } from "../constants";
import MailViewer from "./Viewer";
import MailEditor from "./Editor";

const InvoiceMail = () => {
  const { id, invoiceId } = useParams();
  const history = useHistory();
  const { user, env_variables } = useUserState();
  const [invoice, setInvoice] = useState("");
  const [jobDetail, setJobDetail] = useState();
  const [companyDetails, setCompanyDetails] = useState([]);
  const [companyDetailsLoad, setCompanyDetailsLoad] = useState(true);
  const [
    commencementEmailBackground,
    setCommencementEmailBackground,
  ] = useState();
  const [finalEmailBackground, setFinalEmailBackground] = useState();
  const [invoiceLoad, setInvoiceLoad] = useState(true);
  const [emailBackgroundLoad, setEmailBackgroundLoad] = useState(true);
  const [jobDetailLoad, setjobDetailLoad] = useState(true);
  const [mailLoader, setMailLoader] = useState(false);
  const [defaultTemplateSetting, setDefaultTemplateSetting] = useState();
  const [defaultTemplateSettingLoad, setDefaultTemplateSettingLoad] = useState(
    true
  );
  const [postProductionAlert, setPostProductionAlert] = useState(false);
  const [rciAlertOpen, setRciAlertOpen] = useState(false);

  const formik = useFormik({
    initialValues: INITIAL_VALUE,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: () => {
      sendInvoiceMailToClient();
    },
  });

  useEffect(() => {
    getCompanyDetailsResponse();
    getTemplateSettings();
    setRciAlertOpen(true);
  }, []);

  useEffect(() => {
    if (invoiceId) {
      loadInvoiceEntry(invoiceId);
    }
  }, [invoiceId]);

  useEffect(() => {
    if (id) {
      setJobDetailResponse();
    }
  }, [id]);

  useEffect(() => {
    if (invoice) {
      getEmailBackgroundDetailsResponse();
    }
  }, [invoice]);

  useEffect(() => {
    if (jobDetail && invoice) {
      formik.setFieldValue("firstName", jobDetail.contactFullName);
      formik.setFieldValue(
        "from",
        getEmails(jobDetail?.emailFrom, jobDetail?.allRecipients)[0]
      );
      formik.setFieldValue(
        "to",
        getEmails(jobDetail?.emailTo, jobDetail?.allRecipients)
      );
      formik.setFieldValue(
        "cc",
        getEmails(jobDetail?.emailCc, jobDetail?.allRecipients)
      );
      formik.setFieldValue(
        "bcc",
        getEmails(jobDetail?.emailBcc, jobDetail?.allRecipients)
      );
      formik.setFieldValue("subject", mailSubject());
      formik.setFieldValue("body", mailBody());
    }
  }, [invoice, jobDetail, commencementEmailBackground, finalEmailBackground]);

  const sendInvoiceMailToClient = async () => {
    setMailLoader(true);
    try {
      const response = await sendInvoiceEmail(invoiceId, {
        invoice: {
          ...formik.values,
          jobId: jobDetail.id,
        },
      });
      setMailLoader(false);

      if (user.demo_account) {
        Toastr.error(EMAIL_DISABLED_MESSAGE);
      } else {
        Toastr.info(response.data.notice);
      }

      if (invoice.final) {
        setPostProductionAlert(true);
      } else {
        history.push(`/invoices/${invoice.id}`);
      }
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const setJobDetailResponse = async () => {
    try {
      const response = await getJob(id);
      let job = response.data.job;
      setJobDetail(job);
      setjobDetailLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const getTemplateSettings = async () => {
    try {
      const response = await getSettings();
      setDefaultTemplateSetting(response.data.templateSettings[0]);
      setDefaultTemplateSettingLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadInvoiceEntry = async id => {
    try {
      const response = await getInvoice(id);
      setInvoice(response.data.invoice);
      setInvoiceLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const getEmailBackgroundDetailsResponse = async () => {
    try {
      const response = await getEmailBackgroundDetails("invoice");
      setCommencementEmailBackground(
        response.data.emailBackgrounds?.find(
          background => background.pageName === "Commencement Invoice"
        )
      );
      setFinalEmailBackground(
        response.data.emailBackgrounds?.find(
          background => background.pageName === "Final Invoice"
        )
      );
      setEmailBackgroundLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const getCompanyDetailsResponse = async () => {
    try {
      const response = await getCompanyDetails();
      setCompanyDetails(response.data.companyDetails);
      setCompanyDetailsLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const setResourceForSubject = name => {
    switch (name) {
      case "[paperwork_title]":
        return invoice.invoiceType;
      case "[job_number]":
        return jobDetail?.serialNumber;
      case "[job_name]":
        return jobDetail?.name;
      case "[serial_number]":
        return invoice?.invoiceSerial;
      case "[invoice_number]":
        return invoice.invoiceSerial;
      case "[version_number]":
        return invoice.invoiceType?.replace("Invoices", "");
      case "[organisation_name]":
        return jobDetail?.organisationName;
    }
  };

  const setResourceForBody = name => {
    switch (name) {
      case "[contact_first_name]":
        return jobDetail?.contactFirstName;
      case "[staff_phone]":
        return jobDetail?.staffPhone;
      case "[staff_first_name]":
        return jobDetail?.staffFirstName;
      case "[account_name]":
        return jobDetail?.accountName;
    }
  };

  const mailSubject = () => {
    let emailSubject = invoice?.final
      ? finalEmailBackground?.emailSubject
      : commencementEmailBackground?.emailSubject;
    let subject = "";
    const subjectComponents = emailSubject?.split("<>");

    subjectComponents?.map(component => {
      if (component.includes("[")) {
        subject += setResourceForSubject(component);
      } else {
        subject += component;
      }
    });

    return subject;
  };

  const mailBody = () => {
    let emailDetails = invoice?.final
      ? finalEmailBackground?.finalInvoiceEmailDetails
      : commencementEmailBackground?.commencementInvoiceEmailDetails;
    let body = "";
    const bodyComponents = emailDetails?.split("<>");

    bodyComponents?.map(component => {
      if (component.includes("[")) {
        body += setResourceForBody(component);
      } else {
        body += component;
      }
    });

    return body;
  };

  if (
    jobDetailLoad ||
    invoiceLoad ||
    mailLoader ||
    emailBackgroundLoad ||
    companyDetailsLoad ||
    defaultTemplateSettingLoad
  ) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <Container isHeaderFixed>
      <Header
        title={invoice.invoiceType}
        breadcrumbs={[
          { text: "Invoices", link: "/invoices" },
          {
            text: jobDetail.name,
            link: `/jobs/${jobDetail.id}/overview`,
          },
          {
            text: invoice.invoiceSerial,
            link: `/invoices/${invoice.id}`,
          },
        ]}
        actionBlock={
          <Button label="Send Email" onClick={() => formik.handleSubmit()} />
        }
      />

      <div
        className="flex overflow-hidden w-full"
        style={{ height: "calc(100vh - 94px)" }}
      >
        <MailEditor
          formik={formik}
          docUrl={`${window.location.protocol}//${user.account_subdomain}.${
            env_variables.root_domain
          }/invoices/${
            invoice.invoiceType === "Final" ? "final" : "commencement"
          }?token=${invoice.slug}`}
          jobDetail={jobDetail}
        />
        <MailViewer
          formik={formik}
          docUrl={`${window.location.protocol}//${user.account_subdomain}.${
            env_variables.root_domain
          }/invoices/${
            invoice.invoiceType === "Final" ? "final" : "commencement"
          }?token=${invoice.slug}`}
          jobDetail={jobDetail}
          licenseUrl={`${window.location.protocol}//${user.account_subdomain}.${env_variables.root_domain}/standard_license?token=${invoice.approvedRevision.slug}`}
          invoice={invoice}
          companyDetails={companyDetails}
          usageEnabled={invoice.approvedRevision.usageEnabled}
          defaultTemplateSetting={defaultTemplateSetting}
          emailBackground={
            invoice.final ? finalEmailBackground : commencementEmailBackground
          }
        />
      </div>

      <Alert
        isOpen={postProductionAlert}
        title="Reminder"
        message="Have you filled out Post-Production?"
        onClose={() => {
          history.push(`/invoices/${invoice.id}`);
        }}
        onSubmit={() => {
          history.push(`/jobs/${jobDetail.id}/post_production`)
        }}
      />

      <Modal
        isOpen={rciAlertOpen}
        onClose={() => setRciAlertOpen(false)}
      >
        <Modal.Header>
          <Typography style="h2" weight="semibold">
            RCI Reminder
          </Typography>
        </Modal.Header>

        <Modal.Body>
          {<>
            Once you’ve sent the invoice, please generate the
            associated <a href={`/jobs/${id}/rcis`}>RCI</a> in the RCI tab.
          </>}
        </Modal.Body>

        <Modal.Footer className="space-x-2">
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default InvoiceMail;

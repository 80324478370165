import React, { useState, useEffect } from "react";
import { getInvoiceBuilder } from "apis/email_templates/builders";
import { getInvoicePdf } from "apis/email_templates/pdfs";
import { useLocation } from "react-router-dom";
import { Spinner } from "@bigbinary/neetoui";
import { showToastrError } from "common";
import { numberWithCommas, sumAmount } from "common/helper";
import * as dayjs from "dayjs";
import prntr from "prntr";
import "../Styles/common";
import { CURRENCY_SYMBOL } from "../constants";
import { InView } from "react-intersection-observer";
import {
  InvoiceWelcome,
  InvoiceOverview,
  InvoiceCostSummary,
  InvoiceFee,
  InvoicePayment,
  InvoicePaymentTerm,
} from "../Pages";
import PrintSave from "../Shared/PrintSave";

const TaxInvoice = () => {
  const location = useLocation();
  const paramToken = location?.search?.split("?token=")[1];
  const [inView, setInView] = useState(false);
  const [jobDetail, setJobDetail] = useState();
  const [revision, setRevision] = useState();
  const [invoice, setInvoice] = useState();
  const [emailBackground, setEmailBackground] = useState();
  const [companyDetail, setCompanyDetail] = useState();
  const [companyDetails, setCompanyDetails] = useState();
  const [defaultTemplateSetting, setDefaultTemplateSetting] = useState();
  const [defaultTemplateSettingLoad, setDefaultTemplateSettingLoad] = useState(
    false
  );
  const [
    jobTypeSpecificTemplateSetting,
    setJobTypeSpecificTemplateSetting,
  ] = useState();
  const [
    jobTypeSpecificTemplateSettingLoad,
    setJobTypeSpecificTemplateSettingLoad,
  ] = useState();

  const [jobDetailLoad, setJobDetailLoad] = useState(true);
  const [revisionLoad, setRevisionLoad] = useState(true);
  const [emailBackgroundLoad, setEmailBackgroundLoad] = useState(true);
  const [invoiceLoad, setInvoiceLoad] = useState();
  const [companyDetailLoad, setCompanyDetailLoad] = useState(true);
  const [pdfLoad, setPdfLoad] = useState(false);

  const setResourceForPdfFilename = name => {
    switch (name) {
      case "[job_serial]":
        return jobDetail?.serialNumber;
      case "[org_serial]":
        return jobDetail?.organisationSerial;
      case "[paperwork_serial]":
        return invoice?.invoiceSerial;
    }
  };

  const pdfFileName = () => {
    let fileName = "";
    const filenameComponents = emailBackground?.pdfFileName?.split("<>");

    filenameComponents?.map(component => {
      if (component.includes("[")) {
        fileName += setResourceForPdfFilename(component);
      } else {
        fileName += component;
      }
    });

    return fileName;
  };

  useEffect(() => {
    getInvoiceBuilderRecords();
  }, []);

  useEffect(() => {
    document.title = `${invoice?.invoiceType} Invoice`;
  }, [invoice]);

  const currencySymbol = currency => {
    if (CURRENCY_SYMBOL[currency] !== undefined) {
      return CURRENCY_SYMBOL[currency];
    } else {
      return "";
    }
  };

  const ammountWithCurrency = amount => {
    return `${currencySymbol(revision?.currency)}${numberWithCommas(
      Number(amount).toFixed(2)
    )} ${revision?.currency}`;
  };

  const getInvoiceBuilderRecords = async () => {
    try {
      const response = await getInvoiceBuilder(paramToken);
      setJobDetail(response.data.job);
      setRevision(response.data.estimateRevision);
      setInvoice(response.data.invoice);
      setEmailBackground(response.data.emailBackgroundDetails[0]);
      setCompanyDetail(response.data.job.companyDetail);
      setCompanyDetails(response.data.companyDetails);
      setCompanyDetailLoad(false);
      setDefaultTemplateSetting(
        response.data.templateSettings.find(
          template => template.default === true
        )
      );
      setDefaultTemplateSettingLoad(false);
      setJobTypeSpecificTemplateSetting(
        response.data.templateSettings.find(
          template => template.jobTypeId === response.data.job.jobTypeId
        )
      );
      setJobTypeSpecificTemplateSettingLoad(false);

      setJobDetailLoad(false);
      setInvoiceLoad(false);
      setRevisionLoad(false);
      setEmailBackgroundLoad(false);
      setCompanyDetailLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const getInvoicePdfRecordsPrint = async () => {
    setPdfLoad(true);
    try {
      const response = await getInvoicePdf(paramToken);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

      if (isSafari) {
        const link = document.createElement("a");
        link.href = url;
        link.download = `${pdfFileName() || "Invoice"}.pdf`;
        link.click();
      } else {
        prntr({
          printable: url,
          type: "pdf",
          showModal: true,
        });
      }
      setPdfLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const isIE11 = navigator.userAgent.indexOf("Trident/") > -1;

  document.addEventListener(
    "keydown",
    function keyDownHandler(event) {
      // Watch for `metaKey` too (⌘ in Mac)
      if ((event.ctrlKey || event.metaKey) && event.keyCode === 80) {
        // Is the browser IE11?
        if (isIE11) {
          const end = Date.now() + 4000; // block the main thread for 4s.
          while (Date.now() < end) {
            // Deliberately no-op
          }
        }

        event.preventDefault();
        event.stopImmediatePropagation();
        getInvoicePdfRecordsPrint();
      }
    },
    isIE11 ? { capture: true } : true
  );

  const getInvoicePdfRecords = async () => {
    setPdfLoad(true);
    try {
      const response = await getInvoicePdf(paramToken);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.download = `${pdfFileName() || "Invoice"}.pdf`;
      link.click();
      setPdfLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const agencyServiceFeeItemRate = () => {
    return invoice?.invoiceItems
      .filter(item => item.feeType === "agency_service_fee")
      .reduce((partialSum, item) => partialSum + Number(item.customRate), 0);
  };

  const taxAmount = baseAmount => {
    return Number(
      (Number(baseAmount) * Number(invoice?.taxPercentage)) / 100
    ).toFixed(2);
  };

  const commencementTaxAmount = () => {
    return Number(
      (Number(invoice?.total) * Number(invoice?.taxPercentage)) / 100
    ).toFixed(2);
  };

  const totalInvoice = () => {
    return Number(
      Number(invoice?.total) + Number(commencementTaxAmount())
    ).toFixed(2);
  };

  const usageItem = () => {
    return invoice?.invoiceItems.find(item => item.feeType === "usage");
  };

  const adjustedAmount = (fee, type = "plus") => {
    return type === "plus"
      ? Number(invoice?.total) + Number(fee)
      : Number(invoice?.total) - Number(fee);
  };

  const calculateTotal = (baseAmount, tax) => {
    return Number(baseAmount) + Number(tax);
  };

  const costSummaryData = [
    revision?.usageEnabled
      ? {
          title: "Usage",
          amount: ammountWithCurrency(usageItem()?.amount) || 0,
          bgColor: "#D6EEFF",
          textColor: "#000",
        }
      : undefined,
    {
      title: "Agency Service Fee",
      amount: ammountWithCurrency(agencyServiceFeeItemRate()),
      bgColor: "#EDE0D4",
      textColor: "#000",
    },
    invoice?.final && invoice?.taxTypeName
      ? {
          title: invoice?.taxTypeName,
          amount: ammountWithCurrency(taxAmount(adjustedAmount(0))),
          bgColor: "#D1D3D4",
          textColor: "#000",
        }
      : undefined,
    invoice?.final
      ? {
          title: "Total",
          amount: ammountWithCurrency(
            calculateTotal(adjustedAmount(0), taxAmount(adjustedAmount(0)))
          ),
          bgColor: "#D1D3D4",
          textColor: "#000",
        }
      : undefined,
    !invoice?.final
      ? {
          title: "Subtotal (See below for total fees due)",
          amount: ammountWithCurrency(
            sumAmount(invoice?.invoiceItems || [], "amount")
          ),
          bgColor: "#D1D3D4",
          textColor: "#000",
        }
      : undefined,
  ].filter(item => item !== undefined);

  const templateLogo = () => {
    return jobTypeSpecificTemplateSetting?.attachments?.find(
      attachment => attachment.attachable_kind === "template_logo"
    );
  };

  const jobLogo = () => {
    if (templateLogo()) {
      return templateLogo().src;
    } else {
      return defaultTemplateSetting?.attachments?.find(
        attachment => attachment.attachable_kind === "pdf_footer_logo"
      )?.src;
    }
  };

  const earlyFeeDiscount = () => {
    return (
      (Number(invoice?.total) * Number(companyDetail?.earlyBirdFeePercentage)) /
      100
    );
  };

  const lateFeeDiscount = () => {
    return (
      (Number(invoice?.total) * Number(companyDetail?.lateFeePercentage)) / 100
    );
  };

  const PAYMENT_TERMS_DATA = [
    invoice?.earlyBirdFee
      ? {
          title: "Early Bird Discount",
          price: ammountWithCurrency(
            calculateTotal(
              adjustedAmount(earlyFeeDiscount(), "minus"),
              taxAmount(adjustedAmount(earlyFeeDiscount(), "minus"))
            )
          ),
          due_date: dayjs(invoice?.earlyBirdDueDate).format("DD MMMM YYYY"),
          discount: `-${ammountWithCurrency(earlyFeeDiscount())}`,
          total: ammountWithCurrency(
            adjustedAmount(earlyFeeDiscount(), "minus")
          ),
          taxTypeName: invoice?.taxTypeName,
          tax: ammountWithCurrency(
            taxAmount(adjustedAmount(earlyFeeDiscount(), "minus"))
          ),
          total_fee: ammountWithCurrency(
            calculateTotal(
              adjustedAmount(earlyFeeDiscount(), "minus"),
              taxAmount(adjustedAmount(earlyFeeDiscount(), "minus"))
            )
          ),
          bgColor: "#EBDDC6",
        }
      : undefined,
    {
      title: "Standard Payment Terms",
      price: ammountWithCurrency(
        calculateTotal(adjustedAmount(0), taxAmount(adjustedAmount(0)))
      ),
      due_date: dayjs(invoice?.dueDate).format("DD MMMM YYYY"),
      discount: "-0",
      total: ammountWithCurrency(adjustedAmount(0)),
      taxTypeName: invoice?.taxTypeName,
      tax: ammountWithCurrency(taxAmount(adjustedAmount(0))),
      total_fee: ammountWithCurrency(
        calculateTotal(adjustedAmount(0), taxAmount(adjustedAmount(0)))
      ),
      bgColor: "#000000",
    },
    invoice?.lateFee
      ? {
          title: "Late Payment Terms",
          id: "lateFee",
          price: ammountWithCurrency(
            calculateTotal(
              adjustedAmount(lateFeeDiscount()),
              taxAmount(adjustedAmount(lateFeeDiscount()))
            )
          ),
          due_date: dayjs(invoice?.dueDate).format("DD MMMM YYYY"),
          penalty: ammountWithCurrency(lateFeeDiscount()),
          total: ammountWithCurrency(adjustedAmount(lateFeeDiscount(), "plus")),
          taxTypeName: invoice?.taxTypeName,
          tax: ammountWithCurrency(
            taxAmount(adjustedAmount(lateFeeDiscount(), "plus"))
          ),
          total_fee: ammountWithCurrency(
            calculateTotal(
              adjustedAmount(lateFeeDiscount()),
              taxAmount(adjustedAmount(lateFeeDiscount()))
            )
          ),
          bgColor: "#D6886D",
        }
      : undefined,
  ];

  const COMMENCEMENT_FEE_DATA = [
    {
      title: "Subtotal",
      amount: ammountWithCurrency(
        sumAmount(invoice?.invoiceItems || [], "amount")
      ),
      bgColor: "#D1D3D4",
    },
    {
      title: `${invoice?.percentage}% of fee`,
      amount: ammountWithCurrency(invoice?.total),
      bgColor: "#FCB613",
    },
    invoice?.taxTypeName
      ? {
          title: invoice?.taxTypeName,
          amount: ammountWithCurrency(commencementTaxAmount()),
          bgColor: "#D1D3D4",
        }
      : undefined,
  ];

  const BANK_DATA = [
    { title: "Bank Name", value: companyDetail?.bankName },
    {
      title:
        companyDetail?.auBankFormat || companyDetail?.usaBankFormat
          ? "Branch Location"
          : undefined,
      value:
        companyDetail?.auBankFormat || companyDetail?.usaBankFormat
          ? companyDetail?.branchName
          : undefined,
    },
    {
      title: companyDetail?.ukBankFormat ? "IBAN" : undefined,
      value: companyDetail?.ukBankFormat ? companyDetail?.iban : undefined,
    },
    {
      title: "Swift ID Code",
      value: companyDetail?.swiftCode,
    },
    {
      title: "Account Name",
      value: companyDetail?.accountName,
    },
    {
      title: companyDetail?.ukBankFormat ? "Sort Code" : undefined,
      value: companyDetail?.ukBankFormat ? companyDetail?.sortCode : undefined,
    },
    {
      title: companyDetail?.auBankFormat ? "BSB (Branch)" : undefined,
      value: companyDetail?.auBankFormat ? companyDetail?.bsbCode : undefined,
    },
    {
      title: companyDetail?.usaBankFormat ? (
        <>
          ABA/
          <br />
          Routing Code
        </>
      ) : undefined,
      value: companyDetail?.usaBankFormat
        ? companyDetail?.routingCode
        : undefined,
    },
    {
      title: "Account No",
      value: companyDetail?.accountNumber,
    },
  ].filter(bank => bank.title !== undefined);

  const organisationAddress = jobDetail?.secondaryAddress?.id
    ? jobDetail?.secondaryAddress
    : jobDetail?.primaryAddress;

  if (
    jobDetailLoad ||
    revisionLoad ||
    companyDetailLoad ||
    emailBackgroundLoad ||
    invoiceLoad ||
    pdfLoad ||
    companyDetailLoad ||
    defaultTemplateSettingLoad ||
    jobTypeSpecificTemplateSettingLoad
  ) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="relative w-full px-4 py-8 bg-white lg:p-8 jw-template">
      <div className="flex flex-col w-full h-full">
        <InvoiceWelcome
          jobDetail={jobDetail}
          revision={revision}
          emailBackground={emailBackground}
          jobLogo={jobLogo}
          templateLogo={templateLogo}
          invoice={invoice}
        />
        <div className="flex flex-col w-full divide-y divide-black">
          <InvoiceOverview
            jobDetail={jobDetail}
            organisationAddress={organisationAddress}
            invoice={invoice}
            emailBackground={emailBackground}
          />
          <InView onChange={setInView}>
            {({ ref }) => (
              <div
                ref={ref}
                className="flex flex-col w-full divide-y divide-black"
              >
                {!invoice?.final && (
                  <InvoiceCostSummary
                    title="Total Approved Fees"
                    desc={emailBackground?.approvedFeeDetails}
                    invoice={invoice}
                    ammountWithCurrency={ammountWithCurrency}
                    costSummaryData={costSummaryData}
                  />
                )}

                {invoice?.final && (
                  <InvoiceCostSummary
                    title="Final Invoice Summary"
                    invoice={invoice}
                    ammountWithCurrency={ammountWithCurrency}
                    costSummaryData={costSummaryData}
                  />
                )}

                {invoice?.final && (
                  <InvoicePaymentTerm
                    paymentTermData={PAYMENT_TERMS_DATA}
                    invoice={invoice}
                    companyDetail={companyDetail}
                  />
                )}
                {!invoice?.final && (
                  <InvoiceFee
                    commencementFeeData={COMMENCEMENT_FEE_DATA}
                    totalInvoice={totalInvoice}
                    ammountWithCurrency={ammountWithCurrency}
                    invoice={invoice}
                    description={emailBackground?.commencementFeeDetails}
                  />
                )}
                <InvoicePayment
                  jobDetail={jobDetail}
                  companyDetails={companyDetails}
                  illustratorAuthorLink={emailBackground?.artistUrl}
                  illustratorAuthorName={emailBackground?.artistName}
                  footerLogo={defaultTemplateSetting?.attachments?.find(
                    attachment =>
                      attachment.attachable_kind === "pdf_footer_logo"
                  )}
                  paymentTerm={emailBackground?.templatePaymentTerm}
                  bankData={BANK_DATA}
                />
              </div>
            )}
          </InView>
        </div>
      </div>
      <PrintSave
        isVisible={inView}
        getPdfRecordsPrint={getInvoicePdfRecordsPrint}
        getPdfRecords={getInvoicePdfRecords}
      />
    </div>
  );
};

export default TaxInvoice;

import axios from "axios";

const getPhaseTypesUrl = id => {
  if (id) {
    return `/api/v1/settings/phase_types/${id}`;
  }

  return "/api/v1/settings/phase_types";
};

export const getPhaseTypes = () => {
  return axios.get(getPhaseTypesUrl());
};

export const createPhaseType = payload => {
  return axios.post(getPhaseTypesUrl(), payload);
};

export const updatePhaseType = (id, payload) => {
  return axios.put(getPhaseTypesUrl(id), payload);
};

export const destroyPhaseType = id => {
  return axios.delete(getPhaseTypesUrl(id));
};

import React from "react";

import { Typography } from "@bigbinary/neetoui";
import { Link } from "react-router-dom";

const SettingsTile = ({
  label,
  description,
  icon: Icon,
  path,
  ...otherProps
}) => (
  <Link
    className="flex flex-col p-6 no-underline border outline-none cursor-pointer neeto-ui-border-gray-300 hover:neeto-ui-border-gray-500 neeto-ui-shadow-xs focus:neeto-ui-border-gray-500 neeto-ui-rounded-lg"
    to={path}
  >
    <div {...otherProps}>
      <div className="flex items-center mb-4">
        {Icon && <Icon className="neeto-ui-text-gray-700" size={32} />}
      </div>
      <Typography
        className="mb-2 neeto-ui-text-gray-800"
        style="h4"
        weight="semibold"
      >
        {label}
      </Typography>
      <Typography className="neeto-ui-text-gray-600" style="body2">
        {description}
      </Typography>
    </div>
  </Link>
);

export default SettingsTile;

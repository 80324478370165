import React, { useState } from "react";
import { Group } from "@bigbinary/neeto-icons";
import { Tab } from "@bigbinary/neetoui";
import Profile from "./Profile";
import Details from "./Details";
import Invoices from "./Invoices";
import Rcis from "./Rcis";
import Jobs from "./Jobs";
import CatchupNotes from "./CatchupNotes";
import TeamMembers from "./Profile/TeamMembers";
import { ARTIST_DETAILS_TABS } from "../constants";

const renderTabContents = (Tab, artistDetail, coverImage, heroImage) => {
  switch (Tab.label) {
    case "Profile":
      return (
        <Profile
          artistDetail={artistDetail}
          coverImage={coverImage}
          heroImage={heroImage}
        />
      );
    case "Details":
      return <Details artistDetail={artistDetail} />;
    case "Invoices":
      return <Invoices artistDetail={artistDetail} />;
    case "RCIs":
      return <Rcis artistDetail={artistDetail} />;
    case "Jobs":
      return <Jobs artistDetail={artistDetail} />;
    case "Catchup Notes":
      return <CatchupNotes artistId={artistDetail.id} />;
  }
};

const ArtistDetails = ({ artistDetail }) => {
  const [currentTab, setCurrentTab] = useState(ARTIST_DETAILS_TABS.Profile);
  const attachments = artistDetail?.attachments_attributes;
  const coverImage = attachments?.find(
    attachment => attachment.attachable_kind === "cover"
  );
  const heroImage = attachments?.find(
    attachment => attachment.attachable_kind === "hero"
  );

  return (
    <div className="w-full overflow-auto">
      <div className="flex items-center">
        <Tab className="px-6 border-b">
          {Object.keys(ARTIST_DETAILS_TABS).map((key, index) => {
            const tab = ARTIST_DETAILS_TABS[key];
            const { label, icon } = tab;
            return (
              <Tab.Item
                icon={icon}
                key={index}
                className="px-3 py-4"
                onClick={() => setCurrentTab(tab)}
                active={currentTab === tab}
              >
                {label}
              </Tab.Item>
            );
          })}
          {artistDetail?.company && (
            <Tab.Item
              icon={Group}
              className="px-3 py-4"
              onClick={() => setCurrentTab("Team")}
              active={currentTab === "Team"}
            >
              Team
            </Tab.Item>
          )}
        </Tab>
      </div>
      {renderTabContents(currentTab, artistDetail, coverImage, heroImage)}
      {artistDetail?.company && currentTab === "Team" && <TeamMembers artistDetail={artistDetail} />}
    </div>
  );
};

export default ArtistDetails;

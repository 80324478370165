import React from "react";
import { Input, Select } from "@bigbinary/neetoui/formik";

const CommissionComponent = ({ artistOptions, isMarkupDisable }) => (
  <>
    <Select
      label="Assigned To"
      options={artistOptions}
      name="Assigned"
      blurInputOnSelect
      isDisabled={isMarkupDisable()}
    />

    <Input
      type="number"
      label="Commission %"
      name="CommissionPercentage"
      disabled={isMarkupDisable()}
    />
  </>
);

export default CommissionComponent;

import React from "react";
import { Accordion, Typography, Label } from "@bigbinary/neetoui";
import { numberWithCommas } from "common/helper";
import { percentageCalculator } from "./utils";

const AgencyBreakdown = ({
  totalAmount,
  totalAgencyFee,
  creativeEstimateItems,
  asfEstimateItem,
  jobDetail,
  estimateItems,
}) => {
  const markup = Number(
    estimateItems
      .filter(item => item.commissionType === "markup")
      .reduce((acc, item) => acc + Number(item.agencyFee), 0)
  ).toFixed(2)

  const commission = Number(
    creativeEstimateItems
      .filter(item => item.commissionType === "commission")
      .reduce((acc, item) => acc + Number(item.agencyFee), 0)
  ).toFixed(2)

  const agencyServiceFee = Number(
    Number(asfEstimateItem?.rate) +
      Number(asfEstimateItem?.adjustAmount)
  ).toFixed(2)

  return (
    <Accordion className="px-0 border-b border-gray-200">
      <Accordion.Item
        className="pl-0"
        title={
          <div className="flex justify-between w-full mr-3">
            <div className="flex justify-between space-x-1 group">
              <Typography style="h5" weight="semibold">
                Agency Fee
              </Typography>
              <Typography
                style="h5"
                weight="semibold"
                className="hidden group-hover:inline"
              >
                ({percentageCalculator(totalAmount, totalAgencyFee)}%)
              </Typography>
            </div>
            <Typography style="h5" weight="semibold">{`${numberWithCommas(
              Number(totalAgencyFee).toFixed(2)
            )} ${jobDetail.currency}`}</Typography>
          </div>
        }
      >
        <div className="flex flex-col p-4 space-y-2 bg-white rounded-md">
          <div className="flex justify-between">
            <Label className="font-semibold">Markup ({`${percentageCalculator(totalAgencyFee, markup)}%`}):</Label>
            <Label className="font-bold mr-3">
              {numberWithCommas(
                markup
              )}&nbsp;
              {jobDetail.currency}
            </Label>
          </div>
          <div className="flex justify-between">
            <Label className="font-semibold">Commission ({`${percentageCalculator(totalAgencyFee, commission)}%`}):</Label>
            <Label className="font-bold mr-3">
              {numberWithCommas(
                commission
              )}&nbsp;
              {jobDetail.currency}
            </Label>
          </div>
          <div className="flex justify-between">
            <Label className="font-semibold">Agency Service Fee ({`${percentageCalculator(totalAgencyFee, agencyServiceFee)}%`}):</Label>
            <Label className="font-bold mr-3">
              {numberWithCommas(
                agencyServiceFee
              )}&nbsp;
              {jobDetail.currency}
            </Label>
          </div>
        </div>
      </Accordion.Item>
    </Accordion>
  );
};

export default AgencyBreakdown;

import axios from "axios";

const getExclusivityPeriodsUrl = id => {
  if (id) {
    return `/api/v1/settings/exclusivity_periods/${id}`;
  }

  return `/api/v1/settings/exclusivity_periods`;
};

export const getExclusivityPeriods = (searchParams = "") => {
  let query = "";
  if (searchParams.length > 0) query = `q[name_i_cont_any]=${searchParams}`;
  return axios.get(`${getExclusivityPeriodsUrl()}?${query}`);
};

export const createExclusivityPeriod = payload => {
  return axios.post(getExclusivityPeriodsUrl(), payload);
};

export const updateExclusivityPeriod = (id, payload) => {
  return axios.put(getExclusivityPeriodsUrl(id), payload);
};

export const destroyExclusivityPeriod = id => {
  return axios.delete(getExclusivityPeriodsUrl(id));
};

import axios from "axios";
import { DEFAULT_PAGE_SIZE } from "common/helper";

const getContactsUrl = (id, searchParams, page) => {
  if (id) {
    return `/api/v1/contacts/contacts/${id}`;
  }

  if (searchParams) {
    let searchQuery = `q[first_name_i_cont_any]=${searchParams}&q[last_name_i_cont_any]=${searchParams}&q[full_name_i_cont_any]=${searchParams}&q[m]=or&q[id_eq]=${searchParams}&q[active_organisation_contact_title_i_cont_any]=${searchParams}`;
    return `/api/v1/contacts/contacts?${searchQuery}&page=${page}&per_page=${DEFAULT_PAGE_SIZE}`;
  }

  return `/api/v1/contacts/contacts?page=${page}&per_page=${DEFAULT_PAGE_SIZE}`;
};

export const getContacts = (searchParams, page = 1) => {
  return axios.get(getContactsUrl("", searchParams, page));
};

export const getContact = id => {
  return axios.get(getContactsUrl(id));
};

export const createContact = payload => {
  return axios.post(getContactsUrl(), payload);
};

export const updateContact = (id, payload) => {
  return axios.put(getContactsUrl(id), payload);
};

export const destroyContact = id => {
  return axios.delete(getContactsUrl(id));
};

export const importContacts = payload => {
  return axios.post(`/api/v1/contacts/contacts/import`, payload);
};

export const downloadContactsCsv = () => {
  return axios.get(`/api/v1/contacts/contacts/csv_download`);
};

import React from "react";
import { Tag, Dropdown } from "@bigbinary/neetoui";
import { Down } from "@bigbinary/neeto-icons";
import { humanize, getJobsStatusColor } from "common/helper";

const StatusDropdown = ({ onStatusUpdate, data, onGraveyardStatusUpdate }) => {
  const { Menu, MenuItem } = Dropdown;

  return (
    <Dropdown
      position="bottom-end"
      buttonStyle="text"
      customTarget={
        <div className="flex items-center justify-end space-x-2 cursor-pointer">
          <Tag
            style={getJobsStatusColor(data.status)}
            label={humanize(data.status)}
          />
          <Down size={16} />
        </div>
      }
      closeOnSelect
    >
      <Menu>
        <MenuItem.Button
          onClick={() => onStatusUpdate(data.id, { status: "estimate" })}
        >
          Estimate
        </MenuItem.Button>
        <MenuItem.Button
          onClick={() => onStatusUpdate(data.id, { status: "in_progress" })}
        >
          In Progress
        </MenuItem.Button>
        <MenuItem.Button
          onClick={() =>
            onStatusUpdate(data.id, {
              status: "post",
            })
          }
        >
          Post
        </MenuItem.Button>
        <MenuItem.Button
          onClick={() => onStatusUpdate(data.id, { status: "inactive" })}
        >
          Inactive
        </MenuItem.Button>
        <MenuItem.Button
          onClick={() => onStatusUpdate(data.id, { status: "purgatory" })}
        >
          Purgatory
        </MenuItem.Button>
        <MenuItem.Button
          onClick={() => onGraveyardStatusUpdate(data.id)}
          style="danger"
        >
          Graveyard
        </MenuItem.Button>
      </Menu>
    </Dropdown>
  );
};

export default StatusDropdown;

import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Spinner, Toastr } from "@bigbinary/neetoui";
import { Header, Container } from "@bigbinary/neetoui/layouts";
import { getJob } from "apis/jobs/messages";
import { useUserState } from "contexts/user";
import { getEstimateRevisions } from "apis/jobs/estimate_revisions";
import {
  getMessageThreads,
  createMessageThread,
} from "apis/jobs/message_threads";
import { sendDraftMessage } from "apis/jobs/draft_message_threads";
import { showToastrError } from "common";
import { EMAIL_DISABLED_MESSAGE } from "common/constants";
import { useFormik } from "formik";
import { jobWithNameNumber, getRevisionId } from "../../common/helper";
import Tabs from "../Tabs";
import EmptyMessage from "./EmptyMessage";
import MessageThreads from "./MessageThreads";
import NewMessage from "./NewMessage";
import { INITIAL_VALUE, VALIDATION_SCHEMA } from "./constants";

const Messages = () => {
  const { id } = useParams();
  const { user } = useUserState();
  const location = useLocation();
  const threadId = location?.state?.threadId || "";
  const [jobDetail, setJobDetail] = useState({});
  const [jobDetailLoad, setJobDetailLoad] = useState(true);
  const [messageThreads, setMessageThreads] = useState([]);
  const [messageThreadsLoad, setMessageThreadsLoad] = useState(true);
  const [newMessage, setNewMessage] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [activeThread, setActiveThred] = useState();
  const [estimateRevisionList, setestimateRevisionList] = useState([]);
  const [estimateRevisionListLoad, setEstimateRevisionListLoad] = useState(
    true
  );
  const [draftButtonLoader, setDraftButtonLoader] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_VALUE,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: () => {
      if (formik.values.messageId) {
        updateMessageThreadsResponse();
      } else {
        createMessageThreadsResponse();
      }
    },
  });

  useEffect(() => {
    if (id) {
      loadJobDetailResponse();
      loadMessageThreadsResponse();
      loadEstimateRevisionListResponse();
    }
  }, [id]);

  useEffect(() => {
    if (!newMessage) {
      formik.resetForm();
    }
  }, [newMessage]);

  const loadJobDetailResponse = async () => {
    try {
      const response = await getJob(id);
      setJobDetail(response.data.job);
      setJobDetailLoad(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const loadEstimateRevisionListResponse = async () => {
    try {
      const response = await getEstimateRevisions(id);
      setestimateRevisionList(response.data.estimateRevisions);
      setEstimateRevisionListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadMessageThreadsResponse = async () => {
    try {
      const response = await getMessageThreads(id);
      setMessageThreads(response.data.messageThreads);

      if (threadId.length > 0) {
        setActiveThred(threadId);
      }

      setMessageThreadsLoad(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const createMessageThreadsResponse = async () => {
    try {
      if (!formik.values.draft) {
        setButtonLoader(true);
      } else {
        setDraftButtonLoader(true);
      }

      const response = await createMessageThread(id, {
        message_thread: formik.values,
      });
      setNewMessage(false);
      loadMessageThreadsResponse();

      if (user.demo_account) {
        Toastr.error(EMAIL_DISABLED_MESSAGE);
      } else {
        Toastr.info(response.data.notice);
      }
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setButtonLoader(false);
      setDraftButtonLoader(false);
    }
  };

  const updateMessageThreadsResponse = async () => {
    try {
      if (!formik.values.draft) {
        setButtonLoader(true);
      } else {
        setDraftButtonLoader(true);
      }

      const response = await sendDraftMessage(id, formik.values.messageId, {
        message_thread: formik.values,
      });
      setNewMessage(false);
      loadMessageThreadsResponse();

      if (user.demo_account) {
        Toastr.error(EMAIL_DISABLED_MESSAGE);
      } else {
        Toastr.info(response.data.notice);
      }
      formik.resetForm();
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setButtonLoader(false);
      setDraftButtonLoader(false);
    }
  };

  if (jobDetailLoad || messageThreadsLoad || estimateRevisionListLoad) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <Container isHeaderFixed>
      <Header
        title="Messages"
        breadcrumbs={[
          { text: "Jobs", link: "/jobs" },
          {
            text: jobWithNameNumber(jobDetail),
            link: `/jobs/${id}/overview`,
          },
        ]}
      />
      <Tabs
        jobId={id}
        revisionId={getRevisionId(estimateRevisionList)}
        note={jobDetail.note}
      />
      <div
        className="flex w-full overflow-hidden"
        style={{ height: "calc(100vh - 134px)" }}
      >
        {newMessage ? (
          <NewMessage
            formik={formik}
            setNewMessage={setNewMessage}
            jobDetail={jobDetail}
            buttonLoader={buttonLoader}
            draftButtonLoader={draftButtonLoader}
          />
        ) : messageThreads.length == 0 ? (
          <EmptyMessage setNewMessage={setNewMessage} formik={formik} />
        ) : (
          <MessageThreads
            setNewMessage={setNewMessage}
            messageThreads={messageThreads}
            jobId={id}
            formik={formik}
            jobDetail={jobDetail}
            activeThread={activeThread}
            setActiveThred={setActiveThred}
            loadMessageThreadsResponse={loadMessageThreadsResponse}
          />
        )}
      </div>
    </Container>
  );
};

export default Messages;

import React, { useState } from "react";
import { MenuHorizontal } from "@bigbinary/neeto-icons";
import { Dropdown, Table } from "@bigbinary/neetoui";
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_INDEX } from "common/constants";
import { displayData } from "common/helper";

const List = ({
  locationList,
  setLocation,
  setModalState,
  setDeleteAlertState,
  formik,
}) => {
  const [pageIndex, setPageIndex] = useState(DEFAULT_PAGE_INDEX);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const { Menu, MenuItem } = Dropdown;

  const renderDropdown = rowData => {
    return (
      <Dropdown
        autoWidth
        closeOnSelect
        buttonStyle="text"
        strategy="fixed"
        icon={MenuHorizontal}
      >
        <Menu>
          <MenuItem.Button
            onClick={() => {
              setLocation(rowData);
              formik.setFieldValue("taxTypeId", null);
              setModalState(true);
            }}
          >
            Edit
          </MenuItem.Button>
          <MenuItem.Button
            style="danger"
            onClick={() => {
              setLocation(rowData);
              setDeleteAlertState(true);
            }}
          >
            Delete
          </MenuItem.Button>
        </Menu>
      </Dropdown>
    );
  };

  const COLUMN_DATA = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "30%",
      render: (_, rowData) => displayData(rowData, "name"),
      fixed: "left",
    },
    {
      title: "Short Name",
      dataIndex: "shortName",
      key: "shortName",
      width: "20%",
      render: (_, rowData) => displayData(rowData, "shortName"),
      fixed: "left",
    },
    {
      title: "Default Currency",
      dataIndex: "currencyName",
      key: "currencyName",
      width: "20%",
      render: (_, rowData) => displayData(rowData, "currencyName"),
      fixed: "left",
    },
    {
      title: "Default Tax",
      dataIndex: "taxTypeName",
      key: "taxTypeName",
      width: "20%",
      render: (_, rowData) => displayData(rowData, "taxTypeName"),
      fixed: "left",
    },
    {
      title: "Xero Tax Format",
      dataIndex: "tag",
      key: "tag",
      width: "20%",
      render: (_, rowData) => displayData(rowData, "tag"),
      fixed: "left",
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: "10%",
      render: (_, rowData) => renderDropdown(rowData),
    },
  ];

  return (
    <Table
      fixedHeight
      columnData={COLUMN_DATA}
      rowData={locationList}
      currentPageNumber={pageIndex}
      defaultPageSize={pageSize}
      handlePageChange={(page, pageSize) => {
        setPageIndex(page);
        setPageSize(pageSize);
      }}
    />
  );
};

export default List;

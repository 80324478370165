import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const CostSummary = ({
  costSummaryData,
  revision,
  totalCost,
  selectedUsageId,
}) => {
  useEffect(() => {
    AOS.init({
      once: false,
    });
  }, []);

  let samt = 0;
  window.addEventListener("scroll", function () {
    samt <= 10 ? samt++ : AOS.refresh();
  });

  return (
    <div className="flex flex-col items-start justify-between w-full py-12 lg:flex-row">
      <div
        className="flex flex-col items-start justify-start w-full h-full lg:w-1/3"
        style={{ fontFamily: "Modern Gothic" }}
      >
        <div className="flex flex-col items-start justify-start">
          <h2 className="mb-8 text-xl font-medium lg:mb-0">Cost Summary</h2>
        </div>
      </div>
      <div className="flex flex-col items-start justify-start w-full lg:w-2/3">
        <div
          className="flex flex-col items-start justify-start w-full space-y-2"
          style={{ fontFamily: "Modern Gothic" }}
        >
          {costSummaryData.map((data, index) => (
            <div
              key={index}
              className="flex flex-col items-center justify-between w-full px-6 py-4 space-y-2 font-medium lg:space-y-0 lg:flex-row rounded_container"
              style={{ backgroundColor: data.bgColor, color: data.textColor }}
              data-aos="fade-down"
              data-aos-easing="ease-in-out"
            >
              <span className="text-base lg:text-xl">{data.title}</span>
              <span className="text-xl">{data.amount}</span>
            </div>
          ))}
        </div>
        <div className="flex flex-col w-full px-6 py-4 mt-2 font-medium text-white bg-black divide-y divide-white rounded_container">
          <div className="flex flex-col items-center justify-between w-full space-y-2 lg:flex-row lg:space-y-0">
            <span className="text-base lg:text-xl">Total Cost</span>
            {((revision?.usageEnabled && selectedUsageId?.length > 0) || !revision?.usageEnabled) && <span className="text-xl">{totalCost}</span>}
          </div>
          {revision?.licensesCount > 1 && !selectedUsageId && (
            <div className="flex items-center justify-start w-full pt-4 mt-4 text-base text-center lg:text-left lg:text-xl">
              <span>
                Please select a usage option first in order for it to be
                included here.
              </span>
            </div>
          )}
        </div>

        <span
          className="mt-12 leading-tight text-black font-size-32"
          style={{ fontFamily: "Modern Gothic" }}
        >
          Please see below for the cost breakdown.
        </span>
      </div>
    </div>
  );
};

export default CostSummary;

import React, { useState } from "react";
import { Tab } from "@bigbinary/neetoui";
import { artistModuleName } from "common/helper";
import { TAB_CONSTANTS } from "./constants";
import EditArtistDetails from "./EditArtistDetails";
import EditInvoiceDetails from "./EditInvoiceDetails";

const EditArtistForm = ({ formik }) => {
  const [currentTab, setCurrentTab] = useState(TAB_CONSTANTS().ArtistDetails);

  const renderTabs = currentTab => {
    switch (currentTab.label) {
      case `${artistModuleName()} Details`:
        return <EditArtistDetails formik={formik} />;
      case "Invoice Details":
        return <EditInvoiceDetails formik={formik} />;
    }
  };

  return (
    <div className="w-full overflow-hidden">
      <Tab className="px-6">
        {Object.keys(TAB_CONSTANTS()).map((key, index) => {
          const tab = TAB_CONSTANTS()[key];
          const { label, icon } = tab;
          return (
            <Tab.Item
              icon={icon}
              key={index}
              className="px-3 py-4"
              onClick={() => setCurrentTab(tab)}
              active={currentTab.label === tab.label}
            >
              {label}
            </Tab.Item>
          );
        })}
      </Tab>
      <form>{renderTabs(currentTab)}</form>
    </div>
  );
};

export default EditArtistForm;

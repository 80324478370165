import axios from "axios";
import { direction } from "common/helper";

const getInvoicesUrl = id => {
  if (id) {
    return `/api/v1/invoice_module/invoices/${id}`;
  }

  return `/api/v1/invoice_module/invoices`;
};

export const getInvoices = (
  searchParams = "",
  sortProps = {},
  pageIndex,
  perPage,
  statusFilter,
  amountFilter,
  preferredLocation,
  preferredPeriod
) => {
  let paginationQuery = `page=${pageIndex}&per_page=${perPage}`;
  let searchQuery = `q[job_name_i_cont_any]=${searchParams}&q[contact_full_name_i_cont_any]=${searchParams}&q[status_i_cont_any]=${searchParams}&q[job_organisation_name_i_cont_any]=${searchParams}&q[user_full_name_i_cont_any]=${searchParams}&q[invoice_serial_number_i_cont_any]=${searchParams}&q[m]=or&q[user_full_name_i_cont_any]=${searchParams}`;
  let statusQuery = `&status_filter=${statusFilter}`;
  let amountQuery = `&amount_filter=${amountFilter}`;
  let locationQuery = `&job_location_filter=${preferredLocation}`;
  let periodFilter = `&created_at_gteq=${preferredPeriod}`;
  let sortQuery = `q[s]=${sortProps?.column?.field}+${direction(
    sortProps?.order
  )}`;
  let query = paginationQuery;

  if (searchParams.length > 0) query += `&${searchQuery}`;
  if (statusFilter) query += `&${statusQuery}`;
  if (amountFilter) query += `&${amountQuery}`;
  if (sortProps?.column && sortProps?.order) query += `&${sortQuery}`;
  if (preferredLocation?.length > 0) query += `&${locationQuery}`;
  if (preferredPeriod) query += `&${periodFilter}`;

  return axios.get(`${getInvoicesUrl()}?${query}`);
};

export const getInvoice = id => {
  return axios.get(getInvoicesUrl(id));
};

export const updateInvoice = (id, payload) => {
  return axios.put(getInvoicesUrl(id), payload);
};

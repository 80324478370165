import React, { useState, useEffect } from "react";
import { Tab } from "@bigbinary/neetoui";
import { getRandomNotFoundImage } from "common/helper";
import { CONTACT_DETAILS_TABS } from "./constants";
import Profile from "./Profile";
import Jobs from "./Jobs";
import History from "./History";
import EmptyState from "components/Common/EmptyState";

const renderTabs = (
  tabs,
  contactDetail,
  loadContactDetailResponse,
  setcurrentTab
) => {
  switch (tabs.label) {
    case "Profile":
      return (
        <Profile
          contactDetail={contactDetail}
          loadContactDetailResponse={loadContactDetailResponse}
          setcurrentTab={setcurrentTab}
        />
      );
    case "Jobs":
      return <Jobs contactDetail={contactDetail} />;
    case "History":
      return <History contactDetail={contactDetail} />;
  }
};

const ContactDetails = ({ contactDetail, loadContactDetailResponse }) => {
  const [currentTab, setcurrentTab] = useState(CONTACT_DETAILS_TABS.Profile);
  const [emptyImage, setEmptyImage] = useState();

  useEffect(() => {
    setEmptyImage(getRandomNotFoundImage());
  }, [])

  if (!contactDetail?.id) {
    return (
      <div className="p-2 m-auto" style={{ height: "calc(100vh - 134px)" }}>
        <EmptyState
          image={emptyImage}
          title="Please click on any contact to access the details."
        />
      </div>
    );
  }

  return (
    <>
      <div className="w-full overflow-hidden">
        <div className="flex items-center">
          <Tab className="px-6 border-b">
            {Object.keys(CONTACT_DETAILS_TABS).map((tab, index) => {
              const { label, icon } = CONTACT_DETAILS_TABS[tab];
              return (
                <Tab.Item
                  icon={icon}
                  key={index}
                  className="px-3 py-4"
                  active={currentTab.label === label}
                  onClick={() => setcurrentTab(CONTACT_DETAILS_TABS[tab])}
                >
                  {label}
                </Tab.Item>
              );
            })}
          </Tab>
        </div>

        {renderTabs(
          currentTab,
          contactDetail,
          loadContactDetailResponse,
          setcurrentTab
        )}
      </div>
    </>
  );
};

export default ContactDetails;

import React, { useState } from "react";
import { Tab } from "@bigbinary/neetoui";
import { useUserState } from "contexts/user";
import { TAB_CONSTANTS } from "../../constants";
import CompanyDetail from "./CompanyDetail";
import BankDetail from "./BankDetail";
import BasecampDetail from "./BasecampDetail";
import XeroDetail from "./XeroDetail";
import Serials from "./Serials";

const EntryForm = ({ formik, setTaxTypeAlertOpen }) => {
  const { user } = useUserState();
  const [currentTab, setCurrentTab] = useState(TAB_CONSTANTS.CompanyDetails);

  const renderTabs = currentTab => {
    switch (currentTab.label) {
      case "Company":
        return (
          <CompanyDetail
            formik={formik}
            setTaxTypeAlertOpen={setTaxTypeAlertOpen}
          />
        );
      case "Bank":
        return <BankDetail formik={formik} />;
      case "Basecamp":
        return <BasecampDetail formik={formik} />;
      case "Xero":
        return <XeroDetail formik={formik} />;
      case "Serial":
        return <Serials formik={formik} />;
    }
  };

  // TODO: Need to fix basecamp tab
  return (
    <div className="relative flex flex-col w-full h-full">
      <Tab className="sticky top-0 left-0 z-50 bg-white">
        {Object.keys(TAB_CONSTANTS)
          .filter(tab =>
            user.account_id === "f4413fd9-bcec-44f5-b000-a770b16af7ef"
              ? true
              : tab !== "BasecampDetails"
          )
          .map((key, index) => {
            const tab = TAB_CONSTANTS[key];
            const { label } = tab;
            return (
              <Tab.Item
                key={index}
                onClick={() => setCurrentTab(tab)}
                active={currentTab === tab}
              >
                {label}
              </Tab.Item>
            );
          })}
      </Tab>
      <div className="h-full">
        <form className="h-full">{renderTabs(currentTab)}</form>
      </div>
    </div>
  );
};

export default EntryForm;

import axios from "axios";

const getOrganisationTypesUrl = id => {
  if (id) {
    return `/api/v1/settings/organisation_types/${id}`;
  }

  return `/api/v1/settings/organisation_types`;
};

export const getOrganisationTypes = (searchParams = "") => {
  let query = "";
  if (searchParams.length > 0) query = `q[name_i_cont_any]=${searchParams}`;
  return axios.get(`${getOrganisationTypesUrl()}?${query}`);
};

export const createOrganisationType = payload => {
  return axios.post(getOrganisationTypesUrl(), payload);
};

export const updateOrganisationType = (id, payload) => {
  return axios.put(getOrganisationTypesUrl(id), payload);
};

export const destroyOrganisationType = id => {
  return axios.delete(getOrganisationTypesUrl(id));
};

export const transferOrganisationType = (id, payload) => {
  return axios.put(`${getOrganisationTypesUrl(id)}/transfer`, payload);
};

import React from "react";
import { Modal, Typography, Spinner } from "@bigbinary/neetoui";
import JobList from "./JobList";
import InvoiceList from "./InvoiceList";
import ActivityTagList from "./ActivityTagList";

const ListModal = ({
  jobList,
  invoiceList,
  setShowListModal,
  showListModal,
  getApprovedJobs,
  noAgency,
  getInvoices,
  listDataLoader,
  getCurrentWeekStatisticsData,
  activityTagList,
  listType,
}) => {
  return (
    <Modal
      size="large"
      isOpen={showListModal}
      onClose={() => setShowListModal(false)}
      style={{ width: "950px" }}
    >
      <Modal.Header>
        <Typography style="h2" weight="semibold">
          {listType === "jobs"
            ? "Job List"
            : listType === "action_tags"
            ? "Action Tags"
            : listType === "invoices"
            ? "Invoice List"
            : ""}
        </Typography>
      </Modal.Header>
      <Modal.Body className="w-full mb-6">
        {listDataLoader ? (
          <div
            className="flex items-center justify-center"
            style={{ height: "calc(60vh)" }}
          >
            <Spinner />
          </div>
        ) : listType === "jobs" ? (
          <JobList
            jobs={jobList}
            noAgency={noAgency}
            getApprovedJobs={getApprovedJobs}
            setShowListModal={setShowListModal}
            getCurrentWeekStatisticsData={getCurrentWeekStatisticsData}
          />
        ) : listType === "action_tags" ? (
          <ActivityTagList
            activityTagList={activityTagList}
            showListModal={showListModal}
          />
        ) : listType === "invoices" ? (
          <InvoiceList
            invoices={invoiceList}
            getInvoices={getInvoices}
            setShowListModal={setShowListModal}
            getCurrentWeekStatisticsData={getCurrentWeekStatisticsData}
          />
        ) : null}
      </Modal.Body>
    </Modal>
  );
};

export default ListModal;

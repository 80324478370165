import React from "react";
import { Modal, Typography, Label } from "@bigbinary/neetoui";

const CompanyDetailsModal = ({ isOpen, onClose, company }) => {
  return (
    <Modal size="large" isOpen={isOpen} onClose={() => onClose()}>
      <Modal.Header>
        <Typography style="h2" weight="semibold">
          {company?.name}
        </Typography>
      </Modal.Header>
      <Modal.Body>
        <div
          className="flex flex-col items-start justify-start pb-6 mt-4 overflow-auto"
          style={{ height: "75vh" }}
        >
          <Typography
            style="h3"
            weight="medium"
            className="w-full pb-2 border-b border-gray-400"
          >
            Company
          </Typography>
          <div className="grid w-full grid-cols-2 gap-6 mt-6 mb-8">
            <div className="flex flex-col items-start justify-start w-full space-y-1">
              <Label>Name</Label>
              <Typography style="body2" weight="medium">
                {company.name}
              </Typography>
            </div>
            {company.bankFormat === "AU" && (
              <div className="flex flex-col items-start justify-start w-full space-y-1">
                <Label>ABN</Label>
                <Typography style="body2" weight="medium">
                  {company.abn}
                </Typography>
              </div>
            )}
            {company.bankFormat === "UK" && (
              <div className="flex flex-col items-start justify-start w-full space-y-1">
                <Label>VAT</Label>
                <Typography style="body2" weight="medium">
                  {company.vatCode}
                </Typography>
              </div>
            )}
            <div className="flex flex-col items-start justify-start w-full space-y-1">
              <Label>Street Address</Label>
              <Typography
                style="body2"
                weight="medium"
                dangerouslySetInnerHTML={{
                  __html: company?.streetAddress?.split("\n").join("<br/>"),
                }}
              ></Typography>
            </div>
            <div className="flex flex-col items-start justify-start w-full space-y-1">
              <Label>City/Suburb</Label>
              <Typography style="body2" weight="medium">
                {company.suburb}
              </Typography>
            </div>
            <div className="flex flex-col items-start justify-start w-full space-y-1">
              <Label>State</Label>
              <Typography style="body2" weight="medium">
                {company.state}
              </Typography>
            </div>
            <div className="flex flex-col items-start justify-start w-full space-y-1">
              <Label>Postcode</Label>
              <Typography style="body2" weight="medium">
                {company.postcode}
              </Typography>
            </div>
            <div className="flex flex-col items-start justify-start w-full space-y-1">
              <Label>Location</Label>
              <Typography style="body2" weight="medium">
                {company.locationTag}
              </Typography>
            </div>
            <div className="flex flex-col items-start justify-start w-full space-y-1">
              <Label>Currency</Label>
              <Typography style="body2" weight="medium">
                {company.currencyName}
              </Typography>
            </div>
            <div className="flex flex-col items-start justify-start w-full space-y-1">
              <Label>Phone Number</Label>
              <Typography style="body2" weight="medium">
                {company.phoneNumber}
              </Typography>
            </div>
            <div className="flex flex-col items-start justify-start w-full space-y-1">
              <Label>Tax Type</Label>
              <Typography style="body2" weight="medium">
                {company.taxType.name}
              </Typography>
            </div>
            <div className="flex flex-col items-start justify-start w-full space-y-1">
              <Label>Tax Rate</Label>
              <Typography style="body2" weight="medium">
                {company.taxRate}
              </Typography>
            </div>
            <div className="flex flex-col items-start justify-start w-full space-y-1">
              <Label>Late Fee Percentage</Label>
              <Typography style="body2" weight="medium">
                {company.lateFeePercentage}
              </Typography>
            </div>
            <div className="flex flex-col items-start justify-start w-full space-y-1">
              <Label>Early Bird Fee Percentage</Label>
              <Typography style="body2" weight="medium">
                {company.earlyBirdFeePercentage}
              </Typography>
            </div>
            <div className="flex flex-col items-start justify-start w-full space-y-1">
              <Label>Early Bird Payment Terms</Label>
              <Typography style="body2" weight="medium">
                {company.earlyBirdPaymentTerm}
              </Typography>
            </div>
            <div className="flex flex-col items-start justify-start w-full space-y-1">
              <Label>Commencement Inv Payment Term</Label>
              <Typography style="body2" weight="medium">
                {company.commencementInvoicePaymentTerm}
              </Typography>
            </div>
            <div className="flex flex-col items-start justify-start w-full space-y-1">
              <Label>Early Bird Fee Limit</Label>
              <Typography style="body2" weight="medium">
                {company.earlyBirdFeeLimit}
              </Typography>
            </div>
            <div className="flex flex-col items-start justify-start w-full space-y-1">
              <Label>Revision Expiry Term</Label>
              <Typography style="body2" weight="medium">
                {company.defaultRevisionExpiryTerms}
              </Typography>
            </div>
          </div>
          <Typography
            style="h3"
            weight="medium"
            className="w-full pb-2 border-b border-gray-400"
          >
            Bank
          </Typography>
          <div className="grid w-full grid-cols-2 gap-6 mt-6 mb-8">
            <div className="flex flex-col items-start justify-start w-full space-y-1">
              <Label>Bank Name</Label>
              <Typography style="body2" weight="medium">
                {company.bankName}
              </Typography>
            </div>
            {(company.bankFormat === "AU" || company.bankFormat === "USA") && (
              <div className="flex flex-col items-start justify-start w-full space-y-1">
                <Label>Branch Name</Label>
                <Typography style="body2" weight="medium">
                  {company.branchName}
                </Typography>
              </div>
            )}
            <div className="flex flex-col items-start justify-start w-full space-y-1">
              <Label>Swift Code</Label>
              <Typography style="body2" weight="medium">
                {company.swiftCode}
              </Typography>
            </div>
            {company.bankFormat === "AU" && (
              <div className="flex flex-col items-start justify-start w-full space-y-1">
                <Label>BSB Code</Label>
                <Typography style="body2" weight="medium">
                  {company.bsbCode}
                </Typography>
              </div>
            )}
            {company.bankFormat === "USA" && (
              <div className="flex flex-col items-start justify-start w-full space-y-1">
                <Label>Routing Code</Label>
                <Typography style="body2" weight="medium">
                  {company.routingCode}
                </Typography>
              </div>
            )}
            {company.bankFormat === "UK" && (
              <div className="flex flex-col items-start justify-start w-full space-y-1">
                <Label>Sort Code</Label>
                <Typography style="body2" weight="medium">
                  {company.sortCode}
                </Typography>
              </div>
            )}
            {company.bankFormat === "UK" && (
              <div className="flex flex-col items-start justify-start w-full space-y-1">
                <Label>IBAN</Label>
                <Typography style="body2" weight="medium">
                  {company.iban}
                </Typography>
              </div>
            )}
            <div className="flex flex-col items-start justify-start w-full space-y-1">
              <Label>Account Name</Label>
              <Typography style="body2" weight="medium">
                {company.accountName}
              </Typography>
            </div>
            <div className="flex flex-col items-start justify-start w-full space-y-1">
              <Label>Account Number</Label>
              <Typography style="body2" weight="medium">
                {company.accountNumber}
              </Typography>
            </div>
          </div>
          <Typography
            style="h3"
            weight="medium"
            className="w-full pb-2 border-b border-gray-400"
          >
            Basecamp
          </Typography>
          <div className="grid w-full grid-cols-2 gap-6 mt-6 mb-8">
            <div className="flex flex-col items-start justify-start w-full space-y-1">
              <Label>Basecamp Post Space</Label>
              <Typography style="body2" weight="medium">
                {company.basecampPostSpace}
              </Typography>
            </div>
            <div className="flex flex-col items-start justify-start w-full space-y-1">
              <Label>Basecamp Bot Name</Label>
              <Typography style="body2" weight="medium">
                {company.basecampBotName}
              </Typography>
            </div>
            <div className="flex flex-col items-start justify-start w-full space-y-1">
              <Label>Basecamp Bot Email</Label>
              <Typography style="body2" weight="medium">
                {company.basecampBotEmail}
              </Typography>
            </div>
            <div className="flex flex-col items-start justify-start w-full space-y-1">
              <Label>Basecamp Head Name</Label>
              <Typography style="body2" weight="medium">
                {company.basecampHeadName}
              </Typography>
            </div>
            <div className="flex flex-col items-start justify-start w-full space-y-1">
              <Label>Basecamp Head Email</Label>
              <Typography style="body2" weight="medium">
                {company.basecampHeadEmail}
              </Typography>
            </div>
            <div className="flex flex-col items-start justify-start w-full col-span-2 space-y-1">
              <Label className="font-semibold">Basecamp Intro Text</Label>
              <Typography
                style="body2"
                dangerouslySetInnerHTML={{
                  __html: company.basecampIntroText?.split("\n").join(""),
                }}
              ></Typography>
            </div>
          </div>
          <Typography
            style="h3"
            weight="medium"
            className="w-full pb-2 border-b border-gray-400"
          >
            Xero
          </Typography>
          <div className="grid w-full grid-cols-2 gap-6 mt-6 mb-8">
            <div className="flex flex-col items-start justify-start w-full space-y-1">
              <Label>Xero Organisation Name</Label>
              <Typography style="body2" weight="medium">
                {company.xeroTenantName}
              </Typography>
            </div>
            <div className="flex flex-col items-start justify-start w-full space-y-1">
              <Label>Default Xero Invoice Account Code</Label>
              <Typography style="body2" weight="medium">
                {company.defaultXeroInvoiceAccountCode}
              </Typography>
            </div>
            <div className="flex flex-col items-start justify-start w-full space-y-1">
              <Label>Default Xero RCI Account Code</Label>
              <Typography style="body2" weight="medium">
                {company.defaultXeroRciAccountCode}
              </Typography>
            </div>
            <div className="flex flex-col items-start justify-start w-full space-y-1">
              <Label>Xero Invoice Type</Label>
              <Typography style="body2" weight="medium">
                {company.xeroInvoiceType}
              </Typography>
            </div>
            <div className="flex flex-col items-start justify-start w-full space-y-1">
              <Label>Xero RCI Type</Label>
              <Typography style="body2" weight="medium">
                {company.xeroRciType}
              </Typography>
            </div>
            <div className="flex flex-col items-start justify-start w-full space-y-1">
              <Label>Default Xero Invoice Status</Label>
              <Typography style="body2" weight="medium">
                {company.xeroInvoiceDefaultStatus}
              </Typography>
            </div>
            <div className="flex flex-col items-start justify-start w-full space-y-1">
              <Label>Default Xero RCI Status</Label>
              <Typography style="body2" weight="medium">
                {company.xeroRciDefaultStatus}
              </Typography>
            </div>
            <div className="flex flex-col items-start justify-start w-full space-y-1">
              <Label>Default Xero Invoice Commencement Fee Account Code</Label>
              <Typography style="body2" weight="medium">
                {company.defaultXeroInvoiceCommencementFeeAccountCode}
              </Typography>
            </div>
            <div className="flex flex-col items-start justify-start w-full space-y-1">
              <Label>Default Xero ASF Account Code</Label>
              <Typography style="body2" weight="medium">
                {company.defaultXeroAsfAccountCode}
              </Typography>
            </div>
          </div>
          <Typography
            style="h3"
            weight="medium"
            className="w-full pb-2 border-b border-gray-400"
          >
            Serials
          </Typography>
          <div className="grid w-full grid-cols-2 gap-6 mt-6 mb-8">
            <div className="flex flex-col items-start justify-start w-full space-y-1">
              <Label>Job Serial</Label>
              <Typography style="body2" weight="medium">
                {company.jobSerial}
              </Typography>
            </div>
            <div className="flex flex-col items-start justify-start w-full space-y-1">
              <Label>Invoice Serial</Label>
              <Typography style="body2" weight="medium">
                {company.invoiceSerial}
              </Typography>
            </div>
            <div className="flex flex-col items-start justify-start w-full space-y-1">
              <Label>RCI Serial</Label>
              <Typography style="body2" weight="medium">
                {company.rciSerial}
              </Typography>
            </div>
            <div className="flex flex-col items-start justify-start w-full space-y-1">
              <Label>License Serial</Label>
              <Typography style="body2" weight="medium">
                {company.licenseSerial}
              </Typography>
            </div>
            <div className="flex flex-col items-start justify-start w-full space-y-1">
              <Label>Revision Serial</Label>
              <Typography style="body2" weight="medium">
                {company.revisionSerial}
              </Typography>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CompanyDetailsModal;

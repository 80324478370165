import React, { useState, useEffect, useRef } from "react";
import { Search } from "@bigbinary/neeto-icons";
import {
  Dropdown,
  Table,
  Button,
  Tag,
  Spinner,
  Input,
  Typography,
} from "@bigbinary/neetoui";
import { SubHeader } from "@bigbinary/neetoui/layouts";
import * as dayjs from "dayjs";
import * as R from "ramda";
import useDebounce from "common/debounce";
import { showToastrError } from "common";
import { humanize, getJobsStatusColor, getRandomNotFoundImage, artistModuleName } from "common/helper";
import { DEFAULT_PAGE_SIZE } from "common/constants";
import EmptyState from "components/Common/EmptyState";
import { getStaffJobs } from "apis/staffs/jobs";
import NewJob from "components/Dashboard/Jobs/NewJob";

const Jobs = ({ staffId, category }) => {
  const initialFocusRef = useRef(null);
  const [newJobPane, setNewJobPane] = useState(false);
  const [searchParams, setSearchParams] = useState();
  const debouncedSearchTerm = useDebounce(searchParams, 500);
  const [jobList, setJoblist] = useState([]);
  const [jobListLoading, setJobListLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [sortProps, setSortProps] = useState();
  const [initial, setInitial] = useState(true);
  const { Menu, MenuItem } = Dropdown;
  const [emptyImage, setEmptyImage] = useState();

  useEffect(() => {
    setEmptyImage(getRandomNotFoundImage())
  }, [])

  useEffect(() => {
    if (staffId) {
      loadJobListResponse();
    }
  }, [staffId]);

  useEffect(() => {
    if (pageIndex && !initial) {
      loadJobListResponse();
    }
  }, [pageIndex]);

  useEffect(() => {
    if (sortProps && !initial) {
      loadJobListResponse();
    }
  }, [sortProps]);

  useEffect(() => {
    if (category) {
      loadJobListResponse();
    }
  }, [category]);

  useEffect(() => {
    if (debouncedSearchTerm != undefined) {
      loadJobListResponse(searchParams, {});
    }
  }, [debouncedSearchTerm]);

  const loadJobListResponse = async () => {
    try {
      const response = await getStaffJobs(
        staffId,
        searchParams,
        sortProps,
        pageIndex || 1,
        DEFAULT_PAGE_SIZE,
        category
      );

      setJoblist(response.data.jobs);
      setTotalRecords(response.data.totalRecords);
      setJobListLoading(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const COLUMN_DATA = [
    {
      key: "serialNumber",
      dataIndex: "serialNumber",
      title: "Job No.",
      width: 100,
      sorter: true,
      field: "serial",
      render: serialNumber => <>{serialNumber}</>,
    },
    {
      key: "name",
      dataIndex: "name",
      title: "Name",
      sorter: true,
      field: "name",
      width: 180,
      render: (_, rowData) => {
        return (
          <Button
            style="link"
            to={`/jobs/${rowData.id}/overview`}
            label={rowData.name}
          />
        );
      },
    },
    {
      key: "organisationName",
      dataIndex: "organisationName",
      title: "Organisation",
      sorter: true,
      field: "organisation_name",
      width: 170,
      render: (_, rowData) => {
        return (
          <Button
            style="link"
            to={`/organisations/${rowData.organisationId}`}
            label={rowData.organisationName}
          />
        );
      },
    },
    {
      title: artistModuleName(),
      dataIndex: "artists",
      key: "artists",
      width: 180,
      field: "artists_full_name",
      render: (_, rowData) => {
        return (
          <Dropdown
            autoWidth
            closeOnSelect
            trigger="hover"
            position="auto"
            customTarget={
              <p className="w-32 truncate">
                {rowData.artists.map((artist, index) => {
                  return (
                    <>
                      {artist.name}
                      {index !== rowData.artists.length - 1 ? ", " : ""}
                    </>
                  );
                })}
              </p>
            }
          >
            <Menu>
              {rowData.artists.map(artist => {
                return (
                  <MenuItem.Button
                    className="no-underline"
                    key={artist.id}
                    to={`/${artistModuleName().toLowerCase()}/${artist.id}`}
                  >
                    {artist.name}
                  </MenuItem.Button>
                );
              })}
            </Menu>
          </Dropdown>
        );
      },
    },
    {
      title: "Enquired On",
      dataIndex: "enquiryDate",
      sorter: true,
      field: "enquiry_date",
      width: 180,
      key: "enquiryDate",
      render: enquiryDate => dayjs(enquiryDate).format("DD/MM/YYYY"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: true,
      field: "status",
      width: 130,
      render: status => {
        return (
          <Tag
            type="solid"
            style={getJobsStatusColor(status)}
            label={humanize(status)}
          />
        );
      },
    },
  ];

  const handleTableChange = (_, __, sorter) => {
    setInitial(false);
    setSortProps(sorter);
  };

  if (jobListLoading) {
    return (
      <div
        className="flex items-center justify-center w-full"
        style={{ height: "calc(100vh - 134px)" }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <SubHeader
        className="px-6 pt-4"
        leftActionBlock={
          <Typography style="h4" component="h4" weight="semibold">
            {totalRecords > 1 ? `${totalRecords} Jobs` : `${totalRecords} Job`}
          </Typography>
        }
        rightActionBlock={
          <div className="flex items-center space-x-3">
            <Input
              size="small"
              type="search"
              placeholder="Search"
              className="neeto-ui-header__search-input"
              prefix={<Search size={16} />}
              value={searchParams}
              onChange={e => setSearchParams(e.target.value)}
              clear={() => setSearchParams("")}
            />
            <Button
              size="small"
              label="Add Job"
              className="px-3"
              onClick={() => setNewJobPane(true)}
            />
          </div>
        }
      />
      <div
        className="px-4 mb-6"
        style={{ height: "calc(100vh - 280px)" }}
      >
        {!R.isEmpty(jobList) ? (
          <Table
            columnData={COLUMN_DATA}
            rowData={jobList}
            fixedHeight
            totalCount={totalRecords}
            currentPageNumber={pageIndex}
            defaultPageSize={pageSize}
            handlePageChange={(page, pageSize) => {
              setInitial(false);
              setPageIndex(page);
              setPageSize(pageSize);
            }}
            onChange={(pagination, filters, sorter) =>
              handleTableChange(pagination, filters, sorter)
            }
            paginationProps={{
              showSizeChanger: false,
            }}
          />
        ) : (
          <EmptyState
            image={emptyImage}
            title="No Jobs Found"
            primaryButtonProps={{
              label: "New Job",
              onClick: () => setNewJobPane(true),
            }}
          />
        )}

        <NewJob
          isOpen={newJobPane}
          onClose={() => setNewJobPane(false)}
          userId={staffId}
          initialFocusRef={initialFocusRef}
        />
      </div>
    </>
  );
};

export default Jobs;

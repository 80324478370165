import React, { useState, useEffect, useContext } from "react";
import { Plus, Delete, Redo } from "@bigbinary/neeto-icons";
import {
  Button,
  Typography,
  Input,
  Select,
  Alert,
  Spinner,
} from "@bigbinary/neetoui";
import Pluralize from "pluralize";
import { components } from "react-select";
import { updateLicense, destroyLicense } from "apis/jobs/licenses";
import { destroyLicenseItem } from "apis/jobs/license_items";
import { showToastrError } from "common";
import produce from "immer";
import { UsageDetailsContext } from "./index";

const UsageItemDetail = ({
  item,
  usageItemFromUsage,
  revisionId,
  usageListRecords,
}) => {
  const {
    partiallyApprovedRevisionId,
    categoryList,
    detailList,
    quantityList,
    territoryList,
    periodList,
    exclusivityPeriodList,
  } = useContext(UsageDetailsContext);
  const [usageItemList, setUsageItemList] = useState([]);
  const [title, setTitle] = useState("");
  const [terms, setTerms] = useState("");
  const [deleteUsage, setDeleteUsage] = useState(false);
  const [deleteUsageItem, setDeleteUsageItem] = useState(false);
  const [deletingUsageItemId, setDeletingUsageItemId] = useState("");
  const [usageFee, setUsageFee] = useState(Number(item.fee));
  const [feeChange, setFeeChange] = useState(false);
  const [itemLoad, setItemLoad] = useState(true);
  const [btnLoader, setBtnLoader] = useState(false);

  useEffect(() => {
    if (item) {
      setItemLoad(false);
      setUsageFee(Number(item.fee).toFixed(2));
      setTitle(item.title);
      setTerms(item.terms);
    }
  }, [item]);

  useEffect(() => {
    setUsageItemList(usageItemFromUsage);
  }, [usageItemFromUsage]);

  useEffect(() => {
    let total = 0;
    usageItemList?.map(item => {
      total = Number(total) + Number(item.fee);
    });

    if (feeChange) {
      setUsageFee(Number(total).toFixed(2));
    }
  }, [usageItemList]);

  const updateUsageEntry = async usageId => {
    setBtnLoader(true);
    try {
      let payload = {
        license: {
          title: title,
          terms: terms,
          fee: usageFee,
          license_items_attributes: usageItemList,
        },
      };
      await updateLicense(revisionId, usageId, payload);
      usageListRecords();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      setBtnLoader(false);
    }
  };

  const destroyUsageEntry = async () => {
    try {
      await destroyLicense(revisionId, item.id);
      setDeleteUsage(false);
      usageListRecords();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const destroyUsageItemEntry = async usageItemId => {
    try {
      await destroyLicenseItem(item.id, usageItemId);
      setDeleteUsageItem(false);
      usageListRecords();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const optionList = (data, itemValue) => {
    const item = data.find(el => el.label === itemValue)

    if (item) {
      return data;
    } else if (itemValue?.length > 0) {
      return [...data, { label: itemValue, value: itemValue }]
    } else {
      return data;
    }
  }

  if (itemLoad) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <div className="w-full p-6 bg-gray-100 rounded-md">
        <div className="grid grid-cols-4 gap-4">
          <Input
            autoFocus
            label="Usage Title"
            className="col-span-1"
            disabled={partiallyApprovedRevisionId}
            value={title}
            onChange={e => {
              setTitle(e.target.value);
            }}
          />

          <Input
            label="Usage Notes"
            className="col-span-3"
            disabled={partiallyApprovedRevisionId}
            value={terms}
            onChange={e => {
              setTerms(e.target.value);
            }}
          />
        </div>
        {usageItemList?.map((item, index) => {
          return (
            <div className="relative mt-4" key={index}>
              <div className="px-4 py-4 bg-white rounded-md">
                <div className="flex items-center justify-between">
                  <Typography style="h5" weight="semibold">
                    {item.name}
                  </Typography>
                  <div className="flex space-x-2">
                    <Button
                      style="secondary"
                      label="Mark fields as Excluded"
                      disabled={partiallyApprovedRevisionId}
                      onClick={() =>
                        setUsageItemList(
                          produce(draft => {
                            draft[index] = {
                              ...draft[index],
                              detail: "Excluded",
                              quantity: "Excluded",
                              territory: "Excluded",
                              period: "Excluded",
                              fee: null,
                              exclusivityPeriod: "Excluded",
                            };
                          })
                        )
                      }
                    />
                    <Button
                      style="danger-text"
                      icon={Delete}
                      disabled={partiallyApprovedRevisionId}
                      onClick={() => {
                        if (item.id) {
                          setDeleteUsageItem(true);
                          setDeletingUsageItemId(item.id);
                        } else {
                          setUsageItemList(state => {
                            let newState = [...state];
                            newState.pop();
                            return newState;
                          });
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-6 gap-4 mt-4">
                  <Select
                    isCreateable
                    label="Category"
                    name="category"
                    className="col-span-1"
                    isDisabled={partiallyApprovedRevisionId}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    options={optionList(categoryList, item.category)}
                    value={
                      categoryList.find(cat => cat.label === item.category) || {
                        label: item.category,
                        value: item.category,
                      }
                    }
                    onChange={e => {
                      setUsageItemList(
                        produce(draft => {
                          draft[index] = {
                            ...draft[index],
                            category: e.label,
                          };
                        })
                      );
                    }}
                    onCreateOption={inputValue => {
                      setUsageItemList(
                        produce(draft => {
                          draft[index] = {
                            ...draft[index],
                            category: inputValue,
                          };
                        })
                      );
                    }}
                  />

                  <div className="flex flex-col col-span-5">
                    {item.detail ? (
                      <div className="flex items-end space-x-2">
                        <Input
                          label="Detail"
                          value={item.detail}
                          disabled={partiallyApprovedRevisionId}
                          onChange={e => {
                            setUsageItemList(
                              produce(draft => {
                                draft[index] = {
                                  ...draft[index],
                                  detail: e.target.value,
                                };
                              })
                            );
                          }}
                        />
                        <Button
                          style="text"
                          icon={Redo}
                          disabled={partiallyApprovedRevisionId}
                          tooltipProps={{
                            content: "Reset",
                            position: "top",
                          }}
                          onClick={() => {
                            setUsageItemList(
                              produce(draft => {
                                draft[index] = {
                                  ...draft[index],
                                  detail: null,
                                };
                              })
                            );
                          }}
                        />
                      </div>
                    ) : (
                      <Select
                        isCreateable
                        label="Detail"
                        name="detail"
                        isDisabled={partiallyApprovedRevisionId}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        options={optionList(detailList, item.detail)}
                        value={
                          detailList.find(
                            cat => cat.label === item.category
                          ) || {
                            label: item.detail,
                            value: item.detail,
                          }
                        }
                        onChange={e => {
                          setUsageItemList(
                            produce(draft => {
                              draft[index] = {
                                ...draft[index],
                                detail: e.label,
                              };
                            })
                          );
                        }}
                        onCreateOption={inputValue => {
                          setUsageItemList(
                            produce(draft => {
                              draft[index] = {
                                ...draft[index],
                                detail: inputValue,
                              };
                            })
                          );
                        }}
                      />
                    )}
                  </div>

                  <Select
                    isCreateable
                    label="Quantity"
                    name="quantity"
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    options={optionList(quantityList, item.quantity)}
                    isDisabled={partiallyApprovedRevisionId}
                    className="col-span-1 "
                    value={
                      quantityList.find(cat => cat.label === item.quantity) || {
                        label: item.quantity,
                        value: item.quantity,
                      }
                    }
                    onChange={e => {
                      setUsageItemList(
                        produce(draft => {
                          draft[index] = {
                            ...draft[index],
                            quantity: e.label,
                          };
                        })
                      );
                    }}
                    onCreateOption={inputValue => {
                      setUsageItemList(
                        produce(draft => {
                          draft[index] = {
                            ...draft[index],
                            quantity: inputValue,
                          };
                        })
                      );
                    }}
                  />
                  <Select
                    isCreateable
                    label="Territory"
                    name="territory"
                    isDisabled={partiallyApprovedRevisionId}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    className="col-span-2 "
                    options={optionList(territoryList, item.territory)}
                    value={
                      territoryList.find(
                        territory => territory.label === item.territory
                      ) || { label: item.territory, value: item.territory }
                    }
                    onChange={e => {
                      setUsageItemList(
                        produce(draft => {
                          draft[index] = {
                            ...draft[index],
                            territory: e.label,
                          };
                        })
                      );
                    }}
                    onCreateOption={inputValue => {
                      setUsageItemList(
                        produce(draft => {
                          draft[index] = {
                            ...draft[index],
                            territory: inputValue,
                          };
                        })
                      );
                    }}
                  />

                  <Select
                    isCreateable
                    label="Period"
                    name="period"
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    options={optionList(periodList, item.period)}
                    labelProps={{
                      helpIconProps: {
                        tooltipProps: {
                          content: "Please enter number of months only",
                          position: "auto",
                        },
                      },
                    }}
                    isDisabled={partiallyApprovedRevisionId}
                    className="col-span-1 period-select-container"
                    value={
                      periodList.find(cat => cat.label === item.period) || {
                        label: item.period,
                        value: item.period,
                      }
                    }
                    onChange={e => {
                      setUsageItemList(
                        produce(draft => {
                          draft[index] = {
                            ...draft[index],
                            period: e.label,
                            exclusivityPeriod: e.label
                          };
                        })
                      );
                    }}
                    components={{
                      SingleValue: ({ children, ...props }) => {
                        return (
                          <components.SingleValue {...props}>
                            {!isNaN(Number(children))
                              ? children + Pluralize(" month", Number(children))
                              : children}
                          </components.SingleValue>
                        );
                      },
                      Placeholder: ({ children, ...props }) => {
                        return (
                          <components.Placeholder {...props}>
                            {children + " month"}
                          </components.Placeholder>
                        );
                      },
                      IndicatorSeparator: () => null,
                    }}
                    onCreateOption={inputValue => {
                      setUsageItemList(
                        produce(draft => {
                          draft[index] = {
                            ...draft[index],
                            period: inputValue,
                            exclusivityPeriod: inputValue,
                          };
                        })
                      );
                    }}
                  />

                  <Select
                    isCreateable
                    label="Exclusivity Period"
                    name="exclusivityPeriod"
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    options={optionList(exclusivityPeriodList, item.exclusivityPeriod)}
                    labelProps={{
                      helpIconProps: {
                        tooltipProps: {
                          content: "Please enter number of months only",
                          position: "auto",
                        },
                      },
                    }}
                    isDisabled={partiallyApprovedRevisionId}
                    value={
                      exclusivityPeriodList.find(
                        cat => cat.label === item.exclusivityPeriod
                      ) || {
                        label: item.exclusivityPeriod,
                        value: item.exclusivityPeriod,
                      }
                    }
                    onChange={e => {
                      setUsageItemList(
                        produce(draft => {
                          draft[index] = {
                            ...draft[index],
                            exclusivityPeriod: e.label,
                          };
                        })
                      );
                    }}
                    onCreateOption={inputValue => {
                      setUsageItemList(
                        produce(draft => {
                          draft[index] = {
                            ...draft[index],
                            exclusivityPeriod: inputValue,
                          };
                        })
                      );
                    }}
                    components={{
                      SingleValue: ({ children, ...props }) => {
                        return (
                          <components.SingleValue {...props}>
                            {!isNaN(Number(children))
                              ? children + Pluralize(" month", Number(children))
                              : children}
                          </components.SingleValue>
                        );
                      },
                      Placeholder: ({ children, ...props }) => {
                        return (
                          <components.Placeholder {...props}>
                            {children + " month"}
                          </components.Placeholder>
                        );
                      },
                      IndicatorSeparator: () => null,
                    }}
                  />

                  {item.fee === null ? (
                    <Input
                      label="Fee"
                      value="Excluded"
                      onChange={e => {
                        setFeeChange(true);
                        setUsageItemList(
                          produce(draft => {
                            draft[index] = {
                              ...draft[index],
                              fee: e.target.value,
                            };
                          })
                        );
                      }}
                      disabled={partiallyApprovedRevisionId}
                    />
                  ) : (
                    <Input
                      type="number"
                      label="Fee"
                      className="col-span-1"
                      disabled={partiallyApprovedRevisionId}
                      value={item.fee}
                      onChange={e => {
                        setFeeChange(true);
                        setUsageItemList(
                          produce(draft => {
                            draft[index] = {
                              ...draft[index],
                              fee: e.target.value,
                            };
                          })
                        );
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          );
        })}

        <div className="flex items-center justify-between w-full mt-4">
          <div className="self-start w-40">
            <Input
              type="number"
              label="Fee"
              disabled={partiallyApprovedRevisionId}
              value={usageFee}
              onChange={e => {
                setFeeChange(false);
                setUsageItemList(state => {
                  let arr = [...state];

                  let newArr = arr.map(a => {
                    return { ...a, fee: 0 };
                  });

                  return newArr;
                });

                setUsageFee(e.target.value);
              }}
            />
          </div>
          <Button
            style="link"
            disabled={partiallyApprovedRevisionId}
            icon={Plus}
            iconPosition="left"
            label="Add Usage Item"
            onClick={() => {
              setUsageItemList(state => {
                return [
                  ...state,
                  {
                    name: `Usage Category ${usageItemList.length + 1}`,
                    category: "",
                    detail: "",
                    quantity: "",
                    period: "",
                    exclusivity_period: "",
                    fee: 0,
                    serial: usageItemList?.length + 1,
                  },
                ];
              });
            }}
          />
        </div>

        <div className="flex justify-end mt-8 space-x-2">
          <Button
            style="danger-text"
            label="Delete"
            onClick={() => setDeleteUsage(true)}
            disabled={partiallyApprovedRevisionId || item.kind === "master"}
          />
          <Button
            label="Save Changes"
            onClick={() => updateUsageEntry(item.id)}
            disabled={partiallyApprovedRevisionId}
            loading={btnLoader}
          />
        </div>
      </div>

      <Alert
        isOpen={deleteUsageItem}
        title="Delete Usage Item"
        message="Are you sure you want to delete the Usage Item? Once deleted, it will not be further considered."
        onClose={() => setDeleteUsageItem(false)}
        onSubmit={() => destroyUsageItemEntry(deletingUsageItemId)}
        submitButtonLabel="Yes, delete"
        cancelButtonLabel="No, cancel"
      />

      <Alert
        isOpen={deleteUsage}
        title="Delete Usage"
        message="Are you sure you want to delete the Usage? Once destroyed, it will not be further considered."
        onClose={() => setDeleteUsage(false)}
        onSubmit={() => destroyUsageEntry()}
        submitButtonLabel="Yes, delete"
        cancelButtonLabel="No, cancel"
      />
    </>
  );
};

export default UsageItemDetail;

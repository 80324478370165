import axios from "axios";

export const getEstimateBuilder = (token, usageType = "") => {
  return axios.get(
    `/api/v1/email_templates/estimates?token=${token}&usge_type=${usageType}`
  );
};

export const getEstimateOverageBuilder = token => {
  return axios.get(`/api/v1/email_templates/overages?token=${token}`);
};

export const getBookingFormBuilder = (token, artistId) => {
  return axios.get(
    `/api/v1/email_templates/booking_forms?token=${token}&artist_id=${artistId}`
  );
};

export const getStandardLicenseBuilder = token => {
  return axios.get(`/api/v1/email_templates/licenses?token=${token}`);
};

export const getRciBuilder = token => {
  return axios.get(`/api/v1/email_templates/rcis?token=${token}`);
};

export const getInvoiceBuilder = token => {
  return axios.get(`api/v1/email_templates/invoices?token=${token}`);
};

import * as Yup from "yup";

export const COMPANY_DETAILS_VALIDATION_SCHEMA = Yup.object({
  name: Yup.string().required("Name can't be empty"),
  locationId: Yup.string().required("Location can't be empty"),
  currencyId: Yup.string().required("Currency can't be empty"),
  taxRate: Yup.mixed().when("taxTypeId", {
    is: val => val !== null,
    then: Yup.number().required("Rate can't be empty").min(1),
  }),
});

export const COMPANY_DETAILS_INITIAL_VALUE = {
  name: "",
  locationId: "",
  currencyId: "",
  streetAddress: "",
  suburb: "",
  state: "",
  postcode: "",
  abn: "",
  vatCode: "",
  bankName: "",
  branchName: "",
  swiftCode: "",
  bsbCode: "",
  accountNumber: "",
  accountName: "",
  iban: "",
  sortCode: "",
  routingCode: "",
  phoneNumber: "",
  showInTemplate: false,
  basecampBotEmail: "",
  basecampHeadEmail: "",
  jobSerial: "",
  invoiceSerial: "",
  licenseSerial: "",
  rciSerial: "",
  taxTypeId: null,
};

export const COUNTRY_LIST = [
  {
    label: "United States",
    value: "USA",
  },
  {
    label: "United Kingdom",
    value: "UK",
  },
  {
    label: "Australia",
    value: "AU",
  },
];

export const TAB_CONSTANTS = {
  CompanyDetails: {
    label: "Company",
  },
  BankDetails: {
    label: "Bank",
  },
  BasecampDetails: {
    label: "Basecamp",
  },
  XeroDetails: {
    label: "Xero",
  },
  SerialDetails: {
    label: "Serial",
  },
};

import React, { useState, useEffect, useContext } from "react";
import { Plus, Search, Lock } from "@bigbinary/neeto-icons";
import { Button, Spinner, Input, Typography } from "@bigbinary/neetoui";
import { getEstimateLineItems } from "apis/settings/estimate_line_items";
import { showToastrError } from "common";
import { groupByCategory } from "common/helper";
import AddLineItem from "./AddLineItem";
import { EstimateContext } from "../index";

import Items from "./Items";

const ItemsDirectory = ({
  handleLineItemClick,
  handleLineItemCategoryClick,
}) => {
  const {
    revisionId,
    approvedRevisionId,
    finalInvoice,
    commencementInvoice,
    finalRci,
    commencementRci,
  } = useContext(EstimateContext);
  const [lineItemList, setLineItemList] = useState([]);
  const [lineItemListLoad, setLineItemListLoad] = useState(true);
  const [searchParams, setSearchParams] = useState("");
  const [addModalState, setAddModalState] = useState(false);

  useEffect(() => {
    loadEstimateLineItemData();
  }, []);

  const loadEstimateLineItemData = async () => {
    try {
      const response = await getEstimateLineItems();
      setLineItemList(response.data.estimateLineItems);
      setLineItemListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const filteredLineItems = () => {
    if (searchParams.length === 0) {
      return groupByCategory(lineItemList);
    }

    let filteredItems = lineItemList.filter(
      item =>
        item.name.toLowerCase().includes(searchParams.toLowerCase()) ||
        item.lineItemCategoryName
          .toLowerCase()
          .includes(searchParams.toLowerCase())
    );

    return groupByCategory(filteredItems);
  };

  if (lineItemListLoad) {
    return (
      <div
        className="flex items-center justify-center w-full px-4 py-10 border rounded-md neeto-ui-border-gray-200"
        style={{ height: "200px" }}
      >
        <Spinner />
      </div>
    );
  }

  if (
    (approvedRevisionId && approvedRevisionId !== revisionId) ||
    finalInvoice ||
    commencementInvoice ||
    finalRci ||
    commencementRci
  ) {
    return (
      <div className="flex flex-col items-center justify-center px-4 py-10 border rounded-md neeto-ui-border-gray-200">
        <Lock size={36} />
        <Typography style="h5" weight="semibold" className="mt-3">
          Estimate Revision is locked.
        </Typography>
      </div>
    );
  }

  return (
    <>
      <aside className="p-4 bg-white border rounded-md cursor-pointer neeto-ui-border-gray-200 hover:neeto-ui-border-gray-500 neeto-ui-shadow-xs focus:neeto-ui-border-gray-500">
        <div className="flex items-center justify-between mb-3">
          <div className="flex flex-col space-y-1">
            <Typography style="h4" weight="semibold">
              Fee Items
            </Typography>
            <Typography style="body3">
              Select a billable item to client.
            </Typography>
          </div>
          <Button
            style="secondary"
            icon={Plus}
            onClick={() => {
              setAddModalState(true);
            }}
          />
        </div>
        <form
          className="mb-4"
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <Input
            prefix={<Search size={16} />}
            value={searchParams}
            onChange={e => setSearchParams(e.target.value)}
          />
        </form>
        <nav
          className="flex-1 h-full overflow-y-auto"
          aria-label="Directory"
          style={{ maxHeight: "215px" }}
        >
          {Object.entries(filteredLineItems()).map((itemGroup, index) => {
            return (
              <Items
                key={index}
                itemGroup={itemGroup}
                handleLineItemClick={handleLineItemClick}
                handleLineItemCategoryClick={handleLineItemCategoryClick}
                lineItemList={lineItemList}
              />
            );
          })}
        </nav>
      </aside>
      <AddLineItem
        addModalState={addModalState}
        loadEstimateLineItemData={loadEstimateLineItemData}
        setAddModalState={setAddModalState}
        setLineItemListLoad={setLineItemListLoad}
      />
    </>
  );
};

export default ItemsDirectory;

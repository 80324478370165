import React, { useState, useEffect } from "react";
import { isEmpty } from "ramda";
import * as dayjs from "dayjs";
import { Table, Button, Alert, Dropdown } from "@bigbinary/neetoui";
import { MenuHorizontal } from "@bigbinary/neeto-icons";
import { numberWithCommas, getRandomNotFoundImage } from "common/helper";
import EmptyState from "components/Common/EmptyState";
import { updateInvoice } from "apis/dashboards";
import { showToastrError } from "common";
import { useUserState } from "contexts/user";

const InvoiceList = ({
  invoices,
  getInvoices,
  setShowListModal,
  getCurrentWeekStatisticsData,
}) => {
  const { user } = useUserState();
  const [invoiceId, setInvoiceId] = useState();
  const [invoiceName, setInvoiceName] = useState("");
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const { Menu, MenuItem } = Dropdown;
  const [emptyImage, setEmptyImage] = useState();

  useEffect(() => {
    setEmptyImage(getRandomNotFoundImage())
  }, [])

  const COLUMN_DATA = [
    {
      title: "Serial",
      dataIndex: "serialNumber",
      key: "serialNumber",
      width: 100,
      render: (_, rowData) => (
        <Button
          style="link"
          to={`/invoices/${rowData.id}`}
          label={rowData.serialNumber}
        />
      ),
    },
    {
      title: "Job Name",
      dataIndex: "jobName",
      key: "jobName",
    },
    {
      title: "Organisation",
      dataIndex: "organisationName",
      key: "organisationName",
    },
    {
      title: "Issued On",
      dataIndex: "issueDate",
      key: "issueDate",
      width: 150,
      render: issueDate => dayjs(issueDate).format("DD/MM/YYYY"),
    }
  ];

  if (user.access_amount_in_counter) {
    COLUMN_DATA.push(
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        render: (_, rowData) => (
          <div className="flex flex-row items-center">
            {`${numberWithCommas(Number(rowData.amount).toFixed(2))} ${
              rowData.currency
            }`}
          </div>
        ),
      },
      {
        title: "",
        dataIndex: "actions",
        key: "actions",
        render: (_, rowData) => (
          <Dropdown
            autoWidth
            closeOnSelect
            buttonStyle="text"
            icon={MenuHorizontal}
          >
            <Menu>
              <MenuItem.Button
                onClick={() => {
                  setInvoiceId(rowData.id);
                  setInvoiceName(rowData.jobName);
                  setDeleteAlertOpen(true);
                }}
              >
                Delete
              </MenuItem.Button>
            </Menu>
          </Dropdown>
        ),
      }
    );
  }

  const onUpdate = async () => {
    try {
      let payload = {
        invoice: {
          enableInCounter: false,
        },
      };

      await updateInvoice(invoiceId, payload);
      getInvoices();
      setDeleteAlertOpen(false);
      setShowListModal(false);
      getCurrentWeekStatisticsData();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  return (
    <div className="w-full overflow-y-auto" style={{ height: "calc(60vh)" }}>
      {!isEmpty(invoices) ? (
        <Table
          fixedHeight
          allowRowClick={false}
          columnData={COLUMN_DATA}
          rowData={invoices}
          currentPageNumber={pageIndex}
          defaultPageSize={pageSize}
          handlePageChange={(page, pageSize) => {
            setPageIndex(page);
            setPageSize(pageSize);
          }}
          paginationProps={{
            showSizeChanger: false,
          }}
        />
      ) : (
        <EmptyState
          image={emptyImage}
          title="No Invoices Found"
          description="We couldn’t find any invoice. Try creating one."
        />
      )}

      <Alert
        isOpen={deleteAlertOpen}
        title="Delete Invoice"
        message={
          <>
            Are you sure you want to delete invoice{" "}
            <strong>{invoiceName}?</strong>
          </>
        }
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, delete"
        onClose={() => setDeleteAlertOpen(false)}
        onSubmit={() => onUpdate()}
      />
    </div>
  );
};

export default InvoiceList;

import React, { useState, useEffect } from "react";
import { MenuHorizontal } from "@bigbinary/neeto-icons";
import {
  Input,
  Button,
  Textarea,
  Spinner,
  Alert,
  Dropdown,
  Table,
} from "@bigbinary/neetoui";
import * as dayjs from "dayjs";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import * as R from "ramda";
import { Container, Header } from "@bigbinary/neetoui/layouts";
import {
  getJob,
  getJobAttachments,
  createJobAttachment,
  updateJobAttachment,
  destroyJobAttachment,
} from "apis/jobs/contracts";
import { getEstimateRevisions } from "apis/jobs/estimate_revisions";
import { showToastrError } from "common";
import { getRandomNotFoundImage } from "common/helper";
import EmptyState from "components/Common/EmptyState";
import AttachmentUploader from "./AttachmentUploader";
import { jobWithNameNumber, getRevisionId } from "./../../common/helper";
import Tabs from "../Tabs";

const Contracts = () => {
  const { id } = useParams();
  const [job, setJob] = useState({});
  const [description, setDescription] = useState();
  const [jobDetailsLoad, setJobDetailsLoad] = useState(true);
  const [estimateRevisions, setEstimateRevisions] = useState([]);
  const [estimateRevisionsLoad, setEstimateRevisionsLoad] = useState(true);
  const [jobAttachments, setJobAttachments] = useState([]);
  const [jobAttachmentLoad, setJobAttachmentLoad] = useState(true);
  const [jobAttachment, setJobAttachment] = useState();
  const [deleteAlertState, setDeleteAlertState] = useState(false);
  const [clearAttachment, setClearAttachment] = useState(false);
  const [btnLoad, setBtnLoad] = useState(false);
  const [initial, setInitial] = useState(true);
  const { Menu, MenuItem } = Dropdown;
  const [emptyImage, setEmptyImage] = useState();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: jobAttachment ? jobAttachment : { description: "", contractUrl: "" },
    onSubmit: () => {
      if (jobAttachment) {
        updateJobAttachmentEntry();
      } else {
        createJobAttachmentEntry();
      }
    },
  });

  useEffect(() => {
    setEmptyImage(getRandomNotFoundImage());
  }, [])

  useEffect(() => {
    if (id) {
      loadJobDetailResponse();
      loadEstimateRevisionsResponse();
      loadJobAttachments();
    }
  }, [id]);

  useEffect(() => {
    if (jobAttachment) {
      setDescription(jobAttachment.description);
    }
  }, [jobAttachment]);

  const loadJobDetailResponse = async () => {
    try {
      const response = await getJob(id);
      setJob(response.data.job);
      setJobDetailsLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadEstimateRevisionsResponse = async () => {
    try {
      const response = await getEstimateRevisions(id);
      setEstimateRevisions(response.data.estimateRevisions);
      setEstimateRevisionsLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadJobAttachments = async () => {
    try {
      const response = await getJobAttachments(id);
      setJobAttachments(response.data.attachments);
      setJobAttachmentLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const createJobAttachmentEntry = async () => {
    setBtnLoad(true);
    try {
      await createJobAttachment(id, {
        job_attachment: {
          ...formik.values.attachments,
          description: description || "",
          attachable_kind: "contract",
          contractUrl: formik.values.contractUrl ? `https://${formik.values.contractUrl}` : null,
        },
      });
      loadJobAttachments();
      formik.resetForm();
      setJobAttachment();
      setDescription("");
      setClearAttachment(true);
      setInitial(true);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      setBtnLoad(false);
    }
  };

  const updateJobAttachmentEntry = async () => {
    setBtnLoad(true);
    try {
      await updateJobAttachment(id, jobAttachment.id, {
        job_attachment: {
          ...formik.values.attachments,
          description: description,
          contractUrl: formik.values.contractUrl ? `https://${formik.values.contractUrl}` : null,
        },
      });
      loadJobAttachments();
      formik.resetForm();
      setJobAttachment();
      setDescription("");
      setClearAttachment(true);
      setInitial(true);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      setBtnLoad(false);
    }
  };

  const destroyJobAttachmentRecord = async () => {
    try {
      await destroyJobAttachment(id, jobAttachment.id);
      loadJobAttachments();
      formik.resetForm();
      setJobAttachment();
      setDescription("");
      setDeleteAlertState(false);
      setClearAttachment(true);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const COLUMN_DATA = [
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 300,
      fixed: "left",
      render: description => (
        <>
          {
            <div
              dangerouslySetInnerHTML={{
                __html: description?.split("\n")?.join("<br>"),
              }}
            ></div>
          }
        </>
      ),
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      render: createdBy => <>{createdBy}</>,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: createdAt => dayjs(createdAt).format("DD/MM/YYYY"),
    },
    {
      title: "URL",
      dataIndex: "contractUrl",
      key: "contractUrl",
      width: 100,
      render: contractUrl => (
        <>
          <a target="_blank" href={contractUrl} rel="noreferrer">
            {contractUrl}
          </a>
        </>
      ),
    },
    {
      title: "Attachment",
      dataIndex: "filename",
      width: 200,
      key: "filename",
      render: (_, rowData) => (
        <>

        <Button
          label={rowData.filename}
          style="link"
          href={rowData.src}
          />
        </>
      ),
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 75,
      fixed: "right",
      render: (_, rowData) => (
        <Dropdown
          autoWidth
          closeOnSelect
          buttonStyle="text"
          strategy="fixed"
          appendTo={() => document.body}
          icon={MenuHorizontal}
        >
          <Menu>
            <MenuItem.Button
              onClick={() => {
                setInitial(true);
                setClearAttachment(false);
                formik.resetForm();
                setJobAttachment(rowData);
              }}
            >
              Edit
            </MenuItem.Button>
            <MenuItem.Button
              style="danger"
              onClick={() => {
                setJobAttachment(rowData);
                setDeleteAlertState(true);
              }}
            >
              Delete
            </MenuItem.Button>
          </Menu>
        </Dropdown>
      ),
    },
  ];

  if (jobDetailsLoad || estimateRevisionsLoad || jobAttachmentLoad) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <Container isHeaderFixed>
      <Header
        title={"Resources"}
        breadcrumbs={[
          { text: "Jobs", link: "/jobs" },
          { text: jobWithNameNumber(job), link: `/jobs/${id}/overview` },
        ]}
      />

      <Tabs jobId={id} revisionId={getRevisionId(estimateRevisions)} />

      <div
        className="flex w-full overflow-hidden"
        style={{ height: "calc(100vh - 134px)" }}
      >
        <div className="w-8/12 p-6 space-y-4 border-r neeto-ui-bg-gray-100 neeto-ui-border-gray-300">
          {!R.isEmpty(jobAttachments) ? (
            <Table
              columnData={COLUMN_DATA}
              rowData={jobAttachments}
              fixedHeight
            />
          ) : (
            <EmptyState image={emptyImage} title="No Resources Found" />
          )}
        </div>
        <div className="w-4/12 p-6 space-y-6 overflow-y-scroll neeto-ui-bg-white">
          <AttachmentUploader
            name="Resources"
            type="contract"
            formValue={formik.values}
            setFormValue={formik.setFieldValue}
            clearAttachment={clearAttachment}
            setClearAttachment={setClearAttachment}
            initial={initial}
            setInitial={setInitial}
            accept="image/png, image/gif, image/jpeg, image/jpg, application/pdf"
          />

          <Input
            label="URL"
            name="contractUrl"
            onChange={formik.handleChange}
            value={formik.values.contractUrl}
            prefix="https://"
            error={
              Boolean(formik.touched.contractUrl && formik.errors.contractUrl) &&
              formik.errors.contractUrl
            }
            {...formik.getFieldProps("contractUrl")}
          />

          <Textarea
            value={description}
            onChange={e => setDescription(e.target.value)}
            label="Description"
            name="description"
          />

          <div className="flex justify-end space-x-2">
            <Button
              label="Save Changes"
              onClick={() => formik.handleSubmit()}
              loading={btnLoad}
            />
            {jobAttachment?.id && (
              <Button
                style="text"
                label="Cancel"
                onClick={() => {
                  setJobAttachment();
                  setDescription("");
                  setClearAttachment(true);
                }}
              />
            )}
          </div>
        </div>
      </div>

      <Alert
        isOpen={deleteAlertState}
        title="Delete Attachment"
        message="Are you sure you want to delete this attachment?"
        onClose={() => setDeleteAlertState(false)}
        onSubmit={() => destroyJobAttachmentRecord()}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, delete"
      />
    </Container>
  );
};

export default Contracts;

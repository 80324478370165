import React from "react";

const BAR_SKELETON_WIDTHS = [83, 65, 62, 44, 32, 19, 10];

const BarLoader = () => (
  <>
    {BAR_SKELETON_WIDTHS.map((value, index) => (
      <div className="flex items-center gap-4" key={index}>
        <div className="flex-grow">
          <div
            className="h-8 rounded-md neeto-ui-bg-gray-200 animate-pulse"
            style={{ width: `${value}%` }}
          />
        </div>
        <div className="w-16 h-5 neeto-ui-bg-gray-200 animate-pulse" />
      </div>
    ))}
  </>
);

export default BarLoader;

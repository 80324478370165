import React, { useState } from "react";
import { MenuHorizontal } from "@bigbinary/neeto-icons";
import { Table, Dropdown, Alert, Button } from "@bigbinary/neetoui";
import { destroyPhaseType } from "apis/settings/phase_types";
import { showToastrError } from "common";
import Pluralize from "pluralize";

const MilestoneList = ({
  isLoading,
  phaseTypeList,
  setPhaseList,
  loadPhaseTypeList,
  setSelectedPhaseTypeId,
  setPhaseTypeName,
  setNewPhase,
  loadPhaseList,
  setShowPane,
}) => {
  const [deleteModalState, setDeleteModalState] = useState(false);
  const [deletePhaseTypeId, setDeletePhaseTypeId] = useState("");
  const { Menu, MenuItem } = Dropdown;
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const COLUMN_DATA = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "60%",
      render: (_, rowData) => (
        <Button
          style="link"
          label={rowData.name}
          onClick={() => {
            setPhaseList([]);
            setSelectedPhaseTypeId(rowData.id);
            setPhaseTypeName(rowData.name);
            loadPhaseList(rowData.id);
            setShowPane(true);
          }}
        />
      ),
      fixed: "left",
    },
    {
      title: "Count",
      dataIndex: "phaseCount",
      key: "phaseCount",
      width: "30%",
      render: (_, rowData) => (
        <>{`${rowData.phaseCount} ${Pluralize(
          " milestone",
          Number(rowData.phaseCount)
        )}`}</>
      ),
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: "10%",
      render: (_, rowData) => (
        <Dropdown
          autoWidth
          closeOnSelect
          buttonStyle="text"
          strategy="fixed"
          icon={MenuHorizontal}
        >
          <Menu>
            <MenuItem.Button
              onClick={() => {
                setPhaseList([]);
                setSelectedPhaseTypeId(rowData.id);
                setPhaseTypeName(rowData.name);
                loadPhaseList(rowData.id);
                setShowPane(true);
              }}
            >
              Edit
            </MenuItem.Button>
            <MenuItem.Button
              style="danger"
              onClick={() => {
                setDeleteModalState(true);
                setDeletePhaseTypeId(rowData.id);
              }}
            >
              Delete
            </MenuItem.Button>
          </Menu>
        </Dropdown>
      ),
    },
  ];

  const destroyPhaseTypeEntry = async () => {
    try {
      await destroyPhaseType(deletePhaseTypeId);
      loadPhaseTypeList();
      setSelectedPhaseTypeId();
      setDeleteModalState(false);
      setNewPhase(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  return (
    <>
      <Table 
        fixedHeight 
        loading={isLoading}
        columnData={COLUMN_DATA}
        rowData={phaseTypeList}
        currentPageNumber={pageIndex}
        defaultPageSize={pageSize}
        handlePageChange={(page, pageSize) => {
          setPageIndex(page);
          setPageSize(pageSize);
        }}
      />
      <Alert
        isOpen={deleteModalState}
        title="Delete Milestone Type"
        message="Are you sure you want to delete the milestone type?"
        onClose={() => setDeleteModalState(false)}
        onSubmit={() => destroyPhaseTypeEntry()}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, delete"
      />
    </>
  );
};

export default MilestoneList;

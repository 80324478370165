import React, { useState, useEffect } from "react";
import { getStandardLicenseBuilder } from "apis/email_templates/builders";
import { getLicensePdf } from "apis/email_templates/pdfs";
import * as dayjs from "dayjs";
import prntr from "prntr";
import { useLocation } from "react-router-dom";
import { Spinner } from "@bigbinary/neetoui";
import { showToastrError } from "common";
import "../Styles/common";
import PrintSave from "../Shared/PrintSave";
import { BorderedTable } from "../Shared";

const RegisteredLicense = () => {
  document.title = "License";
  const location = useLocation();
  const paramToken = location?.search?.split("?token=")[1];
  const [jobDetail, setJobDetail] = useState();
  const [templateBannerImgSrc, setTemplateBannerImgSrc] = useState("");
  const [revision, setRevision] = useState();
  const [masterUsage, setMasterUsage] = useState([]);
  const [emailBackground, setEmailBackground] = useState();
  const [defaultTemplateSetting, setDefaultTemplateSetting] = useState();
  const [
    jobTypeSpecificTemplateSetting,
    setJobTypeSpecificTemplateSetting,
  ] = useState();
  const [
    jobTypeSpecificTemplateSettingLoad,
    setJobTypeSpecificTemplateSettingLoad,
  ] = useState();

  const [jobDetailLoad, setJobDetailLoad] = useState();
  const [masterUsageLoad, setMasterUsageLoad] = useState([]);
  const [emailBackgroundLoad, setEmailBackgroundLoad] = useState(true);
  const [pdfLoad, setPdfLoad] = useState(false);

  const setResourceForPdfFilename = name => {
    switch (name) {
      case "[paperwork_serial]":
        return masterUsage?.serialNumber;
      case "[org_serial]":
        return jobDetail?.organisationSerial;
    }
  };

  const pdfFileName = () => {
    let fileName = "";
    const filenameComponents = emailBackground?.pdfFileName?.split("<>");

    filenameComponents?.map(component => {
      if (component.includes("[")) {
        fileName += setResourceForPdfFilename(component);
      } else {
        fileName += component;
      }
    });

    return fileName;
  };

  useEffect(async () => {
    if (paramToken) {
      getStandardLicenseBuilderRecords();
    }
  }, [paramToken]);

  useEffect(() => {
    if (jobDetail?.thumbnail?.src) {
      setTemplateBannerImgSrc(jobDetail?.thumbnail?.src);
    } else {
      setTemplateBannerImgSrc(
        jobDetail?.account.attachments.find(
          attachment => attachment.attachable_kind === "large_app_logo"
        )?.src
      );
    }
  }, [emailBackground]);

  const getStandardLicenseBuilderRecords = async () => {
    try {
      const response = await getStandardLicenseBuilder(paramToken);
      setJobDetail(response.data.job);
      setMasterUsage(response.data.masterUsage);
      setRevision(response.data.estimateRevision);
      setEmailBackground(response.data.emailBackgroundDetail);
      setJobTypeSpecificTemplateSetting(
        response.data.templateSettings.find(
          template => template.jobTypeId === response.data.job.jobTypeId
        )
      );
      setDefaultTemplateSetting(
        response.data.templateSettings.find(
          template => template.default === true
        )
      );
      setJobTypeSpecificTemplateSettingLoad(false);
      setJobDetailLoad(false);
      setMasterUsageLoad(false);
      setEmailBackgroundLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const getLicensePdfRecordsPrint = async () => {
    setPdfLoad(true);
    try {
      const response = await getLicensePdf(paramToken);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

      if (isSafari) {
        const link = document.createElement("a");
        link.href = url;
        link.download = `${pdfFileName() || "License"}.pdf`;
        link.click();
      } else {
        prntr({
          printable: url,
          type: "pdf",
          showModal: true,
        });
      }
      setPdfLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const isIE11 = navigator.userAgent.indexOf("Trident/") > -1;

  document.addEventListener(
    "keydown",
    function keyDownHandler(event) {
      // Watch for `metaKey` too (⌘ in Mac)
      if ((event.ctrlKey || event.metaKey) && event.keyCode === 80) {
        // Is the browser IE11?
        if (isIE11) {
          const end = Date.now() + 4000; // block the main thread for 4s.
          while (Date.now() < end) {
            // Deliberately no-op
          }
        }

        event.preventDefault();
        event.stopImmediatePropagation();
        getLicensePdfRecordsPrint();
      }
    },
    isIE11 ? { capture: true } : true
  );

  const getLicensePdfRecords = async () => {
    setPdfLoad(true);
    try {
      const response = await getLicensePdf(paramToken);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.download = `${pdfFileName() || "License"}.pdf`;
      link.click();
      setPdfLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const templateLogo = () => {
    return jobTypeSpecificTemplateSetting?.attachments?.find(
      attachment => attachment.attachable_kind === "template_logo"
    );
  };

  const jobLogo = () => {
    const largeAppLogo = jobDetail?.account.attachments.find(
      attachment => attachment.attachable_kind === "large_app_logo"
    );

    if (templateLogo()) {
      return templateLogo().src;
    } else if (jobDetail?.thumbnail.src || !largeAppLogo) {
      return defaultTemplateSetting?.attachments?.find(
        attachment => attachment.attachable_kind === "pdf_footer_logo"
      )?.src;
    }
  };

  const getUsageLineItems = [
    {
      option: {
        hasCheckbox: false,
        id: masterUsage?.id,
        hasTitle: true,
        title: masterUsage?.title,
        description: masterUsage?.terms,
      },
      table: {
        heads: [
          "Category",
          "Qty",
          "Territory",
          "Start Date",
          "Duration",
          "End Date",
          "Exclusivity",
        ],
        rows: masterUsage?.licenseItems?.map(item => {
          return {
            values: [
              <>
                <strong>{item.category}</strong> <br />
                <div className="w-60">{item.detail}</div>
              </>,
              item.quantity,
              item.territory,
              item?.startDate
                ? dayjs(item?.startDate).format("DD MMMM YYYY")
                : "-",
              <div className="whitespace-no-wrap" key={item.id}>
                {item.period}
              </div>,
              item?.endDate ? dayjs(item?.endDate).format("DD MMMM YYYY") : "-",
              <div className="whitespace-no-wrap" key={item.id}>
                {item.exclusivityPeriod}
              </div>,
            ],
          };
        }),
      },
    },
  ];

  if (
    jobDetailLoad ||
    masterUsageLoad ||
    emailBackgroundLoad ||
    pdfLoad ||
    jobTypeSpecificTemplateSettingLoad
  ) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="relative w-full px-4 py-8 bg-white lg:p-8 jw-template">
      <div className="flex flex-col w-full h-full">
        <div className="flex flex-col w-full">
          <div className="flex flex-col items-start justify-between w-full mb-12 space-x-0 space-y-4 lg:flex-row lg:space-y-0">
            <div
              className="flex flex-row items-center justify-between w-full h-full lg:items-start lg:flex-col lg:w-1/3"
              style={{ fontFamily: "Modern Gothic" }}
            >
              <span className="mb-0 text-xl font-medium leading-6 lg:mb-10">
                Registered License
              </span>
              {jobLogo() && (
                <div
                  className={`logo-container ${
                    templateLogo() ? "template-logo-container" : ""
                  }`}
                >
                  <img src={jobLogo()} alt="logo" />
                </div>
              )}
            </div>
            <div className="hidden w-full grid-cols-3 gap-4 font-light lg:grid lg:w-2/3 lg:gap-6">
              <div
                className="flex flex-col justify-start divide-y divide-black"
                style={{ fontFamily: "Modern Gothic Mono" }}
              >
                <div className="flex flex-col pb-2 text-sm">
                  <span>License Number</span>
                  <span>{masterUsage?.serialNumber}</span>
                </div>
                <div className="flex flex-col py-2 text-sm">
                  <span>Job Number</span>
                  <span>{jobDetail?.serialNumber}</span>
                </div>
                <div className="flex flex-col pt-2 text-sm">
                  <span>Client</span>
                  <span>{jobDetail?.organisationName}</span>
                </div>
              </div>
              <div
                className="flex flex-col justify-start divide-y divide-black"
                style={{ fontFamily: "Modern Gothic Mono" }}
              >
                <div className="flex flex-col pb-2 text-sm">
                  <span>Estimate Number</span>
                  <span>{revision?.serialNumber}</span>
                </div>
                <div className="flex flex-col py-2 text-sm">
                  <span>Project</span>
                  <span>{jobDetail?.name}</span>
                </div>
                <div className="flex flex-col pt-2 text-sm">
                  <span>End Client</span>
                  <span>{jobDetail?.parentOrganisation}</span>
                </div>
              </div>
              <div
                className="flex flex-col justify-start divide-y divide-black"
                style={{ fontFamily: "Modern Gothic Mono" }}
              >
                <div className="flex flex-col pb-2 text-sm">
                  <span>Issued On</span>
                  <span>
                    {dayjs(masterUsage?.issueDate).format("DD MMMM YYYY")}
                  </span>
                </div>
                <div className="flex flex-col py-2 text-sm">
                  <span>Talent/s</span>
                  <span>{jobDetail?.artistFullNames}</span>
                </div>
                <div className="flex flex-col pt-2 text-sm">
                  <span>Prepared for</span>
                  <span>{jobDetail?.contactFullName}</span>
                </div>
              </div>
            </div>
            <div className="grid w-full grid-cols-2 gap-2 font-light lg:hidden">
              <div
                className="flex flex-col justify-start divide-y divide-black"
                style={{ fontFamily: "Modern Gothic Mono" }}
              >
                <div className="flex flex-col pb-2 text-sm">
                  <span>License Number</span>
                  <span>{masterUsage?.serialNumber}</span>
                </div>
                <div className="flex flex-col py-2 text-sm">
                  <span>Job Number</span>
                  <span>{jobDetail?.serialNumber}</span>
                </div>
                <div className="flex flex-col py-2 text-sm">
                  <span> Prepared For</span>
                  <span>{jobDetail?.contactFullName}</span>
                </div>
                <div className="flex flex-col pt-2 text-sm">
                  <span>End Client</span>
                  <span>{jobDetail?.parentOrganisation}</span>
                </div>
              </div>
              <div
                className="flex flex-col justify-start divide-y divide-black"
                style={{ fontFamily: "Modern Gothic Mono" }}
              >
                <div className="flex flex-col pb-2 text-sm">
                  <span>Estimate Number</span>
                  <span>{revision?.serialNumber}</span>
                </div>
                <div className="flex flex-col py-2 text-sm">
                  <span>Client</span>
                  <span>{jobDetail?.organisationName}</span>
                </div>
                <div className="flex flex-col pt-2 text-sm">
                  <span>Talent/s</span>
                  <span>{jobDetail?.artistFullNames}</span>
                </div>
                <div className="flex flex-col py-2 text-sm">
                  <span>Project</span>
                  <span>{jobDetail?.name}</span>
                </div>
              </div>
            </div>
          </div>

          {jobDetail.logoOnlyTemplate || !jobDetail?.thumbnail?.src ? (
            <div
              className="overflow-hidden flex justify-center"
              style={{ height: 200 }}
            >
              <img src={templateBannerImgSrc} className="w-auto" />
            </div>
          ) : (
            <div className="overflow-hidden">
              <img src={templateBannerImgSrc} className="w-full h-full" />
            </div>
          )}
        </div>
        <div
          className="flex flex-row items-start self-end justify-start w-full h-full mt-12 lg:w-2/3"
          style={{ fontFamily: "Modern Gothic" }}
        >
          <BorderedTable
            data={getUsageLineItems}
            showNotes={masterUsage?.terms?.length > 0}
            notes={masterUsage?.terms}
            noNote={true}
          />
        </div>
      </div>
      <PrintSave
        getPdfRecordsPrint={getLicensePdfRecordsPrint}
        getPdfRecords={getLicensePdfRecords}
      />
    </div>
  );
};

export default RegisteredLicense;

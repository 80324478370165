import React, { useState, useEffect, useRef } from "react";
import { Search } from "@bigbinary/neeto-icons";
import {
  Button,
  Table,
  Tag,
  Spinner,
  Typography,
  Input,
} from "@bigbinary/neetoui";
import { SubHeader } from "@bigbinary/neetoui/layouts";
import EmptyState from "components/Common/EmptyState";
import * as dayjs from "dayjs";
import * as R from "ramda";
import { showToastrError } from "common";
import useDebounce from "common/debounce";
import { humanize, getJobsStatusColor } from "common/helper";
import { DEFAULT_PAGE_SIZE } from "common/constants";
import { getArtistJobs } from "apis/artists/jobs";
import NewJob from "components/Dashboard/Jobs/NewJob";
import { getRandomNotFoundImage } from "common/helper";

const Jobs = ({ artistDetail }) => {
  const initialFocusRef = useRef(null);
  const [searchParams, setSearchParams] = useState();
  const debouncedSearchTerm = useDebounce(searchParams, 500);
  const [jobList, setJobList] = useState([]);
  const [jobListLoading, setJobListLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [initial, setInitial] = useState(true);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [sortProps, setSortProps] = useState();
  const [newJobPane, setNewJobPane] = useState(false);
  const [emptyImage, setEmptyImage] = useState();

  useEffect(() => {
    setEmptyImage(getRandomNotFoundImage());
  }, [])

  useEffect(() => {
    if (artistDetail.id) {
      loadJobListResponse();
    }
  }, [artistDetail.id]);

  useEffect(() => {
    if (pageIndex && !initial) {
      loadJobListResponse();
    }
  }, [pageIndex]);

  useEffect(() => {
    if (sortProps && !initial) {
      loadJobListResponse();
    }
  }, [sortProps]);

  useEffect(() => {
    if (debouncedSearchTerm != undefined) {
      loadJobListResponse(searchParams);
    }
  }, [debouncedSearchTerm]);

  const loadJobListResponse = async () => {
    try {
      const response = await getArtistJobs(
        artistDetail.id,
        searchParams,
        sortProps,
        pageIndex || 1,
        DEFAULT_PAGE_SIZE
      );
      setJobList(response.data.jobs);
      setTotalRecords(response.data.totalRecords);
      setJobListLoading(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const handleTableChange = (_, __, sorter) => {
    setInitial(false);
    setSortProps(sorter);
  };

  const COLUMN_DATA = [
    {
      key: "serialNumber",
      dataIndex: "serialNumber",
      title: "Job No.",
      sorter: true,
      field: "serial",
      width: 100,
      render: serialNumber => <>{serialNumber}</>,
    },
    {
      key: "name",
      dataIndex: "name",
      title: "Name",
      sorter: true,
      field: "name",
      width: 180,
      render: (_, rowData) => {
        return (
          <Button
            style="link"
            to={`/jobs/${rowData.id}/overview`}
            label={rowData.name}
          />
        );
      },
    },
    {
      key: "organisationName",
      dataIndex: "organisationName",
      title: "Organisation",
      sorter: true,
      field: "organisation_name",
      width: 190,
      render: (_, rowData) => {
        return (
          <Button
            style="link"
            to={`/organisations/${rowData.organisationId}`}
            label={rowData.organisationName}
          />
        );
      },
    },
    {
      title: "Staff",
      dataIndex: "staffFullName",
      key: "staffFullName",
      sorter: true,
      width: 180,
      field: "user_full_name",
      render: (_, rowData) => {
        return (
          <Button
            style="link"
            to={`/staff/${rowData.staffId}`}
            label={rowData.staffFullName}
          />
        );
      },
    },
    {
      title: "Enquired On",
      dataIndex: "enquiryDate",
      key: "enquiryDate",
      sorter: true,
      width: 180,
      field: "enquiry_date",
      render: enquiryDate => dayjs(enquiryDate).format("DD/MM/YYYY"),
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: true,
      field: "status",
      key: "status",
      width: 120,
      render: status => {
        return (
          <Tag
            type="solid"
            style={getJobsStatusColor(status)}
            label={humanize(status)}
          />
        );
      },
    },
  ];

  if (!artistDetail) {
    return (
      <div
        className="flex items-center justify-center p-2"
        style={{ height: "calc(100vh - 134px)" }}
      >
        <EmptyState
          image={emptyImage}
          title="Please click on any artist to access the details."
        />
      </div>
    );
  }

  if (jobListLoading) {
    return (
      <div
        className="flex items-center justify-center w-full"
        style={{ height: "calc(100vh - 134px)" }}
      >
        <Spinner />
      </div>
    );
  }

  // TODO: Fix artist select at the time of new job

  return (
    <div
      className="flex flex-col overflow-hidden"
      style={{ height: "calc(100vh - 134px)" }}
    >
      <SubHeader
        className="px-6 pt-4"
        leftActionBlock={
          <Typography style="h4" component="h4" weight="semibold">
            {totalRecords > 1 ? `${totalRecords} Jobs` : `${totalRecords} Job`}
          </Typography>
        }
        rightActionBlock={
          <div className="flex justify-end space-x-3">
            <Input
              size="small"
              type="search"
              placeholder="Search"
              className="neeto-ui-header__search-input"
              prefix={<Search size={16} />}
              value={searchParams}
              onChange={e => setSearchParams(e.target.value)}
              clear={() => setSearchParams("")}
            />
            <Button
              size="small"
              label="Add Job"
              className="px-3"
              onClick={() => {
                setNewJobPane(true);
              }}
            />
          </div>
        }
      />
      <div
        className="px-4"
        style={{ height: "calc(100vh - 285px)" }}
      >
        {!R.isEmpty(jobList) ? (
          <Table
            columnData={COLUMN_DATA}
            rowData={jobList}
            loading={jobListLoading}
            fixedHeight
            currentPageNumber={pageIndex}
            defaultPageSize={pageSize}
            totalCount={totalRecords}
            handlePageChange={(page, pageSize) => {
              setInitial(false);
              setPageIndex(page);
              setPageSize(pageSize);
            }}
            onChange={(pagination, filters, sorter) =>
              handleTableChange(pagination, filters, sorter)
            }
            paginationProps={{
              showSizeChanger: false,
            }}
          />
        ) : (
          <EmptyState image={emptyImage} title="No Jobs Found" />
        )}
      </div>

      <NewJob
        isOpen={newJobPane}
        onClose={() => setNewJobPane(false)}
        initialFocusRef={initialFocusRef}
        artistId={artistDetail.id}
      />
    </div>
  );
};

export default Jobs;

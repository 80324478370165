import React, { useState, useEffect } from "react";
import { useUserState } from "contexts/user";
import { useParams, useHistory } from "react-router-dom";
import EmptyState from "components/Common/EmptyState";
import { Edit } from "@bigbinary/neeto-icons";
import {
  Spinner,
  Button,
  Alert,
  Pane,
  Typography,
  Toastr,
} from "@bigbinary/neetoui";
import { Header, Container } from "@bigbinary/neetoui/layouts";
import { useFormik } from "formik";
import { showToastrError } from "common";
import { getRandomNotFoundImage } from "common/helper";
import {
  getAccounts,
  getAccount,
  createAccount,
  destroyAccount,
  updateAccount,
} from "apis/super_admins/accounts";
import {
  createSampleData,
  destroySampleData,
} from "apis/super_admins/sample_data";
import { VALIDATION_SCHEMA, INITIAL_VALUE } from "./constants";
import Details from "./Details";
import Directory from "./Directory";
import AccountForm from "./Form";

const SuperAdmin = () => {
  const { id } = useParams();
  const { user } = useUserState();
  const history = useHistory();
  const [newPane, setNewPane] = useState(false);
  const [editPane, setEditPane] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [accountList, setAccountList] = useState([]);
  const [accountListLoad, setAccountListLoad] = useState(true);
  const [accountDetail, setAccountDetail] = useState({});
  const [accountDetailLoad, setAccountDetailLoad] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [sampleDataCreateModal, setSampleDataCreateModal] = useState(false);
  const [sampleDataDestroyModal, setSampleDataDestroyModal] = useState(false);
  const [emptyImage, setEmptyImage] = useState();

  const editFormik = useFormik({
    enableReinitialize: true,
    initialValues: accountDetail ? accountDetail : INITIAL_VALUE,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: values => onUpdate(values),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_VALUE,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: values => onSubmit(values),
  });

  useEffect(() => {
    loadAccountListResponse();
    setEmptyImage(getRandomNotFoundImage());
  }, []);

  useEffect(() => {
    if (id) {
      loadAccountDetailResponse();
    } else {
      setAccountDetail({});
    }
  }, [id]);

  // TODO: page params is not required
  const loadAccountListResponse = async (searchParams = null, page = 1) => {
    try {
      const response = await getAccounts(searchParams, page);
      setAccountList(response.data.accounts);
      setTotalRecords(response.data.totalRecords);

      if (!id && response.data.accounts.length > 0) {
        history.push(`/super_admin/accounts/${response.data.accounts[0].id}`);
      }

      setAccountListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadAccountDetailResponse = async () => {
    try {
      setAccountDetailLoad(true);
      const response = await getAccount(id);
      setAccountDetail(response.data.account);
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setAccountDetailLoad(false);
    }
  };

  const createSampleDataResponse = async () => {
    try {
      setButtonLoader(true);
      await createSampleData({ accountId: accountDetail.id });
      setSampleDataCreateModal(false);
      Toastr.info("You will receive a confirmation email on copy completion!");
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setButtonLoader(false);
    }
  };

  const destroySampleDataResponse = async () => {
    try {
      setButtonLoader(true);
      await destroySampleData(accountDetail.id);
      setSampleDataDestroyModal(false);
      Toastr.info(
        "You will receive a confirmation email on destroy completion!"
      );
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setButtonLoader(false);
    }
  };

  const onUpdate = async formValue => {
    try {
      setButtonLoader(true);
      let payload = {
        account: {
          ...formValue,
          attachments_attributes: { ...formValue.attachments_attributes },
        },
      };

      await updateAccount(accountDetail.id, payload);
      setEditPane(false);
      loadAccountDetailResponse();
      loadAccountListResponse();
      formik.resetForm();
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setButtonLoader(false);
    }
  };

  const onSubmit = async formValue => {
    try {
      setButtonLoader(true);
      let payload = {
        account: { ...formValue, attachment_attributes: null },
      };
      const response = await createAccount(payload);
      setNewPane(false);
      history.push(`/super_admin/accounts/${response.data.id}`);
      loadAccountListResponse();
      formik.resetForm();
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setButtonLoader(false);
    }
  };

  const onDelete = async () => {
    try {
      setButtonLoader(true);
      await destroyAccount(accountDetail.id);
      setAccountDetail({});
      loadAccountListResponse();
      history.push("/super_admin/accounts");
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setButtonLoader(false);
    }
  };

  if (accountListLoad) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <Container isHeaderFixed>
      {user.access === "super_admin" ? (
        <>
          <Header
            title="Accounts"
            actionBlock={
              <div className="flex justify-end space-x-3">
                {accountDetail.id && (
                  <>
                    {accountDetail.accountType === "test" && accountDetail.usersCount > 0 && (
                      <>
                        <Button
                          label="Delete Data"
                          onClick={() => setSampleDataDestroyModal(true)}
                        />

                        <Button
                          label="Copy Sample Data"
                          onClick={() => setSampleDataCreateModal(true)}
                        />
                      </>
                    )}

                    <Button
                      label="Edit"
                      icon={Edit}
                      iconPosition="left"
                      onClick={() => {
                        formik.resetForm();
                        setEditPane(true);
                      }}
                    />
                  </>
                )}

                <Button
                  label="Add Account"
                  onClick={() => {
                    formik.resetForm();
                    setNewPane(true);
                  }}
                />
              </div>
            }
          />
          <div className="flex w-full h-screen overflow-hidden border-t border-gray-200">
            {accountDetailLoad ? (
              <div className="flex items-center justify-center w-full">
                <Spinner />
              </div>
            ) : (
              <Details
                accountDetail={accountDetail}
                loadAccountDetailResponse={loadAccountDetailResponse}
              />
            )}
            <Directory
              accountList={accountList}
              loadAccountListResponse={loadAccountListResponse}
              accountDetail={accountDetail}
              totalRecords={totalRecords}
            />
          </div>
        </>
      ) : (
        <EmptyState
          image={emptyImage}
          title="<div class='flex'><i class='ri-forbid-2-line'></i><p>Not Allowed</p></div>"
          description="We are sorry but the page you are looking for is prohibitted."
        />
      )}

      <Pane
        isOpen={newPane || editPane}
        onClose={() => {
          editPane ? setEditPane(false) : setNewPane(false);
        }}
      >
        <Pane.Header>
          <Typography style="h2" weight="semibold">
            {editPane ? "Edit Account" : "Add Account"}
          </Typography>
        </Pane.Header>
        <Pane.Body>
          {accountDetailLoad ? (
            <div className="flex items-center justify-center w-full">
              <Spinner />
            </div>
          ) : (
            editPane ? <AccountForm formik={editFormik} /> : <AccountForm formik={formik} />
          )}
        </Pane.Body>
        <Pane.Footer className="gap-2">
          <Button
            label="Save Changes"
            onClick={editPane ? editFormik.handleSubmit : formik.handleSubmit}
            loading={buttonLoader}
          />
          <Button
            style="text"
            label="Cancel"
            onClick={() => {
              editPane ? setEditPane(false) : setNewPane(false);
            }}
          />
        </Pane.Footer>
      </Pane>

      <Alert
        isOpen={deleteAlertOpen}
        title="Delete Account"
        message="Are you sure you want to delete this account?"
        onClose={() => setDeleteAlertOpen(false)}
        onSubmit={() => onDelete()}
        isSubmitting={buttonLoader}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, delete"
      />

      <Alert
        isOpen={sampleDataCreateModal}
        title="Copy Sample Data"
        message="Are you sure you want to copy sample data?"
        onClose={() => setSampleDataCreateModal(false)}
        onSubmit={() => createSampleDataResponse()}
        isSubmitting={buttonLoader}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, proceed"
      />

      <Alert
        isOpen={sampleDataDestroyModal}
        title="Destroy Data"
        message="Are you sure you want to destroy data?"
        onClose={() => setSampleDataDestroyModal(false)}
        onSubmit={() => destroySampleDataResponse()}
        isSubmitting={buttonLoader}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, proceed"
      />
    </Container>
  );
};

export default SuperAdmin;

import React from "react";
import { Route, Switch } from "react-router-dom";
import InvoiceListing from "./InvoiceListing";
import InvoiceDetails from "./InvoiceDetails";

const Invoices = () => {
  return (
    <div className="flex h-screen">
      <div className="content-main">
        <Switch>
          <Route exact path="/invoices" component={InvoiceListing} />
          <Route exact path="/invoices/:id" component={InvoiceDetails} />
        </Switch>
      </div>
    </div>
  );
};

export default Invoices;

import React, { useEffect, useState, useContext } from "react";
import { Button, Modal, Typography } from "@bigbinary/neetoui";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useFormik } from "formik";
import * as dayjs from "dayjs";
import { showToastrError } from "common";
import {
  updateEstimateRevisionItem,
  destroyEstimateRevisionItem,
} from "apis/jobs/estimate_revision_items";
import { updateEstimateRevisionItemPosition } from "apis/jobs/estimate_revision_items";
import { updateJob } from "apis/jobs/jobs";
import { EstimateContext } from "../index";
import SingleImageUploader from "components/Common/SingleImageUploader";
import EstimateAccordion from "./EstimateAccordion";
import DetailsFooter from "./DetailsFooter";
import { filterEstimateListItems } from "../../../common/helper";

const ExtraDetails = ({
  uploadModal,
  setUploadModal,
  createInvoiceEntry,
  createOrRedirectToInvoice,
}) => {
  const {
    jobDetail,
    revisionId,
    finalInvoice,
    finalRci,
    estimateRevisionItemList,
    loadEstimateRevisionItemList,
    currentRevision,
    loadInvoiceList,
    loadRciList,
    rciList,
    revisionLineItemCatgoryList,
    openedParent,
    setOpenedParent,
  } = useContext(EstimateContext);

  const [artistList, setArtistList] = useState([]);
  const [openedItem, setOpenedItem] = useState();
  const [updateServiceFee, setUpdateServiceFee] = useState(false);
  const estimateListItems = filterEstimateListItems(estimateRevisionItemList);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: jobDetail,
    onSubmit: () => onJobUpdate(),
  });

  useEffect(() => {
    if (jobDetail.id) {
      setArtistList(jobDetail.artists);
    }
  }, [jobDetail]);

  const saveEstimateRecord = async (payload, id) => {
    try {
      await updateEstimateRevisionItem(revisionId, id, {
        estimate_revision_item: payload,
      });
      await loadEstimateRevisionItemList();
      await setUpdateServiceFee(true);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      loadRciList();
      loadInvoiceList();
    }
  };

  const onJobUpdate = async () => {
    try {
      let payload = {
        job: {
          thumbnail_attachment_attributes: formik.values.attachments_attributes,
        },
      };

      await updateJob(jobDetail.id, payload);
      setUploadModal(false);
      formik.resetForm();
      createInvoiceEntry(true);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const deleteItemClickFunction = async id => {
    try {
      await updateEstimateRevisionItem(revisionId, id, {
        estimate_revision_item: { deletedAt: dayjs() },
      });
      loadEstimateRevisionItemList();
      await setUpdateServiceFee(true);
    } catch (error) {
      loadInvoiceList();
      loadRciList();
      showToastrError(error.data.errors[0]);
    }
  };

  const itemPositionUpdated = async (revisionId, id, type, difference) => {
    try {
      await updateEstimateRevisionItemPosition(
        revisionId,
        id,
        type,
        difference
      );
      loadEstimateRevisionItemList();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const placeDifference = result => {
    let sourceIndex = result.source.index;
    let destinationIndex = result.destination.index;
    let placeChangeType = "";
    let difference = 0;

    if (sourceIndex > destinationIndex) {
      placeChangeType = "up";
      difference = sourceIndex - destinationIndex;
    } else {
      placeChangeType = "down";
      difference = destinationIndex - sourceIndex;
    }

    return { placeChangeType: placeChangeType, difference: difference };
  };

  const itemReorderFunction = result => {
    if (!result.destination) return;
    const { placeChangeType, difference } = placeDifference(result);
    itemPositionUpdated(
      revisionId,
      result.draggableId,
      placeChangeType,
      difference
    );
  };

  const isDisable = finalInvoice || finalRci;

  return (
    <main>
      <>
        <div className="pb-6">
          <DragDropContext onDragEnd={itemReorderFunction}>
            <Droppable droppableId="droppable">
              {provided => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <div className="flex w-full">
                    <EstimateAccordion
                      estimateListItems={estimateListItems}
                      onAccordionClick={setOpenedItem}
                      jobDetail={jobDetail}
                      deleteItemClickFunction={deleteItemClickFunction}
                      artistList={artistList}
                      saveEstimateRecord={saveEstimateRecord}
                      openedItem={openedItem}
                      isDisable={isDisable}
                      rciList={rciList}
                      setOpenedParent={setOpenedParent}
                      openedParent={openedParent}
                      revisionLineItemCatgoryList={revisionLineItemCatgoryList}
                    />
                  </div>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <DetailsFooter
            jobDetail={jobDetail}
            revisionId={revisionId}
            isDisable={isDisable}
            isAsfDisable={isDisable}
            estimateItems={estimateRevisionItemList}
            loadEstimateRevisionItemList={loadEstimateRevisionItemList}
            updateServiceFee={updateServiceFee}
            setUpdateServiceFee={setUpdateServiceFee}
            finalInvoice={finalInvoice}
            currentRevision={currentRevision}
            revisionLineItemCatgoryList={revisionLineItemCatgoryList}
          />
        </div>
      </>

      <Modal isOpen={uploadModal} onClose={() => setUploadModal(false)}>
        <Modal.Header>
          <Typography style="h2" weight="semibold">
            Please upload a thumbnail for the job to show in License.
          </Typography>
        </Modal.Header>

        <Modal.Body>
          <SingleImageUploader
            name="Thumbnail"
            type="thumbnail"
            formValue={formik.values}
            setFormValue={formik.setFieldValue}
          />
        </Modal.Body>
        <Modal.Footer className="space-x-2">
          <Button label="Save Changes" onClick={() => formik.handleSubmit()} />
          <Button
            style="text"
            label="Cancel"
            onClick={() => createOrRedirectToInvoice()}
          />
        </Modal.Footer>
      </Modal>
    </main>
  );
};

export default ExtraDetails;

import * as Yup from "yup";

export const ACTIVITY_LOG_VALIDATION_SCHEMA = Yup.object({
  crmActionTagId: Yup.string().nullable(),
});

export const INITIAL_ACTIVITY_LOG_VALUE = {
  note: "",
};

export const NOTE_VALIDATION_SCHEMA = Yup.object({
  crmActionTagId: Yup.string().nullable(),
});

export const INITIAL_NOTE_VALUE = {
  note: "",
};

export const ACTION_TAG_STATUS_LIST = [
  { label: "Open", value: "open" },
  {
    label: "Completed",
    value: "completed",
  },
];

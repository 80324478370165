import * as Yup from "yup";

export const CONTACT_VALIDATION_SCHEMA = Yup.object({
  organisationId: Yup.string().required("Organisation can't be empty"),
  firstName: Yup.string().required("First name can't be empty"),
  lastName: Yup.string().required("Last name can't be empty"),
  email: Yup.string().email("Invalid email").required("Email can't be empty"),
});

export const INITIAL_CONTACT_VALUE = {
  organisationId: "",
  userId: "",
  firstName: "",
  lastName: "",
  email: "",
  pronouns: "",
  title: "",
  phoneNumber: "",
  accountPayable: false,
  showInCrm: false,
};

export const IMPORT_CONTACT_VALIDATION_SCHEMA = Yup.object({
  file_data: Yup.string().required("File can't be empty"),
  filename: Yup.string().required("File name can't be empty"),
});

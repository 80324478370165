import React from "react";
import FlowerSVG from "images/flower.svg";
import CobblerLogo from "images/CobblerBlack.png";
import { Typography } from "@bigbinary/neetoui";

const MobileScreen = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-screen">
      <img src={FlowerSVG} className="w-40 h-auto mb-10" />
      <Typography style="h3" className="max-w-xs mx-auto font-bold text-center">
        Hi There!
        <br />
        <br />
        Cobbler does not yet support access on mobile devices. Please try again
        on a tablet or desktop.
      </Typography>
      <img src={CobblerLogo} className="w-auto h-6 mt-16" />
    </div>
  );
};

export default MobileScreen;

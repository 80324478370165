import React from "react";
import classnames from "classnames";

import { Typography } from "@bigbinary/neetoui";

const Bar = ({ label, count, value, maxValue, color }) => {
  const width = value > 0 ? (value / maxValue) * 100 : 0;
  return (
    <div className="flex items-center gap-4">
      <div className="flex-grow overflow-x-auto">
        <div
          style={{ width: `${width}%` }}
          className={classnames(
            "flex h-8 items-center overflow-visible rounded-r-md p-2",
            { [color]: value > 0 && color }
          )}
        >
          <Typography
            className="whitespace-no-wrap neeto-ui-text-gray-800"
            style="body2"
            weight="semibold"
          >
            {label}
          </Typography>
        </div>
      </div>
      <div className="pr-4 text-right min-w-min">
        <Typography className="neeto-ui-text-gray-800" style="body2">
          {count}
        </Typography>
      </div>
    </div>
  );
};

export default Bar;

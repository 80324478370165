import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Spinner, Button, Callout, Alert } from "@bigbinary/neetoui";
import { Header, Container } from "@bigbinary/neetoui/layouts";
import { useUserState } from "contexts/user";
import { getJob } from "apis/jobs/rcis_module";
import { getRcis, getRci } from "apis/jobs/rcis";
import { getEstimateRevisions } from "apis/jobs/estimate_revisions";
import { showToastrError } from "common";
import { pushRciToXero, destroyXeroSession } from "apis/settings/xero";
import { getInvoices } from "apis/jobs/invoices";
import RciHeader from "./RciHeader";
import ExtraItems from "./ExtraItems";
import RciList from "./RciList";
import Tabs from "../Tabs";
import {
  jobWithNameNumber,
  getRevisionId,
  getApprovedRevisionId,
} from "./../../common/helper";
import Pluralize from "pluralize";
import { dropDownListGenerator, artistModuleName } from "common/helper";
import { isEmpty } from "ramda";

const RCI = () => {
  const { id } = useParams();
  const { user } = useUserState();
  const rciId = window.location.href.split("rcis" + "/")[1];
  const history = useHistory();
  const [jobDetail, setJobDetail] = useState({});
  const [jobLoad, setJobLoad] = useState(true);
  const [artistList, setArtistList] = useState([]);
  const [estimateRevisions, setEstimateRevisions] = useState([]);
  const [estimateRevisionsLoad, setEstimateRevisionsLoad] = useState(true);
  const [rciList, setRciList] = useState([]);
  const [rciListLoad, setRciListLoad] = useState(true);
  const [rciDetail, setRciDetail] = useState();
  const [rciLoad, setRciLoad] = useState(false);
  const [pushToXeroConfirm, setPushToXeroConfirm] = useState(false);
  const [logoutConfirm, setLogoutConfirm] = useState(false);
  const [invoiceList, setInvoiceList] = useState([]);
  const [invoiceListLoad, setInvoiceListLoad] = useState(true);
  const [btnLoad, setBtnLoad] = useState(false);

  useEffect(() => {
    if (id) {
      loadJobDetailResponse();
      loadRciList();
      loadEstimateRevisionsResponse();
      loadInvoiceList();
    }
  }, [id]);

  useEffect(() => {
    if (rciId) {
      setRciLoad(true);
      loadRciDetail();
    }
  }, [rciId]);

  const loadInvoiceList = async () => {
    try {
      const response = await getInvoices(id);
      setInvoiceList(response.data.invoices);
      setInvoiceListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadJobDetailResponse = async () => {
    try {
      const response = await getJob(id);
      setJobDetail(response.data.job);
      setArtistList(dropDownListGenerator(response.data.job.artists));
      setJobLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadEstimateRevisionsResponse = async () => {
    try {
      const response = await getEstimateRevisions(id);
      setEstimateRevisions(response.data.estimateRevisions);
      setEstimateRevisionsLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadRciDetail = async () => {
    try {
      const response = await getRci(id, rciId);
      setRciDetail(response.data.rci);
      setRciLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadRciList = async () => {
    try {
      const response = await getRcis(id);
      setRciList(response.data.rcis);
      setRciListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const xeroLogoutEntry = async () => {
    try {
      await destroyXeroSession();
      loadRciDetail();
      setLogoutConfirm(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const pushToXeroEntry = async () => {
    try {
      setBtnLoad(true);
      await pushRciToXero(rciDetail.id);
      setBtnLoad(false);
      setPushToXeroConfirm(false);
      loadRciDetail();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      setBtnLoad(false);
    }
  };

  if (
    jobLoad ||
    rciLoad ||
    rciListLoad ||
    estimateRevisionsLoad ||
    invoiceListLoad
  ) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <Container isHeaderFixed>
      <Header
        title={rciDetail?.serialNumber || "New"}
        breadcrumbs={[
          { text: "Jobs", link: "/jobs" },
          {
            text: jobWithNameNumber(jobDetail),
            link: `/jobs/${jobDetail.id}/overview`,
          },
        ]}
        actionBlock={
          <div className="space-x-3">
            {rciDetail?.xeroTokenExpired === false && (
              <>
                <Button
                  style="secondary"
                  label="Push to Xero"
                  onClick={() => {
                    setPushToXeroConfirm(true);
                  }}
                />

                {user.access !== "team" && (
                  <Button
                    style="secondary"
                    label="Logout from Xero"
                    onClick={() => {
                      setLogoutConfirm(true);
                    }}
                  />
                )}
              </>
            )}

            {rciDetail && (
              <Button
                label={`Send to ${Pluralize(artistModuleName(), 1)}`}
                to={`/mailer/jobs/${rciDetail.jobId}/rcis/${rciDetail.id}/artists/${rciDetail.artistId}`}
              />
            )}

            <Button
              label="New RCI"
              onClick={() => {
                history.push(`/jobs/${jobDetail.id}/rcis`);
                window.location.reload();
              }}
            />
          </div>
        }
      />

      <Tabs jobId={id} revisionId={getRevisionId(estimateRevisions)} />

      <div
        className="flex w-full overflow-hidden"
        style={{ height: "calc(100vh - 134px)" }}
      >
        {!getApprovedRevisionId(estimateRevisions) && (
          <div className="flex items-center justify-center w-full h-full text-3xl">
            <span>Please approve any revision to access RCI form.</span>
          </div>
        )}

        {getApprovedRevisionId(estimateRevisions) && (
          <>
            <div className="w-8/12 p-6 space-y-4 overflow-y-scroll border-r neeto-ui-bg-gray-100 neeto-ui-border-gray-300">
              {rciDetail?.xeroIntegrated && (
                <Callout style="warning">
                  RCI Successfully Pushed to Xero.
                </Callout>
              )}

              <RciHeader
                artistList={artistList}
                jobDetail={jobDetail}
                rciList={rciList}
                loadRciList={loadRciList}
                rciDetail={rciDetail}
                invoiceList={invoiceList}
                approvedRevisionId={getApprovedRevisionId(estimateRevisions)}
              />
            </div>

            <div className="flex flex-col items-start w-4/12 p-6 space-y-8 overflow-y-scroll neeto-ui-bg-white">
              {!isEmpty(rciList) ? (
                <RciList
                  rciList={rciList}
                  jobId={id}
                  loadRciList={loadRciList}
                  setRciDetail={setRciDetail}
                />
              ) : (
                <div className="flex items-center justify-center w-full h-full px-6 py-12 text-2xl border border-gray-200 rounded-md">
                  <span>No RCIs Found</span>
                </div>
              )}
              {rciDetail?.id && (
                <ExtraItems
                  jobDetail={jobDetail}
                  rciDetail={rciDetail}
                  loadRciDetail={loadRciDetail}
                  extraRciItemList={rciDetail.rciItems.filter(
                    item => item.kind == "extra"
                  )}
                />
              )}
            </div>
          </>
        )}
      </div>
      <Alert
        isOpen={pushToXeroConfirm}
        title="Push to Xero"
        message="Are you sure you want to push this RCI to Xero?"
        onClose={() => setPushToXeroConfirm(false)}
        onSubmit={() => pushToXeroEntry()}
        submitButtonLabel="Yes, continue"
        cancelButtonLabel="No, cancel"
        isSubmitting={btnLoad}
      />

      <Alert
        isOpen={logoutConfirm}
        title="Logout"
        message="Are you sure you want to logout from Xero?"
        onClose={() => setLogoutConfirm(false)}
        onSubmit={() => xeroLogoutEntry()}
        submitButtonLabel="Yes, continue"
        cancelButtonLabel="No, cancel"
      />
    </Container>
  );
};

export default RCI;

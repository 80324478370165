import React, { useState } from "react";
import { Delete } from "@bigbinary/neeto-icons";
import { Button, Alert } from "@bigbinary/neetoui";
import { isEmpty } from "ramda";
import { useUserState } from "contexts/user";
import { showToastrError } from "common";
import { numberWithCommas, artistModuleName } from "common/helper";
import { destroyRci } from "apis/jobs/rcis";
import Card from "components/Common/Card";

const RciDetails = ({ jobDetail, rciList, loadRciListResponse }) => {
  const { user } = useUserState();
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [rciId, setRciId] = useState();
  const [btnload, setBtnLoad] = useState(false);

  const deleteRciEntry = async () => {
    try {
      setBtnLoad(true);
      await destroyRci(jobDetail.id, rciId);
      setDeleteAlertOpen(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      loadRciListResponse();
      setBtnLoad(false);
    }
  };

  return (
    <>
      <Card title={"RCI Details"}>
        {!isEmpty(rciList) ? (
          <div className="flex flex-col space-y-2">
            {rciList.map(rci => {
              return (
                <div
                  className="grid items-center grid-cols-5 gap-4"
                  key={rci.id}
                >
                  <div className="flex items-center col-span-2">
                    <Button
                      label={rci.artistName}
                      style="link"
                      to={`/${artistModuleName().toLowerCase()}/${
                        rci.artistId
                      }`}
                    />
                  </div>

                  <div className="flex items-center">
                    <span className="font-semibold text-right">{`${numberWithCommas(
                      Number(rci?.total || 0).toFixed(2)
                    )} ${rci.currency}`}</span>
                  </div>

                  <div className="flex items-center justify-end col-span-2">
                    <Button
                      label={`${rci.serialNumber}(${rci.type[0]})`}
                      style="link"
                      to={`/jobs/${jobDetail.id}/rcis/${rci.id}`}
                    />

                    {(user.access === "admin" ||
                      user.access === "super_admin") && (
                      <Button
                        size="small"
                        style="danger-text"
                        icon={Delete}
                        className="ml-2"
                        onClick={() => {
                          setDeleteAlertOpen(true);
                          setRciId(rci.id);
                        }}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="flex items-center justify-center h-16">
            <span className="text-sm text-gray-600">No RCIs Found</span>
          </div>
        )}
      </Card>

      <Alert
        isOpen={deleteAlertOpen}
        title="Delete RCI"
        message="Are you sure you want to delete the RCI?"
        isSubmitting={btnload}
        onClose={() => setDeleteAlertOpen(false)}
        onSubmit={() => deleteRciEntry()}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, delete"
      />
    </>
  );
};

export default RciDetails;

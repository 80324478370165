import React, { useState, useEffect } from "react";
import * as dayjs from "dayjs";
import { isEmpty } from "ramda";
import { Button, Table, Dropdown, Alert } from "@bigbinary/neetoui";
import { MenuHorizontal } from "@bigbinary/neeto-icons";
import { numberWithCommas, getRandomNotFoundImage } from "common/helper";
import EmptyState from "components/Common/EmptyState";
import { updateJob } from "apis/dashboards";
import { showToastrError } from "common";
import { useUserState } from "contexts/user";

const JobList = ({
  jobs,
  noAgency,
  getApprovedJobs,
  setShowListModal,
  getCurrentWeekStatisticsData,
}) => {
  const { user } = useUserState();
  const [jobId, setJobId] = useState();
  const [jobName, setJobName] = useState("");
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const { Menu, MenuItem } = Dropdown;
  const [emptyImage, setEmptyImage] = useState();

  useEffect(() => {
    setEmptyImage(getRandomNotFoundImage());
  }, [])

  const COLUMN_DATA = [
    {
      title: "Job No.",
      dataIndex: "serialNumber",
      key: "serialNumber",
      width: 100,
      render: (_, rowData) => (
        <Button
          style="link"
          to={`/jobs/${rowData.id}/overview`}
          label={rowData.serialNumber}
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Organisation",
      dataIndex: "organisationName",
      key: "organisationName",
    },
    {
      title: "Enquired On",
      dataIndex: "enquiryDate",
      key: "enquiryDate",
      width: 150,
      render: enquiryDate => dayjs(enquiryDate).format("DD/MM/YYYY"),
    },
  ];

  if (!noAgency && user.access_amount_in_counter) {
    COLUMN_DATA.push(
      {
        title: "Agency Fee",
        dataIndex: "agencyFee",
        key: "agencyFee",
        width: 150,
        render: (_, rowData) => (
          <div className="flex items-center">
            {`${numberWithCommas(Number(rowData.agencyFee).toFixed(2))} ${
              rowData.currency
            }`}
          </div>
        ),
      },
      {
        title: "",
        dataIndex: "actions",
        key: "actions",
        width: 100,
        render: (_, rowData) => (
          <Dropdown
            autoWidth
            closeOnSelect
            buttonStyle="text"
            icon={MenuHorizontal}
          >
            <Menu>
              <MenuItem.Button
                onClick={() => {
                  setJobId(rowData.id);
                  setJobName(rowData.name);
                  setDeleteAlertOpen(true);
                }}
              >
                Delete
              </MenuItem.Button>
            </Menu>
          </Dropdown>
        ),
      }
    );
  }

  const onUpdate = async () => {
    try {
      let payload = {
        job: {
          enableInCounter: false,
        },
      };

      await updateJob(jobId, payload);
      getApprovedJobs();
      getCurrentWeekStatisticsData();
      setShowListModal(false);
      setDeleteAlertOpen(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  return (
    <div className="w-full overflow-y-auto" style={{ height: "calc(60vh)" }}>
      {!isEmpty(jobs) ? (
        <Table
          fixedHeight
          allowRowClick={false}
          columnData={COLUMN_DATA}
          rowData={jobs}
          currentPageNumber={pageIndex}
          defaultPageSize={pageSize}
          handlePageChange={(page, pageSize) => {
            setPageIndex(page);
            setPageSize(pageSize);
          }}
          paginationProps={{
            showSizeChanger: false,
          }}
        />
      ) : (
        <EmptyState
          image={emptyImage}
          title="No Jobs Found"
          description="We couldn’t find any job. Try creating one."
        />
      )}

      <Alert
        isOpen={deleteAlertOpen}
        title="Delete Job"
        message={
          <>
            Are you sure you want to delete job <strong>{jobName}?</strong>
          </>
        }
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, delete"
        onClose={() => setDeleteAlertOpen(false)}
        onSubmit={() => onUpdate()}
      />
    </div>
  );
};

export default JobList;

import React from "react";
import { Button, Table, Typography } from "@bigbinary/neetoui";
import { displayData } from "common/helper";

const Contacts = ({ list, title }) => {
  const renderName = rowData => {
    return (
      <Button
        style="link"
        to={`/people/${rowData.id}`}
        label={rowData.fullName}
      />
    );
  };

  const renderEmail = rowData => {
    return (
      <Button
        style="link"
        href={`mailto:${rowData.email}`}
        label={rowData.email}
      />
    );
  };

  const renderAddress = rowData => {
    return (
      <div
        className="w-56 text-sm text-gray-900 truncate"
        dangerouslySetInnerHTML={{
          __html: rowData?.address?.split("\n").join("<br/>"),
        }}
      />
    );
  };

  const COLUMN_DATA = [
    {
      title: "Name",
      key: "fullName",
      dataIndex: "fullName",
      fixed: "left",
      width: 200,
      render: (_, rowData) => renderName(rowData),
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
      width: 200,
      render: (_, rowData) => renderEmail(rowData),
    },
    {
      title: "Phone Number",
      key: "phoneNumber",
      dataIndex: "phoneNumber",
      width: 200,
      render: (_, rowData) => displayData(rowData, "phoneNumber"),
    },
    {
      title: "Mobile Number",
      key: "mobileNumber",
      dataIndex: "mobileNumber",
      width: 200,
      render: (_, rowData) => displayData(rowData, "mobileNumber"),
    },
    {
      title: "Title",
      key: "title",
      dataIndex: "title",
      width: 200,
      render: (_, rowData) => displayData(rowData, "title"),
    },
    {
      title: "Address",
      key: "address",
      dataIndex: "address",
      width: 200,
      render: (_, rowData) => renderAddress(rowData),
    },
  ];

  return (
    <div className="flex flex-col w-full mt-4 p-4 rounded bg-white">
      <Typography
        style="h3"
        weight="semibold"
        className="mb-4 mt-2 text-gray-800 px-3"
      >
        {title}
      </Typography>
      <Table fixedHeight columnData={COLUMN_DATA} rowData={list} />
    </div>
  );
};

export default Contacts;

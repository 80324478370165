import React, { useState, useEffect } from "react";
import { MenuHorizontal } from "@bigbinary/neeto-icons";
import {
  Button,
  Dropdown,
  Tag,
  Table,
  Spinner,
  Alert,
} from "@bigbinary/neetoui";
import { Container, Header } from "@bigbinary/neetoui/layouts";
import { isEmpty } from "ramda";
import { showToastrError } from "common";
import { getRandomNotFoundImage } from "common/helper";
import {
  getCompanyDetail,
  getCompanyDetails,
  createCompanyDetail,
  updateCompanyDetail,
  destroyCompanyDetail,
} from "apis/settings/company_details";
import { useFormik } from "formik";
import EmptyState from "components/Common/EmptyState";
import {
  COMPANY_DETAILS_INITIAL_VALUE,
  COMPANY_DETAILS_VALIDATION_SCHEMA,
} from "./constants";
import CompanyDetailsModal from "./CompanyDetailsModal";
import CompanyPane from "./CompanyPane";

const CompanyDetails = ({ breadcrumbs }) => {
  const [companyDetailsList, setCompanyDetails] = useState([]);
  const [companyDetailsListLoad, setCompanyDetailsListLoad] = useState(true);
  const [companyDetailId, setCompanyDetailId] = useState("");
  const [companyDetailName, setCompanyDetailName] = useState("");
  const [companyDetail, setCompanyDetail] = useState();
  const [isCompanyDetailsPaneOpen, setIsCompanyDetailsPaneOpen] = useState(
    false
  );
  const [isCompanyDetailModalOpen, setIsCompanyDetailModalOpen] = useState(
    false
  );
  const [deleteModalState, setDeleteModalState] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [taxTypeAlertOpen, setTaxTypeAlertOpen] = useState(false);
  const { Menu, MenuItem } = Dropdown;
  const [emptyImage, setEmptyImage] = useState();
  const [submitBtnLoader, setSubmitBtnLoader] = useState(false);

  useEffect(() => {
    setEmptyImage(getRandomNotFoundImage());
  }, [])

  const loadCompanyDetailsList = async () => {
    try {
      const companyDetailResponse = await getCompanyDetails();
      setCompanyDetails(companyDetailResponse.data.companyDetails || []);
      setCompanyDetailsListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: companyDetail || COMPANY_DETAILS_INITIAL_VALUE,
    validationSchema: COMPANY_DETAILS_VALIDATION_SCHEMA,
    onSubmit: () => {
      if (companyDetailId) {
        updateCompanyDetailsEntry();
      } else {
        createCompanyDetailsEntry();
      }
    },
  });

  useEffect(() => {
    loadCompanyDetailsList();
  }, []);

  useEffect(async () => {
    if (companyDetailId) {
      await formik.setValues({ ...COMPANY_DETAILS_INITIAL_VALUE })
      getCompanyDetailEntry();
    }
  }, [companyDetailId]);

  const resetAllStates = () => {
    loadCompanyDetailsList();
    setCompanyDetailId("");
    setCompanyDetail();
    setIsCompanyDetailsPaneOpen(false);
    setIsCompanyDetailModalOpen(false);
    setDeleteModalState(false);
  };

  const createCompanyDetailsEntry = async () => {
    try {
      setSubmitBtnLoader(true);
      const {
        streetAddress,
        suburb,
        state,
        location,
        postcode,
        locationId,
      } = formik.values;
      let payload = {
        ...formik.values,
        locationId: locationId,
        address_attributes: {
          street_address: streetAddress,
          suburb: suburb,
          state: state,
          country: location,
          postcode: postcode,
        },
      };
      await createCompanyDetail({ company_detail: payload });
      resetAllStates();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      setSubmitBtnLoader(false);
    }
  };

  const getCompanyDetailEntry = async () => {
    try {
      const { data } = await getCompanyDetail(companyDetailId);
      setCompanyDetail(data.companyDetail);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const updateCompanyDetailsEntry = async () => {
    setSubmitBtnLoader(true);
    try {
      const {
        streetAddress,
        suburb,
        state,
        location,
        postcode,
        locationId,
        addressId,
      } = formik.values;
      let payload = {
        ...formik.values,
        address_attributes: {
          id: addressId,
          street_address: streetAddress,
          suburb: suburb,
          state: state,
          country: location,
          postcode: postcode,
          locationId: locationId,
        },
      };
      await updateCompanyDetail(companyDetailId, { company_detail: payload });
      resetAllStates();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      setSubmitBtnLoader(false);
    }
  };

  const destroyCompanyDetailsEntry = async () => {
    try {
      await destroyCompanyDetail(companyDetailId);
      resetAllStates();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const COLUMN_DATA = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "35%",
      render: (_, rowData) => (
        <Button
          style="link"
          label={rowData.name}
          onClick={() => {
            setCompanyDetailId(rowData.id);
            setIsCompanyDetailModalOpen(true);
          }}
        />
      ),
      fixed: "left",
    },
    {
      title: "Location",
      dataIndex: "locationName",
      key: "locationName",
      width: "18%",
      render: (_, rowData) => (
        <>{rowData.locationName ? rowData.locationName : "-"}</>
      ),
    },
    {
      title: "Currency",
      dataIndex: "currencyName",
      key: "currencyName",
      width: "12%",
      render: (_, rowData) => (
        <>{rowData.currencyName ? rowData.currencyName : "-"}</>
      ),
    },
    {
      title: "Display in Template",
      dataIndex: "displayInTemplate",
      key: "displayInTemplate",
      width: "25%",
      align: "center",
      render: (_, rowData) => (
        <Tag
          label={rowData.displayInTemplate ? "Enabled" : "Disabled"}
          style={rowData.displayInTemplate ? "success" : "danger"}
          type="solid"
        />
      ),
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: "10%",
      render: (_, rowData) => (
        <Dropdown
          autoWidth
          closeOnSelect
          buttonStyle="text"
          strategy="fixed"
          icon={MenuHorizontal}
        >
          <Menu>
            <MenuItem.Button
              onClick={() => {
                setCompanyDetailId(rowData.id);
                setIsCompanyDetailsPaneOpen(true);
              }}
            >
              Edit
            </MenuItem.Button>
            <MenuItem.Button
              style="danger"
              onClick={() => {
                setCompanyDetailId(rowData.id);
                setCompanyDetailName(rowData.name);
                setDeleteModalState(true);
              }}
            >
              Delete
            </MenuItem.Button>
          </Menu>
        </Dropdown>
      ),
    },
  ];

  return (
    <Container>
      <Header
        title="Company Details"
        breadcrumbs={breadcrumbs}
        actionBlock={
          <Button
            label="Add Company Details"
            onClick={() => {
              setCompanyDetail();
              formik.setValues({ ...COMPANY_DETAILS_INITIAL_VALUE });
              setCompanyDetailId();
              setIsCompanyDetailsPaneOpen(true);
            }}
          />
        }
      />
      <div className="w-full h-full" style={{ height: "calc(100vh - 30px)" }}>
        {companyDetailsListLoad ? (
          <div className="flex items-center justify-center w-full h-full">
            <Spinner />
          </div>
        ) : (
          <>
            {!isEmpty(companyDetailsList) ? (
              <Table
                fixedHeight
                loading={companyDetailsListLoad}
                columnData={COLUMN_DATA}
                rowData={companyDetailsList}
                currentPageNumber={pageIndex}
                defaultPageSize={pageSize}
                handlePageChange={(page, pageSize) => {
                  setPageIndex(page);
                  setPageSize(pageSize);
                }}
              />
            ) : (
              <EmptyState
                image={emptyImage}
                title="No Company Details Found"
                description="We couldn’t find any company details. Try creating one."
                primaryButtonProps={{
                  label: "Add Company Details",
                  onClick: () => {
                    setCompanyDetail();
                    formik.resetForm();
                    setCompanyDetailId();
                    setIsCompanyDetailsPaneOpen(true);
                  },
                }}
              />
            )}
          </>
        )}
      </div>

      <Alert
        isOpen={deleteModalState}
        title="Delete Company Details"
        message={
          <>
            Are you sure you want to delete the company details for{" "}
            <strong>{companyDetailName}?</strong>
          </>
        }
        onClose={() => setDeleteModalState(false)}
        onSubmit={() => {
          destroyCompanyDetailsEntry();
          setCompanyDetailName("");
        }}
        submitButtonLabel="Yes, delete"
        cancelButtonLabel="No, cancel"
      />

      <Alert
        isOpen={taxTypeAlertOpen}
        title="Tax Type Reminder"
        message={"Please don't forget to add tax type if applicable!"}
        onSubmit={() => {
          setTaxTypeAlertOpen(false)
        }}
        submitButtonLabel="Sure!"
      />

      {companyDetail && (
        <CompanyDetailsModal
          isOpen={isCompanyDetailModalOpen}
          onClose={() => setIsCompanyDetailModalOpen(false)}
          company={companyDetail}
        />
      )}
      <CompanyPane
        isOpen={isCompanyDetailsPaneOpen}
        onClose={() => setIsCompanyDetailsPaneOpen(false)}
        title={companyDetail ? "Edit Company Detail" : "Add Company Detail"}
        formik={formik}
        onSubmit={() => formik.handleSubmit()}
        setTaxTypeAlertOpen={setTaxTypeAlertOpen}
        submitBtnLoader={submitBtnLoader}
      />
    </Container>
  );
};

export default CompanyDetails;

import React, { useState, useEffect } from "react";
import { Header, Container } from "@bigbinary/neetoui/layouts";
import { Spinner, Button, Alert, Toastr } from "@bigbinary/neetoui";
import { isEmpty } from "ramda";
import { useFormik } from "formik";
import {
  getSpecialConditions,
  createSpecialCondition,
  updateSpecialCondition,
  destroySpecialCondition,
} from "apis/settings/special_conditions";
import { showToastrError } from "common";
import { getRandomNotFoundImage } from "common/helper";
import useDebounce from "common/debounce";
import {
  SPECIAL_CONDITION_VALIDATION_SCHEMA,
  SPECIAL_CONDITION_INITIAL_VALUE,
} from "../constants";
import List from "./List";
import EmptyState from "components/Common/EmptyState";
import FormPane from "./FormPane";

const SpecialConditions = ({ breadcrumbs }) => {
  const [specialConditionList, setSpecialConditionList] = useState([]);
  const [listLoader, setListLoader] = useState(true);
  const [specialCondition, setSpecialCondition] = useState({});
  const [modalState, setModalState] = useState(false);
  const [deleteAlertState, setDeleteAlertState] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [searchParams, setSearchParams] = useState();
  const debouncedSearchTerm = useDebounce(searchParams, 1000);
  const [emptyImage, setEmptyImage] = useState();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: specialCondition?.id
      ? specialCondition
      : SPECIAL_CONDITION_INITIAL_VALUE,
    validationSchema: SPECIAL_CONDITION_VALIDATION_SCHEMA,
    onSubmit: () => {
      if (specialCondition?.id) {
        updateSpecialConditionEntry();
      } else {
        createSpecialConditionEntry();
      }
    },
  });

  useEffect(() => {
    loadSpecialConditionList();
    setEmptyImage(getRandomNotFoundImage());
  }, []);

  useEffect(() => {
    if (debouncedSearchTerm != undefined) {
      loadSpecialConditionList();
    }
  }, [debouncedSearchTerm]);

  const loadSpecialConditionList = async () => {
    try {
      const response = await getSpecialConditions(searchParams);
      setSpecialConditionList(response.data.specialConditions || []);
      setListLoader(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const createSpecialConditionEntry = async () => {
    try {
      const response = await createSpecialCondition({
        special_condition: {
          name: formik.values.name,
          condition: formik.values.condition,
        },
      });
      setModalState(false);
      Toastr.info(response.data.notice);
      formik.resetForm();
      loadSpecialConditionList();
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setButtonLoader(false);
    }
  };

  const updateSpecialConditionEntry = async () => {
    try {
      const response = await updateSpecialCondition(specialCondition?.id, {
        special_condition: {
          name: formik.values.name,
          condition: formik.values.condition,
        },
      });
      setModalState(false);
      Toastr.info(response.data.notice);
      formik.resetForm();
      loadSpecialConditionList();
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setButtonLoader(false);
    }
  };

  const destroySpecialConditionEntry = async () => {
    try {
      const response = await destroySpecialCondition(specialCondition?.id);
      setDeleteAlertState(false);
      Toastr.info(response.data.notice);
      loadSpecialConditionList();
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setButtonLoader(false);
    }
  };

  const handleNewButtonClick = async () => {
    setButtonLoader(false);
    formik.resetForm();
    setSpecialCondition({});
    setModalState(true);
  };

  const handleSubmitButtonClick = async () => {
    if (Object.keys(formik.errors).length === 0) {
      setButtonLoader(true);
      formik.handleSubmit();
    }
  };

  if (listLoader) {
    return (
      <div className="flex items-center justify-center h-full">
        <Spinner />
      </div>
    );
  }

  return (
    <Container>
      <Header
        title="Special Job Conditions"
        breadcrumbs={breadcrumbs}
        searchProps={{
          value: searchParams,
          onChange: e => setSearchParams(e.target.value),
          clear: () => setSearchParams(),
        }}
        actionBlock={
          <Button label="Add New" onClick={() => handleNewButtonClick()} />
        }
      />

      <div className="w-full" style={{ height: "calc(100vh - 128px)" }}>
        {!isEmpty(specialConditionList) ? (
          <List
            specialConditionList={specialConditionList}
            setSpecialCondition={setSpecialCondition}
            setModalState={setModalState}
            setDeleteAlertState={setDeleteAlertState}
          />
        ) : (
          <EmptyState
            image={emptyImage}
            title="No Special Job Conditions Found"
            description="We couldn’t find any special job conditions. Try creating one."
            primaryButtonProps={{
              label: "Add New",
              onClick: () => handleNewButtonClick(),
            }}
          />
        )}
      </div>

      <FormPane
        isOpen={modalState}
        onClose={() => setModalState(false)}
        formik={formik}
        onSubmit={handleSubmitButtonClick}
        title={
          specialCondition?.id
            ? "Edit Special Job Condition"
            : "Add Special Job Condition"
        }
        buttonLoader={buttonLoader}
      />

      <Alert
        isOpen={deleteAlertState}
        title="Delete Special Job Condition"
        message={
          <>
            Are you sure you want to delete the special job condition{" "}
            <strong>{specialCondition?.name}</strong>?
          </>
        }
        onClose={() => setDeleteAlertState(false)}
        onSubmit={() => {
          setButtonLoader(true);
          destroySpecialConditionEntry();
        }}
        isSubmitting={buttonLoader}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, delete"
      />
    </Container>
  );
};

export default SpecialConditions;

import React from "react";
import { Route, Switch } from "react-router-dom";
import RciListing from "./RciListing";

const Rcis = () => {
  return (
    <div className="flex h-screen">
      <div className="content-main">
        <Switch>
          <Route exact path="/Rcis" component={RciListing} />
        </Switch>
      </div>
    </div>
  );
};

export default Rcis;

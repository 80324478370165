import * as Yup from "yup";

export const INITIAL_VALUE = {
  quantity: 0,
  metric: "",
  rate: 0,
  commissionType: "",
  artistId: "",
  commission: 0,
  markupPercentage: 0,
  note: "",
  artist_markup_percentages_attributes: [],
};

export const MARKUP_COMMISSION_INITIAL_VALUE = {
  artistId: "",
  markupPercentage: "",
};

export const MARKUP_COMMISSION_VALIDATION_SCHEMA = Yup.object({
  artistId: Yup.string().required("Artist can't be empty"),
  markupPercentage: Yup.number()
    .required("Markup percentage can't be empty")
    .min(1),
});

export const VALIDATION_SCHEMA = Yup.object({
  quantity: Yup.number().required("Quantity can't be empty").min(1),
  metric: Yup.string().required("Metric can't be empty"),
  rate: Yup.number().required("Rate can't be empty").min(1),
  commissionType: Yup.string().required("Commission type can't be empty"),
});

export const ESTIMATEFORMVALIDATION = Yup.object().shape({
  rateTbc: Yup.bool(),
  rateNa: Yup.bool(),
  Quantity: Yup.number().when(["rateTbc", "rateNa"], (rateTbc, rateNa) => {
    return rateTbc || rateNa
      ? Yup.number().nullable()
      : Yup.number().typeError("Quantity can't be empty").required("Quantity can't be empty");
  }),
  Metrics: Yup.object()
    .when(["rateTbc", "rateNa"], (rateTbc, rateNa) => {
      return rateTbc || rateNa ? Yup.object().shape({
        label: Yup.string().nullable(),
        value: Yup.string().nullable(),
      }) : Yup.object().shape({
        label: Yup.string().nullable().required("Can't be empty"),
        value: Yup.string().nullable().required("Can't be empty"),
      })
    }),
  Rate: Yup.number().when(["rateTbc", "rateNa"], (rateTbc, rateNa) => {
    return rateTbc || rateNa
      ? Yup.number().nullable()
      : Yup.number().typeError("Rate can't be empty").required("Rate can't be empty");
  }),
  Type: Yup.object().shape({
    label: Yup.string().required("Required"),
    value: Yup.string().required("Required"),
  }),
  Assigned: "",
  CommissionPercentage: Yup.number().when("Type", {
    is: typeObject => typeObject.value == "commission",
    then: Yup.number().required("Commission % can't be empty"),
  }),
  Markup: Yup.object().when("Type", {
    is: typeObject => typeObject.value == "markup",
    then: Yup.object().shape({
      percentage: Yup.number().required("Percentage can't be empty"),
      rate: Yup.number().required("Rate can't be empty"),
      artist: Yup.array().of(
        Yup.object().shape({
          artistName: Yup.object()
            .shape({
              label: Yup.string().required("Name can't be empty"),
              value: Yup.string().required("Name can't be empty"),
            })
            .nullable(),
          percentage: Yup.number()
            .max(100, "Split percentage should be less than or equal to 100")
            .required("Percentage can't be empty"),
          rate: Yup.number().required("Rate can't be empty"),
        })
      ),
    }),
  }),
});

export const ESTIMATE_INITIAL_VALUE = {
  Status: "unassigned",
  Quantity: "",
  Metrics: { label: "", value: "" },
  Rate: "",
  Type: { value: "commission", label: "Commission" },
  Assigned: { label: "", value: "" },
  CommissionPercentage: "30",
  Markup: {
    percentage: 0,
    rate: "",
    artist: [],
  },
  notes: "",
};

export const ESTIMATE_COMMISSION_TYPE = [
  { value: "markup", label: "Markup" },
  { value: "commission", label: "Commission" },
];

export const INTIAL_TOUCH = {
  Quantity: true,
  Metrics: true,
  Rate: true,
  Type: true,
  Assigned: true,
  CommissionPercentage: true,
  Markup: {
    percentage: true,
    rate: true,
    artist: [
      { artistName: true, percentage: true, rate: true },
      { ...{ artistName: true, percentage: true, rate: true } },
    ],
  },
};

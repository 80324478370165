import React from "react";
import InvoiceMailerPreview from "./InvoiceMailerPreview";

const MailViewer = ({
  formik,
  jobDetail,
  licenseUrl,
  docUrl,
  invoice,
  companyDetails,
  usageEnabled,
  defaultTemplateSetting,
  emailBackground,
}) => {
  return (
    <div className="w-3/5 overflow-y-scroll">
      <div
        className={`${
          invoice.final ? "final-invoice-email-body" : "comm-invoice-email-body"
        } p-6`}
      >
        <InvoiceMailerPreview
          formik={formik}
          jobDetail={jobDetail}
          docUrl={docUrl}
          invoice={invoice}
          licenseUrl={licenseUrl}
          companyDetails={companyDetails}
          usageEnabled={usageEnabled}
          defaultTemplateSetting={defaultTemplateSetting}
          emailBackground={emailBackground}
        />
      </div>
    </div>
  );
};

export default MailViewer;

import { stringify } from "qs";
import { isEmpty, omit, pipe, toPairs } from "ramda";

export const transformObjectDeep = (
  object,
  keyValueTransformer,
  /** @type {undefined | ((any) => any)} */
  objectPreProcessor = undefined
) => {
  if (objectPreProcessor && typeof objectPreProcessor === "function") {
    object = objectPreProcessor(object);
  }

  if (Array.isArray(object)) {
    return object.map(obj =>
      transformObjectDeep(obj, keyValueTransformer, objectPreProcessor)
    );
  } else if (object === null || typeof object !== "object") {
    return object;
  }

  return Object.fromEntries(
    Object.entries(object).map(([key, value]) =>
      keyValueTransformer(
        key,
        transformObjectDeep(value, keyValueTransformer, objectPreProcessor)
      )
    )
  );
};

export const preprocessForSerialization = object =>
  transformObjectDeep(
    object,
    (key, value) => [key, value],
    object => (typeof object?.toJSON === "function" ? object.toJSON() : object)
  );

export const buildUrl = (route, params) => {
  const placeHolders = [];
  toPairs(params).forEach(([key, value]) => {
    if (route.includes(`:${key}`)) {
      placeHolders.push(key);
      route = route.replace(`:${key}`, encodeURIComponent(value));
    }
  });
  const queryParams = pipe(
    omit(placeHolders),
    preprocessForSerialization,
    stringify
  )(params);
  return isEmpty(queryParams) ? route : `${route}?${queryParams}`;
};

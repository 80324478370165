import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Typography, Switch } from "@bigbinary/neetoui";
import { updateJob } from "apis/jobs/jobs";
import { showToastrError } from "common";
import SingleImageUploader from "components/Common/SingleImageUploader";

const HeaderImage = ({ jobDetail, loadJobDetailResponse }) => {
  const [initial, setInitial] = useState(true);
  const [logoOnlyTemplate, setLogoOnlyTemplate] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: jobDetail,
    onSubmit: () => updateJobEntry(),
  });

  useEffect(() => {
    if (jobDetail) {
      setLogoOnlyTemplate(jobDetail.logoOnlyTemplate);
    }
  }, [jobDetail]);

  useEffect(() => {
    if (!initial) {
      updateJobEntry();
    }
  }, [formik.values, logoOnlyTemplate]);

  const updateJobEntry = async () => {
    try {
      let payload = formik.values.attachments_attributes && {
        override_header_attachment_attributes: {
          ...formik.values.attachments_attributes,
          attachable_kind: "override",
        },
      };
      await updateJob(jobDetail.id, {
        job: {
          ...payload,
          logoOnlyTemplate: logoOnlyTemplate,
        },
      });
      loadJobDetailResponse();
      setInitial(true);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  return (
    <div className="flex flex-col space-y-2">
      <Typography style="h4" weight="semibold">
        Override Header Image
      </Typography>
      <SingleImageUploader
        name="Only JPEG, JPG, PNG files are supported"
        type="thumbnail"
        setDetailInitial={setInitial}
        formValue={formik.values}
        setFormValue={formik.setFieldValue}
      />

      <Switch
        label="Logo Only Template"
        onClick={() => {
          setInitial(false);
          setLogoOnlyTemplate(!logoOnlyTemplate);
        }}
        checked={logoOnlyTemplate}
      />
    </div>
  );
};

export default HeaderImage;

import React, { useState, useEffect, createContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import * as R from "ramda";
import { CloseCircle, Warning } from "@bigbinary/neeto-icons";
import { Spinner, Callout, Button, Alert } from "@bigbinary/neetoui";
import { Header, Container } from "@bigbinary/neetoui/layouts";
import { showToastrError } from "common";
import { getJob } from "apis/jobs/estimate";
import {
  getEstimateRevisionItems,
  createEstimateRevisionItem,
} from "apis/jobs/estimate_revision_items";
import { getRevisionLineItemCategories } from "apis/jobs/revision_line_item_category";
import { getEstimateRevisions } from "apis/jobs/estimate_revisions";
import { getInvoices } from "apis/jobs/invoices";
import { getRcis } from "apis/jobs/estimate";
import { createInvoice } from "apis/jobs/invoices";
import {
  jobWithNameNumber,
  getFinalInvoice,
  getCommencementInvoice,
  getApprovedRevisionId,
} from "../../common/helper";
import EstimateRevisions from "../../common/EstimateRevisions";
import ItemsDirectory from "./ItemsDirectory";
import EstimateDetails from "./Details";
import Tabs from "../Tabs";
import {
  filterEstimateItems,
  filterExtraItems,
  filterEstimateListItems,
  filterUsageItems,
} from "../../common/helper";

export const EstimateContext = createContext();

const Extras = () => {
  const { id, revisionId } = useParams();
  const history = useHistory();
  const [jobDetail, setJobDetail] = useState({});
  const [jobDetailLoad, setJobDetailLoad] = useState(true);
  const [estimateRevisionList, setEstimateRevisionList] = useState([]);
  const [rciList, setRciList] = useState([]);
  const [rciListLoad, setRciListLoad] = useState(true);
  const [estimateRevisionListLoad, setEstimateRevisionListLoad] = useState(
    true
  );
  const [estimateRevisionItemList, setEstimateRevisionItemList] = useState([]);
  const [
    estimateRevisionItemListLoad,
    setEstimateRevisionItemListLoad,
  ] = useState(true);
  const [invoiceList, setInvoiceList] = useState([]);
  const [invoiceListLoad, setInvoiceListLoad] = useState(true);
  const [currentRevisionId, setCurrentRevisionId] = useState();
  const currentRevision = estimateRevisionList.find(
    item => item.id === revisionId
  );
  const [openedParent, setOpenedParent] = useState();
  const [
    revisionLineItemCatgoryList,
    setRevisionLineItemCatgoryList,
  ] = useState([]);
  const [
    revisionLineItemCatgoryListLoad,
    setRevisionLineItemCatgoryListLoad,
  ] = useState(true);
  const estimateItems = filterEstimateItems(estimateRevisionItemList);
  const extraItems = filterExtraItems(estimateRevisionItemList);
  const extraListItems = filterEstimateListItems(extraItems);
  const usageEstimateItem = filterUsageItems(estimateItems)[0];
  const [uploadModal, setUploadModal] = useState(false);
  const [btnLoad, setBtnLoad] = useState(false);
  const [rciAlertOpen, setRciAlertOpen] = useState(false);
  const [newInvoiceId, setNewInvoiceId] = useState();

  useEffect(() => {
    if (id) {
      loadJobDetailResponse();
      loadEstimateRevisionList();
      loadInvoiceList();
      loadRciList();
    }
  }, [id]);

  useEffect(() => {
    if (id && revisionId) {
      loadRevisionLineItemCategoryList();
      loadEstimateRevisionItemList();
    }
  }, [id, revisionId]);

  useEffect(() => {
    if (currentRevisionId) {
      history.push(`/jobs/${id}/revisions/${currentRevisionId}/extras`);
    }
  }, [currentRevisionId]);

  useEffect(() => {
    if (newInvoiceId) {
      setRciAlertOpen(true);
    }
  }, [newInvoiceId]);

  const loadJobDetailResponse = async () => {
    try {
      const response = await getJob(id);
      setJobDetail(response.data.job);
      setJobDetailLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadInvoiceList = async () => {
    try {
      const response = await getInvoices(id);
      setInvoiceList(response.data.invoices);
      setInvoiceListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadRciList = async () => {
    try {
      const response = await getRcis(id);
      setRciList(response.data.rcis);
      setRciListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadEstimateRevisionList = async () => {
    try {
      const response = await getEstimateRevisions(id);
      setEstimateRevisionList(response.data.estimateRevisions);
      setEstimateRevisionListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadEstimateRevisionItemList = async () => {
    try {
      const response = await getEstimateRevisionItems(revisionId);
      setEstimateRevisionItemList(response.data.estimateRevisionItems);
      setEstimateRevisionItemListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadRevisionLineItemCategoryList = async () => {
    try {
      setRevisionLineItemCatgoryListLoad(true);
      const response = await getRevisionLineItemCategories(revisionId);
      setRevisionLineItemCatgoryList(response.data.revisionLineItemCategories);
      setRevisionLineItemCatgoryListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const handleLineItemClick = async item => {
    try {
      const payload = {
        estimate_revision_item: {
          estimate_line_item_id: item.id,
          commission: jobDetail.jobType.commissionPercentage,
          commission_type: "commission",
          status: "unassigned",
          kind: "extra",
          new_item: true,
        },
      };

      await createEstimateRevisionItem(revisionId, payload);
      await loadEstimateRevisionItemList();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      loadInvoiceList();
      loadRciList();
    }
  };

  const createOrRedirectToInvoice = () => {
    if (finalInvoice) {
      history.push(`/invoices/${finalInvoice.id}`);
    } else {
      createInvoiceEntry(true);
    }
  };

  const createInvoiceEntry = async status => {
    try {
      setBtnLoad(true);
      const percentage = status
        ? !commencementInvoice ||
          Number(jobDetail.commencementFee) == 0 ||
          R.isNil(jobDetail.commencementFee)
          ? 100
          : 100 - jobDetail.commencementFee
        : jobDetail.commencementFee;
      const payload = {
        invoice: {
          purchase_order: jobDetail.purchaseOrder,
          client_job_number: jobDetail.clientJobNumber,
          final: status,
          job_id: jobDetail.id,
          percentage: percentage,
          invoice_items_attributes: prepareInvoiceItemPayload(
            status,
            percentage
          ),
        },
      };
      const response = await createInvoice(jobDetail.id, payload);
      setNewInvoiceId(response.data.id);
    } catch (error) {
      loadInvoiceList();
      loadEstimateRevisionList();
      showToastrError(error.data.errors[0]);
    } finally {
      setBtnLoad(false);
    }
  };

  const prepareInvoiceItemPayload = (status, percentage) => {
    return estimateRevisionItemList.map(item => {
      return {
        name: item.estimateLineItem.name,
        xeroAccountCode: item.estimateLineItem.xeroAccountCode,
        feeType: item.feeType,
        quantity: item.quantity || 0,
        rate: Number(item.rate) + Number(item.adjustAmount),
        rateTbc: item.rateTbc,
        rateNa: item.rateNa,
        markupPercentage:
          item.feeType === "agency_service_fee" ? null : item.markupPercentage,
        metrics: item.metricName || "",
        notes: item.notes,
        percentage: item.kind === "extra" && status ? 100 : percentage,
        kind: item.kind,
        estimate_revision_item_id: item.id,
      };
    });
  };
  const revisionDetail = () => {
    return estimateRevisionList.find(revision => revision.id === revisionId);
  };

  const approvedRevisionId = getApprovedRevisionId(estimateRevisionList);
  const finalInvoice = getFinalInvoice(invoiceList);
  const commencementInvoice = getCommencementInvoice(invoiceList);
  const commencementRci = getCommencementInvoice(rciList);
  const finalRci = getFinalInvoice(rciList);

  let contextValue = {
    jobDetail,
    revisionId,
    approvedRevisionId,
    finalInvoice,
    commencementInvoice,
    commencementRci,
    finalRci,
    estimateRevisionItemList,
    estimateRevisionItemListLoad,
    loadEstimateRevisionItemList,
    setEstimateRevisionItemList,
    currentRevision,
    loadInvoiceList,
    loadRciList,
    rciList,
    loadEstimateRevisionList,
    revisionLineItemCatgoryList,
    openedParent,
    setOpenedParent,
    loadRevisionLineItemCategoryList,
  };

  if (
    jobDetailLoad ||
    estimateRevisionListLoad ||
    invoiceListLoad ||
    rciListLoad ||
    revisionLineItemCatgoryListLoad
  ) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <EstimateContext.Provider value={contextValue}>
      <Container isHeaderFixed>
        <Header
          title={`${revisionDetail()?.name} / Extras`}
          breadcrumbs={[
            { text: "Jobs", link: "/jobs" },
            {
              text: jobWithNameNumber(jobDetail),
              link: `/jobs/${id}/overview`,
            },
          ]}
          actionBlock={
            extraListItems.length > 0 && (
              <div className="flex items-center space-x-2">
                {((currentRevision.usageEnabled && usageEstimateItem) ||
                  !currentRevision.usageEnabled) &&
                  approvedRevisionId === revisionId &&
                  currentRevision?.staffApproved &&
                  currentRevision?.management1Approved &&
                  currentRevision?.management2Approved &&
                  extraListItems.length > 0 && (
                    <Button
                      label="Generate Final Invoice"
                      loading={btnLoad}
                      onClick={() => {
                        if (finalInvoice || jobDetail.attachments_attributes) {
                          createOrRedirectToInvoice();
                        } else {
                          setUploadModal(true);
                        }
                      }}
                    />
                  )}

                {extraListItems.length > 0 && (
                  <Button
                    label="Generate Overage"
                    onClick={() => {
                      history.push(
                        `/mailer/jobs/${jobDetail.id}/revisions/${revisionId}/overage_mail`
                      );
                    }}
                  />
                )}
              </div>
            )
          }
        />

        <Tabs jobId={id} revisionId={revisionId} />

        <div
          className="flex w-full overflow-hidden"
          style={{ height: "calc(100vh - 134px)" }}
        >
          <div className="relative w-8/12 p-6 space-y-4 overflow-y-scroll border-r neeto-ui-bg-gray-100 neeto-ui-border-gray-300">
            {jobDetail.cancellationReason && (
              <Callout style="danger" icon={CloseCircle}>
                <span>
                  In <b>Graveyard</b> because of &ldquo;
                  {jobDetail.cancellationReason}
                  &rdquo;
                </span>
              </Callout>
            )}
            {jobDetail.note && (
              <Callout style="warning" icon={Warning}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: jobDetail.note.split("\n").join("<br/>"),
                  }}
                />
              </Callout>
            )}
            {!R.isNil(jobDetail.xeroTokenExpired) &&
              !jobDetail.xeroTokenExpired && (
                <Callout className="w-full mb-3">Xero Logged in!</Callout>
              )}
            <EstimateDetails
              uploadModal={uploadModal}
              setUploadModal={setUploadModal}
              createInvoiceEntry={createInvoiceEntry}
              createOrRedirectToInvoice={createOrRedirectToInvoice}
            />
          </div>

          <div className="w-4/12 p-6 space-y-6 overflow-y-scroll neeto-ui-bg-white">
            <ItemsDirectory handleLineItemClick={handleLineItemClick} />
            <EstimateRevisions
              estimateRevisionList={estimateRevisionList}
              loadEstimateRevisionListResponse={loadEstimateRevisionList}
              jobDetail={jobDetail}
              invoiceList={invoiceList}
              loadJobDetailResponse={loadJobDetailResponse}
              setCurrentRevisionId={setCurrentRevisionId}
              currentRevisionId={revisionId}
              loadRciList={loadRciList}
              usageEstimateItem={usageEstimateItem}
            />
          </div>
        </div>

        <Alert
          isOpen={rciAlertOpen}
          title={`RCI Reminder`}
          message={
            <>
              Don't forget to generate the RCI associated to this invoice. Go to{" "}
              <a href={`/jobs/${id}/rcis`}>RCI</a> page.
            </>
          }
          onClose={() => history.push(`/invoices/${newInvoiceId}`)}
          onSubmit={() => {
            history.push(`/jobs/${id}/rcis`);
          }}
          submitButtonLabel="Sure."
          cancelButtonLabel="No, Thanks!"
        />
      </Container>
    </EstimateContext.Provider>
  );
};

export default Extras;

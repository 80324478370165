import React, { useState, useEffect } from "react";
import { isEmpty } from "ramda";
import { Button, Table } from "@bigbinary/neetoui";
import EmptyState from "components/Common/EmptyState";
import { getRandomNotFoundImage } from "common/helper";

const TagList = ({
  list,
}) => {
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [emptyImage, setEmptyImage] = useState();

  useEffect(() => {
    setEmptyImage(getRandomNotFoundImage());
  }, [])

  const renderContact = rowData => {
    return (
      <Button
        style="link"
        to={`/people/${rowData.id}`}
        label={rowData.name}
      />
    );
  };

  const renderOrganisation = rowData => {
    return (
      <Button
        style="link"
        to={`/organisations/${rowData.organisationId}`}
        label={rowData.client}
      />
    );
  };

  const COLUMN_DATA = [
    {
      title: "Contact",
      dataIndex: "name",
      key: "name",
      width: 200,
      render: (_, rowData) => renderContact(rowData),
    },
    {
      title: "Organisation",
      dataIndex: "client",
      key: "client",
      width: 200,
      render: (_, rowData) => renderOrganisation(rowData),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 200,
      render: (_, rowData) => rowData.email,
    },
  ];

  return (
    <div className="w-full overflow-y-auto" style={{ height: "calc(60vh)" }}>
      {!isEmpty(list) ? (
        <Table
          fixedHeight
          allowRowClick={false}
          columnData={COLUMN_DATA}
          rowData={list}
          currentPageNumber={pageIndex}
          defaultPageSize={pageSize}
          handlePageChange={(page, pageSize) => {
            setPageIndex(page);
            setPageSize(pageSize);
          }}
        />
      ) : (
        <EmptyState
          image={emptyImage}
          title="No Leads Found"
          description="We couldn’t find any leads. Try creating one."
        />
      )}
    </div>
  );
};

export default TagList;

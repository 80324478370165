import {
  createRevisionDeliveryScope,
  updateRevisionDeliveryScope,
  destroyRevisionDeliveryScope,
} from "apis/jobs/revision_delivery_scopes";
import {
  createRevisionProjectScope,
  updateRevisionProjectScope,
  destroyRevisionProjectScope,
} from "apis/jobs/revision_project_scopes";
import produce from "immer";
import { showToastrError } from "common";

export const updateRevisionScopeEntry = async (
  revisionId,
  scopeId,
  value,
  scope,
  loadRevisionSpecificProjectData,
  loadRevisionSpecificDeliveryData
) => {
  try {
    if (scope === "project") {
      let payload = { revision_project_scope: { name: value } };
      await updateRevisionProjectScope(revisionId, scopeId, payload);
      loadRevisionSpecificProjectData();
    } else if (scope === "delivery") {
      let payload = { revision_delivery_scope: { name: value } };
      await updateRevisionDeliveryScope(revisionId, scopeId, payload);
      loadRevisionSpecificDeliveryData();
    }
  } catch (error) {
    showToastrError(error.data.errors[0]);
  }
};

const deleteRevisionProjectScopeEntry = async (revisionId, item) => {
  try {
    await destroyRevisionProjectScope(revisionId, item.value);
  } catch (error) {
    showToastrError(error.data.errors[0]);
  }
};

const deleteRevisionDeliveryScopeEntry = async (revisionId, item) => {
  try {
    await destroyRevisionDeliveryScope(revisionId, item.value);
  } catch (error) {
    showToastrError(error.data.errors[0]);
  }
};

export const addScope = (
  revisionId,
  scope,
  setRevisionSpecificProjectScope = () => {},
  setRevisionSpecificDeliveryScope = () => {},
  revisionProjectScopeData = () => {},
  revisionDeliveryScopeData = () => {}
) => {
  if (scope === "project") {
    setRevisionSpecificProjectScope(state => {
      return [...state, { label: "", value: "" }];
    });

    createRevisionProjectScopeEntry(revisionId, "", revisionProjectScopeData);
  } else if (scope === "delivery") {
    setRevisionSpecificDeliveryScope(state => {
      return [...state, { label: "", value: "" }];
    });

    createRevisionDeliveryScopeEntry(revisionId, "", revisionDeliveryScopeData);
  }
};

export const destroyRevisionScopeEntry = (
  revisionId,
  item,
  index,
  scope,
  setRevisionSpecificProjectScope = () => {},
  setRevisionSpecificDeliveryScope = () => {}
) => {
  if (scope === "project") {
    setRevisionSpecificProjectScope(state => {
      let value = [...state];
      value.splice(index, 1);
      return value;
    });

    if (item.value) {
      deleteRevisionProjectScopeEntry(revisionId, item);
    }
  } else if (scope === "delivery") {
    setRevisionSpecificDeliveryScope(state => {
      let value = [...state];
      value.splice(index, 1);
      return value;
    });

    if (item.value) {
      deleteRevisionDeliveryScopeEntry(revisionId, item);
    }
  }
};

export const changeProjectScopeValue = (
  revisionId,
  item,
  index,
  revisionScope,
  type,
  setRevisionSpecificProjectScope,
  setRevisionSpecificDeliveryScope,
  loadRevisionSpecificProjectData,
  loadRevisionSpecificDeliveryData
) => {
  setRevisionSpecificProjectScope(
    produce(draft => {
      draft[index] = { ...item, isEditable: true };
    })
  );
  if (revisionScope.value?.length > 0) {
    updateRevisionScopeEntry(
      revisionId,
      revisionScope.value,
      item.label,
      type,
      loadRevisionSpecificProjectData,
      loadRevisionSpecificDeliveryData
    );
  } else {
    createRevisionProjectScopeEntry(
      revisionId,
      item.label,
      loadRevisionSpecificProjectData
    );
  }
};

export const createRevisionProjectScopeEntry = async (
  revisionId,
  value,
  loadRevisionSpecificProjectData = () => {}
) => {
  try {
    let payload = { revision_project_scope: { name: value } };
    await createRevisionProjectScope(revisionId, payload);
    loadRevisionSpecificProjectData();
  } catch (error) {
    showToastrError(error.data.errors[0]);
  }
};

export const changeDeliveryScopeValue = (
  revisionId,
  item,
  index,
  revisionScope,
  type,
  setRevisionSpecificProjectScope,
  setRevisionSpecificDeliveryScope,
  loadRevisionSpecificProjectData,
  loadRevisionSpecificDeliveryData
) => {
  setRevisionSpecificDeliveryScope(
    produce(draft => {
      draft[index] = { ...item, isEditable: true };
    })
  );

  if (revisionScope.value?.length > 0) {
    updateRevisionScopeEntry(
      revisionId,
      revisionScope.value,
      item.label,
      type,
      loadRevisionSpecificProjectData,
      loadRevisionSpecificDeliveryData
    );
  } else {
    createRevisionDeliveryScopeEntry(
      revisionId,
      item.label,
      loadRevisionSpecificDeliveryData
    );
  }
};

const createRevisionDeliveryScopeEntry = async (
  revisionId,
  value,
  loadRevisionSpecificDeliveryData
) => {
  try {
    let payload = { revision_delivery_scope: { name: value } };
    await createRevisionDeliveryScope(revisionId, payload);
    loadRevisionSpecificDeliveryData();
  } catch (error) {
    showToastrError(error.data.errors[0]);
  }
};

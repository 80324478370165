import React from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import { buildUrl } from "../utils";
import routes from "../routes";
import { Header, Container } from "@bigbinary/neetoui/layouts";
import { USAGE_LIST } from "../constants";

import Categories from "./Categories";
import Details from "./Details";
import Quantities from "./Quantities";
import Territories from "./Territories";
import Periods from "./Periods";
import ExclusivityPeriods from "./ExclusivityPeriods";
import SettingsTile from "../SettingsTile";

const Usages = ({ title, tab }) => {
  const { subTab } = useParams();
  const breadcrumbs = [
    { text: title, link: buildUrl(routes.settings.show, { tab }) },
  ];

  return (
    <>
      {subTab ? (
        <Switch>
          <Route
            path={routes.settings.categories}
            render={() => <Categories breadcrumbs={breadcrumbs} />}
          />
          <Route
            path={routes.settings.details}
            render={() => <Details breadcrumbs={breadcrumbs} />}
          />
          <Route
            path={routes.settings.quantities}
            render={() => <Quantities breadcrumbs={breadcrumbs} />}
          />
          <Route
            path={routes.settings.territories}
            render={() => <Territories breadcrumbs={breadcrumbs} />}
          />
          <Route
            path={routes.settings.periods}
            render={() => <Periods breadcrumbs={breadcrumbs} />}
          />
          <Route
            path={routes.settings.exclusivityPeriods}
            render={() => <ExclusivityPeriods breadcrumbs={breadcrumbs} />}
          />
          <Redirect to={buildUrl(routes.settings.show, { tab })} />
        </Switch>
      ) : (
        <Container>
          <Header title={title} />
          <div className="grid grid-cols-2 gap-3 lg:grid-cols-3 lg:gap-6 xl:grid-cols-4 2xl:grid-cols-4">
            {USAGE_LIST.map(item => (
              <SettingsTile
                {...item}
                key={item.value}
                path={buildUrl(item.path, { tab })}
              />
            ))}
          </div>
        </Container>
      )}
    </>
  );
};

export default Usages;

import React, { useState } from "react";
import { Delete, Plus } from "@bigbinary/neeto-icons";
import {
  Typography,
  Button,
  Input,
  Select,
  Modal,
  Alert,
  Pane,
} from "@bigbinary/neetoui";
import { useFormik } from "formik";
import { humanize } from "common/helper";
import { destroyRciItem } from "apis/jobs/rci_items";
import { updateRci } from "apis/jobs/rcis";
import { numberWithCommas } from "common/helper";
import {
  RCI_EXTRA_VALIDATION_SCHEMA,
  RCI_EXTRA_VALUE,
  EXTRA_TYPE_VALUE_LIST,
} from "./constants";

const ExtraItems = ({
  jobDetail,
  rciDetail,
  loadRciDetail,
  extraRciItemList,
}) => {
  const [btnLoad, setBtnLoad] = useState(false);
  const [addModalState, setAddModalState] = useState(false);
  const [sidepaneState, setSidepaneState] = useState(false);
  const [extra, setExtra] = useState("");
  const [deleteExtraItem, setDeleteExtraItem] = useState(false);
  const [deletingExtraItemId, setDeletingExtraItemId] = useState("");

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: extra ? extra : RCI_EXTRA_VALUE,
    validationSchema: RCI_EXTRA_VALIDATION_SCHEMA,
    onSubmit: () => {
      updateRciEntry();
    },
  });

  const updateRciEntry = async () => {
    try {
      setBtnLoad(true);
      let amount = 0;

      if (formik.values.extraType === "deduction") {
        amount =
          formik.values.amount < 0
            ? formik.values.amount
            : -formik.values.amount;
      } else {
        amount = formik.values.amount;
      }

      await updateRci(rciDetail.jobId, rciDetail.id, {
        rci: {
          rci_items_attributes: [
            {
              ...formik.values,
              amount: amount,
              percentage: 100,
              kind: "extra",
              exchangeRate: rciDetail.exchangeRate,
            },
          ],
        },
      });
      setSidepaneState(false);
      setAddModalState(false);
      loadRciDetail();
    } catch (error) {
      if (error.response.data.id) {
        setSidepaneState(false);
        setAddModalState(false);
        loadRciDetail();
      }
    } finally {
      setBtnLoad(false);
    }
  };

  const destroyExtraOnRciRecord = async () => {
    try {
      setBtnLoad(true);
      await destroyRciItem(rciDetail.id, deletingExtraItemId);
      setDeleteExtraItem(false);
      loadRciDetail();
    } catch (error) {
      if (error.response.data.id) {
        setDeleteExtraItem(false);
        loadRciDetail();
      }
    } finally {
      setBtnLoad(false);
    }
  };

  const extraTypeAction = () => {
    return extra?.extraType === "addition"
      ? "add"
      : extra?.extraType === "deduction"
      ? "delete"
      : "";
  };

  const getExtraOnRciItemDetail = item => {
    return (
      <div className="grid items-center grid-cols-4 gap-4 py-1 border-t border-gray-200">
        <Button
          label={item.name}
          style="link"
          className="col-span-2"
          onClick={() => {
            setExtra(item);
            setAddModalState(true);
          }}
        />
        <Typography style="body2" weight="semibold">
          {`${numberWithCommas(Number(item.amount).toFixed(2))} ${
            rciDetail.currencyName || jobDetail.currency
          }`}
        </Typography>
        <div className="flex items-center justify-end">
          <Button
            size="small"
            style="danger-text"
            icon={Delete}
            loading={btnLoad}
            onClick={() => {
              setDeleteExtraItem(true);
              setDeletingExtraItemId(item.id);
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="w-full mb-3">
      <div className="flex flex-col">
        <div className="flex items-center justify-between mb-3">
          <Typography style="h4" weight="semibold">
            Additions/Deductions
          </Typography>
          <Button
            style="link"
            icon={Plus}
            label="Add"
            iconPosition="left"
            onClick={() => {
              formik.resetForm();
              setExtra();
              formik.setFieldValue("extraType", null);
              setSidepaneState(true);
            }}
          />
        </div>
        {extraRciItemList?.map(extra => {
          return getExtraOnRciItemDetail(extra);
        })}
      </div>

      <Modal isOpen={addModalState} onClose={() => setAddModalState(false)}>
        <Modal.Header>
          <Typography style="h2" weight="semibold">
            {`${humanize(extra?.extraType)} - ${extra?.name}`}
          </Typography>
        </Modal.Header>
        <Modal.Body className="w-full">
          <div className="flex flex-col w-full space-y-6">
            <Typography style="body2">
              {`Are you sure you want to ${extraTypeAction()} an item? This will change the fee to be paid to the artist and organisation. Once done, it cannot be reverted.`}
            </Typography>
            <Input
              type="number"
              label="Amount"
              name="amount"
              value={formik.values.amount}
              onChange={formik.handleChange}
              {...formik.getFieldProps("amount")}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="space-x-2">
          <Button
            label="Save Changes"
            loading={btnLoad}
            onClick={() => {
              formik.handleSubmit("edit");
            }}
          />
          <Button
            label="Cancel"
            style="text"
            onClick={() => setAddModalState(false)}
          />
        </Modal.Footer>
      </Modal>
      <Pane isOpen={sidepaneState} onClose={() => setSidepaneState(false)}>
        <Pane.Header>
          <Typography style="h2" weight="semibold">
            New Additions/Deductions
          </Typography>
        </Pane.Header>
        <Pane.Body>
          <div className="flex flex-col w-full space-y-6">
            <Select
              label="Type"
              name="extraType"
              options={EXTRA_TYPE_VALUE_LIST}
              value={EXTRA_TYPE_VALUE_LIST.find(
                type => type.value === formik.values.extraType
              )}
              error={
                Boolean(formik.touched.extraType && formik.errors.extraType) &&
                formik.errors.extraType
              }
              onChange={option =>
                formik.setFieldValue("extraType", option.value)
              }
            />

            <Input
              className="mt-4"
              label="Name"
              name="name"
              error={
                Boolean(formik.touched.name && formik.errors.name) &&
                formik.errors.name
              }
              value={formik.values.name}
              onChange={formik.handleChange}
              {...formik.getFieldProps("name")}
            />

            <Input
              className="mt-4"
              type="number"
              label="Amount"
              name="amount"
              error={
                Boolean(formik.touched.amount && formik.errors.amount) &&
                formik.errors.amount
              }
              value={formik.values.amount}
              onChange={formik.handleChange}
              {...formik.getFieldProps("amount")}
            />
          </div>
        </Pane.Body>
        <Pane.Footer className="space-x-2">
          <Button
            loading={btnLoad}
            label="Save Changes"
            onClick={() => {
              formik.handleSubmit("new");
            }}
          />
          <Button
            label="Cancel"
            style="text"
            onClick={() => setSidepaneState(false)}
          />
        </Pane.Footer>
      </Pane>

      <Alert
        isOpen={deleteExtraItem}
        title="Delete Extra Item"
        message="Are you sure you want to delete this extra item?"
        onClose={() => setDeleteExtraItem(false)}
        onSubmit={() => destroyExtraOnRciRecord()}
        isSubmitting={btnLoad}
        submitButtonLabel="Yes, delete"
        cancelButtonLabel="No, cancel"
      />
    </div>
  );
};

export default ExtraItems;

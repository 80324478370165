import React, { useState, useEffect } from "react";
import { MenuHorizontal } from "@bigbinary/neeto-icons";
import {
  Avatar,
  Typography,
  Dropdown,
  Spinner,
  Table,
  Button,
  Pane,
  Alert,
} from "@bigbinary/neetoui";
import { SubHeader } from "@bigbinary/neetoui/layouts";
import {
  getTeamMembers,
  getTeamMember,
  createTeamMember,
  updateTeamMember,
  destroyTeamMember,
} from "apis/artists/team_members";
import { useFormik } from "formik";
import * as R from "ramda";
import * as dayjs from "dayjs";
import { showToastrError } from "common";

import {
  TEAMMEMBER_VALIDATION_SCHEMA,
  INITIAL_TEAMMEMBER_VALUE,
} from "./constants";
import AddTeamMember from "../../AddTeamMember";
import EmptyState from "components/Common/EmptyState";
import { getRandomNotFoundImage } from "common/helper";

const TeamMebers = ({ artistDetail }) => {
  const [addPane, setAddPane] = useState(false);
  const [editPane, setEditPane] = useState(false);
  const [teamMemberList, setTeamMemberList] = useState([]);
  const [teamMemberListLoading, setTeamMemberListLoading] = useState(false);
  const [teamMemberDetail, setTeamMemberDetail] = useState({});
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [deletingTeamMemberId, setDeletingTeamMemberId] = useState("");
  const { Menu, MenuItem } = Dropdown;
  const [emptyImage, setEmptyImage] = useState();

  useEffect(() => {
    setEmptyImage(getRandomNotFoundImage());
  }, [])

  useEffect(() => {
    if (artistDetail.id) {
      setTeamMemberListLoading(true);
      loadTeamMemberListResponse();
    }
  }, [artistDetail]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: teamMemberDetail.id
      ? teamMemberDetail
      : INITIAL_TEAMMEMBER_VALUE,
    validationSchema: TEAMMEMBER_VALIDATION_SCHEMA,
    onSubmit: values => {
      teamMemberDetail.id ? onUpdate(values) : onCreate(values);
    },
  });

  const onCreate = async formValue => {
    try {
      let payload = {
        team_member: {
          ...formValue,
          dateOfBirth: dayjs(formValue.dateOfBirth).format("YYYY-MM-DD"),
        },
      };
      await createTeamMember(artistDetail.id, payload);
      formik.resetForm();
      setAddPane(false);
      loadTeamMemberListResponse();
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const onUpdate = async editingFormValue => {
    try {
      let payload = {
        team_member: {
          ...editingFormValue,
          dateOfBirth: dayjs(editingFormValue.dateOfBirth).format("YYYY-MM-DD"),
        },
      };
      await updateTeamMember(artistDetail.id, teamMemberDetail.id, payload);
      setEditPane(false);
      loadTeamMemberListResponse();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const onDelete = async () => {
    try {
      await destroyTeamMember(artistDetail.id, deletingTeamMemberId);
      loadTeamMemberListResponse();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadTeamMemberListResponse = async () => {
    try {
      const response = await getTeamMembers(artistDetail.id);
      setTeamMemberList(response.data.teamMembers);
      setTeamMemberListLoading(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const setTeamMemberDetailResponse = async teamMemberId => {
    try {
      const response = await getTeamMember(artistDetail.id, teamMemberId);
      setTeamMemberDetail(response.data.teamMember);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const COLUMN_DATA = [
    {
      key: "fullName",
      dataIndex: "fullName",
      title: "Name",
      render: (_, rowData) => {
        return (
          <div className="flex items-center space-x-2">
            <Avatar
              size="medium"
              user={{
                name: rowData.fullName,
              }}
            />
            <Typography style="body2">{`${rowData?.fullName} ${
              rowData.pronouns ? `(${rowData.pronouns})` : ""
            }`}</Typography>
          </div>
        );
      },
    },
    {
      key: "email",
      dataIndex: "email",
      title: "Email",
      render: email => <>{email}</>,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: phoneNumber => <>{phoneNumber}</>,
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      fixed: "right",
      width: "10%",
      render: (_, rowData) => (
        <Dropdown
          autoWidth
          closeOnSelect
          buttonStyle="text"
          strategy="fixed"
          appendTo={() => document.body}
          icon={MenuHorizontal}
        >
          <Menu>
            <MenuItem.Button
              onClick={() => {
                setEditPane(true);
                setTeamMemberDetailResponse(rowData.id);
              }}
            >
              Edit
            </MenuItem.Button>
            <MenuItem.Button
              style="danger"
              onClick={() => {
                setDeleteAlertOpen(true);
                setDeletingTeamMemberId(rowData.id);
              }}
            >
              Delete
            </MenuItem.Button>
          </Menu>
        </Dropdown>
      ),
    },
  ];

  if (teamMemberListLoading) {
    return (
      <div
        className="flex items-center justify-center w-full"
        style={{ height: "calc(100vh - 134px)" }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div
      className="flex flex-col overflow-hidden"
      style={{ height: "calc(100vh - 134px)" }}
    >
      <SubHeader
        className="px-6 pt-4"
        leftActionBlock={
          <Typography style="h4" component="h4" weight="semibold">
            {teamMemberList?.length > 1
              ? `${teamMemberList?.length} Team Members`
              : `${teamMemberList?.length} Team Member`}
          </Typography>
        }
        rightActionBlock={
          <Button
            size="small"
            className="px-3"
            label="Add Team Member"
            onClick={() => {
              setAddPane(true);
              setTeamMemberDetail({});
            }}
          />
        }
      />
      <div
        className="px-4 mb-6 overflow-hidden"
        style={{ height: "calc(100vh - 214px)" }}
      >
        {!R.isEmpty(teamMemberList) ? (
          <Table
            columnData={COLUMN_DATA}
            rowData={teamMemberList}
            fixedHeight
          />
        ) : (
          <EmptyState image={emptyImage} title="No Team Members Found" />
        )}
      </div>
      <Pane
        isOpen={teamMemberDetail.id ? editPane : addPane}
        onClose={() => {
          teamMemberDetail.id ? setEditPane(false) : setAddPane(false);
        }}
      >
        <Pane.Header>
          <Typography style="h2" weight="semibold">
            {teamMemberDetail.id ? "Edit Team Member" : "Add Team Member"}
          </Typography>
        </Pane.Header>
        <Pane.Body>
          <AddTeamMember formik={formik} artistDetail={artistDetail} />
        </Pane.Body>

        <Pane.Footer className="space-x-2">
          <Button label="Save Changes" onClick={() => formik.handleSubmit()} />
          <Button
            style="text"
            label="Cancel"
            onClick={() => {
              teamMemberDetail.id ? setEditPane(false) : setAddPane(false);
            }}
          />
        </Pane.Footer>
      </Pane>
      <Alert
        isOpen={deleteAlertOpen}
        title="Delete Team Member"
        message="Are you sure you want to delete this team member?"
        onClose={() => setDeleteAlertOpen(false)}
        onSubmit={() => {
          setDeleteAlertOpen(false);
          onDelete();
        }}
        submitButtonLabel="Yes, delete"
        cancelButtonLabel="No, cancel"
      />
    </div>
  );
};

export default TeamMebers;

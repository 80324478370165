import React, { useState, useEffect } from "react";
import { Search } from "@bigbinary/neeto-icons";
import { Spinner, Button, Input, Typography, Table } from "@bigbinary/neetoui";
import { SubHeader } from "@bigbinary/neetoui/layouts";
import * as dayjs from "dayjs";
import * as R from "ramda";
import { getArtistRcis } from "apis/artists/rcis";
import { showToastrError } from "common";
import { numberWithCommas } from "common/helper";
import { DEFAULT_PAGE_SIZE } from "common/constants";
import EmptyState from "components/Common/EmptyState";
import useDebounce from "common/debounce";
import { getRandomNotFoundImage } from "common/helper";

const RciComponent = ({ artistDetail }) => {
  const [searchParams, setSearchParams] = useState();
  const debouncedSearchTerm = useDebounce(searchParams, 500);
  const [rciList, setRciList] = useState([]);
  const [rciListLoading, setRciListLoading] = useState(true);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalRecords, setTotalRecords] = useState();
  const [sortProps, setSortProps] = useState();
  const [pageIndex, setPageIndex] = useState(1);
  const [initial, setInitial] = useState(true);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [emptyImage, setEmptyImage] = useState();

  useEffect(() => {
    setEmptyImage(getRandomNotFoundImage());
  }, [])

  useEffect(() => {
    if (artistDetail.id) {
      loadRciListResponse();
    }
  }, [artistDetail.id]);

  useEffect(() => {
    if (sortProps && !initial) {
      loadRciListResponse();
    }
  }, [sortProps]);

  useEffect(() => {
    if (pageIndex && !initial) {
      loadRciListResponse();
    }
  }, [pageIndex]);

  useEffect(() => {
    if (debouncedSearchTerm !== undefined) {
      loadRciListResponse();
    }
  }, [debouncedSearchTerm]);

  const loadRciListResponse = async () => {
    try {
      const response = await getArtistRcis(
        artistDetail.id,
        searchParams,
        sortProps,
        pageIndex || 1,
        DEFAULT_PAGE_SIZE
      );
      setRciList(response.data.rcis);
      setTotalRecords(response.data.totalRecords);
      setTotalAmount(response.data.totalAmount);
      setRciListLoading(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const handleTableChange = (_, __, sorter) => {
    setInitial(false);
    setSortProps(sorter);
  };

  const COLUMN_DATA = [
    {
      key: "serialNumber",
      dataIndex: "serialNumber",
      title: "Serial",
      sorter: true,
      field: "serial",
      width: 120,
      render: (_, rowData) => {
        return (
          <Button
            style="link"
            to={`/jobs/${rowData.jobId}/rcis/${rowData.id}`}
            label={rowData.serialNumber}
          />
        );
      },
    },
    {
      key: "jobSerialNumber",
      dataIndex: "jobSerialNumber",
      title: "Job Serial",
      sorter: true,
      field: "job_serial_number",
      width: 120,
      render: (_, rowData) => {
        return (
          <Button
            style="link"
            to={`/jobs/${rowData.jobId}/overview`}
            label={rowData.jobSerialNumber}
          />
        );
      },
    },
    {
      key: "jobName",
      dataIndex: "jobName",
      title: "Job",
      sorter: true,
      field: "job_name",
      width: 500,
      render: (_, rowData) => {
        return (
          <Button
            style="link"
            to={`/jobs/${rowData.jobId}/overview`}
            label={rowData.jobName}
          />
        );
      },
    },
    {
      title: "Type",
      dataIndex: "rciType",
      key: "rciType",
      sorter: true,
      field: "final",
      render: rciType => <>{rciType}</>,
    },
    {
      title: "Issued On",
      dataIndex: "issueDate",
      sorter: true,
      field: "issue_date",
      key: "issueDate",
      render: issueDate => dayjs(issueDate).format("DD/MM/YYYY"),
    },
    {
      title: "Amount",
      dataIndex: "currency",
      key: "currency",
      render: (_, rowData) => {
        return (
          <>
            {`${numberWithCommas(Number(rowData.total).toFixed(2))} ${
              rowData.currency
            }`}
          </>
        );
      },
    },
  ];

  if (!artistDetail) {
    return (
      <div
        className="flex items-center justify-center p-2"
        style={{ height: "calc(100vh - 134px)" }}
      >
        <EmptyState
          image={emptyImage}
          title="Please click on any artist to access the details."
        />
      </div>
    );
  }

  if (rciListLoading) {
    return (
      <div
        className="flex items-center justify-center w-full"
        style={{ height: "calc(100vh - 134px)" }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div
      className="flex flex-col overflow-hidden"
      style={{ height: "calc(100vh - 134px)" }}
    >
      <SubHeader
        className="px-6 pt-4"
        leftActionBlock={
          <Typography style="h4" component="h4" weight="semibold">
            {totalRecords > 1 ? `${totalRecords} RCIs` : `${totalRecords} RCI`}
          </Typography>
        }
        rightActionBlock={
          <Input
            size="small"
            type="search"
            placeholder="Search"
            className="neeto-ui-header__search-input"
            prefix={<Search size={16} />}
            value={searchParams}
            onChange={e => setSearchParams(e.target.value)}
            clear={() => setSearchParams("")}
          />
        }
      />
      <div
        className="px-4 mb-6"
        style={{ height: "calc(100vh - 214px - 53px)" }}
      >
        {!R.isEmpty(rciList) ? (
          <Table
            columnData={COLUMN_DATA}
            rowData={rciList}
            fixedHeight
            loading={rciListLoading}
            currentPageNumber={pageIndex}
            defaultPageSize={pageSize}
            totalCount={totalRecords}
            handlePageChange={(page, pageSize) => {
              setInitial(false);
              setPageIndex(page);
              setPageSize(pageSize);
            }}
            onChange={(pagination, filters, sorter) =>
              handleTableChange(pagination, filters, sorter)
            }
            paginationProps={{
              showSizeChanger: false,
            }}
          />
        ) : (
          <EmptyState image={emptyImage} title="No RCIs Found" />
        )}
      </div>

      <div className="fixed bottom-0 right-0 flex justify-end px-6 py-4 text-right bg-white border-t neeto-ui-border-gray-200" style={{ width: "calc(100vw - 550px)" }}>
        <div className="flex items-center space-x-2">
          <Typography style="h4" className="neeto-ui-text-gray-800">
            Total RCIs:
          </Typography>
          <Typography
            style="h4"
            weight="bold"
            className="neeto-ui-text-error-600"
          >
            {`${numberWithCommas(
              Number(
                totalAmount
              ).toFixed(2)
            )}`}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default RciComponent;

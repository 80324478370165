import { Quill } from "react-quill";

// Pagination constants
export const DEFAULT_PAGE_SIZE = 20;
export const DEFAULT_PAGE_INDEX = 1;

// Validation constants
export const MAX_STRING_LENGTH = 255;

// Text editor toolbar
export const DEFAULT_EDITOR_TOOLBAR =
  "formatgroup | undo redo| blocks | bold italic underline forecolor backcolor fontsize | alignleft aligncenter alignright alignjustify | numlist bullist | outdent indent hr | blockquote";
export const DEFAULT_EDITOR_FONT_SIZES =
  "8px 10px 12px 14px 16px 18px 20px 24px 36px";
export const DEFAULT_EDITOR_PLUGINS = "lists advlist wordcount link";
export const DEFAULT_EDITOR_HEIGHT = 300;

// Message
export const EMAIL_DISABLED_MESSAGE =
  "Email functionality is currently disabled in demo mode.";

export const CHAT_EDITOR_TOOLBAR =
  "undo redo | bold italic underline forecolor backcolor fontsize | alignleft aligncenter alignright alignjustify | numlist bullist | outdent indent hr | link";

export const CHAT_MENU_BAR = "edit insert view tools";
export const CHAT_EDITOR_HEIGHT = 500;
export const REMOVE_LINE_BREAKS = false;

const Size = Quill.import("attributors/style/size");
Size.whitelist = [
  "8px",
  "9px",
  "10px",
  "12px",
  "14px",
  "16px",
  "20px",
  "24px",
  "32px",
  "42px",
  "54px",
  "68px",
  "84px",
  "98px",
];
Quill.register(Size, true);

const Font = Quill.import("formats/font");
Font.whitelist = ["arial", "roboto", "georgia", "helvetica"];
Quill.register(Font, true);

export const QUILL_TOOLBAR_STYLE = [
  ["bold", "italic", "underline", "strike", "link"],
  ["code"],
  [{ size: Size.whitelist }],
  [{ header: 1 }, { header: 2 }],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }],
  [{ align: [] }],
  [{ font: Font.whitelist }],
  [{ color: [] }, { background: [] }],
];

export const QUILL_KEYBOARD_BINDINGS = {
  bindings: {
    "list autofill": {
      prefix: /^\s*()$/,
    },
  },
};

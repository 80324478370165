import axios from "axios";

const getMessageThreadsUrl = (jobId, id) => {
  if (id) {
    return `/api/v1/job_module/jobs/${jobId}/message_threads/${id}`;
  }

  return `/api/v1/job_module/jobs/${jobId}/message_threads`;
};

export const getMessageThreads = jobId => {
  return axios.get(getMessageThreadsUrl(jobId));
};

export const getMessageThread = (jobId, id) => {
  return axios.get(getMessageThreadsUrl(jobId, id));
};

export const createMessageThread = (jobId, payload) => {
  return axios.post(getMessageThreadsUrl(jobId), payload);
};

export const updateMessageThread = (jobId, id, payload) => {
  return axios.put(getMessageThreadsUrl(jobId, id), payload);
};

import axios from "axios";
import {direction} from "common/helper";

const getContactActivityLogsUrl = (contactId, id) => {
  if (id) {
    return `/api/v1/contacts/contacts/${contactId}/contact_activity_logs/${id}`;
  }

  return `/api/v1/contacts/contacts/${contactId}/contact_activity_logs`;
};

export const getContactActivityLogs = (
  contactId,
  searchParams = "",
  sortProps = {},
  pageIndex,
  perPage
) => {
  let paginationQuery = `page=${pageIndex}&per_page=${perPage}`;
  let searchQuery = `q[crm_action_tag_name_i_cont_any]=${searchParams}&q[created_by_full_name_i_cont_any]=${searchParams}&q[m]=or`;
  let sortQuery = `q[s]=${sortProps?.column?.field}+${direction(sortProps?.order)}`;
  let query = paginationQuery;
  if (searchParams.length > 0) query += `&${searchQuery}`;
  if (sortProps?.column && sortProps?.order) query += `&${sortQuery}`;

  return axios.get(
    `/api/v1/contacts/contacts/${contactId}/contact_activity_logs?${query}`
  );
};

export const getContactActivityLog = (contactId, id) => {
  return axios.get(getContactActivityLogsUrl(contactId, id));
};

export const createContactActivityLog = (contactId, payload) => {
  return axios.post(getContactActivityLogsUrl(contactId), payload);
};

export const updateContactActivityLog = (contactId, id, payload) => {
  return axios.put(getContactActivityLogsUrl(contactId, id), payload);
};

export const destroyContactActivityLog = (contactId, id) => {
  return axios.delete(getContactActivityLogsUrl(contactId, id));
};

import React from "react";
import { Input, Label } from "@bigbinary/neetoui";
import Pluralize from "pluralize";
import { numberWithCommas, artistModuleName } from "common/helper";
import { percentageCalculator } from "./utils";

const ArtistLineItemDetail = ({ revisionItems, jobDetail, artist }) => {
  return (
    <div className="w-full p-6 bg-gray-100 rounded">
      {revisionItems
        .filter(
          item =>
            item.artistId === artist.artistId ||
            item.artistMarkupPercentages.some(
              markup => markup.artistId === artist.artistId
            )
        )
        .map(item => {
          return (
            <div className="relative" key={item.id}>
              <div className="px-4 py-4 bg-white rounded">
                <div className="flex items-center justify-between">
                  <Label className="font-bold">
                    {item.estimateLineItem.name}
                  </Label>
                </div>
                <div className="grid grid-cols-3 gap-8 py-2 mt-2">
                  <div className="col-span-1">
                    <Input
                      disabled={true}
                      label="Quantity"
                      value={item.quantity}
                    />
                  </div>

                  <div className="col-span-1">
                    <Input
                      disabled={true}
                      label="Metric"
                      value={item.metricName}
                    />
                  </div>

                  {item.commissionType === "commission" && (
                    <div className="col-span-1">
                      <Input disabled={true} label="Rate" value={item.rate} />
                    </div>
                  )}

                  {item.commissionType === "markup" && (
                    <div className="flex col-span-1">
                      <Input
                        disabled={true}
                        label="Rate"
                        value={item.rate}
                        className="mr-2"
                      />
                      <Input
                        disabled={true}
                        label="Custom Rate"
                        value={item.customRate}
                      />
                    </div>
                  )}
                </div>

                <div className="grid grid-cols-3 gap-8 py-2 mt-2">
                  <div className="col-span-1">
                    <Input
                      disabled={true}
                      label="Commission Type"
                      value={item.commissionType}
                    />
                  </div>

                  {item.commissionType === "markup" && (
                    <>
                      <div className="col-span-1">
                        <Input
                          disabled={true}
                          label="Markup Percentage"
                          value={item.markupPercentage}
                        />
                      </div>

                      <div className="col-span-1">
                        <Input
                          disabled={true}
                          label="Markup Total"
                          value={numberWithCommas(
                            Number(item.amount).toFixed(2)
                          )}
                        />
                      </div>
                    </>
                  )}

                  {item.commissionType === "commission" && (
                    <>
                      <div className="col-span-1">
                        <Input
                          disabled={true}
                          label="Assigned To"
                          value={item.artistName}
                        />
                      </div>

                      <div className="col-span-1">
                        <Input
                          disabled={true}
                          label="Commission"
                          value={item.commission}
                        />
                      </div>
                    </>
                  )}
                </div>

                {item.commissionType === "markup" && (
                  <div className="grid grid-cols-3 gap-8 py-2 mt-2">
                    {item.artistMarkupPercentages.map(markupItem => {
                      return (
                        <>
                          <div className="col-span-1">
                            <Input
                              disabled={true}
                              label={Pluralize(artistModuleName(), 1)}
                              value={markupItem.artistName}
                            />
                          </div>

                          <div className="col-span-1">
                            <Input
                              disabled={true}
                              label="Percentage"
                              value={markupItem.markupPercentage}
                            />
                          </div>

                          <div className="col-span-1">
                            <Input
                              disabled={true}
                              label="Total"
                              value={numberWithCommas(
                                Number(markupItem.artistFee).toFixed(2)
                              )}
                            />
                          </div>
                        </>
                      );
                    })}
                  </div>
                )}

                <div className="grid grid-cols-3 gap-8 py-2 mt-2">
                  <div className="col-span-3">
                    <Input disabled={true} label="Notes" value={item.note} />
                  </div>
                </div>

                <div className="grid grid-cols-3 gap-8 py-2 mt-2">
                  <div className="flex justify-between col-span-3">
                    <div className="font-bold">
                      Agency({percentageCalculator(item.amount, item.agencyFee)}
                      %)
                    </div>
                    <div className="font-bold">
                      {numberWithCommas(Number(item.agencyFee).toFixed(2))}
                      {jobDetail.currency}
                    </div>
                  </div>
                </div>

                {item.commissionType === "commission" && (
                  <div className="grid grid-cols-3 gap-8 py-2 mt-2">
                    <div className="flex justify-between col-span-3">
                      <div className="font-bold">{item.artistName}</div>
                      <div className="font-bold">
                        {numberWithCommas(Number(item.artistFee).toFixed(2))}
                        {jobDetail.currency}
                      </div>
                    </div>
                  </div>
                )}

                <div className="grid grid-cols-3 gap-8 py-2 mt-2">
                  <div className="flex justify-between col-span-3">
                    <div className="font-bold">Total</div>
                    <div className="font-bold">
                      {numberWithCommas(Number(item.amount).toFixed(2))}
                      {jobDetail.currency}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default ArtistLineItemDetail;

import React, { useState, createContext, useEffect } from "react";
import { Spinner } from "@bigbinary/neetoui";
import { Header, Container } from "@bigbinary/neetoui/layouts";
import { useParams } from "react-router-dom";
import { getLicenses } from "apis/jobs/licenses";
import { getCategories } from "apis/settings/categories";
import { getDetails } from "apis/settings/details";
import { getQuantities } from "apis/settings/quantities";
import { getTerritories } from "apis/settings/territories";
import { getPeriods } from "apis/settings/periods";
import { getExclusivityPeriods } from "apis/settings/exclusivity_periods";
import { getEstimateRevisions } from "apis/jobs/estimate_revisions";
import { showToastrError } from "common";
import { getJob } from "apis/jobs/jobs";
import AddUsagePage from "./AddUsage";
import CreateUsagePage from "./CreateUsage";
import Tabs from "../Tabs";
import { dropDownListGenerator } from "common/helper";
import {
  jobWithNameNumber,
  getApprovedRevisionId,
} from "./../../common/helper";

export const UsageDetailsContext = createContext();

const UsageIndex = () => {
  const { id, revisionId } = useParams();
  const [jobDetail, setJobDetail] = useState("");
  const [jobDetailLoad, setJobDetailLoad] = useState(true);
  const [estimateRevisions, setEstimateRevisions] = useState([]);
  const [estimateRevisionsLoad, setEstimateRevisionsLoad] = useState(true);
  const [usageList, setUsageList] = useState([]);
  const [usageListLoad, setUsageListLoad] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [categoryListLoad, setCategoryListLoad] = useState(true);
  const [detailList, setDetailList] = useState([]);
  const [detailListLoad, setDetailListLoad] = useState(true);
  const [quantityList, setQuantityList] = useState([]);
  const [quantityListLoad, setQuantityListLoad] = useState(true);
  const [territoryList, setTerritoryList] = useState([]);
  const [territoryListLoad, setTerritoryListLoad] = useState(true);
  const [periodList, setPeriodList] = useState([]);
  const [periodListLoad, setPeriodListLoad] = useState(true);
  const [exclusivityPeriodList, setExclusivityPeriodList] = useState([]);
  const [exclusivityPeriodListLoad, setExclusivityPeriodListLoad] = useState(
    true
  );
  const [title, setTitle] = useState("");
  const [terms, setTerms] = useState("");
  const [alternateUsageNeeded, setAlternateUsageNeeded] = useState(false);

  useEffect(() => {
    getCategoryList();
    getDetailList();
    getQuantityList();
    getTerritoryList();
    getPeriodList();
    getExclusivityPeriodList();

    if (id) {
      loadJobDetail();
      loadEstimateRevisionsResponse();
    }
  }, [id]);

  useEffect(() => {
    if (revisionId) {
      usageListRecords();
    }
  }, [revisionId]);

  const getRevisionName = () => {
    return estimateRevisions.find(revision => revision.id == revisionId).name;
  };

  const getCategoryList = async () => {
    try {
      const response = await getCategories();
      setCategoryList(dropDownListGenerator(response.data.categories));
      setCategoryListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const getDetailList = async () => {
    try {
      const response = await getDetails();
      setDetailList(dropDownListGenerator(response.data.details));
      setDetailListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const getQuantityList = async () => {
    try {
      const response = await getQuantities();
      setQuantityList(dropDownListGenerator(response.data.quantities));
      setQuantityListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const getTerritoryList = async () => {
    try {
      const response = await getTerritories();
      setTerritoryList(dropDownListGenerator(response.data.territories));
      setTerritoryListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const getPeriodList = async () => {
    try {
      const response = await getPeriods();
      setPeriodList(dropDownListGenerator(response.data.periods));
      setPeriodListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const getExclusivityPeriodList = async () => {
    try {
      const response = await getExclusivityPeriods();
      setExclusivityPeriodList(
        dropDownListGenerator(response.data.exclusivityPeriods)
      );
      setExclusivityPeriodListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const loadJobDetail = async () => {
    try {
      const response = await getJob(id);
      setJobDetail(response.data.job);
      setJobDetailLoad(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const loadEstimateRevisionsResponse = async () => {
    try {
      const response = await getEstimateRevisions(id);
      setEstimateRevisions(response.data.estimateRevisions);
      setEstimateRevisionsLoad(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const usageListRecords = async () => {
    try {
      const response = await getLicenses(revisionId);
      setUsageList(response.data.licenses);
      setAlternateUsageNeeded(response.data.alternativeLicenseNeeded);
      setUsageListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const currentRevision = estimateRevisions.find(rev => rev.id === revisionId);

  let contextValue = {
    jobDetail,
    partiallyApprovedRevisionId: getApprovedRevisionId(estimateRevisions),
    usageList,
    setUsageList,
    categoryList,
    setCategoryList,
    detailList,
    setDetailList,
    quantityList,
    setQuantityList,
    territoryList,
    setTerritoryList,
    periodList,
    setPeriodList,
    exclusivityPeriodList,
    setExclusivityPeriodList,
    revisionId,
    usageListRecords,
    alternateUsageNeeded,
    title,
    setTitle,
    terms,
    setTerms,
    estimateRevisions,
    currentRevision,
    loadEstimateRevisionsResponse,
  };

  if (
    usageListLoad ||
    jobDetailLoad ||
    categoryListLoad ||
    detailListLoad ||
    quantityListLoad ||
    territoryListLoad ||
    periodListLoad ||
    exclusivityPeriodListLoad ||
    estimateRevisionsLoad
  ) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <UsageDetailsContext.Provider value={contextValue}>
      <Container isHeaderFixed>
        <Header
          title={`${getRevisionName()} / Usages`}
          breadcrumbs={[
            { text: "Jobs", link: "/jobs" },
            {
              text: jobWithNameNumber(jobDetail),
              link: `/jobs/${id}/overview`,
            },
          ]}
        />

        <Tabs jobId={jobDetail.id} revisionId={revisionId} />

        {(jobDetail.invoicesCount !== 0 || jobDetail.rcisCount !== 0) &&
        currentRevision?.licensesCount === 0 ? (
          <div className="flex items-center justify-center w-full h-full text-3xl">
            Usage is disabled
          </div>
        ) : (
          <div
            className="flex w-full overflow-hidden"
            style={{ height: "calc(100vh - 134px)" }}
          >
            {usageList.length > 0 ? <CreateUsagePage /> : <AddUsagePage />}
          </div>
        )}
      </Container>
    </UsageDetailsContext.Provider>
  );
};

export default UsageIndex;

import * as Yup from "yup";
import { User, Suitcase, History } from "@bigbinary/neeto-icons";

export const CONTACT_DETAILS_TABS = {
  Profile: {
    icon: User,
    label: "Profile",
  },
  Jobs: {
    icon: Suitcase,
    label: "Jobs",
  },
  Invoices: {
    icon: History,
    label: "History",
  },
};

export const ACTIVITY_LOG_VALIDATION_SCHEMA = Yup.object({
  crmActionTagId: Yup.string().nullable(),
});

export const INITIAL_ACTIVITY_LOG_VALUE = {
  note: "",
};

export const NOTE_VALIDATION_SCHEMA = Yup.object({
  crmActionTagId: Yup.string().nullable(),
});

export const INITIAL_NOTE_VALUE = {
  note: "",
};

import axios from "axios";

const getTemplateSettingsUrl = id => {
  if (id) {
    return `/api/v1/settings/template_settings/${id}`;
  }

  return `/api/v1/settings/template_settings`;
};

export const getTemplateSettings = (searchParams = "") => {
  let query = "";
  if (searchParams.length > 0) query = `q[job_type_name_i_cont_any]=${searchParams}`;
  return axios.get(`${getTemplateSettingsUrl()}?${query}`);
};

export const getTemplateSetting = id => {
  return axios.get(getTemplateSettingsUrl(id));
};

export const createTemplateSetting = payload => {
  return axios.post(getTemplateSettingsUrl(), payload);
};

export const updateTemplateSetting = (id, payload) => {
  return axios.put(getTemplateSettingsUrl(id), payload);
};

export const destroyTemplateSetting = id => {
  return axios.delete(getTemplateSettingsUrl(id));
};

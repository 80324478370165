import axios from "axios";

const getQuantitiesUrl = id => {
  if (id) {
    return `/api/v1/settings/quantities/${id}`;
  }

  return `/api/v1/settings/quantities`;
};

export const getQuantities = (searchParams = "") => {
  let query = "";
  if (searchParams.length > 0) query = `q[name_i_cont_any]=${searchParams}`;
  return axios.get(`${getQuantitiesUrl()}?${query}`);
};

export const createQuantity = payload => {
  return axios.post(getQuantitiesUrl(), payload);
};

export const updateQuantity = (id, payload) => {
  return axios.put(getQuantitiesUrl(id), payload);
};

export const destroyQuantity = id => {
  return axios.delete(getQuantitiesUrl(id));
};

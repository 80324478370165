import axios from "axios";
import { direction } from "common/helper";

const getRcisUrl = id => {
  if (id) {
    return `/api/v1/rci_module/rcis/${id}`;
  }

  return `/api/v1/rci_module/rcis`;
};

export const getRcis = (
  searchParams = "",
  sortProps = {},
  pageIndex,
  perPage,
  preferredLocation,
  preferredPeriod
) => {
  let paginationQuery = `page=${pageIndex}&per_page=${perPage}`;
  let searchQuery = `q[job_name_i_cont_any]=${searchParams}&q[contact_full_name_i_cont_any]=${searchParams}&q[job_organisation_name_i_cont_any]=${searchParams}&q[user_full_name_i_cont_any]=${searchParams}&q[serial_number_i_cont_any]=${searchParams}&q[m]=or&q[artist_company_name_i_cont_any]=${searchParams}&q[artist_first_name_i_cont_any]=${searchParams}&q[artist_last_name_i_cont_any]=${searchParams}`;
  let locationQuery = `&job_location_filter=${preferredLocation}`;
  let periodFilter = `&created_at_gteq=${preferredPeriod}`;
  let sortQuery = `q[s]=${sortProps?.column?.field}+${direction(
    sortProps?.order
  )}`;
  let query = paginationQuery;

  if (searchParams.length > 0) query += `&${searchQuery}`;
  if (sortProps?.column && sortProps?.order) query += `&${sortQuery}`;
  if (preferredLocation?.length > 0) query += `&${locationQuery}`;
  if (preferredPeriod) query += `&${periodFilter}`;

  return axios.get(`${getRcisUrl()}?${query}`);
};

import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Input,
  Select,
  Spinner,
  Modal,
  Pane,
  DatePicker,
} from "@bigbinary/neetoui";
import * as dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import { showToastrError } from "common";
import {
  dropDownListGenerator,
  dropDownListGeneratorForCompanyDetails,
  artistModuleName,
} from "common/helper";
import { useFormik } from "formik";
import { createOrganisation } from "apis/organisations/organisations";
import { getOrganisations } from "apis/jobs/organisations";
import { getOrganisationTypes } from "apis/settings/organisation_types";
import {
  getIndustrySectors,
  createIndustrySector,
} from "apis/settings/industry_sectors";
import { getStaffs } from "apis/jobs/staffs";
import { createContact } from "apis/organisations/contacts";
import { getContacts } from "apis/jobs/contacts";
import { createJob, getJob, updateJob } from "apis/jobs/jobs";
import { getCompanyDetails } from "apis/jobs/company_details";
import { getArtists } from "apis/jobs/artists";
import { getJobTypes } from "apis/settings/job_types";
import { getInvoices } from "apis/jobs/invoices";
import { useUserState } from "contexts/user";
import { VALIDATION_SCHEMA, INITIAL_VALUE } from "./constants";
import { JobStatus } from "./constants";
import { getFinalInvoice, getCommencementInvoice } from "./common/helper";
import { sleep } from "common/helper";
import AsyncPaginateSelect from "components/Common/AsyncPaginateSelect";

const NewJob = ({
  isOpen,
  onClose,
  initialFocusRef,
  orgId,
  conId,
  jobId,
  userId,
  setNewJobPane = () => {},
  detailsLoad = () => {},
  redirectTo = "",
}) => {
  const id = jobId;
  const { user } = useUserState();
  const history = useHistory();
  const newOrganisationId = orgId;
  const newContactId = conId;
  const [invoiceList, setInvoiceList] = useState([]);
  const [organisationOptions, setOrganisationOptions] = useState([]);
  const [organisationOptionsLoading, setOrganisationOptionsLoading] = useState(
    true
  );
  const [
    industrySectorOptionsLoading,
    setIndustrySectorOptionsLoading,
  ] = useState(true);
  const [staffOptions, setStaffOptions] = useState([]);
  const [staffOptionsLoading, setStaffOptionsLoading] = useState(true);
  const [contactOptions, setContactOptions] = useState([]);
  const [organisationTypeOptions, setOrganisationTypeOptions] = useState([]);
  const [industrySectorOptions, setIndustrySectorOptions] = useState([]);
  const [jobTypeOptions, setJobTypeOptions] = useState([]);
  const [companyDetailOptions, setCompanyDetailOptions] = useState([]);
  const [artistOptions, setArtistOptions] = useState([]);
  const [artistOptionsLoading, setArtistOptionsLoading] = useState(true);
  const [jobDetail, setJobDetail] = useState({});
  const [jobDetailLoad, setJobDetailLoad] = useState(true);
  const [organisationId, setOrganisationId] = useState("");
  const [contactId, setContactId] = useState("");
  const [artistIds, setArtistIds] = useState([]);
  const [contactIds, setContactIds] = useState([]);
  const [userIds, setUserIds] = useState([]);
  const [contactForm, setContactForm] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [contactValue, setContactValue] = useState();
  const [organisationFormModal, setOrganisationFormModal] = useState(false);
  const [organisationName, setOrganisationName] = useState("");
  const [organisationID, setOrganisationID] = useState("");
  const [organisationTypeId, setOrganisationTypeId] = useState("");
  const [additionalContactCreate, setAdditionalContactCreate] = useState(false);
  const [initialDataLoad, setInitialDataLoad] = useState(true);
  const [initialEditDataLoad, setInitialEditDataLoad] = useState(false);
  const [orgPage, setOrgPage] = useState(1);
  const [artistPage, setArtistPage] = useState(1);
  const [staffPage, setStaffPage] = useState(1);
  const [orgInitial, setOrgInitial] = useState(true);
  const [artistInitial, setArtistInitial] = useState(true);
  const [staffInitial, setStaffInitial] = useState(true);
  const [asyncDataLoad, setAsyncDataLoad] = useState(true);
  const [jobSubmitBtnLoad, setJobSubmitBtnLoad] = useState(false);
  const [contactSubmitBtnLoad, setContactSubmitBtnLoad] = useState(false);
  const [organisationSubmitBtnLoad, setOrganisationSubmitBtnLoad] = useState(
    false
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: jobDetail.id ? jobDetail : INITIAL_VALUE,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: values => (jobDetail.id ? onUpdate(values) : onCreate(values)),
  });

  useEffect(() => {
    if (isOpen) {
      loadInitialData();
    } else {
      formik.resetForm();
      setInvoiceList([]);
      setOrganisationOptions([]);
      setOrganisationOptionsLoading(true);
      setStaffOptions([]);
      setStaffOptionsLoading(true);
      setContactOptions([]);
      setOrganisationTypeOptions([]);
      setIndustrySectorOptions([]);
      setJobTypeOptions([]);
      setCompanyDetailOptions([]);
      setArtistOptions([]);
      setArtistOptionsLoading(true);
      setJobDetail({});
      setJobDetailLoad(true);
      setOrganisationId("");
      setContactId("");
      setArtistIds([]);
      setContactIds([]);
      setUserIds([]);
      setContactForm(false);
      setFirstName("");
      setLastName("");
      setEmail("");
      setContactValue();
      setOrganisationFormModal(false);
      setOrganisationName("");
      setOrganisationID("");
      setOrganisationTypeId("");
      setAdditionalContactCreate(false);
      setInitialDataLoad(true);
      setInitialEditDataLoad(false);
      setJobSubmitBtnLoad(false);
      setContactSubmitBtnLoad(false);
      setOrganisationSubmitBtnLoad(false);
      setOrgPage(1);
      setArtistPage(1);
      setStaffPage(1);
      setOrgInitial(true);
      setArtistInitial(true);
      setStaffInitial(true);
      setAsyncDataLoad(true);
    }
  }, [isOpen]);

  useEffect(() => {
    if (id && isOpen) {
      setInitialEditDataLoad(true);
      loadInitialEditData();
    }
  }, [id, isOpen]);

  useEffect(() => {
    if (newOrganisationId && !id && isOpen) {
      setOrganisationId(newOrganisationId);
      formik.setFieldValue("organisationId", newOrganisationId);
    }
  }, [newOrganisationId, isOpen]);

  useEffect(() => {
    if (newContactId && !id && isOpen) {
      setContactId(newContactId);
      formik.setFieldValue("contactId", newContactId);
    }
  }, [newContactId, isOpen]);

  useEffect(() => {
    if (user?.id && !id && isOpen) {
      formik.setFieldValue("staffId", user.id);
    }
  }, [user, isOpen]);

  useEffect(() => {
    if (userId && !id && isOpen) {
      formik.setFieldValue("staffId", userId);
    }
  }, [userId, isOpen]);

  useEffect(() => {
    if (formik.touched.contactId) {
      if (contactId === undefined) {
        formik.setFieldValue("contactId", "");
      } else {
        formik.setFieldValue("contactId", contactId);
      }
    }

    if (formik.touched.artistIds) {
      if (artistIds === null) {
        setArtistIds([]);
        formik.setFieldValue("artistsJobsAttributes", []);
      } else {
        let artistPayload = [];
        formik.values.artistsJobsAttributes?.map(artist => {
          if (artistIds.map(artist => artist.value).includes(artist.artistId)) {
            artistPayload = [
              ...artistPayload,
              { artistId: artist.artistId, id: artist.id },
            ];
          } else {
            artistPayload = [
              ...artistPayload,
              { artistId: artist.artistId, id: artist.id, _destroy: 1 },
            ];
          }
        });

        artistIds?.map(artist => {
          if (
            !formik.values.artistsJobsAttributes
              ?.map(artist => artist.artistId)
              .includes(artist.id)
          ) {
            artistPayload = [...artistPayload, { artistId: artist.id }];
          }
        });

        formik.setFieldValue("artistsJobsAttributes", artistPayload);
      }
    }

    if (formik.touched.contactIds) {
      if (contactIds === null) {
        setContactIds([]);
        formik.setFieldValue("contactIds", []);
      } else {
        let contactIdsOnly = contactIds.map(contact => contact.value);
        formik.setFieldValue("contactIds", contactIdsOnly);
      }
    }

    if (formik.touched.userIds) {
      if (userIds === null) {
        setUserIds([]);
        formik.setFieldValue("jobsUsersAttributes", []);
      } else {
        let userPayload = [];
        formik.values.jobsUsersAttributes?.map(jobUser => {
          if (
            userIds.find(selectedUser => selectedUser.value == jobUser.userId)
          ) {
            userPayload = [
              ...userPayload,
              { userId: jobUser.userId, id: jobUser.id },
            ];
          } else {
            userPayload = [
              ...userPayload,
              { userId: jobUser.userId, id: jobUser.id, _destroy: 1 },
            ];
          }
        });

        userIds.map(selectedUser => {
          if (
            !formik.values.jobsUsersAttributes?.find(
              jobUser => selectedUser.value == jobUser.userId
            )
          ) {
            userPayload = [...userPayload, { userId: selectedUser.value }];
          }
        });

        formik.setFieldValue("jobsUsersAttributes", userPayload);
      }
    }
  }, [contactId, artistIds, contactIds, userIds, isOpen]);

  useEffect(() => {
    if (
      staffInitial &&
      formik.values.staffId?.length > 0 &&
      staffOptions.length > 0
    ) {
      searchStaff(formik.values.staffId);
      setStaffInitial(false);
    }
  }, [formik.values.staffId, staffOptions, isOpen]);

  useEffect(() => {
    if (
      formik.values.organisationId?.length > 0 &&
      orgInitial &&
      organisationOptions.length > 0
    ) {
      searchOrganisation(formik.values.organisationId);
      setOrgInitial(false);
    }
  }, [organisationOptions, isOpen]);

  useEffect(() => {
    if (formik.values.organisationId?.length > 0) {
      loadContactList();
    }
  }, [formik.values.organisationId, isOpen]);

  useEffect(() => {
    if (
      artistInitial &&
      formik.values.artistsJobsAttributes?.length > 0 &&
      artistOptions.length > 0
    ) {
      setArtistIds(
        formik.values.artistsJobsAttributes.map(artistJobs => {
          return { id: artistJobs.artistId, value: artistJobs.artistId };
        })
      );
      searchArtists(
        formik.values.artistsJobsAttributes.map(artistJob => artistJob.artistId)
      );
      setArtistInitial(false);
    }
  }, [formik.values.artistsJobsAttributes, artistOptions, isOpen]);

  useEffect(() => {
    if (
      staffInitial &&
      formik.values.jobsUsersAttributes?.length > 0 &&
      staffOptions.length > 0
    ) {
      setUserIds(
        formik.values.jobsUsersAttributes.map(jobUser => {
          return { id: jobUser.userId, value: jobUser.userId };
        })
      );
      searchStaff(
        formik.values.jobsUsersAttributes.map(jobUser => jobUser.userId)
      );
      setStaffInitial(false);
    }
  }, [formik.values.jobsUsersAttributes, staffOptions, isOpen]);

  useEffect(() => {
    if (contactValue) {
      setFirstName(contactValue?.split(" ")[0]);
      setLastName(contactValue?.split(" ")[1]);
    }
  }, [contactValue]);

  useEffect(() => {
    if (organisationFormModal) {
      loadOrganisationTypeList();
    }
  }, [organisationFormModal]);

  useEffect(() => {
    if (user && !formik.values.id) {
      formik.setFieldValue(
        "companyDetailId",
        companyDetailOptions.find(
          company =>
            company.locationId === user.location_id &&
            company.currencyId === company.defaultCurrencyId
        )?.id
      );
    }
  }, [user, companyDetailOptions, isOpen]);

  const loadInitialData = async () => {
    await loadJobTypeList();
    await loadCompanyDetailsList();
    await setInitialDataLoad(false);
    await loadOrganisationList();
    await loadStaffList();
    await loadArtistList();
    await loadIndustrySectorList();
    await setAsyncDataLoad(false);
  };

  const loadInitialEditData = async () => {
    await setJobDetailResponse();
    await loadInvoiceListResponse();
    await setInitialEditDataLoad(false);
  };

  const loadInvoiceListResponse = async () => {
    try {
      const response = await getInvoices(id);
      setInvoiceList(response.data.invoices);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const setJobDetailResponse = async () => {
    try {
      const response = await getJob(id);
      setJobDetail(response.data.job);
      setJobDetailLoad(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const loadOrganisationList = async search => {
    setOrganisationOptionsLoading(true);
    await sleep(1000);

    try {
      let filteredOptions;
      let totalRecords;

      if (!search) {
        const response = await getOrganisations("", orgPage);
        setOrganisationOptions(
          removeDuplicacy([
            ...organisationOptions,
            ...response.data.organisations,
          ])
        );
        totalRecords = response.data.totalRecords;
        setOrgPage(orgPage + 1);
        filteredOptions = response.data.organisations;
      } else {
        const searchLower = search.toLowerCase();

        const response = await getOrganisations(searchLower, orgPage);
        setOrganisationOptions(
          removeDuplicacy([
            ...organisationOptions,
            ...response.data.organisations,
          ])
        );
        setOrgPage(orgPage + 1);
        filteredOptions = response.data.organisations;
        totalRecords = filteredOptions.length;
      }
      return {
        options: dropDownListGenerator(removeDuplicacy(filteredOptions)),
        hasMore: filteredOptions.length === totalRecords ? false : true,
      };
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setOrganisationOptionsLoading(false);
    }
  };

  const loadArtistList = async search => {
    setArtistOptionsLoading(true);
    await sleep(1000);

    try {
      let filteredOptions;
      let totalRecords;
      if (!search) {
        const response = await getArtists("", artistPage);
        setArtistOptions(
          removeDuplicacy([...artistOptions, ...response.data.artists])
        );
        totalRecords = response.data.totalRecords;
        setArtistPage(artistPage + 1);
        filteredOptions = response.data.artists;
      } else {
        const searchLower = search.toLowerCase();

        const response = await getArtists(searchLower, artistPage);
        setArtistOptions(
          removeDuplicacy([...artistOptions, ...response.data.artists])
        );

        totalRecords = response.data.totalRecords;
        setArtistPage(artistPage + 1);
        filteredOptions = response.data.artists;
      }

      return {
        options: dropDownListGenerator(removeDuplicacy(filteredOptions)),
        hasMore: filteredOptions.length === totalRecords ? false : true,
      };
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setArtistOptionsLoading(false);
    }
  };

  const removeDuplicacy = array => {
    var resArr = [];
    array.filter(item => {
      var i = resArr.findIndex(x => x.id == item.id);
      if (i <= -1) {
        resArr.push({ ...item });
      }
      return null;
    });

    return resArr;
  };

  const searchOrganisation = async organisationId => {
    try {
      const searchResponse = await getOrganisations(organisationId, 1);
      setOrganisationOptions([
        ...organisationOptions,
        ...searchResponse.data.organisations,
      ]);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const searchArtists = async idArray => {
    try {
      const response = await getArtists("", 1, idArray);
      setArtistOptions([...artistOptions, ...response.data.artists]);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const loadStaffList = async search => {
    setStaffOptionsLoading(true);
    await sleep(1000);

    try {
      let filteredOptions;
      let totalRecords;

      if (!search) {
        const response = await getStaffs("", staffPage);
        setStaffOptions(
          removeDuplicacy([...staffOptions, ...response.data.staffs])
        );
        totalRecords = response.data.totalRecords;
        setStaffPage(staffPage + 1);
        filteredOptions = response.data.staffs;
      } else {
        const searchLower = search.toLowerCase();

        filteredOptions = staffOptions.filter(({ fullName }) =>
          fullName.toLowerCase().includes(searchLower)
        );

        totalRecords = filteredOptions.length;
      }

      return {
        options: dropDownListGenerator(removeDuplicacy(filteredOptions)),
        hasMore: filteredOptions.length === totalRecords ? false : true,
      };
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setStaffOptionsLoading(false);
    }
  };

  const searchStaff = async staffId => {
    try {
      const searchResponse = await getStaffs(staffId, 1);
      setStaffOptions([...staffOptions, ...searchResponse.data.staffs]);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const loadCompanyDetailsList = async () => {
    try {
      const response = await getCompanyDetails();
      setCompanyDetailOptions(
        dropDownListGeneratorForCompanyDetails(response.data.companyDetails)
      );
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const loadJobTypeList = async () => {
    try {
      const response = await getJobTypes();
      setJobTypeOptions(dropDownListGenerator(response.data.jobTypes));
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const loadOrganisationTypeList = async () => {
    try {
      const response = await getOrganisationTypes();
      setOrganisationTypeOptions(
        dropDownListGenerator(response.data.organisationTypes)
      );
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const loadContactList = async () => {
    try {
      const response = await getContacts(
        organisationId.length > 0
          ? organisationId
          : formik.values.organisationId
      );
      setContactOptions(dropDownListGenerator(response.data.contacts));
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const onCreate = async formValue => {
    try {
      setJobSubmitBtnLoad(true);
      const { staffId } = formValue;
      const payload = { job: { ...formValue, userId: staffId } };
      const response = await createJob(payload);
      history.push(`/jobs/${response.data.id}/overview`);
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setJobSubmitBtnLoad(false);
    }
  };

  const onUpdate = async formValue => {
    try {
      setJobSubmitBtnLoad(true);
      const { staffId } = formValue;
      const payload = { job: { ...formValue, userId: staffId } };
      await updateJob(jobDetail.id, payload);
      setNewJobPane(false);

      if (redirectTo?.length > 0) {
        history.push(redirectTo);
      } else {
        history.push(`/jobs/${jobDetail.id}/overview`);
      }
      detailsLoad();
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setJobSubmitBtnLoad(false);
    }
  };

  const handleContactCreate = async () => {
    try {
      setContactSubmitBtnLoad(true);
      const response = await createContact(formik.values.organisationId, {
        contact: {
          firstName: firstName,
          lastName: lastName,
          organisation_contacts_attributes: [
            { email: email, organisationId: formik.values.organisationId },
          ],
        },
      });

      if (!additionalContactCreate) {
        setContactId(response.data.id);
        formik.setFieldValue("contactId", response.data.id);
      }
      await loadContactList();
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setContactSubmitBtnLoad(false);
    }
  };

  const handleOrganisationCreate = async () => {
    try {
      setOrganisationSubmitBtnLoad(true);
      const response = await createOrganisation({
        organisation: {
          name: organisationName,
          organisationSerial: organisationID,
          organisationTypeId: organisationTypeId,
          addresses_attributes: [{ kind: "primary" }],
        },
      });
      setOrganisationId(response.data.id);
      formik.setFieldValue("organisationId", response.data.id);
      await searchOrganisation(response.data.id);
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setOrganisationSubmitBtnLoad(false);
    }
  };

  const loadIndustrySectorList = async () => {
    try {
      setIndustrySectorOptionsLoading(true);
      const response = await getIndustrySectors();
      setIndustrySectorOptions(
        dropDownListGenerator(response.data.industrySectors)
      );
      setIndustrySectorOptionsLoading(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const handleIndustrySectorCreate = async value => {
    try {
      const response = await createIndustrySector({
        industry_sector: { name: value },
      });
      formik.setFieldValue("industrySectorId", response.data.value);
      await loadIndustrySectorList();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const finalInvoice = getFinalInvoice(invoiceList);
  const commencementInvoice = getCommencementInvoice(invoiceList);

  return (
    <Pane
      size="large"
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={initialFocusRef}
    >
      <Pane.Header>
        <Typography style="h2" weight="semibold">
          {jobDetail.id ? "Edit Job" : "Add Job"}
        </Typography>
      </Pane.Header>
      <Pane.Body>
        {(id && jobDetailLoad && initialEditDataLoad) || initialDataLoad ? (
          <div className="flex items-center justify-center w-full h-full">
            <Spinner />
          </div>
        ) : (
          <div className="flex flex-col w-full h-full pb-3 pr-3 space-y-6 overflow-y-auto">
            <div className="grid grid-cols-1 gap-4">
              <Input
                label="Name"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                required={true}
                error={
                  Boolean(formik.touched.name && formik.errors.name) &&
                  formik.errors.name
                }
                {...formik.getFieldProps("name")}
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <Select
                label="Status"
                name="status"
                id="status"
                placeholder="Select an Option"
                defaultValue={{ label: "Estimate", value: "estimate" }}
                value={JobStatus.find(
                  status => status.value === formik.values.status
                )}
                error={
                  Boolean(formik.touched.status && formik.errors.status) &&
                  formik.errors.status
                }
                required={true}
                touched={formik.touched.status}
                options={JobStatus}
                onChange={opt => {
                  formik.setFieldValue("status", opt.value);
                  formik.setFieldTouched("status", true);
                }}
              />

              <Select
                label="Job Type"
                name="jobTypeId"
                id="jobTypeId"
                placeholder="Select a Job type"
                value={jobTypeOptions.find(
                  jobType => jobType.value === formik.values.jobTypeId
                )}
                error={
                  Boolean(
                    formik.touched.jobTypeId && formik.errors.jobTypeId
                  ) && formik.errors.jobTypeId
                }
                required={true}
                options={jobTypeOptions}
                onChange={async opt => {
                  await formik.setFieldValue("jobTypeId", opt.value);
                  await formik.setFieldTouched("jobTypeId", true);
                }}
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <AsyncPaginateSelect
                label="Client"
                required={true}
                isCreatable
                isDisabled={asyncDataLoad}
                isLoading={asyncDataLoad || organisationOptionsLoading}
                cacheOptions
                onCreateOption={value => {
                  setOrganisationFormModal(true);
                  setOrganisationName(value);
                }}
                loadOptions={loadOrganisationList}
                value={
                  dropDownListGenerator(
                    removeDuplicacy(organisationOptions)
                  ).find(org => org.value === formik.values.organisationId) ||
                  null
                }
                onChange={async opt => {
                  if (opt) {
                    setOrganisationId(opt.value);
                    await formik.setFieldValue("organisationId", opt.value);
                    formik.setFieldValue(
                      "industrySectorId",
                      opt.industrySectorId
                    );
                    await formik.setFieldTouched("organisationId", true);
                  } else {
                    setOrganisationId("");
                  }
                }}
                name="organisationId"
                id="organisationId"
                placeholder="Select a Organisation"
                error={
                  Boolean(
                    formik.touched.organisationId &&
                      formik.errors.organisationId
                  ) && formik.errors.organisationId
                }
              />

              <Select
                label="Industry Sector"
                isCreateable
                name="industrySectorId"
                id="industrySectorId"
                placeholder="Select a Industry Sector"
                isLoading={industrySectorOptionsLoading}
                onCreateOption={handleIndustrySectorCreate}
                options={industrySectorOptions}
                value={industrySectorOptions.find(
                  obj => obj.value === formik.values.industrySectorId
                )}
                error={
                  Boolean(
                    formik.touched.industrySectorId &&
                      formik.errors.industrySectorId
                  ) && formik.errors.industrySectorId
                }
                onChange={opt => {
                  formik.setFieldTouched("industrySectorId", true);
                  formik.setFieldValue("industrySectorId", opt.value);
                }}
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <Input
                label="Client’s Client"
                name="parentClient"
                id="parentClient"
                onChange={formik.handleChange}
                value={formik.values.parentOrganisation}
                error={
                  Boolean(
                    formik.touched.parentOrganisation &&
                      formik.errors.parentOrganisation
                  ) && formik.errors.parentOrganisation
                }
                {...formik.getFieldProps("parentOrganisation")}
              />

              <AsyncPaginateSelect
                cacheOptions
                isDisabled={asyncDataLoad}
                isLoading={asyncDataLoad || staffOptionsLoading}
                label="Staff"
                name="staffId"
                id="staffId"
                placeholder="Select a Staff"
                required
                value={dropDownListGenerator(
                  removeDuplicacy(staffOptions)
                ).find(staff => staff.value === formik.values.staffId)}
                error={
                  Boolean(formik.touched.staffId && formik.errors.staffId) &&
                  formik.errors.staffId
                }
                onChange={async opt => {
                  await formik.setFieldValue("staffId", opt.value);
                  await formik.setFieldTouched("staffId", true);
                }}
                loadOptions={loadStaffList}
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <Select
                isClearable
                isCreateable
                required={true}
                label="Nominated Contact"
                isDisabled={!formik.values.organisationId}
                onCreateOption={async value => {
                  await setAdditionalContactCreate(true);
                  setContactForm(true);
                  setContactValue(value);
                }}
                options={contactOptions}
                value={
                  contactOptions.find(
                    contact =>
                      contact.value === (formik.values.contactId || contactId)
                  ) || null
                }
                onChange={opt => {
                  setContactId(opt?.value);
                  formik.setFieldTouched("contactId", true);
                }}
                name="contactId"
                id="contactId"
                placeholder="Select a Contact"
                error={
                  Boolean(
                    formik.touched.contactId && formik.errors.contactId
                  ) && formik.errors.contactId
                }
              />

              <AsyncPaginateSelect
                isMulti
                cacheOptions
                isDisabled={asyncDataLoad}
                isLoading={asyncDataLoad || staffOptionsLoading}
                label="Additional Staff"
                name="staffId"
                id="staffId"
                placeholder="Select a Staff"
                value={dropDownListGenerator(
                  removeDuplicacy(staffOptions)
                ).filter(user =>
                  userIds.map(user => user.id).includes(user.value)
                )}
                error={
                  Boolean(formik.touched.userIds && formik.errors.userIds) &&
                  formik.errors.userIds
                }
                onChange={async opt => {
                  setUserIds(opt);
                  formik.setFieldTouched("userIds", true);
                }}
                loadOptions={loadStaffList}
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <Select
                label="Company Detail"
                name="companyDetailId"
                id="companyDetailId"
                isDisabled={finalInvoice || commencementInvoice}
                placeholder="Select a company"
                value={companyDetailOptions.find(
                  type => type.value === formik.values.companyDetailId
                )}
                required={true}
                onChange={opt => {
                  formik.setFieldValue("companyDetailId", opt.value);
                  formik.setFieldValue("locationId", opt.locationId);
                  formik.setFieldValue("currencyId", opt.currencyId);
                  formik.setFieldTouched("companyDetailId", true);
                }}
                options={companyDetailOptions}
                error={
                  Boolean(
                    formik.touched.companyDetailId &&
                      formik.errors.companyDetailId
                  ) && formik.errors.companyDetailId
                }
              />

              <DatePicker
                label="Enquiry Date"
                name="enquiryDate"
                dateFormat="DD/MM/YYYY"
                required={true}
                allowClear={false}
                value={dayjs(formik.values.enquiryDate)}
                onChange={date =>
                  formik.setFieldValue("enquiryDate", date.format("YYYY-MM-DD"))
                }
                getPopupContainer={triggerNode => triggerNode.parentNode}
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <Input
                label="Client Job Number"
                name="clientJobNumber"
                onChange={formik.handleChange}
                value={formik.values.clientJobNumber}
                error={
                  Boolean(
                    formik.touched.clientJobNumber &&
                      formik.errors.clientJobNumber
                  ) && formik.errors.clientJobNumber
                }
                {...formik.getFieldProps("clientJobNumber")}
              />

              <Input
                label="Purchase Order"
                name="purchaseOrder"
                onChange={formik.handleChange}
                value={formik.values.purchaseOrder}
                error={
                  Boolean(
                    formik.touched.purchaseOrder && formik.errors.purchaseOrder
                  ) && formik.errors.purchaseOrder
                }
                {...formik.getFieldProps("purchaseOrder")}
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <Select
                isMulti
                id="contacts"
                isCreateable
                label="Additional Contacts"
                strategy="fixed"
                isDisabled={!formik.values.organisationId}
                onCreateOption={async value => {
                  await setAdditionalContactCreate(false);
                  setContactForm(true);
                  setContactValue(value);
                }}
                placeholder="Select Contacts"
                options={contactOptions.filter(
                  contact => contact.value !== formik.values.contactId
                )}
                value={contactOptions.filter(
                  contact =>
                    formik.values.contactIds?.includes(contact.value) &&
                    formik.values.contactId !== contact.value
                )}
                isClearable={true}
                error={
                  Boolean(
                    formik.touched.contactIds && formik.errors.contactIds
                  ) && formik.errors.contactIds
                }
                onChange={opt => {
                  setContactIds(opt);
                  formik.setFieldTouched("contactIds", true);
                }}
              />

              <AsyncPaginateSelect
                cacheOptions
                isDisabled={asyncDataLoad}
                isLoading={asyncDataLoad || artistOptionsLoading}
                label={artistModuleName()}
                isMulti
                id="artists"
                placeholder="Select Artists"
                strategy="fixed"
                value={dropDownListGenerator(
                  removeDuplicacy(artistOptions),
                  "name"
                ).filter(artist =>
                  artistIds.map(artist => artist.id).includes(artist.value)
                )}
                isClearable={true}
                error={
                  Boolean(
                    formik.touched.artistIds && formik.errors.artistIds
                  ) && formik.errors.artistIds
                }
                onChange={opt => {
                  setArtistIds(opt);
                  formik.setFieldTouched("artistIds", true);
                }}
                loadOptions={loadArtistList}
              />
            </div>
          </div>
        )}
      </Pane.Body>
      <Modal isOpen={contactForm} onClose={() => setContactForm(false)}>
        <Modal.Header>
          <Typography style="h2" weight="semibold">
            Create Contact
          </Typography>
        </Modal.Header>
        <Modal.Body>
          <div className="flex flex-col w-full space-y-6">
            <Input
              required
              label="First Name"
              name="firstName"
              onChange={e => setFirstName(e.target.value)}
              value={firstName}
            />

            <Input
              required
              label="Last Name"
              name="lastName"
              onChange={e => setLastName(e.target.value)}
              value={lastName}
            />

            <Input
              required
              type="email"
              label="Email"
              name="email"
              onChange={e => setEmail(e.target.value)}
              value={email}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            label="Save Changes"
            loading={contactSubmitBtnLoad}
            onClick={() => {
              handleContactCreate();
              setContactForm(false);
            }}
          />
          <Button
            label="Cancel"
            style="text"
            onClick={() => setContactForm(false)}
          />
        </Modal.Footer>
      </Modal>

      <Modal
        isOpen={organisationFormModal}
        onClose={() => setOrganisationFormModal(false)}
      >
        <Modal.Header>
          <Typography style="h2" weight="semibold">
            Create Organisation
          </Typography>
        </Modal.Header>

        <Modal.Body>
          <div className="flex flex-col w-full space-y-6">
            <Input
              required
              label="Name"
              name="name"
              value={organisationName}
              onChange={e => setOrganisationName(e.target.value)}
            />

            <Input
              required
              label="Organisation ID"
              name="organisationID"
              value={organisationID}
              onChange={e => setOrganisationID(e.target.value)}
            />

            <Select
              label="Organisation Type"
              name="organisationTypeId"
              id="organisationTypeId"
              placeholder="Select a type"
              value={organisationTypeOptions.find(
                type => type.value === organisationTypeId
              )}
              onChange={opt => setOrganisationTypeId(opt.value)}
              options={organisationTypeOptions}
            />
          </div>
        </Modal.Body>

        <Modal.Footer className="space-x-2">
          <Button
            label="Save Changes"
            loading={organisationSubmitBtnLoad}
            onClick={() => {
              handleOrganisationCreate();
              setOrganisationFormModal(false);
            }}
          />
          <Button
            label="Cancel"
            style="text"
            onClick={() => setOrganisationFormModal(false)}
          />
        </Modal.Footer>
      </Modal>
      <Pane.Footer className="flex items-center gap-x-2">
        <Button
          label="Save Changes"
          loading={jobSubmitBtnLoad}
          onClick={() => formik.handleSubmit()}
        />
        <Button label="Cancel" style="text" onClick={onClose} />
      </Pane.Footer>
    </Pane>
  );
};

export default NewJob;

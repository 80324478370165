import React from "react";
import RciMailerPreview from "./RciMailerPreview";

const MailViewer = ({
  formik,
  jobDetail,
  docUrl,
  final,
  companyDetails,
  rciDetail,
  artistDetail,
  defaultTemplateSetting,
  emailBackground,
}) => {
  return (
    <div className="w-3/5 overflow-y-scroll">
      <div
        className={`p-6 ${
          final ? "final-rci-email-body" : "comm-rci-email-body"
        }`}
      >
        <RciMailerPreview
          formik={formik}
          jobDetail={jobDetail}
          docUrl={docUrl}
          final={final}
          companyDetails={companyDetails}
          rciDetail={rciDetail}
          artistDetail={artistDetail}
          defaultTemplateSetting={defaultTemplateSetting}
          emailBackground={emailBackground}
        />
      </div>
    </div>
  );
};

export default MailViewer;

import React from "react";
import { Button, Label } from "@bigbinary/neetoui";

const Details = ({ artistDetail }) => {
  return (
    <div
      className="flex flex-col overflow-y-auto"
      style={{ height: "calc(100vh - 134px)" }}
    >
      <div className="flex flex-col px-6 my-6">
        <div className="grid grid-cols-3 gap-x-4 gap-y-6">
          <div className="flex flex-col space-y-1">
            <Label>Instagram</Label>
            {artistDetail.instagramUserName ? (
              <Button
                style="link"
                href={`https://www.instagram.com/${artistDetail.instagramUserName}`}
                target="_blank"
                label={`@${artistDetail.instagramUserName}`}
              />
            ) : (
              <p className="text-sm font-semibold text-gray-800">N/A</p>
            )}
          </div>

          <div className="flex flex-col space-y-1">
            <Label>Emergency Contact</Label>
            <p className="text-sm font-semibold text-gray-800">
              {artistDetail.emergencyContact || "N/A"}
            </p>
          </div>

          <div className="flex flex-col space-y-1">
            <Label>Personal Website</Label>
            {artistDetail.personalWebsite ? (
              <Button
                style="link"
                href={artistDetail.personalWebsite}
                target="_blank"
                label={artistDetail.personalWebsite}
              />
            ) : (
              <p className="text-sm font-semibold text-gray-800">N/A</p>
            )}
          </div>

          <div className="flex flex-col col-span-3 space-y-1">
            <Label>House Accounts</Label>
            <p className="text-sm font-semibold text-gray-800" dangerouslySetInnerHTML={{
              __html: artistDetail?.houseAccounts
                ? artistDetail?.houseAccounts?.split("\n").join("<br>")
                : "N/A" }}></p>
          </div>

          <div className="flex flex-col col-span-3 space-y-1">
            <Label>Personal Details</Label>
            <p className="text-sm font-semibold text-gray-800" dangerouslySetInnerHTML={{
              __html: artistDetail?.about
                ? artistDetail?.about
                    ?.split("\\n")
                    .join("\n")
                    .split("\n")
                    .join("<br>")
                : "N/A"}}>
            </p>
          </div>

          <div className="flex flex-col col-span-3 space-y-1">
            <Label>Other Notes</Label>
            <p className="text-sm font-semibold text-gray-800" dangerouslySetInnerHTML={{
              __html: artistDetail?.otherNotes
                ? artistDetail?.otherNotes?.split("\n").join("<br>")
                : "N/A"}}>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;

import React, { useState, useRef, useEffect } from "react";
import { MenuHorizontal } from "@bigbinary/neeto-icons";
import {
  Spinner,
  Button,
  Alert,
  Input,
  Modal,
  Dropdown,
  Typography,
  Table,
} from "@bigbinary/neetoui";
import { Header, Container } from "@bigbinary/neetoui/layouts";
import { isEmpty } from "ramda";
import { showToastrError } from "common";
import { getRandomNotFoundImage } from "common/helper";
import {
  getMetrics,
  createMetric,
  updateMetric,
  destroyMetric,
} from "apis/settings/metrics";
import EmptyState from "components/Common/EmptyState";

const Metric = ({ breadcrumbs }) => {
  const inputRef = useRef();
  const [metricList, setMetricList] = useState([]);
  const [metricListLoad, setMetricListLoad] = useState(true);
  const [metricId, setMetricId] = useState("");
  const [modalState, setModalState] = useState(false);
  const [deleteModalState, setDeleteModalState] = useState(false);
  const [name, setName] = useState("");
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const { Menu, MenuItem } = Dropdown;
  const [emptyImage, setEmptyImage] = useState();

  useEffect(() => {
    loadEstimateLineItemData();
    setEmptyImage(getRandomNotFoundImage());
  }, []);

  const loadEstimateLineItemData = async () => {
    loadMetricList();
  };

  const loadMetricList = async () => {
    try {
      const metricResponse = await getMetrics();
      setMetricList(metricResponse.data.metrics || []);
      setMetricListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const resetAllStates = () => {
    loadMetricList();
    setMetricId("");
    setModalState(false);
    setDeleteModalState(false);
    setName("");
  };

  const createMetricEntry = async () => {
    try {
      await createMetric({ metric: { name: name } });
      resetAllStates();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const updateMetricEntry = async () => {
    try {
      await updateMetric(metricId, { metric: { name: name } });
      resetAllStates();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const destroyMetricEntry = async () => {
    try {
      await destroyMetric(metricId);
      resetAllStates();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const COLUMN_DATA = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "90%",
      render: (_, rowData) => <>{rowData.name ? rowData.name : "-"}</>,
      fixed: "left",
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: "10%",
      render: (_, rowData) => (
        <Dropdown
          autoWidth
          closeOnSelect
          buttonStyle="text"
          strategy="fixed"
          icon={MenuHorizontal}
        >
          <Menu>
            <MenuItem.Button
              onClick={() => {
                setMetricId(rowData.id);
                setName(rowData.name);
                setModalState(true);
              }}
            >
              Edit
            </MenuItem.Button>
            <MenuItem.Button
              style="danger"
              onClick={() => {
                setMetricId(rowData.id);
                setDeleteModalState(true);
              }}
            >
              Delete
            </MenuItem.Button>
          </Menu>
        </Dropdown>
      ),
    },
  ];

  if (metricListLoad) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <Container>
      <Header
        title="Metrics"
        breadcrumbs={breadcrumbs}
        actionBlock={
          <Button
            label="Add Metric"
            onClick={() => {
              setName("");
              setMetricId("");
              setModalState(true);
            }}
          />
        }
      />
      <div className="w-full" style={{ height: "calc(100vh - 128px)" }}>
        {!isEmpty(metricList) ? (
          <Table fixedHeight columnData={COLUMN_DATA} rowData={metricList} currentPageNumber={pageIndex}
          defaultPageSize={pageSize}
          handlePageChange={(page, pageSize) => {
            setPageIndex(page);
            setPageSize(pageSize);
          }} />
        ) : (
          <EmptyState
            image={emptyImage}
            title="No Metrics Found"
            description="We couldn’t find any metrics. Try creating one."
            primaryButtonProps={{
              label: "Add Metric",
              onClick: () => {
                setName("");
                setMetricId("");
                setModalState(true);
              },
            }}
          />
        )}
      </div>
      <Modal
        isOpen={modalState}
        onClose={() => setModalState(false)}
        initialFocusRef={inputRef}
      >
        <Modal.Header>
          <Typography style="h2" weight="semibold">
            {metricId ? "Edit Metric" : "Add Metric"}
          </Typography>
        </Modal.Header>

        <Modal.Body>
          <Input
            label="Name"
            name="name"
            value={name}
            ref={inputRef}
            onChange={e => setName(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer className="flex gap-x-2">
          <Button
            label="Save changes"
            onClick={() => {
              if (metricId) {
                updateMetricEntry();
              } else {
                createMetricEntry();
              }
            }}
          />
          <Button
            label="Cancel"
            style="text"
            onClick={() => setModalState(false)}
          />
        </Modal.Footer>
      </Modal>

      <Alert
        isOpen={deleteModalState}
        title="Delete Metric"
        message="Are you sure you want to delete the Metric?"
        onClose={() => setDeleteModalState(false)}
        onSubmit={() => destroyMetricEntry()}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, delete"
      />
    </Container>
  );
};

export default Metric;

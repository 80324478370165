/* eslint-disable react/display-name */
import React from "react";
import { Button, Tag, Dropdown, Table, Typography } from "@bigbinary/neetoui";
import * as dayjs from "dayjs";
import {
  humanize,
  getJobsStatusColor,
  numberWithCommas,
  displayData,
  artistModuleName,
} from "common/helper";

const Jobs = ({ list, title }) => {
  const { Menu, MenuItem } = Dropdown;

  const renderName = rowData => {
    return (
      <Button
        style="link"
        to={`/jobs/${rowData.id}/overview`}
        label={rowData.name}
      />
    );
  };

  const renderOrganisation = rowData => {
    return (
      <Button
        style="link"
        to={`/organisations/${rowData.organisationId}`}
        label={rowData.organisationName}
      />
    );
  };

  const renderStaff = rowData => {
    return (
      <Button
        style="link"
        to={`/staff/${rowData.staffId}`}
        label={rowData.staffFullName}
      />
    );
  };

  const renderArtist = rowData => {
    return (
      <Dropdown
        autoWidth
        closeOnSelect
        trigger="hover"
        position="top"
        customTarget={
          <p className="w-48 truncate">
            {rowData.artists?.map(artist => artist.name).join(", ")}
          </p>
        }
      >
        <Menu>
          {rowData.artists?.map(artist => {
            return (
              <MenuItem.Button
                className="no-underline"
                key={artist.id}
                to={`/${artistModuleName().toLowerCase()}/${artist.id}`}
              >
                {artist.name}
              </MenuItem.Button>
            );
          })}
        </Menu>
      </Dropdown>
    );
  };

  const renderStatus = rowData => {
    return (
      <Tag
        type="solid"
        style={getJobsStatusColor(rowData.status)}
        label={humanize(rowData.status)}
      />
    );
  };

  const renderAmount = rowData => {
    return rowData.approvedAmount
      ? `${numberWithCommas(Number(rowData.approvedAmount).toFixed(2))} ${
          rowData.currency
        }`
      : "";
  };

  const COLUMN_DATA = [
    {
      title: "Job No.",
      dataIndex: "serialNumber",
      key: "serialNumber",
      width: 100,
      fixed: "left",
      render: (_, rowData) => displayData(rowData, "serialNumber"),
    },
    {
      title: "Title",
      dataIndex: "name",
      key: "name",
      width: 200,
      fixed: "left",
      render: (_, rowData) => renderName(rowData),
    },
    {
      title: "Organisation",
      dataIndex: "organisationName",
      key: "organisationName",
      width: 200,
      render: (_, rowData) => renderOrganisation(rowData),
    },
    {
      title: "Staff",
      dataIndex: "staffFullName",
      key: "staffFullName",
      width: 150,
      render: (_, rowData) => renderStaff(rowData),
    },

    {
      title: artistModuleName(),
      dataIndex: "artists",
      key: "artists",
      width: 150,
      render: (_, rowData) => renderArtist(rowData),
    },
    {
      title: "Enquired On",
      dataIndex: "enquiryDate",
      key: "enquiryDate",
      render: enquiryDate => dayjs(enquiryDate).format("DD/MM/YYYY"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 150,
      render: (_, rowData) => renderStatus(rowData),
    },
    {
      title: "Amount",
      dataIndex: "approvedAmount",
      key: "approvedAmount",
      width: 150,
      render: (_, rowData) => renderAmount(rowData),
    },
  ];

  return (
    <div className="flex flex-col w-full p-4 rounded bg-white">
      <Typography
        style="h3"
        weight="semibold"
        className="mb-4 mt-2 text-gray-800 px-3"
      >
        {title}
      </Typography>
      <Table fixedHeight columnData={COLUMN_DATA} rowData={list} />
    </div>
  );
};

export default Jobs;

import React from "react";
import CobblerBlack from "images/CobblerBlack.png";
import { Button, Input, Toastr } from "@bigbinary/neetoui";
import { resetThePassword } from "apis/password_reset";
import { useFormik } from "formik";
import { showToastrError } from "common";
import { useHistory } from "react-router-dom";
import { PASSWORD_INITIAL_VALUE, PASSWORD_SCHEMA } from "./constants";

const ResetPassword = () => {
  const history = useHistory();
  const formik = useFormik({
    initialValues: PASSWORD_INITIAL_VALUE,
    validationSchema: PASSWORD_SCHEMA,
    onSubmit: () => {
      resetPasswordData();
    },
  });

  const resetPasswordData = async () => {
    try {
      const response = await resetThePassword(formik.values);
      Toastr.info(response.data.notice)
      history.push("/");
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  return (
    <div className="flex flex-grow h-screen neeto-ui-bg-white">
      <div
        className="relative w-full p-8 m-10 border border-black"
        style={{ borderRadius: 20 }}
      >
        <img
          src={CobblerBlack}
          alt="Cobbler Logo"
          className="absolute w-auto h-7 left-8 bottom-8"
        />
        <Button
          style="link"
          className="absolute text-black underline left-8 top-8 hover:text-gray-800"
          label="Back to login"
          onClick={() => {
            history.push("/login");
          }}
        />
        <div className="flex flex-col items-center justify-center flex-grow w-5/12 h-full py-20 mx-auto">
          <h2 className="mb-10 text-3xl font-semibold text-center text-black">
            Reset Password
          </h2>

          <form
            className="flex flex-col items-center w-full signin-form__wrapper"
            onSubmit={() => {}}
          >
            <Input
              required
              type="text"
              value={formik.values.token}
              label="Auth Code"
              helpText="The code that was emailed to you. This is case-sensitive."
              id="token"
              onChange={formik.handleChange}
              error={
                Boolean(formik.touched.token && formik.errors.token) &&
                formik.errors.token
              }
              className="w-full mb-6"
              {...formik.getFieldProps("token")}
            />

            <Input
              required
              type="text"
              className="w-full mb-4"
              value={formik.values.email}
              label="Email"
              id="email"
              error={
                Boolean(formik.touched.email && formik.errors.email) &&
                formik.errors.email
              }
              {...formik.getFieldProps("email")}
            />

            <Input
              required
              type="Password"
              className="w-full mb-6"
              value={formik.values.password}
              label="Password"
              id="password"
              error={
                Boolean(formik.touched.password && formik.errors.password) &&
                formik.errors.password
              }
              {...formik.getFieldProps("password")}
            />

            <Input
              required
              type="Password"
              value={formik.values.passwordConfirmation}
              label="Confirm Password"
              id="passwordConfirmation"
              className="w-full mb-8"
              error={
                Boolean(
                  formik.touched.passwordConfirmation &&
                    formik.errors.passwordConfirmation
                ) && formik.errors.passwordConfirmation
              }
              {...formik.getFieldProps("passwordConfirmation")}
            />

            <div>
              <Button
                fullWidth
                type="submit"
                className="px-12 py-4 text-2xl font-medium rounded-full signin-btn"
                label="Reset Password"
                onClick={e => {
                  e.preventDefault();
                  formik.handleSubmit();
                }}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;

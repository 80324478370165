import axios from "axios";

export const createCurrency = (accountId, payload) => {
  return axios.post(
    `/api/v1/super_admins/accounts/${accountId}/currencies`,
    payload
  );
};

export const getCurrencies = accountId => {
  return axios.get(`/api/v1/super_admins/accounts/${accountId}/currencies`);
};

import React, { createContext, useRef } from "react";

export const EstimateContext = createContext(null);

export const EstimateContextProvider = ({ children }) => {
  const ref = useRef(null);
  return (
    <EstimateContext.Provider value={ref}>{children}</EstimateContext.Provider>
  );
};

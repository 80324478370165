import React from "react";
import { Tab } from "@bigbinary/neetoui";
import { Header, Container } from "@bigbinary/neetoui/layouts";
import { Route, Switch, useHistory } from "react-router-dom";
import { useUserState } from "contexts/user";
import { POST_PRODUCTION_TABS } from "./constants";
import FinalArtworkRequests from "./FinalArtworkRequests";
import FinishedArtFiled from "./FinishedArtFiled";
import Social from "./Social";
import Folio from "./Folio";
import Showpiece from "./Showpiece";
import ChallengeCoin from "./ChallengeCoin";
import LicenseTracker from "./LicenseTracker";
import ClaimRoyalty from "./ClaimRoyalty";

const PostProduction = () => {
  const { user } = useUserState();
  const history = useHistory();

  const activeTab = tab => {
    return (
      tab.toLowerCase() ===
      window.location.href
        .split("/")
        .pop()
        .replace("_", " ")
        .replace("_", " ")
        .replace("_", " ")
    );
  };

  return (
    <Container isHeaderFixed>
      <Header title="Post Production" />
      <Tab className="w-full px-6">
        {POST_PRODUCTION_TABS.filter(tab =>
          user.account_id === "f4413fd9-bcec-44f5-b000-a770b16af7ef"
            ? true
            : !["Claim Royalty", "Challenge Coin"].includes(tab)
        ).map((tab, index) => {
          return (
            <Tab.Item
              key={index}
              active={activeTab(tab)}
              className="px-4"
              onClick={() => {
                let urlTab = tab
                  .toLowerCase()
                  .replace(" ", "_")
                  .replace(" ", "_");
                history.push(`/post_production/${urlTab}`);
              }}
            >
              {tab}
            </Tab.Item>
          );
        })}
      </Tab>

      <Switch>
        <Route exact path={`/post_production/final_artwork_requests`}>
          <FinalArtworkRequests />
        </Route>
        <Route exact path={`/post_production/finished_art_filed`}>
          <FinishedArtFiled />
        </Route>
        <Route exact path={`/post_production/social`}>
          <Social />
        </Route>
        <Route exact path={`/post_production/folio`}>
          <Folio />
        </Route>
        <Route exact path={`/post_production/showpiece`}>
          <Showpiece />
        </Route>
        <Route exact path={`/post_production/challenge_coin`}>
          <ChallengeCoin />
        </Route>
        <Route exact path={`/post_production/claim_royalty`}>
          <ClaimRoyalty />
        </Route>
        <Route exact path={`/post_production/license_tracker`}>
          <LicenseTracker />
        </Route>
      </Switch>
    </Container>
  );
};

export default PostProduction;

import React, { useState } from "react";
import { Download, Graph } from "@bigbinary/neeto-icons";
import { Typography, Button, NoData, Spinner, Alert } from "@bigbinary/neetoui";
import { numberWithCommas } from "common/helper";
import { isEmpty } from "ramda";
import * as dayjs from "dayjs";
import { showToastrError } from "common";
import { exportClients } from "apis/reports/top_clients_by_amounts";
import Bar from "./BarGraph/Bar";
import BarLoader from "./BarGraph/BarLoader";

const ClientsByAmount = ({
  dataList,
  user,
  location,
  preferredPeriod,
  preferredLocation,
  dataLoader,
}) => {
  const [exportConfirmAlertState, setExportConfirmAlertState] = useState(false);
  const [exportLoad, setExportLoad] = useState(false);

  const exportOrganisations = async () => {
    try {
      setExportLoad(true);
      const { data } = await exportClients(
        location === "All" ? "" : location,
        preferredPeriod === "All Time"
          ? ""
          : dayjs().subtract(preferredPeriod, "month").format("YYYY-MM-DD")
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.download = `top-30-clients-by-invoices-in-${preferredLocation}-${dayjs().format(
        "DD-MM-YYYY"
      )}.csv`;
      link.click();
      setExportLoad(false);
      setExportConfirmAlertState(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  if (exportLoad) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full">
      <div className="flex items-center justify-between mb-3">
        <Typography style="h4" weight="semibold">
          Top Clients By Amount
        </Typography>

        <Button
          style="secondary"
          icon={Download}
          tooltipProps={{
            content: "Export",
            position: "auto",
          }}
          onClick={() => {
            setExportConfirmAlertState(true);
          }}
        />
      </div>
      <div className="space-y-2 overflow-y-auto border neeto-ui-border-300 neeto-ui-rounded-md h-72">
        {dataLoader && <BarLoader />}
        {!dataLoader && isEmpty(dataList) && (
          <div className="flex items-center justify-center flex-grow w-full h-full">
            <NoData
              title="No Reports"
              description="You don't have any reports yet."
              image={<Graph />}
            />
          </div>
        )}
        {!dataLoader &&
          !isEmpty(dataList) &&
          dataList.map(item => (
            <Bar
              color="neeto-ui-bg-pastel-green"
              key={item.id}
              label={item.name}
              count={
                (user.access === "admin" || user.access === "super_admin") &&
                numberWithCommas(Number(item.sum).toFixed(2))
              }
              value={Number(item.sum).toFixed(0)}
              maxValue={Math.max(
                ...dataList.map(item => Number(item.sum).toFixed(0))
              )}
            />
          ))}
      </div>

      <Alert
        isOpen={exportConfirmAlertState}
        title="Confirm Export"
        message="Are you sure you want to export the list of organisations?"
        onClose={() => setExportConfirmAlertState(false)}
        onSubmit={() => exportOrganisations()}
        submitButtonLabel="Yes, export"
        cancelButtonLabel="No, cancel"
      />
    </div>
  );
};

export default ClientsByAmount;

import React, { useState, useEffect } from "react";
import { Label } from "@bigbinary/neetoui";
import EmptyState from "components/Common/EmptyState";
import { getRandomNotFoundImage } from "common/helper";

const Details = ({ accountDetail }) => {
  const [emptyImage, setEmptyImage] = useState();

  useEffect(() => {
    setEmptyImage(getRandomNotFoundImage());
  }, [])

  if (!accountDetail?.id) {
    return (
      <div
        className="flex items-center justify-center p-2"
        style={{ height: "calc(100vh - 134px)" }}
      >
        <EmptyState
          image={emptyImage}
          title="Please click on any account to access the details."
        />
      </div>
    );
  }

  return (
    <div className="px-6 mt-6">
      <div className="flex flex-col">
        <div className="grid grid-cols-2 mb-6 gap-x-4 gap-y-6">
          <div className="flex flex-col space-y-1">
            <Label>Name</Label>
            <p className="text-sm font-semibold text-gray-800">
              {accountDetail.name ? accountDetail.name : "N/A"}
            </p>
          </div>

          <div className="flex flex-col space-y-1">
            <Label>Subdomain</Label>
            <p className="text-sm font-semibold text-gray-800">
              {accountDetail.subdomain ? accountDetail.subdomain : "N/A"}
            </p>
          </div>

          <div className="flex flex-col space-y-1">
            <Label>Test Account</Label>
            <p className="text-sm font-semibold text-gray-800">
              {accountDetail.accountType === "test" ? (
                <i className="ri-checkbox-circle-fill text-xl"></i>
              ) : (
                <i className="ri-close-circle-fill text-xl"></i>
              )}
            </p>
          </div>

          <div className="flex flex-col space-y-1">
            <Label>Suspended</Label>
            <p className="text-sm font-semibold text-gray-800">
              {accountDetail.plan === "suspended" ? (
                <i className="ri-checkbox-circle-fill text-xl"></i>
              ) : (
                <i className="ri-close-circle-fill text-xl"></i>
              )}
            </p>
          </div>

          <div className="flex flex-col space-y-1">
            <Label>Studio</Label>
            <p className="text-sm font-semibold text-gray-800">
              {accountDetail.studio ? (
                <i className="ri-checkbox-circle-fill text-xl"></i>
              ) : (
                <i className="ri-close-circle-fill text-xl"></i>
              )}
            </p>
          </div>

          <div className="flex flex-col space-y-1">
            <Label>Seats Count</Label>
            <p className="text-sm font-semibold text-gray-800">
              {accountDetail.seatsCount}
            </p>
          </div>

          <div className="flex flex-col space-y-1">
            <Label>Logo</Label>
            <div className="text-sm font-semibold text-gray-800">
              {accountDetail.attachments_attributes && (
                <img
                  src={`${
                    accountDetail.attachments_attributes.find(
                      attachment => attachment.attachable_kind === "app_logo"
                    )?.src
                  }`}
                  width="150"
                  height="150"
                />
              )}
            </div>
          </div>

          <div className="flex flex-col space-y-1">
            <Label className="font-semibold">Logo For Templates</Label>
            <div className="text-sm font-semibold text-gray-800">
              {accountDetail.attachments_attributes && (
                <img
                  src={`${
                    accountDetail.attachments_attributes.find(
                      attachment =>
                        attachment.attachable_kind === "large_app_logo"
                    )?.src
                  }`}
                  width="150"
                  height="150"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;

import React, { useState, useEffect } from "react";
import { MenuHorizontal } from "@bigbinary/neeto-icons";
import { Button, Table, Spinner, Dropdown, Alert } from "@bigbinary/neetoui";
import { Container, Header } from "@bigbinary/neetoui/layouts";
import { showToastrError } from "common";
import { getRandomNotFoundImage } from "common/helper";
import { isEmpty } from "ramda";
import { useFormik } from "formik";
import {
  getEmailBackgroundDetail,
  getEmailBackgroundDetails,
  createEmailBackgroundDetail,
  updateEmailBackgroundDetail,
  destroyEmailBackgroundDetail,
} from "apis/settings/email_background_details";
import EmptyState from "components/Common/EmptyState";
import {
  EMAIL_BACKGROUND_VALIDATION_SCHEMA,
  EMAIL_BACKGROUND_INITIAL_VALUE,
} from "./constants";
import EmailBackgroundPane from "./EmailBackgroundPane";

const EmailBackgroundDetails = ({ breadcrumbs }) => {
  const [emailBackgroundDetailId, setEmailBackgroundDetailId] = useState("");
  const [emailBackgroundDetail, setEmailBackgroundDetail] = useState();
  const [emailBackgroundDetailLoad, setEmailBackgroundDetailLoad] = useState(false);
  const [emailBackgroundDetailsList, setEmailBackgroundDetailList] = useState(
    []
  );
  const [
    emailBackgroundDetailsListLoad,
    setEmailBackgroundDetailsListLoad,
  ] = useState(true);
  const [emailBackgroundDetailName, setEmailBackgroundDetailName] = useState(
    ""
  );
  const [modalState, setModalState] = useState(false);
  const [deleteModalState, setDeleteModalState] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const { Menu, MenuItem } = Dropdown;
  const [buttonLoader, setButtonLoader] = useState(false);
  const [emptyImage, setEmptyImage] = useState()

  useEffect(() => {
    setEmptyImage(getRandomNotFoundImage());
  }, [])

  const loadEmailBackgroundDetailsList = async () => {
    try {
      const emailBackgroundDetailsResponse = await getEmailBackgroundDetails();
      setEmailBackgroundDetailList(
        emailBackgroundDetailsResponse.data.emailBackgroundDetails || []
      );
      setEmailBackgroundDetailsListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: emailBackgroundDetail
      ? {
          ...emailBackgroundDetail,
          artistUrl: emailBackgroundDetail.artistUrl?.split("https://")[1],
          welcomeEmailVideoUrl: emailBackgroundDetail.welcomeEmailVideoUrl?.split("https://")[1],
        }
      : EMAIL_BACKGROUND_INITIAL_VALUE,
    validationSchema: EMAIL_BACKGROUND_VALIDATION_SCHEMA,
    onSubmit: () => {
      if (emailBackgroundDetailId) {
        updateEmailBackgroundDetailEntry();
      } else {
        createEmailBackgroundDetailEntry();
      }
    },
  });

  useEffect(() => {
    loadEmailBackgroundDetailsList();
  }, []);

  const resetAllStates = () => {
    loadEmailBackgroundDetailsList();
    setEmailBackgroundDetailId("");
    setEmailBackgroundDetail();
    setModalState(false);
    setDeleteModalState(false);
  };

  const createEmailBackgroundDetailEntry = async () => {
    try {
      setButtonLoader(true);
      await createEmailBackgroundDetail({
        email_background_detail: {
          ...formik.values,
          welcomeEmailVideoUrl:
            formik.values.welcomeEmailVideoUrl?.length > 0
              ? `https://${formik.values.welcomeEmailVideoUrl}`
              : null,
          artistUrl:
            formik.values.artistUrl?.length > 0
              ? `https://${formik.values.artistUrl}`
              : null,
        },
      });
      resetAllStates();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      setButtonLoader(false);
    }
  };

  const updateEmailBackgroundDetailEntry = async () => {
    try {
      setButtonLoader(true);
      await updateEmailBackgroundDetail(emailBackgroundDetailId, {
        email_background_detail: {
          ...formik.values,
          welcomeEmailVideoUrl:
            formik.values.welcomeEmailVideoUrl?.length > 0
              ? `https://${formik.values.welcomeEmailVideoUrl}`
              : null,
          artistUrl:
            formik.values.artistUrl?.length > 0
              ? `https://${formik.values.artistUrl}`
              : null,
        },
      });
      resetAllStates();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      setButtonLoader(false);
    }
  };

  const destroyEmailBackgroundDetailEntry = async () => {
    try {
      await destroyEmailBackgroundDetail(emailBackgroundDetailId);
      resetAllStates();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      setButtonLoader(false);
    }
  };

  const getEmailBackgroundDetailEntry = async emailBackgroundDetailId => {
    try {
      setEmailBackgroundDetailLoad(true);
      const { data } = await getEmailBackgroundDetail(emailBackgroundDetailId);
      setEmailBackgroundDetail(data.emailBackgroundDetail);
      setEmailBackgroundDetailLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const COLUMN_DATA = [
    {
      title: "Page Name",
      dataIndex: "pageName",
      key: "pageName",
      width: 220,
      render: pageName => <>{pageName ? pageName : "-"}</>,
    },
    {
      title: "Fallback Header Image",
      dataIndex: "headerImage",
      key: "headerImage",
      width: 280,
      render: (_, rowData) => {
        let headerImage = rowData.attachments.find(
          attachment => attachment.attachable_kind === "header"
        );
        return (
          <Button
            style="link"
            href={headerImage?.src}
            label={headerImage?.filename ? headerImage?.filename : "-"}
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 75,
      render: (_, rowData) => (
        <Dropdown
          autoWidth
          closeOnSelect
          buttonStyle="text"
          strategy="fixed"
          icon={MenuHorizontal}
        >
          <Menu>
            <MenuItem.Button
              onClick={() => {
                getEmailBackgroundDetailEntry(rowData.id);
                setEmailBackgroundDetailId(rowData.id);
                setModalState(true);
              }}
            >
              Edit
            </MenuItem.Button>
            <MenuItem.Button
              style="danger"
              onClick={() => {
                setEmailBackgroundDetailId(rowData.id);
                setEmailBackgroundDetailName(rowData.pageName);
                setDeleteModalState(true);
                setButtonLoader(true);
              }}
            >
              Delete
            </MenuItem.Button>
          </Menu>
        </Dropdown>
      ),
    },
  ];

  return (
    <Container>
      <Header
        title="Email / Paperwork Details"
        breadcrumbs={breadcrumbs}
        actionBlock={
          <Button
            label="Add Email Background Details"
            onClick={() => {
              setEmailBackgroundDetail();
              setEmailBackgroundDetailId();
              formik.resetForm();
              setButtonLoader(false);
              setModalState(true);
            }}
          />
        }
      />
      <div className="w-full" style={{ height: "calc(100vh - 128px)" }}>
        {emailBackgroundDetailsListLoad ? (
          <div className="flex items-center justify-center w-full h-full">
            <Spinner />
          </div>
        ) : (
          <>
            {!isEmpty(emailBackgroundDetailsList) ? (
              <Table
                fixedHeight
                loading={emailBackgroundDetailsListLoad}
                columnData={COLUMN_DATA}
                rowData={emailBackgroundDetailsList}
                currentPageNumber={pageIndex}
                defaultPageSize={pageSize}
                handlePageChange={(page, pageSize) => {
                  setPageIndex(page);
                  setPageSize(pageSize);
                }}
              />
            ) : (
              <EmptyState
                image={emptyImage}
                title="No Company Details Found"
                description="We couldn’t find any company details. Try creating one."
                primaryButtonProps={{
                  label: "Add Email Background Details",
                  onClick: () => {
                    setEmailBackgroundDetail();
                    setEmailBackgroundDetailId();
                    formik.resetForm();
                    setModalState(true);
                  },
                }}
              />
            )}
          </>
        )}
      </div>
      <EmailBackgroundPane
        isOpen={modalState}
        onClose={() => setModalState(false)}
        title={
          emailBackgroundDetailId ? "Edit Email Details" : "Add Email Details"
        }
        formik={formik}
        onSubmit={() => {
          formik.handleSubmit()
        }}
        buttonLoader={buttonLoader}
        setButtonLoader={setButtonLoader}
        emailBackgroundDetailLoad={emailBackgroundDetailLoad}
      />
      <Alert
        isOpen={deleteModalState}
        title="Delete Email Background Detail"
        message={
          <>
            Are you sure you want to delete the email background details for{" "}
            <strong>{emailBackgroundDetailName}?</strong>
          </>
        }
        submitButtonLabel="Yes, delete"
        cancelButtonLabel="No, cancel"
        onClose={() => setDeleteModalState(false)}
        onSubmit={() => {
          setButtonLoader(true);
          destroyEmailBackgroundDetailEntry()
        }}
      />
    </Container>
  );
};

export default EmailBackgroundDetails;

import * as Yup from "yup";
import { MAX_STRING_LENGTH } from "common/constants";

export const VALIDATION_SCHEMA = Yup.object({
  name: Yup.string()
    .required("Name can't be empty.")
    .max(
      MAX_STRING_LENGTH,
      `Name must be at most ${MAX_STRING_LENGTH} characters.`
    ),
  subdomain: Yup.string()
    .required("Subdomain can't be empty.")
    .max(
      MAX_STRING_LENGTH,
      `Subdomain must be at most ${MAX_STRING_LENGTH} characters.`
    ),
  seatsCount: Yup.number()
    .positive()
    .required("Seats count can't be empty.")
    .min(1),
});

export const INITIAL_VALUE = {
  name: "",
  basecampAccountName: "",
  subdomain: "",
  accountType: "test",
  plan: "active",
  seatsCount: 5,
};

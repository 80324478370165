import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Footer } from "../../Shared";

const TotalCost = ({
  revision,
  ammountWithCurrency,
  applicableTax,
  taxAmount,
  footerLogo,
  companyDetails,
  illustratorAuthorLink,
  illustratorAuthorName,
  jobDetail,
  footerDetails,
  totalCostDetails,
}) => {
  useEffect(() => {
    AOS.init({
      once: false,
    });
  }, []);

  let samt = 0;
  window.addEventListener("scroll", function () {
    samt <= 10 ? samt++ : AOS.refresh();
  });

  const setResourceForDetails = name => {
    switch (name) {
      case "[staff_full_name]":
        return jobDetail.staffFullName;
      case "[staff_first_name]":
        return jobDetail.staffFirstName;
      case "[staff_phone]":
        return jobDetail.staffPhone;
      case "[staff_designation]":
        return jobDetail.staffTitle;
    }
  };

  const footer = () => {
    const detailComponents = footerDetails?.split("&lt;&gt;");
    let details = "";

    detailComponents?.map(component => {
      if (component.includes("[")) {
        details += setResourceForDetails(component);
      } else {
        details += component;
      }
    });

    return details;
  };

  return (
    <div className="flex flex-col items-start justify-between w-full pt-12 pb-24 lg:flex-row">
      <div
        className="flex flex-col items-start justify-start w-full pr-0 lg:w-1/3 lg:h-full lg:pr-12"
        style={{ fontFamily: "Modern Gothic" }}
      >
        <div className="flex flex-col items-start justify-between h-full mb-8 space-y-20 lg:mb-0">
          <div className="flex flex-col items-start justify-start">
            <h2 className="text-xl font-medium">Total Cost</h2>
            <p
              className="mt-6 text-base font-normal leading-5"
              dangerouslySetInnerHTML={{
                __html: totalCostDetails?.split("\n").join("<br />"),
              }}
            ></p>
          </div>
          <div className="flex-col items-start justify-end hidden space-y-6 lg:flex">
            <Footer
              jobDetail={jobDetail}
              companyDetails={companyDetails}
              footerLogo={footerLogo}
              illustratorAuthorName={illustratorAuthorName}
              illustratorAuthorLink={illustratorAuthorLink}
            />
          </div>
        </div>
      </div>
      <div
        className="flex flex-col items-center justify-between w-full h-full lg:items-start lg:w-2/3"
        style={{ fontFamily: "Modern Gothic" }}
      >
        <div
          className="flex flex-col items-start justify-start w-full mb-12 space-y-2"
          style={{ fontFamily: "Modern Gothic" }}
          data-aos="fade-down"
          data-aos-easing="ease-in-out"
        >
          <div
            className="flex flex-col items-center justify-between w-full px-6 py-4 space-y-2 font-medium text-black lg:space-y-0 lg:flex-row rounded_container"
            style={{ backgroundColor: "#FFEFFF" }}
          >
            <span className="text-base lg:text-xl">Total Creative Fees</span>
            <span className="text-xl">
              {ammountWithCurrency(revision.artistArtworkFee)}
            </span>
          </div>

          {revision?.usageEnabled && (
            <div
              className="flex flex-col items-center justify-between w-full px-6 py-4 space-y-2 font-medium text-black lg:space-y-0 lg:flex-row rounded_container"
              style={{ backgroundColor: "#D6EEFF" }}
            >
              <span className="text-base lg:text-xl">Usage</span>
              <span className="text-xl">
                {ammountWithCurrency(revision.artistUsageFee || 0)}
              </span>
            </div>
          )}

          <div
            className="flex flex-col items-center justify-between w-full px-6 py-4 space-y-2 font-medium text-black lg:space-y-0 lg:flex-row rounded_container"
            style={{ backgroundColor: "#D6EEFF" }}
          >
            <span className="text-base lg:text-xl">Subtotal </span>
            <span className="text-xl">
              {ammountWithCurrency(
                Number(revision.artistArtworkFee || 0) +
                  Number(revision.artistUsageFee || 0)
              )}
            </span>
          </div>

          {applicableTax() && (
            <div
              className="flex flex-col items-center justify-between w-full px-6 py-4 space-y-2 font-medium text-black lg:space-y-0 lg:flex-row rounded_container"
              style={{ backgroundColor: "#D1D3D4" }}
            >
              <span className="text-base lg:text-xl">{applicableTax()}</span>
              <span className="text-xl">
                {ammountWithCurrency(taxAmount())}
              </span>
            </div>
          )}

          <div className="flex flex-col items-center justify-between w-full px-6 py-4 mt-2 space-y-2 font-medium text-white bg-black lg:space-y-0 lg:flex-row rounded_container">
            <span className="text-base lg:text-xl">Total</span>
            <span className="text-xl">
              {ammountWithCurrency(
                Number(revision.artistArtworkFee) +
                  Number(revision.artistUsageFee) +
                  taxAmount()
              )}
            </span>
          </div>
        </div>
        <div className="flex flex-col items-center lg:items-start">
          <p
            dangerouslySetInnerHTML={{
              __html: footer()?.split("\n").join("<br />"),
            }}
          ></p>
          <div className="w-auto h-20 mt-8">
            <img src={jobDetail.staffSignature} className="w-auto h-full" />
          </div>
        </div>
        <div className="flex flex-col items-center justify-end w-full pt-12 lg:hidden">
          <Footer
            jobDetail={jobDetail}
            companyDetails={companyDetails}
            footerLogo={footerLogo}
            illustratorAuthorName={illustratorAuthorName}
            illustratorAuthorLink={illustratorAuthorLink}
          />
        </div>
      </div>
    </div>
  );
};

export default TotalCost;

import React, { useState, useEffect } from "react";
import { Input } from "@bigbinary/neetoui";

const EstimateRevisionName = ({
  revision,
  updateEstimateRevisionNameEntry,
}) => {
  const [revisionName, setRevisionName] = useState();

  useEffect(() => {
    setRevisionName(revision.name);
  }, [revision]);

  return (
    <Input
      label="Name"
      placeholder="Revision Name"
      name="name"
      className="mb-4 w-full"
      value={revisionName}
      disabled={
        revision.staffApproved ||
        revision.management1Approved ||
        revision.management2Approved
      }
      onChange={e => setRevisionName(e.target.value)}
      onBlur={e => updateEstimateRevisionNameEntry(revision.id, e.target.value)}
    />
  );
};

export default EstimateRevisionName;

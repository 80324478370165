import axios from "axios";

const getJobTypesUrl = id => {
  if (id) {
    return `/api/v1/settings/job_types/${id}`;
  }

  return `/api/v1/settings/job_types`;
};

export const getJobTypes = (searchParams = "") => {
  let query = "";
  if (searchParams.length > 0) query = `q[name_i_cont_any]=${searchParams}`;
  return axios.get(`${getJobTypesUrl()}?${query}`);
};

export const createJobType = payload => {
  return axios.post(getJobTypesUrl(), payload);
};

export const updateJobType = (id, payload) => {
  return axios.put(getJobTypesUrl(id), payload);
};

export const destroyJobType = id => {
  return axios.delete(getJobTypesUrl(id));
};

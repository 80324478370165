import React, { useEffect, useState } from "react";
import { Send, Draft, Help } from "@bigbinary/neeto-icons";
import ReactQuill from "react-quill";
import * as R from "ramda";
import {
  Input,
  Label,
  Button,
  Select,
  Spinner,
  Alert,
  Checkbox,
} from "@bigbinary/neetoui";
import { getChatTemplates } from "apis/settings/chat_templates";
import { dropDownListGenerator } from "common/helper";
import { showToastrError } from "common";
import { getChatTemplate } from "apis/jobs/messages";
import { QUILL_TOOLBAR_STYLE, QUILL_KEYBOARD_BINDINGS } from "common/constants";
import MessageModal from "./MessageModal";
import Footer from "./footer";
import AddAttachments from "./AddAttachments";
import Attachment from "./Attachment";

const NewMessage = ({
  formik,
  setNewMessage,
  jobDetail,
  buttonLoader,
  draftButtonLoader = false,
}) => {
  const [chatTemplateList, setChatTemplateList] = useState([]);
  const [chatTemplateListLoad, setChatTemplateListLoad] = useState(true);
  const [recipients, setRecipients] = useState();
  const [editorModalOpen, setEditorModalOpen] = useState(false);
  const [addAttachmentModal, setAddAttachmentModal] = useState(false);
  const [deleteConfirmAlert, setDeleteConfirmAlert] = useState(false);
  const [deletingAttachmentIndex, setDeletingAttachmentIndex] = useState();

  useEffect(() => {
    loadchatTemplatesList();
  }, []);

  useEffect(() => {
    setRecipients(
      [
        getNameFromEmails(formik.values.to),
        getNameFromEmails(formik.values.cc),
      ].flat()
    );
  }, [formik.values.to, formik.values.cc, formik.values.bcc]);

  const getNameFromEmails = array => {
    return array.map(item => item.name.split(" ")[0]);
  };

  const loadChatMessageBody = async chatTemplateId => {
    try {
      const response = await getChatTemplate(jobDetail.id, chatTemplateId);
      formik.setFieldValue("htmlBody", response.data.message_body);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const loadchatTemplatesList = async () => {
    try {
      const response = await getChatTemplates("");
      setChatTemplateList(dropDownListGenerator(response.data.chatTemplates));
      setChatTemplateListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const setAttachmentRecord = attachments => {
    const allAttachments = [
      ...formik.values.attachments_attributes,
      ...attachments,
    ];
    formik.setFieldValue("attachments_attributes", allAttachments);
  };

  const createDraftMessage = async () => {
    await formik.setFieldValue("draft", true);
    await formik.handleSubmit();
  };

  const createMessage = async () => {
    await formik.setFieldValue("draft", false);
    await formik.handleSubmit();
  };

  const setEditorValue = value => {
    formik.setFieldValue("htmlBody", value);
  };

  const destroyAttachment = () => {
    let finalAttachments = formik.values.attachments_attributes.filter(
      (attachment, index) => {
        if (index === deletingAttachmentIndex) {
          if (attachment.id) {
            attachment._destroy = 1;
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    );

    formik.setFieldValue("attachments_attributes", finalAttachments);
    setDeletingAttachmentIndex();
    setDeleteConfirmAlert(false);
  };

  if (chatTemplateListLoad) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex w-full">
      <div className="flex flex-col w-1/2 p-6 overflow-y-auto">
        <div className="flex flex-col w-full space-y-4">
          <Select
            label="Templates"
            name="template"
            id="template"
            isClearable={true}
            placeholder="Select a message template"
            value={chatTemplateList.find(
              template => template.value === formik.values.template?.id
            )}
            options={chatTemplateList}
            onChange={async opt => {
              if (opt) {
                await formik.setFieldValue("template", opt);
                await loadChatMessageBody(opt.value);
              } else {
                await formik.setFieldValue("template", null);
                await formik.setFieldValue("htmlBody", "");
              }
            }}
          />

          <Input
            label="From"
            name="from"
            value={jobDetail?.senderName}
            disabled={true}
            required
          />

          <Input
            label="Subject"
            name="subject"
            placeholder="Add subject"
            value={formik.values.subject}
            onChange={e => formik.setFieldValue("subject", e.target.value)}
            error={formik.touched.subject && formik.errors.subject}
            required
          />

          <div className="flex justify-between">
            <Label
              className="w-2/3"
              required>
                To
            </Label>

            <Checkbox
              className="w-1/3 flex items-end"
              disabled={R.isNil(jobDetail.account.productionEmail) || R.isEmpty(jobDetail.account.productionEmail)}
              label={
                <Label
                  helpIconProps={{
                    icon: Help,
                    tooltipProps: {
                      content: "The Production Email from Account settings will be copied if checked!",
                      position: "right",
                    }
                  }}>
                  Production Email
                </Label>
              }
              checked={formik.values.productionEmail}
              onClick={() => formik.setFieldValue("productionEmail", !formik.values.productionEmail)}
            />
          </div>
          <Select
            options={jobDetail.allRecipients}
            value={formik.values.to}
            onChange={opt => formik.setFieldValue("to", opt)}
            required={true}
            placeholder="Select a recipient"
            error={
              Boolean(formik.touched.to && formik.errors.to) && formik.errors.to
            }
            isMulti
          />

          <Select
            options={jobDetail.allRecipients}
            value={formik.values.cc}
            onChange={opt => formik.setFieldValue("cc", opt)}
            placeholder="Select a recipient"
            label="CC"
            error={
              Boolean(formik.touched.cc && formik.errors.cc) && formik.errors.cc
            }
            isMulti
          />

          <Select
            options={jobDetail.allRecipients}
            value={formik.values.bcc}
            onChange={opt => formik.setFieldValue("bcc", opt)}
            placeholder="Select a recipient"
            label="BCC"
            error={
              Boolean(formik.touched.bcc && formik.errors.bcc) &&
              formik.errors.bcc
            }
            isMulti
          />

          <Input
            label="File management link"
            name="fileManagementLink"
            placeholder="Add file management link"
            value={formik.values.fileManagementLink}
            onChange={e =>
              formik.setFieldValue("fileManagementLink", e.target.value)
            }
            error={
              formik.touched.fileManagementLink &&
              formik.errors.fileManagementLink
            }
          />

          <div className="flex flex-col space-y-2 mb-2 large-ql-editor">
            <div className="flex justify-between">
              <Label>Body</Label>
              <div>
                <Button
                  style="link"
                  label="Add Attachments"
                  onClick={() => setAddAttachmentModal(true)}
                  className="ml-2 underline"
                />
                <Button
                  style="link"
                  label="Expand"
                  onClick={() => setEditorModalOpen(true)}
                  className="ml-2 underline"
                />
              </div>
            </div>

            {!editorModalOpen && (
              <>
                <ReactQuill
                  value={formik.values.htmlBody}
                  onChange={text => formik.setFieldValue("htmlBody", text)}
                  modules={{
                    toolbar: QUILL_TOOLBAR_STYLE,
                    keyboard: QUILL_KEYBOARD_BINDINGS
                  }}
                  placeholder="Enter your message"
                />
                {formik.touched.htmlBody && formik.errors.htmlBody?.length > 0 && (
                  <p className="text-red-700 text-xs">{formik.errors.htmlBody}</p>
                )}
              </>
            )}
          </div>

          <div className="flex flex-col w-full">
            {formik.values.attachments_attributes?.map((attachment, index) => {
              if (attachment._destroy === 1) return null;

              return (
                <Attachment
                  key={index}
                  index={index}
                  attachment={attachment}
                  setDeleteConfirmAlert={setDeleteConfirmAlert}
                  setDeletingAttachmentIndex={setDeletingAttachmentIndex}
                />
              );
            })}
          </div>

          <div className="flex flex-row items-center justify-end w-full space-x-2">
            <Button
              label="Cancel"
              onClick={() => setNewMessage(false)}
              style="text"
            />

            <Button
              label="Draft"
              icon={Draft}
              iconPosition="left"
              onClick={() => createDraftMessage()}
              loading={draftButtonLoader}
            />

            <Button
              label="Send Message"
              icon={Send}
              iconPosition="left"
              onClick={() => createMessage()}
              loading={buttonLoader}
            />
          </div>
        </div>
      </div>
      <div
        className="w-1/2 p-4 overflow-y-auto"
        style={{
          background: "linear-gradient(180deg, #4558F9 0%, #9747FF 100%)",
        }}
      >
        <div className="p-4 font-sans text-base bg-white chat-email-body">
          <div
            className="w-full py-12"
            style={{ minHeight: "calc(100vh - 204px) !important" }}
          >
            <div
              className="text-base ql-editor ql-editor-preview"
              dangerouslySetInnerHTML={{ __html: formik.values.htmlBody }}
            ></div>
          </div>
          <Footer
            recipients={recipients}
            attachmentsCount={formik.values.attachments_attributes.length}
          />
        </div>
      </div>

      <MessageModal
        value={formik.values.htmlBody}
        setValue={setEditorValue}
        editorModalOpen={editorModalOpen}
        setEditorModalOpen={setEditorModalOpen}
      />

      <AddAttachments
        setAttachmentRecord={setAttachmentRecord}
        addAttachmentModal={addAttachmentModal}
        setAddAttachmentModal={setAddAttachmentModal}
      />

      <Alert
        isOpen={deleteConfirmAlert}
        message="Are you sure you want to delete the attachment?"
        submitButtonLabel="Yes!"
        title="Delete Attachment"
        onClose={() => setDeleteConfirmAlert(false)}
        onSubmit={() => destroyAttachment()}
      />
    </div>
  );
};

export default NewMessage;

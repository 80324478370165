import React from "react";
import { Modal, Typography, Spinner } from "@bigbinary/neetoui";
import TagList from "./TagList";
import ContactList from "./ContactList";

const TagListModal = ({
  list,
  listType,
  dataLoaded,
  setTagListModal,
  tagListModal,
}) => {
  return (
    <Modal
      size="large"
      isOpen={tagListModal}
      onClose={() => setTagListModal(false)}
      style={{ width: "950px" }}
    >
      <Modal.Header>
        <Typography style="h2" weight="semibold">
          {listType === "closed"
            ? "Closed Tag List"
            : listType === "opened"
            ? "Added Leads" : "" }
        </Typography>
      </Modal.Header>
      <Modal.Body className="w-full mb-6">
        {dataLoaded ? (
          <div
            className="flex items-center justify-center"
            style={{ height: "calc(60vh)" }}
          >
            <Spinner />
          </div>
        ) :
        (
          listType === "closed" ?
          <TagList
            list={list}
          /> :
          <ContactList list={list} />
        )
      }
      </Modal.Body>
    </Modal>
  );
};

export default TagListModal;

import React from "react";
import classnames from "classnames";
import { Label } from "@bigbinary/neetoui";

const Card = ({ title, children, className = "", titleAction = null }) => {
  return (
    <div className={classnames(["bg-white p-3 rounded-md"], className)}>
      <div className="flex justify-between">
        <Label className="text-base font-semibold text-black">{title}</Label>
        {titleAction}
      </div>
      {children}
    </div>
  );
};

export default Card;

import React, { useState, useEffect } from "react";
import { Dropdown } from "@bigbinary/neetoui";
import { Container, Header } from "@bigbinary/neetoui/layouts";
import * as dayjs from "dayjs";
import { useUserState } from "contexts/user";
import { showToastrError } from "common";
import { getClientsByAmounts } from "apis/reports/top_clients_by_amounts";
import { getClientsByJobs } from "apis/reports/top_clients_by_jobs";
import { getContactsByAmounts } from "apis/reports/top_contacts_by_amounts";
import { getContactsByJobs } from "apis/reports/top_contacts_by_jobs";
import LocationDropdown from "../LocationDropdown";
import ClientsByAmount from "./ClientsByAmount";
import ClientsByJob from "./ClientsByJob";
import ContactsByAmount from "./ContactsByAmount";
import ContactsByJob from "./ContactsByJob";

const Reports = () => {
  const { user } = useUserState();
  const [location, setLocation] = useState(user.location_id);
  const [clientsByAmount, setClientsByAmount] = useState([]);
  const [clientsByAmountLoad, setClientsByAmountLoad] = useState(true);
  const [clientsByJob, setClientsByJob] = useState([]);
  const [clientsByJobLoad, setClientsByJobLoad] = useState(true);
  const [contactsByAmount, setContactsByAmount] = useState([]);
  const [contactsByAmountLoad, setContactsByAmountLoad] = useState(true);
  const [contactsByJob, setContactsByJob] = useState([]);
  const [contactsByJobLoad, setContactsByJobLoad] = useState(true);
  const [preferredLocation, setPreferredLocation] = useState();
  const [preferredPeriod, setPreferredPeriod] = useState("All Time");
  const { Menu, MenuItem } = Dropdown;

  useEffect(() => {
    if (preferredPeriod) {
      loadClientsByAmounts();
      loadClientsByJobs();
      loadContactsByAmounts();
      loadContactsByJobs();
    }
  }, [preferredPeriod]);

  useEffect(() => {
    if (location) {
      loadClientsByAmounts();
      loadClientsByJobs();
      loadContactsByJobs();
      loadContactsByAmounts();
    }
  }, [location]);

  const loadClientsByAmounts = async () => {
    setClientsByAmountLoad(true);
    try {
      const { data } = await getClientsByAmounts(
        location === "All" ? "" : location,
        preferredPeriod === "All Time"
          ? ""
          : dayjs().subtract(preferredPeriod, "month").format("YYYY-MM-DD")
      );
      setClientsByAmount(data.organisations);
      setClientsByAmountLoad(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const loadClientsByJobs = async () => {
    setClientsByJobLoad(true);
    try {
      const { data } = await getClientsByJobs(
        location === "All" ? "" : location,
        preferredPeriod === "All Time"
          ? ""
          : dayjs().subtract(preferredPeriod, "month").format("YYYY-MM-DD")
      );
      setClientsByJob(data.organisations);
      setClientsByJobLoad(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const loadContactsByAmounts = async () => {
    setContactsByAmountLoad(true);
    try {
      const { data } = await getContactsByAmounts(
        location === "All" ? "" : location,
        preferredPeriod === "All Time"
          ? ""
          : dayjs().subtract(preferredPeriod, "month").format("YYYY-MM-DD")
      );
      setContactsByAmount(data.contacts);
      setContactsByAmountLoad(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const loadContactsByJobs = async () => {
    setContactsByJobLoad(true);
    try {
      const { data } = await getContactsByJobs(
        location === "All" ? "" : location,
        preferredPeriod === "All Time"
          ? ""
          : dayjs().subtract(preferredPeriod, "month").format("YYYY-MM-DD")
      );
      setContactsByJob(data.contacts);
      setContactsByJobLoad(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  return (
    <Container isHeaderFixed>
      <Header
        title={"Reports"}
        actionBlock={
          <div className="flex items-center space-x-3">
            <Dropdown
              label={
                preferredPeriod === "All Time"
                  ? "All Time"
                  : `Last ${preferredPeriod} months`
              }
              buttonStyle="text"
              position="bottom-end"
              closeOnSelect
            >
              <Menu>
                <MenuItem.Button onClick={() => setPreferredPeriod(4)}>
                  Last 4 months
                </MenuItem.Button>
                <MenuItem.Button onClick={() => setPreferredPeriod(6)}>
                  Last 6 months
                </MenuItem.Button>
                <MenuItem.Button onClick={() => setPreferredPeriod(12)}>
                  Last 12 months
                </MenuItem.Button>
                <MenuItem.Button onClick={() => setPreferredPeriod(24)}>
                  Last 24 months
                </MenuItem.Button>
                <MenuItem.Button onClick={() => setPreferredPeriod(36)}>
                  Last 36 months
                </MenuItem.Button>
                <MenuItem.Button onClick={() => setPreferredPeriod("All Time")}>
                  All Time
                </MenuItem.Button>
              </Menu>
            </Dropdown>
            <LocationDropdown
              location={location}
              setLocation={setLocation}
              setLocationName={setPreferredLocation}
            />
          </div>
        }
      />

      <div
        className="flex flex-col w-full overflow-auto p-6"
        style={{ height: "calc(100vh - 80px - 24px)" }}
      >
        <div className="grid grid-cols-2 gap-8 ">
          <ClientsByAmount
            user={user}
            location={location}
            dataList={clientsByAmount}
            dataLoader={clientsByAmountLoad}
            preferredPeriod={preferredPeriod}
            preferredLocation={preferredLocation}
          />
          <ClientsByJob
            user={user}
            location={location}
            dataList={clientsByJob}
            dataLoader={clientsByJobLoad}
            preferredPeriod={preferredPeriod}
            preferredLocation={preferredLocation}
          />
          <ContactsByAmount
            user={user}
            location={location}
            dataList={contactsByAmount}
            dataLoader={contactsByAmountLoad}
            preferredPeriod={preferredPeriod}
            preferredLocation={preferredLocation}
          />
          <ContactsByJob
            user={user}
            location={location}
            dataList={contactsByJob}
            dataLoader={contactsByJobLoad}
            preferredPeriod={preferredPeriod}
            preferredLocation={preferredLocation}
          />
        </div>
      </div>
    </Container>
  );
};

export default Reports;

import React from "react";
import { Modal, Typography, Textarea, Button } from "@bigbinary/neetoui";
import { createContactNote } from "apis/contacts/contact_notes";
import { showToastrError } from "common";
import { useFormik } from "formik";
import { NOTE_VALIDATION_SCHEMA, INITIAL_NOTE_VALUE } from "./constants";

const NoteModal = ({
  noteModalState,
  setNoteModalState,
  contactDetail,
  loadContactListResponse,
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_NOTE_VALUE,
    validationSchema: NOTE_VALIDATION_SCHEMA,
    onSubmit: () => {
      onSubmit();
    },
  });

  const onSubmit = async () => {
    try {
      await createContactNote(contactDetail.id, {
        contact_note: { ...formik.values },
      });
      setNoteModalState(false);
      loadContactListResponse();
      formik.resetForm();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  return (
    <Modal isOpen={noteModalState} onClose={() => setNoteModalState(false)}>
      <Modal.Header>
        <Typography style="h2" weight="semibold">
          Add Note
        </Typography>
      </Modal.Header>
      <Modal.Body>
        <Textarea
          label="Notes"
          name="notes"
          value={formik.values.note}
          onChange={formik.handleChange}
          error={
            Boolean(formik.touched.note && formik.errors.note) &&
            formik.errors.note
          }
          row="4"
          {...formik.getFieldProps("note")}
        />
      </Modal.Body>
      <Modal.Footer className="space-x-3">
        <Button label="Save changes" onClick={() => formik.handleSubmit()} />
        <Button
          style="text"
          label="Cancel"
          onClick={() => setNoteModalState(false)}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default NoteModal;

import * as Yup from "yup";
import { User, Suitcase, Invoice, ContactBook } from "@bigbinary/neeto-icons";

export const CONTACT_VALIDATION_SCHEMA = Yup.object({
  firstName: Yup.string().required("First name can't be empty"),
  lastName: Yup.string().required("Last name can't be empty"),
  email: Yup.string().email("Invalid email").required("Email can't be empty"),
});

export const INITIAL_CONTACT_VALUE = {
  firstName: "",
  lastName: "",
  email: "",
  pronouns: "",
  title: "",
  phoneNumber: "",
  accountPayable: false,
};

export const ORGINIASTION_DETAILS_TABS = {
  Profile: {
    icon: User,
    label: "Profile",
  },
  Jobs: {
    icon: Suitcase,
    label: "Jobs",
  },
  Invoices: {
    icon: Invoice,
    label: "Invoices",
  },
  Contacts: {
    icon: ContactBook,
    label: "Contacts",
  },
};

import React from "react";
import { Pane, Button, Typography } from "@bigbinary/neetoui";
import EntryForm from "./EntryForm";

const FormPane = ({
  isOpen,
  onClose,
  title,
  formik,
  onSubmit,
  buttonLoader,
}) => {
  return (
    <Pane isOpen={isOpen} onClose={() => onClose()} size="large">
      <Pane.Header>
        <Typography style="h2" weight="semibold">
          {title}
        </Typography>
      </Pane.Header>
      <Pane.Body>
        <EntryForm formik={formik} />
      </Pane.Body>
      <Pane.Footer className="flex space-x-2">
        <Button
          label="Save changes"
          onClick={() => onSubmit()}
          loading={buttonLoader}
        />
        <Button label="Cancel" style="text" onClick={() => onClose()} />
      </Pane.Footer>
    </Pane>
  );
};

export default FormPane;

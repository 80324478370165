import React, { useState, useEffect, createContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Settings, Warning, CloseCircle } from "@bigbinary/neeto-icons";
import {
  Button,
  Spinner,
  Callout,
  Modal,
  Input,
  Typography,
} from "@bigbinary/neetoui";
import { Header, Container } from "@bigbinary/neetoui/layouts";
import { useFormik } from "formik";
import { showToastrError } from "common";
import { getJob } from "apis/jobs/overview";
import { getRcis } from "apis/jobs/estimate";
import { getPhaseTypes } from "apis/settings/phase_types";
import {
  getEstimateRevisions,
  getEstimateRevision,
  createEstimateRevision,
} from "apis/jobs/estimate_revisions";
import { getEstimateRevisionItems } from "apis/jobs/estimate_revision_items";
import { getInvoices } from "apis/jobs/invoices";
import { getFinalInvoice, getCommencementInvoice } from "../../common/helper";
import { jobWithNameNumber, filterUsageItems } from "./../../common/helper";
import JobOverview from "./JobOverview";
import EstimateRevisions from "../../common/EstimateRevisions";
import EstimateSettingPane from "../../common/EstimateSettingPane";
import { INITIAL_VALUE, VALIDATION_SCHEMA } from "./constants";
import Tabs from "../Tabs";
import HeaderImage from "./HeaderImage";

export const OverviewDetailsContext = createContext();

const Overview = () => {
  const { id, revisionId } = useParams();
  const history = useHistory();
  const [jobDetail, setJobDetail] = useState({});
  const [jobDetailLoad, setJobDetailLoad] = useState(true);
  const [revisionDetail, setRevisionDetail] = useState({});
  const [revisionDetailLoad, setRevisionDetailLoad] = useState(true);
  const [estimateRevisionList, setestimateRevisionList] = useState([]);
  const [estimateRevisionListLoad, setEstimateRevisionListLoad] = useState(
    true
  );
  const [invoiceList, setInvoiceList] = useState([]);
  const [invoiceListLoad, setInvoiceListLoad] = useState(true);
  const [rciList, setRciList] = useState([]);
  const [rciListLoad, setRciListLoad] = useState(true);
  const [phaseTypeList, setPhaseTypeList] = useState([]);
  const [phaseTypeListLoad, setPhaseTypeListLoad] = useState(true);
  const [newRevisionModalOpen, setNewRevisionModalOpen] = useState(false);
  const [btnLoad, setBtnLoad] = useState(false);
  const [estimateSettingsOpen, setEstimateSettingsOpen] = useState(false);
  const [currentRevisionId, setCurrentRevisionId] = useState();
  const staffApprovedInAllRevisions = estimateRevisionList.map(
    revision => revision.staffApproved
  );
  const [estimateRevisionItemList, setEstimateRevisionItemList] = useState([]);
  const [
    estimateRevisionItemListLoad,
    setEstimateRevisionItemListLoad,
  ] = useState(true);
  const usageEstimateItem = filterUsageItems(estimateRevisionItemList)[0];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_VALUE,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: () => cloneRevisionRecord(),
  });

  useEffect(() => {
    if (id) {
      loadJobDetailResponse();
      loadEstimateRevisionList();
      loadInvoiceList();
      loadPhaseTypeList();
      loadRciList();
    }
  }, [id]);

  useEffect(() => {
    if (id && revisionId) {
      loadEstimateRevision();
      loadEstimateRevisionItemList();
    }
  }, [id, revisionId]);

  useEffect(() => {
    if (currentRevisionId) {
      history.push(`/jobs/${id}/revisions/${currentRevisionId}/scope`);
    }
  }, [currentRevisionId]);

  const loadRciList = async () => {
    try {
      const response = await getRcis(id);
      setRciList(response.data.rcis);
      setRciListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadJobDetailResponse = async () => {
    try {
      const response = await getJob(id);
      setJobDetail(response.data.job);
      setJobDetailLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadInvoiceList = async () => {
    try {
      const response = await getInvoices(id);
      setInvoiceList(response.data.invoices);
      setInvoiceListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadEstimateRevisionList = async () => {
    try {
      const response = await getEstimateRevisions(id);
      setestimateRevisionList(response.data.estimateRevisions);
      setEstimateRevisionListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadEstimateRevisionItemList = async () => {
    try {
      const response = await getEstimateRevisionItems(revisionId);
      setEstimateRevisionItemList(response.data.estimateRevisionItems);
      setEstimateRevisionItemListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadPhaseTypeList = async () => {
    try {
      const response = await getPhaseTypes();
      setPhaseTypeList(response.data.phaseTypes);
      setPhaseTypeListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadEstimateRevision = async () => {
    try {
      const response = await getEstimateRevision(id, revisionId);
      setRevisionDetail(response.data.estimateRevision);
      setRevisionDetailLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const cloneRevisionRecord = async () => {
    try {
      setBtnLoad(true);
      let payload = {
        estimate_revision: {
          name: formik.values.name,
          revision_id: revisionId,
        },
      };
      const response = await createEstimateRevision(id, payload);
      history.push(`/jobs/${id}/revisions/${response.data.revision_id}/scope`);
      loadEstimateRevisionList();
      formik.resetForm();
      setBtnLoad(false);
      setNewRevisionModalOpen(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      setBtnLoad(false);
    }
  };

  const finalRci = getFinalInvoice(rciList);
  const commencementRci = getCommencementInvoice(rciList);

  let contextValue = {
    phaseTypeList,
    revisionId,
    revisionDetail,
    loadEstimateRevision,
    jobDetail,
  };

  const actionBlockComponent = () => {
    return (
      <div className="flex space-x-3">
        <Button
          style="secondary"
          icon={Settings}
          label="Estimate Settings"
          iconPosition="left"
          onClick={() => setEstimateSettingsOpen(true)}
        />

        {staffApprovedInAllRevisions.every(approval => !approval) &&
          !commencementRci &&
          !finalRci && (
            <Button
              label="New Revision"
              onClick={() => setNewRevisionModalOpen(true)}
            />
          )}
      </div>
    );
  };

  if (
    jobDetailLoad ||
    phaseTypeListLoad ||
    estimateRevisionListLoad ||
    invoiceListLoad ||
    revisionDetailLoad ||
    rciListLoad ||
    estimateRevisionItemListLoad
  ) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <OverviewDetailsContext.Provider value={contextValue}>
      <Container isHeaderFixed>
        <Header
          title={`${revisionDetail.name} / Scope`}
          breadcrumbs={[
            { text: "Jobs", link: "/jobs" },
            {
              text: jobWithNameNumber(jobDetail),
              link: `/jobs/${id}/overview`,
            },
          ]}
          actionBlock={actionBlockComponent()}
        />

        <Tabs jobId={id} revisionId={revisionId} />

        <div
          className="flex w-full overflow-hidden"
          style={{ height: "calc(100vh - 134px)" }}
        >
          <div className="w-8/12 p-6 space-y-4 overflow-y-scroll border-r neeto-ui-bg-gray-100 neeto-ui-border-gray-300">
            {jobDetail.cancellationReason && (
              <Callout style="danger" icon={CloseCircle}>
                <span>
                  In <b>Graveyard</b> because of &ldquo;
                  {jobDetail.cancellationReason}
                  &rdquo;
                </span>
              </Callout>
            )}
            {jobDetail.note && (
              <Callout style="warning" icon={Warning}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: jobDetail.note.split("\n").join("<br/>"),
                  }}
                />
              </Callout>
            )}
            <JobOverview />
          </div>

          <div className="w-4/12 p-6 space-y-6 overflow-y-scroll neeto-ui-bg-white">
            <HeaderImage
              jobDetail={jobDetail}
              loadJobDetailResponse={loadJobDetailResponse}
            />
            <EstimateRevisions
              estimateRevisionList={estimateRevisionList}
              loadEstimateRevisionListResponse={loadEstimateRevisionList}
              jobDetail={jobDetail}
              invoiceList={invoiceList}
              loadJobDetailResponse={loadJobDetailResponse}
              setCurrentRevisionId={setCurrentRevisionId}
              currentRevisionId={revisionId}
              loadRciList={loadRciList}
              usageEstimateItem={usageEstimateItem}
            />
          </div>
        </div>

        <EstimateSettingPane
          estimateSettingsOpen={estimateSettingsOpen}
          setEstimateSettingsOpen={setEstimateSettingsOpen}
          jobDetail={jobDetail}
          loadJobDetailResponse={loadJobDetailResponse}
          invoiceList={invoiceList}
        />

        <Modal
          isOpen={newRevisionModalOpen}
          onClose={() => setNewRevisionModalOpen(false)}
        >
          <Modal.Header>
            <Typography style="h2" weight="semibold">
              New Revision
            </Typography>
          </Modal.Header>

          <Modal.Body>
            <Input
              label="Revision Name"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              error={
                Boolean(formik.touched.name && formik.errors.name) &&
                formik.errors.name
              }
              {...formik.getFieldProps("name")}
            />
          </Modal.Body>
          <Modal.Footer className="space-x-2">
            <Button
              label="Save Changes"
              onClick={() => formik.handleSubmit()}
              loading={btnLoad}
            />
            <Button
              style="text"
              label="Cancel"
              onClick={() => setNewRevisionModalOpen(false)}
            />
          </Modal.Footer>
        </Modal>
      </Container>
    </OverviewDetailsContext.Provider>
  );
};

export default Overview;

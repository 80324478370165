import axios from "axios";

export const getMasterLicense = revisionId => {
  return axios.get(
    `/api/v1/job_module/estimate_revisions/${revisionId}/master_licenses/1`
  );
};

export const sendRegisteredLicense = (jobId, revisionId, payload) => {
  return axios.post(
    `/api/v1/job_module/jobs/${jobId}/estimate_revisions/${revisionId}/send_licenses`,
    payload
  );
};

import * as Yup from "yup";
import {
  User,
  UserRole,
  Invoice,
  Clock,
  Suitcase,
  Draft,
} from "@bigbinary/neeto-icons";

export const ARTIST_DETAILS_TABS = {
  Profile: {
    icon: User,
    label: "Profile",
  },
  Details: {
    icon: UserRole,
    label: "Details",
  },
  Invoices: {
    icon: Invoice,
    label: "Invoices",
  },
  RCIs: {
    icon: Clock,
    label: "RCIs",
  },
  Jobs: {
    icon: Suitcase,
    label: "Jobs",
  },
  Catchup: {
    icon: Draft,
    label: "Catchup Notes",
  },
};

export const TAX_STATUS_LIST = [
  { value: "gst_registered", label: "GST Registered" },
  { value: "gst_unregistered", label: "GST Unregistered" },
  { value: "vat_registered", label: "VAT Registered" },
  { value: "vat_unregistered", label: "VAT Unregistered" },
  { value: "N/A", label: "N/A" },
];

export const DETAILS_VALIDATION_SCHEMA = Yup.object({
  company: Yup.boolean(),
  firstName: Yup.string().when("company", {
    is: false,
    then: Yup.string().required("First name can't be empty"),
  }),
  lastName: Yup.string().when("company", {
    is: false,
    then: Yup.string().required("Last name can't be empty"),
  }),
  email: Yup.string().when("company", {
    is: false,
    then: Yup.string().email("Invalid email").required("Email can't be empty"),
  }),
  companyName: Yup.string().when("company", {
    is: true,
    then: Yup.string().nullable().required("Company name can't be empty"),
    otherwise: Yup.string().nullable(),
  }),
  companyEmail: Yup.string().when("company", {
    is: true,
    then: Yup.string().nullable().required("Company email can't be empty"),
    otherwise: Yup.string().nullable(),
  }),
  dateOfBirth: Yup.string().nullable(),
  streetAddress: Yup.string().nullable(),
  suburb: Yup.string().nullable(),
  state: Yup.string().nullable(),
  postcode: Yup.string().nullable(),
  country: Yup.string().nullable(),
  phoneNumber: Yup.string().nullable(),
  abn: Yup.string(),
  taxStatus: Yup.string().nullable(),
  accountName: Yup.string(),
  accountNumber: Yup.string(),
});

export const EDIT_VALIDATION_SCHEMA = Yup.object({
  company: Yup.boolean(),
  firstName: Yup.string().when("company", {
    is: false,
    then: Yup.string().required("First name can't be empty"),
  }),
  firstName: Yup.string().when("company", {
    is: true,
    then: Yup.string().nullable(),
  }),
  lastName: Yup.string().when("company", {
    is: false,
    then: Yup.string().required("Last name can't be empty"),
  }),
  lastName: Yup.string().when("company", {
    is: true,
    then: Yup.string().nullable(),
  }),
  email: Yup.string().when("company", {
    is: false,
    then: Yup.string().email("Invalid email").required("Email can't be empty"),
  }),
  email: Yup.string().when("company", {
    is: true,
    then: Yup.string().nullable(),
  }),
  companyName: Yup.string().when("company", {
    is: true,
    then: Yup.string().required("Company name can't be empty"),
    otherwise: Yup.string().nullable(),
  }),
  companyEmail: Yup.string().when("company", {
    is: true,
    then: Yup.string().required("Company email can't be empty"),
    otherwise: Yup.string().nullable(),
  }),
  dateOfBirth: Yup.string().nullable(),
  streetAddress: Yup.string().nullable(),
  suburb: Yup.string().nullable(),
  state: Yup.string().nullable(),
  postcode: Yup.string().nullable(),
  country: Yup.string().nullable(),
  phoneNumber: Yup.string().nullable(),
  abn: Yup.string().nullable(),
  taxStatus: Yup.string().nullable(),
  accountName: Yup.string().nullable(),
  accountNumber: Yup.string().nullable(),
});

export const DETAILS_INITIAL_VALUE = {
  company: false,
  firstName: "",
  lastName: "",
  pronouns: "",
  email: "",
  salutation: "Guys",
  phoneNumber: "",
  dateOfBirth: "",
  streetAddress: "",
  suburb: "",
  state: "",
  postcode: "",
  country: "",
  about: "",
  abn: "",
  taxStatus: "",
  accountName: "",
  accountNumber: "",
  companyName: "",
  tagIds: [],
  companyEmail: "",
  companyPhone: "",
  rciCompanyName: "",
};

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Search } from "@bigbinary/neeto-icons";
import { Typography, Input, Avatar } from "@bigbinary/neetoui";
import { groupByInitial } from "common/helper";
import useDebounce from "common/debounce";
import InfiniteScroll from "react-infinite-scroll-component";
import classNames from "classnames";

const OrganisationDirectory = ({
  organisationList,
  organisationDetail,
  loadOrganisationListResponse,
  list,
  setList,
  totalRecords,
}) => {
  const [searchParams, setSearchParams] = useState();
  const debouncedSearchTerm = useDebounce(searchParams, 500);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (debouncedSearchTerm != undefined) {
      setList([]);
      setPage(1);
      loadOrganisationListResponse(searchParams);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    setList([...list, ...organisationList]);
  }, [organisationList]);

  const loadNext = () => {
    setTimeout(() => {
      loadOrganisationListResponse(searchParams, page + 1);
      setPage(page + 1);
    }, 1000);
  };

  const removeDuplicacy = array => {
    var resArr = [];
    array.filter(item => {
      var i = resArr.findIndex(x => x.id == item.id);
      if (i <= -1) {
        resArr.push({ ...item });
      }
      return null;
    });

    return resArr;
  };

  const LoaderBlockItem = () => (
    <div className="relative flex gap-3 p-3 transition-colors border-b cursor-pointer neeto-ui-border-gray-200">
      <div className="flex-shrink-0 w-10 h-10 rounded-full shimmer-bg" />
      <div className="flex flex-col flex-grow gap-1">
        <div className="h-4 shimmer-bg" />
        <div className="h-6 shimmer-bg" />
      </div>
    </div>
  );

  const LoaderBlock = () => (
    <div className="flex flex-col w-full">
      <LoaderBlockItem />
      <LoaderBlockItem />
      <LoaderBlockItem />
      <LoaderBlockItem />
    </div>
  );

  return (
    <>
      <aside className="flex-shrink-0 hidden border-r neeto-ui-border-gray-200 xl:order-first xl:flex xl:flex-col w-80">
        <div className="px-6 py-4 space-y-3">
          <div className="flex flex-col space-y-1">
            <Typography style="h4" weight="semibold" lineHeight="none">
              Directory
            </Typography>
            <Typography style="body2" className="neeto-ui-text-gray-600">
              Search directory of {totalRecords} organisations
            </Typography>
          </div>
          <form
            onSubmit={e => {
              e.preventDefault();
            }}
          >
            <Input
              placeholder="Search name, address or contact"
              prefix={<Search size={16} />}
              value={searchParams}
              onChange={e => setSearchParams(e.target.value)}
            />
          </form>
        </div>
        <div
          id="scrollableDiv"
          style={{
            height: "100vh",
            overflow: "auto",
          }}
        >
          <InfiniteScroll
            dataLength={list.length}
            next={loadNext}
            loader={<LoaderBlock />}
            scrollableTarget="scrollableDiv"
            hasMore={totalRecords > list.length ? true : false}
          >
            <nav
              className="flex-1 min-h-0 pb-4 space-y-2"
              aria-label="Directory"
            >
              {Object.entries(
                groupByInitial(removeDuplicacy(list), "name")
              ).map((orgGroup, index) => {
                return (
                  <div className="px-3" key={index}>
                    <ul className="relative z-0 space-y-2">
                      {orgGroup[1].map((organisation, index) => {
                        return (
                          <li key={index}>
                            <Link
                              className="no-underline focus:outline-none"
                              to={`/organisations/${organisation.id}`}
                            >
                              <div
                                className={classNames(
                                  "relative flex items-center p-3 space-x-3 rounded-md hover:neeto-ui-bg-gray-200",
                                  {
                                    "neeto-ui-bg-gray-200":
                                      organisation.id ===
                                      organisationDetail?.id,
                                  }
                                )}
                              >
                                <div className="flex-shrink-0">
                                  <Avatar
                                    size="large"
                                    user={{ name: organisation.name }}
                                  />
                                </div>
                                <div className="flex flex-col flex-shrink-1">
                                  <Typography
                                    className="block w-full break-words neeto-ui-text-gray-800"
                                    component="span"
                                    lineHeight="tight"
                                    style="h5"
                                    weight="medium"
                                  >
                                    {organisation.name}
                                  </Typography>
                                  <Typography
                                    className="w-full overflow-hidden neeto-ui-text-gray-700 overflow-ellipsis"
                                    component="span"
                                    data-cy="chat-item"
                                    lineHeight="normal"
                                    style="body3"
                                  >
                                    {organisation.type}
                                  </Typography>
                                </div>
                              </div>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
            </nav>
          </InfiniteScroll>
        </div>
      </aside>
    </>
  );
};

export default OrganisationDirectory;

import React, { useState, useEffect, useRef } from "react";
import { Plus } from "@bigbinary/neeto-icons";
import {
  Button,
  Label,
  Textarea,
  Spinner,
  Table,
  Tag,
  Modal,
  Typography,
  Radio,
  Select,
  DatePicker
} from "@bigbinary/neetoui";
import * as dayjs from "dayjs";
import { useFormik } from "formik";
import * as R from "ramda";
import { showToastrError } from "common";
import { DEFAULT_PAGE_SIZE, humanize, getRandomNotFoundImage } from "common/helper";
import {
  getContactActivityLogs,
  createContactActivityLog,
} from "apis/contacts/contact_activity_logs";
import {
  getActiveCrmActionTags,
  createCrmActionTag,
} from "apis/settings/crm_action_tags";
import { dropDownListGenerator } from "common/helper";
import {
  ACTIVITY_LOG_VALIDATION_SCHEMA,
  INITIAL_ACTIVITY_LOG_VALUE,
} from "./constants";
import EmptyState from "components/Common/EmptyState";

const LastHistory = ({ contactDetail, setcurrentTab }) => {
  const inputRef = useRef(null);
  const [activityLogList, setActivityLogList] = useState([]);
  const [activityLogListLoading, setActivityLogListLoading] = useState(true);
  const [crmActionTagList, setCrmActionTagList] = useState([]);
  const [entryFormState, setEntryFormState] = useState(false);
  const [emptyImage, setEmptyImage] = useState();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_ACTIVITY_LOG_VALUE,
    validationSchema: ACTIVITY_LOG_VALIDATION_SCHEMA,
    onSubmit: () => {
      onSubmit();
    },
  });

  useEffect(() => {
    loadContactActivityListResponse();
    setEmptyImage(getRandomNotFoundImage());
  }, []);

  useEffect(() => {
    if (entryFormState) {
      loadCrmActionTagListResponse();
    }
  }, [entryFormState]);

  useEffect(() => {
    if (formik.values.status === "open") {
      formik.setFieldValue("closureDate", null);
    } else if (formik.values.status === "completed") {
      formik.setFieldValue("issueDate", null);
    } else {
      formik.setFieldValue("issueDate", null);
      formik.setFieldValue("closureDate", null);
    }
  }, [formik.values.status])

  const loadContactActivityListResponse = async () => {
    try {
      const response = await getContactActivityLogs(
        contactDetail.id,
        "",
        {},
        1,
        DEFAULT_PAGE_SIZE
      );
      setActivityLogList(response.data.contactActivityLogs);
      setActivityLogListLoading(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const createCrmActionTagEntry = async tagName => {
    try {
      const response = await createCrmActionTag({
        crm_action_tag: {
          name: tagName,
          status: "open",
          tag: tagName,
        },
      });
      loadCrmActionTagListResponse();
      formik.setFieldValue("crmActionTagId", response.data.id);
      formik.setFieldValue("status", response.data.status);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadCrmActionTagListResponse = async () => {
    try {
      const crmActionTagListResponse = await getActiveCrmActionTags();
      setCrmActionTagList(crmActionTagListResponse.data.crmActionTags || []);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const onSubmit = async () => {
    try {
      await createContactActivityLog(contactDetail.id, {
        contact_activity_log: { ...formik.values },
      });
      setEntryFormState(false);
      loadContactActivityListResponse();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const COLUMN_DATA = [
    {
      title: "Date",
      dataIndex: "latestDate",
      key: "latestDate",
      render: latestDate => {
        return (
          <Button
            style="link"
            onClick={() => setcurrentTab({ label: "History" })}
            label={dayjs(latestDate).format("DD/MM/YYYY")}
          />
        );
      },
    },
    {
      title: "Action Tag",
      dataIndex: "crmActionTagName",
      key: "crmActionTagName",
      render: crmActionTagName => <>{crmActionTagName || "N/A"}</>,
    },
    {
      title: "Note",
      dataIndex: "note",
      key: "note",
      render: note => <>{note || "N/A"}</>,
    },
    {
      title: "Created By",
      dataIndex: "createrName",
      key: "createrName",
      render: (_, rowData) => {
        return (
          <>
            {rowData.createdById ? (
              <Button
                style="link"
                to={`/staff/${rowData.createdById}`}
                label={rowData.createrName}
              />
            ) : (
              <>N/A</>
            )}
          </>
        );
      },
    },
    {
      title: "Job",
      dataIndex: "resourceName",
      key: "resourceName",
      render: (_, rowData) => {
        return (
          <>
            {rowData.resourceId ? (
              <Button
                style="link"
                to={`/jobs/${rowData.resourceId}/overview`}
                label={rowData.resourceName}
              />
            ) : (
              <>N/A</>
            )}
          </>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: status => {
        return (
          <Tag
            type="solid"
            label={humanize(status)}
            style={status === "open" ? "danger" : "success"}
          />
        );
      },
    },
  ];

  if (activityLogListLoading) {
    return (
      <div className="flex items-center justify-center w-full h-10">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex flex-col py-3 border-t border-gray-200">
      <div className="flex items-center justify-between pb-3 mb-3 space-x-3 border-b border-gray-200">
        <Label className="font-semibold text-gray-800">Last History</Label>
        <Button
          style="link"
          label="Add Log"
          icon={Plus}
          iconPosition="left"
          onClick={() => {
            formik.resetForm();
            setEntryFormState(true);
          }}
        />
      </div>

      {!R.isEmpty(activityLogList) ? (
        <Table columnData={COLUMN_DATA} rowData={[activityLogList[0]]} />
      ) : (
        <EmptyState image={emptyImage} title="No Activity Logs Found" />
      )}

      <Modal
        isOpen={entryFormState}
        onClose={() => setEntryFormState(false)}
        initialFocusRef={inputRef}
      >
        <Modal.Header>
          <Typography style="h2" weight="semibold">
            Add Log
          </Typography>
        </Modal.Header>
        <Modal.Body>
          <div className="flex flex-col space-y-6">
            <Select
              isClearable
              isSearchable
              isCreateable
              innerRef={inputRef}
              label="CRM Action Tag"
              name="crmActionTagId"
              id="crmActionTagId"
              placeholder="Select CRM Action Tag"
              value={dropDownListGenerator(crmActionTagList).find(
                crmActionTag =>
                  crmActionTag.value === formik.values.crmActionTagId
              )}
              error={
                Boolean(
                  formik.touched.crmActionTagId && formik.errors.crmActionTagId
                ) && formik.errors.crmActionTagId
              }
              options={dropDownListGenerator(crmActionTagList)}
              onChange={option => {
                if (option) {
                  formik.setFieldValue("crmActionTagId", option.value);
                  formik.setFieldValue("status", option.status);
                } else {
                  formik.setFieldValue("crmActionTagId", null);
                }

                formik.setFieldTouched("crmActionTagId", true);
              }}
              onCreateOption={inputValue => {
                createCrmActionTagEntry(inputValue);
              }}
            />

            <Radio label="Status">
              <Radio.Item
                label="Open"
                name="status"
                checked={formik.values.status === "open"}
                onChange={() => formik.setFieldValue("status", "open")}
              />
              <Radio.Item
                label="Completed"
                name="status"
                checked={formik.values.status === "completed"}
                onChange={() => formik.setFieldValue("status", "completed")}
              />
            </Radio>

            {formik.values.status === "open" && (
              <DatePicker
                label="Issue Date"
                name="issueDate"
                dateFormat="DD/MM/YYYY"
                disabledDate={current =>
                  current && current.valueOf() > dayjs().toDate()
                }
                allowClear={false}
                value={formik.values.issueDate
                  ? dayjs(formik.values.issueDate)
                  : null}
                onChange={date => {
                  formik.setFieldValue("issueDate", date.format("YYYY-MM-DD"));
                }}
                getPopupContainer={triggerNode => triggerNode.parentNode}
              />
            )}

            {formik.values.status === "completed" && (
              <DatePicker
                label="Closure Date"
                name="closureDate"
                dateFormat="DD/MM/YYYY"
                allowClear={false}
                value={formik.values.closureDate
                  ? dayjs(formik.values.closureDate)
                  : null}
                onChange={date => {
                  formik.setFieldValue("closureDate", date.format("YYYY-MM-DD"));
                }}
                getPopupContainer={triggerNode => triggerNode.parentNode}
              />
            )}

            <Textarea
              label="Notes"
              name="notes"
              value={formik.values.note}
              onChange={formik.handleChange}
              error={
                Boolean(formik.touched.note && formik.errors.note) &&
                formik.errors.note
              }
              row="4"
              {...formik.getFieldProps("note")}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="space-x-2">
          <Button
            label="Save Changes"
            onClick={() => {
              formik.handleSubmit();
            }}
          />
          <Button
            style="text"
            label="Cancel"
            onClick={() => {
              setEntryFormState(false);
            }}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default LastHistory;

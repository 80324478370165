import React, { useState, useEffect } from "react";
import {
  Pane,
  Typography,
  Spinner,
  Button,
  Select,
  Input,
  Switch,
  Modal,
} from "@bigbinary/neetoui";
import { getOrganisations } from "apis/organisations/organisations";
import { getOrganisationTypes } from "apis/settings/organisation_types";
import { getIndustrySectors } from "apis/settings/industry_sectors";
import { getJobTypes } from "apis/settings/job_types";
import { getCrmActionTags } from "apis/settings/crm_action_tags";
import { createFilterSpecification } from "apis/settings/filter_specifications";
import { getCountries } from "apis/settings/countries";
import { getStates } from "apis/settings/states";
import { getStaffs } from "apis/staffs/staffs";
import { showToastrError } from "common";
import useDebounce from "common/debounce";
import { dropDownListGenerator, removeDuplicacy, sleep } from "common/helper";
import { ACTION_TAG_STATUS_LIST } from "./constants";
import { Refresh } from "@bigbinary/neeto-icons";
import AsyncPaginateSelect from "components/Common/AsyncPaginateSelect";

const FilterPane = ({
  isOpen,
  onClose,
  filterAttr,
  setFilterAttr,
  loadContactListResponse,
  loadFilterSpecificationListResponse,
  user,
}) => {
  const [initialDataLoad, setInitialDataLoad] = useState(true);
  const [organisationOptions, setOrganisationOptions] = useState([]);
  const [organisationOptionsLoading, setOrganisationOptionsLoading] = useState(
    false
  );
  const [staffOptions, setStaffOptions] = useState([]);
  const [organisationTypeOptions, setOrganisationTypeOptions] = useState([]);
  const [industrySectorOptions, setIndustrySectorOptions] = useState([]);
  const [jobTypeOptions, setJobTypeOptions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [saveFilterAlertState, setSaveFilterAlertState] = useState(false);
  const [filterSpecificationName, setFilterSpecificationName] = useState("");
  const [orgPage, setOrgPage] = useState(1);
  const [staffPage, setStaffPage] = useState(1);
  const [orgInitial, setOrgInitial] = useState(true);
  const [staffInitial, setStaffInitial] = useState(true);
  const [asyncDataLoad, setAsyncDataLoad] = useState(true);
  const [crmActionTagList, setCrmActionTagList] = useState([]);
  const [initial, setInitial] = useState(true);
  const [daysSinceLastActivity, setDaysSinceLastActivity] = useState();
  const debouncedDaysSinceLastActivity = useDebounce(
    daysSinceLastActivity,
    500
  );
  const [contactPositions, setContactPositions] = useState();
  const debouncedContactPositions = useDebounce(contactPositions, 1000);

  useEffect(() => {
    if (isOpen) {
      loadInitialData();
    }
  }, [isOpen]);

  useEffect(async () => {
    if (filterAttr?.countries?.length > 0) {
      await loadStateList();
    }
  }, [filterAttr]);

  useEffect(() => {
    if (
      staffInitial &&
      filterAttr.staffIds?.length > 0 &&
      staffOptions.length > 0
    ) {
      searchStaff(filterAttr.staffIds);
      setStaffInitial(false);
    }
  }, [filterAttr.staffIds, staffOptions]);

  useEffect(() => {
    if (
      filterAttr.organisationIds?.length > 0 &&
      orgInitial &&
      organisationOptions.length > 0
    ) {
      searchOrganisation(filterAttr.organisationIds);
      setOrgInitial(false);
    }
  }, [filterAttr.organisationIds, organisationOptions]);

  useEffect(() => {
    setDaysSinceLastActivity(filterAttr.daysSinceLastActivity);
  }, [filterAttr.daysSinceLastActivity]);

  useEffect(() => {
    setContactPositions(filterAttr.contactPositions);
  }, [filterAttr.contactPositions]);

  useEffect(() => {
    if (!initial) {
      setFilterAttr({
        ...filterAttr,
        daysSinceLastActivity: debouncedDaysSinceLastActivity,
        contactPositions: debouncedContactPositions,
      });
    }
  }, [debouncedDaysSinceLastActivity, debouncedContactPositions]);

  const loadInitialData = async () => {
    await loadJobTypeList();
    await loadCountryList();
    await loadOrganisationTypeList();
    await loadCrmActionTagListResponse();
    await setInitialDataLoad(false);
    await loadOrganisationList();
    await loadStaffList();
    await loadIndustrySectorList();
    await setAsyncDataLoad(false);
  };

  const loadOrganisationTypeList = async () => {
    try {
      const response = await getOrganisationTypes();
      setOrganisationTypeOptions(
        dropDownListGenerator(response.data.organisationTypes)
      );
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadJobTypeList = async () => {
    try {
      const response = await getJobTypes();
      setJobTypeOptions(dropDownListGenerator(response.data.jobTypes));
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadCountryList = async () => {
    try {
      const response = await getCountries();
      setCountries(response.data.countries);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadStateList = async () => {
    try {
      const response = await getStates(filterAttr.countries?.join("<>") || "");
      setStates(response.data.states);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadOrganisationList = async search => {
    setOrganisationOptionsLoading(true);
    await sleep(1000);

    try {
      let filteredOptions;
      let totalRecords;

      if (!search) {
        const response = await getOrganisations("", orgPage);
        setOrganisationOptions([
          ...organisationOptions,
          ...response.data.organisations,
        ]);
        totalRecords = response.data.totalRecords;
        setOrgPage(orgPage + 1);
        filteredOptions = response.data.organisations;
      } else {
        const searchLower = search.toLowerCase();

        const response = await getOrganisations(searchLower, orgPage);
        setOrganisationOptions([
          ...organisationOptions,
          ...response.data.organisations,
        ]);
        setOrgPage(orgPage + 1);
        filteredOptions = response.data.organisations;
        totalRecords = filteredOptions.length;
      }
      return {
        options: dropDownListGenerator(removeDuplicacy(filteredOptions)),
        hasMore: filteredOptions.length === totalRecords ? false : true,
      };
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      setOrganisationOptionsLoading(false);
    }
  };

  const loadStaffList = async search => {
    await sleep(1000);

    try {
      let filteredOptions;
      let totalRecords;

      if (!search) {
        const response = await getStaffs("", staffPage);
        setStaffOptions(
          removeDuplicacy([...staffOptions, ...response.data.staffs])
        );
        totalRecords = response.data.totalRecords;
        setStaffPage(staffPage + 1);
        filteredOptions = response.data.staffs;
      } else {
        const searchLower = search.toLowerCase();

        const response = await getStaffs(searchLower, staffPage);
        setStaffOptions([...staffOptions, ...response.data.staffs]);
        setStaffPage(staffPage + 1);
        filteredOptions = response.data.staffs;
        totalRecords = filteredOptions.length;
      }

      return {
        options: dropDownListGenerator(removeDuplicacy(filteredOptions)),
        hasMore: filteredOptions.length === totalRecords ? false : true,
      };
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const searchStaff = async staffIds => {
    try {
      const searchResponse = await getStaffs("", 1, staffIds);
      setStaffOptions([...staffOptions, ...searchResponse.data.staffs]);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const searchOrganisation = async organisationIds => {
    try {
      const searchResponse = await getOrganisations("", 1, organisationIds);
      setOrganisationOptions([
        ...organisationOptions,
        ...searchResponse.data.organisations,
      ]);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadCrmActionTagListResponse = async () => {
    try {
      const crmActionTagListResponse = await getCrmActionTags();
      setCrmActionTagList(
        dropDownListGenerator(crmActionTagListResponse.data.crmActionTags || [])
      );
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const saveFilterSpecification = async () => {
    try {
      await createFilterSpecification({
        filter_specification: {
          ...filterAttr,
          name: filterSpecificationName,
          user_id: user.user.id,
          default: true,
        },
      });
      setSaveFilterAlertState(false);
      setFilterSpecificationName();
      loadFilterSpecificationListResponse();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadIndustrySectorList = async () => {
    try {
      const response = await getIndustrySectors();
      setIndustrySectorOptions(
        dropDownListGenerator(response.data.industrySectors)
      );
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  return (
    <>
      <Pane isOpen={isOpen} onClose={onClose}>
        <Pane.Header>
          <Typography style="h2" weight="semibold">
            Filter
          </Typography>
        </Pane.Header>
        <Pane.Body>
          {initialDataLoad ? (
            <div className="flex items-center justify-center w-full h-full">
              <Spinner />
            </div>
          ) : (
            <>
              <div className="flex flex-col w-full pb-6 space-y-6">
                <AsyncPaginateSelect
                  isMulti
                  cacheOptions
                  label="Client"
                  isDisabled={asyncDataLoad}
                  isLoading={asyncDataLoad || organisationOptionsLoading}
                  loadOptions={loadOrganisationList}
                  value={
                    dropDownListGenerator(
                      removeDuplicacy(organisationOptions)
                    ).filter(org =>
                      filterAttr.organisationIds?.includes(org.value)
                    ) || null
                  }
                  onChange={orgs => {
                    if (orgs?.length > 0) {
                      let orgIds = orgs.map(org => org.value);
                      setFilterAttr({
                        ...filterAttr,
                        organisationIds: orgIds,
                      });
                    } else {
                      setFilterAttr({ ...filterAttr, organisationIds: [] });
                    }
                  }}
                  name="organisationId"
                  id="organisationId"
                  placeholder="Select a Organisation"
                />

                <Select
                  isMulti
                  label="Organisation Types"
                  name="organisationTypeIds"
                  id="organisationTypeIds"
                  placeholder="Select Organisation type"
                  value={organisationTypeOptions.filter(organisationType =>
                    filterAttr.organisationTypeIds?.includes(
                      organisationType.value
                    )
                  )}
                  options={organisationTypeOptions}
                  onChange={types => {
                    if (types?.length > 0) {
                      setFilterAttr({
                        ...filterAttr,
                        organisationTypeIds: types.map(type => type.value),
                      });
                    } else {
                      setFilterAttr({ ...filterAttr, organisationTypeIds: [] });
                    }
                  }}
                />

                <Select
                  isMulti
                  label="Industry Sectors"
                  name="industrySectorIds"
                  id="industrySectorIds"
                  placeholder="Select Industry Sector"
                  value={industrySectorOptions.filter(industrySector =>
                    filterAttr.industrySectorIds?.includes(industrySector.value)
                  )}
                  options={industrySectorOptions}
                  onChange={types => {
                    if (types?.length > 0) {
                      setFilterAttr({
                        ...filterAttr,
                        industrySectorIds: types.map(type => type.value),
                      });
                    } else {
                      setFilterAttr({ ...filterAttr, industrySectorIds: [] });
                    }
                  }}
                />

                <AsyncPaginateSelect
                  isDisabled={asyncDataLoad}
                  isLoading={asyncDataLoad}
                  label="Staff"
                  isMulti
                  id="staff"
                  placeholder="Select Staff"
                  value={dropDownListGenerator(
                    removeDuplicacy(staffOptions),
                    "name"
                  ).filter(staff => filterAttr.staffIds?.includes(staff.value))}
                  isClearable={true}
                  onChange={staffs => {
                    if (staffs?.length > 0) {
                      let staffIds = staffs.map(staff => staff.value);
                      setFilterAttr({ ...filterAttr, staffIds: staffIds });
                    } else {
                      setFilterAttr({ ...filterAttr, staffIds: [] });
                    }
                  }}
                  loadOptions={loadStaffList}
                />

                <Select
                  isMulti
                  label="Country"
                  name="countries"
                  id="countries"
                  placeholder="Select a Country"
                  value={countries.filter(country =>
                    filterAttr.countries?.includes(country.value)
                  )}
                  options={countries}
                  onChange={countries => {
                    if (countries?.length > 0) {
                      let countryValues = countries.map(
                        country => country.value
                      );
                      setFilterAttr({
                        ...filterAttr,
                        countries: countryValues,
                      });
                    } else {
                      setFilterAttr({ ...filterAttr, countries: [] });
                    }
                  }}
                />

                <Select
                  label="State"
                  name="states"
                  id="states"
                  isMulti
                  placeholder="Select a State"
                  value={states.filter(state =>
                    filterAttr.states?.includes(state.value)
                  )}
                  options={states}
                  onChange={states => {
                    if (states?.length > 0) {
                      let stateValues = states.map(state => state.value);
                      setFilterAttr({ ...filterAttr, states: stateValues });
                    } else {
                      setFilterAttr({ ...filterAttr, states: [] });
                    }
                  }}
                />

                <Input
                  label="Position"
                  name="contactPositions"
                  onChange={e => {
                    setInitial(false);
                    setContactPositions(e.target.value);
                  }}
                  value={contactPositions}
                />

                <Select
                  label="Job Types"
                  name="jobTypeIds"
                  id="jobTypeIds"
                  isMulti
                  placeholder="Select a Job type"
                  value={jobTypeOptions.filter(jobType =>
                    filterAttr.jobTypeIds?.includes(jobType.value)
                  )}
                  options={jobTypeOptions}
                  onChange={types => {
                    if (types?.length > 0) {
                      let typeIds = types.map(type => type.value);
                      setFilterAttr({ ...filterAttr, jobTypeIds: typeIds });
                    } else {
                      setFilterAttr({ ...filterAttr, jobTypeIds: [] });
                    }
                  }}
                />

                <Select
                  label="Activity Tag (1 Max)"
                  name="actionTagIds"
                  id="actionTagIds"
                  isDisabled={filterAttr.inactiveContactSearch}
                  isMulti
                  placeholder="Select an Action tag"
                  value={crmActionTagList.filter(actionTag =>
                    filterAttr.actionTagIds?.includes(actionTag.value)
                  )}
                  options={crmActionTagList}
                  onChange={tags => {
                    if (tags?.length > 0) {
                      let tagIds = tags.map(tag => tag.value);
                      setFilterAttr({ ...filterAttr, actionTagIds: tagIds });
                    } else {
                      setFilterAttr({ ...filterAttr, actionTagIds: [] });
                    }
                  }}
                />

                <Select
                  label="Activity Tag Status"
                  name="actionTagStatuses"
                  id="actionTagStatuses"
                  isDisabled={filterAttr.inactiveContactSearch}
                  isMulti
                  placeholder="Select an Action tag status"
                  value={ACTION_TAG_STATUS_LIST.filter(status =>
                    filterAttr.actionTagStatuses?.includes(status.value)
                  )}
                  options={ACTION_TAG_STATUS_LIST}
                  onChange={statuses => {
                    if (statuses?.length > 0) {
                      let statusIds = statuses.map(status => status.value);
                      setFilterAttr({
                        ...filterAttr,
                        actionTagStatuses: statusIds,
                      });
                    } else {
                      setFilterAttr({ ...filterAttr, actionTagStatuses: [] });
                    }
                  }}
                />

                <Input
                  type="number"
                  label="Days Since Last Activity"
                  name="daysSinceLastActivity"
                  disabled={filterAttr.inactiveContactSearch}
                  onChange={e => {
                    setInitial(false);
                    setDaysSinceLastActivity(e.target.value);
                  }}
                  value={daysSinceLastActivity}
                />

                <Switch
                  checked={filterAttr.inactiveContactSearch}
                  onChange={() => {
                    setInitial(false);
                    setFilterAttr({
                      ...filterAttr,
                      inactiveContactSearch: !filterAttr.inactiveContactSearch,
                    });
                  }}
                  label="Search inactive contacts"
                />

                <Switch
                  checked={filterAttr.notAssignedContactSearch}
                  onChange={() => {
                    setInitial(false);
                    setFilterAttr({
                      ...filterAttr,
                      notAssignedContactSearch: !filterAttr.notAssignedContactSearch,
                    });
                  }}
                  label="Search not assigned contacts"
                />

                <Switch
                  checked={filterAttr.notEnabledContactsSearch}
                  onChange={() => {
                    setInitial(false);
                    setFilterAttr({
                      ...filterAttr,
                      notEnabledContactsSearch: !filterAttr.notEnabledContactsSearch,
                    });
                  }}
                  label="Search non-opted in contacts"
                />
              </div>
            </>
          )}
        </Pane.Body>
        <Pane.Footer className="flex justify-between w-full">
          <div className="flex space-x-2">
            {!filterAttr?.id && (
              <Button
                label="Save Filter"
                onClick={async () => {
                  setSaveFilterAlertState(true);
                }}
              />
            )}
            <Button label="Cancel" style="text" onClick={() => onClose()} />
          </div>
          <Button
            label="Reset"
            style="secondary"
            icon={Refresh}
            onClick={async () => {
              setFilterAttr({});
              loadContactListResponse(true, 1, {});
            }}
          />
        </Pane.Footer>
      </Pane>

      <Modal
        isOpen={saveFilterAlertState}
        onClose={() => setSaveFilterAlertState(false)}
      >
        <Modal.Header>
          <Typography style="h2" weight="semibold">
            Save Filter Specification
          </Typography>
        </Modal.Header>
        <Modal.Body>
          <Input
            className="mb-5"
            label="Name"
            autoFocus={true}
            required={true}
            onChange={e => setFilterSpecificationName(e.target.value)}
            value={filterSpecificationName}
          />
        </Modal.Body>
        <Modal.Footer className="space-x-2">
          <Button
            label="Save Filter"
            onClick={() => saveFilterSpecification()}
          />
          <Button
            style="text"
            label="Cancel"
            onClick={() => setSaveFilterAlertState(false)}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FilterPane;

import axios from "axios";

const getContactNotesUrl = (contactId, id) => {
  if (id) {
    return `/api/v1/contacts/contacts/${contactId}/contact_notes/${id}`;
  }

  return `/api/v1/contacts/contacts/${contactId}/contact_notes`;
};

export const getContactNotes = contactId => {
  return axios.get(getContactNotesUrl(contactId));
};

export const getContactNote = (contactId, contactNoteId) => {
  return axios.get(getContactNotesUrl(contactId, contactNoteId));
};

export const createContactNote = (contactId, payload) => {
  return axios.post(getContactNotesUrl(contactId), payload);
};

export const updateContactNote = (contactId, id, payload) => {
  return axios.put(getContactNotesUrl(contactId, id), payload);
};

export const destroyContactNote = (contactId, id) => {
  return axios.delete(getContactNotesUrl(contactId, id));
};

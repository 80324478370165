import React, { useState } from "react";
import { MenuHorizontal } from "@bigbinary/neeto-icons";
import {
  Modal,
  Input,
  Alert,
  Typography,
  Dropdown,
  Table,
  Spinner,
  Button,
  Switch,
} from "@bigbinary/neetoui";
import {
  updateTargetAmount,
  destroyTargetAmount,
} from "apis/settings/target_amounts";
import { showToastrError } from "common";
import { numberWithCommas, displayData } from "common/helper";

const TargetAmountListForSpecificYear = ({
  listModalState,
  setListModalState,
  targetAmountYearList,
  targetAmountYearListLoad,
  loadTargetAmountData,
  selectedYear,
  selectedLocationName,
}) => {
  const [modalState, setModalState] = useState(false);
  const [deleteModalState, setDeleteModalState] = useState(false);
  const [targetAmountId, setTargetAmountId] = useState("");
  const [year, setYear] = useState("");
  const [week, setWeek] = useState("");
  const [locationId, setLocationId] = useState("");
  const [editAll, setEditAll] = useState(false);
  const [invoiceAmount, setInvoiceAmount] = useState("");
  const [approvalAmount, setApprovalAmount] = useState("");
  const [actionTagClosureCount, setActionTagClosureCount] = useState();
  const { Menu, MenuItem } = Dropdown;

  const resetAllStates = () => {
    loadTargetAmountData(locationId, year);
    setTargetAmountId("");
    setModalState(false);
    setDeleteModalState(false);
    setWeek("");
    setInvoiceAmount("");
    setApprovalAmount("");
  };

  const updateTargetAmountEntry = async () => {
    try {
      await updateTargetAmount(targetAmountId, {
        target_amount: {
          invoice_amount: invoiceAmount,
          approval_amount: approvalAmount,
          actionTagClosureCount: actionTagClosureCount,
          edit_all: editAll,
        },
      });
      resetAllStates();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const destroyTargetAmountEntry = async () => {
    try {
      await destroyTargetAmount(targetAmountId);
      resetAllStates();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const renderDropdown = (rowData) => {
    return (
      <Dropdown
        autoWidth
        closeOnSelect
        buttonStyle="text"
        strategy="fixed"
        icon={MenuHorizontal}
      >
        <Menu>
          <MenuItem.Button
            onClick={() => {
              setTargetAmountId(rowData.id);
              setLocationId(rowData.locationId);
              setYear(rowData.year);
              setWeek(rowData.week);
              setInvoiceAmount(rowData.invoiceAmount);
              setApprovalAmount(rowData.approvalAmount);
              setActionTagClosureCount(rowData.actionTagClosureCount);
              setModalState(true);
            }}
          >
            Edit
          </MenuItem.Button>
          <MenuItem.Button
            style="danger"
            onClick={() => {
              setLocationId(rowData.locationId);
              setYear(rowData.year);
              setTargetAmountId(rowData.id);
              setDeleteModalState(true);
            }}
          >
            Delete
          </MenuItem.Button>
        </Menu>
      </Dropdown>
    );
  }

  const COLUMN_DATA = [
    {
      title: "Week",
      dataIndex: "week",
      key: "week",
      width: 75,
      render: (_, rowData) => displayData(rowData, "week"),
    },
    {
      title: "Invoice Amount",
      dataIndex: "invoiceAmount",
      key: "invoiceAmount",
      width: 150,
      render: (_, rowData) => numberWithCommas(rowData.invoiceAmount),
    },
    {
      title: "Approval Amount",
      dataIndex: "approvalAmount",
      key: "approvalAmount",
      width: 160,
      render: (_, rowData) => numberWithCommas(rowData.approvalAmount),
    },
    {
      title: "Closure Count",
      dataIndex: "actionTagClosureCount",
      key: "actionTagClosureCount",
      width: 130,
      render: (_, rowData) => displayData(rowData, "actionTagClosureCount"),
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 60,
      render: (_, rowData) => renderDropdown(rowData),
    },
  ];

  return (
    <Modal
      isOpen={listModalState}
      size="large"
      onClose={() => {
        setListModalState(false);
        loadTargetAmountData();
      }}
    >
      <Modal.Header>
        <Typography style="h2" weight="semibold">
          {`${selectedLocationName} Target Amount List - ${selectedYear}`}
        </Typography>
      </Modal.Header>
      <Modal.Body className="mb-6">
        <div
          className="flex flex-col w-full overflow-y-hidden overflow-x-auto"
          style={{ height: "calc(75vh)" }}
        >
          {targetAmountYearListLoad ? (
            <Spinner />
          ) : (
            <Table
              fixedHeight
              rowData={targetAmountYearList}
              columnData={COLUMN_DATA}
              defaultPageSize={100}
              currentPageNumber={1}
            />
          )}
        </div>
      </Modal.Body>

      <Modal isOpen={modalState} onClose={() => setModalState(false)}>
        <Modal.Header>
          <Typography style="h2" weight="semibold">
            Edit Target Amount
          </Typography>
        </Modal.Header>
        <Modal.Body>
          <div className="flex flex-col w-full space-y-6">
            <Input
              label="Week"
              type="number"
              name="week"
              value={week}
              disabled={true}
              onChange={e => setWeek(e.target.value)}
            />

            <Input
              type="number"
              label="Invoice Amount"
              name="invoiceAmount"
              value={invoiceAmount}
              onChange={e => setInvoiceAmount(e.target.value)}
            />

            <Input
              type="number"
              label="Approval Amount"
              name="approvalAmount"
              value={approvalAmount}
              onChange={e => setApprovalAmount(e.target.value)}
            />

            <Input
              label="Action Tag Closure Count"
              name="actionTagClosureCount"
              type="number"
              value={actionTagClosureCount}
              autoFocus
              onChange={e => setActionTagClosureCount(e.target.value)}
            />

            <Switch
              checked={editAll}
              label="Edit later entries"
              onChange={() => setEditAll(!editAll)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="flex space-x-2">
          <Button
            label="Save changes"
            onClick={() => {
              updateTargetAmountEntry();
            }}
          />
          <Button
            label="Cancel"
            style="text"
            onClick={() => setModalState(false)}
          />
        </Modal.Footer>
      </Modal>

      <Alert
        isOpen={deleteModalState}
        title="Delete Target Amount"
        message="Are you sure you want to delete the Target Amount?"
        onClose={() => setDeleteModalState(false)}
        onSubmit={() => destroyTargetAmountEntry()}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, delete"
      />
    </Modal>
  );
};

export default TargetAmountListForSpecificYear;

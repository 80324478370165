import axios from "axios";

const getFilterSpecificationsUrl = id => {
  if (id) {
    return `/api/v1/settings/filter_specifications/${id}`;
  }

  return `/api/v1/settings/filter_specifications`;
};

export const getFilterSpecifications = () => {
  return axios.get(getFilterSpecificationsUrl());
};

export const getFilterSpecification = (id) => {
  return axios.get(getFilterSpecificationsUrl(id));
};

export const createFilterSpecification = payload => {
  return axios.post(getFilterSpecificationsUrl(), payload);
};

export const updateFilterSpecification = (id, payload) => {
  return axios.put(getFilterSpecificationsUrl(id), payload);
};

export const destroyFilterSpecification = id => {
  return axios.delete(getFilterSpecificationsUrl(id));
};

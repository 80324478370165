import React, { useState, useEffect } from "react";
import { Delete } from "@bigbinary/neeto-icons";
import {
  Select,
  Textarea,
  Button,
  Typography,
  Label,
} from "@bigbinary/neetoui";
import produce from "immer";

const SubjectComponent = ({ formik, getOptions, attr }) => {
  const [subjectList, setSubjectList] = useState([{ content: "" }]);
  const [initial, setInitial] = useState(true);
  const contentOptions = [
    { label: "Resource", value: "resource" },
    { label: "Text", value: "text" },
  ];
  const resourceOptions = getOptions();

  useEffect(() => {
    if (!initial) {
      formik.setFieldValue(
        attr,
        subjectList?.map(subject => subject.value).join("<>")
      );
    }
  }, [subjectList]);

  useEffect(() => {
    if (initial) {
      const subjectComponents = formik.values[attr]?.split("<>");
      let array = [];
      subjectComponents?.map(component => {
        if (component.includes("[")) {
          array = [...array, { content: "resource", value: component }];
        } else {
          array = [...array, { content: "text", value: component }];
        }
      });

      setSubjectList(array);
    }
  }, [formik.values[attr]]);

  return (
    <div className="flex flex-col w-full space-y-4">
      <div className="flex flex-col w-full space-y-4">
        {subjectList?.map((item, index) => {
          return (
            <div className="grid items-end grid-cols-8 gap-4" key={index}>
              <Select
                label="Content"
                name="content"
                id="pageName"
                className="col-span-3 flex items-start"
                options={contentOptions}
                value={contentOptions.find(page => page.value === item.content)}
                required={true}
                onChange={e => {
                  setInitial(false);
                  setSubjectList(
                    produce(draft => {
                      draft[index] = {
                        ...draft[index],
                        content: e.value,
                      };
                    })
                  );
                }}
              />

              {item.content === "text" ? (
                <Textarea
                  label="Enter your words"
                  value={item.value}
                  rows={1}
                  className="col-span-4 subject-text"
                  labelProps={{
                    helpIconProps: {
                      tooltipProps: {
                        content: "Please use <br /> for line breaks!",
                        position: "auto",
                      },
                    },
                  }}
                  onChange={e => {
                    setInitial(false);
                    setSubjectList(
                      produce(draft => {
                        draft[index] = {
                          ...draft[index],
                          value: e.target.value,
                        };
                      })
                    );
                  }}
                />
              ) : (
                <Select
                  label="Select option"
                  options={resourceOptions}
                  value={resourceOptions.find(
                    page => page.value === item.value
                  )}
                  className="col-span-4"
                  strategy="fixed"
                  onChange={e => {
                    setInitial(false);
                    setSubjectList(
                      produce(draft => {
                        draft[index] = {
                          ...draft[index],
                          value: e.value,
                        };
                      })
                    );
                  }}
                />
              )}
              <div className="col-span-1 text-right">
                <Button
                  onClick={() => {
                    setInitial(false);
                    setSubjectList(state => {
                      let newState = [...state];
                      newState.splice(index, 1);
                      return newState;
                    });
                  }}
                  className="p-0"
                  style="text"
                  disabled={subjectList.length === 1}
                  icon={Delete}
                  tooltipProps={{
                    content: "Delete",
                    position: "top",
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex justify-between w-full mt-3">
        <div className="flex items-center mr-2 space-x-2 overflow-hidden">
          {formik.values[attr] ? <Label>Preview:</Label> : null}
          <Typography style="body2">
            {formik.values[attr]?.split("<>").join("")}
          </Typography>
        </div>
        <Button
          style="link"
          label="Add Components"
          className="flex justify-end w-64"
          onClick={() => {
            setSubjectList(state => {
              return [
                ...state,
                {
                  content: "",
                },
              ];
            });
          }}
        />
      </div>
    </div>
  );
};

export default SubjectComponent;

import React, { useState, useEffect } from "react";
import { Container, Header } from "@bigbinary/neetoui/layouts";
import {
  Spinner,
  Button,
  Input,
  Label,
  Switch,
  Typography,
} from "@bigbinary/neetoui";
import { useFormik } from "formik";
import { showToastrError } from "common";
import { getAccount, updateAccount } from "apis/settings/accounts";
import SingleImageUploader from "components/Common/SingleImageUploader";
import { VALIDATION_SCHEMA, INITIAL_VALUE } from "./constants";

const NewAccount = ({ breadcrumbs }) => {
  const [accountDetail, setAccountDetail] = useState({});
  const [accountDetailLoad, setAccountDetailLoad] = useState(false);
  const [btnLoad, setBtnLoad] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: accountDetail ? accountDetail : INITIAL_VALUE,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: () => onUpdate(),
  });

  useEffect(() => {
    loadAccountDetailResponse();
  }, []);

  const loadAccountDetailResponse = async () => {
    try {
      setAccountDetailLoad(true);
      const response = await getAccount();
      setAccountDetail(response.data.account);
      setAccountDetailLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const onUpdate = async () => {
    try {
      setBtnLoad(true);
      const payload = {
        account: { ...formik.values, attachment_attributes: null },
      };

      await updateAccount(accountDetail.id, payload);
      setBtnLoad(false);
      loadAccountDetailResponse();
      formik.resetForm();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      setBtnLoad(false);
    }
  };

  if (accountDetailLoad) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <Container>
      <Header title="Accounts" breadcrumbs={breadcrumbs} />
      <form className="w-full space-y-6">
        <Input
          label="Name"
          name="name"
          onChange={formik.handleChange}
          value={formik.values.name}
          required={true}
          error={
            Boolean(formik.touched.name && formik.errors.name) &&
            formik.errors.name
          }
          autoFocus
          {...formik.getFieldProps("name")}
        />

        <Input
          label="Production Email"
          name="productionEmail"
          onChange={formik.handleChange}
          value={formik.values.productionEmail}
          error={
            Boolean(
              formik.touched.productionEmail && formik.errors.productionEmail
            ) && formik.errors.productionEmail
          }
        />

        <Input
          label="Basecamp Account Name"
          name="basecampAccountName"
          onChange={formik.handleChange}
          value={formik.values.basecampAccountName}
          error={
            Boolean(
              formik.touched.basecampAccountName &&
                formik.errors.basecampAccountName
            ) && formik.errors.basecampAccountName
          }
          {...formik.getFieldProps("basecampAccountName")}
        />

        <Input
          label="Subdomain"
          name="subdomain"
          onChange={formik.handleChange}
          value={formik.values.subdomain}
          required={true}
          error={
            Boolean(formik.touched.subdomain && formik.errors.subdomain) &&
            formik.errors.subdomain
          }
          {...formik.getFieldProps("subdomain")}
        />

        <div className="flex flex-col space-y-2">
          <Label>App Logo</Label>
          <SingleImageUploader
            name="App Logo"
            type="app_logo"
            formValue={formik.values}
            setFormValue={formik.setFieldValue}
          />
          <Typography style="body3" className="text-gray-500">
            This logo should be center aligned. Image resolution should be 200px
            width and 60px height.
          </Typography>
        </div>

        <div className="flex flex-col space-y-2">
          <Label>Large App Logo (for Templates)</Label>
          <SingleImageUploader
            name="Logo for Templates"
            type="large_app_logo"
            formValue={formik.values}
            setFormValue={formik.setFieldValue}
          />
        </div>

        <Switch
          label="Show Booking Form Detail"
          checked={formik.values.showBookingFormDetail}
          onChange={() => {
            formik.setFieldValue(
              "showBookingFormDetail",
              !formik.values.showBookingFormDetail
            );
          }}
        />

        <Switch
          label="Show RCI items Detail"
          checked={formik.values.showRciItemsDetail}
          onChange={() => {
            formik.setFieldValue(
              "showRciItemsDetail",
              !formik.values.showRciItemsDetail
            );
          }}
        />

        <Switch
          label="Send License With Invoice"
          checked={formik.values.sendLicenseWithInvoice}
          onChange={() => {
            formik.setFieldValue(
              "sendLicenseWithInvoice",
              !formik.values.sendLicenseWithInvoice
            );
          }}
        />

        <Switch
          label="Send Approval To Production"
          checked={formik.values.sendApprovalToProduction}
          onChange={() => {
            formik.setFieldValue(
              "sendApprovalToProduction",
              !formik.values.sendApprovalToProduction
            );
          }}
        />

        {formik.values.sendApprovalToProduction && (
          <Input
            label="Production Email (Approvals)"
            name="approvalEmail"
            required={formik.values.sendApprovalToProduction}
            onChange={formik.handleChange}
            value={formik.values.approvalEmail}
            error={
              Boolean(
                formik.touched.approvalEmail && formik.errors.approvalEmail
              ) && formik.errors.approvalEmail
            }
          />
        )}

        <div className="flex justify-end mt-3">
          <Button
            label="Save Changes"
            onClick={() => formik.handleSubmit()}
            loading={btnLoad}
          />
        </div>
      </form>
    </Container>
  );
};

export default NewAccount;

import React, { useState, useEffect } from "react";
import {
  getPhaseTypes,
  createPhaseType,
  updatePhaseType,
} from "apis/settings/phase_types";
import { getPhases } from "apis/settings/phases";
import { Container, Header } from "@bigbinary/neetoui/layouts";
import { Spinner, Button } from "@bigbinary/neetoui";
import { showToastrError } from "common";
import { getRandomNotFoundImage } from "common/helper";
import { isEmpty } from "ramda";
import MilestoneList from "./MilestoneList";
import MilestonePane from "./MilestonePane";
import EmptyState from "components/Common/EmptyState";

const Milestones = ({ title }) => {
  const [saveBtnLoad, setSaveBtnLoad] = useState(false);
  const [phaseTypeName, setPhaseTypeName] = useState("");
  const [newPhase, setNewPhase] = useState(false);
  const [phaseList, setPhaseList] = useState([
    {
      name: "",
      description: "",
      revision: "",
    },
  ]);
  const [selectedPhaseTypeId, setSelectedPhaseTypeId] = useState("");
  const [phaseTypeList, setPhaseTypeList] = useState([]);
  const [phaseTypeListLoad, setPhaseTypeListLoad] = useState(true);
  const [phaseListLoad, setPhaseListLoad] = useState(true);
  const [showPane, setShowPane] = useState(false);
  const [emptyImage, setEmptyImage] = useState();

  useEffect(() => {
    loadPhaseTypeList();
    setEmptyImage(getRandomNotFoundImage());
  }, []);

  const loadPhaseTypeList = async () => {
    try {
      const response = await getPhaseTypes();
      setPhaseTypeList(response.data.phaseTypes || []);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      setPhaseTypeListLoad(false);
    }
  };

  const createNewPhaseTypeEntry = async () => {
    try {
      setSaveBtnLoad(true);
      let payload = {
        phase_type: { name: phaseTypeName, phases_attributes: phaseList },
      };
      const response = await createPhaseType(payload);
      setSelectedPhaseTypeId(response.data.id);
      loadPhaseTypeList();
      loadPhaseList(response.data.id);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      setShowPane(false);
    }
  };

  const loadPhaseList = async selectedPhaseTypeId => {
    try {
      const response = await getPhases(selectedPhaseTypeId);
      setPhaseList(response.data.phases || []);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      setPhaseListLoad(false);
    }
  };

  const updatePhaseTypeEntry = async () => {
    try {
      setSaveBtnLoad(true);
      let payload = {
        phase_type: { name: phaseTypeName, phases_attributes: phaseList },
      };
      const response = await updatePhaseType(selectedPhaseTypeId, payload);
      loadPhaseList(response.data.id);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      setShowPane(false);
      loadPhaseTypeList();
    }
  };

  if (phaseListLoad || phaseTypeListLoad) {
    <div className="flex items-center justify-center h-screen">
      <Spinner />
    </div>;
  }

  return (
    <Container>
      <Header
        title={title}
        actionBlock={
          <Button
            label="Add Milestone"
            onClick={() => {
              setSelectedPhaseTypeId();
              setNewPhase(true);
              setPhaseTypeName("");
              setPhaseList([
                {
                  name: "",
                  revision: "",
                  description: "",
                },
              ]);
              setShowPane(true);
            }}
          />
        }
      />
      <div className="w-full" style={{ height: "calc(100vh - 124px)" }}>
        {!isEmpty(phaseTypeList) ? (
          <MilestoneList
            isLoading={phaseTypeListLoad}
            phaseTypeList={phaseTypeList}
            setPhaseList={setPhaseList}
            setNewPhase={setNewPhase}
            setSelectedPhaseTypeId={setSelectedPhaseTypeId}
            setPhaseTypeName={setPhaseTypeName}
            loadPhaseTypeList={loadPhaseTypeList}
            loadPhaseList={loadPhaseList}
            setShowPane={setShowPane}
          />
        ) : (
          <EmptyState
            image={emptyImage}
            title="No Milestones Found"
            description="We couldn’t find any milestones. Try creating one."
            primaryButtonProps={{
              label: "Add Milestone",
              onClick: () => {
                setSelectedPhaseTypeId();
                setNewPhase(true);
                setPhaseTypeName("");
                setPhaseList([
                  {
                    name: "",
                    revision: "",
                    description: "",
                  },
                ]);
                setShowPane(true);
              },
            }}
          />
        )}
      </div>
      <MilestonePane
        isOpen={showPane}
        onClose={() => setShowPane(false)}
        title={phaseTypeName ? "Edit Milestone" : "Add Milestone"}
        phaseTypeName={phaseTypeName}
        newPhase={newPhase}
        setPhaseTypeName={setPhaseTypeName}
        saveBtnLoad={saveBtnLoad}
        setSaveBtnLoad={setSaveBtnLoad}
        phaseList={phaseList}
        setPhaseList={setPhaseList}
        loadPhaseList={loadPhaseList}
        selectedPhaseTypeId={selectedPhaseTypeId}
        setSelectedPhaseTypeId={setSelectedPhaseTypeId}
        createNewPhaseTypeEntry={createNewPhaseTypeEntry}
        updatePhaseTypeEntry={updatePhaseTypeEntry}
      />
    </Container>
  );
};

export default Milestones;

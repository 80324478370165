import React, { useEffect, useState } from "react";
import { UsageOptionsTable } from "../Shared";

import AOS from "aos";
import "aos/dist/aos.css";

const UsageOptions = ({
  usages,
  ammountWithCurrency,
  selectedUsageId,
  setSelectedUsageId,
  revision,
  description,
  selectedUsageAmount,
}) => {
  const [openedItemId, setOpenedItemId] = useState();

  useEffect(() => {
    AOS.init();
  }, []);

  let getUsageLineItems = usage => [
    {
      option: {
        hasCheckbox: true,
        id: usage.id,
        hasTitle: true,
        title: usage.title,
        description: usage.terms,
        price: ammountWithCurrency(usage.fee),
      },
      table: {
        heads: [
          "Category",
          "Qty",
          "Territory",
          "Duration",
          "Exclusivity",
          "Cost",
        ],
        rows: usage.licenseItems.map(item => {
          return {
            values: [
              <>
                <strong>{item.category}</strong> <br />
                <div className="w-60">{item.detail}</div>
              </>,
              item.quantity,
              item.territory,
              <div className="whitespace-no-wrap" key={item.id}>
                {item.period}
              </div>,
              <div className="whitespace-no-wrap" key={item.id}>
                {item.exclusivityPeriod}
              </div>,
              item.fee === null ? (
                "Excluded"
              ) : Number(item.fee) === 0 ? (
                <div>Inclusive</div>
              ) : (
                <div className="whitespace-no-wrap" key={item.id}>
                  {ammountWithCurrency(item.fee)}
                </div>
              ),
            ],
          };
        }),
      },
    },
  ];

  return (
    <div className="flex flex-col items-start justify-between w-full py-12 lg:flex-row">
      <div
        className="static top-0 flex flex-col items-start justify-start w-full pr-0 lg:pr-12 lg:sticky lg:top-8 lg:w-1/3"
        style={{ fontFamily: "Modern Gothic" }}
      >
        <div className="flex flex-col items-start justify-start mb-8 lg:mb-0">
          <h2 className="text-xl font-medium">
            {revision?.licensesCount > 1 ? "Usage Options" : "Usage"}
          </h2>
          <p
            className="mt-6 text-base font-normal leading-5"
            dangerouslySetInnerHTML={{
              __html: description?.split("\n").join("<br />"),
            }}
          ></p>
        </div>
      </div>
      <div
        className="flex flex-col items-start justify-start w-full h-full lg:w-2/3"
        style={{ fontFamily: "Modern Gothic" }}
      >
        <div className="flex flex-col items-start justify-start w-full space-y-2">
          {usages.map((usage, index) => {
            return (
              <UsageOptionsTable
                key={index}
                usage={usage}
                data={getUsageLineItems(usage)}
                selectedUsageId={selectedUsageId}
                setSelectedUsageId={setSelectedUsageId}
                shouldBeOpened={usage.kind === "master"}
                openedItemId={openedItemId}
                setOpenedItemId={setOpenedItemId}
              />
            );
          })}
          {selectedUsageId && (
            <div className="flex flex-col items-center justify-between w-full px-6 py-4 mt-2 space-y-2 font-medium text-white bg-black lg:flex-row lg:space-y-0 rounded_container">
              <span className="text-base lg:text-xl">Usage Total</span>
              <span className="text-xl">
                {ammountWithCurrency(selectedUsageAmount)}
              </span>
            </div>
          )}
        </div>
        {revision?.licensesCount > 1 && !selectedUsageId && (
          <span
            className="mt-2 text-xs font-normal text-center text-black lg:mt-4 lg:text-left lg:text-sm"
            style={{ fontFamily: "Modern Gothic Mono" }}
          >
            If the project total is empty, please select a usage option
          </span>
        )}
      </div>
    </div>
  );
};

export default UsageOptions;

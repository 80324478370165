import React from "react";
import BookingFormMailerPreview from "./BookingFormMailerPreview";

const MailViewer = ({
  formik,
  docUrl,
  jobDetail,
  companyDetails,
  revision,
  defaultTemplateSetting,
  artistId,
  emailBackground,
}) => {
  return (
    <div className="w-3/5 overflow-y-scroll">
      <div
        className="p-6"
        style={{
          background: "#D6EEFF",
        }}
      >
        <BookingFormMailerPreview
          formik={formik}
          jobDetail={jobDetail}
          docUrl={docUrl}
          companyDetails={companyDetails}
          revision={revision}
          artistId={artistId}
          defaultTemplateSetting={defaultTemplateSetting}
          emailBackground={emailBackground}
        />
      </div>
    </div>
  );
};

export default MailViewer;

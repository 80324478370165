import React, { useState } from "react";
import { Delete } from "@bigbinary/neeto-icons";
import { Typography, Button, Alert } from "@bigbinary/neetoui";
import classNames from "classnames";
import { destroyRci } from "apis/jobs/rcis";
import { useHistory } from "react-router";
import { showToastrError } from "common";
import { artistModuleName } from "common/helper";
import { useUserState } from "contexts/user";

const RciList = ({ jobId, rciList, loadRciList, setRciDetail }) => {
  const { user } = useUserState();
  const history = useHistory();
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [deletingRciId, setDeletingRciId] = useState();
  const [btnLoad, setBtnLoad] = useState(false);
  const [rciId, setRciId] = useState(
    window.location.href.split("rcis" + "/")[1]
  );
  const artistModule = artistModuleName();

  const deleteRciEntry = async () => {
    try {
      setBtnLoad(true);
      await destroyRci(jobId, deletingRciId);
      setDeleteAlertOpen(false);
      setRciDetail();
      loadRciList();
      history.push(`/jobs/${jobId}/rcis`);
    } catch (error) {
      if (error.response.data.id) {
        history.push(`/jobs/${jobId}/rcis`);
        setRciDetail();
        loadRciList();
        setDeleteAlertOpen(false);
      }
    } finally {
      setBtnLoad(false);
    }
  };

  const rciCards = rci => {
    return (
      <div
        className={classNames(
          "relative p-4 bg-white border neeto-ui-shadow-xs rounded-md cursor-pointer hover:neeto-ui-border-gray-500 focus:neeto-ui-border-gray-500",
          {
            "neeto-ui-border-primary-600": rci.id === rciId,
            "neeto-ui-border-gray-200": rci.id !== rciId,
          }
        )}
        key={rci.id}
        onClick={() => {
          setRciId(rci.id);
          history.push(`/jobs/${jobId}/rcis/${rci.id}`);
        }}
      >
        <div className="flex items-center justify-between">
          <Typography style="h5" weight="semibold">
            {`${rci.serialNumber}(${rci.type[0]})`}
          </Typography>
          <div className="flex space-x-3">
            <Button
              style="link"
              label={rci.artistName}
              onClick={e => {
                e.stopPropagation();
                history.push(`/${artistModule.toLowerCase()}/${rci.artistId}`);
              }}
            />

            {(user.access === "admin" || user.access === "super_admin") && (
              <Button
                style="danger-text"
                icon={Delete}
                onClick={() => {
                  setDeleteAlertOpen(true);
                  setDeletingRciId(rci.id);
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full">
      <div className="flex flex-col space-y-2">
        <Typography style="h4" weight="semibold">
          RCI List
        </Typography>
        <div className="flex flex-col space-y-3">
          {rciList.map(rci => {
            return rciCards(rci);
          })}
        </div>
      </div>

      <Alert
        isOpen={deleteAlertOpen}
        isSubmitting={btnLoad}
        title="Delete RCI"
        message="Are you sure you want to delete this RCI?"
        onClose={() => setDeleteAlertOpen(false)}
        onSubmit={() => deleteRciEntry()}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, delete"
      />
    </div>
  );
};

export default RciList;

import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import RCI from "./Rci";
import Extras from "./Extras";
import Details from "./Details";
import Estimate from "./Estimate";
import Overview from "./Overview";
import Usagepage from "./Usage";
import LicensePage from "./License";
import PostProduction from "./PostProduction";
import BookingForm from "./BookingForm";
import Invoices from "./Invoices";
import Contracts from "./Contracts";
import Logs from "./Logs";
import Messages from "./Messages";
import NotFound from "components/Dashboard/NotFound";
import { EstimateContextProvider } from "./Context";

const JobDetails = () => {
  let { path } = useRouteMatch();

  return (
    <EstimateContextProvider>
      <div className="flex-grow">
        <Switch>
          <Route path={`${path}/overview`}>
            <Details />
          </Route>
          <Route path={`${path}/revisions/:revisionId/scope`}>
            <Overview />
          </Route>
          <Route path={`${path}/revisions/:revisionId/estimate`}>
            <Estimate />
          </Route>
          <Route path={`${path}/rcis`}>
            <RCI />
          </Route>
          <Route path={`${path}/rcis/:rciId`}>
            <RCI />
          </Route>
          <Route path={`${path}/post_production`}>
            <PostProduction />
          </Route>
          <Route path={`${path}/revisions/:revisionId/extras`}>
            <Extras />
          </Route>
          <Route path={`${path}/revisions/:revisionId/usage`}>
            <Usagepage />
          </Route>
          <Route path={`${path}/revisions/:revisionId/license`}>
            <LicensePage />
          </Route>
          <Route path={`${path}/revisions/:revisionId/booking_form`}>
            <BookingForm />
          </Route>
          <Route path={`${path}/invoices`}>
            <Invoices />
          </Route>
          <Route path={`${path}/resources`}>
            <Contracts />
          </Route>
          <Route path={`${path}/messages`}>
            <Messages />
          </Route>
          <Route path={`${path}/logs`}>
            <Logs />
          </Route>
          <Route path={`${path}`} component={NotFound} />
        </Switch>
      </div>
    </EstimateContextProvider>
  );
};

export default JobDetails;

import axios from "axios";

const getRevisionDeliveryScopesUrl = (revisionId, id) => {
  if (id) {
    return `/api/v1/job_module/estimate_revisions/${revisionId}/revision_delivery_scopes/${id}`;
  }

  return `/api/v1/job_module/estimate_revisions/${revisionId}/revision_delivery_scopes`;
};

export const getRevisionDeliveryScopes = revisionId => {
  return axios.get(getRevisionDeliveryScopesUrl(revisionId));
};

export const createRevisionDeliveryScope = (revisionId, payload) => {
  return axios.post(getRevisionDeliveryScopesUrl(revisionId), payload);
};

export const updateRevisionDeliveryScope = (revisionId, id, payload) => {
  return axios.put(getRevisionDeliveryScopesUrl(revisionId, id), payload);
};

export const destroyRevisionDeliveryScope = (revisionId, id) => {
  return axios.delete(getRevisionDeliveryScopesUrl(revisionId, id));
};

export const updateRevisionDeliveryScopePosition = (
  revisionId,
  id,
  type,
  difference
) => {
  return axios.put(
    `${getRevisionDeliveryScopesUrl(
      revisionId,
      id
    )}/change_position?type=${type}&difference=${difference}`
  );
};

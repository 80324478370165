import axios from "axios";
import { DEFAULT_PAGE_SIZE } from "common/helper";

const getAccountsUrl = (id, searchParams, page) => {
  if (id) {
    return `/api/v1/super_admins/accounts/${id}`;
  }

  if (searchParams) {
    let searchQuery = `q[name_i_cont_any]=${searchParams}`;
    return `/api/v1/super_admins/accounts?${searchQuery}&page=${page}&per_page=${DEFAULT_PAGE_SIZE}`;
  }

  return `/api/v1/super_admins/accounts?page=${page}&per_page=${DEFAULT_PAGE_SIZE}`;
};

export const getAccounts = (searchParams, page = 1) => {
  return axios.get(getAccountsUrl(null, searchParams, page));
};

export const getAccount = id => {
  return axios.get(getAccountsUrl(id));
};

export const createAccount = payload => {
  return axios.post(getAccountsUrl(), payload);
};

export const updateAccount = (id, payload) => {
  return axios.put(getAccountsUrl(id), payload);
};

export const destroyAccount = id => {
  return axios.delete(getAccountsUrl(id));
};

import React from "react";
import classnames from "classnames";
import { useHistory, useLocation } from "react-router-dom";
import { artistModuleName } from "common/helper";

export default function NavItem({ icon, link, title, onClick }) {
  const history = useHistory();
  const location = useLocation();

  const isActive = () => {
    let windowUrl = window.location.href.split("/");

    if (title === artistModuleName() && link === `/${artistModuleName().toLowerCase()}`) {
      return windowUrl.includes(artistModuleName().toLowerCase()) && !windowUrl.includes("jobs");
    } else if (title === "Post Production") {
      return (
        windowUrl.includes("post_production") && !windowUrl.includes("jobs")
      );
    } else if (title === "Invoices" && link === "/invoices") {
      return windowUrl.includes("invoices") && !windowUrl.includes("jobs");
    } else if (title === "Dashboard") {
      return link === location.pathname;
    } else if (title === "CRM") {
      return windowUrl.includes("crm") && !windowUrl.includes("settings");
    } else {
      return windowUrl.includes(title.toLowerCase()?.replace(" ", "_"));
    }
  };

  return (
    <div className="w-full my-1">
      <div
        className={classnames(
          [
            "flex flex-row items-center justify-between w-full p-2 rounded-md cursor-pointer transition-all duration-300 ease-in-out",
          ],
          {
            "neeto-ui-text-gray-900 neeto-ui-bg-gray-300 hover:neeto-ui-bg-gray-300": isActive(),
            "neeto-ui-text-gray-800 hover:neeto-ui-bg-gray-200": !isActive(),
          }
        )}
        onClick={() => {
          onClick
            ? onClick()
            : history.push(link, { category: "personalised" })
        }}
      >
        <div className="flex items-center">
          {icon && icon}
          <p className="ml-2 font-semibold">{title}</p>
        </div>
        {title === "My Jobs" && false && (
          <div
            className="w-3 h-3 ml-2 rounded-full"
            style={{
              background: "#FC5555",
            }}
          ></div>
        )}
      </div>
    </div>
  );
}

import * as Yup from "yup";
import { MAX_STRING_LENGTH } from "common/constants";

export const SPECIAL_CONDITION_VALIDATION_SCHEMA = Yup.object({
  name: Yup.string()
    .required("Name can't be empty.")
    .max(
      MAX_STRING_LENGTH,
      `Name must be at most ${MAX_STRING_LENGTH} characters.`
    ),
  condition: Yup.string().required("Condition can't be empty."),
});

export const SPECIAL_CONDITION_INITIAL_VALUE = {
  name: "",
  condition: "",
};

export const TERMS_AND_CONDITION_VALIDATION_SCHEMA = Yup.object({
  section: Yup.string()
    .required("Section can't be empty.")
    .max(
      MAX_STRING_LENGTH,
      `Section must be at most ${MAX_STRING_LENGTH} characters.`
    ),
});

export const TERMS_AND_CONDITION_INITIAL_VALUE = {
  section: "",
  description: "",
};

export const TEMPLATE_SETTINGS_VALIDATION_SCHEMA = Yup.object({
  default: Yup.bool(),
  jobTypeId: Yup.string().when("default", {
    is: true,
    then: Yup.string().nullable(),
    otherwise: Yup.string().required("Job type can't be empty."),
  }),
});

export const CHAT_TEMPLATE_INITIAL_VALUE = {
  name: "",
  body: "",
};

export const CHAT_TEMPLATE_VALIDATION_SCHEMA = Yup.object({
  name: Yup.string()
    .required("Name can't be empty.")
    .max(
      MAX_STRING_LENGTH,
      `Name must be at most ${MAX_STRING_LENGTH} characters.`
    ),
  body: Yup.string().required("Body can't be empty."),
});

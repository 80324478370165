import * as dayjs from "dayjs";

Array.range = (start, end) =>
  Array.from({ length: end - start + 1 }, (v, k) => k + start);

export const YEARS_LIST = Array.range(2013, dayjs().year() - 1).map(year => {
  return { label: year, value: year };
});

export const CHART_LIST = [
  {
    label: "Weekly Invoice Targets",
    value: "weekly_invoice_targets",
  },
  {
    label: "Weekly Job Approval Targets",
    value: "weekly_job_approval_targets",
  },
  {
    label: "Weekly Job Enquiry",
    value: "weekly_job_enquiry",
  },
  {
    label: "Weekly Action Tags",
    value: "weekly_action_tags",
  },
];

// Date.prototype.getWeek = function () {
//   var target = new Date(this.valueOf());
//   var dayNr = (this.getDay() + 6) % 7;
//   target.setDate(target.getDate() - dayNr + 3);
//   var jan4 = new Date(target.getFullYear(), 0, 4);
//   var dayDiff = (target - jan4) / 86400000;
//
//   if (new Date(target.getFullYear(), 0, 1).getDay() < 5) {
//     return 1 + Math.ceil(dayDiff / 7);
//   } else {
//     return Math.ceil(dayDiff / 7);
//   }
// };
//
// function getDateRangeOfWeek(weekNo, year) {
//   var date = new Date(year, 1, 1);
//   var numOfdaysPastSinceLastMonday = eval(date.getDay() - 1);
//   date.setDate(date.getDate() - numOfdaysPastSinceLastMonday);
//   var weekNoToday = date.getWeek();
//   var weeksInTheFuture = eval(weekNo - weekNoToday);
//   date.setDate(date.getDate() + eval(7 * weeksInTheFuture));
//   var rangeIsFrom =
//     date.getFullYear() + "-" + eval(date.getMonth() + 1) + "-" + date.getDate();
//   date.setDate(date.getDate() + 6);
//   var rangeIsTo =
//     date.getFullYear() + "-" + eval(date.getMonth() + 1) + "/" + date.getDate();
//   return (
//     dayjs(rangeIsFrom).format("MMMM DD, YYYY") +
//     " - " +
//     dayjs(rangeIsTo).format("MMMM DD, YYYY")
//   );
// }

function getWeekRange(weekNo, yearNo) {
  let firstDayofYear = new Date(yearNo, 0, 1);

  if (firstDayofYear.getDay() > 4) {
    let weekStart = new Date(
      yearNo,
      0,
      1 + (weekNo - 1) * 7 - firstDayofYear.getDay() + 8
    );
    let weekEnd = new Date(
      yearNo,
      0,
      1 + (weekNo - 1) * 7 - firstDayofYear.getDay() + 8 + 6
    );
    return (
      dayjs(weekStart).format("MMMM DD, YYYY") +
      " - " +
      dayjs(weekEnd).format("MMMM DD, YYYY")
    );
  } else {
    let weekStart = new Date(
      yearNo,
      0,
      1 + (weekNo - 1) * 7 - firstDayofYear.getDay() + 1
    );
    let weekEnd = new Date(
      yearNo,
      0,
      1 + (weekNo - 1) * 7 - firstDayofYear.getDay() + 1 + 6
    );
    return (
      dayjs(weekStart).format("MMMM DD, YYYY") +
      " - " +
      dayjs(weekEnd).format("MMMM DD, YYYY")
    );
  }
}

// https://weeknumber.com/how-to/ruby
export const DEFAULT_CHART_DATA = {
  series: [],
  options: {
    colors: ["#EBFF00", "#008FFB"],
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      show: false,
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return `
          <div>
            <div class="p-2 bg-gray-200 font-semibold">${getWeekRange(
              dataPointIndex + 1,
              w.globals.initialSeries[seriesIndex].name
            )}</div>
            <div class="p-2"><span class="font-semibold">${
              w.globals.initialSeries[seriesIndex].name
            }:</span> ${series[seriesIndex][dataPointIndex]}</div>
          </div>
        `;
      },
    },
    fill: {
      type: "gradient",
      fillOpacity: 0.6,
    },
    annotations: {
      xaxis: [
        {
          x: 10,
          strokeDashArray: 0,
          borderColor: "#4558f9",
          label: {
            style: {
              color: "#ffffff",
              background: "#4558f9",
            },
            orientation: "horizontal",
            text: "Current Week",
          },
        },
      ],
    },
  },
};

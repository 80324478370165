import * as Yup from "yup";

export const PROJECT_SCOPE_VALIDATION_SCHEMA = Yup.object({
  name: Yup.string().required("Name can't be empty."),
});

export const PROJECT_SCOPE_INITIAL_VALUE = {
  name: "",
};

export const DELIVERY_SCOPE_VALIDATION_SCHEMA = Yup.object({
  name: Yup.string().required("Name can't be empty."),
});

export const DELIVERY_SCOPE_INITIAL_VALUE = {
  name: "",
};

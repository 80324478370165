import React, { useState, useEffect } from "react";
import { Button, Modal, Typography, Table, Spinner } from "@bigbinary/neetoui";
import { isEmpty } from "ramda";
import EmptyState from "components/Common/EmptyState";
import { getRandomNotFoundImage } from "common/helper";

const TagRemovalModal = ({
  isOpen,
  onClose,
  isLoading,
  openedActionTags,
  destroyContactActivityLogsResponse,
  setBulkTagIds,
  bulkTagIds,
  removalLoading,
}) => {
  const [emptyImage, setEmptyImage] = useState();
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  useEffect(() => {
    setEmptyImage(getRandomNotFoundImage());
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="large">
      <Modal.Header>
        <Typography style="h2" weight="semibold">
          Tag Removal Confirm
        </Typography>
      </Modal.Header>

      <Modal.Body>
        <Typography style="body2" weight="medium">
          Please select action tags which you want to destroy. Once done you are
          going to destroy these tags for the the respective contacts. Once
          completed, this action cannot be undone.
        </Typography>
        {isLoading ? (
          <div className="flex items-center justify-center w-full h-full">
            <Spinner />
          </div>
        ) : (
          <>
            {!isEmpty(openedActionTags) ? (
              <div className="h-64 mt-3">
                <Table
                  columnData={[
                    {
                      title: "Action Tags",
                      dataIndex: "name",
                      key: "name",
                    },
                  ]}
                  rowData={openedActionTags}
                  fixedHeight
                  rowSelection
                  selectedRowKeys={bulkTagIds}
                  onRowSelect={selectedRowKeys =>
                    setBulkTagIds(selectedRowKeys)
                  }
                  currentPageNumber={pageIndex}
                  defaultPageSize={pageSize}
                  handlePageChange={(page, pageSize) => {
                    setPageIndex(page);
                    setPageSize(pageSize);
                  }}
                />
              </div>
            ) : (
              <EmptyState
                image={emptyImage}
                title="Open Action Tag not found."
                description="We couldn’t find any opened action tags for the current filter."
              />
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="space-x-3">
        <Button
          label="Save changes"
          onClick={() => destroyContactActivityLogsResponse()}
          loading={removalLoading}
        />
        <Button style="text" label="Cancel" onClick={() => onClose()} />
      </Modal.Footer>
    </Modal>
  );
};

export default TagRemovalModal;

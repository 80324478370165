import axios from "axios";

const getMetricsUrl = id => {
  if (id) {
    return `/api/v1/settings/metrics/${id}`;
  }

  return `/api/v1/settings/metrics`;
};

export const getMetrics = () => {
  return axios.get(getMetricsUrl());
};

export const createMetric = payload => {
  return axios.post(getMetricsUrl(), payload);
};

export const updateMetric = (id, payload) => {
  return axios.put(getMetricsUrl(id), payload);
};

export const destroyMetric = id => {
  return axios.delete(getMetricsUrl(id));
};

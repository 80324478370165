import axios from "axios";

export const getJob = id => {
  return axios.get(`/api/v1/job_module/booking_form/jobs/${id}`);
};

export const sendFormToArtist = (jobId, payload) => {
  return axios.post(
    `/api/v1/job_module/jobs/${jobId}/send_booking_forms`,
    payload
  );
};

export const updateIssueDate = (jobId, artistId, payload) => {
  return axios.put(
    `/api/v1/job_module/booking_form/jobs/${jobId}/artists_jobs/1?artist_id=${artistId}`,
    payload
  );
};

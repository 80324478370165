import * as Yup from "yup";
import { User, Suitcase, Report } from "@bigbinary/neeto-icons";

export const INITIAL_VALUE = {
  firstName: "",
  lastName: "",
  title: "",
  email: "",
  access: "",
  locationId: "",
  phoneNumber: "",
  homeAddress: "",
  bankDetails: "",
  personalEmail: "",
};

export const VALIDATION_SCHEMA = Yup.object({
  firstName: Yup.string().required("First Name can't be empty"),
  lastName: Yup.string().required("Last Name can't be empty"),
  title: Yup.string().required("Title can't be empty"),
  email: Yup.string().email("Invalid email").required("Email can't be empty"),
  access: Yup.string()
    .oneOf(["admin", "team", "super_admin"])
    .required("Access can't be empty"),
  locationId: Yup.string().required("Location can't be empty"),
  phoneNumber: Yup.string().required("Phone Number can't be empty"),
  homeAddress: Yup.string().nullable(),
  bankDetails: Yup.string().nullable(),
  personalEmail: Yup.string().email("Invalid email").nullable(),
});

export const COUNTRY_LIST = [
  {
    label: "USA",
    value: "USA",
  },
  {
    label: "UK",
    value: "UK",
  },
  {
    label: "AU",
    value: "AU",
  },
];

export const ACCESS_LIST = [
  { label: "Admin", value: "admin" },
  { label: "Team", value: "team" },
];

export const STAFF_DETAILS_TABS = {
  Profile: {
    icon: User,
    label: "Profile",
  },
  Jobs: {
    icon: Suitcase,
    label: "Jobs",
  },
  Report: {
    icon: Report,
    label: "Report",
  },
};

import React, { useState, useEffect, useRef } from "react";
import { Warning, Plus, Delete, Edit } from "@bigbinary/neeto-icons";
import {
  Radio,
  Switch,
  Callout,
  Textarea,
  Button,
  Typography,
  Spinner,
  Modal,
  Alert,
} from "@bigbinary/neetoui";
import { Header, Container } from "@bigbinary/neetoui/layouts";
import * as dayjs from "dayjs";
import * as R from "ramda";
import { useParams } from "react-router-dom";
import { getJob } from "apis/jobs/post_production";
import { updateJob } from "apis/jobs/jobs";
import { getEstimateRevisions } from "apis/jobs/estimate_revisions";
import {
  createPostProductionNote,
  updatePostProductionNote,
  destroyPostProductionNote,
} from "apis/post_productions/post_production_notes";
import { showToastrError } from "common";
import { sortedByCreation } from "common/helper";
import { useFormik } from "formik";
import useDebounce from "common/debounce";
import Tabs from "../Tabs";
import { jobWithNameNumber, getRevisionId } from "./../../common/helper";

const PostProduction = () => {
  const { id } = useParams();
  const inputRef = useRef(null);
  const [inSituShotsRequirement, setInSituShotsRequirement] = useState("");
  const debouncedInSituShotsRequirement = useDebounce(
    inSituShotsRequirement,
    500
  );
  const [job, setJob] = useState({});
  const [jobDetailsLoad, setJobDetailsLoad] = useState(true);
  const [postProductionNotes, setPostProductionNotes] = useState([]);
  const [postProductionNoteId, setPostProductionNoteId] = useState("");
  const [postProductionNote, setPostProductionNote] = useState("");
  const [addProductionNote, setAddProductionNote] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [estimateRevisions, setEstimateRevisions] = useState([]);
  const [estimateRevisionsLoad, setEstimateRevisionsLoad] = useState(true);
  const [initial, setInitial] = useState(true);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: job,
    onSubmit: () => onUpdate(),
  });

  useEffect(() => {
    loadJobDetailResponse();
    loadEstimateRevisionsResponse();
  }, [id]);

  useEffect(() => {
    if (!initial) {
      onUpdate();
    }
  }, [formik.values, debouncedInSituShotsRequirement]);

  const loadJobDetailResponse = async () => {
    try {
      const response = await getJob(id);
      setJob(response.data.job);
      setInSituShotsRequirement(response.data.job.inSituShotsRequirement);
      setPostProductionNotes(response.data.job.postProductionNotes);
      setJobDetailsLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadEstimateRevisionsResponse = async () => {
    try {
      const response = await getEstimateRevisions(id);
      setEstimateRevisions(response.data.estimateRevisions);
      setEstimateRevisionsLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const onUpdate = async () => {
    try {
      let payload = {
        job: {
          ...formik.values,
          inSituShotsRequirement: inSituShotsRequirement,
        },
      };

      await updateJob(id, payload);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const onPostProductionNoteCreate = async () => {
    try {
      await createPostProductionNote(id, {
        post_production_note: { note: postProductionNote },
      });
      setAddProductionNote(false);
      setPostProductionNote();
      loadJobDetailResponse();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const onPostProductionNoteUpdate = async () => {
    try {
      await updatePostProductionNote(id, postProductionNoteId, {
        post_production_note: { note: postProductionNote },
      });
      setAddProductionNote(false);
      setPostProductionNote();
      loadJobDetailResponse();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const onPostProductionNoteDestroy = async () => {
    try {
      await destroyPostProductionNote(id, postProductionNoteId);
      setDeleteAlertOpen(false);
      loadJobDetailResponse();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  if (jobDetailsLoad || estimateRevisionsLoad) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <Container isHeaderFixed>
      <Header
        title="Post Production"
        breadcrumbs={[
          { text: "Jobs", link: "/jobs" },
          { text: jobWithNameNumber(job), link: `/jobs/${id}/overview` },
        ]}
      />

      <Tabs jobId={id} revisionId={getRevisionId(estimateRevisions)} />

      <div
        className="flex w-full overflow-hidden"
        style={{ height: "calc(100vh - 134px)" }}
      >
        <div className="w-1/2 p-6 space-y-3 overflow-y-scroll border-r neeto-ui-bg-gray-100 neeto-ui-border-gray-300">
          {job.note && (
            <Callout style="warning" icon={Warning}>
              <div
                dangerouslySetInnerHTML={{
                  __html: job.note.split("\n").join("<br/>"),
                }}
              />
            </Callout>
          )}
          <div className="flex flex-col p-4 space-y-3 border neeto-ui-bg-white neeto-ui-rounded-lg neeto-ui-border-gray-300">
            <Typography style="h5" weight="semibold">
              Do we want final artwork or in-situ shots?
            </Typography>
            <Radio className="flex-row">
              <Radio.Item
                label="Yes"
                checked={formik.values.inSituShots === true}
                name="inSituShots"
                onFocus={() => setInitial(false)}
                onChange={() => formik.setFieldValue("inSituShots", true)}
              />
              <Radio.Item
                label="No"
                checked={formik.values.inSituShots === false}
                name="inSituShots"
                onFocus={() => setInitial(false)}
                onChange={() => {
                  formik.setFieldValue("inSituShots", false);
                  setInSituShotsRequirement();
                  formik.setFieldValue("inSituShotsFilled", false);
                }}
              />
              <Radio.Item
                label="Completed"
                checked={formik.values.inSituShots === null}
                name="inSituShots"
                onFocus={() => setInitial(false)}
                onChange={() => formik.setFieldValue("inSituShots", null)}
              />
            </Radio>
          </div>
          {formik.values.inSituShots && (
            <div className="flex flex-col p-4 space-y-3 border neeto-ui-bg-white neeto-ui-rounded-lg neeto-ui-border-gray-300">
              <div className="flex flex-row items-center gap-2">
                <Switch
                  checked={formik.values.inSituShotsFilled}
                  onFocus={() => setInitial(false)}
                  onChange={() =>
                    formik.setFieldValue(
                      "inSituShotsFilled",
                      !formik.values.inSituShotsFilled
                    )
                  }
                />
                <Typography style="h5" weight="semibold">
                  Has this been obtained and filed?
                </Typography>
              </div>
              <Textarea
                autoFocus
                onFocus={() => setInitial(false)}
                value={inSituShotsRequirement}
                onChange={e => setInSituShotsRequirement(e.target.value)}
                label="What is required?"
                name="inSituShotsRequirement"
              />
            </div>
          )}
          <div className="flex flex-col p-4 space-y-3 border neeto-ui-bg-white neeto-ui-rounded-lg neeto-ui-border-gray-300">
            <Typography style="h5" weight="semibold">
              Should it be on Socials?
            </Typography>
            <Radio className="flex-row">
              <Radio.Item
                label="Yes"
                checked={formik.values.socials === true}
                name="socials"
                onFocus={() => setInitial(false)}
                onChange={() => formik.setFieldValue("socials", true)}
              />
              <Radio.Item
                label="No"
                checked={formik.values.socials === false}
                name="socials"
                onFocus={() => setInitial(false)}
                onChange={() => formik.setFieldValue("socials", false)}
              />
              <Radio.Item
                label="Completed"
                checked={formik.values.socials === null}
                name="socials"
                onFocus={() => setInitial(false)}
                onChange={() => formik.setFieldValue("socials", null)}
              />
            </Radio>
          </div>
          <div className="flex flex-col p-4 space-y-3 border neeto-ui-bg-white neeto-ui-rounded-lg neeto-ui-border-gray-300">
            <Typography style="h5" weight="semibold">
              Should it be in Folios?
            </Typography>
            <Radio className="flex-row">
              <Radio.Item
                label="Yes"
                checked={formik.values.folios === true}
                name="folios"
                onFocus={() => setInitial(false)}
                onChange={() => formik.setFieldValue("folios", true)}
              />
              <Radio.Item
                label="No"
                checked={formik.values.folios === false}
                name="folios"
                onFocus={() => setInitial(false)}
                onChange={() => formik.setFieldValue("folios", false)}
              />
              <Radio.Item
                label="Completed"
                checked={formik.values.folios === null}
                name="folios"
                onFocus={() => setInitial(false)}
                onChange={() => formik.setFieldValue("folios", null)}
              />
            </Radio>
          </div>
          <div className="flex flex-col p-4 space-y-3 border neeto-ui-bg-white neeto-ui-rounded-lg neeto-ui-border-gray-300">
            <Typography style="h5" weight="semibold">
              Is this is a Showpiece?
            </Typography>
            <Radio className="flex-row">
              <Radio.Item
                label="Yes"
                checked={formik.values.showpiece === true}
                name="showpiece"
                onFocus={() => setInitial(false)}
                onChange={() => formik.setFieldValue("showpiece", true)}
              />
              <Radio.Item
                label="No"
                checked={formik.values.showpiece === false}
                name="showpiece"
                onFocus={() => setInitial(false)}
                onChange={() => formik.setFieldValue("showpiece", false)}
              />
              <Radio.Item
                label="Completed"
                checked={formik.values.showpiece === null}
                name="showpiece"
                onFocus={() => setInitial(false)}
                onChange={() => formik.setFieldValue("showpiece", null)}
              />
            </Radio>
          </div>
          <div className="flex flex-col p-4 space-y-3 border neeto-ui-bg-white neeto-ui-rounded-lg neeto-ui-border-gray-300">
            <Typography style="h5" weight="semibold">
              Does a Challenge Coin or Follow-Up need to happen?
            </Typography>
            <Radio className="flex-row">
              <Radio.Item
                label="Yes"
                checked={formik.values.challengeCoin === true}
                name="challengeCoin"
                onFocus={() => setInitial(false)}
                onChange={() => formik.setFieldValue("challengeCoin", true)}
              />
              <Radio.Item
                label="No"
                checked={formik.values.challengeCoin === false}
                name="challengeCoin"
                onFocus={() => setInitial(false)}
                onChange={() => formik.setFieldValue("challengeCoin", false)}
              />
              <Radio.Item
                label="Completed"
                checked={formik.values.challengeCoin === null}
                name="challengeCoin"
                onFocus={() => setInitial(false)}
                onChange={() => formik.setFieldValue("challengeCoin", null)}
              />
            </Radio>
          </div>
          <div className="flex flex-col p-4 space-y-3 border neeto-ui-bg-white neeto-ui-rounded-lg neeto-ui-border-gray-300">
            <div className="flex flex-col space-y-1">
              <Typography style="h5" weight="semibold">
                Claim royalty via Copyright Agency?
              </Typography>
              <Typography style="body2" className="neeto-ui-text-gray-700">
                Book, magazine, editorial or online/print news article
                illustrations only
              </Typography>
            </div>
            <Radio className="flex-row">
              <Radio.Item
                label="Yes"
                checked={formik.values.claimRoyalty === true}
                name="claimRoyalty"
                onFocus={() => setInitial(false)}
                onChange={() => formik.setFieldValue("claimRoyalty", true)}
              />
              <Radio.Item
                label="No"
                checked={formik.values.claimRoyalty === false}
                name="claimRoyalty"
                onFocus={() => setInitial(false)}
                onChange={() => formik.setFieldValue("claimRoyalty", false)}
              />
              <Radio.Item
                label="Completed"
                checked={formik.values.claimRoyalty === null}
                name="claimRoyalty"
                onFocus={() => setInitial(false)}
                onChange={() => formik.setFieldValue("claimRoyalty", null)}
              />
            </Radio>
          </div>
          <div className="flex flex-col p-4 space-y-3 border neeto-ui-bg-white neeto-ui-rounded-lg neeto-ui-border-gray-300">
            <Typography style="h5" weight="semibold">
              Has a screenshot of final art been uploaded?
            </Typography>
            <Radio
              value={formik.values.finalArtScreenshot}
              className="flex-row"
            >
              <Radio.Item
                label="Yes"
                checked={formik.values.finalArtScreenshot === true}
                name="screenshot"
                onFocus={() => setInitial(false)}
                onChange={() =>
                  formik.setFieldValue("finalArtScreenshot", true)
                }
              />
              <Radio.Item
                label="No"
                checked={formik.values.finalArtScreenshot === false}
                name="screenshot"
                onFocus={() => setInitial(false)}
                onChange={() =>
                  formik.setFieldValue("finalArtScreenshot", false)
                }
              />
              <Radio.Item
                label="NA"
                checked={formik.values.finalArtScreenshot === null}
                name="screenshot"
                onFocus={() => setInitial(false)}
                onChange={() =>
                  formik.setFieldValue("finalArtScreenshot", null)
                }
              />
            </Radio>
          </div>
          <div className="flex flex-col p-4 space-y-3 border neeto-ui-bg-white neeto-ui-rounded-lg neeto-ui-border-gray-300">
            <Typography style="h5" weight="semibold">
              Is Finished Art Filed?
            </Typography>
            <Radio className="flex-row">
              <Radio.Item
                label="Yes"
                checked={formik.values.finishedArtFiled === true}
                name="finishedArtFiled"
                onFocus={() => setInitial(false)}
                onChange={() => formik.setFieldValue("finishedArtFiled", true)}
              />
              <Radio.Item
                label="No"
                checked={formik.values.finishedArtFiled === false}
                name="finishedArtFiled"
                onFocus={() => setInitial(false)}
                onChange={() => formik.setFieldValue("finishedArtFiled", false)}
              />
              <Radio.Item
                label="NA"
                checked={formik.values.finishedArtFiled === null}
                name="finishedArtFiled"
                onFocus={() => setInitial(false)}
                onChange={() => formik.setFieldValue("finishedArtFiled", null)}
              />
            </Radio>
          </div>
          <div className="flex flex-col p-4 space-y-3 border neeto-ui-bg-white neeto-ui-rounded-lg neeto-ui-border-gray-300">
            <Typography style="h5" weight="semibold">
              Clearance Status
            </Typography>
            <Radio className="flex-row">
              <Radio.Item
                label="Seeking"
                checked={formik.values.clearanceStatus === "seeking"}
                name="clearanceStatus"
                onFocus={() => setInitial(false)}
                onChange={() =>
                  formik.setFieldValue("clearanceStatus", "seeking")
                }
              />
              <Radio.Item
                label="Allowed"
                checked={formik.values.clearanceStatus === "allowed"}
                name="clearanceStatus"
                onFocus={() => setInitial(false)}
                onChange={() =>
                  formik.setFieldValue("clearanceStatus", "allowed")
                }
              />
              <Radio.Item
                label="Assumed"
                checked={formik.values.clearanceStatus === "assumed"}
                name="clearanceStatus"
                onFocus={() => setInitial(false)}
                onChange={() =>
                  formik.setFieldValue("clearanceStatus", "assumed")
                }
              />
              <Radio.Item
                label="Denied"
                checked={formik.values.clearanceStatus === "denied"}
                name="clearanceStatus"
                onFocus={() => setInitial(false)}
                onChange={() =>
                  formik.setFieldValue("clearanceStatus", "denied")
                }
              />
            </Radio>
          </div>
        </div>
        <div className="w-1/2 p-6 space-y-6 overflow-y-scroll neeto-ui-bg-white">
          <div className="flex items-center justify-between w-full">
            <Typography style="h3" weight="semibold">
              Notes
            </Typography>
            <Button
              label="Add Note"
              style="link"
              icon={Plus}
              iconPosition="left"
              onClick={() => {
                setPostProductionNoteId();
                setPostProductionNote();
                setAddProductionNote(true);
              }}
            />
          </div>
          {!R.isEmpty(postProductionNotes) ? (
            <div className="flex flex-col space-y-3">
              {sortedByCreation(postProductionNotes)
                .reverse()
                .map(note => {
                  return (
                    <div className="grid grid-cols-5 gap-6" key={note.id}>
                      <div className="flex items-center">
                        <Typography style="body2" weight="semibold">
                          {dayjs(note.createdAt).format("DD/MM/YYYY")}
                        </Typography>
                      </div>
                      <div className="flex items-center col-span-3">
                        <Typography style="body2">{`${note.note
                          .split("\n")
                          .join(" - ")}`}</Typography>
                      </div>
                      <div className="flex items-center justify-end space-x-2">
                        <Button
                          style="text"
                          icon={Edit}
                          size="small"
                          onClick={() => {
                            setAddProductionNote(true);
                            setPostProductionNote(note.note);
                            setPostProductionNoteId(note.id);
                          }}
                        />
                        <Button
                          style="danger-text"
                          icon={Delete}
                          size="small"
                          onClick={() => {
                            setDeleteAlertOpen(true);
                            setPostProductionNoteId(note.id);
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          ) : (
            <div className="flex items-center justify-center w-full h-16">
              <Typography style="body2">No notes added yet</Typography>
            </div>
          )}
        </div>

        <Modal
          isOpen={addProductionNote}
          onClose={() => setAddProductionNote(false)}
          initialFocusRef={inputRef}
        >
          <Modal.Header>
            <Typography style="h2" weight="semibold">
              {postProductionNoteId ? "Edit Note" : "Add Note"}
            </Typography>
          </Modal.Header>

          <Modal.Body>
            <Textarea
              ref={inputRef}
              value={postProductionNote}
              onChange={e => setPostProductionNote(e.target.value)}
              label="Note"
              rows={3}
              name="note"
            />
          </Modal.Body>

          <Modal.Footer className="items-center space-x-2">
            <Button
              label="Save changes"
              onClick={() => {
                if (postProductionNoteId) {
                  onPostProductionNoteUpdate();
                } else {
                  onPostProductionNoteCreate();
                }
              }}
            />
            <Button
              style="text"
              label="Cancel"
              onClick={() => setAddProductionNote(false)}
            />
          </Modal.Footer>
        </Modal>

        <Alert
          isOpen={deleteAlertOpen}
          title="Delete Note"
          message="Are you sure you want to delete this note?"
          onClose={() => setDeleteAlertOpen(false)}
          onSubmit={() => onPostProductionNoteDestroy()}
          cancelButtonLabel="No, cancel"
          submitButtonLabel="Yes, delete"
        />
      </div>
    </Container>
  );
};

export default PostProduction;

import React, { useState, useEffect } from "react";
import * as dayjs from "dayjs";
import { updateContact } from "apis/contacts/contacts";
import { getStaffs } from "apis/artists/staffs";
import { CloseCircle, Message, Down } from "@bigbinary/neeto-icons";
import {
  Label,
  Button,
  Tag,
  Dropdown,
  Switch,
  Spinner,
} from "@bigbinary/neetoui";
import { showToastrError } from "common";
import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContactNotes from "./ContactNotes";
import LastHistory from "./LastHistory";

const Profile = ({
  contactDetail,
  loadContactDetailResponse,
  setcurrentTab,
}) => {
  const [showInCrm, setShowInCrm] = useState(false);
  const [staffOptions, setStaffOptions] = useState([]);
  const [staffOptionLoad, setStaffOptionLoad] = useState(true);
  const [copyEmail, setCopyEmail] = useState(false);
  const { Menu, MenuItem } = Dropdown;

  useEffect(() => {
    if (contactDetail) {
      setShowInCrm(contactDetail.showInCrm);
    }
  }, [contactDetail]);

  useEffect(() => {
    loadStaffListResponse();
  }, []);

  useEffect(() => {
    if (copyEmail) {
      toast.info("Copied to Clipboard!", {
        position: "bottom-left",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setCopyEmail(false);
    }
  }, [copyEmail]);

  const loadStaffListResponse = async () => {
    try {
      const response = await getStaffs();
      setStaffOptions(response.data.staffs);
      setStaffOptionLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const onUpdate = async (attr, value) => {
    try {
      let payload = {}
      if (attr === "showInCrm") {
        payload = {
          contact: {
            showInCrm: value,
            addedToCrmDate: value ? dayjs().format("YYYY-MM-DD") : null,
          }
        }
      } else if (attr === "userId") {
        payload = {
          contact: {
            organisation_contacts_attributes: [
              { userId: value, id: contactDetail.organisationContactId },
            ],
          },
        }
      }
      await updateContact(contactDetail.id, payload);
      loadContactDetailResponse(contactDetail.id);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  if (staffOptionLoad) {
    return (
      <div
        className="flex items-center justify-center w-full"
        style={{ height: "calc(100vh - 134px)" }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div
      className="px-6 pt-6 pb-12 overflow-auto"
      style={{ height: "calc(100vh - 134px)" }}
    >
      <div className="flex flex-col space-y-6">
        <div className="grid grid-cols-3 gap-x-4 gap-y-6">
          <div className="flex flex-col space-y-1">
            <Label>Organisation</Label>
            <Button
              style="link"
              to={`/organisations/${contactDetail.organisationId}`}
              label={contactDetail.organisationName}
            />
          </div>

          <div className="flex flex-col space-y-1">
            <Label>Name</Label>
            <p className="text-sm font-semibold text-gray-800">{`${
              contactDetail.fullName
            } ${
              contactDetail.pronouns ? `(${contactDetail.pronouns})` : ``
            }`}</p>
          </div>

          <div className="flex flex-col space-y-1">
            <Label>Phone Number</Label>
            <p className="text-sm font-semibold text-gray-800">
              {contactDetail.phoneNumber || "N/A"}
            </p>
          </div>

          <div className="flex flex-col space-y-1">
            <Label>Position</Label>
            <p className="text-sm font-semibold text-gray-800">
              {contactDetail.title || "N/A"}
            </p>
          </div>

          <div className="flex flex-col space-y-1">
            <Label>Mobile Number</Label>
            <p className="text-sm font-semibold text-gray-800">
              {contactDetail.mobileNumber || "N/A"}
            </p>
          </div>

          <div className="flex flex-col items-start space-y-1">
            <Label>Accounts Payable</Label>
            {contactDetail.accountPayable ? (
              <Tag label="Yes" style="success" type="solid" />
            ) : (
              <Tag label="No" style="danger" type="solid" />
            )}
          </div>

          <div className="flex flex-col space-y-1">
            <Label>Email</Label>
            <div className="flex items-center space-x-2">
              <Button
                style="link"
                className="break-word"
                href={`mailto:${contactDetail.email}`}
                label={contactDetail.email}
              />
              <div onClick={e => e.stopPropagation()}>
                <CopyToClipboard
                  text={contactDetail.email}
                  onCopy={() => setCopyEmail(true)}
                >
                  <Button
                    style="text"
                    className="block"
                    icon={Message}
                    tooltipProps={{
                      content: "Click to copy email",
                      position: "top",
                    }}
                  />
                </CopyToClipboard>
              </div>
            </div>
          </div>

          <div className="flex flex-col space-y-1">
            <Label>Assigned Producer</Label>
            <div className="flex items-center space-x-2">
              <Dropdown
                icon={Down}
                label={contactDetail.userFullName || "Please select one"}
                buttonStyle="link"
                position="bottom-end"
                closeOnSelect
              >
                <Menu>
                  {staffOptions.map(staff => {
                    return (
                      <MenuItem.Button
                        key={staff.id}
                        onClick={() => {
                          onUpdate("userId", staff.id);
                        }}
                      >
                        {staff.fullName}
                      </MenuItem.Button>
                    );
                  })}
                </Menu>
              </Dropdown>

              <Button
                style="text"
                className="block"
                icon={CloseCircle}
                onClick={() => onUpdate("userId", null)}
                disabled={contactDetail.userId === null}
                tooltipProps={{
                  content: "Remove Selection",
                  position: "top",
                }}
              />
            </div>
          </div>
        </div>

        <Switch
          label="Show in CRM"
          checked={showInCrm}
          onChange={() => {
            setShowInCrm(!showInCrm);
            onUpdate("showInCrm", !showInCrm);
          }}
        />

        <ContactNotes contactDetail={contactDetail} />

        <LastHistory
          contactDetail={contactDetail}
          setcurrentTab={setcurrentTab}
        />

        {contactDetail.previousOrganisations?.length > 0 && (
          <div className="flex flex-col py-3 border-t border-gray-200">
            <div className="flex items-start justify-between pb-3 mb-3 space-x-3 border-b border-gray-200">
              <Label className="font-semibold text-gray-800">
                Work History
              </Label>
            </div>
            <div className="flex flex-col space-y-2">
              {contactDetail.previousOrganisations.map(org => {
                return (
                  <Button
                    style="link"
                    key={org.id}
                    label={org.name}
                    to={`/organisations/${org.id}`}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;

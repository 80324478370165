import React, { useState, useEffect } from "react";
import EmptyState from "components/Common/EmptyState";
import { useUserState } from "contexts/user";
import { getRandomNotFoundImage } from "common/helper";
import { Button } from "@bigbinary/neetoui";
import { LeftArrow } from "@bigbinary/neeto-icons";

const NotFound = ({ backBtn }) => {
  const { env_variables } = useUserState();
  const [emptyImage, setEmptyImage] = useState();

  useEffect(() => {
    setEmptyImage(getRandomNotFoundImage())
  }, [])

  const handleClick = () => {
    window.location.href = `${window.location.protocol}//auth.${env_variables.root_domain}/login`;
  };

  return (
    <div className="flex flex-col items-center justify-center w-full h-screen">
      <div className="flex flex-col items-center">
        <EmptyState
          image={emptyImage}
          title="Page Not Found"
          description="We are sorry but the page you are looking for does not exist."
        />

        {backBtn && (
          <div className="flex flex-col items-center justify-center h-full max-w-md m-auto">
            <Button
              icon={LeftArrow}
              iconPosition="left"
              label="Back"
              onClick={handleClick}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default NotFound;

import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Modal, Spinner, Typography, Button } from "@bigbinary/neetoui";
import { getStaffs } from "apis/staffs/staffs";
import { showToastrError } from "common";
import { dropDownListGenerator, removeDuplicacy, sleep } from "common/helper";
import { updateContacts } from "apis/crms/organisation_contacts";
import AsyncPaginateSelect from "components/Common/AsyncPaginateSelect";

const ActivityModal = ({
  addStaffModalState,
  setAddStaffModalState,
  bulkContactIds,
  loadContactListResponse,
}) => {
  const [asyncDataLoad, setAsyncDataLoad] = useState(true);
  const [staffOptions, setStaffOptions] = useState([]);
  const [staffPage, setStaffPage] = useState(1);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { userId: "" },
    onSubmit: () => {
      onSubmit();
    },
  });

  useEffect(async () => {
    if (addStaffModalState) {
      await loadStaffList();
      await setAsyncDataLoad(false);
    }
  }, [addStaffModalState]);

  const onSubmit = async () => {
    try {
      await updateContacts({
        organisation_contacts: { ...formik.values, contactIds: bulkContactIds },
      });
      setAddStaffModalState(false);
      loadContactListResponse();
      formik.resetForm();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadStaffList = async search => {
    await sleep(1000);

    try {
      let filteredOptions;
      let totalRecords;

      if (!search) {
        const response = await getStaffs("", staffPage);
        setStaffOptions(
          removeDuplicacy([...staffOptions, ...response.data.staffs])
        );
        totalRecords = response.data.totalRecords;
        setStaffPage(staffPage + 1);
        filteredOptions = response.data.staffs;
      } else {
        const searchLower = search.toLowerCase();

        const response = await getStaffs(searchLower, staffPage);
        setStaffOptions([...staffOptions, ...response.data.staffs]);
        setStaffPage(staffPage + 1);
        filteredOptions = response.data.staffs;
        totalRecords = filteredOptions.length;
      }

      return {
        options: dropDownListGenerator(removeDuplicacy(filteredOptions)),
        hasMore: filteredOptions.length === totalRecords ? false : true,
      };
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  return (
    <Modal
      isOpen={addStaffModalState}
      onClose={() => setAddStaffModalState(false)}
    >
      <Modal.Header>
        <Typography style="h2" weight="semibold">
          Add Staff
        </Typography>
      </Modal.Header>
      <Modal.Body>
        {asyncDataLoad ? (
          <div className="flex items-center justify-center w-full h-full">
            <Spinner />
          </div>
        ) : (
          <AsyncPaginateSelect
            cacheOptions
            isClearable
            isSearchable
            label="Staff"
            id="staff"
            placeholder="Select Staff"
            strategy="fixed"
            isDisabled={asyncDataLoad}
            isLoading={asyncDataLoad}
            value={dropDownListGenerator(
              removeDuplicacy(staffOptions),
              "name"
            ).find(staff => formik.values.userId === staff.value)}
            onChange={staff => {
              formik.setFieldValue("userId", staff.value);
            }}
            loadOptions={loadStaffList}
          />
        )}
      </Modal.Body>
      <Modal.Footer className="space-x-2">
        <Button label="Save changes" onClick={() => formik.handleSubmit()} />
        <Button
          style="text"
          label="Cancel"
          onClick={() => setAddStaffModalState(false)}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ActivityModal;

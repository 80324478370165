import React, { useState, useEffect, useContext } from "react";
import { Delete, Redo, Plus, Help } from "@bigbinary/neeto-icons";
import { Spinner, Input, Button, Select, Typography, Checkbox, Label } from "@bigbinary/neetoui";
import Pluralize from "pluralize";
import { components } from "react-select";
import { createLicense } from "apis/jobs/licenses";
import {
  getDefaultUsages,
  getDefaultUsage,
} from "apis/settings/default_usages";
import { updateEstimateRevision } from "apis/jobs/estimate_revisions";
import produce from "immer";
import { showToastrError } from "common";
import { dropDownListGenerator } from "common/helper";
import { UsageDetailsContext } from "../Usage/index";

const AddUsagePage = () => {
  const {
    jobDetail,
    revisionId,
    usageListRecords,
    title,
    setTitle,
    terms,
    setTerms,
    categoryList,
    setCategoryList,
    detailList,
    setDetailList,
    quantityList,
    setQuantityList,
    territoryList,
    setTerritoryList,
    periodList,
    setPeriodList,
    exclusivityPeriodList,
    setExclusivityPeriodList,
    currentRevision,
    loadEstimateRevisionsResponse,
  } = useContext(UsageDetailsContext);
  const [usageFee, setUsageFee] = useState();
  const [feeChange, setFeeChange] = useState(false);
  const [usage, setUsage] = useState();
  const [usageLoad, setUsageLoad] = useState(true);
  const [defaultUsageList, setDefaultUsageList] = useState([]);
  const [defaultUsageListLoad, setDefaultUsageListLoad] = useState(true);
  const [selectedDefaultUsageId, setSelectedDefaultUsageId] = useState("");
  const [btnLoader, setBtnLoader] = useState(false);
  const [usageList, setUsageList] = useState([
    {
      name: "Usage Category 1",
      category: "",
      detail: "",
      quantity: "",
      period: "",
      exclusivityPeriod: "",
      serial: 0,
      fee: 0,
      isEditable: true,
    },
  ]);
  const [calculatorNumber, setCalculatorNumber] = useState(0);
  const [calculatorPercentage, setCalculatorPercentage] = useState(0);
  const [reverseCalculatorNumber, setReverseCalculatorNumber] = useState(0);
  const [
    reverseCalculatorPercentage,
    setReverseCalculatorPercentage,
  ] = useState(1);

  useEffect(() => {
    setUsageLoad(false);
    loadDefaultUsageListData();
  }, []);

  useEffect(() => {
    if (selectedDefaultUsageId) {
      loadUsageData(selectedDefaultUsageId);
    }
  }, [selectedDefaultUsageId]);

  useEffect(() => {
    if (usage) {
      setUsageList(() =>
        usage.licenseItems.map(item => {
          return { ...item, id: "" };
        })
      );

      setFeeChange(true);
    }
  }, [usage]);

  useEffect(() => {
    let total = 0;
    usageList.map(item => {
      total = Number(total) + Number(item.fee);
    });

    if (feeChange) {
      setUsageFee(Number(total).toFixed(2));
    }
  }, [usageList]);

  const updateEstimateRevisionResponse = async (usageEnabled) => {
    try {
      setUsageLoad(true);
      await updateEstimateRevision(jobDetail.id, revisionId, { estimate_revision: { usageEnabled: usageEnabled }});
      loadEstimateRevisionsResponse();
      setUsageLoad(false);
    } catch (error) {
      showToastrError(error.data.errors)
    }
  }

  const createUsageEntry = async () => {
    setBtnLoader(true);
    try {
      let payload = {
        license: {
          name: "Standard Usage",
          title: title,
          terms: terms,
          fee: usageFee,
          license_items_attributes: usageList,
        },
      };
      await createLicense(revisionId, payload);
      usageListRecords();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      setBtnLoader(false);
    }
  };

  const loadUsageData = async id => {
    try {
      setUsageLoad(true);
      const { data } = await getDefaultUsage(id);
      setUsage(data.license);
      setUsageLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadDefaultUsageListData = async () => {
    try {
      setDefaultUsageListLoad(true);
      const { data } = await getDefaultUsages();

      setDefaultUsageList(dropDownListGenerator(data.licenses));
      setDefaultUsageListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  if (defaultUsageListLoad || usageLoad) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex justify-center w-full h-full p-6 overflow-y-scroll">
      <div className="w-full mb-6">
        <div className="flex space-x-4">
          <Select
            autoFocus
            className="w-1/6"
            label="Default Templates"
            value={
              selectedDefaultUsageId &&
              defaultUsageList.find(
                usage => usage.value === selectedDefaultUsageId
              )
            }
            onChange={option => {
              setSelectedDefaultUsageId(option.value);
            }}
            options={defaultUsageList}
          />

          <Input
            label="Usage Title"
            required={true}
            className="w-2/6"
            value={title}
            onChange={e => {
              setTitle(e.target.value);
            }}
          />

          <Input
            label="Usage Notes"
            className="w-4/6"
            value={terms}
            rows={1}
            onChange={e => {
              setTerms(e.target.value);
            }}
          />
        </div>

        <div className="flex items-center justify-between">
          <div className="mt-6 w-full">
            <div className="flex items-end justify-between">
              <div className="flex items-center">
                <Typography style="h4" weight="semibold">
                  Standard Usage
                </Typography>
                <Checkbox
                  className="ml-2"
                  label={
                    <Label
                      className="ml-0"
                      helpIconProps={{
                        icon: Help,
                        tooltipProps: {
                          content: "If you want usage to be included in an estimate, you should enable it!",
                          position: "right",
                        }
                      }}
                    >
                    Enable in Estimate
                  </Label>}
                  checked={currentRevision.usageEnabled}
                  onChange={() => updateEstimateRevisionResponse(!currentRevision.usageEnabled)}
                />
              </div>
              <div className="w-40">
                <Input
                  type="number"
                  label="Subtotal"
                  value={usageFee}
                  onChange={e => {
                    setFeeChange(false);
                    setUsageList(state => {
                      let arr = [...state];
                      let newArr = arr.map(a => {
                        return { ...a, fee: 0 };
                      });

                      return newArr;
                    });
                    setUsageFee(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="w-full p-6 mt-4 space-y-4 bg-gray-100 rounded-md">
              {usageList?.map((item, index) => {
                return (
                  <div className="relative" key={index}>
                    <div className="px-4 py-4 bg-white rounded-md">
                      <div className="flex items-center justify-between">
                        <Typography style="h5" weight="semibold">
                          Usage Category {index + 1}
                        </Typography>
                        <div className="flex space-x-2">
                          <Button
                            style="secondary"
                            label="Mark fields as Excluded"
                            onClick={() =>
                              setUsageList(
                                produce(draft => {
                                  draft[index] = {
                                    ...draft[index],
                                    detail: "Excluded",
                                    quantity: "Excluded",
                                    territory: "Excluded",
                                    period: "Excluded",
                                    fee: null,
                                    exclusivityPeriod: "Excluded",
                                  };
                                })
                              )
                            }
                          />
                          <Button
                            style="danger-text"
                            icon={Delete}
                            onClick={() => {
                              setUsageList(state => {
                                let newState = [...state];
                                newState.pop();
                                return newState;
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="grid grid-cols-6 gap-4 mt-4">
                        <Select
                          isCreateable
                          label="Category"
                          name="category"
                          options={categoryList}
                          onCreateOption={inputValue => {
                            setUsageList(
                              produce(draft => {
                                draft[index] = {
                                  ...draft[index],
                                  category: inputValue,
                                };
                              })
                            );

                            setCategoryList(state => {
                              return [
                                ...state,
                                { label: inputValue, value: inputValue },
                              ];
                            });
                          }}
                          value={
                            categoryList.find(
                              cat => cat.label === item.category
                            ) || {
                              label: item.category,
                              value: item.category,
                            }
                          }
                          onChange={e => {
                            setUsageList(
                              produce(draft => {
                                draft[index] = {
                                  ...draft[index],
                                  category: e.label,
                                };
                              })
                            );
                          }}
                        />

                        <div className="flex flex-col col-span-5">
                          {item.detail ? (
                            <div className="flex items-end space-x-2">
                              <Input
                                label="Detail"
                                value={item.detail}
                                onChange={e => {
                                  setUsageList(
                                    produce(draft => {
                                      draft[index] = {
                                        ...draft[index],
                                        detail: e.target.value,
                                      };
                                    })
                                  );
                                }}
                              />
                              <Button
                                style="text"
                                icon={Redo}
                                tooltipProps={{
                                  content: "Reset",
                                  position: "top",
                                }}
                                onClick={() => {
                                  setUsageList(
                                    produce(draft => {
                                      draft[index] = {
                                        ...draft[index],
                                        detail: null,
                                      };
                                    })
                                  );
                                }}
                              />
                            </div>
                          ) : (
                            <Select
                              isCreateable
                              label="Detail"
                              name="detail"
                              options={detailList}
                              value={
                                detailList.find(
                                  detail => detail.label === item.detail
                                ) || {
                                  label: item.detail,
                                  value: item.detail,
                                }
                              }
                              onChange={e => {
                                setUsageList(
                                  produce(draft => {
                                    draft[index] = {
                                      ...draft[index],
                                      detail: e.label,
                                    };
                                  })
                                );
                              }}
                              onCreateOption={inputValue => {
                                setUsageList(
                                  produce(draft => {
                                    draft[index] = {
                                      ...draft[index],
                                      detail: inputValue,
                                    };
                                  })
                                );
                                setDetailList(state => {
                                  return [
                                    ...state,
                                    { label: inputValue, value: inputValue },
                                  ];
                                });
                              }}
                            />
                          )}
                        </div>

                        <Select
                          isCreateable
                          label="Quantity"
                          name="quantity"
                          options={quantityList}
                          value={
                            quantityList.find(
                              quantity => quantity.label === item.quantity
                            ) || {
                              label: item.quantity,
                              value: item.quantity,
                            }
                          }
                          onChange={e => {
                            setUsageList(
                              produce(draft => {
                                draft[index] = {
                                  ...draft[index],
                                  quantity: e.label,
                                };
                              })
                            );
                          }}
                          onCreateOption={inputValue => {
                            setUsageList(
                              produce(draft => {
                                draft[index] = {
                                  ...draft[index],
                                  quantity: inputValue,
                                };
                              })
                            );
                            setQuantityList(state => {
                              return [
                                ...state,
                                { label: inputValue, value: inputValue },
                              ];
                            });
                          }}
                        />

                        <Select
                          isCreateable
                          label="Territory"
                          name="territory"
                          className="col-span-2"
                          options={territoryList}
                          value={
                            territoryList.find(
                              territory => territory.label === item.territory
                            ) || {
                              label: item.territory,
                              value: item.territory,
                            }
                          }
                          onChange={e => {
                            setUsageList(
                              produce(draft => {
                                draft[index] = {
                                  ...draft[index],
                                  territory: e.label,
                                };
                              })
                            );
                          }}
                          onCreateOption={inputValue => {
                            setUsageList(
                              produce(draft => {
                                draft[index] = {
                                  ...draft[index],
                                  territory: inputValue,
                                };
                              })
                            );
                            setTerritoryList(state => {
                              return [
                                ...state,
                                { label: inputValue, value: inputValue },
                              ];
                            });
                          }}
                        />

                        <Select
                          isCreateable
                          label="Period"
                          name="period"
                          labelProps={{
                            helpIconProps: {
                              tooltipProps: {
                                content: "Please enter number of months only",
                                position: "auto",
                              },
                            },
                          }}
                          options={periodList}
                          value={
                            periodList.find(
                              period => period.label === item.period
                            ) || { label: item.period, value: item.period }
                          }
                          onChange={e => {
                            setUsageList(
                              produce(draft => {
                                draft[index] = {
                                  ...draft[index],
                                  period: e.label,
                                  exclusivityPeriod: e.label,
                                };
                              })
                            );
                          }}
                          components={{
                            SingleValue: ({ children, ...props }) => {
                              return (
                                <components.SingleValue {...props}>
                                  {!isNaN(Number(children))
                                    ? children +
                                      Pluralize(" month", Number(children))
                                    : children}
                                </components.SingleValue>
                              );
                            },
                            Placeholder: ({ children, ...props }) => {
                              return (
                                <components.Placeholder {...props}>
                                  {children + " month"}
                                </components.Placeholder>
                              );
                            },
                            IndicatorSeparator: () => null,
                          }}
                          onCreateOption={inputValue => {
                            setUsageList(
                              produce(draft => {
                                draft[index] = {
                                  ...draft[index],
                                  period: inputValue,
                                  exclusivityPeriod: inputValue,
                                };
                              })
                            );
                            setPeriodList(state => {
                              return [
                                ...state,
                                { label: inputValue, value: inputValue },
                              ];
                            });
                          }}
                        />

                        <Select
                          isCreateable
                          label="Exclusivity Period"
                          name="exclusivity_period"
                          labelProps={{
                            helpIconProps: {
                              tooltipProps: {
                                content: "Please enter number of months only",
                                position: "auto",
                              },
                            },
                          }}
                          options={exclusivityPeriodList}
                          value={
                            exclusivityPeriodList.find(
                              period => period.label === item.exclusivityPeriod
                            ) || {
                              label: item.exclusivityPeriod,
                              value: item.exclusivityPeriod,
                            }
                          }
                          onChange={e => {
                            setUsageList(
                              produce(draft => {
                                draft[index] = {
                                  ...draft[index],
                                  exclusivityPeriod: e.label,
                                };
                              })
                            );
                          }}
                          onCreateOption={inputValue => {
                            setUsageList(
                              produce(draft => {
                                draft[index] = {
                                  ...draft[index],
                                  exclusivityPeriod: inputValue,
                                };
                              })
                            );
                            setExclusivityPeriodList(state => {
                              return [
                                ...state,
                                { label: inputValue, value: inputValue },
                              ];
                            });
                          }}
                          components={{
                            SingleValue: ({ children, ...props }) => {
                              return (
                                <components.SingleValue {...props}>
                                  {!isNaN(Number(children))
                                    ? children +
                                      Pluralize(" month", Number(children))
                                    : children}
                                </components.SingleValue>
                              );
                            },
                            Placeholder: ({ children, ...props }) => {
                              return (
                                <components.Placeholder {...props}>
                                  {children + " month"}
                                </components.Placeholder>
                              );
                            },
                            IndicatorSeparator: () => null,
                          }}
                        />

                        {item.fee === null ? (
                          <Input
                            label="Fee"
                            value="Excluded"
                            onChange={e => {
                              setFeeChange(true);
                              setUsageList(
                                produce(draft => {
                                  draft[index] = {
                                    ...draft[index],
                                    fee: e.target.value,
                                  };
                                })
                              );
                            }}
                          />
                        ) : (
                          <Input
                            type="number"
                            label="Fee"
                            value={item.fee}
                            onChange={e => {
                              setFeeChange(true);
                              setUsageList(
                                produce(draft => {
                                  draft[index] = {
                                    ...draft[index],
                                    fee: e.target.value,
                                  };
                                })
                              );
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}

              <div className="flex items-center justify-end mt-4">
                <Button
                  style="link"
                  label="Add Usage Item"
                  icon={Plus}
                  iconPosition="left"
                  onClick={() => {
                    setUsageList(state => {
                      return [
                        ...state,
                        {
                          name: `Usage Category ${usageList?.length + 1}`,
                          category: "",
                          detail: "",
                          quantity: "",
                          period: "",
                          exclusivity_period: "",
                          fee: 0,
                          serial: usageList?.length + 1,
                        },
                      ];
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-6 mb-12 space-x-2">
          <Button label="Save Changes" onClick={() => createUsageEntry()} loading={btnLoader}/>
          <Button
            style="text"
            label="Cancel"
            onClick={() => {
              setSelectedDefaultUsageId(null);
              setUsageList(() => {
                return [
                  {
                    name: `Usage Category ${usageList?.length + 1}`,
                    category: "",
                    detail: "",
                    quantity: "",
                    period: "",
                    exclusivity_period: "",
                    fee: 0,
                    serial: usageList?.length + 1,
                  },
                ];
              });
            }}
          />
        </div>

        <div className="flex flex-col w-full pb-24">
          <Typography style="h4" weight="semibold" className="mb-3">
            Calculator
          </Typography>
          <div className="grid grid-cols-2 gap-6 divide-x divide-gray-200">
            <div className="flex items-end">
              <Input
                label="What is"
                value={calculatorPercentage}
                onChange={e => setCalculatorPercentage(e.target.value)}
              />
              <p className="flex items-center p-2 mt-4">%</p>
              <Input
                label="of"
                value={calculatorNumber}
                onChange={e => setCalculatorNumber(e.target.value)}
              />
              <p className="flex items-center p-2 mt-4">=</p>
              <Input
                className="mt-5"
                value={(calculatorNumber * calculatorPercentage) / 100}
              />
            </div>
            <div className="flex items-end pl-6">
              <Input
                label="What is"
                value={reverseCalculatorNumber}
                onChange={e => setReverseCalculatorNumber(e.target.value)}
              />
              <p className="flex items-center p-2 mt-4">of</p>
              <Input
                value={reverseCalculatorPercentage}
                onChange={e => setReverseCalculatorPercentage(e.target.value)}
              />
              <p className="flex items-center p-2 mt-4">=</p>
              <Input
                value={
                  (reverseCalculatorNumber / reverseCalculatorPercentage) * 100
                }
              />
              <p className="flex items-center p-2 mt-4">%</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUsagePage;

import React from "react";
import { Button, Tag } from "@bigbinary/neetoui";
import { humanize, numberWithCommas } from "common/helper";
import classnames from "classnames";
import * as dayjs from "dayjs";

export const PAYMENT_TYPE_VALUE = [
  { label: "Standard", value: "standard" },
  { label: "Late Fee", value: "late_fee" },
  { label: "Early Bird Fee", value: "early_bird_fee" },
];

export const COMMENCEMENT_PAYMENT_TYPE_VALUE = [
  { label: "Standard", value: "standard" },
];

export const TAX_TYPE_LIST = [
  { label: "GST", value: "GST" },
  { label: "VAT", value: "VAT" },
];

export const COLUMN_DATA = [
  {
    key: "serialNumber",
    dataIndex: "serialNumber",
    title: "Serial",
    width: 120,
    sorter: true,
    field: "serial",
    fixed: "left",
    render: (_, rowData) => {
      return (
        <Button
          style="link"
          to={`/invoices/${rowData.id}`}
          label={rowData.serialNumber}
        />
      );
    },
  },
  {
    key: "jobName",
    dataIndex: "jobName",
    title: "Job",
    sorter: true,
    field: "job_name",
    width: 240,
    render: (_, rowData) => {
      return (
        <Button
          style="link"
          to={`/jobs/${rowData.jobId}/overview`}
          label={rowData.jobName}
        />
      );
    },
  },
  {
    title: "Organisation",
    dataIndex: "organisationName",
    key: "organisationName",
    sorter: true,
    field: "job_organisation_name",
    width: 240,
    render: (_, rowData) => {
      return (
        <Button
          style="link"
          to={`/organisations/${rowData.organisationId}`}
          label={rowData.organisationName}
        />
      );
    },
  },
  {
    title: "Staff",
    dataIndex: "staffFullName",
    key: "staffFullName",
    sorter: true,
    field: "user_full_name",
    render: (_, rowData) => {
      return (
        <Button
          style="link"
          to={`/staff/${rowData.staffId}`}
          label={rowData.staffFullName}
        />
      );
    },
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    sorter: true,
    field: "total_amount",
    render: (_, rowData) => {
      return (
        <>
          {`${numberWithCommas(Number(rowData.amount).toFixed(2))} ${
            rowData.currency
          }`}
        </>
      );
    },
  },
  {
    title: "Issued On",
    dataIndex: "issueDate",
    key: "issueDate",
    sorter: true,
    width: 120,
    field: "issue_date",
    render: issueDate => dayjs(issueDate).format("DD/MM/YYYY"),
  },
  {
    title: "Contact",
    dataIndex: "contactFullName",
    sorter: true,
    field: "contact_full_name",
    key: "contactFullName",
    render: (_, rowData) => {
      return (
        <Button
          style="link"
          to={`/people/${rowData.contactId}`}
          label={rowData.contactFullName}
        />
      );
    },
  },
  {
    title: "# Days Overdue",
    dataIndex: "overdueDays",
    key: "overdueDays",
    width: 150,
    sorter: true,
    field: "invoice_overdue_days",
    render: overdueDays => (
      <Tag
        label={overdueDays ? `${overdueDays} days` : "-"}
        className={classnames({
          "bg-red-200": overdueDays < 32,
          "bg-red-300 font-bold": overdueDays > 32,
          "bg-red-400 text-white font-bold": overdueDays > 64,
          "bg-red-500 text-white font-bold": overdueDays > 96,
        })}
        type="solid"
      />
    ),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    sorter: true,
    field: "status",
    width: 60,
    fixed: "right",
    render: status => {
      return (
        <Tag
          style={status === "paid" ? "success" : "danger"}
          label={humanize(status)}
        />
      );
    },
  },
];

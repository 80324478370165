import React from "react";
import { Label } from "@bigbinary/neetoui";
import { numberWithCommas, artistModuleName } from "common/helper";
import Pluralize from "pluralize";

const CommissionFooter = ({
  values,
  agencyCommissionValue,
  revisionItemTotal,
  jobDetail,
}) => (
  <div className="py-2">
    <div className="flex justify-between py-2 border-b border-gray-200">
      <Label className="font-medium">Agency</Label>
      <Label className="font-bold">
        {values.rateTbc
          ? `TBC`
          : values.rateNa
          ? `NA`
          : `${numberWithCommas(agencyCommissionValue(values) || 0)} ${
              jobDetail.currency
            }`}
      </Label>
    </div>

    {values.Assigned?.value?.length > 0 && (
      <div className="flex justify-between py-2 border-b border-gray-200">
        <Label className="font-medium">
          {values.Assigned.label ||
            `${Pluralize(artistModuleName(), 1)} Amount`}
        </Label>
        <Label className="font-bold">
          {values.rateTbc
            ? `TBC`
            : values.rateNa
            ? "NA"
            : `${numberWithCommas(
                (
                  revisionItemTotal(values) - agencyCommissionValue(values)
                ).toFixed(2)
              )} ${jobDetail.currency}`}
        </Label>
      </div>
    )}

    <div className="flex justify-between py-2">
      <Label className="font-bold">Total</Label>
      <Label className="font-bold">
        {values.rateTbc
          ? `TBC`
          : values.rateNa
          ? "NA"
          : `${numberWithCommas(revisionItemTotal(values))} ${
              jobDetail.currency
            }`}
      </Label>
    </div>
  </div>
);

export default CommissionFooter;

import React, { useState, useEffect } from "react";
import { Label } from "@bigbinary/neetoui";
import { TagInput } from "@blueprintjs/core";

// Move to constants file
export const onKeyDown = event => {
  event.which === 13 && event.preventDefault();
};

const Form = ({ formik, required = false, label = "Tags", attr = "tags" }) => {
  const [tagArray, settagArray] = useState([]);
  const [tagValue, setTagValue] = useState("");

  useEffect(() => {
    if (formik.values[attr]?.length > 0) {
      settagArray(formik.values[attr]);
    } else if (formik.values[attr]?.length === 0 && required) {
      formik.setFieldError(attr, "Should not be empty");
    }
  }, [formik.values[attr]]);

  const handleAddInviteEmail = value => {
    let updatedTags = [...tagArray, ...value];
    settagArray(updatedTags);
    setTagValue("");
    formik.setFieldValue(attr, updatedTags);
  };

  const handleRemoveInviteEmail = value => {
    let updatedTags = tagArray.filter(email => email !== value);
    settagArray(updatedTags);
    formik.setFieldValue(attr, updatedTags);
  };

  return (
    <div className="neeto-ui-input__wrapper">
      <Label className="mb-2" required={required}>
        {label}
      </Label>
      <TagInput
        addOnBlur={true}
        addOnPaste={true}
        onKeyDown={onKeyDown}
        values={tagArray}
        inputValue={tagValue}
        placeholder={label}
        className="neeto-ui-input neeto-ui-input--medium"
        onAdd={email => handleAddInviteEmail(email)}
        onRemove={email => handleRemoveInviteEmail(email)}
        onInputChange={({ target: { value } }) => setTagValue(value)}
      />
      {formik.errors[attr] && (
        <p className="text-red-600">{formik.errors[attr]}</p>
      )}
    </div>
  );
};

export default Form;

import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Table } from "../Shared";
import Accordion from "../Shared/Accordion";

const UsageOptionsTable = ({
  usage,
  data,
  setSelectedUsageId,
  selectedUsageId,
  shouldBeOpened = false,
  openedItemId,
  setOpenedItemId,
}) => {
  const [isOpen, setIsOpen] = useState(shouldBeOpened);

  useEffect(() => {
    if (openedItemId) {
      setIsOpen(usage.id === openedItemId);
    }
  }, [openedItemId]);

  return data ? (
    <div
      className="w-full text-base regular-table usage"
      style={{ fontFamily: "Modern Gothic" }}
    >
      {data?.map(item => (
        <Accordion key={item?.option?.title}>
          <div
            onClick={() => {
              setSelectedUsageId(usage.id)
              isOpen ? setOpenedItemId() : setOpenedItemId(usage.id);
              setIsOpen(!isOpen);
            }}
            className={`w-full p-4 transition-all cursor-pointer rounded_container lg:px-6 lg:py-4`}
            style={{
              backgroundColor: isOpen ? "#D6EEFF" : "#D1D3D4",
              border: isOpen ? "2px solid #75C6FF" : "2px solid #D1D3D4",
            }}
          >
            <Accordion.Item
              isOpen={isOpen}
              title={
                item?.option?.hasTitle ? (
                  <div className="flex items-start justify-start w-full text-center lg:text-left lg:items-center">
                    {item?.option?.hasCheckbox && (
                      <div className="mt-1 mr-2 lg:m-0 lg:pr-4">
                        <label className="flex flex-row items-center justify-start space-x-3 cursor-pointer">
                          <input
                            type="checkbox"
                            name="checkbox"
                            className={`cursor-pointer rounded-full bg-white border-2 border-black`}
                            checked={usage.id === selectedUsageId}
                            onChange={() => setSelectedUsageId(usage.id)}
                          />
                        </label>
                      </div>
                    )}
                    {item?.option?.hasTitle && (
                      <div
                        className={classNames("flex w-full", {
                          "flex-col items-start justify-start space-y-2 lg:space-y-0 lg:flex-row lg:items-center lg:justify-between":
                            item?.option?.price,
                          "flex-row items-center justify-between": !item?.option
                            ?.price,
                        })}
                      >
                        <div className="pr-0 lg:pr-5">
                          <h2 className="text-xl font-medium leading-6 text-left">
                            {item?.option?.title}
                            {item?.option?.description?.length > 0
                              ? ` - ${item?.option?.description}`
                              : ""}
                          </h2>
                        </div>
                        {item?.option?.price ? (
                          <h2 className="text-xl font-medium">
                            {item?.option?.price}
                          </h2>
                        ) : (
                          <div className="flex flex-row items-center self-center justify-center w-6 h-6 p-1 ml-2 text-lg font-bold text-center text-white whitespace-no-wrap bg-gray-900 rounded-full lg:ml-auto">
                            <span className="leading-none">
                              {usage.id || "1"}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )
              }
            >
              <div className="flex w-full mt-4">
                <Table data={item?.table} type="bordered" />
              </div>
            </Accordion.Item>
          </div>
        </Accordion>
      ))}
    </div>
  ) : (
    ""
  );
};

export default UsageOptionsTable;

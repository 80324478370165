import React from "react";
import {
  Dashboard,
  UserSettings,
  UserStar,
  Suitcase,
  Group,
  ContactBook,
  Customize,
  Invoice,
  UserCircle,
  Settings,
  Globe,
  Agents,
  Report,
  File,
} from "@bigbinary/neeto-icons";
import { artistModuleName } from "common/helper";

export const MENU_ITEMS = user => [
  { name: "Dashboard", link: "/", icon: <Dashboard /> },
  user.access === "super_admin"
    ? {
        name: "Super Admin",
        link: "/super_admin/accounts",
        icon: <UserSettings />,
      }
    : null,
  { name: "My Jobs", link: "/my_jobs", icon: <UserStar /> },
  { name: "Jobs", link: "/jobs", icon: <Suitcase /> },
  { name: "Organisations", link: "/organisations", icon: <Group /> },
  { name: "People", link: "/people", icon: <ContactBook /> },
  {
    name: artistModuleName(),
    link: `/${artistModuleName().toLowerCase()}`,
    icon: <Customize />,
  },
  { name: "Invoices", link: "/invoices", icon: <Invoice /> },
  { name: "RCIs", link: "/rcis", icon: <File /> },
  { name: "Staff", link: "/staff", icon: <UserCircle /> },
  { name: "Settings", link: "/settings", icon: <Settings /> },
  {
    name: "Post Production",
    link: "/post_production/final_artwork_requests",
    icon: <Globe />,
  },
  {
    name: "CRM",
    link: "/crm",
    icon: <Agents />,
  },
  {
    name: "Reports",
    link: "/reports",
    icon: <Report />,
  },
];

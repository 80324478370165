import React, { useState, useRef, useEffect } from "react";
import {
  Spinner,
  Button,
  Alert,
  Input,
  Modal,
  Typography,
  Toastr,
} from "@bigbinary/neetoui";
import { Header, Container } from "@bigbinary/neetoui/layouts";
import { isEmpty } from "ramda";
import { useFormik } from "formik";
import {
  getExclusivityPeriods,
  createExclusivityPeriod,
  updateExclusivityPeriod,
  destroyExclusivityPeriod,
} from "apis/settings/exclusivity_periods";
import { moveItem } from "apis/settings/move_items";
import { showToastrError } from "common";
import { getRandomNotFoundImage } from "common/helper";
import useDebounce from "common/debounce";
import {
  EXCLUSIVITY_PERIOD_VALIDATION_SCHEMA,
  EXCLUSIVITY_PERIOD_INITIAL_VALUE,
} from "../constants";
import List from "./List";
import EmptyState from "components/Common/EmptyState";

const ExclusivityPeriods = ({ breadcrumbs }) => {
  const inputRef = useRef();
  const [exclusivityPeriodList, setExclusivityPeriodList] = useState([]);
  const [listLoader, setListLoader] = useState(true);
  const [exclusivityPeriod, setExclusivityPeriod] = useState({});
  const [modalState, setModalState] = useState(false);
  const [deleteAlertState, setDeleteAlertState] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [searchParams, setSearchParams] = useState();
  const debouncedSearchTerm = useDebounce(searchParams, 1000);
  const [emptyImage, setEmptyImage] = useState();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: exclusivityPeriod?.id
      ? exclusivityPeriod
      : EXCLUSIVITY_PERIOD_INITIAL_VALUE,
    validationSchema: EXCLUSIVITY_PERIOD_VALIDATION_SCHEMA,
    onSubmit: () => {
      if (exclusivityPeriod?.id) {
        updateExclusivityPeriodEntry();
      } else {
        createExclusivityPeriodEntry();
      }
    },
  });

  useEffect(() => {
    loadExclusivityPeriodList();
    setEmptyImage(getRandomNotFoundImage());
  }, []);

  useEffect(() => {
    if (debouncedSearchTerm != undefined) {
      loadExclusivityPeriodList();
    }
  }, [debouncedSearchTerm]);

  const loadExclusivityPeriodList = async () => {
    try {
      const response = await getExclusivityPeriods(searchParams);
      setExclusivityPeriodList(response.data.exclusivityPeriods || []);
      setListLoader(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const createExclusivityPeriodEntry = async () => {
    try {
      const response = await createExclusivityPeriod({
        exclusivity_period: { name: formik.values.name },
      });
      setModalState(false);
      Toastr.info(response.data.notice);
      formik.resetForm();
      loadExclusivityPeriodList();
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setButtonLoader(false);
    }
  };

  const updateExclusivityPeriodEntry = async () => {
    try {
      const response = await updateExclusivityPeriod(exclusivityPeriod?.id, {
        exclusivity_period: { name: formik.values.name },
      });
      setModalState(false);
      Toastr.info(response.data.notice);
      formik.resetForm();
      loadExclusivityPeriodList();
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setButtonLoader(false);
    }
  };

  const destroyExclusivityPeriodEntry = async () => {
    try {
      const response = await destroyExclusivityPeriod(exclusivityPeriod?.id);
      setDeleteAlertState(false);
      Toastr.info(response.data.notice);
      loadExclusivityPeriodList();
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setButtonLoader(false);
    }
  };

  const moveExclusivityPeriodEntry = async (
    exclusivityPeriodId,
    nature,
    place
  ) => {
    try {
      const response = await moveItem(exclusivityPeriodId, {
        move_item: {
          resource: "exclusivity_periods",
          nature: nature,
          place: place,
        },
      });
      Toastr.info(response.data.notice);
      loadExclusivityPeriodList();
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const handleNewButtonClick = async () => {
    setButtonLoader(false);
    formik.resetForm();
    setExclusivityPeriod({});
    setModalState(true);
  };

  const handleSubmitButtonClick = async () => {
    if (Object.keys(formik.errors).length === 0) {
      setButtonLoader(true);
      formik.handleSubmit();
    }
  };

  if (listLoader) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <Container>
      <Header
        title="Exclusivity Periods"
        breadcrumbs={breadcrumbs}
        searchProps={{
          value: searchParams,
          onChange: e => setSearchParams(e.target.value),
          clear: () => setSearchParams(),
        }}
        actionBlock={
          <Button label="Add New" onClick={() => handleNewButtonClick()} />
        }
      />
      <div className="w-full" style={{ height: "calc(100vh - 128px)" }}>
        {!isEmpty(exclusivityPeriodList) ? (
          <List
            exclusivityPeriodList={exclusivityPeriodList}
            setExclusivityPeriod={setExclusivityPeriod}
            setModalState={setModalState}
            setDeleteAlertState={setDeleteAlertState}
            moveExclusivityPeriodEntry={moveExclusivityPeriodEntry}
          />
        ) : (
          <EmptyState
            image={emptyImage}
            title="No Exclusivity Periods Found"
            description="We couldn’t find any exclusivity periods. Try creating one."
            primaryButtonProps={{
              label: "Add New",
              onClick: () => handleNewButtonClick(),
            }}
          />
        )}
      </div>

      <Modal
        isOpen={modalState}
        onClose={() => setModalState(false)}
        initialFocusRef={inputRef}
      >
        <Modal.Header>
          <Typography style="h2" weight="semibold">
            {exclusivityPeriod?.id
              ? "Edit Exclusivity Period"
              : "Add Exclusivity Period"}
          </Typography>
        </Modal.Header>
        <Modal.Body>
          <div className="flex flex-col w-full space-y-6">
            <Input
              label="Name"
              ref={inputRef}
              required={true}
              onChange={formik.handleChange}
              value={formik.values.name}
              error={
                Boolean(formik.touched.name && formik.errors.name) &&
                formik.errors.name
              }
              {...formik.getFieldProps("name")}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="flex gap-x-2">
          <Button
            label="Save changes"
            onClick={() => handleSubmitButtonClick()}
            loading={buttonLoader}
          />
          <Button
            label="Cancel"
            style="text"
            onClick={() => setModalState(false)}
          />
        </Modal.Footer>
      </Modal>

      <Alert
        isOpen={deleteAlertState}
        title="Delete Exclusivity Period"
        message={
          <>
            Are you sure you want to delete the exclusivityPeriod{" "}
            <strong>{exclusivityPeriod?.name}</strong>?
          </>
        }
        onClose={() => setDeleteAlertState(false)}
        onSubmit={() => {
          setButtonLoader(true);
          destroyExclusivityPeriodEntry();
        }}
        isSubmitting={buttonLoader}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, delete"
      />
    </Container>
  );
};

export default ExclusivityPeriods;

import React from "react";

const TimelineTable = ({ id, data, bgColor }) => {
  return (
    <div
      className="w-full text-base regular-table"
      style={{ fontFamily: "Modern Gothic" }}
    >
      {data?.map(item => (
        <div
          key={item?.option?.title}
          className={`rounded_container w-full py-5 lg:p-6 px-3`}
          style={{ backgroundColor: bgColor && bgColor }}
        >
          <div className="flex flex-row items-center justify-between w-full pb-4 mb-4 border-b border-black">
            <div className="pr-0 lg:pr-5">
              <h2 className="text-xl font-medium">{item?.name}</h2>
            </div>
            <div className="flex flex-row items-center justify-center w-6 h-6 p-1 ml-2 text-lg font-bold text-center text-white whitespace-no-wrap bg-gray-900 rounded-full lg:ml-auto">
              <span className="leading-none">{id && id}</span>
            </div>
          </div>
          <div className="flex flex-col w-full gap-4 lg:grid lg:grid-cols-3">
            <div className="flex flex-col items-start justify-start space-y-6">
              <div className="flex flex-col items-start justify-start">
                <h3 className="text-sm font-bold">Creative Delivery</h3>
                <span className="mt-1 text-sm font-normal leading-4">
                  {item.deliveryTimelineData}
                </span>
              </div>
              <div className="flex flex-col items-start justify-start">
                <h3 className="text-sm font-bold">Client Approval</h3>
                <span className="mt-1 text-sm font-normal leading-4">
                  {item.approvalTimelineData}
                </span>
              </div>
            </div>
            <div className="flex flex-col items-start justify-start">
              <h3 className="text-sm font-bold">Phase Description</h3>
              <p className="mt-1 text-sm font-normal leading-4">
                {item?.description}
              </p>
            </div>
            <div className="flex flex-col items-start justify-start">
              <h3 className="text-sm font-bold">Revisions</h3>
              <p className="mt-1 text-sm font-normal leading-4">
                {item?.revision}
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TimelineTable;

import React, { useEffect, useState } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import { either, isEmpty, isNil } from "ramda";

import { initializeLogger } from "common/logger";
import { setAuthHeaders, registerIntercepts } from "apis/axios";
import { Spinner } from "@bigbinary/neetoui";
import Dashboard from "components/Dashboard";

import PrivateRoute from "components/Common/PrivateRoute";
import ResetPassword from "components/Authentication/PasswordReset";
import PasswordReset from "components/Authentication/ForgotPassword";
import Login from "components/Authentication/Login";
import Signup from "components/Authentication/Signup";
import Redirect from "components/Authentication/Redirect";
import NotFound from "components/Dashboard/NotFound";
import { useAuthState, useAuthDispatch } from "contexts/auth";
import { useUserDispatch } from "contexts/user";

// Public Routes

import EstimateRegular from "components/Dashboard/Mailer/Estimate/NewEstimate";
import EstimateRegularMulti from "components/Dashboard/Mailer/Estimate/NewEstimate";
import BookingForm from "components/Dashboard/Mailer/BookingForm/NewBookingForm";
import ChangeOrder from "components/Dashboard/Mailer/Overage/NewChangeOrder";
import TaxInvoiceCommencement from "components/Dashboard/Mailer/Invoice/NewTaxInvoice";
import TaxInvoice from "components/Dashboard/Mailer/Invoice/NewTaxInvoice";
import RegisteredLicense from "components/Dashboard/Mailer/License/NewRegisteredLicense";
import RecipientTaxInvoiceComm from "components/Dashboard/Mailer/Rci/NewRciTaxInvoice";
import RecipientTaxInvoice from "components/Dashboard/Mailer/Rci/NewRciTaxInvoice";

const Main = props => {
  const [loading, setLoading] = useState(true);
  const { authToken } = useAuthState();
  const userDispatch = useUserDispatch();
  const authDispatch = useAuthDispatch();
  const isLoggedIn = !either(isNil, isEmpty)(authToken);

  useEffect(() => {
    userDispatch({
      type: "SET_USER",
      payload: { user: props.user, env_variables: props.env_variables },
    });
    initializeLogger();
    registerIntercepts(authDispatch);
    setAuthHeaders(setLoading);
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  if (!props.valid_subdomain) {
    return <NotFound backBtn={true} />;
  }

  // TODO: Make same URL for single and multi estimate

  return (
    <BrowserRouter>
      <ToastContainer autoClose={3000} limit={2} />
      <Switch>
        <Route exact path="/my/password/new" component={PasswordReset} />
        <Route exact path="/signup" component={Signup} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/redirect" component={Redirect} />
        <Route exact path="/reset_password" component={ResetPassword} />

        <Route exact path="/estimate" component={EstimateRegular} />
        <Route
          exact
          path="/estimate-regular-multi"
          component={EstimateRegularMulti}
        />
        <Route exact path="/booking-form" component={BookingForm} />
        <Route exact path="/change-order" component={ChangeOrder} />
        <Route
          exact
          path={"/estimate_multi"}
          component={EstimateRegularMulti}
        />
        <Route exact path={"/overage"} component={ChangeOrder} />
        <Route
          exact
          path={"/invoices/commencement"}
          component={TaxInvoiceCommencement}
        />
        <Route exact path={"/invoices/final"} component={TaxInvoice} />
        <Route
          exact
          path={"/standard_license"}
          component={RegisteredLicense}
        />
        <Route exact path={"/booking_form"} component={BookingForm} />
        <Route
          exact
          path={"/rcis/Commencement"}
          component={RecipientTaxInvoiceComm}
        />
        <Route exact path={"/rcis/Final"} component={RecipientTaxInvoice} />

        <PrivateRoute
          path="/"
          redirectRoute="/login"
          condition={isLoggedIn}
          component={Dashboard}
        />
      </Switch>
    </BrowserRouter>
  );
};

Main.propTypes = {
  user: PropTypes.object,
};

export default Main;

import axios from "axios";
import { DEFAULT_PAGE_SIZE } from "common/helper";

const getStaffsUrl = (id, searchParams, page) => {
  if (id) {
    return `/api/v1/job_module/staffs/${id}`;
  }

  if (searchParams) {
    let searchQuery = `q[first_name_i_cont_any]=${searchParams}&q[last_name_i_cont_any]=${searchParams}&q[full_name_i_cont_any]=${searchParams}&q[title_i_cont_any]=${searchParams}&q[m]=or&q[id_eq]=${searchParams}`;
    return `/api/v1/job_module/staffs?${searchQuery}&page=${page}&per_page=${DEFAULT_PAGE_SIZE}`;
  }

  return `/api/v1/job_module/staffs?page=${page}&per_page=${DEFAULT_PAGE_SIZE}`;
};

export const getStaffs = (searchParams, page = 1) => {
  return axios.get(getStaffsUrl(null, searchParams, page));
};

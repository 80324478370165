import React, { useState, useEffect } from "react";
import { getBookingFormBuilder } from "apis/email_templates/builders";
import { getBookingFormPdf } from "apis/email_templates/pdfs";
import { useLocation } from "react-router-dom";
import { showToastrError } from "common";
import { numberWithCommas } from "common/helper";
import prntr from "prntr";
import { Spinner } from "@bigbinary/neetoui";
import { InView } from "react-intersection-observer";
import {
  BookingFormWelcome,
  BookingFormOverview,
  ProjectPhases,
  BookingFormUsageSummary,
  BookingFormTotalCost,
  BookingFormCreationFees,
} from "../Pages";
import PrintSave from "../Shared/PrintSave";
import "../Styles/common";
import { CURRENCY_SYMBOL } from "../constants";

const BookingForm = () => {
  document.title = "Booking Form";
  const [inView, setInView] = useState(false);
  const location = useLocation();
  const paramToken = location?.search?.split("?token=")[1];
  const artistId = location?.search?.split("&artist_id=")[1];
  const [jobDetail, setJobDetail] = useState();
  const [companyDetails, setCompanyDetails] = useState();
  const [revision, setRevision] = useState();
  const [revisionItems, setRevisionItems] = useState([]);
  const [projectScopes, setProjectScopes] = useState([]);
  const [timingList, setTimingList] = useState([]);
  const [deliveryScopes, setDeliveryScopes] = useState([]);
  const [jobPhases, setJobPhases] = useState([]);
  const [masterUsage, setMasterUsage] = useState([]);
  const [emailBackground, setEmailBackground] = useState();
  const [artist, setArtist] = useState();
  const [
    jobTypeSpecificTemplateSetting,
    setJobTypeSpecificTemplateSetting,
  ] = useState();
  const [
    jobTypeSpecificTemplateSettingLoad,
    setJobTypeSpecificTemplateSettingLoad,
  ] = useState();
  const [companyDetailLoad, setCompanyDetailLoad] = useState(true);
  const [defaultTemplateSetting, setDefaultTemplateSetting] = useState();
  const [jobDetailLoad, setJobDetailLoad] = useState(true);
  const [revisionLoad, setRevisionLoad] = useState(true);
  const [projectScopesLoad, setProjectScopesLoad] = useState(true);
  const [deliveryScopesLoad, setDeliveryScopesLoad] = useState(true);
  const [jobPhasesLoad, setJobPhasesLoad] = useState(true);
  const [masterUsageLoad, setMasterUsageLoad] = useState(true);
  const [emailBackgroundLoad, setEmailBackgroundLoad] = useState(true);
  const [artistLoad, setArtistLoad] = useState(true);
  const [timingLoad, setTimingLoad] = useState(true);
  const [pdfLoad, setPdfLoad] = useState(false);

  const setResourceForPdfFilename = name => {
    switch (name) {
      case "[job_serial]":
        return jobDetail?.serialNumber;
      case "[org_serial]":
        return jobDetail?.organisationSerial;
    }
  };

  const pdfFileName = () => {
    let fileName = "";
    const filenameComponents = emailBackground?.pdfFileName?.split("<>");

    filenameComponents?.map(component => {
      if (component.includes("[")) {
        fileName += setResourceForPdfFilename(component);
      } else {
        fileName += component;
      }
    });

    return fileName;
  };

  useEffect(() => {
    getBookingFormBuilderRecords();
  }, [paramToken]);

  const getBookingFormBuilderRecords = async () => {
    try {
      const response = await getBookingFormBuilder(paramToken, artistId);
      setJobDetail(response.data.job);
      setRevision(response.data.estimateRevision);
      setProjectScopes(response.data.projectScopes);
      setDeliveryScopes(response.data.deliveryScopes);
      setJobPhases(response.data.jobPhases);
      setArtist(response.data.artist);
      setRevisionItems(response.data.estimateRevisionItems);
      setTimingList(response.data.timings);
      setEmailBackground(response.data.emailBackgroundDetail);
      setMasterUsage(response.data.masterUsage);
      setCompanyDetails(response.data.companyDetails);
      setCompanyDetailLoad(false);
      setJobTypeSpecificTemplateSetting(
        response.data.templateSettings.find(
          template => template.jobTypeId === response.data.job.jobTypeId
        )
      );
      setDefaultTemplateSetting(
        response.data.templateSettings.find(
          template => template.default === true
        )
      );
      setJobTypeSpecificTemplateSettingLoad(false);

      setJobDetailLoad(false);
      setRevisionLoad(false);
      setProjectScopesLoad(false);
      setDeliveryScopesLoad(false);
      setJobPhasesLoad(false);
      setEmailBackgroundLoad(false);
      setMasterUsageLoad(false);
      setArtistLoad(false);
      setTimingLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const currencySymbol = currency => {
    if (CURRENCY_SYMBOL[currency] !== undefined) {
      return CURRENCY_SYMBOL[currency];
    } else {
      return "";
    }
  };

  const ammountWithCurrency = amount => {
    return `${currencySymbol(revision?.currency)}${numberWithCommas(
      Number(amount).toFixed(2)
    )} ${revision?.currency}`;
  };

  const creativeFees = () => {
    return revisionItems
      .filter(item => item.feeType === "artwork")
      .reduce((acc, item) => acc + Number(item.total), 0);
  };

  const applicableTax = () => {
    if (revision?.taxTypeId === artist?.taxTypeId) {
      return artist?.taxType;
    } else {
      return null;
    }
  };

  const taxAmount = () => {
    if (applicableTax() !== null) {
      return Number(
        Number(
          Number(
            Number(revision?.artistArtworkFee) +
              Number(revision?.artistUsageFee)
          ) * Number(jobDetail?.taxPercentage)
        ) / 100
      );
    } else {
      return 0;
    }
  };

  const templateLogo = () => {
    return jobTypeSpecificTemplateSetting?.attachments?.find(
      attachment => attachment.attachable_kind === "template_logo"
    );
  };

  const jobLogo = () => {
    if (templateLogo()) {
      return templateLogo().src;
    } else {
      return defaultTemplateSetting?.attachments?.find(
        attachment => attachment.attachable_kind === "pdf_footer_logo"
      )?.src;
    }
  };

  const getBookingFormPdfRecordsPrint = async () => {
    setPdfLoad(true);
    try {
      const response = await getBookingFormPdf(paramToken, artistId);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

      if (isSafari) {
        const link = document.createElement("a");
        link.href = url;
        link.download = `${pdfFileName() || "Booking Form"}.pdf`;
        link.click();
      } else {
        prntr({
          printable: url,
          type: "pdf",
          showModal: true,
        });
      }

      setPdfLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const isIE11 = navigator.userAgent.indexOf("Trident/") > -1;

  document.addEventListener(
    "keydown",
    function keyDownHandler(event) {
      // Watch for `metaKey` too (⌘ in Mac)
      if ((event.ctrlKey || event.metaKey) && event.keyCode === 80) {
        // Is the browser IE11?
        if (isIE11) {
          const end = Date.now() + 4000; // block the main thread for 4s.
          while (Date.now() < end) {
            // Deliberately no-op
          }
        }

        event.preventDefault();
        event.stopImmediatePropagation();
        getBookingFormPdfRecordsPrint();
      }
    },
    isIE11 ? { capture: true } : true
  );

  const getBookingFormPdfRecords = async () => {
    setPdfLoad(true);
    try {
      const response = await getBookingFormPdf(paramToken, artistId);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.download = `${pdfFileName() || "Booking Form"}.pdf`;
      link.click();
      setPdfLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  if (
    jobDetailLoad ||
    revisionLoad ||
    projectScopesLoad ||
    deliveryScopesLoad ||
    jobPhasesLoad ||
    masterUsageLoad ||
    emailBackgroundLoad ||
    artistLoad ||
    pdfLoad ||
    companyDetailLoad ||
    jobTypeSpecificTemplateSettingLoad ||
    timingLoad
  ) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="relative w-full px-4 py-8 bg-white lg:p-8 jw-template">
      <div className="flex flex-col w-full h-full">
        <BookingFormWelcome
          jobDetail={jobDetail}
          revision={revision}
          emailBackground={emailBackground}
          jobLogo={jobLogo}
          templateLogo={templateLogo}
          artist={artist}
        />
        <div className="flex flex-col w-full divide-y divide-black">
          <BookingFormOverview
            jobDetail={jobDetail}
            projectScopes={projectScopes}
            deliveryScopes={deliveryScopes}
            revision={revision}
            timingList={timingList}
            artist={artist}
            emailBackground={emailBackground}
          />

          <InView onChange={setInView}>
            {({ ref }) => (
              <div
                ref={ref}
                className="flex flex-col w-full divide-y divide-black"
              >
                {jobDetail.account.showBookingFormDetail && (
                  <BookingFormCreationFees
                    lineItemCategories={revision?.revisionLineItemCategories}
                    parentLessItemCount={revision?.parentLessItemCount}
                    revisionItems={revisionItems}
                    ammountWithCurrency={ammountWithCurrency}
                    creativeFees={creativeFees}
                    description={emailBackground?.approvedFeeDetails}
                  />
                )}

                {revision?.jobPhaseEnabled && jobPhases?.length > 0 && (
                  <ProjectPhases
                    data={revision?.jobPhaseEnabled ? jobPhases : []}
                    description={emailBackground?.phasesDetails}
                  />
                )}
                {revision?.usageEnabled && (
                  <BookingFormUsageSummary
                    usage={masterUsage}
                    ammountWithCurrency={ammountWithCurrency}
                    revision={revision}
                    description={emailBackground?.usageDetails}
                  />
                )}

                <BookingFormTotalCost
                  revision={revision}
                  ammountWithCurrency={ammountWithCurrency}
                  applicableTax={applicableTax}
                  taxAmount={taxAmount}
                  footerLogo={defaultTemplateSetting?.attachments?.find(
                    attachment =>
                      attachment.attachable_kind === "pdf_footer_logo"
                  )}
                  companyDetails={companyDetails}
                  illustratorAuthorLink={emailBackground?.artistUrl}
                  illustratorAuthorName={emailBackground?.artistName}
                  jobDetail={jobDetail}
                  footerDetails={emailBackground?.footerDetails}
                  totalCostDetails={emailBackground?.totalCostDetails}
                />
              </div>
            )}
          </InView>
        </div>
      </div>
      <PrintSave
        isVisible={inView}
        getPdfRecordsPrint={getBookingFormPdfRecordsPrint}
        getPdfRecords={getBookingFormPdfRecords}
      />
    </div>
  );
};

export default BookingForm;

import axios from "axios";

const getCurrenciesUrl = id => {
  if (id) {
    return `/api/v1/settings/currencies/${id}`;
  }

  return `/api/v1/settings/currencies`;
};

export const getCurrencies = (searchParams = "") => {
  let query = "";
  if (searchParams.length > 0) query = `q[name_i_cont_any]=${searchParams}`;
  return axios.get(`${getCurrenciesUrl()}?${query}`);
};

export const createCurrency = payload => {
  return axios.post(getCurrenciesUrl(), payload);
};

export const updateCurrency = (id, payload) => {
  return axios.put(getCurrenciesUrl(id), payload);
};

export const destroyCurrency = id => {
  return axios.delete(getCurrenciesUrl(id));
};

// Need clean up

import * as R from "ramda";

export const toArtistFormFormat = (item, markupRate) => ({
  artistName: {
    label: item.artistName,
    value: item.artistId,
  },
  percentage: item.markupPercentage,
  rate: calculatePercentage(markupRate, item.markupPercentage),
  estimateRevisionItemId: item.estimateRevisionItemId,
  artistExpense: item.artistExpense,
  advance: item.advance,
  id: item.id,
});

const calculatePercentage = (amount, percentage) => {
  return Number((amount * percentage) / 100).toFixed(2);
};

export const findIntersection = (a, b) => {
  const cmp = (x, y) => x.id === y.id;

  let difference = R.differenceWith(cmp, a, b);

  return difference;
};

export const checkStatus = (values, artist_markup_percentages_attributes) => {
  if (
    values.Assigned?.value?.length > 0 ||
    Number(values.commission) === 100.0
  ) {
    return "assigned";
  } else if (artist_markup_percentages_attributes?.length > 0) {
    let artistExist = artist_markup_percentages_attributes.find(item => {
      return !item._destroy;
    });
    if (artistExist) return "multiple";
    else return "unassigned";
  } else {
    return "unassigned";
  }
};

export const calculateMarkupRate = (total, percentage) => {
  return (total * (1 + percentage / 100)).toFixed(2);
};

export const revisionItemTotal = values => {
  let rate = values.Rate;
  let quantity = values.Quantity;
  let markupPercentage = values.Markup.percentage;
  let totalRate = quantity * rate;

  return values.Type.value === "commission"
    ? (rate * quantity).toFixed(2)
    : Number(calculateMarkupRate(totalRate, markupPercentage)).toFixed(2);
};

export const totalMarkupValueForAgency = values => {
  const artistPercentageForMarkup = values => {
    let sum = 0;
    values.Markup.artist?.map(markup => {
      return (sum = sum + Number(markup.percentage || 0));
    });

    return sum;
  };
  let rate = values.Rate;
  let quantity = values.Quantity;
  let markupPercentage = values.Markup.percentage;

  if (quantity && rate) {
    let totalRate = quantity * rate;
    let totalMarkedupRate =
      markupPercentage === 0
        ? totalRate
        : calculateMarkupRate(totalRate, markupPercentage);

    return (
      (totalMarkedupRate * (100 - artistPercentageForMarkup(values))) /
      100
    ).toFixed(2);
  }
};

export const agencyCommissionValue = values => {
  return (
    (values.Rate * values.Quantity * values.CommissionPercentage || 0) / 100
  ).toFixed(2);
};

export const calculteMarkupPercentage = (rate, total) => {
  return (((rate - total) / total) * 100).toFixed(4);
};

export const totalMarkupValueForArtist = (markup, values) => {
  let rate = values.Rate;
  let quantity = values.Quantity;
  let markupPercentage = values.Markup.percentage;
  let totalRate = quantity * rate;
  return (
    (Number(calculateMarkupRate(totalRate, markupPercentage)) *
      Number(markup.percentage)) /
    100
  ).toFixed(2);
};

import React from "react";
import LicenseMailerPreview from "./LicenseMailerPreview";

const MailViewer = ({
  formik,
  jobDetail,
  docUrl,
  companyDetails,
  revision,
  defaultTemplateSetting,
  emailBackground,
}) => {
  return (
    <div className="w-3/5 overflow-y-scroll">
      <div className="p-6 license-email-body">
        <LicenseMailerPreview
          formik={formik}
          jobDetail={jobDetail}
          docUrl={docUrl}
          companyDetails={companyDetails}
          revision={revision}
          defaultTemplateSetting={defaultTemplateSetting}
          emailBackground={emailBackground}
        />
      </div>
    </div>
  );
};

export default MailViewer;

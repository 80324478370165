import * as Yup from "yup";

export const PASSWORD_INITIAL_VALUE = {
  token: "",
  email: "",
  password: "",
  passwordConfirmation: "",
};

export const PASSWORD_SCHEMA = Yup.object({
  token: Yup.string().required("Token can't be empty"),
  email: Yup.string().email("Invalid email").required("Email can't be empty"),
  password: Yup.string().required("Password is required").min(6),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .min(6),
});

export const FORGOT_PASSWORD_INITIAL_VALUE = {
  email: "",
};

export const FORGOT_PASSWORD_SCHEMA = Yup.object({
  email: Yup.string().email("Invalid email").required("Email can't be empty"),
});

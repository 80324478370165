import React from "react";
import WelcomeEmail from "./WelcomeEmail";

const MailViewer = ({
  formik,
  jobDetail,
  companyDetails,
  defaultTemplateSetting,
  emailBackground,
}) => {
  return (
    <div className="w-3/5 overflow-y-scroll">
      <div className="p-6 welcome-email-body">
        <WelcomeEmail
          formik={formik}
          jobDetail={jobDetail}
          companyDetails={companyDetails}
          defaultTemplateSetting={defaultTemplateSetting}
          emailBackground={emailBackground}
        />
      </div>
    </div>
  );
};

export default MailViewer;

import axios from "axios";
import { DEFAULT_PAGE_SIZE } from "common/helper";

export const getOrganisations = (searchParams, page, id_array) => {
  if (searchParams || id_array) {
    let searchQuery = `q[name_i_cont_any]=${searchParams.replace(
      "&",
      ""
    )}&q[id_eq]=${searchParams}&q[m]=or&q[id_array]=${id_array}`;
    return axios.get(
      `/api/v1/job_module/organisations?${searchQuery}&page=${page}&per_page=${DEFAULT_PAGE_SIZE}`
    );
  }

  return axios.get(
    `/api/v1/job_module/organisations?page=${page}&per_page=${DEFAULT_PAGE_SIZE}`
  );
};

import axios from "axios";

const getMessagesUrl = (jobId, messageThreadId, id) => {
  if (id) {
    return `/api/v1/job_module/jobs/${jobId}/message_threads/${messageThreadId}/messages/${id}`;
  }

  return `/api/v1/job_module/jobs/${jobId}/message_threads/${messageThreadId}/messages`;
};

export const createMessage = (jobId, messageThreadId, payload) => {
  return axios.post(getMessagesUrl(jobId, messageThreadId), payload);
};

export const getJob = jobId => {
  return axios.get(`/api/v1/job_module/messages/jobs/${jobId}`);
};

export const getChatTemplate = (jobId, templateId) => {
  return axios.get(
    `/api/v1/job_module/messages/jobs/${jobId}/chat_templates/${templateId}`
  );
};

export const getAttachment = id => {
  return axios.get(`/api/v1/job_module/messages/attachments/${id}`, {
    responseType: "blob",
  });
};

export const sendOldThreads = (jobId, payload) => {
  return axios.post(`/api/v1/job_module/jobs/${jobId}/send_old_threads`, payload)
}

import React, { useState } from "react";
import { useUserState } from "contexts/user";
import useDebounce from "common/debounce";

import Header from "./Header";
import Overview from "./Overview";
import ChartView from "./ChartView";
import GlobalSearch from "./GlobalSearch";

const index = () => {
  const { user } = useUserState();
  const [searchTerm, setSearchTerm] = useState();
  const [location, setLocation] = useState(user?.location_id);
  const debouncedSearchParams = useDebounce(searchTerm, 500);

  return (
    <div className="w-full h-screen bg-gray-100">
      <Header
        location={location}
        setLocation={setLocation}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
      {debouncedSearchParams?.length > 0 ? (
        <GlobalSearch searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      ) : (
        <>
          <Overview location={location} />
          <ChartView location={location} />
        </>
      )}
    </div>
  );
};

export default index;

import React, { useState, useEffect, useContext } from "react";
import { Input, Label } from "@bigbinary/neetoui";
import { getTimings } from "apis/jobs/timings";
import { showToastrError } from "common";
import Timing from "./Timing";
import { OverviewDetailsContext } from ".";

const RevisionDetails = ({ jobPhaseEnabled, onRevisionRecordUpdate }) => {
  const { jobDetail, revisionDetail } = useContext(OverviewDetailsContext);
  const [revisionRate, setRevisionRate] = useState(0);
  const [revisionRound, setRevisionRound] = useState(0);
  const [timingList, setTimingList] = useState([{ note: "" }]);
  const [timingListLoading, setTimingListLoading] = useState(false);

  useEffect(() => {
    if (revisionDetail) {
      loadRevisionTiming();
      setRevisionRate(revisionDetail.revisionRate);
      setRevisionRound(revisionDetail.revisionRound);
    }
  }, [revisionDetail]);

  const loadRevisionTiming = async () => {
    try {
      const response = await getTimings(revisionDetail.id);
      setTimingList(
        response.data.timings?.length > 0
          ? response.data.timings
          : [{ note: "" }]
      );
      setTimingListLoading(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  return (
    <>
      <div className="flex flex-col w-full">
        {revisionDetail.revisionToggleEnabled && (
          <div className="grid w-full grid-cols-2 gap-4">
            <Input
              label="Rounds of Revision"
              type="number"
              value={revisionRound}
              onChange={e => {
                setRevisionRound(e.target.value);
              }}
              onBlur={e =>
                onRevisionRecordUpdate("revisionRound", e.target.value)
              }
            />

            <Input
              value={revisionRate}
              label="Revision Rate"
              type="number"
              required={!jobPhaseEnabled}
              onChange={e => {
                setRevisionRate(e.target.value);
              }}
              onBlur={e =>
                onRevisionRecordUpdate("revisionRate", e.target.value)
              }
            />
          </div>
        )}
        {!revisionDetail.jobPhaseEnabled && (
          <div className="flex flex-col mt-6 space-y-2">
            <Label>Timing</Label>
            <Timing
              revisionDetail={revisionDetail}
              jobDetail={jobDetail}
              loadRevisionTiming={loadRevisionTiming}
              timingList={timingList}
              setTimingList={setTimingList}
              timingLoading={timingListLoading}
              setTimingLoading={setTimingListLoading}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default RevisionDetails;

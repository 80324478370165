import React, { useState, useContext } from "react";
import { MenuHorizontal } from "@bigbinary/neeto-icons";
import { Table, Dropdown, Alert, Button } from "@bigbinary/neetoui";
import { destroyDefaultUsage } from "apis/settings/default_usages";
import { showToastrError } from "common";
import { DefaultUsageTemplateContext } from ".";

const List = ({ setShowPane, setIsEdit }) => {
  const {
    usageList,
    setUsage,
    setUsageItems,
    loadUsageData,
    loadUsageListData,
    setUsageName,
  } = useContext(DefaultUsageTemplateContext);
  const [deleteModalState, setDeleteModalState] = useState(false);
  const [deleteUsageId, setDeleteUsageId] = useState("");
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const { Menu, MenuItem } = Dropdown;

  const destroyUsageEntry = async usageId => {
    try {
      await destroyDefaultUsage(usageId);
      setUsage();
      setUsageItems([
        {
          name: "Usage Option 1",
          category: "",
          detail: "",
          quantity: "",
          period: "",
          exclusivityPeriod: "",
          fee: 0,
        },
      ]);
      setDeleteModalState(false);
      loadUsageListData("blank");
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const COLUMN_DATA = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "90%",
      render: (_, rowData) => (
        <Button
          style="link"
          label={rowData.name}
          onClick={() => {
            loadUsageData(rowData.id);
            setIsEdit(true);
            setShowPane(true);
          }}
        />
      ),
      fixed: "left",
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: "10%",
      render: (_, rowData) => (
        <Dropdown
          autoWidth
          closeOnSelect
          buttonStyle="text"
          strategy="fixed"
          icon={MenuHorizontal}
        >
          <Menu>
            <MenuItem.Button
              onClick={() => {
                loadUsageData(rowData.id);
                setIsEdit(true);
                setShowPane(true);
              }}
            >
              Edit
            </MenuItem.Button>
            <MenuItem.Button
              style="danger"
              onClick={() => {
                setUsageName();
                setDeleteModalState(true);
                setDeleteUsageId(rowData.id);
              }}
            >
              Delete
            </MenuItem.Button>
          </Menu>
        </Dropdown>
      ),
    },
  ];

  return (
    <>
      <Table fixedHeight columnData={COLUMN_DATA} rowData={usageList}                 currentPageNumber={pageIndex}
      defaultPageSize={pageSize}
      handlePageChange={(page, pageSize) => {
        setPageIndex(page);
        setPageSize(pageSize);
      }} />
      <Alert
        isOpen={deleteModalState}
        title="Delete Usage Template"
        message="Are you sure you want to delete the usage template?"
        onClose={() => setDeleteModalState(false)}
        onSubmit={() => destroyUsageEntry(deleteUsageId)}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, delete"
      />
    </>
  );
};

export default List;

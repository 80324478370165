import React, { useState } from "react";
import { Delete } from "@bigbinary/neeto-icons";
import { Textarea, Button, Input, Alert, Label, Tag } from "@bigbinary/neetoui";
import produce from "immer";
import { destroyPhase } from "apis/settings/phases";
import { showToastrError } from "common";

const MilestoneItem = ({
  phaseList,
  setPhaseList,
  loadPhaseList,
  setSaveBtnLoad,
}) => {
  const [deletingPhaseId, setDeletingPhaseId] = useState("");
  const [phaseTypeId, setPhaseTypeId] = useState("");
  const [deleteModalState, setDeleteModalState] = useState(false);

  const destroyPhaseEntry = async () => {
    try {
      await destroyPhase(phaseTypeId, deletingPhaseId);
      setDeleteModalState(false);
      loadPhaseList(phaseTypeId);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  return (
    <div className="flex flex-col w-full pb-8">
      <Label>Phases</Label>
      <div className="flex flex-col w-full mt-2 space-y-4">
        {phaseList?.map((item, index) => {
          return (
            <div
              className="flex flex-col p-4 space-y-4 bg-gray-100 rounded-md"
              key={index}
            >
              <Input
                placeholder="Name"
                required={true}
                value={item.name}
                onChange={e => {
                  setSaveBtnLoad(false);
                  setPhaseList(
                    produce(draft => {
                      draft[index] = {
                        ...draft[index],
                        name: e.target.value,
                      };
                    })
                  );
                }}
                prefix={<Tag label={index + 1} />}
                suffix={
                  item.id && (
                    <Button
                      style="text"
                      icon={Delete}
                      size="small"
                      onClick={() => {
                        setPhaseTypeId(item.phaseTypeId);
                        setDeleteModalState(true);
                        setDeletingPhaseId(item.id);
                      }}
                    />
                  )
                }
                error={
                  phaseList[index].name?.length > 0 ? "" : "Can't be Empty!"
                }
              />
              <div className="grid grid-cols-2 gap-4">
                <Textarea
                  value={item.description}
                  onChange={e => {
                    setSaveBtnLoad(false);
                    setPhaseList(
                      produce(draft => {
                        draft[index] = {
                          ...draft[index],
                          description: e.target.value,
                        };
                      })
                    );
                  }}
                  label="Description"
                />

                <Textarea
                  value={item.revision}
                  onChange={e => {
                    setSaveBtnLoad(false);
                    setPhaseList(
                      produce(draft => {
                        draft[index] = {
                          ...draft[index],
                          revision: e.target.value,
                        };
                      })
                    );
                  }}
                  label="Revision"
                />
              </div>
            </div>
          );
        })}
      </div>

      <div className="flex items-center justify-end w-full mt-2">
        <Button
          style="link"
          label="Add Phase"
          onClick={() => {
            setPhaseList(state => {
              return [
                ...state,
                {
                  name: "",
                  revision: "",
                  description: "",
                },
              ];
            });
          }}
        />
      </div>

      <Alert
        isOpen={deleteModalState}
        title="Delete Milestone Item"
        message="Are you sure you want to destroy the milestone item?"
        onClose={() => setDeleteModalState(false)}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, delete"
        onSubmit={() => destroyPhaseEntry()}
      />
    </div>
  );
};

export default MilestoneItem;

import axios from "axios";

const getRecipientsUrl = (jobId, messageThreadId, id) => {
  if (id) {
    return `/api/v1/job_module/jobs/${jobId}/message_threads/${messageThreadId}/recipients/${id}`;
  }

  return `/api/v1/job_module/jobs/${jobId}/message_threads/${messageThreadId}/recipients`;
};

export const createRecipient = (jobId, messageThreadId, payload) => {
  return axios.post(getRecipientsUrl(jobId, messageThreadId), payload);
};

export const destroyRecipient = (jobId, messageThreadId, id) => {
  return axios.delete(getRecipientsUrl(jobId, messageThreadId, id));
};

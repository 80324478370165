import React from "react";
import { Up, Down } from "@bigbinary/neeto-icons";
import { Label, Typography } from "@bigbinary/neetoui";
import { numberWithCommas } from "common/helper";
import EstimateAccordian from "components/Common/EstimateAccordian";
import { Accordion } from "@bigbinary/neetoui";
import ArtistLineItemDetail from "./ArtistLineItemDetail";

const ArtistFeeDetail = ({
  artist,
  jobDetail,
  revisionId,
  estimateRevisionItems,
}) => {
  const taxEligibility = () => {
    return (
      jobDetail.taxTypeId && jobDetail.taxTypeId === artist.artistTaxTypeId
    );
  };

  const totalTaxAmount = () => {
    if (taxEligibility()) {
      return Number(
        (Number(artist.fee) * Number(jobDetail.taxPercentage)) / 100
      ).toFixed(2);
    } else {
      return 0;
    }
  };

  const artistTotal = () => {
    return Number(artist.fee) + Number(totalTaxAmount());
  };

  return (
    <>
      <div className="p-3 mt-4 bg-gray-100 rounded-t-md">
        {!jobDetail.showBookingFormDetail && (
          <>
            <div className="flex justify-between px-3 mb-2">
              <Typography weight="semibold" style="h4">
                Item
              </Typography>
              <Typography weight="semibold" style="h4">
                Amount
              </Typography>
            </div>

            <div className="flex justify-between p-5 my-4 bg-white rounded-md">
              <Label className="font-medium">Artwork Creation</Label>
              <Label className="pl-1 font-bold text-gray-800">{`${numberWithCommas(
                Number(artist.fee || 0).toFixed(2)
              )} ${jobDetail.currency}`}</Label>
            </div>
          </>
        )}

        {jobDetail.showBookingFormDetail && artist.name && (
          <Accordion>
            <EstimateAccordian.Item
              className="p-3 bg-white shadow"
              CustomTitle={({ isOpen }) => {
                return (
                  <div className="flex items-center justify-between w-full bg-white rounded">
                    <Label className="font-medium">{artist.name}</Label>
                    <div className="flex items-center justify-end space-x-3">
                      <Label className="pl-1 font-bold text-gray-800">
                        {numberWithCommas(Number(artist.fee || 0).toFixed(2))}{" "}
                        {jobDetail?.currency}
                      </Label>
                      {isOpen ? <Up size={20} /> : <Down size={20} />}
                    </div>
                  </div>
                );
              }}
            >
              <ArtistLineItemDetail
                revisionItems={estimateRevisionItems}
                jobDetail={jobDetail}
                artist={artist}
              />
            </EstimateAccordian.Item>
          </Accordion>
        )}

        {taxEligibility() && (
          <div className="flex justify-between p-5 my-4 bg-white rounded-md">
            <Label className="font-medium">{artist.taxStatus}</Label>
            <span className="pl-1 font-bold text-gray-800">{`${numberWithCommas(
              Number(totalTaxAmount()).toFixed(2)
            )} ${jobDetail.currency}`}</span>
          </div>
        )}
      </div>

      <div className="flex justify-end w-full px-6 py-3 bg-gray-200 rounded-b-md">
        <div className="flex flex-col flex-end">
          <div className="self-end text-lg font-bold">
            Total:&nbsp;
            {`${numberWithCommas((artistTotal() || 0).toFixed(2))} ${
              jobDetail.currency
            }`}
          </div>
        </div>
      </div>
    </>
  );
};

export default ArtistFeeDetail;

import React from "react";
import { Footer } from "../Shared";

const Thankyou = ({
  illustratorAuthorLink,
  illustratorAuthorName,
  companyDetails,
  footerLogo,
  jobDetail,
  emailBackground,
}) => {
  const setResourceForDetails = name => {
    switch (name) {
      case "[staff_full_name]":
        return jobDetail.staffFullName;
      case "[staff_phone]":
        return jobDetail.staffPhone;
      case "[staff_designation]":
        return jobDetail.staffTitle;
    }
  };

  const footerDetails = () => {
    const detailComponents = emailBackground.footerDetails?.split("&lt;&gt;");
    let details = "";

    detailComponents?.map(component => {
      if (component.includes("[")) {
        details += setResourceForDetails(component);
      } else {
        details += component;
      }
    });

    return details;
  };

  return (
    <div className="flex flex-col items-start justify-between w-full pt-12 pb-24 lg:flex-row">
      <div
        className="flex flex-col items-start justify-start w-full lg:w-1/3 lg:h-full"
        style={{ fontFamily: "Modern Gothic" }}
      >
        <div className="flex flex-col items-start justify-between h-full space-y-20">
          <h2 className="hidden text-xl font-medium lg:block">Thank you</h2>
          <div className="flex-col items-start justify-end hidden space-y-6 lg:flex">
            <Footer
              jobDetail={jobDetail}
              companyDetails={companyDetails}
              footerLogo={footerLogo}
              illustratorAuthorName={illustratorAuthorName}
              illustratorAuthorLink={illustratorAuthorLink}
            />
          </div>
        </div>
      </div>
      <div
        className="flex flex-col items-center justify-between w-full h-full lg:items-start lg:w-2/3"
        style={{ fontFamily: "Modern Gothic" }}
      >
        <div className="flex flex-col items-center lg:items-start">
          <p dangerouslySetInnerHTML={{ __html: footerDetails()?.split("\n")?.join("<br />") }}>
          </p>
          <div className="w-auto h-20 mt-8">
            <img src={jobDetail.staffSignature} className="w-auto h-full" />
          </div>
        </div>
        <div className="flex flex-col items-center justify-end w-full pt-12 lg:hidden">
          <Footer
            jobDetail={jobDetail}
            companyDetails={companyDetails}
            footerLogo={footerLogo}
            illustratorAuthorName={illustratorAuthorName}
            illustratorAuthorLink={illustratorAuthorLink}
          />
        </div>
      </div>
    </div>
  );
};

export default Thankyou;

import React from "react";
import EstimateMailerPreview from "./EstimateMailerPreview";

const MailViewer = ({
  formik,
  jobDetail,
  host,
  token,
  licenseCount,
  companyDetails,
  revision,
  defaultTemplateSetting,
  emailBackground,
}) => {
  const docUrl =
    licenseCount > 1
      ? `${host}/estimate_multi?token=${token}`
      : `${host}/estimate?token=${token}`;
  return (
    <div className="w-3/5 overflow-y-scroll">
      <div className="p-6 estimate-email-body">
        <EstimateMailerPreview
          formik={formik}
          jobDetail={jobDetail}
          docUrl={docUrl}
          companyDetails={companyDetails}
          revision={revision}
          defaultTemplateSetting={defaultTemplateSetting}
          emailBackground={emailBackground}
        />
      </div>
    </div>
  );
};

export default MailViewer;

import React from "react";
import { Typography } from "@bigbinary/neetoui";
import classNames from "classnames";
import { artistModuleName } from "common/helper";

const ArtistList = ({ list, setArtist, artistId }) => {
  return (
    <div className="w-full">
      <div className="flex flex-col space-y-2">
        <Typography style="h4" weight="semibold">
          {artistModuleName()} List
        </Typography>
        <div className="flex flex-col space-y-3">
          {list?.map(artist => {
            return (
              <div
                className={classNames(
                  "relative p-4 bg-white border neeto-ui-shadow-xs rounded-md cursor-pointer hover:neeto-ui-border-gray-500 focus:neeto-ui-border-gray-500",
                  {
                    "neeto-ui-border-primary-600": artistId === artist.artistId,
                    "neeto-ui-border-gray-200": artistId !== artist.artistId,
                  }
                )}
                key={artist.artistId}
                onClick={() => setArtist(artist)}
              >
                <Typography style="h5" weight="semibold">
                  {artist.name}
                </Typography>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ArtistList;

import axios from "axios";

export const jobDetailsPushToBasecamp = jobId => {
  return axios.post(`/api/v1/job_module/jobs/${jobId}/push_to_basecamps`);
};

export const jobPhasesPushToBasecamp = (estimateRevisionId, phaseTypeId) => {
  return axios.post(
    `/api/v1/job_module/job_phases/estimate_revisions/${estimateRevisionId}/push_to_basecamps?phase_type_id=${phaseTypeId}`
  );
};

import axios from "axios";
import { direction } from "common/helper";

export const getOrganisationJobs = (
  organisationId,
  searchParams = "",
  sortProps = {},
  pageIndex,
  perPage
) => {
  let paginationQuery = `page=${pageIndex}&per_page=${perPage}`;
  let searchQuery = `q[name_i_cont_any]=${searchParams}&q[user_full_name_i_cont_any]=${searchParams}&q[job_serial_number_i_cont_any]=${searchParams}&q[artists_full_name_i_cont_any]=${searchParams}&q[status_i_cont_any]=${searchParams}&q[m]=or`;
  let sortQuery = `q[s]=${sortProps?.column?.field}+${direction(sortProps?.order)}`;
  let query = paginationQuery;
  if (searchParams.length > 0) query += `&${searchQuery}`;
  if (sortProps?.column && sortProps?.order) query += `&${sortQuery}`;

  return axios.get(
    `/api/v1/organisations/organisations/${organisationId}/jobs?${query}`
  );
};

import React, { useState, useEffect } from "react";
import * as dayjs from "dayjs";
import {
  Spinner,
  Input,
  Label,
  Select,
  Switch,
  DatePicker,
  Textarea,
} from "@bigbinary/neetoui";
import { Help } from "@bigbinary/neeto-icons";
import ContactUpload from "components/Common/ContactUpload";
import SingleImageUploader from "components/Common/SingleImageUploader";
import { ACCESS_LIST } from "../constants";
import { getLocations } from "apis/settings/locations";
import { showToastrError } from "common";
import { dropDownListGenerator } from "common/helper";
import { useUserState } from "contexts/user";

const StaffForm = ({ inputRef, formik }) => {
  const { user } = useUserState();
  const [locationList, setLocationList] = useState([]);
  const [locationListLoad, setLocationListLoad] = useState(true);

  useEffect(() => {
    loadLocationList();
  }, []);

  const loadLocationList = async () => {
    try {
      const response = await getLocations();
      setLocationList(
        dropDownListGenerator(response.data.locations, "shortName")
      );
      setLocationListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  if (locationListLoad) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="w-full pb-8 space-y-6">
      <Input
        label="First Name"
        name="firstName"
        ref={inputRef}
        value={formik.values.firstName}
        onChange={formik.handleChange}
        required={true}
        error={
          Boolean(formik.touched.firstName && formik.errors.firstName) &&
          formik.errors.firstName
        }
        {...formik.getFieldProps("firstName")}
      />
      <Input
        label="Last Name"
        name="lastName"
        value={formik.values.lastName}
        onChange={formik.handleChange}
        required={true}
        error={
          Boolean(formik.touched.lastName && formik.errors.lastName) &&
          formik.errors.lastName
        }
        {...formik.getFieldProps("lastName")}
      />
      <Input
        label="Pronouns"
        name="pronouns"
        value={formik.values.pronouns}
        onChange={formik.handleChange}
        error={
          Boolean(formik.touched.pronouns && formik.errors.pronouns) &&
          formik.errors.pronouns
        }
        {...formik.getFieldProps("pronouns")}
      />
      <Input
        label="Email"
        name="email"
        value={formik.values.email}
        onChange={formik.handleChange}
        required={true}
        error={
          Boolean(formik.touched.email && formik.errors.email) &&
          formik.errors.email
        }
        {...formik.getFieldProps("email")}
      />
      <Input
        label="Title"
        required={true}
        name="title"
        value={formik.values.title}
        onChange={formik.handleChange}
        error={
          Boolean(formik.touched.title && formik.errors.title) &&
          formik.errors.title
        }
        {...formik.getFieldProps("title")}
      />
      <Input
        label="Phone Number"
        name="phoneNumber"
        value={formik.values.phoneNumber}
        onChange={formik.handleChange}
        required={true}
        error={
          Boolean(formik.touched.phoneNumber && formik.errors.phoneNumber) &&
          formik.errors.phoneNumber
        }
        {...formik.getFieldProps("phoneNumber")}
      />

      <DatePicker
        label="DOB"
        name="dateOfBirth"
        dateFormat="DD/MM/YYYY"
        value={
          formik.values.dateOfBirth
            ? dayjs(formik.values.dateOfBirth).toDate()
            : null
        }
        disabledDate={current =>
          current && current.valueOf() > dayjs().toDate()
        }
        onChange={date => {
          if (date) {
            formik.setFieldValue("dateOfBirth", dayjs(date).toDate());
          } else {
            formik.setFieldValue("dateOfBirth", null);
          }
        }}
        getPopupContainer={triggerNode => triggerNode.parentNode}
      />

      <Textarea
        label="Home Address"
        placeholder="Enter address"
        name="homeAddress"
        value={formik.values.homeAddress}
        error={
          Boolean(formik.touched.homeAddress && formik.errors.homeAddress) &&
          formik.errors.homeAddress
        }
        {...formik.getFieldProps("homeAddress")}
      />

      <DatePicker
        label="Joining Date"
        name="joiningDate"
        dateFormat="DD/MM/YYYY"
        allowClear={true}
        value={
          formik.values.joiningDate
            ? dayjs(formik.values.joiningDate).toDate()
            : null
        }
        disabledDate={current =>
          current && current.valueOf() > dayjs().toDate()
        }
        onChange={date => {
          if (date) {
            formik.setFieldValue("joiningDate", dayjs(date).toDate());
          } else {
            formik.setFieldValue("joiningDate", null);
          }
        }}
        getPopupContainer={triggerNode => triggerNode.parentNode}
      />

      <Input
        label="Bank Details"
        placeholder="Enter bank details"
        name="bankDetails"
        value={formik.values.bankDetails}
        error={
          Boolean(formik.touched.bankDetails && formik.errors.bankDetails) &&
          formik.errors.bankDetails
        }
        {...formik.getFieldProps("bankDetails")}
      />

      <Input
        label="Personal Email"
        name="personalEmail"
        value={formik.values.personalEmail}
        onChange={formik.handleChange}
        error={
          Boolean(
            formik.touched.personalEmail && formik.errors.personalEmail
          ) && formik.errors.personalEmail
        }
        {...formik.getFieldProps("personalEmail")}
      />

      {(user.access === "admin" || user.access === "super_admin") && (
        <Select
          name="access"
          label="Access"
          required={true}
          onChange={e => {
            formik.setFieldValue("access", e.value);

            if (e.value === "admin" || e.value === "super_admin") {
              formik.setFieldValue("accessAmountInCounter", true);
            } else {
              formik.setFieldValue("accessAmountInCounter", false);
            }
          }}
          value={ACCESS_LIST.find(
            access => access.value === formik.values.access
          )}
          options={ACCESS_LIST}
          error={
            Boolean(formik.touched.access && formik.errors.access) &&
            formik.errors.access
          }
        />
      )}

      <Select
        label="Location"
        name="location"
        required={true}
        placeholder="Select a Location"
        onChange={async selectedValue => {
          await formik.setFieldValue("locationId", selectedValue.value);
        }}
        value={locationList.find(
          country => country.value === formik.values.locationId
        )}
        options={locationList}
        error={
          Boolean(formik.touched.locationId && formik.errors.locationId) &&
          formik.errors.locationId
        }
      />

      <div className="flex flex-col space-y-2">
        <Label>Welcome Email</Label>
        <SingleImageUploader
          name="Welcome Email"
          type="welcome_email"
          formValue={formik.values}
          setFormValue={formik.setFieldValue}
        />
      </div>

      <div className="flex flex-col space-y-2">
        <Label>Headshot</Label>
        <SingleImageUploader
          name="Headshot"
          type="hero"
          formValue={formik.values}
          setFormValue={formik.setFieldValue}
        />
      </div>

      <div className="flex flex-col space-y-2">
        <Label>Signature(.png only)</Label>
        <SingleImageUploader
          name="Signature"
          type="signature"
          formValue={formik.values}
          setFormValue={formik.setFieldValue}
          accept="image/png"
        />
      </div>
      <div className="flex flex-col space-y-2">
        <Label>Contact VCF Cards</Label>
        <ContactUpload
          name="attachments"
          formValue={formik.values}
          setFormValue={formik.setFieldValue}
        />
      </div>

      <div className="flex flex-col space-y-3">
        <Label className="font-semibold">Job Approvals</Label>
        <div className="flex flex-col space-y-4">
          <Switch
            label="Management 1"
            checked={formik.values.management1}
            onChange={() => {
              formik.setFieldValue("management1", !formik.values.management1);
            }}
          />
          <Switch
            label="Management 2"
            checked={formik.values.management2}
            onChange={() => {
              formik.setFieldValue("management2", !formik.values.management2);
            }}
          />
        </div>
      </div>
      <div className="flex flex-col space-y-3">
        <Label className="font-semibold">State</Label>
        <Switch
          label="Archive"
          checked={formik.values.archive}
          onChange={() => {
            formik.setFieldValue("archive", !formik.values.archive);
          }}
        />
      </div>

      {user.access === "admin" && formik.values.access === "team" && (
        <div className="flex flex-col space-y-3">
          <Label className="font-semibold">Xero Push Access</Label>
          <div className="flex flex-col space-y-4">
            <Switch
              label="Enable"
              labelProps={{
                helpIconProps: {
                  icon: Help,
                  tooltipProps: {
                    content: "Enable this user for Xero Push!",
                    position: "right",
                    hideAfter: 3000,
                  },
                },
              }}
              checked={formik.values.enableXeroPush}
              onChange={() => {
                formik.setFieldValue(
                  "enableXeroPush",
                  !formik.values.enableXeroPush
                );
              }}
            />
          </div>
        </div>
      )}

      {(user.access === "admin" || user.access === "super_admin") && (
        <div className="flex flex-col space-y-3">
          <Label className="font-semibold">Team Access</Label>
          <Switch
            label="Amount"
            labelProps={{
              helpIconProps: {
                icon: Help,
                tooltipProps: {
                  content:
                    "If toggled, staff can see the amount in dashboard counter!",
                  position: "right",
                  hideAfter: 3000,
                },
              },
            }}
            checked={formik.values.accessAmountInCounter}
            onChange={() => {
              formik.setFieldValue(
                "accessAmountInCounter",
                !formik.values.accessAmountInCounter
              );
            }}
          />
        </div>
      )}
    </div>
  );
};

export default StaffForm;

import React, { useState } from "react";
import Accordion from "../Shared/Accordion";

const TermsTable = ({ data, index }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className="w-full text-base regular-table description"
      style={{ fontFamily: "Modern Gothic" }}
    >
      <Accordion>
        <div
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          className="w-full p-4 transition-all cursor-pointer rounded_container lg:p-6"
          style={{ backgroundColor: isOpen ? "#EDE0D4" : "#D1D3D4" }}
        >
          <Accordion.Item
            isOpen={isOpen}
            title={
              <div
                className={`flex items-center justify-between w-full ${
                  isOpen ? "border-b border-black pb-3" : ""
                }`}
              >
                <div className="pr-0 lg:pr-5">
                  <h2 className="text-xl font-medium">{data.section}</h2>
                </div>
                <hr />
                <div className="flex flex-row items-center justify-center w-6 h-6 p-1 mb-1 text-lg font-bold text-center text-white whitespace-no-wrap bg-gray-900 rounded-full lg:ml-auto">
                  <span className="leading-none">{index}</span>
                </div>
              </div>
            }
          >
            <div
              dangerouslySetInnerHTML={{
                __html: data.description,
              }}
            ></div>
          </Accordion.Item>
        </div>
      </Accordion>
    </div>
  );
};

export default TermsTable;

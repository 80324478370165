import axios from "axios";

export const getSocialJobs = (
  searchParams = "",
  pageIndex,
  perPage,
  userId
) => {
  let paginationQuery = `page=${pageIndex}&per_page=${perPage}`;
  let searchQuery = `q[name_i_cont_any]=${searchParams}&q[user_full_name_i_cont_any]=${searchParams}&q[job_serial_number_i_cont_any]=${searchParams}&q[artists_full_name_i_cont_any]=${searchParams}&q[organisation_name_i_cont_any]=${searchParams}&q[m]=or`;
  let query = paginationQuery;
  if (searchParams.length > 0) query += `&${searchQuery}`;
  if (userId?.length > 0) query += `&user_id=${userId}`;

  return axios.get(`/api/v1/post_productions/socials?${query}`);
};

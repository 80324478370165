import React, { useState, useRef, useEffect } from "react";
import { MenuHorizontal } from "@bigbinary/neeto-icons";
import {
  Spinner,
  Button,
  Alert,
  Input,
  Modal,
  Dropdown,
  Typography,
  Table,
} from "@bigbinary/neetoui";
import { Header, Container } from "@bigbinary/neetoui/layouts";
import { isEmpty } from "ramda";
import { showToastrError } from "common";
import { getRandomNotFoundImage } from "common/helper";
import {
  getLineItemCategories,
  createLineItemCategory,
  updateLineItemCategory,
  destroyLineItemCategory,
} from "apis/settings/line_item_categories";
import EmptyState from "components/Common/EmptyState";

const LineItemCategory = ({ breadcrumbs }) => {
  const inputRef = useRef();
  const [lineItemCategoryList, setLineItemCategoryList] = useState([]);
  const [lineItemCategoryListLoad, setLineItemCategoryListLoad] = useState(
    true
  );
  const [lineItemCategoryId, setLineItemCategoryId] = useState("");
  const [modalState, setModalState] = useState(false);
  const [deleteModalState, setDeleteModalState] = useState(false);
  const [name, setName] = useState("");
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const { Menu, MenuItem } = Dropdown;
  const [emptyImage, setEmptyImage] = useState();

  useEffect(() => {
    loadEstimateLineItemData();
    setEmptyImage(getRandomNotFoundImage());
  }, []);

  const loadEstimateLineItemData = async () => {
    loadLineItemCategoryList();
  };

  const loadLineItemCategoryList = async () => {
    try {
      const lineItemCategoryResponse = await getLineItemCategories();
      setLineItemCategoryList(
        lineItemCategoryResponse.data.lineItemCategories || []
      );
      setLineItemCategoryListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const resetAllStates = () => {
    loadLineItemCategoryList();
    setLineItemCategoryId("");
    setModalState(false);
    setDeleteModalState(false);
    setName("");
  };

  const createLineItemCategoryEntry = async () => {
    try {
      await createLineItemCategory({
        lineItemCategory: { name: name },
      });
      resetAllStates();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const updateLineItemCategoryEntry = async () => {
    try {
      await updateLineItemCategory(lineItemCategoryId, {
        lineItemCategory: { name: name },
      });
      resetAllStates();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const destroyLineItemCategoryEntry = async () => {
    try {
      await destroyLineItemCategory(lineItemCategoryId);
      resetAllStates();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const COLUMN_DATA = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "90%",
      render: (_, rowData) => <>{rowData.name ? rowData.name : "-"}</>,
      fixed: "left",
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: "10%",
      render: (_, rowData) => (
        <Dropdown
          autoWidth
          closeOnSelect
          buttonStyle="text"
          strategy="fixed"
          icon={MenuHorizontal}
        >
          <Menu>
            <MenuItem.Button
              onClick={() => {
                setLineItemCategoryId(rowData.id);
                setName(rowData.name);
                setModalState(true);
              }}
            >
              Edit
            </MenuItem.Button>
            <MenuItem.Button
              style="danger"
              onClick={() => {
                setLineItemCategoryId(rowData.id);
                setDeleteModalState(true);
              }}
            >
              Delete
            </MenuItem.Button>
          </Menu>
        </Dropdown>
      ),
    },
  ];

  if (lineItemCategoryListLoad) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <Container>
      <Header
        title="Line Item Categories"
        breadcrumbs={breadcrumbs}
        actionBlock={
          <Button
            label="Add Line Item Category"
            onClick={() => {
              setName("");
              setLineItemCategoryId("");
              setModalState(true);
            }}
          />
        }
      />
      <div className="w-full" style={{ height: "calc(100vh - 128px)" }}>
        {!isEmpty(lineItemCategoryList) ? (
          <Table
            fixedHeight
            columnData={COLUMN_DATA}
            rowData={lineItemCategoryList}
            currentPageNumber={pageIndex}
            defaultPageSize={pageSize}
            handlePageChange={(page, pageSize) => {
              setPageIndex(page);
              setPageSize(pageSize);
            }}
          />
        ) : (
          <EmptyState
            image={emptyImage}
            title="No Line Item Categories Found"
            description="We couldn’t find any line item categories. Try creating one."
            primaryButtonProps={{
              label: "Add Line Item Category",
              onClick: () => {
                setName("");
                setLineItemCategoryId("");
                setModalState(true);
              },
            }}
          />
        )}
      </div>
      <Modal
        isOpen={modalState}
        onClose={() => setModalState(false)}
        initialFocusRef={inputRef}
      >
        <Modal.Header>
          <Typography style="h2" weight="semibold">
            {lineItemCategoryId
              ? "Edit Line Item Category"
              : "Add Line Item Category"}
          </Typography>
        </Modal.Header>

        <Modal.Body>
          <Input
            label="Name"
            name="name"
            value={name}
            ref={inputRef}
            onChange={e => setName(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer className="flex gap-x-2">
          <Button
            label="Save changes"
            onClick={() => {
              if (lineItemCategoryId) {
                updateLineItemCategoryEntry();
              } else {
                createLineItemCategoryEntry();
              }
            }}
          />
          <Button
            label="Cancel"
            style="text"
            onClick={() => setModalState(false)}
          />
        </Modal.Footer>
      </Modal>

      <Alert
        isOpen={deleteModalState}
        title="Delete Line Item Category"
        message="Are you sure you want to delete the Line Item Category?"
        onClose={() => setDeleteModalState(false)}
        onSubmit={() => destroyLineItemCategoryEntry()}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, delete"
      />
    </Container>
  );
};

export default LineItemCategory;

import React, { useState, useRef, useEffect } from "react";
import {
  Spinner,
  Button,
  Alert,
  Input,
  Modal,
  Typography,
  Radio,
  Label,
  Switch,
} from "@bigbinary/neetoui";
import { Header, Container } from "@bigbinary/neetoui/layouts";
import { isEmpty } from "ramda";
import { useFormik } from "formik";
import {
  getCrmActionTags,
  createCrmActionTag,
  updateCrmActionTag,
  destroyCrmActionTag,
} from "apis/settings/crm_action_tags";
import { showToastrError } from "common";
import { getRandomNotFoundImage } from "common/helper";
import {
  CRM_ACTION_TAG_VALIDATION_SCHEMA,
  CRM_ACTION_TAG_INITIAL_VALUE,
} from "./constants";
import List from "./List";
import EmptyState from "components/Common/EmptyState";

const CrmActionTags = ({ breadcrumbs }) => {
  const inputRef = useRef();
  const [crmActionTagList, setCrmActionTagList] = useState([]);
  const [crmActionTagListLoad, setCrmActionTagListLoad] = useState(true);
  const [crmActionTag, setCrmActionTag] = useState();
  const [modalState, setModalState] = useState(false);
  const [archiveAlertState, setArchiveAlertState] = useState(false);
  const [deleteModalState, setDeleteModalState] = useState(false);
  const [emptyImage, setEmptyImage] = useState();
  const [btnLoader, setBtnLoader] = useState(false);

  useEffect(() => {
    loadData();
    setEmptyImage(getRandomNotFoundImage());
  }, []);

  const loadData = async () => {
    loadCrmActionTagListResponse();
  };

  const loadCrmActionTagListResponse = async () => {
    try {
      const crmActionTagListResponse = await getCrmActionTags();
      setCrmActionTagList(crmActionTagListResponse.data.crmActionTags || []);
      setCrmActionTagListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: crmActionTag ? crmActionTag : CRM_ACTION_TAG_INITIAL_VALUE,
    validationSchema: CRM_ACTION_TAG_VALIDATION_SCHEMA,
    onSubmit: () => {
      if (crmActionTag) {
        updateCrmActionTagEntry();
      } else {
        createCrmActionTagEntry();
      }
    },
  });

  const createCrmActionTagEntry = async () => {
    try {
      await createCrmActionTag({
        crm_action_tag: {
          name: formik.values.name,
          status: formik.values.status,
          archive: formik.values.archive
        },
      });
      loadCrmActionTagListResponse();
      setModalState(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const updateCrmActionTagEntry = async (archived = formik.values.archive) => {
    try {
      setBtnLoader(true);
      await updateCrmActionTag(crmActionTag.id, {
        crm_action_tag: {
          name: formik.values.name,
          status: formik.values.status,
          archive: archived
        },
      });
      formik.resetForm();
      loadCrmActionTagListResponse();
      setModalState(false);
      setArchiveAlertState(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      setBtnLoader(false);
    }
  };

  const destroyCrmActionTagEntry = async () => {
    try {
      setBtnLoader(true);
      await destroyCrmActionTag(crmActionTag.id);
      loadCrmActionTagListResponse();
      setDeleteModalState(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      setBtnLoader(false);
    }
  };

  if (crmActionTagListLoad) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <Container>
      <Header
        title="CRM Action Tags"
        breadcrumbs={breadcrumbs}
        actionBlock={
          <Button
            label="Add Action Tag"
            onClick={() => {
              formik.resetForm();
              setCrmActionTag();
              setModalState(true);
            }}
          />
        }
      />
      <div className="w-full" style={{ height: "calc(100vh - 128px)" }}>
        {!isEmpty(crmActionTagList) ? (
          <List
            setCrmActionTag={setCrmActionTag}
            setModalState={setModalState}
            setDeleteModalState={setDeleteModalState}
            crmActionTagList={crmActionTagList}
            setArchiveAlertState={setArchiveAlertState}
          />
        ) : (
          <EmptyState
            image={emptyImage}
            title="No CRM Action Tags Found"
            description="We couldn’t find any CRM action tags. Try creating one."
            primaryButtonProps={{
              label: "Add Action Tag",
              onClick: () => {
                formik.resetForm();
                setCrmActionTag();
                setModalState(true);
              },
            }}
          />
        )}
      </div>

      <Modal
        isOpen={modalState}
        onClose={() => setModalState(false)}
        initialFocusRef={inputRef}
      >
        <Modal.Header>
          <Typography style="h2" weight="semibold">
            {crmActionTag ? "Edit Action Tag" : "Add Action Tag"}
          </Typography>
        </Modal.Header>
        <Modal.Body>
          <div className="flex flex-col w-full space-y-6">
            <Input
              label="Name"
              autoFocus={true}
              required={true}
              ref={inputRef}
              onChange={formik.handleChange}
              value={formik.values.name}
              error={
                Boolean(formik.touched.name && formik.errors.name) &&
                formik.errors.name
              }
              {...formik.getFieldProps("name")}
            />
            <Label required={true}>Status</Label>
            <Radio>
              <Radio.Item
                label="Open"
                checked={formik.values.status === "open"}
                name="status"
                onChange={() => formik.setFieldValue("status", "open")}
              />
              <Radio.Item
                label="Completed"
                checked={formik.values.status === "completed"}
                name="status"
                onChange={() => formik.setFieldValue("status", "completed")}
              />
            </Radio>

            <Switch
              label="Archive"
              checked={formik.values.archive}
              onChange={() => {
                formik.setFieldValue(
                  "archive",
                  !formik.values.archive
                );
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="flex gap-x-2">
          <Button
            label="Save changes"
            onClick={() => {
              formik.handleSubmit();
            }}
          />
          <Button
            label="Cancel"
            style="text"
            onClick={() => setModalState(false)}
          />
        </Modal.Footer>
      </Modal>

      <Alert
        isOpen={deleteModalState}
        title="Delete Action Tag"
        message="Are you sure you want to delete the action tag?"
        onClose={() => setDeleteModalState(false)}
        onSubmit={() => destroyCrmActionTagEntry()}
        isSubmitting={btnLoader}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, delete"
      />

      <Alert
        isOpen={archiveAlertState}
        title="Archive Action Tag"
        message="Are you sure you want to archive the action tag?"
        onClose={() => setArchiveAlertState(false)}
        onSubmit={() => updateCrmActionTagEntry(true)}
        isSubmitting={btnLoader}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, Archive"
      />
    </Container>
  );
};

export default CrmActionTags;

import React, { useState, useEffect } from "react";
import { getRciBuilder } from "apis/email_templates/builders";
import { getRciPdf } from "apis/email_templates/pdfs";
import { useLocation } from "react-router-dom";
import { Spinner } from "@bigbinary/neetoui";
import { showToastrError } from "common";
import { numberWithCommas } from "common/helper";
import * as dayjs from "dayjs";
import prntr from "prntr";
import "../Styles/common";
import { CURRENCY_SYMBOL } from "../constants";
import {
  approvedAmount,
  approvedAmountWithExchangeRateAndPercentage,
  approvedAmountWithExchangeRateAndPercentageWithoutadditionalCost,
  approvedAmountWithExchangeRateWithoutAdditionalFees,
} from "./utils";
import PrintSave from "../Shared/PrintSave";

const RecipientCreatedTaxInvoice = () => {
  const location = useLocation();
  const paramToken = location?.search?.split("?token=")[1];
  const [jobDetail, setJobDetail] = useState();
  const [artist, setArtist] = useState();
  const [rci, setRci] = useState();
  const [companyDetail, setCompanyDetail] = useState();
  const [emailBackground, setEmailBackground] = useState();
  const [defaultTemplateSetting, setDefaultTemplateSetting] = useState();
  const [
    jobTypeSpecificTemplateSetting,
    setJobTypeSpecificTemplateSetting,
  ] = useState();
  const [
    jobTypeSpecificTemplateSettingLoad,
    setJobTypeSpecificTemplateSettingLoad,
  ] = useState();

  const [jobDetailLoad, setJobDetailLoad] = useState(true);
  const [emailBackgroundLoad, setEmailBackgroundLoad] = useState(true);
  const [artistLoad, setArtistLoad] = useState(true);
  const [rciLoad, setRciLoad] = useState(false);
  const [companyDetailLoad, setCompanyDetailLoad] = useState(true);
  const [pdfLoad, setPdfLoad] = useState(false);

  const setResourceForPdfFilename = name => {
    switch (name) {
      case "[job_serial]":
        return jobDetail?.serialNumber;
      case "[org_serial]":
        return jobDetail?.organisationSerial;
      case "[paperwork_serial]":
        return rci?.serialNumber;
    }
  };

  const pdfFileName = () => {
    let fileName = "";
    const filenameComponents = emailBackground?.pdfFileName?.split("<>");

    filenameComponents?.map(component => {
      if (component.includes("[")) {
        fileName += setResourceForPdfFilename(component);
      } else {
        fileName += component;
      }
    });

    return fileName;
  };

  useEffect(async () => {
    getRciBuilderRecords();
  }, []);

  useEffect(() => {
    document.title = `${rci?.rciType} RCI`;
  }, [rci]);

  const getRciBuilderRecords = async () => {
    try {
      const response = await getRciBuilder(paramToken);
      setJobDetail(response.data.job);
      setArtist(response.data.artist);
      setRci(response.data.rci);
      setCompanyDetail(response.data.companyDetail);
      setCompanyDetailLoad(false);
      setJobTypeSpecificTemplateSetting(
        response.data.templateSettings.find(
          template => template.jobTypeId === response.data.job.jobTypeId
        )
      );
      setDefaultTemplateSetting(
        response.data.templateSettings.find(
          template => template.default === true
        )
      );
      setEmailBackground(response.data.emailBackgroundDetails[0]);
      setJobTypeSpecificTemplateSettingLoad(false);

      setJobDetailLoad(false);
      setEmailBackgroundLoad(false);
      setArtistLoad(false);
      setRciLoad(false);
      setCompanyDetailLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const getRciPdfRecordsPrint = async () => {
    setPdfLoad(true);
    try {
      const response = await getRciPdf(paramToken);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

      if (isSafari) {
        const link = document.createElement("a");
        link.href = url;
        link.download = `${pdfFileName() || "Rci"}.pdf`;
        link.click();
      } else {
        prntr({
          printable: url,
          type: "pdf",
          showModal: true,
        });
      }
      setPdfLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const isIE11 = navigator.userAgent.indexOf("Trident/") > -1;

  document.addEventListener(
    "keydown",
    function keyDownHandler(event) {
      // Watch for `metaKey` too (⌘ in Mac)
      if ((event.ctrlKey || event.metaKey) && event.keyCode === 80) {
        // Is the browser IE11?
        if (isIE11) {
          const end = Date.now() + 4000; // block the main thread for 4s.
          while (Date.now() < end) {
            // Deliberately no-op
          }
        }

        event.preventDefault();
        event.stopImmediatePropagation();
        getRciPdfRecordsPrint();
      }
    },
    isIE11 ? { capture: true } : true
  );

  const getRciPdfRecords = async () => {
    setPdfLoad(true);
    try {
      const response = await getRciPdf(paramToken);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.download = `${pdfFileName() || "RCI"}.pdf`;
      link.click();
      setPdfLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const currencySymbol = currency => {
    if (CURRENCY_SYMBOL[currency] !== undefined) {
      return CURRENCY_SYMBOL[currency];
    } else {
      return "";
    }
  };

  const ammountWithCurrency = (amount, currency = null) => {
    return currency
      ? `${currencySymbol(currency)}${numberWithCommas(
          Number(amount).toFixed(2)
        )} ${currency}`
      : `${currencySymbol(rci?.currencyName)}${numberWithCommas(
          Number(amount).toFixed(2)
        )} ${rci?.currencyName}`;
  };

  const totalTaxAmount = () => {
    return rci?.taxTypeName
      ? (
          (approvedAmountWithExchangeRateAndPercentage(
            rci.rciItems,
            rci?.exchangeRate
          ) *
            rci.taxPercentage) /
          100
        ).toFixed(2)
      : 0;
  };

  const rciItemsDetail = () => {
    return (
      jobDetail?.account?.showRciItemsDetail &&
      rci?.rciItems
        ?.filter(item => item.kind !== "extra")
        ?.map(item => {
          return {
            title: item.name,
            amount: ammountWithCurrency(item.amount, jobDetail?.currency),
            bgColor: "#D1D3D4",
          };
        })
    );
  };

  const RCI_FEE_DATA = [
    rciItemsDetail(),
    {
      title: "Total Approved Fees",
      amount: ammountWithCurrency(
        approvedAmount(rci?.rciItems || []),
        jobDetail?.currency
      ),
      bgColor: "#D1D3D4",
    },
    Number(rci?.exchangeRate) != 1
      ? {
          title: `Fees due in AUD at exchange rate of ${rci?.exchangeRate}`,
          amount: ammountWithCurrency(
            approvedAmountWithExchangeRateWithoutAdditionalFees(
              rci?.rciItems || [],
              rci?.exchangeRate
            ),
            rci?.currencyName
          ),
          bgColor: "#D1D3D4",
        }
      : undefined,
    !rci?.final
      ? {
          title: `${rci?.percentage}% of fees due now`,
          amount: ammountWithCurrency(
            approvedAmountWithExchangeRateAndPercentageWithoutadditionalCost(
              rci?.rciItems || [],
              rci?.exchangeRate
            )
          ),
          bgColor: "#FCB613",
        }
      : undefined,
    rci?.final
      ? {
          title: "Fees paid to date",
          amount: `-${ammountWithCurrency(
            approvedAmountWithExchangeRateWithoutAdditionalFees(
              rci?.rciItems || [],
              rci?.exchangeRate
            ) -
              approvedAmountWithExchangeRateAndPercentageWithoutadditionalCost(
                rci?.rciItems || [],
                rci?.exchangeRate
              )
          )}`,
          bgColor: "#FCB613",
        }
      : undefined,
    rci?.final
      ? {
          title: "Subtotal",
          amount: ammountWithCurrency(
            approvedAmountWithExchangeRateAndPercentageWithoutadditionalCost(
              rci?.rciItems || [],
              rci?.exchangeRate
            ),
            rci?.currencyName
          ),
          bgColor: "#D1D3D4",
        }
      : undefined,
    rci?.rciItems
      ?.filter(item => item.kind === "extra")
      .map(item => {
        return {
          title: item.name,
          amount: ammountWithCurrency(
            Number(
              Number(item.amount) / Number(rci?.exchangeRate || 1)
            ).toFixed(2)
          ),
          bgColor: "#D1D3D4",
        };
      }),
    !rci?.final
      ? {
          title: "Subtotal",
          amount: ammountWithCurrency(
            approvedAmountWithExchangeRateAndPercentage(
              rci?.rciItems || [],
              rci?.exchangeRate
            ),
            rci?.currencyName
          ),
          bgColor: "#D1D3D4",
        }
      : undefined,
    rci?.final
      ? {
          title: "Final Balance of fees due now",
          amount: ammountWithCurrency(
            approvedAmountWithExchangeRateAndPercentage(
              rci?.rciItems || [],
              rci?.exchangeRate
            )
          ),
          bgColor: "#D1D3D4",
        }
      : undefined,
    rci?.taxTypeName
      ? {
          title: rci.taxTypeName,
          amount: ammountWithCurrency(totalTaxAmount()),
          bgColor: "#D1D3D4",
        }
      : undefined,
  ].flat();

  const templateLogo = () => {
    return jobTypeSpecificTemplateSetting?.attachments?.find(
      attachment => attachment.attachable_kind === "template_logo"
    );
  };

  const jobLogo = () => {
    if (templateLogo()) {
      return templateLogo().src;
    } else {
      return defaultTemplateSetting?.attachments?.find(
        attachment => attachment.attachable_kind === "pdf_footer_logo"
      )?.src;
    }
  };

  if (
    jobDetailLoad ||
    artistLoad ||
    rciLoad ||
    pdfLoad ||
    companyDetailLoad ||
    jobTypeSpecificTemplateSettingLoad ||
    emailBackgroundLoad
  ) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="relative w-full px-4 py-8 bg-white lg:p-8 jw-template">
      <div className="flex flex-col w-full h-full">
        <div className="flex flex-col w-full">
          <div className="flex flex-col items-start justify-between w-full mb-12 space-x-0 space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
            <div
              className="static top-0 flex flex-row items-center justify-between w-full h-full pr-0 lg:items-start lg:flex-col lg:w-1/3 lg:pr-12 lg:sticky lg:top-8"
              style={{ fontFamily: "Modern Gothic" }}
            >
              <span className="mb-0 text-xl font-medium leading-6 lg:mb-10">
                Recipient Created Invoice - {rci?.rciType}
              </span>
              <div
                className={`logo-container ${
                  templateLogo() ? "template-logo-container" : ""
                }`}
              >
                <img src={jobLogo()} alt="logo" />
              </div>
            </div>
            <div className="flex flex-col items-start justify-start w-full lg:w-2/3">
              <div className="grid w-full grid-cols-2 gap-4 font-light lg:gap-6">
                <div
                  className="flex flex-col justify-start divide-y divide-black"
                  style={{ fontFamily: "Modern Gothic Mono" }}
                >
                  <div className="flex flex-col pb-2 text-sm">
                    <span>Job Number</span>
                    <span>{jobDetail?.serialNumber}</span>
                  </div>
                  <div className="flex flex-col py-2 text-sm">
                    <span>Invoice Number</span>
                    <span>{rci?.serialNumber}</span>
                  </div>
                  <div
                    className="flex flex-col justify-start pt-2 text-sm font-light"
                    style={{ fontFamily: "Modern Gothic Mono" }}
                  >
                    <span>To</span>
                    <div className="flex flex-col pt-1">
                      <p>
                        {companyDetail?.name}
                        <br />
                        <p
                          dangerouslySetInnerHTML={{
                            __html: companyDetail?.streetAddress
                              ?.split("\n")
                              .join("<br />"),
                          }}
                        ></p>
                        <p>
                          {[
                            companyDetail?.suburb,
                            companyDetail?.state,
                            companyDetail?.postcode,
                          ]
                            .filter(el => el !== null)
                            .join(", ")}
                        </p>
                        {companyDetail?.fullLocationName}
                        <br />
                        {companyDetail?.addressCode
                          ? `${companyDetail?.addressCode}: ${companyDetail?.addressId}`
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="flex flex-col justify-start divide-y divide-black"
                  style={{ fontFamily: "Modern Gothic Mono" }}
                >
                  <div className="flex flex-col pb-2 text-sm">
                    <span>Issued On</span>
                    <span>{dayjs(rci.issueDate).format("DD MMMM YYYY")}</span>
                  </div>
                  <div className="flex flex-col py-2 text-sm">
                    <span>Project</span>
                    <span>{jobDetail?.name}</span>
                  </div>
                  <div
                    className="flex flex-col justify-start pt-2 text-sm font-light"
                    style={{ fontFamily: "Modern Gothic Mono" }}
                  >
                    <span>From</span>
                    <div className="flex flex-col pt-1">
                      {artist?.fullName}
                      <br />
                      <p
                        dangerouslySetInnerHTML={{
                          __html: artist?.streetAddress
                            ?.split("\n")
                            .join("<br />"),
                        }}
                      ></p>
                      <p>
                        {[artist?.suburb, artist?.state, artist?.postcode]
                          .filter(el => el !== null)
                          .join(", ")}
                      </p>
                      {artist?.country}
                      <br />
                      ABN: {artist?.abn}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="flex flex-col items-start justify-start w-full mt-24 space-y-2"
                style={{ fontFamily: "Modern Gothic" }}
              >
                {RCI_FEE_DATA.flat()
                  .filter(item => item !== undefined)
                  .map((data, index) => (
                    <div
                      key={index}
                      className="flex flex-col items-center justify-between w-full px-6 py-4 space-y-2 font-medium text-black lg:space-y-0 lg:flex-row rounded_container"
                      style={{ backgroundColor: data?.bgColor }}
                    >
                      <span className="text-base lg:text-xl">
                        {data?.title}
                      </span>
                      <span className="text-xl">{data?.amount}</span>
                    </div>
                  ))}
                <div className="flex flex-col items-center justify-start w-full px-6 pt-6 pb-12 mt-2 space-y-4 font-medium text-white bg-black lg:items-start rounded_container">
                  <span className="text-base text-center lg:text-xl lg:text-left">
                    {`Total due by ${dayjs(rci.dueDate).format(
                      "MMMM DD, YYYY"
                    )}`}
                  </span>
                  <span className="self-center text-4xl leading-snug lg:leading-loose lg:text-5xl">
                    {ammountWithCurrency(
                      approvedAmountWithExchangeRateAndPercentage(
                        rci?.rciItems || [],
                        rci?.exchangeRate
                      ) + Number(totalTaxAmount())
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PrintSave
        getPdfRecordsPrint={getRciPdfRecordsPrint}
        getPdfRecords={getRciPdfRecords}
      />
    </div>
  );
};

export default RecipientCreatedTaxInvoice;

import axios from "axios";

const getTargetAmountUrl = (id, locationId, year) => {
  if (locationId && year && id) {
    return `/api/v1/settings/target_amounts/${id}?location_id=${locationId}&year=${year}`;
  }

  if (id) {
    return `/api/v1/settings/target_amounts/${id}`;
  }

  if (locationId && year) {
    return `/api/v1/settings/target_amounts?location_id=${locationId}&year=${year}`;
  }

  return `/api/v1/settings/target_amounts`;
};

export const getTargetAmounts = (locationId = "", year = "") => {
  return axios.get(getTargetAmountUrl(null, locationId, year));
};

export const createTargetAmount = payload => {
  return axios.post(getTargetAmountUrl(), payload);
};

export const updateTargetAmount = (id, payload) => {
  return axios.put(getTargetAmountUrl(id), payload);
};

export const destroyTargetAmount = (id, locationId = "", year = "") => {
  return axios.delete(getTargetAmountUrl(id, locationId, year));
};

export const getTargetAmountGroupedByYear = () => {
  return axios.get(`/api/v1/settings/target_amount_grouped_by_years`);
};

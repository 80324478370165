import * as Yup from "yup";
import { MAX_STRING_LENGTH } from "common/constants";

export const INITIAL_VALUE = {
  subject: "",
  to: [],
  cc: [],
  bcc: [],
  fileManagementLink: "",
  htmlBody: "",
  attachments_attributes: [],
};

export const VALIDATION_SCHEMA = Yup.object({
  to: Yup.array().min(1, "Recipient email can't be empty."),
  cc: Yup.array().nullable(),
  bcc: Yup.array().nullable(),
  subject: Yup.string()
    .required("Subject can't be empty.")
    .max(
      MAX_STRING_LENGTH,
      `Subject must be at most ${MAX_STRING_LENGTH} characters.`
    ),
  fileManagementLink: Yup.string().max(
    MAX_STRING_LENGTH,
    `File management link must be at most ${MAX_STRING_LENGTH} characters.`
  ).nullable(),
  htmlBody: Yup.string().required("Message can't be empty."),
});

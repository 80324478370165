import React, { useState, useEffect, useContext } from "react";
import { Help, Delete } from "@bigbinary/neeto-icons";
import { Input, Button, Pane, Select, Alert, Label } from "@bigbinary/neetoui";
import { components } from "react-select";
import Pluralize from "pluralize";
import {
  createDefaultUsage,
  updateDefaultUsage,
} from "apis/settings/default_usages";
import { destroyUsageItem } from "apis/settings/default_usage_items";
import produce from "immer";
import { showToastrError } from "common";
import { DefaultUsageTemplateContext } from ".";

const AddUsage = ({ inputRef, onClose }) => {
  const {
    usage,
    loadUsageData,
    loadUsageListData,
    setUsageName,
    usageItems,
    setUsageItems,
    categoryList,
    detailList,
    quantityList,
    territoryList,
    periodList,
    exclusivityPeriodList,
  } = useContext(DefaultUsageTemplateContext);

  const [name, setName] = useState();
  const [deleteUsageItem, setDeleteUsageItem] = useState(false);
  const [deleteUsageItemId, setDeleteUsageItemId] = useState("");

  useEffect(() => {
    setName(usage?.name);
  }, [usage]);

  const createUsageEntry = async () => {
    try {
      let payload = {
        default_license: {
          name: name,
          title: name,
          kind: "default",
          license_items_attributes: usageItems,
        },
      };
      const { data } = await createDefaultUsage(payload);
      loadUsageData(data.id);
      loadUsageListData();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      onClose();
    }
  };

  const updateUsageEntry = async () => {
    try {
      let payload = {
        default_license: {
          id: usage.id,
          name: name,
          title: name,
          kind: "default",
          license_items_attributes: usageItems,
        },
      };
      await updateDefaultUsage(usage.id, payload);
      loadUsageData(usage.id);
      loadUsageListData();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      onClose();
    }
  };

  const destroyUsageItemEntry = async usageItemId => {
    try {
      await destroyUsageItem(usage.id, usageItemId);
      setDeleteUsageItem(false);
      loadUsageData(usage.id);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const optionList = (data, itemValue) => {
    const item = data.find(el => el.label === itemValue)

    if (item) {
      return data;
    } else if (itemValue?.length > 0) {
      return [...data, { label: itemValue, value: itemValue }]
    } else {
      return data;
    }
  }

  return (
    <>
      <Pane.Body className="w-full">
        <div className="flex flex-col w-full pb-8 space-y-6">
          <Input
            label="Name"
            value={name}
            ref={inputRef}
            onChange={e => {
              setName(e.target.value);
              setUsageName(e.target.value);
            }}
          />
          <div className="flex flex-col w-full mt-2 space-y-4">
            {usageItems?.map((item, index) => {
              return (
                <div
                  className="relative flex flex-col p-4 space-y-4 bg-gray-100 rounded-md"
                  key={index}
                >
                  <div className="flex flex-col">
                    <Label className="font-semibold">
                      Usage Category {index + 1}
                    </Label>
                    <div className="grid grid-cols-4 gap-4 mt-4">
                      <Select
                        isCreateable
                        isSearchable
                        label="Category"
                        name="category"
                        strategy="fixed"
                        options={optionList(categoryList, item.category)}
                        onCreateOption={inputValue => {
                          setUsageItems(
                            produce(draft => {
                              draft[index] = {
                                ...draft[index],
                                category: inputValue,
                              };
                            })
                          );
                        }}
                        value={
                          categoryList.find(
                            cat => cat.label === item.category
                          ) || {
                            label: item.category,
                            value: item.category,
                          }
                        }
                        onChange={e => {
                          setUsageItems(
                            produce(draft => {
                              draft[index] = {
                                ...draft[index],
                                category: e.label,
                              };
                            })
                          );
                        }}
                      />

                      <Select
                        isCreateable
                        isSearchable
                        label="Detail"
                        name="detail"
                        strategy="fixed"
                        options={optionList(detailList, item.detail)}
                        value={
                          detailList.find(
                            detail => detail.label === item.detail
                          ) || { label: item.detail, value: item.detail }
                        }
                        onChange={e => {
                          setUsageItems(
                            produce(draft => {
                              draft[index] = {
                                ...draft[index],
                                detail: e.label,
                              };
                            })
                          );
                        }}
                        onCreateOption={inputValue => {
                          setUsageItems(
                            produce(draft => {
                              draft[index] = {
                                ...draft[index],
                                detail: inputValue,
                              };
                            })
                          );
                        }}
                      />

                      <Select
                        isCreateable
                        isSearchable
                        label="Quantity"
                        name="quantity"
                        strategy="fixed"
                        options={optionList(quantityList, item.quantity)}
                        value={
                          quantityList.find(
                            quantity => quantity.label === item.quantity
                          ) || { label: item.quantity, value: item.quantity }
                        }
                        onChange={e => {
                          setUsageItems(
                            produce(draft => {
                              draft[index] = {
                                ...draft[index],
                                quantity: e.label,
                              };
                            })
                          );
                        }}
                        onCreateOption={inputValue => {
                          setUsageItems(
                            produce(draft => {
                              draft[index] = {
                                ...draft[index],
                                quantity: inputValue,
                              };
                            })
                          );
                        }}
                      />

                      <Select
                        isCreateable
                        isSearchable
                        label="Territory"
                        name="territory"
                        strategy="fixed"
                        className="col-span-2 "
                        options={optionList(territoryList, item.territory)}
                        value={
                          territoryList.find(
                            territory => territory.label === item.territory
                          ) || { label: item.territory, value: item.territory }
                        }
                        onChange={e => {
                          setUsageItems(
                            produce(draft => {
                              draft[index] = {
                                ...draft[index],
                                territory: e.label,
                              };
                            })
                          );
                        }}
                        onCreateOption={inputValue => {
                          setUsageItems(
                            produce(draft => {
                              draft[index] = {
                                ...draft[index],
                                territory: inputValue,
                              };
                            })
                          );
                        }}
                      />

                      <div className="flex flex-col space-y-2">
                        <Label
                          helpIconProps={{
                            icon: Help,
                            tooltipProps: {
                              content: "Please enter number of months only",
                              position: "right",
                              hideAfter: 3000,
                            },
                          }}
                          required
                        >
                          Period
                        </Label>
                        <Select
                          isCreateable
                          isSearchable
                          strategy="fixed"
                          name="period"
                          options={optionList(periodList, item.period)}
                          value={
                            periodList.find(
                              period => period.label === item.period
                            ) || { label: item.period, value: item.period }
                          }
                          onChange={e => {
                            setUsageItems(
                              produce(draft => {
                                draft[index] = {
                                  ...draft[index],
                                  period: e.label,
                                };
                              })
                            );
                          }}
                          components={{
                            SingleValue: ({ children, ...props }) => {
                              return (
                                <components.SingleValue {...props}>
                                  {!isNaN(Number(children))
                                    ? children +
                                      Pluralize(" month", Number(children))
                                    : children}
                                </components.SingleValue>
                              );
                            },
                            Placeholder: ({ children, ...props }) => {
                              return (
                                <components.Placeholder {...props}>
                                  {children + " month"}
                                </components.Placeholder>
                              );
                            },
                            IndicatorSeparator: () => null,
                          }}
                          onCreateOption={inputValue => {
                            setUsageItems(
                              produce(draft => {
                                draft[index] = {
                                  ...draft[index],
                                  period: inputValue,
                                };
                              })
                            );
                          }}
                        />
                      </div>
                      <div className="flex flex-col space-y-2">
                        <Label
                          helpIconProps={{
                            icon: Help,
                            tooltipProps: {
                              content: "Please enter number of months only",
                              position: "right",
                              hideAfter: 3000,
                            },
                          }}
                          required
                        >
                          Exclusivity Period
                        </Label>

                        <Select
                          isCreateable
                          isSearchable
                          name="exclusivity_period"
                          strategy="fixed"
                          options={optionList(exclusivityPeriodList, item.exclusivityPeriod)}
                          value={
                            exclusivityPeriodList.find(
                              period => period.label === item.exclusivityPeriod
                            ) || {
                              label: item.exclusivityPeriod,
                              value: item.exclusivityPeriod,
                            }
                          }
                          onChange={e => {
                            setUsageItems(
                              produce(draft => {
                                draft[index] = {
                                  ...draft[index],
                                  exclusivityPeriod: e.label,
                                };
                              })
                            );
                          }}
                          onCreateOption={inputValue => {
                            setUsageItems(
                              produce(draft => {
                                draft[index] = {
                                  ...draft[index],
                                  exclusivityPeriod: inputValue,
                                };
                              })
                            );
                          }}
                          components={{
                            SingleValue: ({ children, ...props }) => {
                              return (
                                <components.SingleValue {...props}>
                                  {!isNaN(Number(children))
                                    ? children +
                                      Pluralize(" month", Number(children))
                                    : children}
                                </components.SingleValue>
                              );
                            },
                            Placeholder: ({ children, ...props }) => {
                              return (
                                <components.Placeholder {...props}>
                                  {Number(children || 0) + " month"}
                                </components.Placeholder>
                              );
                            },
                            IndicatorSeparator: () => null,
                          }}
                        />
                      </div>
                      <Input
                        type="number"
                        label="Fee"
                        value={item.fee}
                        onChange={e => {
                          setUsageItems(
                            produce(draft => {
                              draft[index] = {
                                ...draft[index],
                                fee: e.target.value,
                              };
                            })
                          );
                        }}
                      />
                    </div>
                  </div>

                  {item.id && (
                    <Button
                      onClick={() => {
                        setDeleteUsageItem(true);
                        setDeleteUsageItemId(item.id);
                      }}
                      style="text"
                      icon={Delete}
                      className="absolute top-0 right-2"
                      tooltipProps={{
                        content: "Delete Usage Item",
                      }}
                    />
                  )}
                </div>
              );
            })}

            <div className="flex items-center justify-end mt-4">
              <Button
                style="link"
                label="Add Usage Item"
                onClick={() => {
                  setUsageItems(state => {
                    return [
                      ...state,
                      {
                        name: `Usage Option ${usageItems?.length + 1}`,
                        category: "",
                        detail: "",
                        quantity: "",
                        period: 0,
                        exclusivityPeriod: 0,
                      },
                    ];
                  });
                }}
              />
            </div>
          </div>
        </div>
      </Pane.Body>
      <Pane.Footer className="flex space-x-2">
        <Button
          label="Save changes"
          onClick={() => {
            if (usage?.id) {
              updateUsageEntry();
            } else {
              createUsageEntry();
            }
          }}
        />
        <Button
          label="Cancel"
          style="text"
          onClick={() => {
            onClose();
          }}
        />
      </Pane.Footer>
      <Alert
        isOpen={deleteUsageItem}
        title="Delete Usage Item"
        message="Are you sure you want to delete the Usage Item?"
        onClose={() => setDeleteUsageItem(false)}
        onSubmit={() => destroyUsageItemEntry(deleteUsageItemId)}
        submitButtonLabel="Yes, delete"
        cancelButtonLabel="No, cancel"
      />
    </>
  );
};

export default AddUsage;

import React from "react";
import { Spinner, Select } from "@bigbinary/neetoui";
import { Status } from "./constants";

const JobFilter = ({
  statusFilter,
  setStatusFilter,
  jobTypeId,
  setJobTypeId,
  jobTypeOptions,
  jobTypeOptionLoad,
}) => {
  return (
    <div className="w-full">
      {!jobTypeOptionLoad ? (
        <div className="w-full space-y-6">
          <Select
            label="Status"
            name="status"
            id="status"
            placeholder="Select a status"
            value={statusFilter}
            onChange={opt => setStatusFilter(opt)}
            options={Status}
          />

          <Select
            label="Job Type"
            name="jobTypeId"
            id="jobTypeId"
            placeholder="Select a Job type"
            value={jobTypeOptions.find(jobType => jobType.value === jobTypeId)}
            options={jobTypeOptions}
            onChange={opt => {
              setJobTypeId(opt.value);
            }}
          />
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default JobFilter;

import React from "react";
import classnames from "classnames";
import { components } from "react-select";
import { withAsyncPaginate, AsyncPaginate } from "react-select-async-paginate";
import Creatable from "react-select/creatable";
import { Label } from "@bigbinary/neetoui";
import { Down, Close } from "@bigbinary/neeto-icons";

const CreatableAsyncPaginate = withAsyncPaginate(Creatable);

const SIZES = {
  small: "small",
  medium: "medium",
  large: "large",
};

const STRATEGIES = {
  default: "default",
  fixed: "fixed",
};

const CustomInput = props => {
  return <components.Input {...props}></components.Input>;
};

const AsyncPaginateSelect = ({
  size = SIZES.medium,
  label = "",
  required = false,
  error = "",
  helpText = "",
  className = "",
  innerRef,
  isCreatable = false,
  strategy = STRATEGIES.default,
  ...otherProps
}) => {
  let Parent = AsyncPaginate;

  if (isCreatable) {
    Parent = CreatableAsyncPaginate;
  }

  const portalProps = strategy === STRATEGIES.fixed && {
    menuPortalTarget: document.body,
    styles: { menuPortal: base => ({ ...base, zIndex: 999999 }) },
    menuPosition: "fixed",
  };

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <Down size={16} />
      </components.DropdownIndicator>
    );
  };

  const ClearIndicator = props => {
    return (
      <components.ClearIndicator {...props}>
        <Close size={16} />
      </components.ClearIndicator>
    );
  };

  const MultiValueRemove = props => {
    return (
      <components.MultiValueRemove {...props}>
        <Close size={16} />
      </components.MultiValueRemove>
    );
  };

  return (
    <div className={classnames(["neeto-ui-input__wrapper", className])}>
      {label && (
        <Label required={required} data-testid="select-label">
          {label}
        </Label>
      )}
      <Parent
        ref={innerRef}
        className={classnames(["neeto-ui-react-select__container"], {
          "neeto-ui-react-select__container--error": !!error,
          "neeto-ui-react-select__container--small": size === SIZES.small,
          "neeto-ui-react-select__container--medium": size === SIZES.medium,
          "neeto-ui-react-select__container--large": size === SIZES.large,
        })}
        classNamePrefix="neeto-ui-react-select"
        components={{
          Input: CustomInput,
          DropdownIndicator,
          ClearIndicator,
          MultiValueRemove,
        }}
        {...portalProps}
        {...otherProps}
      />
      {!!error && (
        <p className="neeto-ui-input__error" data-testid="select-error">
          {error}
        </p>
      )}
      {helpText && (
        <p className="neeto-ui-input__help-text" data-testid="select-help-text">
          {helpText}
        </p>
      )}
    </div>
  );
};

export default AsyncPaginateSelect;

import React, { useState, useEffect } from "react";
import { Delete } from "@bigbinary/neeto-icons";
import {
  Button,
  Switch,
  Typography,
  Input,
  Modal,
  Alert,
  DatePicker,
} from "@bigbinary/neetoui";
import * as R from "ramda";
import * as dayjs from "dayjs";
import { showToastrError } from "common";
import { useHistory } from "react-router-dom";
import { useUserState } from "contexts/user";
import {
  updateEstimateRevision,
  destroyEstimateRevision,
} from "apis/jobs/estimate_revisions";
import { updateJob } from "apis/jobs/jobs";
import {
  getApprovedRevisionId,
  getFinalInvoice,
  getCommencementInvoice,
} from "./helper";
import EstimateRevisionName from "./EstimateRevisionName";
import classNames from "classnames";

const EstimateRevisions = ({
  estimateRevisionList,
  loadEstimateRevisionListResponse,
  jobDetail,
  setCurrentRevisionId,
  currentRevisionId,
  invoiceList,
  loadJobDetailResponse,
  loadRciList,
  usageEstimateItem,
  page = "",
}) => {
  const { user } = useUserState();
  const history = useHistory();
  const [commencementModal, setCommencementModal] = useState(false);
  const [redirectionModal, setRedirectionModal] = useState(false);
  const [commencementFee, setCommencementFee] = useState(0);
  const [approvalDate, setApprovalDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState();
  const [
    halfCommencementInvoiceDate,
    setHalfCommencementInvoiceDate,
  ] = useState();
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [deletingRevisionId, setDeletingRevisionId] = useState();
  const [btnLoader, setBtnLoader] = useState(false);

  useEffect(() => {
    if (jobDetail.id) {
      setCommencementFee(jobDetail.commencementFee);
      setExpectedDeliveryDate(jobDetail.expectedDeliveryDate);
      setHalfCommencementInvoiceDate(jobDetail.halfCommencementInvoiceDate);
    }
  }, [jobDetail]);

  const updateEstimateRevisionEntry = async (revisionId, payload) => {
    try {
      await updateEstimateRevision(jobDetail.id, revisionId, payload);
      loadJobDetailResponse();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      loadEstimateRevisionListResponse();
      loadRciList();
    }
  };

  const updateEstimateRevisionNameEntry = async (revisionId, name) => {
    try {
      await updateEstimateRevision(jobDetail.id, revisionId, {
        estimate_revision: { name: name },
      });
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const deleteRevisionEntry = async () => {
    try {
      await destroyEstimateRevision(jobDetail.id, deletingRevisionId, true);
      await loadEstimateRevisionListResponse();
      await setCurrentRevisionId(estimateRevisionList[0]?.id);
      setDeleteAlertOpen(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const updateJobEntry = async () => {
    try {
      setBtnLoader(true);
      const payload = {
        job: {
          commencementFee: commencementFee,
          halfCommencementInvoiceDate: halfCommencementInvoiceDate,
          expectedDeliveryDate: expectedDeliveryDate,
        },
      };
      await updateJob(jobDetail.id, payload);
      await updateEstimateRevision(jobDetail.id, currentRevisionId, {
        estimate_revision: { approvalDate: approvalDate },
      });
      loadJobDetailResponse();
      setCommencementModal(false);
      setRedirectionModal(true);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      setBtnLoader(false);
    }
  };

  const approvedRevisionId = getApprovedRevisionId(estimateRevisionList);
  const usageEstimateItemForPage = revision => {
    return page === "overview"
      ? revision.licensesCount === 0
      : usageEstimateItem;
  };

  return (
    <>
      <div className="flex flex-col space-y-2">
        <Typography style="h4" weight="semibold">
          Estimate Revisions
        </Typography>
        <div className="flex flex-col space-y-3">
          {estimateRevisionList.map(revision => {
            return (
              <div
                key={revision.id}
                onClick={() => setCurrentRevisionId(revision.id)}
                className={classNames(
                  "relative z-10 p-4 neeto-ui-bg-white border rounded-md cursor-pointer hover:neeto-ui-border-gray-500 neeto-ui-shadow-xs focus:neeto-ui-border-gray-500",
                  {
                    "neeto-ui-bg-pastel-blue neeto-ui-border-gray-500":
                      currentRevisionId === revision.id,
                    "neeto-ui-border-gray-200":
                      currentRevisionId != revision.id,
                  }
                )}
              >
                <div className="flex items-center">
                  <EstimateRevisionName
                    revision={revision}
                    updateEstimateRevisionNameEntry={
                      updateEstimateRevisionNameEntry
                    }
                  />
                  <Button
                    style="danger-text"
                    icon={Delete}
                    disabled={
                      revision.staffApproved ||
                      revision.management1Approved ||
                      revision.management2Approved
                    }
                    className=""
                    onClick={e => {
                      e.stopPropagation();
                      setDeleteAlertOpen(true);
                      setDeletingRevisionId(revision.id);
                    }}
                  />
                </div>

                <div className="flex flex-col space-y-2">
                  <Switch
                    label="Staff Approved"
                    className="z-40"
                    checked={revision.staffApproved}
                    disabled={
                      (approvedRevisionId &&
                        approvedRevisionId !== revision.id) ||
                      getFinalInvoice(invoiceList) ||
                      getCommencementInvoice(invoiceList) ||
                      (revision.usageEnabled && revision.licensesCount == 0) ||
                      revision.revisionItemsCount == 0 ||
                      (revision.usageEnabled &&
                        R.isNil(usageEstimateItemForPage(revision)))
                    }
                    onChange={e => {
                      e.stopPropagation();
                      setCurrentRevisionId(revision.id);
                      updateEstimateRevisionEntry(revision.id, {
                        estimate_revision: {
                          staff_approved: !revision.staffApproved,
                          approvalDate: revision.staffApproved
                            ? null
                            : dayjs().format("YYYY-MM-DD"),
                        },
                      });

                      setApprovalDate(dayjs().format("YYYY-MM-DD"));
                      if (!revision.staffApproved) setCommencementModal(true);
                    }}
                  />
                  <Switch
                    label="MGMT #1 Approved"
                    className="z-40"
                    checked={revision.management1Approved}
                    onChange={e => {
                      e.stopPropagation();
                      setCurrentRevisionId(revision.id);
                      updateEstimateRevisionEntry(revision.id, {
                        estimate_revision: {
                          management1_approved: !revision.management1Approved,
                        },
                      });
                    }}
                    disabled={
                      (user.access === "team" && !user.management1) ||
                      (approvedRevisionId &&
                        approvedRevisionId !== revision.id) ||
                      getFinalInvoice(invoiceList) ||
                      getCommencementInvoice(invoiceList) ||
                      (revision.usageEnabled && revision.licensesCount == 0) ||
                      revision.revisionItemsCount == 0 ||
                      !revision.staffApproved
                    }
                  />
                  <Switch
                    label="MGMT #2 Approved"
                    className="z-40"
                    checked={revision.management2Approved}
                    onChange={e => {
                      e.stopPropagation();
                      setCurrentRevisionId(revision.id);
                      updateEstimateRevisionEntry(revision.id, {
                        estimate_revision: {
                          management2_approved: !revision.management2Approved,
                        },
                      });
                    }}
                    disabled={
                      (user.access === "team" && !user.management2) ||
                      (approvedRevisionId &&
                        approvedRevisionId !== revision.id) ||
                      getFinalInvoice(invoiceList) ||
                      (revision.usageEnabled && revision.licensesCount == 0) ||
                      revision.revisionItemsCount == 0 ||
                      !revision.management1Approved
                    }
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <Modal
        isOpen={commencementModal}
        onClose={() => {
          setCommencementModal(false);
          setRedirectionModal(true);
        }}
      >
        <Modal.Header>
          <Typography style="h2">Commencement Fee</Typography>
        </Modal.Header>

        <Modal.Body>
          <div className="flex flex-col w-full space-y-3">
            <Input
              type="number"
              label="Percentage"
              name="commencementFee"
              className="mb-2"
              value={commencementFee}
              onChange={e => {
                setCommencementFee(e.target.value);
                if (e.target.value == 50) {
                  setHalfCommencementInvoiceDate(dayjs().format("YYYY-MM-DD"));
                } else {
                  setHalfCommencementInvoiceDate(null);
                }
              }}
            />

            {commencementFee == 50 && (
              <DatePicker
                label="50% Commencement Invoice Date"
                name="halfCommencementInvoiceDate"
                format="DD/MM/YYYY"
                allowClear={true}
                value={halfCommencementInvoiceDate}
                getPopupContainer={triggerNode => {
                  return triggerNode.parentNode;
                }}
                onChange={date => {
                  if (date) {
                    setHalfCommencementInvoiceDate(date.format("YYYY-MM-DD"));
                  } else {
                    setHalfCommencementInvoiceDate(null);
                  }
                }}
              />
            )}

            <DatePicker
              label="Approval Date"
              name="approvalDate"
              dateFormat="DD/MM/YYYY"
              value={dayjs(approvalDate)}
              allowClear={false}
              onChange={date => setApprovalDate(date.format("YYYY-MM-DD"))}
              getPopupContainer={triggerNode => triggerNode.parentNode}
            />

            <DatePicker
              label="Expected Delivery Date"
              name="expectedDeliveryDate"
              format="DD/MM/YYYY"
              allowClear={true}
              value={expectedDeliveryDate}
              getPopupContainer={triggerNode => {
                return triggerNode.parentNode;
              }}
              onChange={date => {
                if (date) {
                  setExpectedDeliveryDate(date.format("YYYY-MM-DD"));
                } else {
                  setExpectedDeliveryDate(null);
                }
              }}
            />
          </div>
          {((jobDetail.commencementFee == 50 &&
            !jobDetail.halfCommencementInvoiceDate) ||
            !jobDetail.expectedDeliveryDate) && (
            <Typography style="body3" className="mt-2">
              N.B. Don't forget to add{" "}
              <b>
                {commencementFee == 50 && !jobDetail.halfCommencementInvoiceDate
                  ? "50% Commencement Invoice Date and"
                  : ""}
              </b>{" "}
              <b>
                {!jobDetail.expectedDeliveryDate
                  ? "Expected Delivery Date"
                  : ""}
              </b>{" "}
              if not added already.
            </Typography>
          )}
        </Modal.Body>
        <Modal.Footer className="space-x-2">
          <Button
            label="Save Changes"
            loading={btnLoader}
            onClick={() => updateJobEntry()}
          />
          <Button
            style="text"
            label="Cancel"
            onClick={() => {
              setCommencementModal(false);
              setRedirectionModal(true);
            }}
          />
        </Modal.Footer>
      </Modal>

      <Modal
        isOpen={redirectionModal}
        onClose={() => setRedirectionModal(false)}
      >
        <Modal.Header>
          <Typography style="h2">What to next?</Typography>
        </Modal.Header>

        <Modal.Body>
          <Typography style="body2">
            You can either send the Welcome Email or go to the Booking Form.
          </Typography>
        </Modal.Body>
        <Modal.Footer className="space-x-2">
          <Button
            label="Welcome Email"
            onClick={() => history.push(`/mailer/jobs/${jobDetail.id}/welcome`)}
          />
          <Button
            label="Booking Form"
            onClick={() =>
              history.push(
                `/jobs/${jobDetail.id}/revisions/${approvedRevisionId}/booking_form`
              )
            }
          />
        </Modal.Footer>
      </Modal>

      <Alert
        isOpen={deleteAlertOpen}
        title="Delete Revision"
        message="Are you sure you want to delete the Revision?"
        onClose={() => setDeleteAlertOpen(false)}
        onSubmit={() => deleteRevisionEntry()}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, delete"
      />
    </>
  );
};

export default EstimateRevisions;

import React, { useState, useEffect } from "react";
import { Header, Container } from "@bigbinary/neetoui/layouts";
import { Spinner, Button, Alert, Toastr } from "@bigbinary/neetoui";
import { isEmpty } from "ramda";
import { useFormik } from "formik";
import {
  getKillFees,
  createKillFee,
  updateKillFee,
  destroyKillFee,
} from "apis/settings/kill_fees";
import { showToastrError } from "common";
import { getRandomNotFoundImage } from "common/helper";
import useDebounce from "common/debounce";
import {
  KILL_FEE_VALIDATION_SCHEMA,
  KILL_FEE_INITIAL_VALUE,
} from "../constants";
import List from "./List";
import EmptyState from "components/Common/EmptyState";
import FormPane from "./FormPane";

const KillFees = ({ breadcrumbs }) => {
  const [killFeeList, setKillFeeList] = useState([]);
  const [listLoader, setListLoader] = useState(true);
  const [killFee, setKillFee] = useState({});
  const [modalState, setModalState] = useState(false);
  const [deleteAlertState, setDeleteAlertState] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [searchParams, setSearchParams] = useState();
  const debouncedSearchTerm = useDebounce(searchParams, 1000);
  const [emptyImage, setEmptyImage] = useState();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: killFee?.id ? killFee : KILL_FEE_INITIAL_VALUE,
    validationSchema: KILL_FEE_VALIDATION_SCHEMA,
    onSubmit: () => {
      if (killFee?.id) {
        updateKillFeeEntry();
      } else {
        createKillFeeEntry();
      }
    },
  });

  useEffect(() => {
    loadKillFeeList();
    setEmptyImage(getRandomNotFoundImage());
  }, []);

  useEffect(() => {
    if (debouncedSearchTerm != undefined) {
      loadKillFeeList();
    }
  }, [debouncedSearchTerm]);

  const loadKillFeeList = async () => {
    try {
      const response = await getKillFees(searchParams);
      setKillFeeList(response.data.killFees || []);
      setListLoader(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const createKillFeeEntry = async () => {
    try {
      const response = await createKillFee({
        kill_fee: {
          name: formik.values.name,
          percentage: formik.values.percentage,
        },
      });
      setModalState(false);
      Toastr.info(response.data.notice);
      formik.resetForm();
      loadKillFeeList();
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setButtonLoader(false);
    }
  };

  const updateKillFeeEntry = async () => {
    try {
      const response = await updateKillFee(killFee?.id, {
        kill_fee: {
          name: formik.values.name,
          percentage: formik.values.percentage,
        },
      });
      setModalState(false);
      Toastr.info(response.data.notice);
      formik.resetForm();
      loadKillFeeList();
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setButtonLoader(false);
    }
  };

  const destroyKillFeeEntry = async () => {
    try {
      const response = await destroyKillFee(killFee?.id);
      setDeleteAlertState(false);
      Toastr.info(response.data.notice);
      loadKillFeeList();
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setButtonLoader(false);
    }
  };

  const handleNewButtonClick = async () => {
    setButtonLoader(false);
    formik.resetForm();
    setKillFee({});
    setModalState(true);
  };

  const handleSubmitButtonClick = async () => {
    if (Object.keys(formik.errors).length === 0) {
      setButtonLoader(true);
      formik.handleSubmit();
    }
  };

  if (listLoader) {
    return (
      <div className="flex items-center justify-center h-full">
        <Spinner />
      </div>
    );
  }

  return (
    <Container>
      <Header
        title="Default Kill Fees"
        breadcrumbs={breadcrumbs}
        searchProps={{
          value: searchParams,
          onChange: e => setSearchParams(e.target.value),
          clear: () => setSearchParams(),
        }}
        actionBlock={
          <Button label="Add New" onClick={() => handleNewButtonClick()} />
        }
      />

      <div className="w-full" style={{ height: "calc(100vh - 128px)" }}>
        {!isEmpty(killFeeList) ? (
          <List
            killFeeList={killFeeList}
            setKillFee={setKillFee}
            setModalState={setModalState}
            setDeleteAlertState={setDeleteAlertState}
          />
        ) : (
          <EmptyState
            image={emptyImage}
            title="No Default Kill Fees Found"
            description="We couldn’t find any default kill fees. Try creating one."
            primaryButtonProps={{
              label: "Add New",
              onClick: () => handleNewButtonClick(),
            }}
          />
        )}
      </div>

      <FormPane
        isOpen={modalState}
        onClose={() => setModalState(false)}
        formik={formik}
        onSubmit={handleSubmitButtonClick}
        title={killFee?.id ? "Edit Kill Fee" : "Add Kill Fee"}
        buttonLoader={buttonLoader}
      />

      <Alert
        isOpen={deleteAlertState}
        title="Delete Kill Fee"
        message={
          <>
            Are you sure you want to delete the kill fee{" "}
            <strong>{killFee?.name}</strong>?
          </>
        }
        onClose={() => setDeleteAlertState(false)}
        onSubmit={() => {
          setButtonLoader(true);
          destroyKillFeeEntry();
        }}
        isSubmitting={buttonLoader}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, delete"
      />
    </Container>
  );
};

export default KillFees;

import React, { useState, useRef, useEffect } from "react";
import { Header, Container } from "@bigbinary/neetoui/layouts";
import {
  Spinner,
  Button,
  Alert,
  Input,
  Modal,
  Select,
  Typography,
  Toastr,
} from "@bigbinary/neetoui";
import { isEmpty } from "ramda";
import { useFormik } from "formik";
import {
  getOrganisationTypes,
  createOrganisationType,
  updateOrganisationType,
  destroyOrganisationType,
  transferOrganisationType,
} from "apis/settings/organisation_types";
import { showToastrError } from "common";
import useDebounce from "common/debounce";
import { dropDownListGenerator, getRandomNotFoundImage } from "common/helper";
import {
  ORGANISATION_TYPE_VALIDATION_SCHEMA,
  ORGANISATION_TYPE_INITIAL_VALUE,
} from "../constants";
import List from "./List";
import EmptyState from "components/Common/EmptyState";

const OrganisationTypes = ({ breadcrumbs }) => {
  const inputRef = useRef();
  const [organisationTypeList, setOrganisationTypeList] = useState([]);
  const [listLoader, setListLoader] = useState(true);
  const [organisationType, setOrganisationType] = useState({});
  const [modalState, setModalState] = useState(false);
  const [deleteAlertState, setDeleteAlertState] = useState(false);
  const [transferModalState, setTransferModalState] = useState(false);
  const [organisationTypeId, setOrganisationTypeId] = useState();
  const [buttonLoader, setButtonLoader] = useState(false);
  const [searchParams, setSearchParams] = useState();
  const debouncedSearchTerm = useDebounce(searchParams, 1000);
  const [emptyImage, setEmptyImage] = useState();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: organisationType?.id
      ? organisationType
      : ORGANISATION_TYPE_INITIAL_VALUE,
    validationSchema: ORGANISATION_TYPE_VALIDATION_SCHEMA,
    onSubmit: () => {
      if (organisationType?.id) {
        updateOrganisationTypeEntry();
      } else {
        createOrganisationTypeEntry();
      }
    },
  });

  useEffect(() => {
    loadOrganisationTypeList();
    setEmptyImage(getRandomNotFoundImage());
  }, []);

  useEffect(() => {
    if (debouncedSearchTerm != undefined) {
      loadOrganisationTypeList();
    }
  }, [debouncedSearchTerm]);

  const loadOrganisationTypeList = async () => {
    try {
      const response = await getOrganisationTypes(searchParams);
      setOrganisationTypeList(response.data.organisationTypes || []);
      setListLoader(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const createOrganisationTypeEntry = async () => {
    try {
      const response = await createOrganisationType({
        organisation_type: {
          name: formik.values.name,
        },
      });
      setModalState(false);
      Toastr.info(response.data.notice);
      formik.resetForm();
      loadOrganisationTypeList();
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setButtonLoader(false);
    }
  };

  const updateOrganisationTypeEntry = async () => {
    try {
      const response = await updateOrganisationType(organisationType?.id, {
        organisation_type: {
          name: formik.values.name,
        },
      });
      setModalState(false);
      Toastr.info(response.data.notice);
      formik.resetForm();
      loadOrganisationTypeList();
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setButtonLoader(false);
    }
  };

  const destroyOrganisationTypeEntry = async () => {
    try {
      const response = await destroyOrganisationType(organisationType?.id);
      Toastr.info(response.data.notice);
      loadOrganisationTypeList();
    } catch (error) {
      if (error.response?.status === 422) {
        setTransferModalState(true);
      } else {
        showToastrError(error.data.errors);
      }
    } finally {
      setDeleteAlertState(false);
      setButtonLoader(false);
    }
  };

  const transferOrganisationTypeEntry = async () => {
    try {
      const response = await transferOrganisationType(organisationType?.id, {
        newOrganisationTypeId: organisationTypeId,
      });
      setTransferModalState(false);
      Toastr.info(response.data.notice);
      loadOrganisationTypeList();
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setButtonLoader(false);
    }
  };

  const handleNewButtonClick = async () => {
    setButtonLoader(false);
    formik.resetForm();
    setOrganisationType({});
    setModalState(true);
  };

  const handleSubmitButtonClick = async () => {
    if (Object.keys(formik.errors).length === 0) {
      setButtonLoader(true);
      formik.handleSubmit();
    }
  };

  const dropDownList = () => {
    let filteredData = organisationTypeList;

    if (organisationType?.id) {
      filteredData = organisationTypeList.filter(
        data => data.id != organisationType?.id
      );
    }

    return dropDownListGenerator(filteredData);
  };

  if (listLoader) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <Container>
      <Header
        title="Organisation Types"
        breadcrumbs={breadcrumbs}
        searchProps={{
          value: searchParams,
          onChange: e => setSearchParams(e.target.value),
          clear: () => setSearchParams(),
        }}
        actionBlock={
          <Button label="Add New" onClick={() => handleNewButtonClick()} />
        }
      />

      <div className="w-full" style={{ height: "calc(100vh - 128px)" }}>
        {!isEmpty(organisationTypeList) ? (
          <List
            organisationTypeList={organisationTypeList}
            setOrganisationType={setOrganisationType}
            setModalState={setModalState}
            setDeleteAlertState={setDeleteAlertState}
          />
        ) : (
          <EmptyState
            image={emptyImage}
            title="No Organisation Types Found"
            description="We couldn’t find any organisation types. Try creating one."
            primaryButtonProps={{
              label: "Add New",
              onClick: () => handleNewButtonClick(),
            }}
          />
        )}
      </div>

      <Modal
        isOpen={modalState}
        onClose={() => setModalState(false)}
        initialFocusRef={inputRef}
      >
        <Modal.Header>
          <Typography style="h2" weight="semibold">
            {organisationType?.id
              ? "Edit Organisation Type"
              : "Add Organisation Type"}
          </Typography>
        </Modal.Header>
        <Modal.Body>
          <div className="flex flex-col w-full space-y-6">
            <Input
              label="Name"
              ref={inputRef}
              required={true}
              onChange={formik.handleChange}
              value={formik.values.name}
              error={
                Boolean(formik.touched.name && formik.errors.name) &&
                formik.errors.name
              }
              {...formik.getFieldProps("name")}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="flex gap-x-2">
          <Button
            label="Save changes"
            onClick={() => handleSubmitButtonClick()}
            loading={buttonLoader}
          />
          <Button
            label="Cancel"
            style="text"
            onClick={() => setModalState(false)}
          />
        </Modal.Footer>
      </Modal>

      <Modal
        isOpen={transferModalState}
        onClose={() => setTransferModalState(false)}
      >
        <Modal.Header>
          <Typography style="h2" weight="semibold">
            Transfer Organisations
          </Typography>
        </Modal.Header>
        <Modal.Body>
          <div className="flex flex-col w-full space-y-6">
            <Typography style="body2" lineHeight="normal">
              This has been used in some organisations. Choose some other option
              for those organisations and release it to be deleted successfully.
            </Typography>
            <Select
              className="mt-3"
              label="Organisation Types"
              name="organisationTypeId"
              id="organisationTypeId"
              placeholder="Select a Type"
              helpText="If you do not select one, organisation type will be removed from associated organisations."
              options={dropDownList()}
              value={organisationTypeList.find(
                obj => obj.value === organisationTypeId
              )}
              onChange={opt => setOrganisationTypeId(opt.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="flex gap-x-2">
          <Button
            label="Save changes"
            onClick={() => {
              setButtonLoader(true);
              transferOrganisationTypeEntry();
            }}
            loading={buttonLoader}
          />
          <Button
            label="Cancel"
            style="text"
            onClick={() => setTransferModalState(false)}
          />
        </Modal.Footer>
      </Modal>

      <Alert
        isOpen={deleteAlertState}
        title="Delete Organisation Type"
        message={
          <>
            Are you sure you want to delete the organisation type{" "}
            <strong>{organisationType?.name}</strong>?
          </>
        }
        onClose={() => setDeleteAlertState(false)}
        onSubmit={() => {
          setButtonLoader(true);
          destroyOrganisationTypeEntry();
        }}
        isSubmitting={buttonLoader}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, delete"
      />
    </Container>
  );
};

export default OrganisationTypes;

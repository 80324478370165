import React, { useEffect } from "react";
import Scrollspy from "react-scrollspy";
import { numberWithCommas, sumAmount } from "common/helper";
import { BorderedTable } from "../../Shared";

import AOS from "aos";
import "aos/dist/aos.css";

const CreationFees = ({
  lineItemCategories,
  parentLessItemCount,
  revisionItems,
  ammountWithCurrency,
  creativeFees,
  description,
}) => {
  useEffect(() => {
    AOS.init({
      once: false,
    });
  }, []);

  let samt = 0;
  window.addEventListener("scroll", function () {
    samt <= 10 ? samt++ : AOS.refresh();
  });

  const filteredData = [
    ...lineItemCategories.map(cat => {
      return { lineItemCategoryName: cat.lineItemCategoryName, id: cat.id };
    }),
    parentLessItemCount > 0
      ? { id: "estimateLineItem", lineItemCategoryName: "Others" }
      : null,
  ].filter(item => item !== null);

  return (
    <div
      className="flex flex-col items-start justify-between w-full py-12 lg:flex-row"
      style={{ fontFamily: "Modern Gothic" }}
    >
      <div className="static flex flex-col items-start justify-start w-full pr-0 lg:pr-12 lg:sticky lg:top-8 lg:w-1/3">
        <div className="flex flex-col items-start justify-start mb-8 lg:mb-0">
          <h2 className="text-xl font-medium">Creation Fees</h2>
          <p
            className="mt-6"
            dangerouslySetInnerHTML={{
              __html: description?.split("\n").join("<br />"),
            }}
          ></p>
        </div>
        {lineItemCategories?.length > 0 && (
          <div className="flex-col items-start justify-start hidden w-full mt-16 lg:flex">
            <Scrollspy
              items={filteredData}
              currentClassName="jw-navbar-link--active"
              style={{ width: "100%" }}
              className="flex flex-col items-start justify-start w-full border-t border-b border-gray-400 divide-y divide-gray-400"
              offset={-300}
            >
              {filteredData.map(({ id, lineItemCategoryName }, index) => (
                <li key={index} className="w-full">
                  <a
                    href={`#${id}`}
                    data-to-scrollspy-id={`${id}`}
                    className="flex items-center justify-start w-full py-2 text-base font-normal no-underline cursor-pointer hover:text-black focus:text-black jw-navbar-link"
                  >
                    <span className="w-5">{index + 1}.</span>
                    <span className="ml-4">{lineItemCategoryName}</span>
                  </a>
                </li>
              ))}
            </Scrollspy>
          </div>
        )}
      </div>
      <div className="relative flex flex-col items-start justify-start w-full h-full space-y-2 lg:w-2/3">
        {filteredData.map(({ id, lineItemCategoryName }, index) => {
          let children = revisionItems.filter(
            item =>
              (item.revisionLineItemCategoryId === id ||
                (id === "estimateLineItem" &&
                  item.revisionLineItemCategoryId === null)) &&
              item.feeType === "artwork"
          );
          let totalOfParent = sumAmount(children, "total");

          let lineItemTable = [
            {
              option: {
                hasCheckbox: false,
                hasTitle: lineItemCategories?.length > 0,
                title: lineItemCategoryName,
                description: "",
              },
              table: {
                heads: ["Item", "Notes", "Qty", "Metric", "Unit Price", "Cost"],
                rows: children.map(item => {
                  return {
                    values: [
                      <>
                        <strong>{item.name}</strong> <br />
                      </>,
                      <>{item.note}</>,
                      <>{numberWithCommas(Number(item.quantity).toFixed(2))}</>,
                      <>{item.metric}</>,
                      <>
                        {item.rateTbc
                          ? "TBC"
                          : item.rateNa
                          ? "NA"
                          : ammountWithCurrency(item.customRate)}{" "}
                      </>,
                      <>
                        {item.rateTbc
                          ? "TBC"
                          : item.rateNa
                          ? "NA"
                          : ammountWithCurrency(item.total)}
                      </>,
                    ],
                  };
                }),
              },
            },
          ];
          return (
            <>
              {children?.length > 0 && (
                <div
                  data-aos="fade-down"
                  data-aos-easing="ease-in-out"
                  key={index + 1}
                  id={`${id}`}
                  className="flex flex-col w-full"
                >
                  <BorderedTable
                    id={index + 1}
                    title={lineItemCategoryName}
                    data={lineItemTable}
                    bgColor="#FFEFFF"
                  />
                  {lineItemCategories?.length > 0 && (
                    <div
                      className="flex flex-col items-center justify-between w-full px-6 py-4 mt-2 space-y-2 font-medium leading-6 text-black lg:flex-row lg:space-y-0 rounded_container"
                      style={{
                        fontFamily: "Modern Gothic",
                        backgroundColor: "#FFC9FF",
                      }}
                    >
                      <span className="text-base lg:text-xl">
                        {lineItemCategoryName} Subtotal
                      </span>
                      <span className="text-xl">
                        {ammountWithCurrency(totalOfParent)}
                      </span>
                    </div>
                  )}
                </div>
              )}
            </>
          );
        })}
        <div className="flex flex-col items-center justify-between w-full px-6 py-4 mt-2 space-y-2 font-medium leading-6 text-white bg-black lg:flex-row lg:space-y-0 rounded_container">
          <span className="text-base lg:text-xl">Creation Fees Total</span>
          <span className="text-xl">{ammountWithCurrency(creativeFees())}</span>
        </div>
      </div>
    </div>
  );
};

export default CreationFees;

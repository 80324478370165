import React, { useState, useEffect, createContext, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import * as R from "ramda";
import { CloseCircle, Settings, Warning, Edit } from "@bigbinary/neeto-icons";
import {
  Spinner,
  Callout,
  Button,
  Modal,
  Input,
  Typography,
} from "@bigbinary/neetoui";
import { Header, Container } from "@bigbinary/neetoui/layouts";
import { useFormik } from "formik";
import { showToastrError } from "common";
import { getJob } from "apis/jobs/estimate";
import {
  getEstimateRevisionItems,
  createEstimateRevisionItem,
} from "apis/jobs/estimate_revision_items";
import {
  getEstimateRevisions,
  createEstimateRevision,
} from "apis/jobs/estimate_revisions";
import { createInvoice } from "apis/jobs/invoices";
import { getInvoices } from "apis/jobs/invoices";
import { getRcis } from "apis/jobs/estimate";
import {
  getRevisionLineItemCategories,
  createRevisionLineItemCategory,
} from "apis/jobs/revision_line_item_category";
import {
  jobWithNameNumber,
  getFinalInvoice,
  getCommencementInvoice,
  getApprovedRevisionId,
  filterUsageItems,
  filterEstimateItems,
  filterExtraItems,
  filterEstimateListItems,
} from "../../common/helper";
import EstimateRevisions from "../../common/EstimateRevisions";
import EstimateSettingPane from "../../common/EstimateSettingPane";
import ItemsDirectory from "./ItemsDirectory";
import EstimateDetails from "./Details";
import {
  REVISION_INITIAL_VALUE,
  REVISION_VALIDATION_SCHEMA,
} from "./constants";
import Tabs from "../Tabs";
import NewJob from "../../NewJob";
export const EstimateContext = createContext();

const Estimate = () => {
  const { id, revisionId } = useParams();
  const history = useHistory();
  const inputRef = useRef(null);
  const [jobDetail, setJobDetail] = useState({});
  const [newJobPane, setNewJobPane] = useState(false);
  const [jobDetailLoad, setJobDetailLoad] = useState(true);
  const [estimateRevisionList, setEstimateRevisionList] = useState([]);
  const [estimateRevisionListLoad, setEstimateRevisionListLoad] = useState(
    true
  );
  const [estimateRevisionItemList, setEstimateRevisionItemList] = useState([]);
  const [
    estimateRevisionItemListLoad,
    setEstimateRevisionItemListLoad,
  ] = useState(true);
  const [invoiceList, setInvoiceList] = useState([]);
  const [
    revisionLineItemCatgoryList,
    setRevisionLineItemCatgoryList,
  ] = useState([]);
  const [
    revisionLineItemCatgoryListLoad,
    setRevisionLineItemCatgoryListLoad,
  ] = useState(true);
  const [invoiceListLoad, setInvoiceListLoad] = useState(true);
  const [rciList, setRciList] = useState([]);
  const [rciListLoad, setRciListLoad] = useState(true);
  const [newRevisionModalOpen, setNewRevisionModalOpen] = useState(false);
  const [estimateSettingsOpen, setEstimateSettingsOpen] = useState(false);
  const [currentRevisionId, setCurrentRevisionId] = useState();
  const [openedParent, setOpenedParent] = useState();
  const [btnLoad, setBtnLoad] = useState(false);
  const [commInvBtnLoad, setCommInvBtnLoad] = useState(false);
  const [finalInvBtnLoad, setFinalInvBtnLoad] = useState(false);
  const estimateItems = filterEstimateItems(estimateRevisionItemList);
  const usageEstimateItem = filterUsageItems(estimateRevisionItemList)[0];
  const [uploadModal, setUploadModal] = useState(false);
  const extraItems = filterEstimateListItems(
    filterExtraItems(estimateRevisionItemList)
  );
  const staffApprovedInAllRevisions = estimateRevisionList.map(
    revision => revision.staffApproved
  );
  const currentRevision = estimateRevisionList.find(
    item => item.id === revisionId
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: REVISION_INITIAL_VALUE,
    validationSchema: REVISION_VALIDATION_SCHEMA,
    onSubmit: () => cloneRevisionRecord(),
  });

  useEffect(() => {
    if (id) {
      loadJobDetailResponse();
      loadEstimateRevisionList();
      loadInvoiceList();
      loadRciList();
    }
  }, [id]);

  useEffect(() => {
    if (id && revisionId) {
      loadRevisionLineItemCategoryList();
      loadEstimateRevisionItemList();
    }
  }, [id, revisionId]);

  useEffect(() => {
    if (currentRevisionId) {
      history.push(`/jobs/${id}/revisions/${currentRevisionId}/estimate`);
    }
  }, [currentRevisionId]);

  const loadJobDetailResponse = async () => {
    try {
      const response = await getJob(id);
      setJobDetail(response.data.job);
      setJobDetailLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadInvoiceList = async () => {
    try {
      const response = await getInvoices(id);
      setInvoiceList(response.data.invoices);
      setInvoiceListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadRciList = async () => {
    try {
      const response = await getRcis(id);
      setRciList(response.data.rcis);
      setRciListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadEstimateRevisionList = async () => {
    try {
      const response = await getEstimateRevisions(id);
      setEstimateRevisionList(response.data.estimateRevisions);
      setEstimateRevisionListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadEstimateRevisionItemList = async () => {
    try {
      const response = await getEstimateRevisionItems(revisionId);
      setEstimateRevisionItemList(response.data.estimateRevisionItems);
      setEstimateRevisionItemListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadRevisionLineItemCategoryList = async () => {
    try {
      setRevisionLineItemCatgoryListLoad(true);
      const response = await getRevisionLineItemCategories(revisionId);
      setRevisionLineItemCatgoryList(response.data.revisionLineItemCategories);
      setRevisionLineItemCatgoryListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const cloneRevisionRecord = async () => {
    try {
      setBtnLoad(true);
      let payload = {
        estimate_revision: {
          name: formik.values.name,
          revision_id: revisionId,
        },
      };
      const response = await createEstimateRevision(id, payload);
      history.push(
        `/jobs/${id}/revisions/${response.data.revision_id}/estimate`
      );
      formik.resetForm();
      setBtnLoad(false);
      setNewRevisionModalOpen(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      setBtnLoad(false);
      loadEstimateRevisionList();
    }
  };

  const handleLineItemClick = async item => {
    try {
      const payload = {
        estimate_revision_item: {
          estimate_line_item_id: item.id,
          commission: jobDetail.jobType.commissionPercentage,
          commission_type: "commission",
          status: "unassigned",
          kind: "estimate",
          new_item: true,
          revision_line_item_category_id: openedParent,
        },
      };

      await createEstimateRevisionItem(revisionId, payload);
      await loadEstimateRevisionList();
      await loadEstimateRevisionItemList();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      loadRciList();
      loadInvoiceList();
    }
  };

  const handleLineItemCategoryClick = async categoryId => {
    try {
      const payload = {
        revision_line_item_categories: {
          lineItemCategoryId: categoryId,
        },
      };

      await createRevisionLineItemCategory(revisionId, payload);
      await loadEstimateRevisionList();
      await loadRevisionLineItemCategoryList();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      loadRciList();
      loadInvoiceList();
    }
  };

  const createOrRedirectToInvoice = () => {
    setBtnLoad(true);
    if (finalInvoice) {
      history.push(`/invoices/${finalInvoice.id}`);
    } else {
      createInvoiceEntry(true);
    }
  };

  const createInvoiceEntry = async status => {
    try {
      status ? setFinalInvBtnLoad(true) : setCommInvBtnLoad(true);
      const percentage = status
        ? !commencementInvoice ||
          Number(jobDetail.commencementFee) == 0 ||
          R.isNil(jobDetail.commencementFee)
          ? 100
          : 100 - jobDetail.commencementFee
        : jobDetail.commencementFee;
      const payload = {
        invoice: {
          purchase_order: jobDetail.purchaseOrder,
          client_job_number: jobDetail.clientJobNumber,
          final: status,
          job_id: jobDetail.id,
          percentage: percentage,
          invoice_items_attributes: prepareInvoiceItemPayload(
            status,
            percentage
          ),
        },
      };
      const response = await createInvoice(jobDetail.id, payload);
      history.push(`/invoices/${response.data.id}`);
    } catch (error) {
      loadInvoiceList();
      loadEstimateRevisionList();
      loadEstimateRevisionItemList();
      showToastrError(error.data.errors[0]);
    } finally {
      setCommInvBtnLoad(false);
      setFinalInvBtnLoad(false);
      setBtnLoad(false);
    }
  };

  const prepareInvoiceItemPayload = (status, percentage) => {
    return estimateItems.map(item => {
      return {
        name: item.estimateLineItem.name,
        xeroAccountCode: item.estimateLineItem.xeroAccountCode,
        feeType: item.feeType,
        quantity: item.quantity || 0,
        rateTbc: item.rateTbc,
        rateNa: item.rateNa,
        rate: Number(item.rate) + Number(item.adjustAmount),
        markupPercentage:
          item.feeType === "agency_service_fee" ? null : item.markupPercentage,
        metrics: item.metricName || "",
        notes: item.notes,
        percentage: percentage,
        kind: item.kind,
        estimate_revision_item_id: item.id,
      };
    });
  };

  const generateEstimateBtnDisability = () => {
    return (currentRevision?.usageEnabled &&
      currentRevision?.licensesCount === 1 &&
      usageEstimateItem) ||
      !currentRevision?.usageEnabled ||
      currentRevision?.licensesCount > 1
      ? false
      : true;
  };

  const generateEstimateBtnText = () => {
    return (currentRevision?.usageEnabled &&
      currentRevision?.licensesCount === 1 &&
      usageEstimateItem) ||
      !currentRevision?.usageEnabled ||
      currentRevision?.licensesCount > 1
      ? "Generate Estimate"
      : "Add Usage to Generate Estimate";
  };

  const revisionDetail = () => {
    return estimateRevisionList.find(revision => revision.id === revisionId);
  };

  const approvedRevisionId = getApprovedRevisionId(estimateRevisionList);
  const finalInvoice = getFinalInvoice(invoiceList);
  const commencementInvoice = getCommencementInvoice(invoiceList);
  const finalRci = getFinalInvoice(rciList);
  const commencementRci = getCommencementInvoice(rciList);

  let contextValue = {
    jobDetail,
    revisionId,
    approvedRevisionId,
    finalInvoice,
    commencementInvoice,
    finalRci,
    commencementRci,
    estimateRevisionItemList,
    estimateRevisionItemListLoad,
    loadEstimateRevisionItemList,
    setEstimateRevisionItemList,
    currentRevision,
    loadInvoiceList,
    loadRciList,
    rciList,
    loadEstimateRevisionList,
    revisionLineItemCatgoryList,
    handleLineItemCategoryClick,
    openedParent,
    setOpenedParent,
    loadRevisionLineItemCategoryList,
    btnLoad,
  };

  const actionBlockComponent = () => {
    return (
      <div className="flex space-x-2">
        <Button
          style="secondary"
          icon={Edit}
          tooltipProps={{
            position: "bottom",
            content: "Edit",
          }}
          onClick={() => {
            setNewJobPane(true);
          }}
        />

        <Button
          style="secondary"
          icon={Settings}
          iconPosition="left"
          label="Estimate Settings"
          onClick={() => setEstimateSettingsOpen(true)}
        />

        {((currentRevision?.usageEnabled && usageEstimateItem) ||
          !currentRevision?.usageEnabled) &&
          approvedRevisionId === revisionId &&
          currentRevision.staffApproved &&
          currentRevision.management1Approved &&
          Number(jobDetail.commencementFee || 0) > 0 &&
          !finalInvoice &&
          extraItems.length === 0 && (
            <Button
              label="Commencement Invoice"
              loading={commInvBtnLoad}
              className="mr-4"
              disabled={commencementInvoice}
              onClick={() => createInvoiceEntry(false)}
            />
          )}

        {((currentRevision?.usageEnabled && usageEstimateItem) ||
          !currentRevision?.usageEnabled) &&
          approvedRevisionId === revisionId &&
          currentRevision?.staffApproved &&
          currentRevision?.management1Approved &&
          currentRevision?.management2Approved &&
          extraItems.length === 0 && (
            <Button
              label="Generate Final Invoice"
              loading={finalInvBtnLoad}
              onClick={() => {
                if (finalInvoice || jobDetail.attachments_attributes) {
                  createOrRedirectToInvoice();
                } else {
                  setUploadModal(true);
                }
              }}
            />
          )}

        <Button
          label={generateEstimateBtnText()}
          to={
            generateEstimateBtnDisability()
              ? `/jobs/${jobDetail.id}/revisions/${revisionId}/usage`
              : `/mailer/jobs/${jobDetail.id}/revisions/${revisionId}`
          }
        />

        {staffApprovedInAllRevisions.every(approval => !approval) &&
          !commencementRci &&
          !finalRci && (
            <Button
              label="New Revision"
              onClick={() => setNewRevisionModalOpen(true)}
            />
          )}
      </div>
    );
  };

  if (
    jobDetailLoad ||
    estimateRevisionListLoad ||
    invoiceListLoad ||
    rciListLoad ||
    revisionLineItemCatgoryListLoad
  ) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <EstimateContext.Provider value={contextValue}>
      <Container isHeaderFixed>
        <Header
          title={`${revisionDetail()?.name} / Estimate`}
          breadcrumbs={[
            { text: "Jobs", link: "/jobs" },
            {
              text: jobWithNameNumber(jobDetail),
              link: `/jobs/${id}/overview`,
            },
          ]}
          actionBlock={actionBlockComponent()}
        />

        <Tabs jobId={id} revisionId={revisionId} note={jobDetail.note} />

        <div
          className="flex w-full overflow-hidden"
          style={{ height: "calc(100vh - 134px)" }}
        >
          <div className="relative w-8/12 p-6 space-y-4 overflow-y-scroll border-r neeto-ui-bg-gray-100 neeto-ui-border-gray-300">
            {jobDetail.cancellationReason && (
              <Callout style="danger" icon={CloseCircle}>
                <span>
                  In <b>Graveyard</b> because of &ldquo;
                  {jobDetail.cancellationReason}
                  &rdquo;
                </span>
              </Callout>
            )}
            {jobDetail.note && (
              <Callout style="warning" icon={Warning}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: jobDetail.note.split("\n").join("<br/>"),
                  }}
                />
              </Callout>
            )}
            {!R.isNil(jobDetail.xeroTokenExpired) &&
              !jobDetail.xeroTokenExpired && (
                <Callout className="w-full mb-3">Xero Logged in!</Callout>
              )}
            <EstimateDetails
              uploadModal={uploadModal}
              setUploadModal={setUploadModal}
              createInvoiceEntry={createInvoiceEntry}
              createOrRedirectToInvoice={createOrRedirectToInvoice}
            />
          </div>

          <div className="w-4/12 p-6 space-y-6 overflow-y-scroll neeto-ui-bg-white">
            <ItemsDirectory
              handleLineItemClick={handleLineItemClick}
              handleLineItemCategoryClick={handleLineItemCategoryClick}
            />

            <EstimateRevisions
              estimateRevisionList={estimateRevisionList}
              loadEstimateRevisionListResponse={loadEstimateRevisionList}
              jobDetail={jobDetail}
              invoiceList={invoiceList}
              loadJobDetailResponse={loadJobDetailResponse}
              setCurrentRevisionId={setCurrentRevisionId}
              currentRevisionId={revisionId}
              loadRciList={loadRciList}
              usageEstimateItem={usageEstimateItem}
            />
          </div>
        </div>

        <EstimateSettingPane
          estimateSettingsOpen={estimateSettingsOpen}
          setEstimateSettingsOpen={setEstimateSettingsOpen}
          jobDetail={jobDetail}
          loadJobDetailResponse={loadJobDetailResponse}
          invoiceList={invoiceList}
        />

        <Modal
          isOpen={newRevisionModalOpen}
          onClose={() => setNewRevisionModalOpen(false)}
          initialFocusRef={inputRef}
        >
          <Modal.Header>
            <Typography style="h2" weight="semibold">
              New Revision
            </Typography>
          </Modal.Header>

          <Modal.Body>
            <Input
              label="Revision Name"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              error={
                Boolean(formik.touched.name && formik.errors.name) &&
                formik.errors.name
              }
              {...formik.getFieldProps("name")}
            />
          </Modal.Body>
          <Modal.Footer className="space-x-2">
            <Button
              label="Save Changes"
              onClick={() => formik.handleSubmit()}
              loading={btnLoad}
            />
            <Button
              style="text"
              label="Cancel"
              loading={btnLoad}
              onClick={() => setNewRevisionModalOpen(false)}
            />
          </Modal.Footer>
        </Modal>

        <NewJob
          isOpen={newJobPane}
          jobId={id}
          onClose={() => setNewJobPane(false)}
          setNewJobPane={setNewJobPane}
          detailsLoad={loadJobDetailResponse}
          redirectTo={`/jobs/${id}/revisions/${revisionId}/estimate`}
        />
      </Container>
    </EstimateContext.Provider>
  );
};

export default Estimate;

import * as Yup from "yup";
import { MAX_STRING_LENGTH } from "common/constants";

export const AGENCY_GROUP_VALIDATION_SCHEMA = Yup.object({
  name: Yup.string()
    .required("Name can't be empty.")
    .max(
      MAX_STRING_LENGTH,
      `Name must be at most ${MAX_STRING_LENGTH} characters.`
    ),
});

export const AGENCY_GROUP_INITIAL_VALUE = {
  name: "",
};

export const CURRENCY_VALIDATION_SCHEMA = Yup.object({
  name: Yup.string()
    .required("Name can't be empty.")
    .max(
      MAX_STRING_LENGTH,
      `Name must be at most ${MAX_STRING_LENGTH} characters.`
    ),
});

export const CURRENCY_INITIAL_VALUE = {
  name: "",
};

export const JOB_TYPE_VALIDATION_SCHEMA = Yup.object({
  name: Yup.string()
    .required("Name can't be empty.")
    .max(
      MAX_STRING_LENGTH,
      `Name must be at most ${MAX_STRING_LENGTH} characters.`
    ),
  commissionPercentage: Yup.number()
    .required("Commission percentage can't be empty.")
    .min(0, "Commission percentage must be greater than or equal to 0."),
});

export const JOB_TYPE_INITIAL_VALUE = {
  name: "",
  commissionPercentage: 30.0,
};

export const ORGANISATION_TYPE_VALIDATION_SCHEMA = Yup.object({
  name: Yup.string()
    .required("Name can't be empty.")
    .max(
      MAX_STRING_LENGTH,
      `Name must be at most ${MAX_STRING_LENGTH} characters.`
    ),
});

export const ORGANISATION_TYPE_INITIAL_VALUE = {
  name: "",
};

export const INDUSTRY_SECTOR_VALIDATION_SCHEMA = Yup.object({
  name: Yup.string()
    .required("Name can't be empty.")
    .max(
      MAX_STRING_LENGTH,
      `Name must be at most ${MAX_STRING_LENGTH} characters.`
    ),
});

export const INDUSTRY_SECTOR_INITIAL_VALUE = {
  name: "",
};

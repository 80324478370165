import React, { useState, useEffect } from "react";
import * as R from "ramda";
import { Delete, Reorder } from "@bigbinary/neeto-icons";
import { Textarea, Button } from "@bigbinary/neetoui";
import { Draggable } from "react-beautiful-dnd";
import AsyncPaginateSelect from "components/Common/AsyncPaginateSelect";

const ScopeItem = ({
  revisionId,
  index,
  item,
  deleteFunction,
  selectOptions,
  newCreateableName,
  onChangeFunction,
  isDisable,
  updateScopeEntryCall,
  type,
  setRevisionSpecificProjectScope = () => {},
  setRevisionSpecificDeliveryScope = () => {},
  loadRevisionSpecificProjectData = () => {},
  loadRevisionSpecificDeliveryData = () => {},
}) => {
  const [scope, setScope] = useState("");
  const customStyles = {
    control: provided => ({
      ...provided,
      height: "3.5rem",
      minHeight: "fit-content",
    }),
    valueContainer: base => ({
      ...base,
      maxHeight: "3.5rem",
    }),
  };

  useEffect(() => {
    if (item) {
      setScope(item.label);
    }
  }, [item]);

  return (
    <Draggable
      key={item.value?.toString()}
      draggableId={item.value?.toString()}
      index={index}
    >
      {provided => (
        <div
          className="flex items-center justify-between mt-3 space-x-1"
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div {...provided.dragHandleProps}>
            <Reorder size={20} color="#87929d" />
          </div>
          {item.value?.length > 0 && !R.isNil(item.label) ? (
            <>
              <Textarea
                size="medium"
                disabled={isDisable()}
                rows={1}
                value={scope}
                styles={customStyles}
                key={item.value}
                onChange={e => setScope(e.target.value)}
                onBlur={e =>
                  updateScopeEntryCall(
                    revisionId,
                    item.value,
                    e.target.value,
                    type,
                    loadRevisionSpecificProjectData,
                    loadRevisionSpecificDeliveryData
                  )
                }
              />
              <Button
                style="text"
                size="small"
                icon={Delete}
                disabled={isDisable()}
                onClick={() => {
                  deleteFunction(
                    revisionId,
                    item,
                    index,
                    type,
                    setRevisionSpecificProjectScope,
                    setRevisionSpecificDeliveryScope,
                    loadRevisionSpecificProjectData,
                    loadRevisionSpecificDeliveryData
                  );
                }}
              />
            </>
          ) : (
            <div className="flex items-center w-full space-x-2">
              <AsyncPaginateSelect
                cacheOptions
                isSearchable
                isCreatable
                size="large"
                className="word-break w-40"
                onCreateOption={inputValue => {
                  newCreateableName(
                    revisionId,
                    { label: inputValue },
                    index,
                    item,
                    type,
                    setRevisionSpecificProjectScope,
                    setRevisionSpecificDeliveryScope,
                    loadRevisionSpecificProjectData,
                    loadRevisionSpecificDeliveryData
                  );
                }}
                onChange={option => {
                  onChangeFunction(
                    revisionId,
                    option,
                    index,
                    item,
                    type,
                    setRevisionSpecificProjectScope,
                    setRevisionSpecificDeliveryScope,
                    loadRevisionSpecificProjectData,
                    loadRevisionSpecificDeliveryData
                  );
                }}
                value={item}
                isDisabled={isDisable()}
                defaultOptions={selectOptions}
                onKeyDown={e => {
                  if (e.key === "Tab") {
                    e.preventDefault();
                  }
                }}
              />
              <Button
                style="text"
                size="small"
                icon={Delete}
                disabled={isDisable()}
                onClick={() => {
                  deleteFunction(
                    revisionId,
                    item,
                    index,
                    type,
                    setRevisionSpecificProjectScope,
                    setRevisionSpecificDeliveryScope,
                    loadRevisionSpecificProjectData,
                    loadRevisionSpecificDeliveryData
                  );
                }}
              />
            </div>
          )}
        </div>
      )}
    </Draggable>
  );
};

export default ScopeItem;

import React, { useRef } from "react";
import { Pane, Typography, Input, Textarea, Button } from "@bigbinary/neetoui";

const FormPane = ({
  isOpen,
  onClose,
  title,
  formik,
  onSubmit,
  buttonLoader,
}) => {
  const inputRef = useRef();

  return (
    <Pane isOpen={isOpen} onClose={onClose} initialFocusRef={inputRef}>
      <Pane.Header>
        <Typography style="h2" weight="semibold">
          {title}
        </Typography>
      </Pane.Header>
      <Pane.Body>
        <div className="flex flex-col w-full space-y-6">
          <Input
            label="Name"
            ref={inputRef}
            required={true}
            onChange={formik.handleChange}
            value={formik.values.name}
            error={
              Boolean(formik.touched.name && formik.errors.name) &&
              formik.errors.name
            }
            {...formik.getFieldProps("name")}
          />
          <Textarea
            label="Condition"
            required={true}
            onChange={formik.handleChange}
            value={formik.values.condition}
            error={
              Boolean(formik.touched.condition && formik.errors.condition) &&
              formik.errors.condition
            }
            {...formik.getFieldProps("condition")}
          />
        </div>
      </Pane.Body>
      <Pane.Footer className="flex space-x-2">
        <Button
          label="Save changes"
          onClick={() => onSubmit()}
          loading={buttonLoader}
        />
        <Button label="Cancel" style="text" onClick={() => onClose()} />
      </Pane.Footer>
    </Pane>
  );
};

export default FormPane;

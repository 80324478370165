import axios from "axios";
import { direction } from "common/helper";

const getContactsUrl = (orgId, id) => {
  if (id) {
    return `/api/v1/organisations/organisations/${orgId}/contacts/${id}`;
  }

  return `/api/v1/organisations/organisations/${orgId}/contacts`;
};

export const getContacts = (
  organisationId,
  searchParams = "",
  sortProps = {},
  pageIndex,
  perPage
) => {
  let paginationQuery = `page=${pageIndex}&per_page=${perPage}`;
  let searchQuery = `q[full_name_i_cont_any]=${searchParams}&q[active_organisation_contact_title_i_cont_any]=${searchParams}&q[m]=or`;
  let sortQuery = `q[s]=${sortProps?.column?.field}+${direction(sortProps?.order)}`;
  let query = paginationQuery;
  if (searchParams.length > 0) query += `&${searchQuery}`;
  if (sortProps?.column && sortProps?.order) query += `&${sortQuery}`;

  return axios.get(
    `/api/v1/organisations/organisations/${organisationId}/contacts?${query}`
  );
};

export const getContact = (orgId, id) => {
  return axios.get(getContactsUrl(orgId, id));
};

export const createContact = (orgId, payload) => {
  return axios.post(getContactsUrl(orgId), payload);
};

export const updateContact = (orgId, id, payload) => {
  return axios.put(getContactsUrl(orgId, id), payload);
};

export const destroyContact = (orgId, id) => {
  return axios.delete(getContactsUrl(orgId, id));
};

import React from "react";
import classnames from "classnames";
import { Typography } from "@bigbinary/neetoui";

const Card = ({ title, children, className = "", titleAction = null }) => {
  return (
    <div
      className={classnames(
        [
          "bg-white p-4 rounded-md border neeto-ui-border-gray-200 neeto-ui-shadow-xs",
        ],
        className
      )}
    >
      <div className="flex justify-between mb-4">
        <Typography style="h4" weight="semibold">
          {title}
        </Typography>
        {titleAction}
      </div>
      {children}
    </div>
  );
};

export default Card;

import React, { useState, useRef, useEffect } from "react";
import { Header, Container } from "@bigbinary/neetoui/layouts";
import {
  Spinner,
  Button,
  Alert,
  Input,
  Modal,
  Typography,
  Toastr,
} from "@bigbinary/neetoui";
import { isEmpty } from "ramda";
import { useFormik } from "formik";
import {
  getProjectScopes,
  createProjectScope,
  updateProjectScope,
  destroyProjectScope,
} from "apis/settings/project_scopes";
import { showToastrError } from "common";
import useDebounce from "common/debounce";
import { getRandomNotFoundImage } from "common/helper";
import {
  PROJECT_SCOPE_VALIDATION_SCHEMA,
  PROJECT_SCOPE_INITIAL_VALUE,
} from "../constants";
import List from "./List";
import EmptyState from "components/Common/EmptyState";

const ProjectScopes = ({ breadcrumbs }) => {
  const inputRef = useRef();
  const [projectScopeList, setProjectScopeList] = useState([]);
  const [listLoader, setListLoader] = useState(true);
  const [projectScope, setProjectScope] = useState({});
  const [modalState, setModalState] = useState(false);
  const [deleteAlertState, setDeleteAlertState] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [searchParams, setSearchParams] = useState();
  const debouncedSearchTerm = useDebounce(searchParams, 1000);
  const [emptyImage, setEmptyImage] = useState();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: projectScope?.id
      ? projectScope
      : PROJECT_SCOPE_INITIAL_VALUE,
    validationSchema: PROJECT_SCOPE_VALIDATION_SCHEMA,
    onSubmit: () => {
      if (projectScope?.id) {
        updateProjectScopeEntry();
      } else {
        createProjectScopeEntry();
      }
    },
  });

  useEffect(() => {
    loadProjectScopeList();
    setEmptyImage(getRandomNotFoundImage());
  }, []);

  useEffect(() => {
    if (debouncedSearchTerm != undefined) {
      loadProjectScopeList();
    }
  }, [debouncedSearchTerm]);

  const loadProjectScopeList = async () => {
    try {
      const response = await getProjectScopes(searchParams);
      setProjectScopeList(response.data.projectScopes || []);
      setListLoader(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const createProjectScopeEntry = async () => {
    try {
      const response = await createProjectScope({
        project_scope: {
          name: formik.values.name,
        },
      });
      setModalState(false);
      Toastr.info(response.data.notice);
      formik.resetForm();
      loadProjectScopeList();
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setButtonLoader(false);
    }
  };

  const updateProjectScopeEntry = async () => {
    try {
      const response = await updateProjectScope(projectScope?.id, {
        project_scope: {
          name: formik.values.name,
        },
      });
      setModalState(false);
      Toastr.info(response.data.notice);
      formik.resetForm();
      loadProjectScopeList();
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setButtonLoader(false);
    }
  };

  const destroyProjectScopeEntry = async () => {
    try {
      const response = await destroyProjectScope(projectScope?.id);
      setDeleteAlertState(false);
      Toastr.info(response.data.notice);
      loadProjectScopeList();
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setButtonLoader(false);
    }
  };

  const handleNewButtonClick = async () => {
    setButtonLoader(false);
    formik.resetForm();
    setProjectScope({});
    setModalState(true);
  };

  const handleSubmitButtonClick = async () => {
    if (Object.keys(formik.errors).length === 0) {
      setButtonLoader(true);
      formik.handleSubmit();
    }
  };

  if (listLoader) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <Container>
      <Header
        title="Project Scopes"
        breadcrumbs={breadcrumbs}
        searchProps={{
          value: searchParams,
          onChange: e => setSearchParams(e.target.value),
          clear: () => setSearchParams(),
        }}
        actionBlock={
          <Button label="Add New" onClick={() => handleNewButtonClick()} />
        }
      />

      <div className="w-full" style={{ height: "calc(100vh - 128px)" }}>
        {!isEmpty(projectScopeList) ? (
          <List
            projectScopeList={projectScopeList}
            setProjectScope={setProjectScope}
            setModalState={setModalState}
            setDeleteAlertState={setDeleteAlertState}
          />
        ) : (
          <EmptyState
            image={emptyImage}
            title="No Project Scopes Found"
            description="We couldn’t find any project scopes. Try creating one."
            primaryButtonProps={{
              label: "Add New",
              onClick: () => handleNewButtonClick(),
            }}
          />
        )}
      </div>

      <Modal
        isOpen={modalState}
        onClose={() => setModalState(false)}
        initialFocusRef={inputRef}
      >
        <Modal.Header>
          <Typography style="h2" weight="semibold">
            {projectScope?.id ? "Edit Project Scope" : "Add Project Scope"}
          </Typography>
        </Modal.Header>
        <Modal.Body>
          <div className="flex flex-col w-full space-y-6">
            <Input
              label="Name"
              ref={inputRef}
              required={true}
              onChange={formik.handleChange}
              value={formik.values.name}
              error={
                Boolean(formik.touched.name && formik.errors.name) &&
                formik.errors.name
              }
              {...formik.getFieldProps("name")}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="flex gap-x-2">
          <Button
            label="Save changes"
            onClick={() => handleSubmitButtonClick()}
            loading={buttonLoader}
          />
          <Button
            label="Cancel"
            style="text"
            onClick={() => setModalState(false)}
          />
        </Modal.Footer>
      </Modal>

      <Alert
        isOpen={deleteAlertState}
        title="Delete Project Scope"
        message={
          <>
            Are you sure you want to delete the project scope{" "}
            <strong>{projectScope?.name}</strong>?
          </>
        }
        onClose={() => setDeleteAlertState(false)}
        onSubmit={() => {
          setButtonLoader(true);
          destroyProjectScopeEntry();
        }}
        isSubmitting={buttonLoader}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, delete"
      />
    </Container>
  );
};

export default ProjectScopes;

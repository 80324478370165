import React, { useState, useEffect } from "react";
import { Delete, Reorder } from "@bigbinary/neeto-icons";
import { Input, Button, Spinner, Alert } from "@bigbinary/neetoui";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import produce from "immer";
import { showToastrError } from "common";
import { updateJob } from "apis/jobs/jobs";
import { updateKillFeePosition, destroyKillFee } from "apis/jobs/kill_fees";

const KillFee = ({
  jobId,
  killFeeList,
  killFeeLoading,
  loadJobKillFee,
  setKillFeeLoading,
}) => {
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [killFeeId, setKillFeeId] = useState();
  const [killFeeIndex, setKillFeeIndex] = useState();
  const [killFeeBtnLoading, setKillFeeBtnLoading] = useState(false);
  const [list, setList] = useState([]);

  useEffect(() => {
    if (killFeeList) {
      setList(killFeeList);
    }
  }, [killFeeList]);

  const placeDifference = result => {
    let sourceIndex = result.source.index;
    let destinationIndex = result.destination.index;
    let placeChangeType = "";
    let difference = 0;

    if (sourceIndex > destinationIndex) {
      placeChangeType = "up";
      difference = sourceIndex - destinationIndex;
    } else {
      placeChangeType = "down";
      difference = destinationIndex - sourceIndex;
    }

    return { placeChangeType: placeChangeType, difference: difference };
  };

  const killFeeItemReorderFunction = result => {
    if (!result.destination) {
      return;
    }
    setList(
      produce(draft => {
        const [removed] = draft.splice(result.source.index, 1);
        draft.splice(result.destination.index, 0, removed);
      })
    );

    const { placeChangeType, difference } = placeDifference(result);

    updateKillFeePosition(
      jobId,
      result.draggableId,
      placeChangeType,
      difference
    );
  };

  const destroyKillFeeItem = async () => {
    try {
      if (killFeeId) {
        await destroyKillFee(jobId, killFeeId);
      } else {
        setList(state => {
          let value = [...state];
          value.splice(killFeeIndex, 1);
          return value;
        });
      }

      setDeleteAlertOpen(false);
      loadJobKillFee();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const updateJobWithKillFee = async () => {
    try {
      setKillFeeBtnLoading(true);
      let payload = { job: { kill_fees_attributes: list } };
      await updateJob(jobId, payload);
      setKillFeeLoading(false);
      loadJobKillFee();
      setKillFeeBtnLoading(false);
    } catch (error) {
      setKillFeeBtnLoading(false);
      showToastrError(error.data.errors[0]);
    }
  };

  if (killFeeLoading) {
    return (
      <div className="flex items-center justify-center h-20">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="w-full">
      <DragDropContext onDragEnd={killFeeItemReorderFunction}>
        <Droppable droppableId="droppable">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {list?.map((item, index) => {
                return (
                  <Draggable
                    key={item.id?.toString()}
                    draggableId={item.id?.toString()}
                    index={index}
                  >
                    {provided => (
                      <div
                        className="flex items-center justify-between mt-3 space-x-1"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        <div {...provided.dragHandleProps}>
                          <Reorder size={20} color="#87929d" />
                        </div>

                        <div className="grid w-full grid-cols-2 gap-4">
                          <Input
                            value={item.name}
                            size="large"
                            onChange={e => {
                              setList(
                                produce(draft => {
                                  draft[index] = {
                                    ...draft[index],
                                    name: e.target.value,
                                  };
                                })
                              );
                            }}
                          />

                          <Input
                            type="number"
                            size="large"
                            value={item.percentage}
                            onChange={e => {
                              setList(
                                produce(draft => {
                                  draft[index] = {
                                    ...draft[index],
                                    percentage: e.target.value,
                                  };
                                })
                              );
                            }}
                            suffix={
                              <Button
                                style="text"
                                size="small"
                                icon={Delete}
                                onClick={() => {
                                  setKillFeeId(item.id);
                                  setKillFeeIndex(index);
                                  setDeleteAlertOpen(true);
                                }}
                              />
                            }
                          />
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div className="flex justify-end w-full mt-6 mb-2 space-x-2">
        <Button
          label="Add Kill Fee Item"
          onClick={() => {
            setList(state => {
              return [
                ...state,
                {
                  name: "",
                  percentage: 0,
                  kind: "in_job",
                },
              ];
            });
          }}
        />
        <Button
          className="ml-4"
          label="Save Changes"
          onClick={() => updateJobWithKillFee()}
          loading={killFeeBtnLoading}
        />
      </div>

      <Alert
        isOpen={deleteAlertOpen}
        title="Delete Kill Fee"
        message="Are you sure you want to delete this kill fee?"
        onClose={() => setDeleteAlertOpen(false)}
        onSubmit={() => destroyKillFeeItem()}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, delete"
      />
    </div>
  );
};

export default KillFee;

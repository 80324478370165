import axios from "axios";

export const getAccounts = () => {
  return axios.get(`/api/v1/settings/accounts`);
};

export const getAccount = () => {
  return axios.get(`/api/v1/settings/accounts/1`);
};

export const updateAccount = (id, payload) => {
  return axios.put(`/api/v1/settings/accounts/${id}`, payload);
};

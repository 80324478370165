import React, { useState, useEffect, createContext } from "react";
import { showToastrError } from "common";
import { Container, Header } from "@bigbinary/neetoui/layouts";
import { Spinner, Button } from "@bigbinary/neetoui";
import {
  getDefaultUsages,
  getDefaultUsage,
} from "apis/settings/default_usages";
import { getCategories } from "apis/settings/categories";
import { getDetails } from "apis/settings/details";
import { getQuantities } from "apis/settings/quantities";
import { getTerritories } from "apis/settings/territories";
import { getPeriods } from "apis/settings/periods";
import { getExclusivityPeriods } from "apis/settings/exclusivity_periods";
import { dropDownListGenerator, getRandomNotFoundImage } from "common/helper";
import { isEmpty } from "ramda";

import List from "./List";
import UsageTemplatePane from "./UsageTemplatePane";
import EmptyState from "components/Common/EmptyState";

export const DefaultUsageTemplateContext = createContext();

const DefaultUsageTemplates = ({ title }) => {
  const [usage, setUsage] = useState();
  const [usageLoad, setUsageLoad] = useState(true);
  const [usageItems, setUsageItems] = useState([
    {
      name: "Usage Option 1",
      category: "",
      detail: "",
      quantity: "",
      period: "",
      exclusivityPeriod: "",
      fee: 0,
    },
  ]);

  const [usageName, setUsageName] = useState("");
  const [usageList, setUsageList] = useState([]);
  const [usageListLoad, setUsageListLoad] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [categoryListLoad, setCategoryListLoad] = useState(true);
  const [detailList, setDetailList] = useState([]);
  const [detailListLoad, setDetailListLoad] = useState(true);
  const [quantityList, setQuantityList] = useState([]);
  const [quantityListLoad, setQuantityListLoad] = useState(true);
  const [territoryList, setTerritoryList] = useState([]);
  const [territoryListLoad, setTerritoryListLoad] = useState(true);
  const [periodList, setPeriodList] = useState([]);
  const [periodListLoad, setPeriodListLoad] = useState(true);
  const [exclusivityPeriodList, setExclusivityPeriodList] = useState([]);
  const [exclusivityPeriodListLoad, setExclusivityPeriodListLoad] = useState(
    true
  );
  const [showPane, setShowPane] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [emptyImage, setEmptyImage] = useState();

  useEffect(() => {
    loadUsageListData("blank");
    loadUsageItemData();
    setEmptyImage(getRandomNotFoundImage());
  }, []);

  const loadUsageItemData = async () => {
    loadCategoryList();
    loadDetailList();
    loadQuantityList();
    loadTerritoryList();
    loadPeriodList();
    loadExclusivityPeriodList();
  };

  const loadCategoryList = async () => {
    try {
      const categoryResponse = await getCategories();
      setCategoryList(
        dropDownListGenerator(categoryResponse.data.categories) || []
      );
      setCategoryListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadDetailList = async () => {
    try {
      const detailResponse = await getDetails();
      setDetailList(dropDownListGenerator(detailResponse.data.details) || []);
      setDetailListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadQuantityList = async () => {
    try {
      const quantityResponse = await getQuantities();
      setQuantityList(
        dropDownListGenerator(quantityResponse.data.quantities) || []
      );
      setQuantityListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadTerritoryList = async () => {
    try {
      const territoryResponse = await getTerritories();
      setTerritoryList(
        dropDownListGenerator(territoryResponse.data.territories) || []
      );
      setTerritoryListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadPeriodList = async () => {
    try {
      const periodResponse = await getPeriods();
      setPeriodList(dropDownListGenerator(periodResponse.data.periods) || []);
      setPeriodListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadExclusivityPeriodList = async () => {
    try {
      const exclusivityPeriodResponse = await getExclusivityPeriods();
      setExclusivityPeriodList(
        dropDownListGenerator(
          exclusivityPeriodResponse.data.exclusivityPeriods
        ) || []
      );
      setExclusivityPeriodListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadUsageData = async id => {
    try {
      setUsageLoad(true);
      const { data } = await getDefaultUsage(id);
      setUsage(data.license);
      setUsageLoad(false);
      setUsageName(data.license.name);
      setUsageItems(data.license.licenseItems || []);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadUsageListData = async (usageStatus = "") => {
    try {
      const { data } = await getDefaultUsages();
      setUsageList(data.licenses || []);
      setUsageListLoad(false);
      if (
        data.licenses?.length > 0 &&
        (usage === undefined || usageStatus === "blank")
      ) {
        setUsageLoad(true);
        loadUsageData(data.licenses[0].id);
      } else {
        setUsageLoad(false);
      }
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  let contextValue = {
    usageItems,
    setUsageItems,
    usageList,
    loadUsageData,
    loadUsageListData,
    usage,
    setUsageName,
    setUsage,
    categoryList,
    setCategoryList,
    detailList,
    setDetailList,
    quantityList,
    setQuantityList,
    territoryList,
    setTerritoryList,
    periodList,
    setPeriodList,
    exclusivityPeriodList,
    setExclusivityPeriodList,
  };

  if (
    usageLoad ||
    usageListLoad ||
    categoryListLoad ||
    detailListLoad ||
    quantityListLoad ||
    territoryListLoad ||
    periodListLoad ||
    exclusivityPeriodListLoad
  ) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <Container>
      <DefaultUsageTemplateContext.Provider value={contextValue}>
        <Header
          title={title}
          actionBlock={
            <Button
              label="Add Usage Template"
              onClick={() => {
                setUsage();
                setUsageName("");
                setUsageItems([
                  {
                    name: "Usage Option 1",
                    category: "",
                    detail: "",
                    quantity: "",
                    period: "",
                    exclusivityPeriod: "",
                    serial: 0,
                    fee: 0,
                    isEditable: true,
                  },
                ]);
                setShowPane(true);
              }}
            />
          }
        />

        <div className="w-full" style={{ height: "calc(100vh - 124px)" }}>
          {!isEmpty(usageList) ? (
            <List setShowPane={setShowPane} setIsEdit={setIsEdit} />
          ) : (
            <EmptyState
              image={emptyImage}
              title="No Usage Templates Found"
              description="We couldn’t find any usage templates. Try creating one."
              primaryButtonProps={{
                label: "Add Usage Template",
                onClick: () => {
                  setUsage();
                  setUsageName("");
                  setUsageItems([
                    {
                      name: "Usage Option 1",
                      category: "",
                      detail: "",
                      quantity: "",
                      period: "",
                      exclusivityPeriod: "",
                      serial: 0,
                      fee: 0,
                      isEditable: true,
                    },
                  ]);
                  setShowPane(true);
                },
              }}
            />
          )}
        </div>
        <UsageTemplatePane
          isOpen={showPane}
          onClose={() => {
            setIsEdit(false);
            setShowPane(false);
          }}
          title={isEdit ? "Edit Usage Template" : "Add Usage Template"}
        />
      </DefaultUsageTemplateContext.Provider>
    </Container>
  );
};

export default DefaultUsageTemplates;

import React from "react";
import { Button, Table, Typography } from "@bigbinary/neetoui";
import * as dayjs from "dayjs";
import { numberWithCommas, displayData, artistModuleName } from "common/helper";

const Rcis = ({ list, title }) => {
  const renderSerial = rowData => {
    return (
      <Button
        style="link"
        to={`/jobs/${rowData.jobId}/rcis/${rowData.id}`}
        label={rowData.serialNumber}
      />
    );
  };

  const renderArtist = rowData => {
    return (
      <Button
        style="link"
        to={`/${artistModuleName().toLowerCase()}/${rowData.artistId}`}
        label={rowData.artistName}
      />
    );
  };

  const renderJob = rowData => {
    return (
      <Button
        style="link"
        to={`/jobs/${rowData.jobId}/overview`}
        label={rowData.jobName}
      />
    );
  };

  const COLUMN_DATA = [
    {
      title: "Serial",
      key: "serialNumber",
      dataIndex: "serialNumber",
      width: 100,
      fixed: "left",
      render: (_, rowData) => renderSerial(rowData),
    },
    {
      title: artistModuleName(),
      key: "artistName",
      dataIndex: "artistName",
      width: 150,
      render: (_, rowData) => renderArtist(rowData),
    },
    {
      title: "Job",
      dataIndex: "jobTitle",
      key: "jobTitle",
      width: 200,
      render: (_, rowData) => renderJob(rowData),
    },
    {
      title: `${artistModuleName()} Tax Status`,
      dataIndex: "artistTaxStatus",
      key: "artistTaxStatus",
      width: 200,
      render: (_, rowData) => displayData(rowData, "artistTaxStatus"),
    },
    {
      title: "Tax Type",
      dataIndex: "taxType",
      key: "taxType",
      width: 150,
      render: (_, rowData) => displayData(rowData, "taxType"),
    },
    {
      title: "Tax Amount",
      dataIndex: "taxPercentage",
      key: "taxPercentage",
      width: 150,
      render: (_, rowData) => numberWithCommas(rowData.taxPercentage),
    },
    {
      title: "Total",
      dataIndex: "amount",
      key: "amount",
      width: 150,
      render: (_, rowData) =>
        numberWithCommas(Number(rowData.amount).toFixed(2)),
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      width: 150,
      render: (_, rowData) => displayData(rowData, "currency"),
    },
    {
      title: "Issued On",
      dataIndex: "issueDate",
      key: "issueDate",
      width: 150,
      render: issueDate => dayjs(issueDate).format("DD/MM/YYYY"),
    },
  ];
  return (
    <div className="flex flex-col w-full mt-4 p-4 rounded bg-white">
      <Typography
        style="h3"
        weight="semibold"
        className="mb-4 mt-2 text-gray-800 px-3"
      >
        {title}
      </Typography>
      <Table fixedHeight columnData={COLUMN_DATA} rowData={list} />
    </div>
  );
};

export default Rcis;

import React from "react";
import { Label, Modal, Typography } from "@bigbinary/neetoui";
import ReactQuill from "react-quill";
import { QUILL_TOOLBAR_STYLE } from "common/constants";

const MessageModal = ({
  editorModalOpen,
  setEditorModalOpen,
  value,
  setValue,
}) => {
  return (
    <Modal
      isOpen={editorModalOpen}
      onClose={() => setEditorModalOpen(false)}
      className="w-screen h-screen rounded-none"
    >
      <Modal.Header>
        <Typography style="h2" weight="semibold">
          Add your Message
        </Typography>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col space-y-2 full-screen-ql-editor">
          <Label>Body</Label>

          <ReactQuill
            value={value}
            onChange={text => setValue(text)}
            modules={{ toolbar: QUILL_TOOLBAR_STYLE }}
            placeholder="Enter your message"
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MessageModal;

import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Button } from "../Shared";

const Approval = ({
  costSummaryData,
  revision,
  totalCost,
  sendApprovalQuestionMailToProduction,
  sendApprovalMailToProduction,
  selectedUsageId,
  emailBackground,
}) => {
  useEffect(() => {
    AOS.init({
      once: false,
    });
  }, []);

  let samt = 0;
  window.addEventListener("scroll", function () {
    samt <= 10 ? samt++ : AOS.refresh();
  });

  return (
    <div className="flex flex-col items-start justify-between w-full py-12 lg:flex-row">
      <div
        className="flex flex-col items-start justify-start w-full h-full lg:w-1/3"
        style={{ fontFamily: "Modern Gothic" }}
      >
        <div className="flex flex-col items-start justify-start mb-8 lg:mb-0">
          <h2 className="text-xl font-medium">Approval</h2>
        </div>
      </div>
      <div
        className="flex flex-col items-start justify-start w-full h-full lg:w-2/3"
        style={{ fontFamily: "Modern Gothic" }}
      >
        <div
          className="flex flex-col items-start justify-start w-full h-full space-y-2"
          data-aos="fade-down"
          data-aos-easing="ease-in-out"
        >
          {costSummaryData
            .filter(item => item.title !== "Total Cost")
            .map((data, index) => (
              <div
                key={index}
                className="flex flex-col items-center justify-between w-full px-6 py-4 space-y-2 text-xl font-medium text-black lg:space-y-0 lg:flex-row rounded_container"
                style={{ backgroundColor: data.bgColor, color: data.textColor }}
              >
                <span className="text-base lg:text-xl">{data.title}</span>
                <span className="text-xl">{data.amount}</span>
              </div>
            ))}
          <div className="flex flex-col w-full px-6 py-4 mt-2 font-medium text-white bg-black divide-y divide-white rounded_container">
            <div className="flex flex-col items-center justify-between w-full space-y-2 lg:flex-row lg:space-y-0">
              <span className="text-base lg:text-xl">Total Cost</span>
              {((revision?.usageEnabled && selectedUsageId?.length > 0) ||
                !revision?.usageEnabled) && (
                <span className="text-xl">{totalCost}</span>
              )}
            </div>
            {revision?.licensesCount > 1 && !selectedUsageId && (
              <div className="flex items-center justify-start w-full pt-4 mt-4 text-base text-center lg:text-left lg:text-xl">
                <span>
                  Please select a usage option first in order for it to be
                  included here.
                </span>
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col items-center justify-center w-full mt-12 lg:mt-16">
          <span className="mb-12" dangerouslySetInnerHTML={{ __html: emailBackground.soundGoodDetails?.split("\n").join("<br />") }}></span>
          <div className="flex flex-col w-full gap-6 lg:grid lg:grid-cols-2">
            <Button
              className="w-full px-12 py-6 text-2xl font-medium text-center text-black rounded_container"
              style={{ backgroundColor: "#FCB613" }}
              onClick={() =>
                ((revision.usageEnabled && selectedUsageId) ||
                  !revision.usageEnabled) &&
                sendApprovalMailToProduction()
              }
              disabled={revision.usageEnabled && !selectedUsageId}
            >
              I approve the Estimate & <br /> General Terms & Conditions
            </Button>
            <Button
              className="w-full px-12 py-6 text-2xl font-medium text-center text-black bg-white border-2 border-black border-solid rounded_container"
              onClick={() => sendApprovalQuestionMailToProduction()}
            >
              I still have questions
            </Button>
          </div>
          {revision?.licensesCount > 1 && !selectedUsageId && (
            <span
              className="mt-6 text-sm font-normal text-center text-black lg:text-left"
              style={{ fontFamily: "Modern Gothic Mono" }}
            >
              You must select a <span className="underline">usage option</span>{" "}
              before approving estimate
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default Approval;

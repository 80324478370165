import React, { useState, useEffect } from "react";
import { Input, Label, Switch, Typography } from "@bigbinary/neetoui";
import CountryPhone from "components/Common/CountryPhone";
import { numberWithCommas } from "common/helper";
import { countries } from "countries-list";

const AddContact = ({ organisationDetail, formik }) => {
  const [selectedCountry, setSelectedCountry] = useState();

  useEffect(() => {
    if (organisationDetail) {
      let country = organisationDetail.primaryAddress?.country;
      let countryObj = Object.entries(countries).find(item => {
        return (
          item[0].toLowerCase() === country?.toLowerCase() ||
          item[1].name.toLowerCase() === country?.toLowerCase()
        );
      });

      countryObj && setSelectedCountry(countryObj[1]);
    }
  }, [organisationDetail]);

  return (
    <div className="pb-8">
      <form className="space-y-6">
        <Input
          label="First Name"
          name="firstName"
          onChange={formik.handleChange}
          value={formik.values.firstName}
          required={true}
          error={
            Boolean(formik.touched.firstName && formik.errors.firstName) &&
            formik.errors.firstName
          }
          {...formik.getFieldProps("firstName")}
        />

        <Input
          label="Last Name"
          name="lastName"
          onChange={formik.handleChange}
          value={formik.values.lastName}
          required={true}
          error={
            Boolean(formik.touched.lastName && formik.errors.lastName) &&
            formik.errors.lastName
          }
          {...formik.getFieldProps("lastName")}
        />

        <Input
          label="Pronouns"
          name="pronouns"
          value={formik.values.pronouns}
          onChange={formik.handleChange}
          error={
            Boolean(formik.touched.pronouns && formik.errors.pronouns) &&
            formik.errors.pronouns
          }
          {...formik.getFieldProps("pronouns")}
        />

        <Input
          label="Email"
          name="email"
          onChange={formik.handleChange}
          value={formik.values.email}
          required={true}
          error={
            Boolean(formik.touched.email && formik.errors.email) &&
            formik.errors.email
          }
          {...formik.getFieldProps("email")}
        />

        <Input
          label="Title"
          name="tite"
          onChange={formik.handleChange}
          value={formik.values.title}
          error={
            Boolean(formik.touched.title && formik.errors.title) &&
            formik.errors.title
          }
          {...formik.getFieldProps("title")}
        />

        <CountryPhone
          formik={formik}
          required={false}
          selectedCountry={selectedCountry}
        />

        <CountryPhone
          formik={formik}
          required={false}
          selectedCountry={selectedCountry}
          attribute="mobileNumber"
        />

        <div className="flex flex-col space-y-2">
          <Switch
            label="Accounts Payable"
            checked={formik.values.accountPayable}
            onChange={() => {
              formik.setFieldValue(
                "accountPayable",
                !formik.values.accountPayable
              );
            }}
          />
          <Typography style="body3" className="text-gray-600">
            Send a copy of invoice whenever generated to the above mentioned
            email
          </Typography>
        </div>

        <div className="flex flex-col space-y-2">
          <Switch
            label="Show In CRM"
            checked={formik.values.showInCrm}
            onChange={() => {
              formik.setFieldValue("showInCrm", !formik.values.showInCrm);
            }}
          />
        </div>

        {formik.values.id && (
          <>
            <div className="flex flex-col space-y-2">
              <Label>Job Count</Label>
              <Label className="font-semibold neeto-ui-text-error-600">
                {formik.values.jobsCount}
              </Label>
            </div>

            <div className="flex flex-col space-y-2">
              <Label>Invoice Amount</Label>
              <Label className="font-semibold neeto-ui-text-error-600">
                {numberWithCommas(
                  Number(
                    Number(formik.values.oldInvoiceTotal) +
                      Number(formik.values.invoiceAmount)
                  ).toFixed(2)
                )}
              </Label>
            </div>
          </>
        )}
      </form>
    </div>
  );
};

export default AddContact;

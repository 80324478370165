import React, { useState } from "react";
import { Tab } from "@bigbinary/neetoui";
import { ORGINIASTION_DETAILS_TABS } from "./constants";
import Profile from "./Profile";
import Jobs from "./Jobs";
import Invoices from "./Invoices";
import Contacts from "./Contacts";

const renderTabs = (tabs, organisationDetail) => {
  switch (tabs.label) {
    case "Profile":
      return <Profile organisationDetail={organisationDetail} />;
    case "Jobs":
      return <Jobs organisationDetail={organisationDetail} />;
    case "Invoices":
      return <Invoices organisationDetail={organisationDetail} />;
    case "Contacts":
      return <Contacts organisationDetail={organisationDetail} />;
  }
};

const OrganisationDetails = ({ organisationDetail }) => {
  const [currentTab, setcurrentTab] = useState(
    ORGINIASTION_DETAILS_TABS.Profile
  );

  return (
    <div className="w-full overflow-auto">
      <div className="flex items-center">
        <Tab className="px-6 border-b">
          {Object.keys(ORGINIASTION_DETAILS_TABS).map((tab, index) => {
            const { label, icon } = ORGINIASTION_DETAILS_TABS[tab];
            return (
              <Tab.Item
                icon={icon}
                key={index}
                className="px-3 py-4"
                active={currentTab.label === label}
                onClick={() => setcurrentTab(ORGINIASTION_DETAILS_TABS[tab])}
              >
                {label}
              </Tab.Item>
            );
          })}
        </Tab>
      </div>

      {renderTabs(currentTab, organisationDetail)}
    </div>
  );
};

export default OrganisationDetails;

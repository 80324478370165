import React, { useCallback, useRef, useState, useEffect } from "react";
import { ImageUpload } from "@bigbinary/neeto-icons";
import { Button } from "@bigbinary/neetoui";
import { useDropzone } from "react-dropzone";

const ChatAttachment = ({
  setImages,
  accept = ".png, .gif, .jpeg, .jpg, .pdf, .vcf",
}) => {
  const imageRef = useRef(null);
  const [imageFiles, setImageFiles] = useState([]);

  const handleClick = () => {
    imageRef.current.click();
  };

  useEffect(() => {
    if (imageFiles?.length > 0) {
      setImages(imageFiles);
    }
  }, [imageFiles]);

  const onDrop = useCallback(files => {
    let imageArray = [];

    files.map(file => {
      const reader = new FileReader();

      reader.onloadend = e => {
        imageArray = [
          ...imageArray,
          {
            filename: file.name,
            attachable_kind: "chat",
            file_data: e.target.result,
            type: file.type,
            _destroy: 0,
          },
        ];

        setImageFiles(imageArray);
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 50,
    accept: accept,
  });

  const DragDropBaseClass =
    "mb-6 flex justify-center p-2 border neeto-ui-border-primary-500 border-dashed rounded-md transition duration-500 ease-in-out hover:bg-gray-50 cursor-pointer";

  return (
    <div className={DragDropBaseClass}>
      {imageFiles?.filter(
        img => img?._destroy !== 1 && img.filename?.length > 0
      )?.length === 0 ? (
        <div
          className="flex flex-col items-center p-4 space-y-3"
          {...getRootProps()}
        >
          <ImageUpload size={32} className="neeto-ui-text-primary-500" />
          <input
            type="file"
            accept={accept}
            id="photo"
            className="hidden"
            ref={imageRef}
            {...getInputProps()}
          />
          {isDragActive ? (
            <p className={"flex justify-center"}>Drop the file here</p>
          ) : (
            <div className="flex flex-col items-center space-y-1">
              <div className="flex items-center justify-center w-full text-sm neeto-ui-text-gray-800">
                <p>Drag and drop or&nbsp;</p>
                <label
                  htmlFor="file-upload"
                  className="font-medium text-purple-500 bg-transparent rounded-md cursor-pointer"
                >
                  <Button
                    style="link"
                    label="Browse"
                    className="underline"
                    onClick={handleClick}
                  />
                </label>
                <p>&nbsp;a file</p>
                <label
                  htmlFor="file-upload"
                  className="font-medium text-purple-500 bg-transparent rounded-md cursor-pointer"
                ></label>
              </div>
              <p className="neeto-ui-typography neeto-ui-text-body3 neeto-ui-text-gray-500">
                Only&nbsp;
                <code className="p-0 m-0 bg-white border-l-0 neeto-ui-typography neeto-ui-text-body3">
                  JPEG, JPG, PNG, GIF, PDF, VCF
                </code>
                &nbsp;files are supported
              </p>
            </div>
          )}
        </div>
      ) : (
        <div className="flex flex-col">
          <div
            className="relative flex justify-center m-5 overflow-hidden"
            style={{ maxHeight: "500px" }}
          >
            {imageFiles?.map((img, index) => {
              return (
                <>
                  {img.type === "application/pdf" ||
                  img.type === "text/vcard" ? (
                    <p>{img.filename}</p>
                  ) : (
                    <div className="mr-2 w-40 h-40" key={index}>
                      <img src={img.file_data} />
                    </div>
                  )}
                </>
              );
            })}
          </div>

          <div
            className="flex flex-col items-center p-4 space-y-3"
            {...getRootProps()}
          >
            <input
              type="file"
              accept={accept}
              id="photo"
              className="hidden"
              ref={imageRef}
              {...getInputProps()}
            />
            {isDragActive ? (
              <p className={"flex justify-center"}>Drop the file here</p>
            ) : (
              <div className="flex flex-col items-center space-y-1">
                <div className="flex items-center justify-center w-full text-sm neeto-ui-text-gray-800">
                  <p>Drag and drop or&nbsp;</p>
                  <label
                    htmlFor="file-upload"
                    className="font-medium text-purple-500 bg-transparent rounded-md cursor-pointer"
                  >
                    <Button
                      style="link"
                      label="Browse"
                      className="underline"
                      onClick={handleClick}
                    />
                  </label>
                  <p>&nbsp;a file</p>
                  <label
                    htmlFor="file-upload"
                    className="font-medium text-purple-500 bg-transparent rounded-md cursor-pointer"
                  ></label>
                </div>
                <p className="neeto-ui-typography neeto-ui-text-body3 neeto-ui-text-gray-500">
                  Only&nbsp;
                  <code className="p-0 m-0 bg-white border-l-0 neeto-ui-typography neeto-ui-text-body3">
                    JPEG, JPG, PNG, GIF, PDF, VCF
                  </code>
                  &nbsp;files are supported
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatAttachment;

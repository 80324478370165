import React, { useState, useEffect } from "react";
import { Spinner } from "@bigbinary/neetoui";
import { globalRecords } from "apis/global_searches";
import { showToastrError } from "common";
import { getRandomNotFoundImage, artistModuleName } from "common/helper";
import Jobs from "./Jobs";
import Organisations from "./Organisations";
import Invoices from "./Invoices";
import Artists from "./Artists";
import Rcis from "./Rcis";
import Contacts from "./Contacts";
import EmptyState from "components/Common/EmptyState";

const GlobalSearch = ({ searchTerm, setSearchTerm }) => {
  const [jobList, setJobList] = useState([]);
  const [invoiceList, setInvoiceList] = useState([]);
  const [orgList, setOrgList] = useState([]);
  const [artistList, setArtistList] = useState([]);
  const [rciList, setRciList] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [jobListLoading, setJobListLoading] = useState(true);
  const [orgListLoading, setOrgListLoading] = useState(true);
  const [invoiceListLoading, setInvoiceListLoading] = useState(true);
  const [artistListLoading, setArtistListLoading] = useState(true);
  const [rciListLoading, setRciListLoading] = useState(true);
  const [contactListLoading, setContactListLoading] = useState(true);
  const [emptyImage, setEmptyImage] = useState();

  useEffect(() => {
    setEmptyImage(getRandomNotFoundImage());
  }, []);

  useEffect(() => {
    if (searchTerm?.length > 0) {
      loadJobList();
    } else {
      setSearchTerm();
    }
  }, [searchTerm]);

  const loadJobList = async () => {
    try {
      setJobListLoading(true);
      setOrgListLoading(true);
      setInvoiceListLoading(true);
      setArtistListLoading(true);
      setRciListLoading(true);
      setContactListLoading(true);
      const response = await globalRecords(searchTerm);

      setJobList(response.data.jobs);
      setInvoiceList(response.data.invoices);
      setOrgList(response.data.organisations);
      setArtistList(response.data.artists);
      setRciList(response.data.rcis);
      setContactList(response.data.contacts);

      setJobListLoading(false);
      setOrgListLoading(false);
      setInvoiceListLoading(false);
      setArtistListLoading(false);
      setRciListLoading(false);
      setContactListLoading(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  if (
    jobListLoading ||
    orgListLoading ||
    invoiceListLoading ||
    artistListLoading ||
    rciListLoading ||
    contactListLoading
  ) {
    return (
      <div
        className="flex items-center justify-center w-full"
        style={{ height: "calc(100vh - 176px)" }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div
      className="flex flex-col p-6 overflow-y-scroll"
      style={{ height: "calc(100vh - 176px)" }}
    >
      {jobList && <Jobs list={jobList} title="Jobs" />}
      {invoiceList && <Invoices list={invoiceList} title="Invoices" />}
      {orgList && <Organisations list={orgList} title="Organisations" />}
      {artistList && <Artists list={artistList} title={artistModuleName()} />}
      {rciList && <Rcis list={rciList} title="Rcis" />}
      {contactList && <Contacts list={contactList} title="Contacts" />}
      {!jobList &&
        !invoiceList &&
        !orgList &&
        !artistList &&
        !rciList &&
        !jobList &&
        !contactList && (
          <EmptyState
            image={emptyImage}
            title="No Data Found"
            description="We couldn’t find any data."
          />
        )}
    </div>
  );
};

export default GlobalSearch;

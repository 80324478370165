import React, { useState, useEffect } from "react";
import { Warning } from "@bigbinary/neeto-icons";
import { Spinner, Callout, Button } from "@bigbinary/neetoui";
import { Header, Container } from "@bigbinary/neetoui/layouts";
import { useParams } from "react-router-dom";
import AddLicensePage from "./AddLicensePage";
import { getJob } from "apis/jobs/booking_form";
import { getMasterLicense } from "apis/jobs/master_licenses";
import { getEstimateRevisions } from "apis/jobs/estimate_revisions";
import { showToastrError } from "common";
import { getRandomNotFoundImage } from "common/helper";
import Tabs from "../Tabs";
import {
  jobWithNameNumber,
  getRevisionId,
  getApprovedRevisionId,
} from "./../../common/helper";
import EmptyState from "components/Common/EmptyState";

const License = () => {
  const { id, revisionId } = useParams();
  const [jobDetail, setJobDetail] = useState("");
  const [jobDetailLoad, setJobDetailLoad] = useState(true);
  const [masterLicense, setMasterLicense] = useState();
  const [masterLicenseLoad, setMasterLicenseLoad] = useState(true);
  const [estimateRevisions, setEstimateRevisions] = useState([]);
  const [estimateRevisionsLoad, setEstimateRevisionsLoad] = useState(true);
  const [emptyImage, setEmptyImage] = useState();

  useEffect(() => {
    setEmptyImage(getRandomNotFoundImage());
  }, []);

  useEffect(() => {
    loadJobDetailResponse();
    loadEstimateRevisionsResponse();
    loadMasterLicense();
  }, [id]);

  const loadJobDetailResponse = async () => {
    try {
      const response = await getJob(id);
      setJobDetail(response.data.job);
      setJobDetailLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadEstimateRevisionsResponse = async () => {
    try {
      const response = await getEstimateRevisions(id);
      setEstimateRevisions(response.data.estimateRevisions);
      setEstimateRevisionsLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadMasterLicense = async () => {
    try {
      const response = await getMasterLicense(revisionId);
      setMasterLicense(response.data.masterLicense);
      setMasterLicenseLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const getRevisionName = () => {
    return estimateRevisions.find(revision => revision.id === revisionId).name;
  };

  if (masterLicenseLoad || jobDetailLoad || estimateRevisionsLoad) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <Container isHeaderFixed>
      <Header
        title={`${getRevisionName()} / ${
          masterLicense?.serialNumber || "No License"
        }`}
        breadcrumbs={[
          { text: "Jobs", link: "/jobs" },
          { text: jobWithNameNumber(jobDetail), link: `/jobs/${id}/overview` },
        ]}
        actionBlock={
          <>
            {masterLicense &&
              getApprovedRevisionId(estimateRevisions) === revisionId && (
                <Button
                  label="Send License"
                  to={`/mailer/jobs/${id}/revisions/${revisionId}/license`}
                />
              )}
          </>
        }
      />

      <Tabs
        jobId={jobDetail.id}
        revisionId={revisionId || getRevisionId(estimateRevisions)}
      />

      <div
        className="px-6 pt-8 pb-12 space-y-6 w-full"
        style={{ height: "calc(100vh - 120px)" }}
      >
        {jobDetail.note && (
          <Callout style="warning" icon={Warning}>
            <div
              dangerouslySetInnerHTML={{
                __html: jobDetail.note.split("\n").join("<br/>"),
              }}
            />
          </Callout>
        )}
        {masterLicense &&
        getApprovedRevisionId(estimateRevisions) === revisionId ? (
          <div className="mb-10">
            <AddLicensePage
              revisionId={revisionId}
              masterLicense={masterLicense}
              loadMasterLicense={loadMasterLicense}
            />
          </div>
        ) : (
          <EmptyState
            image={emptyImage}
            title="No Licenses Found"
            description="We couldn't find any license for this job."
          />
        )}
      </div>
    </Container>
  );
};

export default License;

import React, { useState, useRef, useEffect } from "react";
import { MenuHorizontal } from "@bigbinary/neeto-icons";
import {
  Spinner,
  Dropdown,
  Table,
  Callout,
  Pane,
  Typography,
  Button,
  Alert,
  Input,
  Select,
} from "@bigbinary/neetoui";
import { Header, Container } from "@bigbinary/neetoui/layouts";
import { isEmpty } from "ramda";
import { showToastrError } from "common";
import { getRandomNotFoundImage } from "common/helper";
import {
  getEstimateLineItems,
  createEstimateLineItem,
  updateEstimateLineItem,
  destroyEstimateLineItem,
} from "apis/settings/estimate_line_items";
import { getXeroAccountCodes } from "apis/settings/xero_account_codes";
import { getLineItemCategories } from "apis/settings/line_item_categories";
import {
  dropDownListGenerator,
  dropDownListGeneratorForXeroCodes,
} from "common/helper";
import EmptyState from "components/Common/EmptyState";

const EstimateLineItem = ({ breadcrumbs }) => {
  const inputRef = useRef();
  const [estimateLineItemList, setEstimateLineItemList] = useState([]);
  const [estimateLineItemListLoad, setEstimateLineItemListLoad] = useState(
    true
  );
  const [estimateLineItemId, setEstimateLineItemId] = useState("");
  const [modalState, setModalState] = useState(false);
  const [deleteModalState, setDeleteModalState] = useState(false);
  const [name, setName] = useState("");
  const [xeroAccountCode, setXeroAccountCode] = useState("");
  const [lineItemCategoryId, setLineItemCategoryId] = useState("");
  const [lineItemCategoryList, setLineItemCategoryList] = useState([]);
  const [lineItemCategoryListLoad, setLineItemCategoryListLoad] = useState(
    true
  );
  const [xeroAccountCodes, setXeroAccountCodes] = useState([]);
  const [xeroAccountCodesLoad, setXeroAccountCodesLoad] = useState(true);
  const [xeroTokenExpired, setXeroTokenExpired] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const { Menu, MenuItem } = Dropdown;
  const [emptyImage, setEmptyImage] = useState();

  useEffect(() => {
    setEmptyImage(getRandomNotFoundImage());
  }, [])

  useEffect(() => {
    if (modalState) {
      getXeroAccountCodeList();
      loadLineItemCategoryList();
    }
  }, [modalState]);

  useEffect(() => {
    loadEstimateLineItemData();
  }, []);

  const loadEstimateLineItemData = async () => {
    loadEstimateLineItemList();
    loadLineItemCategoryList();
  };

  const loadEstimateLineItemList = async () => {
    try {
      const estimateLineItemResponse = await getEstimateLineItems();
      setXeroTokenExpired(estimateLineItemResponse.data.xeroTokenExpired);
      setEstimateLineItemList(
        estimateLineItemResponse.data.estimateLineItems || []
      );
      setEstimateLineItemListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const resetAllStates = () => {
    loadEstimateLineItemList();
    setEstimateLineItemId("");
    setModalState(false);
    setDeleteModalState(false);
    setName("");
    setLineItemCategoryId("");
    setXeroAccountCode("");
  };

  const getXeroAccountCodeList = async () => {
    try {
      setXeroAccountCodesLoad(true);
      const { data } = await getXeroAccountCodes();
      setXeroAccountCodes(data.xeroAccountCodes || []);
      setXeroAccountCodesLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const createEstimateLineItemEntry = async () => {
    try {
      await createEstimateLineItem({
        estimate_line_item: {
          name: name,
          lineItemCategoryId: lineItemCategoryId,
          xeroAccountCode: xeroAccountCode,
        },
      });
      resetAllStates();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const updateEstimateLineItemEntry = async () => {
    try {
      await updateEstimateLineItem(estimateLineItemId, {
        estimate_line_item: {
          name: name,
          lineItemCategoryId: lineItemCategoryId,
          xeroAccountCode: xeroAccountCode,
        },
      });
      resetAllStates();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const destroyEstimateLineItemEntry = async () => {
    try {
      await destroyEstimateLineItem(estimateLineItemId);
      resetAllStates();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadLineItemCategoryList = async () => {
    try {
      const lineItemCategoryResponse = await getLineItemCategories();
      setLineItemCategoryList(
        dropDownListGenerator(
          lineItemCategoryResponse.data.lineItemCategories || []
        )
      );
      setLineItemCategoryListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const COLUMN_DATA = [
    {
      title: "Category",
      dataIndex: "lineItemCategoryName",
      key: "lineItemCategoryName",
      width: "45%",
      render: (_, rowData) => (
        <>{rowData.lineItemCategoryName ? rowData.lineItemCategoryName : "-"}</>
      ),
      fixed: "left",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "45%",
      render: (_, rowData) => <>{rowData.name ? rowData.name : "-"}</>,
      fixed: "left",
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: "10%",
      render: (_, rowData) => (
        <Dropdown
          autoWidth
          closeOnSelect
          buttonStyle="text"
          strategy="fixed"
          icon={MenuHorizontal}
        >
          <Menu>
            <MenuItem.Button
              onClick={() => {
                setEstimateLineItemId(rowData.id);
                setName(rowData.name);
                setLineItemCategoryId(rowData.lineItemCategoryId);
                setXeroAccountCode(rowData.xeroAccountCode);
                setModalState(true);
              }}
            >
              Edit
            </MenuItem.Button>
            <MenuItem.Button
              style="danger"
              onClick={() => {
                setEstimateLineItemId(rowData.id);
                setDeleteModalState(true);
              }}
            >
              Delete
            </MenuItem.Button>
          </Menu>
        </Dropdown>
      ),
    },
  ];

  if (estimateLineItemListLoad || lineItemCategoryListLoad) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <Container>
      <Header
        title="Estimate Line Items"
        breadcrumbs={breadcrumbs}
        actionBlock={
          <Button
            label="Add Estimate Line Item"
            onClick={() => {
              setName("");
              setLineItemCategoryId("");
              setXeroAccountCode("");
              setEstimateLineItemId("");
              setModalState(true);
            }}
          />
        }
      />

      <div className="w-full" style={{ height: "calc(100vh - 162px)" }}>
        {!isEmpty(estimateLineItemList) ? (
          <>
            {xeroTokenExpired && (
              <Callout style="danger" className="mb-3">
                Xero Logged out!
              </Callout>
            )}
            <Table
              fixedHeight
              columnData={COLUMN_DATA}
              rowData={estimateLineItemList}
              currentPageNumber={pageIndex}
              defaultPageSize={pageSize}
              handlePageChange={(page, pageSize) => {
                setPageIndex(page);
                setPageSize(pageSize);
              }}
            />
          </>
        ) : (
          <EmptyState
            image={emptyImage}
            title="No Estimate Line Items Found"
            description="We couldn’t find any estimate line items. Try creating one."
            primaryButtonProps={{
              label: "Add Estimate Line Item",
              onClick: () => {
                setName("");
                setLineItemCategoryId("");
                setXeroAccountCode("");
                setEstimateLineItemId("");
                setModalState(true);
              },
            }}
          />
        )}
      </div>

      <Pane
        isOpen={modalState}
        onClose={() => setModalState(false)}
        initialFocusRef={inputRef}
      >
        <Pane.Header>
          <Typography style="h2" weight="semibold">
            {estimateLineItemId
              ? "Edit Estimate Line Item"
              : "Add Estimate Line Item"}
          </Typography>
        </Pane.Header>

        <Pane.Body>
          {!lineItemCategoryListLoad ? (
            <div className="flex flex-col w-full space-y-6">
              <Select
                label="Category Name"
                name="lineItemCategoryId"
                id="lineItemCategoryId"
                innerRef={inputRef}
                options={lineItemCategoryList}
                value={lineItemCategoryList.find(
                  category => category.value === lineItemCategoryId
                )}
                required={true}
                onChange={opt => {
                  setLineItemCategoryId(opt.value);
                }}
              />

              <Input
                label="Name"
                required={true}
                name="name"
                value={name}
                onChange={e => setName(e.target.value)}
              />

              <Select
                label="Xero Account Code"
                name="xeroAccountCode"
                isLoading={xeroAccountCodesLoad}
                isDisabled={xeroAccountCodesLoad || xeroTokenExpired}
                id="xeroAccountCode"
                options={dropDownListGeneratorForXeroCodes(xeroAccountCodes)}
                value={dropDownListGeneratorForXeroCodes(xeroAccountCodes).find(
                  accountCode => accountCode.code && accountCode.code === xeroAccountCode
                )}
                onChange={opt => {
                  setXeroAccountCode(opt.code);
                }}
              />
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center w-full">
              <Spinner />
            </div>
          )}
        </Pane.Body>
        <Pane.Footer className="flex gap-x-2">
          <Button
            label="Save changes"
            onClick={() => {
              if (estimateLineItemId) {
                updateEstimateLineItemEntry();
              } else {
                createEstimateLineItemEntry();
              }
            }}
          />
          <Button
            label="Cancel"
            style="text"
            onClick={() => setModalState(false)}
          />
        </Pane.Footer>
      </Pane>

      <Alert
        isOpen={deleteModalState}
        title="Delete Estimate Line Item"
        message="Are you sure you want to delete the Estimate Line Item?"
        onClose={() => setDeleteModalState(false)}
        onSubmit={() => destroyEstimateLineItemEntry()}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, delete"
      />
    </Container>
  );
};

export default EstimateLineItem;

import React from "react";
import * as dayjs from "dayjs";

const Overview = ({ jobDetail, organisationAddress, invoice, emailBackground }) => {
  const companyDetail = jobDetail?.companyDetail;

  const setResourceForDetails = name => {
    switch (name) {
      case "[contact_first_name]":
        return jobDetail?.contactFirstName;
      case "[staff_first_name]":
        return jobDetail.staffFirstName;
      case "[staff_full_name]":
        return jobDetail.staffFullName;
      case "[company_name]":
        return jobDetail.companyDetail.name;
      case "[invoice_serial]":
        return invoice.invoiceSerial;
      case "[invoice_type]":
        return invoice.invoiceType;
      case "[due_date]":
        return dayjs(invoice?.dueDate).format("DD MMMM YYYY");
      case "[staff_email]":
        return jobDetail.staffEmail;
      case "[staff_phone]":
        return jobDetail.staffPhone;
      case "[job_name]":
        return jobDetail.name;
      case "[job_serial_number]":
        return jobDetail.serialNumber;
    }
  };

  const headerDetails = () => {
    const detailComponents = emailBackground.headerDetails?.split("&lt;&gt;");
    let details = "";

    detailComponents?.map(component => {
      if (component.includes("[")) {
        details += setResourceForDetails(component);
      } else {
        details += component;
      }
    });

    return details;
  };

  return (
    <div className="flex flex-col items-start justify-between w-full py-12 lg:flex-row">
      <div
        className="flex flex-col items-start justify-start w-full h-full lg:w-1/3"
        style={{ fontFamily: "Modern Gothic" }}
      >
        <div className="flex flex-col items-start justify-start">
          <h2 className="mb-8 text-xl font-medium lg:mb-0">
            Tax Invoice - {invoice?.invoiceType}
          </h2>
        </div>
      </div>
      <div className="flex justify-end w-full h-full lg:w-2/3">
        <div className="relative flex flex-col items-end justify-start w-full h-full">
          <div className="grid w-full grid-cols-2 gap-2 lg:gap-6">
            <div
              className="flex flex-col justify-start text-sm font-light divide-y divide-black"
              style={{ fontFamily: "Modern Gothic Mono" }}
            >
              <span className="mb-2">To</span>
              <div className="flex flex-col pt-4">
                <span>
                  {organisationAddress?.name}
                  <br />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: organisationAddress?.streetAddress
                        ?.split("\n")
                        .join("<br />"),
                    }}
                  ></p>
                  <p>
                    {[
                      organisationAddress?.suburb,
                      organisationAddress?.state,
                      organisationAddress?.postcode,
                    ]
                      .filter(el => el !== null)
                      .join(", ")}
                  </p>
                  {organisationAddress?.country}
                </span>
              </div>
            </div>
            <div
              className="flex flex-col justify-start text-sm font-light divide-y divide-black"
              style={{ fontFamily: "Modern Gothic Mono" }}
            >
              <span className="mb-2">From</span>
              <div className="flex flex-col pt-4">
                <p>
                  {companyDetail?.name}
                  <br />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: companyDetail?.streetAddress
                        ?.split("\n")
                        .join("<br />"),
                    }}
                  ></p>
                  <p>
                    {[
                      companyDetail?.suburb,
                      companyDetail?.state,
                      companyDetail?.postcode,
                    ]
                      .filter(el => el !== null)
                      .join(", ")}
                  </p>
                  {companyDetail?.fullLocationName}
                  <br />
                  {companyDetail?.addressCode
                    ? `${companyDetail?.addressCode}: ${companyDetail?.addressId}`
                    : ""}
                </p>
              </div>
            </div>
          </div>
          <div className="w-full mt-24" style={{ fontFamily: "Modern Gothic" }}>
            <p dangerouslySetInnerHTML={{ __html: headerDetails()?.split("\n")?.join("<br />")}}>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;

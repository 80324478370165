import React, { useState, useEffect } from "react";
import {
  Label,
  Button,
  Input,
  Textarea,
  Typography,
  DatePicker,
} from "@bigbinary/neetoui";
import Pluralize from "pluralize";
import produce from "immer";
import { updateLicense } from "apis/jobs/licenses";
import * as dayjs from "dayjs";
import { showToastrError } from "common";

const AddLicensePage = ({ revisionId, masterLicense, loadMasterLicense }) => {
  const [licenseItems, setLicenseItems] = useState([]);
  const [buttonLoad, setButtonLoad] = useState(false);

  useEffect(() => {
    if (masterLicense) {
      setLicenseItems(masterLicense.license_items);
    }
  }, [masterLicense]);

  const updateLicenseEntry = async () => {
    try {
      setButtonLoad(true);
      let payload = {
        license: {
          license_items_attributes: licenseItems.map(item => {
            return {
              ...item,
              startDate: dayjs(item.startDate).format("YYYY-MM-DD"),
              endDate: dayjs(item.endDate).format("YYYY-MM-DD"),
            };
          }),
        },
      };
      await updateLicense(revisionId, masterLicense.id, payload);
      loadMasterLicense();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const updateIssueDateEntry = async issueDate => {
    try {
      const payload = { license: { issueDate: issueDate } };
      await updateLicense(revisionId, masterLicense.id, payload);
      loadMasterLicense();
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  return (
    <div className="flex flex-col h-full">
      <Typography style="h4" weight="semibold" className="mb-4">
        {masterLicense.name}
      </Typography>
      <div className="w-full p-6 bg-gray-100 rounded-md">
        <div className="flex flex-col space-y-4">
          <div className="flex space-x-4">
            <Input
              className="w-3/4"
              label="Usage Title"
              disabled={true}
              value={masterLicense.title}
            />

            <DatePicker
              label="Issue Date"
              name="issueDate"
              dateFormat="DD/MM/YYYY"
              value={dayjs(masterLicense.issueDate)}
              allowClear={false}
              onChange={date => updateIssueDateEntry(date.format("YYYY-MM-DD"))}
            />
          </div>

          <Textarea
            label="Usage Notes"
            disabled={true}
            value={masterLicense.terms}
            rows={2}
          />
        </div>
        {licenseItems?.map((item, index) => {
          return (
            <div className="relative mt-4" key={index}>
              <div className="p-4 bg-white rounded-md">
                <Label className="font-semibold">
                  {item.name.replace("Usage", "License")}
                </Label>
                <div className="grid grid-cols-6 mt-4 gap-x-4 gap-y-6">
                  <Input
                    label="Category"
                    value={item.category}
                    disabled={true}
                  />

                  <Input
                    label="Detail"
                    className="col-span-5"
                    value={item.detail}
                    disabled={true}
                  />

                  <Input
                    label="Quantity"
                    value={item.quantity}
                    disabled={true}
                  />

                  <Input
                    label="Territory"
                    value={item.territory}
                    disabled={true}
                  />

                  <DatePicker
                    value={
                      item.startDate ? dayjs(item.startDate).toDate() : null
                    }
                    label="Start Date"
                    shortcuts={true}
                    onChange={newDate => {
                      setButtonLoad(false);
                      setLicenseItems(
                        produce(draft => {
                          draft[index] = {
                            ...draft[index],
                            startDate: newDate?.toLocaleString("en-US") || null,
                            endDate: !isNaN(Number(item.period))
                              ? dayjs(newDate)
                                  .add(Number(item.period), "month")
                                  .toLocaleString("en-US")
                              : null,
                          };
                        })
                      );
                    }}
                  />

                  <Input
                    label="Period"
                    value={
                      !isNaN(Number(item.period))
                        ? item.period + Pluralize(" month", Number(item.period))
                        : item.period
                    }
                    disabled={true}
                  />

                  <Input
                    disabled={true}
                    value={
                      item.endDate !== null
                        ? dayjs(item.endDate).format("DD/MM/YYYY")
                        : ""
                    }
                    label="End Date"
                  />

                  <Input
                    label="Exclusivity Period"
                    value={
                      !isNaN(Number(item.exclusivityPeriod))
                        ? item.exclusivityPeriod +
                          Pluralize(" month", Number(item.exclusivityPeriod))
                        : item.exclusivityPeriod
                    }
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          );
        })}

        <div className="flex justify-end mt-8">
          <Button
            label={buttonLoad ? "Saved" : "Save"}
            onClick={() => updateLicenseEntry(masterLicense.id)}
          />
        </div>
      </div>
    </div>
  );
};

export default AddLicensePage;

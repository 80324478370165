import axios from "axios";

const getRevisionLineItemCategoriesUrl = (revisionId, id) => {
  if (id) {
    return `/api/v1/job_module/estimate_revisions/${revisionId}/revision_line_item_categories/${id}`;
  }

  return `/api/v1/job_module/estimate_revisions/${revisionId}/revision_line_item_categories`;
};

export const getRevisionLineItemCategories = revisionId => {
  return axios.get(getRevisionLineItemCategoriesUrl(revisionId));
};

export const createRevisionLineItemCategory = (revisionId, payload) => {
  return axios.post(getRevisionLineItemCategoriesUrl(revisionId), payload);
};

export const destroyRevisionLineItemCategory = (revisionId, id) => {
  return axios.delete(getRevisionLineItemCategoriesUrl(revisionId, id));
};

import React, { useState } from "react";
import ChatAttachment from "components/Common/ChatAttachment";
import { Modal, Typography } from "@bigbinary/neetoui";

const AddAttachment = ({
  setAttachmentRecord,
  addAttachmentModal,
  setAddAttachmentModal,
}) => {
  const [images, setImages] = useState([]);

  return (
    <>
      <Modal
        isOpen={addAttachmentModal}
        onClose={() => {
          setAddAttachmentModal(false);
          setAttachmentRecord(images);
        }}
        className="w-screen h-screen rounded-none"
      >
        <Modal.Header>
          <Typography style="h2" weight="semibold">
            Add your attachments
          </Typography>
        </Modal.Header>
        <Modal.Body>
          <div className="flex flex-col space-y-2 full-screen-ql-editor">
            <ChatAttachment setImages={setImages} />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddAttachment;

import React from "react";
import {
  Select,
  Input,
  Label,
  Switch,
  Typography,
  Spinner,
} from "@bigbinary/neetoui";
import SingleImageUploader from "components/Common/SingleImageUploader";
import { Editor } from "@tinymce/tinymce-react";
import {
  subjectResourceList,
  paperworkResourceList,
  paperworkResourceListWithArtist,
  subjectResourceListForWelcomeEmail,
} from "common/helper";
import { useUserState } from "contexts/user";
import {
  DEFAULT_EDITOR_TOOLBAR,
  DEFAULT_EDITOR_FONT_SIZES,
  DEFAULT_EDITOR_PLUGINS,
  DEFAULT_EDITOR_HEIGHT,
} from "common/constants";
import {
  PAGE_LIST,
  pdfFilenameResourceListForBF,
  pdfFilenameResourceListForEstimate,
  pdfFilenameResourceListForLicense,
} from "../constants";
import SubjectComponent from "./SubjectComponent";

const EntryForm = ({ formik, emailBackgroundDetailLoad }) => {
  const { env_variables } = useUserState();

  return (
    <div className="flex flex-col w-full space-y-6 mb-10">
      {emailBackgroundDetailLoad ? (
        <div className="flex justify-center items-center h-screen">
          <Spinner />
        </div>
      ) : (
        <>
          <Select
            label="Page Name"
            name="pageName"
            id="pageName"
            options={PAGE_LIST}
            value={PAGE_LIST.find(
              page => page.value === formik.values.pageName
            )}
            required={true}
            error={
              Boolean(formik.touched.pageName && formik.errors.pageName) &&
              formik.errors.pageName
            }
            onChange={async opt => {
              await formik.setFieldValue("pageName", opt.value);
              await formik.setFieldTouched("pageName", true);
            }}
          />

          <Input
            label="Artist Name"
            name="artistName"
            onChange={formik.handleChange}
            value={formik.values.artistName}
            error={
              Boolean(formik.touched.artistName && formik.errors.artistName) &&
              formik.errors.artistName
            }
            {...formik.getFieldProps("artistName")}
          />

          <div className="flex flex-col space-y-2">
            <Label>Fallback Header Image</Label>
            <SingleImageUploader
              name="Header"
              type="header"
              formValue={formik.values}
              setFormValue={formik.setFieldValue}
            />
          </div>

          <div className="flex flex-col space-y-2">
            <Label>Subject</Label>
            <SubjectComponent
              formik={formik}
              getOptions={() =>
                formik.values.pageName === "Welcome Email"
                  ? subjectResourceListForWelcomeEmail()
                  : subjectResourceList()
              }
              attr="emailSubject"
            />
          </div>

          <Input
            label="Artist URL"
            name="artistUrl"
            prefix="https://"
            onChange={formik.handleChange}
            value={formik.values.artistUrl}
            error={
              Boolean(formik.touched.artistUrl && formik.errors.artistUrl) &&
              formik.errors.artistUrl
            }
            className="mb-3"
            {...formik.getFieldProps("artistUrl")}
          />

          {formik.values.pageName === "Welcome Email" && (
            <>
              <Input
                label="Video URL"
                name="welcomeEmailVideoUrl"
                prefix="https://"
                required={true}
                onChange={formik.handleChange}
                value={formik.values.welcomeEmailVideoUrl}
                error={
                  Boolean(
                    formik.touched.welcomeEmailVideoUrl &&
                      formik.errors.welcomeEmailVideoUrl
                  ) && formik.errors.welcomeEmailVideoUrl
                }
                className="mb-3"
                {...formik.getFieldProps("welcomeEmailVideoUrl")}
              />

              <div className="flex flex-col space-y-2">
                <Label>Welcome Email Header</Label>
                <SubjectComponent
                  formik={formik}
                  getOptions={() => paperworkResourceList()}
                  attr="welcomeEmailHeaderDetails"
                />
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Welcome Email Body</Label>

                <Editor
                  apiKey={env_variables.tinymce_api_key}
                  init={{
                    height: DEFAULT_EDITOR_HEIGHT,
                    plugins: DEFAULT_EDITOR_PLUGINS,
                    toolbar: DEFAULT_EDITOR_TOOLBAR,
                    font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
                  }}
                  value={formik.values.welcomeEmailBodyDetails}
                  onEditorChange={content =>
                    formik.setFieldValue("welcomeEmailBodyDetails", content)
                  }
                />
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Welcome Email Footer</Label>

                <Editor
                  apiKey={env_variables.tinymce_api_key}
                  init={{
                    height: DEFAULT_EDITOR_HEIGHT,
                    plugins: DEFAULT_EDITOR_PLUGINS,
                    toolbar: DEFAULT_EDITOR_TOOLBAR,
                    font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
                  }}
                  value={formik.values.welcomeEmailFooterDetails}
                  onEditorChange={content =>
                    formik.setFieldValue("welcomeEmailFooterDetails", content)
                  }
                />
              </div>
            </>
          )}

          {formik.values.pageName === "Estimate" && (
            <>
              <div className="flex flex-col space-y-2">
                <Label>PDF Filename</Label>
                <SubjectComponent
                  formik={formik}
                  getOptions={() => pdfFilenameResourceListForEstimate()}
                  attr="pdfFilename"
                />
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Email Text</Label>
                <SubjectComponent
                  formik={formik}
                  getOptions={() => paperworkResourceList()}
                  attr="estimateEmailDetails"
                />
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Header Details</Label>

                <Editor
                  apiKey={env_variables.tinymce_api_key}
                  init={{
                    height: DEFAULT_EDITOR_HEIGHT,
                    plugins: DEFAULT_EDITOR_PLUGINS,
                    toolbar: DEFAULT_EDITOR_TOOLBAR,
                    font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
                  }}
                  value={formik.values.headerDetails}
                  onEditorChange={content =>
                    formik.setFieldValue("headerDetails", content)
                  }
                />

                <Typography style="body3">
                  Please use the tag to get dynamic values with respect to job.{" "}
                  <span>{`<>[staff_first_name]<>, <>[company_name]<>, <>[job_name]<>, <>[job_serial_number]<>,
                  <>[staff_first_name]<>, <>[contact_first_name]<>, <>[staff_phone]<>, <>[staff_email]<>`}</span>
                </Typography>
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Header In PDF</Label>

                <Editor
                  apiKey={env_variables.tinymce_api_key}
                  init={{
                    height: DEFAULT_EDITOR_HEIGHT,
                    plugins: DEFAULT_EDITOR_PLUGINS,
                    toolbar: DEFAULT_EDITOR_TOOLBAR,
                    font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
                    content_style: "p { margin: 0; line-height: 1 }",
                  }}
                  value={formik.values.headerInPdf}
                  onEditorChange={content =>
                    formik.setFieldValue("headerInPdf", content)
                  }
                />

                <Typography style="body3">
                  Please use the tag to get dynamic values with respect to job.{" "}
                  <span>{`<>[staff_first_name]<>, <>[company_name]<>, <>[job_name]<>, <>[job_serial_number]<>,
                  <>[staff_first_name]<>, <>[contact_first_name]<>, <>[staff_phone]<>, <>[staff_email]<>`}</span>
                </Typography>
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Footer Details</Label>

                <Editor
                  apiKey={env_variables.tinymce_api_key}
                  init={{
                    height: DEFAULT_EDITOR_HEIGHT,
                    plugins: DEFAULT_EDITOR_PLUGINS,
                    toolbar: DEFAULT_EDITOR_TOOLBAR,
                    font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
                  }}
                  value={formik.values.footerDetails}
                  onEditorChange={content =>
                    formik.setFieldValue("footerDetails", content)
                  }
                />

                <Typography style="body3">
                  Please use the tag to get dynamic values with respect to job.{" "}
                  <span>{`<>[staff_full_name]<>, <>[staff_phone]<>,
                  <>[staff_designation]<>`}</span>
                </Typography>
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Footer In PDF</Label>

                <Editor
                  apiKey={env_variables.tinymce_api_key}
                  init={{
                    height: DEFAULT_EDITOR_HEIGHT,
                    plugins: DEFAULT_EDITOR_PLUGINS,
                    toolbar: DEFAULT_EDITOR_TOOLBAR,
                    font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
                  }}
                  value={formik.values.footerInPdf}
                  onEditorChange={content =>
                    formik.setFieldValue("footerInPdf", content)
                  }
                />

                <Typography style="body3">
                  Please use the tag to get dynamic values with respect to job.{" "}
                  <span>{`<>[staff_full_name]<>, <>[staff_phone]<>,
                  <>[staff_designation]<>`}</span>
                </Typography>
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Approved Fees Details</Label>

                <Editor
                  apiKey={env_variables.tinymce_api_key}
                  init={{
                    height: DEFAULT_EDITOR_HEIGHT,
                    plugins: DEFAULT_EDITOR_PLUGINS,
                    toolbar: DEFAULT_EDITOR_TOOLBAR,
                    font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
                  }}
                  value={formik.values.approvedFeesDetails}
                  onEditorChange={content =>
                    formik.setFieldValue("approvedFeesDetails", content)
                  }
                />
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Usage Fees Details</Label>

                <Editor
                  apiKey={env_variables.tinymce_api_key}
                  init={{
                    height: DEFAULT_EDITOR_HEIGHT,
                    plugins: DEFAULT_EDITOR_PLUGINS,
                    toolbar: DEFAULT_EDITOR_TOOLBAR,
                    font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
                  }}
                  value={formik.values.usageDetails}
                  onEditorChange={content =>
                    formik.setFieldValue("usageDetails", content)
                  }
                />
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Milestone Details</Label>

                <Editor
                  apiKey={env_variables.tinymce_api_key}
                  init={{
                    height: DEFAULT_EDITOR_HEIGHT,
                    plugins: DEFAULT_EDITOR_PLUGINS,
                    toolbar: DEFAULT_EDITOR_TOOLBAR,
                    font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
                  }}
                  value={formik.values.phasesDetails}
                  onEditorChange={content =>
                    formik.setFieldValue("phasesDetails", content)
                  }
                />
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Sound Good Details</Label>

                <Editor
                  apiKey={env_variables.tinymce_api_key}
                  init={{
                    height: DEFAULT_EDITOR_HEIGHT,
                    plugins: DEFAULT_EDITOR_PLUGINS,
                    toolbar: DEFAULT_EDITOR_TOOLBAR,
                    font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
                  }}
                  value={formik.values.soundGoodDetails}
                  onEditorChange={content =>
                    formik.setFieldValue("soundGoodDetails", content)
                  }
                />
              </div>
            </>
          )}

          {formik.values.pageName === "Booking Form" && (
            <>
              <div className="flex flex-col space-y-2">
                <Label>PDF Filename</Label>
                <SubjectComponent
                  formik={formik}
                  getOptions={() => pdfFilenameResourceListForBF()}
                  attr="pdfFilename"
                />
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Email Text</Label>
                <SubjectComponent
                  formik={formik}
                  getOptions={() => paperworkResourceListWithArtist()}
                  attr="bookingFormEmailDetails"
                />
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Approved Fees Details</Label>

                <Editor
                  apiKey={env_variables.tinymce_api_key}
                  init={{
                    height: DEFAULT_EDITOR_HEIGHT,
                    plugins: DEFAULT_EDITOR_PLUGINS,
                    toolbar: DEFAULT_EDITOR_TOOLBAR,
                    font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
                  }}
                  value={formik.values.approvedFeesDetails}
                  onEditorChange={content =>
                    formik.setFieldValue("approvedFeesDetails", content)
                  }
                />
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Usage Fees Details</Label>

                <Editor
                  apiKey={env_variables.tinymce_api_key}
                  init={{
                    height: DEFAULT_EDITOR_HEIGHT,
                    plugins: DEFAULT_EDITOR_PLUGINS,
                    toolbar: DEFAULT_EDITOR_TOOLBAR,
                    font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
                  }}
                  value={formik.values.usageDetails}
                  onEditorChange={content =>
                    formik.setFieldValue("usageDetails", content)
                  }
                />
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Milestone Details</Label>

                <Editor
                  apiKey={env_variables.tinymce_api_key}
                  init={{
                    height: DEFAULT_EDITOR_HEIGHT,
                    plugins: DEFAULT_EDITOR_PLUGINS,
                    toolbar: DEFAULT_EDITOR_TOOLBAR,
                    font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
                  }}
                  value={formik.values.phasesDetails}
                  onEditorChange={content =>
                    formik.setFieldValue("phasesDetails", content)
                  }
                />
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Total Cost Details</Label>

                <Editor
                  apiKey={env_variables.tinymce_api_key}
                  init={{
                    height: DEFAULT_EDITOR_HEIGHT,
                    plugins: DEFAULT_EDITOR_PLUGINS,
                    toolbar: DEFAULT_EDITOR_TOOLBAR,
                    font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
                  }}
                  value={formik.values.totalCostDetails}
                  onEditorChange={content =>
                    formik.setFieldValue("totalCostDetails", content)
                  }
                />
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Header Details</Label>

                <Editor
                  apiKey={env_variables.tinymce_api_key}
                  init={{
                    height: DEFAULT_EDITOR_HEIGHT,
                    plugins: DEFAULT_EDITOR_PLUGINS,
                    toolbar: DEFAULT_EDITOR_TOOLBAR,
                    font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
                  }}
                  value={formik.values.headerDetails}
                  onEditorChange={content =>
                    formik.setFieldValue("headerDetails", content)
                  }
                />

                <Typography style="body3">
                  Please use the tag to get dynamic values with respect to job.{" "}
                  <span>{`<>[staff_first_name]<>, <>[company_name]<>, <>[job_name]<>, <>[job_serial_number]<>,
                  <>[staff_first_name]<>, <>[artist_first_name]<>, <>[artist_salutation]<>, <>[artist_company_name]<>,
                  <>[staff_phone]<>, <>[staff_email]<>`}</span>
                </Typography>
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Additional Header Details</Label>

                <Editor
                  apiKey={env_variables.tinymce_api_key}
                  init={{
                    height: DEFAULT_EDITOR_HEIGHT,
                    plugins: DEFAULT_EDITOR_PLUGINS,
                    toolbar: DEFAULT_EDITOR_TOOLBAR,
                    font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
                    fontsize_formats: "8px 10px 12px 14px 18px 24px 36px",
                  }}
                  value={formik.values.additionalHeaderDetails}
                  onEditorChange={content =>
                    formik.setFieldValue("additionalHeaderDetails", content)
                  }
                />
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Additional Header Details In Pdf</Label>

                <Editor
                  apiKey={env_variables.tinymce_api_key}
                  init={{
                    height: DEFAULT_EDITOR_HEIGHT,
                    plugins: DEFAULT_EDITOR_PLUGINS,
                    toolbar: DEFAULT_EDITOR_TOOLBAR,
                    font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
                    fontsize_formats: "8px 10px 12px 14px 18px 24px 36px",
                  }}
                  value={formik.values.additionalHeaderDetailsInPdf}
                  onEditorChange={content =>
                    formik.setFieldValue(
                      "additionalHeaderDetailsInPdf",
                      content
                    )
                  }
                />
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Header In PDF</Label>

                <Editor
                  apiKey={env_variables.tinymce_api_key}
                  init={{
                    height: DEFAULT_EDITOR_HEIGHT,
                    plugins: DEFAULT_EDITOR_PLUGINS,
                    toolbar: DEFAULT_EDITOR_TOOLBAR,
                    font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
                    content_style: "p { margin: 0; line-height: 1 }",
                  }}
                  value={formik.values.headerInPdf}
                  onEditorChange={content =>
                    formik.setFieldValue("headerInPdf", content)
                  }
                />

                <Typography style="body3">
                  Please use the tag to get dynamic values with respect to job.{" "}
                  <span>{`<>[staff_first_name]<>, <>[company_name]<>, <>[job_name]<>, <>[job_serial_number]<>,
                  <>[staff_first_name]<>, <>[artist_first_name]<>, <>[artist_salutation]<>, <>[artist_company_name]<>,
                  <>[staff_phone]<>, <>[staff_email]<>`}</span>
                </Typography>
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Footer Details</Label>

                <Editor
                  apiKey={env_variables.tinymce_api_key}
                  init={{
                    height: DEFAULT_EDITOR_HEIGHT,
                    plugins: DEFAULT_EDITOR_PLUGINS,
                    toolbar: DEFAULT_EDITOR_TOOLBAR,
                    font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
                  }}
                  value={formik.values.footerDetails}
                  onEditorChange={content =>
                    formik.setFieldValue("footerDetails", content)
                  }
                />

                <Typography style="body3">
                  Please use the tag to get dynamic values with respect to job.{" "}
                  <span>{`<>[staff_full_name]<>, <>[staff_phone]<>,
                  <>[staff_designation]<>, <>[staff_first_name]<>`}</span>
                </Typography>
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Footer In PDF</Label>

                <Editor
                  apiKey={env_variables.tinymce_api_key}
                  init={{
                    height: DEFAULT_EDITOR_HEIGHT,
                    plugins: DEFAULT_EDITOR_PLUGINS,
                    toolbar: DEFAULT_EDITOR_TOOLBAR,
                    font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
                  }}
                  value={formik.values.footerInPdf}
                  onEditorChange={content =>
                    formik.setFieldValue("footerInPdf", content)
                  }
                />

                <Typography style="body3">
                  Please use the tag to get dynamic values with respect to job.{" "}
                  <span>{`<>[staff_full_name]<>, <>[staff_phone]<>,
                  <>[staff_designation]<>, <>[staff_first_name]<>`}</span>
                </Typography>
              </div>
            </>
          )}

          {formik.values.pageName === "Extra" && (
            <>
              <div className="flex flex-col space-y-2">
                <Label>PDF Filename</Label>
                <SubjectComponent
                  formik={formik}
                  getOptions={() => pdfFilenameResourceListForEstimate()}
                  attr="pdfFilename"
                />
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Email Text</Label>
                <SubjectComponent
                  formik={formik}
                  getOptions={() => paperworkResourceList()}
                  attr="overageEmailDetails"
                />
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Header Details</Label>

                <Editor
                  apiKey={env_variables.tinymce_api_key}
                  init={{
                    height: DEFAULT_EDITOR_HEIGHT,
                    plugins: DEFAULT_EDITOR_PLUGINS,
                    toolbar: DEFAULT_EDITOR_TOOLBAR,
                    font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
                  }}
                  value={formik.values.headerDetails}
                  onEditorChange={content =>
                    formik.setFieldValue("headerDetails", content)
                  }
                />

                <Typography style="body3">
                  Please use the tag to get dynamic values with respect to job.{" "}
                  <span>{`<>[staff_first_name]<>, <>[company_name]<>, <>[job_name]<>, <>[job_serial_number]<>,
                  <>[staff_first_name]<>, <>[contact_first_name]<>, <>[staff_phone]<>,
                  <>[staff_email]<>`}</span>
                </Typography>
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Header In PDF</Label>

                <Editor
                  apiKey={env_variables.tinymce_api_key}
                  init={{
                    height: DEFAULT_EDITOR_HEIGHT,
                    plugins: DEFAULT_EDITOR_PLUGINS,
                    toolbar: DEFAULT_EDITOR_TOOLBAR,
                    font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
                    content_style: "p { margin: 0; line-height: 1 }",
                  }}
                  value={formik.values.headerInPdf}
                  onEditorChange={content =>
                    formik.setFieldValue("headerInPdf", content)
                  }
                />

                <Typography style="body3">
                  Please use the tag to get dynamic values with respect to job.{" "}
                  <span>{`<>[staff_first_name]<>, <>[company_name]<>, <>[job_name]<>, <>[job_serial_number]<>,
                  <>[staff_first_name]<>, <>[contact_first_name]<>,
                  <>[staff_phone]<>, <>[staff_email]<>`}</span>
                </Typography>
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Footer In PDF</Label>

                <Editor
                  apiKey={env_variables.tinymce_api_key}
                  init={{
                    height: DEFAULT_EDITOR_HEIGHT,
                    plugins: DEFAULT_EDITOR_PLUGINS,
                    toolbar: DEFAULT_EDITOR_TOOLBAR,
                    font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
                  }}
                  value={formik.values.footerInPdf}
                  onEditorChange={content =>
                    formik.setFieldValue("footerInPdf", content)
                  }
                />

                <Typography style="body3">
                  Please use the tag to get dynamic values with respect to job.{" "}
                  <span>{`<>[staff_full_name]<>, <>[staff_phone]<>,
                  <>[staff_designation]<>`}</span>
                </Typography>
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Milestone Details</Label>

                <Editor
                  apiKey={env_variables.tinymce_api_key}
                  init={{
                    height: DEFAULT_EDITOR_HEIGHT,
                    plugins: DEFAULT_EDITOR_PLUGINS,
                    toolbar: DEFAULT_EDITOR_TOOLBAR,
                    font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
                  }}
                  value={formik.values.phasesDetails}
                  onEditorChange={content =>
                    formik.setFieldValue("phasesDetails", content)
                  }
                />
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Approved Fees Details</Label>

                <Editor
                  apiKey={env_variables.tinymce_api_key}
                  init={{
                    height: DEFAULT_EDITOR_HEIGHT,
                    plugins: DEFAULT_EDITOR_PLUGINS,
                    toolbar: DEFAULT_EDITOR_TOOLBAR,
                    font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
                  }}
                  value={formik.values.approvedFeesDetails}
                  onEditorChange={content =>
                    formik.setFieldValue("approvedFeesDetails", content)
                  }
                />
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Updated Cost Details</Label>

                <Editor
                  apiKey={env_variables.tinymce_api_key}
                  init={{
                    height: DEFAULT_EDITOR_HEIGHT,
                    plugins: DEFAULT_EDITOR_PLUGINS,
                    toolbar: DEFAULT_EDITOR_TOOLBAR,
                    font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
                  }}
                  value={formik.values.updatedCostDetails}
                  onEditorChange={content =>
                    formik.setFieldValue("updatedCostDetails", content)
                  }
                />
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Revised Total Details</Label>

                <Editor
                  apiKey={env_variables.tinymce_api_key}
                  init={{
                    height: DEFAULT_EDITOR_HEIGHT,
                    plugins: DEFAULT_EDITOR_PLUGINS,
                    toolbar: DEFAULT_EDITOR_TOOLBAR,
                    font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
                  }}
                  value={formik.values.revisedTotalDetails}
                  onEditorChange={content =>
                    formik.setFieldValue("revisedTotalDetails", content)
                  }
                />
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Estimate Validity Details</Label>

                <Editor
                  apiKey={env_variables.tinymce_api_key}
                  init={{
                    height: DEFAULT_EDITOR_HEIGHT,
                    plugins: DEFAULT_EDITOR_PLUGINS,
                    toolbar: DEFAULT_EDITOR_TOOLBAR,
                    font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
                  }}
                  value={formik.values.estimateValidityDetails}
                  onEditorChange={content =>
                    formik.setFieldValue("estimateValidityDetails", content)
                  }
                />
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Sound Good Details</Label>

                <Editor
                  apiKey={env_variables.tinymce_api_key}
                  init={{
                    height: DEFAULT_EDITOR_HEIGHT,
                    plugins: DEFAULT_EDITOR_PLUGINS,
                    toolbar: DEFAULT_EDITOR_TOOLBAR,
                    font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
                  }}
                  value={formik.values.soundGoodDetails}
                  onEditorChange={content =>
                    formik.setFieldValue("soundGoodDetails", content)
                  }
                />
              </div>
            </>
          )}

          {formik.values.pageName === "License" && (
            <>
              <div className="flex flex-col space-y-2">
                <Label>PDF Filename</Label>
                <SubjectComponent
                  formik={formik}
                  getOptions={() => pdfFilenameResourceListForLicense()}
                  attr="pdfFilename"
                />
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Email Text</Label>
                <SubjectComponent
                  formik={formik}
                  getOptions={() => paperworkResourceList()}
                  attr="licenseEmailDetails"
                />
              </div>
            </>
          )}

          {formik.values.pageName === "Commencement Invoice" && (
            <>
              <div className="flex flex-col space-y-2">
                <Label>PDF Filename</Label>
                <SubjectComponent
                  formik={formik}
                  getOptions={() => pdfFilenameResourceListForEstimate()}
                  attr="pdfFilename"
                />
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Email Text</Label>
                <SubjectComponent
                  formik={formik}
                  getOptions={() => paperworkResourceList()}
                  attr="commencementInvoiceEmailDetails"
                />
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Header Details</Label>

                <Editor
                  apiKey={env_variables.tinymce_api_key}
                  init={{
                    height: DEFAULT_EDITOR_HEIGHT,
                    plugins: DEFAULT_EDITOR_PLUGINS,
                    toolbar: DEFAULT_EDITOR_TOOLBAR,
                    font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
                  }}
                  value={formik.values.headerDetails}
                  onEditorChange={content =>
                    formik.setFieldValue("headerDetails", content)
                  }
                />

                <Typography style="body3">
                  Please use the tag to get dynamic values with respect to job.{" "}
                  <span>{`<>[staff_first_name]<>, <>[company_name]<>, <>[job_name]<>, <>[job_serial_number]<>,
                  <>[staff_first_name]<>, <>[contact_first_name]<>, <>[invoice_serial]<>, <>[due_date]<>,
                  <>[staff_phone]<>, <>[staff_email]<>`}</span>
                </Typography>
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Header In PDF</Label>

                <Editor
                  apiKey={env_variables.tinymce_api_key}
                  init={{
                    height: DEFAULT_EDITOR_HEIGHT,
                    plugins: DEFAULT_EDITOR_PLUGINS,
                    toolbar: DEFAULT_EDITOR_TOOLBAR,
                    font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
                    content_style: "p { margin: 0; line-height: 1 }",
                  }}
                  value={formik.values.headerInPdf}
                  onEditorChange={content =>
                    formik.setFieldValue("headerInPdf", content)
                  }
                />

                <Typography style="body3">
                  Please use the tag to get dynamic values with respect to job.{" "}
                  <span>{`<>[staff_first_name]<>, <>[company_name]<>, <>[job_name]<>, <>[job_serial_number]<>,
                  <>[staff_first_name]<>, <>[contact_first_name]<>, <>[invoice_serial]<>, <>[due_date]<>,
                  <>[staff_phone]<>, <>[staff_email]<>`}</span>
                </Typography>
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Commencement Fee Details</Label>
                <Editor
                  apiKey={env_variables.tinymce_api_key}
                  init={{
                    height: DEFAULT_EDITOR_HEIGHT,
                    plugins: DEFAULT_EDITOR_PLUGINS,
                    toolbar: DEFAULT_EDITOR_TOOLBAR,
                    font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
                  }}
                  value={formik.values.commencementFeeDetails}
                  onEditorChange={content =>
                    formik.setFieldValue("commencementFeeDetails", content)
                  }
                />

                <Typography style="body3">
                  Please use the tag to get dynamic values with respect to
                  invoice. <span>{`<>[invoice_percentage]<>`}</span>
                </Typography>
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Approved Fees Details</Label>

                <Editor
                  apiKey={env_variables.tinymce_api_key}
                  init={{
                    height: DEFAULT_EDITOR_HEIGHT,
                    plugins: DEFAULT_EDITOR_PLUGINS,
                    toolbar: DEFAULT_EDITOR_TOOLBAR,
                    font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
                  }}
                  value={formik.values.approvedFeesDetails}
                  onEditorChange={content =>
                    formik.setFieldValue("approvedFeesDetails", content)
                  }
                />
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Invoice Payment Term</Label>

                <Editor
                  apiKey={env_variables.tinymce_api_key}
                  init={{
                    height: DEFAULT_EDITOR_HEIGHT,
                    plugins: DEFAULT_EDITOR_PLUGINS,
                    toolbar: DEFAULT_EDITOR_TOOLBAR,
                    font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
                  }}
                  value={formik.values.invoicePaymentTerm}
                  onEditorChange={text =>
                    formik.setFieldValue("invoicePaymentTerm", text)
                  }
                />
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Invoice Payment Term In PDF</Label>

                <Editor
                  apiKey={env_variables.tinymce_api_key}
                  init={{
                    height: DEFAULT_EDITOR_HEIGHT,
                    plugins: DEFAULT_EDITOR_PLUGINS,
                    toolbar: DEFAULT_EDITOR_TOOLBAR,
                    font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
                  }}
                  value={formik.values.invoicePaymentTermInPdf}
                  onEditorChange={text =>
                    formik.setFieldValue("invoicePaymentTermInPdf", text)
                  }
                />
              </div>
            </>
          )}

          {formik.values.pageName === "Final Invoice" && (
            <>
              <div className="flex flex-col space-y-2">
                <Label>PDF Filename</Label>
                <SubjectComponent
                  formik={formik}
                  getOptions={() => pdfFilenameResourceListForEstimate()}
                  attr="pdfFilename"
                />
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Email Text</Label>
                <SubjectComponent
                  formik={formik}
                  getOptions={() => paperworkResourceList()}
                  attr="finalInvoiceEmailDetails"
                />
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Header Details</Label>

                <Editor
                  apiKey={env_variables.tinymce_api_key}
                  init={{
                    height: DEFAULT_EDITOR_HEIGHT,
                    plugins: DEFAULT_EDITOR_PLUGINS,
                    toolbar: DEFAULT_EDITOR_TOOLBAR,
                    font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
                    content_style:
                      "body p { margin-block-start: 0; margin-block-end: 0; line-height: 1.5 }",
                  }}
                  value={formik.values.headerDetails}
                  onEditorChange={content =>
                    formik.setFieldValue("headerDetails", content)
                  }
                />

                <Typography style="body3">
                  Please use the tag to get dynamic values with respect to job /
                  invoice.{" "}
                  <span>{`<>[staff_first_name]<>, <>[company_name]<>, <>[job_name]<>, <>[job_serial_number]<>,
                  <>[staff_full_name]<>, <>[contact_first_name]<>, <>[invoice_serial]<>, <>[due_date]<>,
                  <>[staff_phone]<>, <>[staff_email]<>, <>[invoice_type]<>`}</span>
                </Typography>
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Header In PDF</Label>

                <Editor
                  apiKey={env_variables.tinymce_api_key}
                  init={{
                    height: DEFAULT_EDITOR_HEIGHT,
                    plugins: DEFAULT_EDITOR_PLUGINS,
                    toolbar: DEFAULT_EDITOR_TOOLBAR,
                    font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
                    content_style: "p { margin: 0; line-height: 1 }",
                  }}
                  value={formik.values.headerInPdf}
                  onEditorChange={content =>
                    formik.setFieldValue("headerInPdf", content)
                  }
                />

                <Typography style="body3">
                  Please use the tag to get dynamic values with respect to job.{" "}
                  <span>{`<>[staff_first_name]<>, <>[company_name]<>, <>[job_name]<>, <>[job_serial_number]<>,
                  <>[staff_first_name]<>, <>[contact_first_name]<>, <>[invoice_serial]<>, <>[due_date]<>,
                  <>[staff_phone]<>, <>[staff_email]<>`}</span>
                </Typography>
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Invoice Payment Term</Label>

                <Editor
                  apiKey={env_variables.tinymce_api_key}
                  init={{
                    height: DEFAULT_EDITOR_HEIGHT,
                    plugins: DEFAULT_EDITOR_PLUGINS,
                    toolbar: DEFAULT_EDITOR_TOOLBAR,
                    font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
                  }}
                  value={formik.values.invoicePaymentTerm}
                  onEditorChange={text =>
                    formik.setFieldValue("invoicePaymentTerm", text)
                  }
                />
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Invoice Payment Term In PDF</Label>

                <Editor
                  apiKey={env_variables.tinymce_api_key}
                  init={{
                    height: DEFAULT_EDITOR_HEIGHT,
                    plugins: DEFAULT_EDITOR_PLUGINS,
                    toolbar: DEFAULT_EDITOR_TOOLBAR,
                    font_size_formats: DEFAULT_EDITOR_FONT_SIZES,
                  }}
                  value={formik.values.invoicePaymentTermInPdf}
                  onEditorChange={text =>
                    formik.setFieldValue("invoicePaymentTermInPdf", text)
                  }
                />
              </div>
            </>
          )}

          {formik.values.pageName === "RCI Final" && (
            <>
              <div className="flex flex-col space-y-2">
                <Label>PDF Filename</Label>
                <SubjectComponent
                  formik={formik}
                  getOptions={() => pdfFilenameResourceListForEstimate()}
                  attr="pdfFilename"
                />
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Email Text</Label>
                <SubjectComponent
                  formik={formik}
                  getOptions={() => paperworkResourceListWithArtist()}
                  attr="finalRciEmailDetails"
                />
              </div>
            </>
          )}

          {formik.values.pageName === "RCI Commencement" && (
            <>
              <div className="flex flex-col space-y-2">
                <Label>PDF Filename</Label>
                <SubjectComponent
                  formik={formik}
                  getOptions={() => pdfFilenameResourceListForEstimate()}
                  attr="pdfFilename"
                />
              </div>

              <div className="flex flex-col space-y-2">
                <Label>Email Text</Label>
                <SubjectComponent
                  formik={formik}
                  getOptions={() => paperworkResourceListWithArtist()}
                  attr="commencementRciEmailDetails"
                />
              </div>
            </>
          )}

          <Switch
            label="Add Company Name in Footer"
            onChange={() =>
              formik.setFieldValue(
                "companyNameInFooter",
                !formik.values.companyNameInFooter
              )
            }
            checked={formik.values.companyNameInFooter}
          />
        </>
      )}
    </div>
  );
};

export default EntryForm;

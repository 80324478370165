import axios from "axios";

export const getInvoicesByWeeks = (locationId, year) => {
  return axios.get(
    `api/v1/dashboards/weekly_invoice_targets?location_id=${locationId}&year=${year}`
  );
};

export const getApprovedJobsByWeeks = (locationId, year) => {
  return axios.get(
    `api/v1/dashboards/weekly_job_approval_targets?location_id=${locationId}&year=${year}`
  );
};

export const getJobEnquiriesByWeeks = (locationId, year) => {
  return axios.get(
    `api/v1/dashboards/weekly_job_enquiries?location_id=${locationId}&year=${year}`
  );
};

export const getWeeklyClosedActionTags = (locationId, year) => {
  return axios.get(
    `/api/v1/dashboards/weekly_closed_action_tags?location_id=${locationId}&year=${year}`
  );
};

export const getCurrentWeekStatistics = locationId => {
  return axios.get(
    `api/v1/dashboards/current_week_statistics?location_id=${
      locationId === "All" ? "" : locationId
    }`
  );
};

export const updateJob = (jobId, payload) => {
  return axios.put(`api/v1/dashboards/jobs/${jobId}`, payload);
};

export const updateInvoice = (invoiceId, payload) => {
  return axios.put(`api/v1/dashboards/invoices/${invoiceId}`, payload);
};

export const getActiveJobs = locationId => {
  return axios.get(`/api/v1/dashboards/active_jobs?location_id=${locationId}`);
};

export const getApprovedJobs = locationId => {
  return axios.get(
    `/api/v1/dashboards/approved_jobs?location_id=${locationId}`
  );
};

export const getUnApprovedJobs = locationId => {
  return axios.get(
    `/api/v1/dashboards/unapproved_jobs?location_id=${locationId}`
  );
};

export const getJobEnquiries = locationId => {
  return axios.get(
    `/api/v1/dashboards/job_enquiries?location_id=${locationId}`
  );
};

export const getInvoices = locationId => {
  return axios.get(`/api/v1/dashboards/invoices?location_id=${locationId}`);
};

export const getClosedActionTags = locationId => {
  return axios.get(
    `/api/v1/dashboards/closed_action_tags?location_id=${locationId}`
  );
};

export const getNotifications = () => {
  return axios.get(`api/v1/notification_module/notifications`);
};

export const updateNotification = id => {
  return axios.put(`api/v1/notification_module/notifications/${id}`);
};

export const updateNotificationBulk = () => {
  return axios.get(`api/v1/notification_module/notifications/bulk_update`);
};

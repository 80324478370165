import axios from "axios";

export const approvalMailToProducer = (token, selectedUsageId) => {
  return axios.post(
    `/api/v1/email_templates/send_to_clients?token=${token}&usage_id=${selectedUsageId}`
  );
};

export const approvalQuestionMail = token => {
  return axios.post(
    `/api/v1/email_templates/send_questions_to_productions?token=${token}`
  );
};

export const overageApprovalMailToProduction = token => {
  return axios.post(
    `/api/v1/email_templates/send_overages_to_clients?token=${token}`
  );
};

export const overageApprovalQuestionMail = token => {
  return axios.post(
    `/api/v1/email_templates/send_overage_questions_to_productions?token=${token}`
  );
};

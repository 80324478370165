import React from "react";
import { Button, Table, Typography } from "@bigbinary/neetoui";
import { displayData } from "common/helper";

const Organisations = ({ list, title }) => {
  const renderSerial = rowData => {
    return (
      <Button
        style="link"
        to={`/organisations/${rowData.id}`}
        label={rowData.serialNumber}
      />
    );
  };

  const renderWebsite = rowData => {
    return (
      <Button
        style="link"
        href={rowData.website}
        target="_blank"
        label={rowData.website}
      />
    );
  };

  const renderAddress = rowData => {
    return (
      <div
        className="w-56 text-sm text-gray-900 truncate"
        dangerouslySetInnerHTML={{
          __html: rowData?.fullAddress?.split("\n").join("<br/>"),
        }}
      />
    );
  };

  const COLUMN_DATA = [
    {
      title: "Serial",
      key: "serialNumber",
      dataIndex: "serialNumber",
      width: 100,
      fixed: "left",
      render: (_, rowData) => renderSerial(rowData),
    },
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      width: 200,
      fixed: "left",
      render: (_, rowData) => displayData(rowData, "name"),
    },
    {
      title: "Type",
      key: "type",
      dataIndex: "type",
      width: 200,
      render: (_, rowData) => displayData(rowData, "type"),
    },
    {
      key: "vendorId",
      dataIndex: "vendorId",
      title: "Vendor Id",
      width: 200,
      render: (_, rowData) => displayData(rowData, "vendorId"),
    },
    {
      key: "organisationId",
      dataIndex: "organisationId",
      title: "Organisation Id",
      width: 200,
      render: (_, rowData) => displayData(rowData, "organisationId"),
    },
    {
      title: "Phone Number",
      key: "phoneNumber",
      dataIndex: "phoneNumber",
      width: 200,
      render: (_, rowData) => displayData(rowData, "phoneNumber"),
    },
    {
      title: "Website",
      key: "website",
      dataIndex: "website",
      width: 200,
      render: (_, rowData) => renderWebsite(rowData),
    },
    {
      title: "Address",
      key: "fullAddress",
      dataIndex: "fullAddress",
      width: 200,
      render: (_, rowData) => renderAddress(rowData),
    },
  ];

  return (
    <div className="flex flex-col w-full mt-4 p-4 rounded bg-white">
      <Typography
        style="h3"
        weight="semibold"
        className="mb-4 mt-2 text-gray-800 px-3"
      >
        {title}
      </Typography>
      <Table fixedHeight columnData={COLUMN_DATA} rowData={list} />
    </div>
  );
};

export default Organisations;

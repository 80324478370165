import React, { useState, useContext, useEffect } from "react";
import {
  Typography,
  Accordion,
  Button,
  Checkbox,
  Input,
  Alert,
  Label,
  Spinner
} from "@bigbinary/neetoui";
import { Help } from "@bigbinary/neeto-icons";
import { showToastrError } from "common";
import { toast } from "react-toastify";
import { updateLicense, cloneLicenses } from "apis/jobs/licenses";
import { updateEstimateRevision } from "apis/jobs/estimate_revisions";
import UsageItemDetail from "./UsageItemDetail";
import { UsageDetailsContext } from "../Usage/index";

const CreateUsagePage = () => {
  const {
    jobDetail,
    partiallyApprovedRevisionId,
    usageList,
    revisionId,
    usageListRecords,
    alternateUsageNeeded,
    currentRevision,
    loadEstimateRevisionsResponse,
  } = useContext(UsageDetailsContext);
  const [alternativeCreatedSuccess, setAlternativeCreatedSuccess] = useState();
  const [cloneUsage, setCloneUsage] = useState(false);
  const [openTab, setOpenTab] = useState();
  const [calculatorNumber, setCalculatorNumber] = useState(0);
  const [calculatorPercentage, setCalculatorPercentage] = useState(0);
  const [reverseCalculatorNumber, setReverseCalculatorNumber] = useState(0);
  const [
    reverseCalculatorPercentage,
    setReverseCalculatorPercentage,
  ] = useState(1);
  const [usageLoad, setUsageLoad] = useState(false);

  useEffect(() => {
    setAlternativeCreatedSuccess(alternateUsageNeeded);
  }, [alternateUsageNeeded]);

  const updateEstimateRevisionResponse = async (usageEnabled) => {
    try {
      setUsageLoad(true);
      await updateEstimateRevision(jobDetail.id, revisionId, { estimate_revision: { usageEnabled: usageEnabled }});
      loadEstimateRevisionsResponse();
      setUsageLoad(false);
    } catch (error) {
      showToastrError(error.data.errors)
    }
  }

  const createAlternativeUsages = async () => {
    let usageId = usageList.find(
      usage => usage.kind === "master" || usage.kind === "not_assigned"
    )?.id;
    let alternateUsageId = usageId ? usageId : usageList[0]?.id;

    if (alternateUsageId) {
      try {
        await cloneLicenses(revisionId, {
          clone_license: {
            license_id: alternateUsageId,
            estimate_revision_id: revisionId,
          },
        });
        setCloneUsage(false);
        setAlternativeCreatedSuccess(true);
        usageListRecords();
      } catch (error) {
        showToastrError(error.data.errors[0]);
      }
    } else {
      toast.info("No Id found", {
        position: "bottom-left",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const updateUsageEntry = async (usageId, kind) => {
    try {
      let payload = { license: { kind: kind } };
      await updateLicense(revisionId, usageId, payload);
      usageListRecords();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  if (usageLoad) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex justify-center w-full h-full p-6 overflow-y-scroll">
      <div className="w-full mb-6">
        <div className="flex justify-between mb-4">
          <div className="flex flex-col">
            <div className="flex items-center">
              <Typography style="h4" weight="semibold" className="mb-1">
                Usage Terms
              </Typography>

              <Checkbox
                className="ml-2"
                label={
                  <Label
                    className="ml-0"
                    helpIconProps={{
                      icon: Help,
                      tooltipProps: {
                        content: "If you want usage to be included in an estimate, you should enable it!",
                        position: "right",
                      }
                    }}
                  >
                  Enable in Estimate
                </Label>}
                checked={currentRevision?.usageEnabled}
                onChange={() => updateEstimateRevisionResponse(!currentRevision?.usageEnabled)}
              />
            </div>
            <Typography style="body2">
              Click to add a new usage term for licensing an artwork.
            </Typography>
          </div>

          {alternativeCreatedSuccess && !partiallyApprovedRevisionId && (
            <div>
              <Button
                label="Create Alternative"
                onClick={() => {
                  setCloneUsage(true);
                }}
              />
            </div>
          )}
        </div>
        <Accordion className="px-2 mb-8 border rounded-md neeto-ui-border-gray-200">
          {usageList?.map((item) => {
            return (
              <Accordion.Item
                key={item.id}
                className="p-0"
                title={
                  <div className="flex items-center justify-between w-full">
                    <div className="flex items-center">
                      <Checkbox
                        className="mr-3"
                        checked={item.kind === "master"}
                        disabled={partiallyApprovedRevisionId}
                        onChange={() => {
                          if (item.kind !== "master") {
                            updateUsageEntry(item.id, "master");
                          } else {
                            let kind =
                              item.name === "high"
                                ? "high"
                                : item.name === "low"
                                ? "low"
                                : item.name === "Standard Usage"
                                ? "not_assigned"
                                : null;
                            updateUsageEntry(item.id, kind);
                          }
                        }}
                      />
                      <Typography style="h5" weight="semibold">
                        {item.name}
                      </Typography>
                    </div>
                  </div>
                }
              >
                <UsageItemDetail
                  item={item}
                  usageItemFromUsage={item.licenseItems}
                  revisionId={revisionId}
                  usageListRecords={usageListRecords}
                />
              </Accordion.Item>
            );
          })}
        </Accordion>

        <div className="flex flex-col w-full pb-24">
          <Typography style="h4" weight="semibold" className="mb-3">
            Calculator
          </Typography>
          <div className="grid grid-cols-2 gap-6 divide-x divide-gray-200">
            <div className="flex items-end">
              <Input
                label="What is"
                value={calculatorPercentage}
                onChange={e => setCalculatorPercentage(e.target.value)}
              />
              <p className="flex items-center p-2 mt-4">%</p>
              <Input
                label="of"
                value={calculatorNumber}
                onChange={e => setCalculatorNumber(e.target.value)}
              />
              <p className="flex items-center p-2 mt-4">=</p>
              <Input
                className="mt-5"
                value={(calculatorNumber * calculatorPercentage) / 100}
              />
            </div>
            <div className="flex items-end pl-6">
              <Input
                label="What is"
                value={reverseCalculatorNumber}
                onChange={e => setReverseCalculatorNumber(e.target.value)}
              />
              <p className="flex items-center p-2 mt-4">of</p>
              <Input
                value={reverseCalculatorPercentage}
                onChange={e => setReverseCalculatorPercentage(e.target.value)}
              />
              <p className="flex items-center p-2 mt-4">=</p>
              <Input
                value={
                  (reverseCalculatorNumber / reverseCalculatorPercentage) * 100
                }
              />
              <p className="flex items-center p-2 mt-4">%</p>
            </div>
          </div>
        </div>
      </div>

      <Alert
        isOpen={cloneUsage}
        title="Add Alternative Usage"
        message="Are you sure you want to add the Alternative Usage Item?"
        onClose={() => setCloneUsage(false)}
        onSubmit={() => createAlternativeUsages()}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, continue"
      />
    </div>
  );
};

export default CreateUsagePage;

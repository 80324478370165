import React from "react";
import { Label, Typography } from "@bigbinary/neetoui";
import * as dayjs from "dayjs";
import { numberWithCommas } from "common/helper";

const EarlyBird = ({
  earlyBirdDueDate,
  taxPercentage,
  currency,
  taxTypeName,
  subtotal,
  earlyBirdFeePercentage,
}) => {
  const discountAmount = () => {
    return Number((subtotal * Number(earlyBirdFeePercentage)) / 100).toFixed(2);
  };

  const adjustedSubtotal = () => {
    return Number(subtotal - discountAmount()).toFixed(2);
  };

  const taxAmountOnSubtotal = () => {
    return Number((adjustedSubtotal() * taxPercentage) / 100).toFixed(2);
  };

  const totalAmount = () => {
    return (Number(adjustedSubtotal()) + Number(taxAmountOnSubtotal())).toFixed(
      2
    );
  };

  return (
    <div className="mb-6">
      <Typography weight="semibold" style="h4" className="mb-3">
        Early Bird Package
      </Typography>

      <div className="p-6 bg-gray-100 rounded-t-md">
        <div className="flex justify-between p-5 my-4 bg-white rounded-md">
          <Label className="font-medium">Discount</Label>
          <Label className="pl-1 font-bold text-gray-800">
            -{numberWithCommas(discountAmount())}
          </Label>
        </div>
        <div className="flex justify-between p-5 my-4 bg-white rounded-md">
          <Label className="font-medium">Adjusted Subtotal</Label>
          <Label className="pl-1 font-bold text-gray-800">
            {numberWithCommas(adjustedSubtotal())}
          </Label>
        </div>

        {taxTypeName?.length > 0 && (
          <div className="flex justify-between p-5 my-4 bg-white rounded-md">
            <Label className="font-medium">{taxTypeName}</Label>
            <Label className="pl-1 font-bold text-gray-800">
              {numberWithCommas(taxAmountOnSubtotal())}
            </Label>
          </div>
        )}
      </div>
      <div className="flex justify-end px-5 py-2 bg-gray-200 rounded-b-md">
        <div className="flex flex-col flex-end">
          <div className="self-end text-xl font-bold">
            Total : {numberWithCommas(totalAmount())} {currency}
          </div>

          <Label>
            (If paid by {dayjs(earlyBirdDueDate).format("MMMM DD, YYYY")})
          </Label>
        </div>
      </div>
    </div>
  );
};

export default EarlyBird;

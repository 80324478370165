import React, { useState, useEffect } from "react";
import * as R from "ramda";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { Search, MenuHorizontal } from "@bigbinary/neeto-icons";
import {
  Spinner,
  Button,
  Input,
  Typography,
  Dropdown,
  Table,
  Alert,
  Pane,
  Tag,
} from "@bigbinary/neetoui";
import { SubHeader } from "@bigbinary/neetoui/layouts";
import { showToastrError } from "common";
import {
  getStaffs,
  createStaff,
  updateStaff,
  getStaff,
  destroyStaff,
} from "apis/super_admins/users";
import { DEFAULT_PAGE_SIZE, titleize, humanize, getRandomNotFoundImage } from "common/helper";
import useDebounce from "common/debounce";
import EmptyState from "components/Common/EmptyState";
import { USERS_VALIDATION_SCHEMA, USERS_INITIAL_VALUE } from "./constants";
import AddUser from "./AddUser";

const Users = ({ accountDetail, loadAccountDetailResponse }) => {
  const [searchParams, setSearchParams] = useState();
  const debouncedSearchTerm = useDebounce(searchParams, 500);
  const [userList, setUserList] = useState([]);
  const [userListLoading, setUserListLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState();
  const [sortProps, setSortProps] = useState();
  const [pageIndex, setPageIndex] = useState();
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [addPane, setAddPane] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [deletingUserId, setDeletingUserId] = useState();
  const [copyEmail, setCopyEmail] = useState(false);
  const [userDetail, setUserDetail] = useState();
  const [btnLoad, setBtnLoad] = useState(false);
  const [userDetailLoading, setUserDetailLoading] = useState(false);
  const { Menu, MenuItem } = Dropdown;
  const [initial, setInitial] = useState(true);
  const [emptyImage, setEmptyImage] = useState();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: userDetail ? userDetail : USERS_INITIAL_VALUE,
    validationSchema: USERS_VALIDATION_SCHEMA,
    onSubmit: () => {
      if (userDetail) {
        onUpdate();
      } else {
        onCreate();
      }
    },
  });

  useEffect(() => {
    setEmptyImage(getRandomNotFoundImage());
  }, [])

  useEffect(() => {
    if (!addPane) {
      setUserDetail();
    }
  }, [addPane])

  useEffect(() => {
    if (accountDetail.id) {
      loadUserListResponse();
    }
  }, [accountDetail.id]);

  useEffect(() => {
    if (sortProps && !initial) {
      loadUserListResponse();
    }
  }, [sortProps]);

  useEffect(() => {
    if (pageIndex && !initial) {
      loadUserListResponse();
    }
  }, [pageIndex]);

  useEffect(() => {
    if (debouncedSearchTerm != undefined) {
      loadUserListResponse(searchParams, {});
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (copyEmail) {
      toast.info("Copied to Clipboard!", {
        position: "bottom-left",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setCopyEmail(false);
    }
  }, [copyEmail]);

  const onCreate = async () => {
    try {
      setBtnLoad(true);
      let payload = {
        staff: {
          ...formik.values,
        },
      };
      await createStaff(accountDetail.id, payload);
      await formik.resetForm();
      setUserDetail();
      loadUserListResponse();
      loadAccountDetailResponse();
      setAddPane(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      setBtnLoad(false);
    }
  };

  const onUpdate = async () => {
    try {
      setBtnLoad(true);
      let payload = {
        staff: {
          ...formik.values,
        },
      };
      await updateStaff(accountDetail.id, formik.values.id, payload);
      setAddPane(false);
      loadUserListResponse();
      formik.resetForm();
      setUserDetail();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      setBtnLoad(false);
    }
  };

  const loadUserListResponse = async () => {
    try {
      const response = await getStaffs(
        accountDetail.id,
        searchParams,
        sortProps,
        pageIndex || 1,
        DEFAULT_PAGE_SIZE
      );
      setUserList(response.data.users);
      setUserListLoading(false);
      setTotalRecords(response.data.totalRecords);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadUserDetailResponse = async userId => {
    try {
      setUserDetailLoading(true);
      const response = await getStaff(accountDetail.id, userId);
      setUserDetail(response.data.user);
      setUserDetailLoading(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const onDelete = async () => {
    try {
      await destroyStaff(accountDetail.id, deletingUserId);
      setDeleteAlertOpen(false);
      loadUserListResponse();
      loadAccountDetailResponse();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const COLUMN_DATA = [
    {
      title: "Name",
      dataIndex: "fullName",
      key: "fullName",
      sorter: true,
      field: "full_name",
      render: (_, rowData) => (
        <>
          {rowData.pronouns
            ? `${rowData.fullName}(${rowData.pronouns})`
            : rowData.fullName}
        </>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: true,
      field: "email",
      key: "email",
      render: (_, rowData) => <>{rowData.email}</>,
    },
    {
      title: "Title",
      dataIndex: "title",
      sorter: true,
      field: "title",
      key: "title",
      render: (_, rowData) => <>{rowData.title}</>,
    },
    {
      title: "Access",
      dataIndex: "access",
      sorter: true,
      field: "access",
      key: "access",
      render: (_, rowData) => <>{titleize(humanize(rowData.access))}</>,
    },
    {
      title: "State",
      dataIndex: "archive",
      sorter: true,
      field: "archive",
      key: "archive",
      render: (_, rowData) => (
        rowData.archive ? (
          <Tag label="Archived" style="danger" type="solid" />
        ) : (
          <Tag label="Active" style="success" type="solid" />
        )
      ),
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      fixed: "right",
      render: (_, rowData) => (
        <Dropdown
          autoWidth
          closeOnSelect
          buttonStyle="text"
          strategy="fixed"
          appendTo={() => document.body}
          icon={MenuHorizontal}
        >
          <Menu>
            <MenuItem.Button
              onClick={() => {
                loadUserDetailResponse(rowData.id);
                setAddPane(true);
              }}
            >
              Edit
            </MenuItem.Button>
            <MenuItem.Button
              style="danger"
              onClick={() => {
                setDeletingUserId(rowData.id);
                setDeleteAlertOpen(true);
              }}
            >
              Delete
            </MenuItem.Button>
          </Menu>
        </Dropdown>
      ),
    },
  ];

  if (!accountDetail.id) {
    return (
      <div
        className="flex items-center justify-center p-2"
        style={{ height: "calc(100vh - 134px)" }}
      >
        <EmptyState
          image={emptyImage}
          title="Please click on any account to access the details."
        />
      </div>
    );
  }

  if (userListLoading) {
    return (
      <div
        className="flex items-center justify-center w-full"
        style={{ height: "calc(100vh - 134px)" }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div
      className="flex flex-col overflow-hidden"
      style={{ height: "calc(100vh - 134px)" }}
    >
      <SubHeader
        className="px-6 pt-4"
        leftActionBlock={
          <Typography style="h4" component="h4" weight="semibold">
            {totalRecords > 1
              ? `${totalRecords} Users`
              : `${totalRecords} User`}
          </Typography>
        }
        rightActionBlock={
          <div className="flex justify-end space-x-3">
            <Input
              size="small"
              type="search"
              placeholder="Search"
              className="neeto-ui-header__search-input"
              prefix={<Search size={16} />}
              value={searchParams}
              onChange={e => setSearchParams(e.target.value)}
              clear={() => setSearchParams("")}
            />
            <Button
              size="small"
              label="Add User"
              className="px-3"
              onClick={() => {
                setAddPane(true);
                formik.resetForm();
                setUserDetail();
              }}
            />
          </div>
        }
      />

      <div className="px-4" style={{ height: "calc(100vh - 280px)" }}>
        {!R.isEmpty(userList) ? (
          <Table
            columnData={COLUMN_DATA}
            rowData={userList}
            fixedHeight
            loading={userListLoading}
            currentPageNumber={pageIndex}
            totalCount={totalRecords}
            defaultPageSize={pageSize}
            handlePageChange={(page, pageSize) => {
              setInitial(false);
              setPageIndex(page);
              setPageSize(pageSize);
            }}
            onChange={(pagination, filters, sorter) => {
              setInitial(false);
              setSortProps(sorter);
            }}
          />
        ) : (
          <EmptyState image={emptyImage} title="No Users Found" />
        )}
      </div>

      <Pane
        isOpen={addPane}
        onClose={() => setAddPane(false)}
        cancelButtonProps={{
          onClick: () => setAddPane(false),
        }}
      >
        <Pane.Header>
          <Typography style="h2" weight="semibold">
            {userDetail ? "Edit User" : "Add User"}
          </Typography>
        </Pane.Header>
        <Pane.Body>
          {userDetailLoading ? (
            <div className="flex items-center justify-center w-full h-full">
              <Spinner />
            </div>
          ) : (
            <AddUser formik={formik} accountDetail={accountDetail} />
          )}
        </Pane.Body>
        <Pane.Footer className="gap-2">
          <Button
            label="Save Changes"
            onClick={() => formik.handleSubmit()}
            loading={btnLoad}
          />
          <Button
            label="Cancel"
            style="text"
            onClick={() => setAddPane(false)}
          />
        </Pane.Footer>
      </Pane>

      <Alert
        isOpen={deleteAlertOpen}
        title="Delete User"
        message="Are you sure you want to delete this user?"
        onClose={() => setDeleteAlertOpen(false)}
        onSubmit={() => onDelete()}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, delete"
      />
    </div>
  );
};

export default Users;

import axios from "axios";
import { direction } from "common/helper";

export const getCurrentArtists = (
  searchParams = "",
  sortProps = {},
  pageIndex,
  perPage
) => {
  let paginationQuery = `page=${pageIndex}&per_page=${perPage}`;
  let searchQuery = `q[full_name_i_cont_any]=${searchParams}&q[user_full_name_i_cont_any]=${searchParams}&q[email_i_cont_any]=${searchParams}&q[artist_serial_number_i_cont_any]=${searchParams}&q[m]=or&q[tags_name_i_cont_any]=${searchParams}`;
  let sortQuery = `q[s]=${sortProps?.column?.field}+${direction(sortProps?.order)}`;
  let query = paginationQuery;
  if (searchParams.length > 0) query += `&${searchQuery}`;
  if (sortProps?.column && sortProps?.order) query += `&${sortQuery}`;

  return axios.get(`/api/v1/artists/current_artists?${query}`);
};

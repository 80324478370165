import axios from "axios";

const getIndustrySectorsUrl = id => {
  if (id) {
    return `/api/v1/settings/industry_sectors/${id}`;
  }

  return `/api/v1/settings/industry_sectors`;
};

export const getIndustrySectors = (searchParams = "") => {
  let query = "";
  if (searchParams.length > 0) query = `q[name_i_cont_any]=${searchParams}`;
  return axios.get(`${getIndustrySectorsUrl()}?${query}`);
};

export const createIndustrySector = payload => {
  return axios.post(getIndustrySectorsUrl(), payload);
};

export const updateIndustrySector = (id, payload) => {
  return axios.put(getIndustrySectorsUrl(id), payload);
};

export const destroyIndustrySector = id => {
  return axios.delete(getIndustrySectorsUrl(id));
};

import axios from "axios";

const getSpecialConditionsUrl = id => {
  if (id) {
    return `/api/v1/settings/special_conditions/${id}`;
  }

  return `/api/v1/settings/special_conditions`;
};

export const getSpecialConditions = (searchParams = "") => {
  let query = "";
  if (searchParams.length > 0)
    query = `q[name_i_cont_any]=${searchParams}&q[condition_i_cont_any]=${searchParams}&q[m]=or`;
  return axios.get(`${getSpecialConditionsUrl()}?${query}`);
};

export const createSpecialCondition = payload => {
  return axios.post(getSpecialConditionsUrl(), payload);
};

export const updateSpecialCondition = (id, payload) => {
  return axios.put(getSpecialConditionsUrl(id), payload);
};

export const destroySpecialCondition = id => {
  return axios.delete(getSpecialConditionsUrl(id));
};

import axios from "axios";

const getCategoriesUrl = id => {
  if (id) {
    return `/api/v1/settings/categories/${id}`;
  }

  return `/api/v1/settings/categories`;
};

export const getCategories = (searchParams = "") => {
  let query = "";
  if (searchParams.length > 0) query = `q[name_i_cont_any]=${searchParams}`;
  return axios.get(`${getCategoriesUrl()}?${query}`);
};

export const createCategory = payload => {
  return axios.post(getCategoriesUrl(), payload);
};

export const updateCategory = (id, payload) => {
  return axios.put(getCategoriesUrl(id), payload);
};

export const destroyCategory = id => {
  return axios.delete(getCategoriesUrl(id));
};

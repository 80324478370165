import React, { useState, useEffect } from "react";
import * as dayjs from "dayjs";
import * as R from "ramda";
import { Search } from "@bigbinary/neeto-icons";
import {
  Spinner,
  Button,
  Input,
  Typography,
  Dropdown,
  Tag,
  Table,
} from "@bigbinary/neetoui";
import { SubHeader } from "@bigbinary/neetoui/layouts";
import { showToastrError } from "common";
import { getOrganisationInvoices } from "apis/organisations/invoices";
import { humanize, numberWithCommas, getRandomNotFoundImage, artistModuleName } from "common/helper";
import { DEFAULT_PAGE_SIZE } from "common/constants";
import useDebounce from "common/debounce";
import EmptyState from "components/Common/EmptyState";

const Invoices = ({ organisationDetail }) => {
  const [searchParams, setSearchParams] = useState();
  const debouncedSearchTerm = useDebounce(searchParams, 500);
  const [invoiceList, setInvoiceList] = useState([]);
  const [invoiceListLoading, setInvoiceListLoading] = useState(true);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalRecords, setTotalRecords] = useState();
  const [sortProps, setSortProps] = useState();
  const [pageIndex, setPageIndex] = useState(1);
  const [initial, setInitial] = useState(true);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const { Menu, MenuItem } = Dropdown;
  const [emptyImage, setEmptyImage] = useState();

  useEffect(() => {
    setEmptyImage(getRandomNotFoundImage());
  }, [])

  useEffect(() => {
    if (organisationDetail.id) {
      loadInvoiceListResponse();
    }
  }, [organisationDetail.id]);

  useEffect(() => {
    if (sortProps && !initial) {
      loadInvoiceListResponse();
    }
  }, [sortProps]);

  useEffect(() => {
    if (pageIndex && !initial) {
      loadInvoiceListResponse();
    }
  }, [pageIndex]);

  useEffect(() => {
    if (debouncedSearchTerm != undefined) {
      loadInvoiceListResponse(searchParams, {});
    }
  }, [debouncedSearchTerm]);

  const loadInvoiceListResponse = async () => {
    try {
      const response = await getOrganisationInvoices(
        organisationDetail.id,
        searchParams,
        sortProps,
        pageIndex || 1,
        DEFAULT_PAGE_SIZE
      );
      setInvoiceListLoading(false);
      setInvoiceList(response.data.invoices);
      setTotalRecords(response.data.totalRecords);
      setTotalAmount(
        response.data.invoices.reduce(
          (partialSum, inv) => partialSum + Number(inv.totalAmount),
          0
        )
      );
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const COLUMN_DATA = [
    {
      key: "serialNumber",
      dataIndex: "serialNumber",
      title: "Serial",
      sorter: true,
      field: "serial",
      render: (_, rowData) => {
        return (
          <Button
            style="link"
            to={`/invoices/${rowData.id}`}
            label={rowData.serialNumber}
          />
        );
      },
    },
    {
      key: "jobName",
      dataIndex: "jobName",
      title: "Job",
      sorter: true,
      field: "job_name",
      width: 180,
      render: (_, rowData) => {
        return (
          <Button
            style="link"
            to={`/jobs/${rowData.jobId}/overview`}
            label={rowData.jobName}
          />
        );
      },
    },
    {
      title: artistModuleName(),
      dataIndex: "artists",
      sorter: true,
      field: "artists_full_name",
      key: "artists",
      render: (_, rowData) => {
        return (
          <Dropdown
            autoWidth
            closeOnSelect
            trigger="hover"
            position="top"
            customTarget={
              <p className="truncate w-28">
                {rowData.artists.map((artist, index) => {
                  return (
                    <>
                      {artist.name}
                      {index !== rowData.artists.length - 1 ? ", " : ""}
                    </>
                  );
                })}
              </p>
            }
          >
            <Menu>
              {rowData.artists.map(artist => {
                return (
                  <MenuItem.Button
                    className="no-underline"
                    key={artist.id}
                    to={`/${artistModuleName().toLowerCase()}/${artist.id}`}
                  >
                    {artist.name}
                  </MenuItem.Button>
                );
              })}
            </Menu>
          </Dropdown>
        );
      },
    },
    {
      title: "Staff",
      dataIndex: "staffFullName",
      key: "staffFullName",
      sorter: true,
      field: "user_full_name",
      render: staffFullName => <>{staffFullName}</>,
    },
    {
      title: "Issued On",
      dataIndex: "issueDate",
      key: "issueDate",
      sorter: true,
      field: "issue_date",
      render: issueDate => dayjs(issueDate).format("DD/MM/YYYY"),
    },
    {
      title: "Amount",
      dataIndex: "currency",
      key: "currency",
      sorter: true,
      field: "total_amount",
      render: (_, rowData) => {
        return (
          <>
            {`${numberWithCommas(Number(rowData.total).toFixed(2))} ${
              rowData.currency
            }`}
          </>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: true,
      field: "status",
      render: status => {
        return (
          <Tag
            // type="solid"
            style={status === "paid" ? "success" : "danger"}
            label={humanize(status)}
          />
        );
      },
    },
  ];

  if (!organisationDetail.id) {
    return (
      <div
        className="flex items-center justify-center p-2"
        style={{ height: "calc(100vh - 134px)" }}
      >
        <EmptyState
          image={emptyImage}
          title="Please click on any organisation to access the details."
        />
      </div>
    );
  }

  if (invoiceListLoading) {
    return (
      <div
        className="flex items-center justify-center w-full"
        style={{ height: "calc(100vh - 134px)" }}
      >
        <Spinner />
      </div>
    );
  }

  const finalInvoices = invoiceList.filter(invoice => invoice.final === true);
  const avgPaymentTerms =
    finalInvoices.length > 0
      ? finalInvoices.reduce((pv, cv) => pv + cv.actualPaymentTerms, 0) /
        finalInvoices.length
      : 0;

  const handleTableChange = (_, __, sorter) => {
    setInitial(false);
    setSortProps(sorter);
  };

  return (
    <div
      className="flex flex-col"
      style={{ height: "calc(100vh - 134px)" }}
    >
      <SubHeader
        className="px-6 pt-4"
        leftActionBlock={
          <Typography style="h4" component="h4" weight="semibold">
            {totalRecords > 1
              ? `${totalRecords} Invoices`
              : `${totalRecords} Invoice`}
          </Typography>
        }
        rightActionBlock={
          <Input
            size="small"
            type="search"
            placeholder="Search"
            className="neeto-ui-header__search-input"
            prefix={<Search size={16} />}
            value={searchParams}
            onChange={e => setSearchParams(e.target.value)}
            clear={() => setSearchParams("")}
          />
        }
      />

      <div
        className="px-4 mb-6"
        style={{ height: "calc(100vh - 130px - 184px)" }}
      >
        {!R.isEmpty(invoiceList) ? (
          <Table
            columnData={COLUMN_DATA}
            rowData={invoiceList}
            fixedHeight
            loading={invoiceListLoading}
            currentPageNumber={pageIndex}
            totalCount={totalRecords}
            defaultPageSize={pageSize}
            handlePageChange={(page, pageSize) => {
              setInitial(false);
              setPageIndex(page);
              setPageSize(pageSize);
            }}
            onChange={(pagination, filters, sorter) =>
              handleTableChange(pagination, filters, sorter)
            }
            paginationProps={{
              showSizeChanger: false,
            }}
          />
        ) : (
          <EmptyState image={emptyImage} title="No Invoices Found" />
        )}
      </div>

      <div className="fixed bottom-0 right-0  flex justify-between px-6 py-4 text-right bg-white border-t neeto-ui-border-gray-200" style={{ width: "calc(100vw - 550px)" }}>
        <div className="flex items-center space-x-2">
          <Typography style="h4" className="neeto-ui-text-gray-800">
            Average Payment Terms
          </Typography>
          <Typography
            style="h4"
            weight="bold"
            className="neeto-ui-text-error-600"
          >
            {`${Number(avgPaymentTerms).toFixed()} days`}
          </Typography>
        </div>
        <div className="flex items-center space-x-2">
          <Typography style="h4" className="neeto-ui-text-gray-800">
            Total Amount:
          </Typography>
          <Typography
            style="h4"
            weight="bold"
            className="neeto-ui-text-error-600"
          >
            {`${numberWithCommas(
              Number(
                totalAmount
              ).toFixed(2)
            )}`}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Invoices;

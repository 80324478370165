import axios from "axios";

export const getJobPhases = (revisionId, phaseTypeId) => {
  return axios.get(
    `/api/v1/job_module/estimate_revisions/${revisionId}/job_phases?phase_type_id=${phaseTypeId}`
  );
};

export const destroyPhase = (revisionId, phaseId) => {
  return axios.delete(
    `/api/v1/job_module/estimate_revisions/${revisionId}/job_phases/${phaseId}`
  );
};

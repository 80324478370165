import React, { useEffect, useState } from "react";
import { Spinner, Button, DatePicker, Label } from "@bigbinary/neetoui";
import { Header, Container } from "@bigbinary/neetoui/layouts";
import * as R from "ramda";
import Pluralize from "pluralize";
import * as dayjs from "dayjs";
import { getEstimateRevisions } from "apis/jobs/estimate_revisions";
import { getJob, updateIssueDate } from "apis/jobs/booking_form";
import { getEstimateRevisionItems } from "apis/jobs/estimate_revision_items";
import { useParams } from "react-router-dom";
import { showToastrError } from "common";
import { artistModuleName } from "common/helper";
import ArtistFeeDetail from "./ArtistFeeDetail";
import ArtistList from "./ArtistList";
import Tabs from "../Tabs";
import {
  jobWithNameNumber,
  filterEstimateItems,
  filterExtraItems,
  filterEstimateListItems,
  filterUsageItems,
} from "../../common/helper";
import Card from "components/Common/Card";

const BookingForm = () => {
  const { id, revisionId } = useParams();
  const [artist, setArtist] = useState({});
  const [jobDetail, setJobDetail] = useState({});
  const [jobDetailLoad, setJobDetailLoad] = useState(true);
  const [estimateRevisions, setEstimateRevisions] = useState([]);
  const [estimateRevisionsLoad, setEstimateRevisionsLoad] = useState(true);
  const [estimateRevisionItems, setEstimateRevisionItems] = useState([]);
  const [estimateRevisionItemsLoad, setEstimateRevisionItemsLoad] = useState(
    true
  );
  const usageEstimateItem = filterUsageItems(estimateRevisionItems)[0];
  const [issueDate, setIssueDate] = useState();
  const currentRevision = estimateRevisions.find(revision => revision.id === revisionId)

  useEffect(() => {
    if (id) {
      loadJobDetailResponse();
      loadEstimateRevisionsResponse();
      loadEstimateRevisionItemsResponse();
    }
  }, [id]);

  useEffect(() => {
    if (jobDetail && artist) {
      setIssueDate(
        jobDetail.artistsJobs?.find(
          artistJob => artistJob.artistId === artist.artistId
        )?.issueDate
      );
    }
  }, [jobDetail, artist]);

  const loadJobDetailResponse = async () => {
    try {
      const response = await getJob(id);
      setJobDetail(response.data.job);
      setJobDetailLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadEstimateRevisionsResponse = async () => {
    try {
      const response = await getEstimateRevisions(id);
      setEstimateRevisions(response.data.estimateRevisions);
      setEstimateRevisionsLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadEstimateRevisionItemsResponse = async () => {
    try {
      const response = await getEstimateRevisionItems(revisionId);
      setEstimateRevisionItems(
        filterEstimateListItems([
          ...filterEstimateItems(response.data.estimateRevisionItems),
          ...filterExtraItems(response.data.estimateRevisionItems),
        ])
      );
      setEstimateRevisionItemsLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const updateIssueDateResponse = async issueDate => {
    try {
      const payload = { artists_job: { issue_date: issueDate } };
      await updateIssueDate(id, artist.artistId, payload);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const getRevisionName = () => {
    return estimateRevisions.find(revision => revision.id === revisionId).name;
  };

  const getLicensesCount = () => {
    return estimateRevisions.find(revision => revision.id === revisionId)
      ?.licensesCount;
  };

  // TODO:  duplicate method. used at booking form
  const getArtistList = () => {
    return estimateRevisionItems
      .map(item => {
        if (item.artistId) {
          return {
            artistId: item.artistId,
            name: item.artistName,
            taxStatus: item.artistTaxStatus,
            fee: item.artistFee,
            artistTaxTypeId: item.artistTaxTypeId,
          };
        } else {
          return item.artistMarkupPercentages.map(item => {
            return {
              artistId: item.artistId,
              name: item.artistName,
              taxStatus: item.artistTaxStatus,
              fee: item.artistFee,
              artistTaxTypeId: item.artistTaxTypeId,
            };
          });
        }
      })
      .flat();
  };

  const totalAmountGroupedByArtist = () => {
    let artists = getArtistList();

    let amountGroupedByArtistId = artists.reduce(
      (artists, { artistId, name, fee, taxStatus, artistTaxTypeId }) => {
        if (!artists[artistId]) artists[artistId] = [];
        artists[artistId].push({
          artistId: artistId,
          name: name,
          taxStatus: taxStatus,
          fee: fee,
          artistTaxTypeId: artistTaxTypeId,
        });
        return artists;
      },
      {}
    );

    return Object.keys(amountGroupedByArtistId).map(artistId => {
      const sum = amountGroupedByArtistId[artistId].reduce(
        (partialSum, data) => partialSum + Number(data.fee),
        0
      );

      return {
        name: amountGroupedByArtistId[artistId][0].name,
        artistId: artistId,
        fee: sum,
        taxStatus: amountGroupedByArtistId[artistId][0].taxStatus,
        artistTaxTypeId: amountGroupedByArtistId[artistId][0].artistTaxTypeId,
      };
    });
  };

  const taxEligibility = () => {
    return jobDetail.taxTypeId === artist.artistTaxTypeId;
  };

  const totalTaxAmount = () => {
    if (taxEligibility()) {
      return Number(
        (Number(artist.fee) * Number(jobDetail.taxPercentage)) / 100
      ).toFixed(2);
    } else {
      return 0;
    }
  };

  const artistTotal = () => {
    return Number(artist.fee) + Number(totalTaxAmount());
  };

  if (estimateRevisionsLoad || jobDetailLoad || estimateRevisionItemsLoad) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <Container isHeaderFixed>
      <Header
        title={`${getRevisionName()} / ${artist?.name || "New"}`}
        breadcrumbs={[
          { text: "Jobs", link: "/jobs" },
          { text: jobWithNameNumber(jobDetail), link: `/jobs/${id}/overview` },
        ]}
        actionBlock={
          <>
            {artist?.artistId && (
              <Button
                label={`Send to ${Pluralize(artistModuleName(), 1)}`}
                to={`/mailer/jobs/${
                  jobDetail.id
                }/revisions/${revisionId}/booking_form/${
                  artist.artistId
                }?fee=${artistTotal()}&currency=${jobDetail.currency}`}
              />
            )}
          </>
        }
      />
      <Tabs jobId={id} revisionId={revisionId} />

      {currentRevision?.usageEnabled && (getLicensesCount() == 0 || R.isNil(usageEstimateItem)) ? (
        <div
          className="flex items-center justify-center w-full h-full text-3xl"
          style={{ height: "calc(100vh - 134px)" }}
        >
          <span>Please add usage to access booking form!</span>
        </div>
      ) : (
        <div
          className="flex w-full overflow-hidden"
          style={{ height: "calc(100vh - 134px)" }}
        >
          <div className="w-8/12 p-6 space-y-4 overflow-y-scroll border-r neeto-ui-bg-gray-100 neeto-ui-border-gray-300">
            <Card
              title="Booking Form"
              className="w-full"
              titleAction={
                artist?.artistId && (
                  <div className="flex justify-end">
                    <Label className="font-bold mr-3">Issue Date:</Label>
                    <DatePicker
                      name="issueDate"
                      dateFormat="DD/MM/YYYY"
                      value={dayjs(issueDate)}
                      className="w-40"
                      onChange={date => {
                        setIssueDate(date.format("YYYY-MM-DD"));
                        updateIssueDateResponse(date.format("YYYY-MM-DD"));
                      }}
                      allowClear={false}
                    />
                  </div>
                )
              }
            >
              <ArtistFeeDetail
                artist={artist}
                jobDetail={jobDetail}
                estimateRevisionItems={estimateRevisionItems}
              />
            </Card>
          </div>

          <div className="w-4/12 p-6 space-y-6 overflow-y-scroll neeto-ui-bg-white">
            <ArtistList
              list={totalAmountGroupedByArtist()}
              setArtist={setArtist}
              artistId={artist?.artistId}
            />
          </div>
        </div>
      )}
    </Container>
  );
};

export default BookingForm;

import React from "react";
import { Accordion, Typography, Label } from "@bigbinary/neetoui";
import { numberWithCommas, sumAmount } from "common/helper";
import { percentageCalculator } from "./utils";

const ParentLineItemBreakdown = ({
  revisionLineItemCatgoryList,
  estimateListItems,
  jobDetail,
}) => {
  let totalParentAmount = 0;
  revisionLineItemCatgoryList.map(parent => {
    let children = estimateListItems.filter(
      item => item.lineItemCategory?.id === parent.id
    );
    const totalOfParent = sumAmount(children, "amount");

    totalParentAmount = totalParentAmount + Number(totalOfParent);
  });

  return (
    <>
      {revisionLineItemCatgoryList?.length > 0 && (
        <Accordion className="px-0 border-b border-gray-200">
          <Accordion.Item
            className="border-b border-gray-200"
            title={
              <div className="flex justify-between w-full mr-3">
                <Typography style="h5" weight="semibold">
                  Estimate Line Item Breakdown
                </Typography>
                <Typography style="h5" weight="semibold">{`${numberWithCommas(
                  Number(totalParentAmount).toFixed(2)
                )} ${jobDetail.currency}`}</Typography>
              </div>
            }
          >
            <div className="flex flex-col p-4 space-y-2 bg-white rounded-md">
              {revisionLineItemCatgoryList.map(parent => {
                let children = estimateListItems.filter(
                  item => item.lineItemCategory?.id === parent.id
                );
                const totalOfParent = sumAmount(children, "amount");
                return (
                  <div key={parent.id} className="flex justify-between">
                    <Label className="font-semibold">
                      {`${parent.lineItemCategoryName} (${numberWithCommas(percentageCalculator(totalParentAmount, totalOfParent))}%)`}:
                    </Label>
                    <Label className="font-bold mr-3">
                      {numberWithCommas(Number(totalOfParent).toFixed(2))}&nbsp;
                      {jobDetail.currency}
                    </Label>
                  </div>
                );
              })}
            </div>
          </Accordion.Item>
        </Accordion>
      )}
    </>
  );
};

export default ParentLineItemBreakdown;

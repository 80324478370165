import React from "react";

const Investment = ({ updatedTotal, emailBackground }) => {
  return (
    <div className="flex flex-col items-start justify-between w-full py-12 lg:flex-row">
      <div
        className="flex flex-col items-start justify-start w-full h-full pr-0 lg:pr-12 lg:w-1/3"
        style={{ fontFamily: "Modern Gothic" }}
      >
        <div className="flex flex-col items-start justify-start mb-8 lg:mb-0">
          <h2 className="text-xl font-medium">Revised Total Investment</h2>
          <p
            className="mt-6"
            dangerouslySetInnerHTML={{
              __html: emailBackground?.revisedTotalDetails?.split("\n")?.join("<br />"),
            }}
          ></p>
        </div>
      </div>
      <div className="flex flex-col items-start justify-start w-full lg:w-2/3">
        <div
          className="flex flex-col items-start justify-start w-full"
          style={{ fontFamily: "Modern Gothic" }}
        >
          <div className="flex items-center justify-center w-full px-6 py-16 text-4xl font-medium leading-loose text-white bg-black lg:text-5xl lg:px-24 rounded_container">
            <span>{updatedTotal}</span>
          </div>
        </div>
        <span
          className="mt-4"
          style={{ fontFamily: "Modern Gothic Mono" }}
          dangerouslySetInnerHTML={{ __html: emailBackground.estimateValidityDetails?.split("\n")?.join("<br />")}}
        >
        </span>
      </div>
    </div>
  );
};

export default Investment;

import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import Navbar from "components/Common/Navbar";
import Dashboard from "./Dashboard";
import PasswordEdit from "./Account/Passwords/Edit";
import Profile from "./Account/Profile";
import Organisations from "./Organisations";
import People from "./People";
import Artists from "./Artists";
import Staffs from "./Staffs";
import Jobs from "./Jobs";
import Invoice from "./Invoices";
import Rci from "./Rcis";
import Mailer from "./Mailer";
import Settings from "./Settings";
import PostProduction from "./PostProduction";
import Reports from "./Reports";
import NotFound from "components/Dashboard/NotFound";
import Crm from "components/Dashboard/Crm";
import Account from "components/Dashboard/SuperAdmin/Accounts";
import MyJobs from "./MyJobs";

const Home = () => {
  return (
    <div className="flex h-screen">
      <Navbar />
      <div className="content-main">
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/staff" component={Staffs} />
          <Route exact path="/staff/:id" component={Staffs} />
          <Route exact path="/my_jobs" component={MyJobs} />
          <Route exact path="/organisations" component={Organisations} />
          <Route exact path="/people" component={People} />
          <Route exact path="/organisations/:id" component={Organisations} />
          <Route exact path="/people/:id" component={People} />
          <Route exact path={`/suppliers`} component={Artists} />
          <Route exact path={`/suppliers/:id`} component={Artists} />
          <Route exact path={`/artists`} component={Artists} />
          <Route exact path={`/artists/:id`} component={Artists} />
          <Route exact path="/my/password/edit" component={PasswordEdit} />
          <Route exact path="/my/profile" component={Profile} />
          <Route path="/jobs" component={Jobs} />
          <Route path="/invoices" component={Invoice} />
          <Route path="/rcis" component={Rci} />
          <Route path="/mailer" component={Mailer} />
          <Route
            path={["/settings/:tab", "/settings"]}
            render={props => <Settings {...props} />}
          />
          <Route path="/post_production" component={PostProduction} />
          <Route path="/crm" component={Crm} />
          <Route path="/reports" component={Reports} />
          <Route exact path="/super_admin/accounts" component={Account} />
          <Route exact path="/super_admin/accounts/:id" component={Account} />
          <Route path="" component={NotFound} />
          <Redirect from="/dashboard" to="/" />
        </Switch>
      </div>
    </div>
  );
};

export default Home;

import React, { useState, useEffect, useRef, useCallback } from "react";
import { UserCircle, Share, Send, Draft } from "@bigbinary/neeto-icons";
import { Button, Typography, Spinner, Toastr, Alert } from "@bigbinary/neetoui";
import { InView, useInView } from "react-intersection-observer";
import { getMessageThread } from "apis/jobs/message_threads";
import { createMessage } from "apis/jobs/messages";
import { updateMessage } from "apis/jobs/draft_messages";
import { updateNotification } from "apis/dashboards";
import { showToastrError } from "common";
import EmptyState from "components/Common/EmptyState";
import { EMAIL_DISABLED_MESSAGE } from "common/constants";
import { useUserState } from "contexts/user";
import { getRandomNotFoundImage } from "common/helper";
import { QUILL_TOOLBAR_STYLE, QUILL_KEYBOARD_BINDINGS } from "common/constants";
import ReactQuill from "react-quill";
import Viewer from "./Viewer";
import RecipientsPane from "./RecipientsPane";
import ShareThreadPane from "./ShareThreadPane";
import AddAttachments from "../AddAttachments";
import MessageModal from "../MessageModal";
import Attachment from "../Attachment";

const MessageList = ({
  jobId,
  threadId,
  jobDetail,
  loadMessageThreadsResponse,
  setNewMessage,
  formik,
  threadDetail,
  setThreadDetail,
  setActiveThred,
  setCurrentTab
}) => {
  const { user } = useUserState();
  const [threadDetailLoad, setThreadDetailLoad] = useState(false);
  const [showRecipientsPane, setShowRecipientsPane] = useState(false);
  const [shareThreadPane, setShowShareThreadPane] = useState(false);
  const [messageBody, setMessageBody] = useState();
  const [attachments, setAttachments] = useState([]);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [draftButtonLoader, setDraftButtonLoader] = useState(false);
  const [emptyImage, setEmptyImage] = useState();
  const [editorModalOpen, setEditorModalOpen] = useState(false);
  const [addAttachmentModal, setAddAttachmentModal] = useState(false);
  const [deleteConfirmAlert, setDeleteConfirmAlert] = useState(false);
  const [deletingAttachmentIndex, setDeletingAttachmentIndex] = useState();
  const [downloadLoad, setDownloadLoad] = useState(false);
  const [selectedMessageId, setSelectedMessageId] = useState();

  const ref = useRef();
  const { ref: inViewRef, inView } = useInView();

  const setRefs = useCallback(
    node => {
      // Ref's from useRef needs to have the node assigned to `current`
      ref.current = node;
      // Callback refs, like the one from `useInView`, is a function that takes the node as an argument
      inViewRef(node);
    },
    [inViewRef]
  );

  useEffect(() => {
    setEmptyImage(getRandomNotFoundImage());
  }, []);

  useEffect(() => {
    if (threadId && jobId) {
      loadThreadDetailResponse();
      setAttachments([]);
      setMessageBody("");
      setDeletingAttachmentIndex();
    }
  }, [threadId]);

  const createMessageResponse = (draft) => {
    if (selectedMessageId) {
      loadUpdateMessageResponse(draft, selectedMessageId)
    } else {
      loadCreateMessageResponse(draft);
    }
  };

  const loadThreadDetailResponse = async () => {
    try {
      setThreadDetailLoad(true);
      const response = await getMessageThread(jobId, threadId);
      await setThreadDetail(response.data.messageThread);
      await setThreadDetailLoad(false);
      const unseenMessageId = response.data.messageThread.messages.find(
        el => el.unseen
      )?.id;
      const lastMessageId =
        response.data.messageThread.messages[
          response.data.messageThread.messages.length - 1
        ]?.id;
      goToUnseenMessage(unseenMessageId || lastMessageId);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const loadCreateMessageResponse = async (draft) => {
    try {
      if (!draft) {
        setButtonLoader(true);
      } else {
        setDraftButtonLoader(true);
      }
      const response = await createMessage(jobId, threadId, {
        message: { htmlBody: messageBody, draft: draft, attachments_attributes: attachments },
      });
      setMessageBody();
      loadThreadDetailResponse();
      setAttachments([]);
      setMessageBody("");

      if (user.demo_account) {
        Toastr.error(EMAIL_DISABLED_MESSAGE);
      } else {
        Toastr.info(response.data.notice);
      }
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setButtonLoader(false);
      setDraftButtonLoader(false);
    }
  };

  const loadUpdateMessageResponse = async (draft, messageId) => {
    try {
      if (!draft) {
        setButtonLoader(true);
      } else {
        setDraftButtonLoader(true);
      }
      const response = await updateMessage(jobId, messageId, {
        draft_message: { htmlBody: messageBody, draft: draft, attachments_attributes: attachments },
      });
      setMessageBody();
      loadThreadDetailResponse();
      setAttachments([]);
      setMessageBody("");
      setSelectedMessageId();

      if (user.demo_account) {
        Toastr.error(EMAIL_DISABLED_MESSAGE);
      } else {
        Toastr.info(response.data.notice);
      }
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setButtonLoader(false);
      setDraftButtonLoader(false);
    }
  };

  const updateNotificationResponse = async id => {
    try {
      await updateNotification(id);
      loadMessageThreadsResponse();
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const setAttachmentRecord = newAttachments => {
    setAttachments([...attachments, ...newAttachments]);
  };

  const destroyAttachment = () => {
    let finalAttachments = attachments.filter(
      (attachment, index) => {
        if (index === deletingAttachmentIndex) {
          if (attachment.id) {
            attachment._destroy = 1;
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    );

    setAttachments(finalAttachments);
    setDeletingAttachmentIndex();
    setDeleteConfirmAlert(false);
  };

  const goToUnseenMessage = id => {
    if (id) {
      const violation = document.getElementById(id);
      violation?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  };

  const inViewIdMessage = (inView, id) => {
    if (inView && id) {
      updateNotificationResponse(id);
    }
  };

  const getRecipients = () => {
    let recipients = threadDetail.recipients
    if (threadDetail.productionEmail) {
      recipients = [...threadDetail.recipients, {
        email: jobDetail.account.productionEmail,
        recipientType: "default",
        name: jobDetail.account.name,
        designation: "Production",
        thumbUrl: jobDetail.account.thumbnailLogoUrl,
        productionEmail: true,
      }]
    }

    return recipients
  }

  if (threadDetailLoad || downloadLoad) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  if (!threadDetail?.id) {
    return (
      <div
        className="flex items-center justify-center p-2"
        style={{ height: "calc(100vh - 134px)" }}
      >
        <EmptyState
          image={emptyImage}
          title="Please click on any thread to access the details."
        />
      </div>
    );
  }

  return (
    <>
      <div>
        <div className="flex flex-row items-center justify-between px-6 py-5 border-b neeto-ui-border-gray-400">
          <Typography style="h4" weight="semibold">
            {threadDetail.subject}
          </Typography>
            <div className="flex items-center justify-end space-x-2">
              <Button
                icon={UserCircle}
                style="secondary"
                tooltipProps={{
                  content: "People who are notified",
                  position: "bottom",
                }}
                onClick={() => setShowRecipientsPane(true)}
              />
              {threadDetail.emailType !== "forward" &&
                <Button
                  icon={Share}
                  style="secondary"
                  tooltipProps={{
                    content: "Share",
                    position: "bottom",
                  }}
                  onClick={() => setShowShareThreadPane(true)}
                />
              }
            </div>
        </div>
        <div className="flex flex-col flex-grow">
          <div
            className="px-6 pb-5 space-y-5 overflow-y-auto divide-y divide-gray-400 mb-4"
            style={{
              height: threadDetail.draft || threadDetail.emailType === "forward"
                ? "calc(100vh - 200px)"
                : "calc(100vh - 480px)",
            }}
            id="message-list-container"
          >
            {threadDetail.messages.map((message, index) => {
              return (
                <InView
                  as="div"
                  key={index}
                  rootMargin="10px 20px 480px 0px"
                  threshold={0.2} // trigger callback when 100% of the element is visible
                  onChange={inView =>
                    inViewIdMessage(inView, message.unseen?.id)
                  }
                >
                  <Viewer
                    setRefs={setRefs}
                    message={message}
                    jobId={jobId}
                    loadMessageThreadsResponse={loadMessageThreadsResponse}
                    threadDetail={threadDetail}
                    setNewMessage={setNewMessage}
                    allRecipients={jobDetail.allRecipients}
                    formik={formik}
                    setDownloadLoad={setDownloadLoad}
                    setMessageBody={setMessageBody}
                    setAttachments={setAttachments}
                    setSelectedMessageId={setSelectedMessageId}
                    loadThreadDetailResponse={loadThreadDetailResponse}
                  />
                </InView>
              );
            })}
          </div>

          {!threadDetail.draft && threadDetail.emailType !== "forward" && (
            <div
              className="px-6 pb-5 overflow-y-auto"
              style={{ height: "270px" }}
            >
              <div className="flex w-full justify-end">
                <Button
                  style="link"
                  label="Add Attachments"
                  onClick={() => setAddAttachmentModal(true)}
                  className="mr-2 mb-2 underline"
                />
                <Button
                  style="link"
                  label="Expand"
                  onClick={() => setEditorModalOpen(true)}
                  className="mb-2 underline"
                />
              </div>

              {!editorModalOpen && (
                <ReactQuill
                  value={messageBody}
                  onChange={text => setMessageBody(text)}
                  modules={{
                    toolbar: QUILL_TOOLBAR_STYLE,
                    keyboard: QUILL_KEYBOARD_BINDINGS
                  }}
                  placeholder="Enter your message"
                  className="mb-3"
                />
              )}

              <AddAttachments
                setAttachmentRecord={setAttachmentRecord}
                addAttachmentModal={addAttachmentModal}
                setAddAttachmentModal={setAddAttachmentModal}
              />

              <div className="flex flex-col w-full">
                {attachments.map((attachment, index) => {
                  if (attachment._destroy === 1) return null;

                  return (
                    <Attachment
                      key={index}
                      index={index}
                      attachment={attachment}
                      setDeleteConfirmAlert={setDeleteConfirmAlert}
                      setDeletingAttachmentIndex={setDeletingAttachmentIndex}
                    />
                  );
                })}
              </div>

              <div className="flex items-center justify-end w-full space-x-2">
                <Button
                  label="Draft"
                  icon={Draft}
                  iconPosition="left"
                  onClick={() => createMessageResponse(true)}
                  loading={draftButtonLoader}
                />

                <Button
                  label="Send message"
                  icon={Send}
                  iconPosition="left"
                  className="ml-2"
                  loading={buttonLoader}
                  onClick={() => createMessageResponse(false)}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <RecipientsPane
        isOpen={showRecipientsPane}
        onClose={() => setShowRecipientsPane(false)}
        recipients={getRecipients()}
        jobId={jobId}
        threadId={threadId}
        jobDetail={jobDetail}
        forwardThread={threadDetail.emailType === "forward"}
        productionEmail={threadDetail.productionEmail}
        loadThreadDetailResponse={() => loadThreadDetailResponse()}
      />

      <ShareThreadPane
        isOpen={shareThreadPane}
        onClose={() => setShowShareThreadPane(false)}
        threadDetail={threadDetail}
        jobId={jobId}
        allRecipients={jobDetail.allRecipients}
        setShowRecipientsPane={setShowRecipientsPane}
        setActiveThred={setActiveThred}
        setThreadDetail={setThreadDetail}
        setCurrentTab={setCurrentTab}
      />

      <MessageModal
        value={messageBody}
        setValue={setMessageBody}
        editorModalOpen={editorModalOpen}
        setEditorModalOpen={setEditorModalOpen}
      />

      <Alert
        isOpen={deleteConfirmAlert}
        message="Are you sure you want to delete the attachment?"
        submitButtonLabel="Yes!"
        title="Delete Attachment"
        onClose={() => setDeleteConfirmAlert(false)}
        onSubmit={() => destroyAttachment()}
      />
    </>
  );
};

export default MessageList;

import axios from "axios";

export const getActionTags = (staffId, startDate, endDate) => {
  return axios.get(
    `/api/v1/staffs/staffs/${staffId}/action_tags?start_date=${startDate}&end_date=${endDate}`
  );
};

export const exportActionTags = (staffId, startDate, endDate, report_type) => {
  return axios.get(
    `/api/v1/staffs/staffs/${staffId}/action_tags/export?start_date=${startDate}&end_date=${endDate}&report_type=${report_type}`
  );
};

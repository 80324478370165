import React from "react";
import { Refresh } from "@bigbinary/neeto-icons";
import { Pane, Typography, Input, Select, Button } from "@bigbinary/neetoui";

const InvoiceFilter = ({
  filterPane,
  setFilterPane,
  statusFilter,
  setStatusFilter,
  setAmountFilter,
  amountFilter,
  inputRef,
}) => {
  return (
    <Pane
      isOpen={filterPane}
      onClose={() => setFilterPane(false)}
      initialFocusRef={inputRef}
    >
      <Pane.Header>
        <Typography style="h2" weight="semibold">
          Filter
        </Typography>
      </Pane.Header>
      <Pane.Body>
        <div className="w-full space-y-6">
          <Select
            label="Status"
            name="status"
            id="status"
            placeholder="Select a status"
            value={statusFilter}
            innerRef={inputRef}
            onChange={opt => setStatusFilter(opt)}
            options={[
              { label: "All", value: "all" },
              { label: "Unpaid", value: "unpaid" },
            ]}
          />

          <Input
            type="number"
            label="Amount"
            onChange={e => setAmountFilter(e.target.value)}
            value={amountFilter}
            name="amount"
            id="amount"
          />
        </div>
      </Pane.Body>
      <Pane.Footer className="space-x-2">
        <Button
          label="Reset"
          icon={Refresh}
          style="secondary"
          iconPosition="left"
          onClick={() => {
            setStatusFilter({ label: "", value: "" });
            setAmountFilter();
          }}
        />
        <Button
          style="text"
          label="Cancel"
          onClick={() => setFilterPane(false)}
        />
      </Pane.Footer>
    </Pane>
  );
};

export default InvoiceFilter;

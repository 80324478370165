import React, { useEffect, useState, useContext } from "react";
import { Button, Modal, Typography } from "@bigbinary/neetoui";
import { useFormik } from "formik";
import { showToastrError } from "common";
import {
  updateEstimateRevisionItem,
  destroyEstimateRevisionItem,
} from "apis/jobs/estimate_revision_items";
import { destroyRevisionLineItemCategory } from "apis/jobs/revision_line_item_category";

import { updateJob } from "apis/jobs/jobs";
import { EstimateContext } from "../index";
import SingleImageUploader from "components/Common/SingleImageUploader";
import EstimateAccordion from "./EstimateAccordion";
import DetailsFooter from "./DetailsFooter";
import {
  filterEstimateItems,
  filterEstimateListItems,
} from "../../../common/helper";

const EstimateDetails = ({
  uploadModal,
  setUploadModal,
  createInvoiceEntry,
  createOrRedirectToInvoice,
}) => {
  const {
    jobDetail,
    revisionId,
    approvedRevisionId,
    finalInvoice,
    commencementInvoice,
    estimateRevisionItemList,
    loadEstimateRevisionItemList,
    currentRevision,
    loadInvoiceList,
    loadRciList,
    rciList,
    loadEstimateRevisionList,
    revisionLineItemCatgoryList,
    openedParent,
    setOpenedParent,
    loadRevisionLineItemCategoryList,
    btnLoad,
  } = useContext(EstimateContext);

  const [artistList, setArtistList] = useState([]);
  const [openedItem, setOpenedItem] = useState();
  const [updateServiceFee, setUpdateServiceFee] = useState(false);
  const [thumbnailSaveLoad, setThumbnailSaveLoad] = useState(false);
  const estimateItems = filterEstimateItems(estimateRevisionItemList);
  const estimateListItems = filterEstimateListItems(estimateItems);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: jobDetail,
    onSubmit: () => onJobUpdate(),
  });

  useEffect(() => {
    if (jobDetail.id) {
      setArtistList(jobDetail.artists);
    }
  }, [jobDetail]);

  const saveEstimateRecord = async (payload, id) => {
    try {
      await updateEstimateRevisionItem(revisionId, id, {
        estimate_revision_item: payload,
      });
      await loadEstimateRevisionItemList();
      await setUpdateServiceFee(true);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      loadRciList();
      loadInvoiceList();
    }
  };

  const onJobUpdate = async () => {
    try {
      setThumbnailSaveLoad(true);
      let payload = {
        job: {
          thumbnail_attachment_attributes: formik.values.attachments_attributes,
        },
      };

      await updateJob(jobDetail.id, payload);
      setUploadModal(false);
      formik.resetForm();
      createInvoiceEntry(true);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      setThumbnailSaveLoad(false);
    }
  };

  const deleteItemClickFunction = async id => {
    try {
      await destroyEstimateRevisionItem(revisionId, id);
      await loadEstimateRevisionItemList();
      await setUpdateServiceFee(true);
    } catch (error) {
      loadInvoiceList();
      loadRciList();
      showToastrError(error.data.errors[0]);
    } finally {
      loadEstimateRevisionList();
    }
  };

  const destroyEstimateRevisionLineItemCategory = async id => {
    try {
      await destroyRevisionLineItemCategory(revisionId, id);
      loadRevisionLineItemCategoryList();
      loadEstimateRevisionItemList();
    } catch (error) {
      loadInvoiceList();
      loadRciList();
      showToastrError(error?.data?.errors[0]);
    } finally {
      loadEstimateRevisionList();
    }
  };

  const isDisable =
    commencementInvoice ||
    finalInvoice ||
    (approvedRevisionId && approvedRevisionId !== revisionId);

  return (
    <main>
      <>
        <div className="pb-6">
          <div className="flex w-full">
            <EstimateAccordion
              estimateListItems={estimateListItems}
              revisionLineItemCatgoryList={revisionLineItemCatgoryList}
              onAccordionClick={setOpenedItem}
              jobDetail={jobDetail}
              deleteItemClickFunction={deleteItemClickFunction}
              artistList={artistList}
              saveEstimateRecord={saveEstimateRecord}
              openedItem={openedItem}
              isDisable={isDisable}
              rciList={rciList}
              setOpenedParent={setOpenedParent}
              openedParent={openedParent}
              revisionId={revisionId}
              loadRevisionLineItemCategoryList={
                loadRevisionLineItemCategoryList
              }
              loadEstimateRevisionItemList={loadEstimateRevisionItemList}
              destroyEstimateRevisionLineItemCategory={
                destroyEstimateRevisionLineItemCategory
              }
            />
          </div>
          <DetailsFooter
            jobDetail={jobDetail}
            revisionId={revisionId}
            currentRevision={currentRevision}
            isDisable={isDisable}
            isAsfDisable={isDisable}
            estimateItems={estimateItems}
            loadEstimateRevisionItemList={loadEstimateRevisionItemList}
            updateServiceFee={updateServiceFee}
            setUpdateServiceFee={setUpdateServiceFee}
            revisionLineItemCatgoryList={revisionLineItemCatgoryList}
          />
        </div>
      </>

      <Modal isOpen={uploadModal} onClose={() => setUploadModal(false)}>
        <Modal.Header>
          <Typography style="h2" weight="semibold">
            Please upload a thumbnail for the job to show in License.
          </Typography>
        </Modal.Header>

        <Modal.Body>
          <SingleImageUploader
            name="Thumbnail"
            type="thumbnail"
            formValue={formik.values}
            setFormValue={formik.setFieldValue}
          />
        </Modal.Body>
        <Modal.Footer className="space-x-2">
          <Button
            label="Save Changes"
            loading={thumbnailSaveLoad}
            onClick={() => formik.handleSubmit()}
          />
          <Button
            style="text"
            label="Cancel"
            loading={btnLoad}
            onClick={() => createOrRedirectToInvoice()}
          />
        </Modal.Footer>
      </Modal>
    </main>
  );
};

export default EstimateDetails;

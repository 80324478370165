import axios from "axios";

const getLocationsUrl = id => {
  if (id) {
    return `/api/v1/settings/locations/${id}`;
  }

  return `/api/v1/settings/locations`;
};

export const getLocations = (searchParams = "") => {
  let query = "";
  if (searchParams.length > 0)
    query = `q[name_i_cont_any]=${searchParams}&q[short_name_i_cont_any]=${searchParams}&q[currency_name_i_cont_any]=${searchParams}&q[tag_i_cont_any]=${searchParams}&q[m]=or`;
  return axios.get(`${getLocationsUrl()}?${query}`);
};

export const createLocation = payload => {
  return axios.post(getLocationsUrl(), payload);
};

export const updateLocation = (id, payload) => {
  return axios.put(getLocationsUrl(id), payload);
};

export const destroyLocation = id => {
  return axios.delete(getLocationsUrl(id));
};

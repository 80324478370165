import * as Yup from "yup";

export const CRM_ACTION_TAG_VALIDATION_SCHEMA = Yup.object({
  name: Yup.string().required("Name can't be empty"),
  status: Yup.string().required("Status can't be empty"),
});

export const CRM_ACTION_TAG_INITIAL_VALUE = {
  name: "",
  status: "",
};

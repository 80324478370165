import React, { useState } from "react";

const ShowNote = ({ note }) => {
  const [showNote, setShowNote] = useState(false);

  return (
    <div className="w-full">
      {showNote ? (
        <div
          className="text-sm text-gray-900"
          dangerouslySetInnerHTML={{
            __html: note?.split("\n").join("<br>"),
          }}
        />
      ) : (
        note.substring(0, 120) + "..."
      )}
      <div
        className="text-blue-500 underline cursor-pointer"
        onClick={() => setShowNote(!showNote)}
      >
        {showNote ? "Less" : "More"}
      </div>
    </div>
  );
};

export default ShowNote;

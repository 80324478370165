import React, { useState, useEffect, useContext } from "react";
import { Select, Checkbox, Input as NeetouiInput } from "@bigbinary/neetoui";
import { Input } from "@bigbinary/neetoui/formik";
import { createMetric, getMetrics } from "apis/settings/metrics";
import { Field, Form, Formik } from "formik";
import { FieldArray } from "formik";
import { useParams } from "react-router-dom";
import { numberWithCommas } from "common/helper";
import { showToastrError } from "common";
import classNames from "classnames";

import { EstimateContext } from "../../../Context";
import { EstimateContext as EstimateDetailsContext } from "../../index";
import {
  ESTIMATEFORMVALIDATION,
  ESTIMATE_COMMISSION_TYPE,
  ESTIMATE_INITIAL_VALUE,
  INTIAL_TOUCH,
} from "../../constants";

import MarkupComponent from "./MarkupType";
import CommissionComponent from "./CommissionComponent";
import MarkupFooter from "./MarkupType/MarkupFooter";
import CommissionFooter from "./CommissionComponent/CommissionFooter";
import ArtistBreakDown from "./ArtistBreakDown";
import { dropDownListGenerator } from "common/helper";
import {
  agencyCommissionValue,
  calculateMarkupRate,
  calculteMarkupPercentage,
  checkStatus,
  findIntersection,
  revisionItemTotal,
  toArtistFormFormat,
  totalMarkupValueForAgency,
  totalMarkupValueForArtist,
} from "../../utils";

const EstimateForm = ({
  itemObject,
  index,
  artistList,
  saveEstimateRecord,
  itemArray,
  jobDetail,
  rciAttached,
}) => {
  const { revisionId } = useParams();
  const [metricList, setMetricList] = useState([]);
  const [metricListLoad, setMetricListLoad] = useState(true);

  useEffect(() => {
    loadMetricListResponse();
  }, []);

  const innerRef = useContext(EstimateContext);

  const { approvedRevisionId, finalInvoice, commencementInvoice } = useContext(
    EstimateDetailsContext
  );

  const getArtistName = id => {
    return artistList.find(artist => artist.id === id).name;
  };

  const findMetric = id => {
    return metricList.find(m => m.id === id);
  };

  const markupSplitUp = (markup, index, values) => {
    return (
      <div
        className="flex justify-between pb-2 mt-2 font-medium border-b border-gray-200"
        key={index}
      >
        <p>
          {markup.artistExpense
            ? `${markup.artistName.label} Expense`
            : markup.artistName.label}
        </p>
        <p>{`${numberWithCommas(totalMarkupValueForArtist(markup, values))} ${
          jobDetail.currency
        }`}</p>
      </div>
    );
  };

  const handleFormSubmit = values => {
    let differenceArtistArray = findIntersection(
      itemObject.artistMarkupPercentages,
      values.Markup.artist
    );

    differenceArtistArray = differenceArtistArray.map(item => {
      return { ...toArtistFormFormat(item), _destroy: 1 };
    });

    let artistarray = [...values.Markup.artist, ...differenceArtistArray];

    const artist_markup_percentages_attributes = artistarray.map(artist => {
      return {
        artistId: artist.artistName.value,
        markupPercentage: artist.percentage,
        rate: artist.artistFee,
        estimateRevisionItemId: artist.estimateRevisionItemId,
        id: artist.id,
        advance: artist.advance,
        artistExpense: artist.artistExpense,
        _destroy: artist?._destroy,
      };
    });

    const payload = {
      name: itemArray.name,
      quantity: values.Quantity,
      estimateLineItemId: itemArray[values.serial].estimateLineItemId,
      rate: values.Rate,
      commission_type: values.Type.value,
      metricId: values.Metrics.value,
      metricName: values.Metrics.label,
      commission: +values.CommissionPercentage,
      artistId: values.Assigned?.value || null,
      note: values.notes,
      internalNote: values.internalNote,
      newItem: false,
      rateTbc: values.rateTbc,
      rateNa: values.rateNa,
      markup_percentage: +values.Markup.percentage,
      status: checkStatus(values, artist_markup_percentages_attributes),
      artist_markup_percentages_attributes: artist_markup_percentages_attributes,
      id: itemArray[values.serial].id,
    };

    saveEstimateRecord(payload, itemObject.id, values.serial);
  };

  const initialValues = () => {
    if (!itemObject.id) {
      return { ...ESTIMATE_INITIAL_VALUE, serial: index };
    } else {
      return {
        Quantity: itemObject.quantity,
        rateTbc: itemObject.rateTbc,
        rateNa: itemObject.rateNa,
        newItem: itemObject.newItem,
        Metrics: {
          label: findMetric(itemObject.metricId)?.name,
          value: itemObject.metricId,
        },
        Rate: itemObject.rate,
        Type: {
          value: itemObject.commissionType,
          label: itemObject?.commissionType?.replace(/(^\w|\s\w)/g, m =>
            m.toUpperCase()
          ),
        },
        Assigned: {
          label: itemObject.artistId && getArtistName(itemObject.artistId),
          value: itemObject.artistId,
        },
        CommissionPercentage:
          itemObject.commission || jobDetail.jobType.CommissionPercentage,
        Markup: {
          percentage:
            itemObject.feeType === "usage" ? 0 : itemObject.markupPercentage,
          rate: calculateMarkupRate(
            itemObject.rate * itemObject.quantity,
            itemObject.markupPercentage
          ),
          customRate: itemObject.customRate,
          artist: itemObject.artistMarkupPercentages.map(artist =>
            toArtistFormFormat(
              artist,
              calculateMarkupRate(
                itemObject.rate * itemObject.quantity,
                itemObject.markupPercentage
              )
            )
          ),
        },
        serial: index,
        notes: itemObject.note,
        internalNote: itemObject.internalNote,
      };
    }
  };
  const currency = jobDetail.currency;

  const bgColor = () => {
    (approvedRevisionId && approvedRevisionId !== revisionId) ||
    commencementInvoice ||
    finalInvoice
      ? "bg-gray-100"
      : "bg-white";
  };

  const onQuantityChange = (e, setFieldValue, values) => {
    {
      let totalRate = values.Rate * e.target.value;
      setFieldValue("Quantity", e.target.value);
      setFieldValue(
        "Markup.rate",
        calculateMarkupRate(totalRate, values.Markup.percentage)
      );
    }
  };

  const hendleMetricCreateResponse = async (form, value) => {
    try {
      await createMetric({ metric: { name: value } });
      loadMetricListResponse();
    } catch (error) {
      showToastrError(error?.data?.errors[0]);
    }
  };

  const loadMetricListResponse = async () => {
    try {
      setMetricListLoad(true);
      const response = await getMetrics();
      setMetricList(response.data.metrics);
      setMetricListLoad(false);
    } catch (error) {
      showToastrError(error?.data?.errors[0]);
    }
  };

  const onRateChange = (e, setFieldValue, values) => {
    let totalRate = e.target.value * values.Quantity;
    let calculatedMarkupRate = calculateMarkupRate(
      totalRate,
      values.Markup.percentage
    );

    setFieldValue("Rate", e.target.value);
    setFieldValue("Markup.rate", calculatedMarkupRate);
    setFieldValue(
      "Markup.customRate",
      (Number(calculatedMarkupRate) / Number(values.Quantity)).toFixed(2)
    );
  };

  const onMarkupPercentageChange = (e, setFieldValue, values) => {
    let totalRate = values.Rate * values.Quantity;
    let calculatedMarkupRate = calculateMarkupRate(totalRate, e.target.value);

    setFieldValue("Markup.percentage", e.target.value);
    setFieldValue("Markup.rate", calculatedMarkupRate);
    setFieldValue(
      "Markup.customRate",
      (Number(calculatedMarkupRate) / Number(values.Quantity)).toFixed(2)
    );
  };

  const onMarkupTotalChange = (e, setFieldValue, values) => {
    setFieldValue("Markup.rate", e.target.value);
    setFieldValue(
      "Markup.percentage",
      calculteMarkupPercentage(e.target.value, values.Rate * values.Quantity)
    );
    setFieldValue(
      "Markup.customRate",
      (Number(e.target.value) / Number(values.Quantity)).toFixed(2)
    );

    values.Markup.artist.map((item, index) => {
      setFieldValue(
        `Markup.artist[${index}].rate`,
        ((parseFloat(values.Markup.rate) * item.percentage) / 100).toFixed(2)
      );
    });
  };

  const isQuanityMetricRateDisable = () => {
    return (
      itemObject.feeType === "usage" ||
      commencementInvoice ||
      finalInvoice ||
      (approvedRevisionId && approvedRevisionId !== revisionId) ||
      rciAttached
    );
  };

  const isMarkupDisable = () => {
    return (
      commencementInvoice ||
      finalInvoice ||
      (approvedRevisionId && approvedRevisionId !== revisionId) ||
      rciAttached
    );
  };

  return (
    <div className="space-y-4">
      <Formik
        initialValues={initialValues()}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize={true}
        isDisable={isMarkupDisable}
        validationSchema={ESTIMATEFORMVALIDATION}
        innerRef={innerRef}
        initialTouched={INTIAL_TOUCH}
        onSubmit={values => {
          handleFormSubmit(values);
        }}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form
              className={classNames(["w-full px-4 py-4 space-y-4"], bgColor)}
            >
              <div className="grid grid-cols-3 gap-4 flex items-start">
                <div className="col-span-1">
                  <Input
                    autoFocus
                    label="Quantity"
                    name="Quantity"
                    type="number"
                    disabled={isQuanityMetricRateDisable()}
                    onChange={e => onQuantityChange(e, setFieldValue, values)}
                  />
                </div>
                <div className="col-span-1">
                  <Field name="Metrics">
                    {({ field, form }) => {
                      return (
                        <Select
                          isCreateable
                          isLoading={metricListLoad}
                          options={dropDownListGenerator(metricList)}
                          {...field}
                          onChange={value => {
                            form.setFieldValue("Metrics", value);
                          }}
                          blurInputOnSelect={true}
                          label="Metrics"
                          defaultOptions={{ label: "", value: "" }}
                          isDisabled={
                            isQuanityMetricRateDisable() || metricListLoad
                          }
                          error={
                            form.touched.Metrics && form.errors.Metrics?.value
                          }
                          onCreateOption={value =>
                            hendleMetricCreateResponse(form, value)
                          }
                        />
                      );
                    }}
                  </Field>
                </div>
                <div className="flex items-start col-span-1 space-x-3">
                  {values.rateTbc || values.rateNa ? (
                    <NeetouiInput
                      className="w-20"
                      label="Rate"
                      value={values.rateTbc ? "TBC" : values.rateNa ? "NA" : ""}
                    />
                  ) : (
                    <Input
                      label="Rate"
                      name="Rate"
                      type="number"
                      className="w-20"
                      disabled={isQuanityMetricRateDisable()}
                      onChange={e => onRateChange(e, setFieldValue, values)}
                    />
                  )}

                  {values?.Type?.value === "commission" && (
                    <div className="flex my-2 space-x-2 items-center mt-7">
                      <Checkbox
                        label="TBC"
                        checked={values.rateTbc}
                        onClick={() =>
                          setFieldValue("rateTbc", !values.rateTbc)
                        }
                        disabled={isQuanityMetricRateDisable() || values.rateNa}
                      />

                      <Checkbox
                        label="NA"
                        checked={values.rateNa}
                        onClick={() => setFieldValue("rateNa", !values.rateNa)}
                        disabled={
                          isQuanityMetricRateDisable() || values.rateTbc
                        }
                      />
                    </div>
                  )}

                  {values?.Type?.value === "markup" && (
                    <Input
                      label="Markup Rate"
                      type="number"
                      name="Markup.customRate"
                      className="w-20"
                      disabled={true}
                    />
                  )}
                </div>
              </div>
              <div className="grid grid-cols-3 gap-4">
                <Field name="Type">
                  {({ field, form, meta }) => {
                    return (
                      <>
                        <Select
                          options={ESTIMATE_COMMISSION_TYPE}
                          {...field}
                          onChange={e => {
                            form.setFieldValue("Type", e);
                            form.setFieldValue("Assigned", {
                              label: "",
                              value: "",
                            });
                          }}
                          blurInputOnSelect
                          label="Commission Type"
                          defaultOptions={{ label: "", value: "" }}
                          isDisabled={isMarkupDisable()}
                          error={meta.touched && meta.error?.label}
                        />
                      </>
                    );
                  }}
                </Field>
                {values?.Type?.value === "markup" ? (
                  <MarkupComponent
                    isMarkupDisable={isMarkupDisable}
                    usageItemDisability={itemObject.feeType === "usage"}
                    onMarkupPercentageChange={e =>
                      onMarkupPercentageChange(e, setFieldValue, values)
                    }
                    onMarkupTotalChange={e =>
                      onMarkupTotalChange(e, setFieldValue, values)
                    }
                    currency={currency}
                  />
                ) : (
                  <CommissionComponent
                    isMarkupDisable={isMarkupDisable}
                    artistOptions={dropDownListGenerator(artistList)}
                    setFieldValue={setFieldValue}
                    value={values.Assigned}
                  />
                )}
              </div>
              {values.Type.value === "markup" && (
                <FieldArray
                  name="Markup.artist"
                  render={props => (
                    <ArtistBreakDown
                      disabled={isMarkupDisable()}
                      currency={currency}
                      markupRate={values.Markup.rate}
                      artistOptions={dropDownListGenerator(artistList)}
                      {...props}
                    />
                  )}
                />
              )}
              <div className="grid grid-cols-3 gap-4">
                <Input
                  label="Notes"
                  name="notes"
                  className="col-span-3"
                  disabled={isMarkupDisable()}
                />
              </div>
              <div className="grid grid-cols-3 gap-4">
                <Input
                  label="Internal Notes"
                  name="internalNote"
                  className="col-span-3"
                  disabled={isMarkupDisable()}
                />
              </div>
              {values?.Type?.value === "markup" ? (
                <MarkupFooter
                  currency={currency}
                  markupSplitUp={markupSplitUp}
                  revisionItemTotal={revisionItemTotal}
                  totalMarkupValueForAgency={totalMarkupValueForAgency}
                  values={values}
                />
              ) : (
                <CommissionFooter
                  agencyCommissionValue={agencyCommissionValue}
                  jobDetail={jobDetail}
                  revisionItemTotal={revisionItemTotal}
                  values={values}
                />
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default EstimateForm;

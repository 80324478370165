import React from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import { buildUrl } from "../utils";
import routes from "../routes";
import { Header, Container } from "@bigbinary/neetoui/layouts";
import { TEMPLATE_SETTINGS_LIST } from "../constants";

import Templates from "./Templates";
import SpecialJobConditions from "./SpecialJobConditions";
import TermsAndConditions from "./TermsAndConditions";
import ChatTemplates from "./ChatTemplates";

import SettingsTile from "../SettingsTile";

const TemplateSetting = ({ title, tab }) => {
  const { subTab } = useParams();
  const breadcrumbs = [
    { text: title, link: buildUrl(routes.settings.show, { tab }) },
  ];

  return (
    <>
      {subTab ? (
        <Switch>
          <Route
            path={routes.settings.jobTemplates}
            render={() => <Templates breadcrumbs={breadcrumbs} />}
          />
          <Route
            path={routes.settings.chatTemplates}
            render={() => <ChatTemplates breadcrumbs={breadcrumbs} />}
          />
          <Route
            path={routes.settings.termsAndConditions}
            render={() => <TermsAndConditions breadcrumbs={breadcrumbs} />}
          />
          <Route
            path={routes.settings.specialJobConditions}
            render={() => <SpecialJobConditions breadcrumbs={breadcrumbs} />}
          />
          <Redirect to={buildUrl(routes.settings.show, { tab })} />
        </Switch>
      ) : (
        <Container>
          <Header title={title} />

          <div className="grid grid-cols-2 gap-3 lg:grid-cols-3 lg:gap-6 xl:grid-cols-4 2xl:grid-cols-4">
            {TEMPLATE_SETTINGS_LIST.map(item => (
              <SettingsTile
                {...item}
                key={item.value}
                path={buildUrl(item.path, { tab })}
              />
            ))}
          </div>
        </Container>
      )}
    </>
  );
};

export default TemplateSetting;

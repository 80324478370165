import React, { useState, useEffect } from "react";
import { Delete, Reorder } from "@bigbinary/neeto-icons";
import { Spinner, Alert, Button, Input } from "@bigbinary/neetoui";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import produce from "immer";
import { showToastrError } from "common";
import { updateEstimateRevision } from "apis/jobs/estimate_revisions";
import { updateTimingPosition, destroyTiming } from "apis/jobs/timings";

const Timing = ({
  jobDetail,
  revisionDetail,
  timingList,
  timingLoading,
  loadRevisionTiming,
  setTimingLoading,
}) => {
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [timingId, setTimingId] = useState();
  const [timingIndex, setTimingIndex] = useState();
  const [timingBtnLoading, setTimingBtnLoading] = useState(false);
  const [list, setList] = useState([{ note: "" }]);

  useEffect(() => {
    if (timingList) {
      setList(timingList || [{ note: "" }]);
    }
  }, [timingList]);

  const placeDifference = result => {
    let sourceIndex = result.source.index;
    let destinationIndex = result.destination.index;
    let placeChangeType = "";
    let difference = 0;

    if (sourceIndex > destinationIndex) {
      placeChangeType = "up";
      difference = sourceIndex - destinationIndex;
    } else {
      placeChangeType = "down";
      difference = destinationIndex - sourceIndex;
    }

    return { placeChangeType: placeChangeType, difference: difference };
  };

  const timingItemReorderFunction = result => {
    if (!result.destination) {
      return;
    }
    setList(
      produce(draft => {
        const [removed] = draft.splice(result.source.index, 1);
        draft.splice(result.destination.index, 0, removed);
      })
    );

    const { placeChangeType, difference } = placeDifference(result);

    updateTimingPosition(
      revisionDetail.id,
      result.draggableId,
      placeChangeType,
      difference
    );
  };

  const destroyTimingItem = async () => {
    try {
      if (timingId) {
        await destroyTiming(revisionDetail.id, timingId);
      } else {
        setList(state => {
          let value = [...state];
          value.splice(timingIndex, 1);
          return value;
        });
      }

      setDeleteAlertOpen(false);
      loadRevisionTiming();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const updateJobWithTiming = async () => {
    try {
      setTimingBtnLoading(true);
      let payload = { estimate_revision: { timings_attributes: list } };
      await updateEstimateRevision(jobDetail.id, revisionDetail.id, payload);
      setTimingLoading(false);
      loadRevisionTiming();
      setTimingBtnLoading(false);
    } catch (error) {
      setTimingBtnLoading(false);
      showToastrError(error.data.errors[0]);
    }
  };

  if (timingLoading) {
    return (
      <div className="flex items-center justify-center w-full h-20">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="w-full">
      <DragDropContext onDragEnd={timingItemReorderFunction}>
        <Droppable droppableId="droppable">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {list?.map((item, index) => {
                return (
                  <Draggable
                    key={item.id?.toString()}
                    draggableId={item.id?.toString()}
                    index={index}
                  >
                    {provided => (
                      <div
                        className="flex items-center justify-between mt-2 space-x-1"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        <div {...provided.dragHandleProps}>
                          <Reorder size={20} color="#87929d" />
                        </div>
                        <Input
                          value={item.note}
                          size="large"
                          onChange={e => {
                            setList(
                              produce(draft => {
                                draft[index] = {
                                  ...draft[index],
                                  note: e.target.value,
                                };
                              })
                            );
                          }}
                          suffix={
                            <Button
                              style="text"
                              size="small"
                              icon={Delete}
                              disabled={list.length === 1}
                              onClick={() => {
                                setTimingId(item.id);
                                setTimingIndex(index);
                                setDeleteAlertOpen(true);
                              }}
                            />
                          }
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className="flex justify-end mt-2">
        <Button
          style="link"
          label="Add Timing"
          onClick={() => {
            setList(state => {
              return [
                ...state,
                {
                  note: "",
                },
              ];
            });
          }}
        />
      </div>

      <div className="flex items-center justify-end mt-3">
        <Button
          label="Save changes"
          onClick={() => updateJobWithTiming()}
          loading={timingBtnLoading}
        />
      </div>

      <Alert
        isOpen={deleteAlertOpen}
        title="Delete Timing"
        message="Are you sure you want to delete this timing?"
        onClose={() => setDeleteAlertOpen(false)}
        onSubmit={() => destroyTimingItem()}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, delete"
      />
    </div>
  );
};

export default Timing;

import axios from "axios";

const getTaxTypesUrl = id => {
  if (id) {
    return `/api/v1/settings/tax_types/${id}`;
  }

  return `/api/v1/settings/tax_types`;
};

export const getTaxTypes = (searchParams = "") => {
  let query = "";
  if (searchParams.length > 0)
    query = `q[name_i_cont_any]=${searchParams}&q[currency_name_i_cont_any]=${searchParams}&q[m]=or`;
  return axios.get(`${getTaxTypesUrl()}?${query}`);
};

export const createTaxType = payload => {
  return axios.post(getTaxTypesUrl(), payload);
};

export const updateTaxType = (id, payload) => {
  return axios.put(getTaxTypesUrl(id), payload);
};

export const destroyTaxType = id => {
  return axios.delete(getTaxTypesUrl(id));
};

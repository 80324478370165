import React, { useState, useEffect } from "react";
import {
  Input,
  Label,
  Select,
  Switch,
  Typography,
  Modal,
  Button,
} from "@bigbinary/neetoui";
import ContactUpload from "components/Common/ContactUpload";
import SingleImageUploader from "components/Common/SingleImageUploader";
import { ACCESS_LIST } from "./constants";
import { createLocation, getLocations } from "apis/super_admins/locations";
import { createCurrency, getCurrencies } from "apis/super_admins/currencies";
import { showToastrError } from "common";
import { dropDownListGenerator } from "common/helper";
import { XERO_TAX_FORMATS } from "./constants";

const StaffForm = ({ formik, accountDetail }) => {
  const [locationList, setLocationList] = useState([]);
  const [locationListLoad, setLocationListLoad] = useState(true);
  const [currencyList, setCurrencyList] = useState([]);
  const [currencyListLoad, setCurrencyListLoad] = useState(true);
  const [locationModalStateOpen, setLocationModalStateOpen] = useState(false);
  const [locationName, setLocationName] = useState("");
  const [locationShortName, setLocationShortName] = useState("");
  const [locationTag, setLocationTag] = useState("");
  const [locationCurrencyId, setLocationCurrencyId] = useState("");

  useEffect(() => {
    loadLocationList();
    loadCurrencyList();
  }, []);

  const createLocationResponse = async () => {
    try {
      const { data } = await createLocation(accountDetail.id, {
        location: {
          name: locationName,
          shortName: locationShortName,
          currencyId: locationCurrencyId,
          tag: locationTag,
        },
      });
      formik.setFieldValue("locationId", data.id);
      setLocationModalStateOpen(false);
      loadLocationList();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadLocationList = async () => {
    try {
      setLocationListLoad(true);
      const response = await getLocations(accountDetail.id);
      setLocationList(
        dropDownListGenerator(response.data.locations, "shortName")
      );
      setLocationListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const createCurrencyResponse = async currencyName => {
    try {
      const { data } = await createCurrency(accountDetail.id, {
        currency: { name: currencyName },
      });
      setLocationCurrencyId(data.id);
      loadCurrencyList();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadCurrencyList = async () => {
    try {
      setCurrencyListLoad(true);
      const response = await getCurrencies(accountDetail.id);
      setCurrencyList(dropDownListGenerator(response.data.currencies, "name"));
      setCurrencyListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  return (
    <div className="w-full pb-8 space-y-6">
      <Input
        label="First Name"
        name="firstName"
        value={formik.values.firstName}
        onChange={formik.handleChange}
        required={true}
        error={
          Boolean(formik.touched.firstName && formik.errors.firstName) &&
          formik.errors.firstName
        }
        {...formik.getFieldProps("firstName")}
      />
      <Input
        label="Last Name"
        name="lastName"
        value={formik.values.lastName}
        onChange={formik.handleChange}
        required={true}
        error={
          Boolean(formik.touched.lastName && formik.errors.lastName) &&
          formik.errors.lastName
        }
        {...formik.getFieldProps("lastName")}
      />
      <Input
        label="Pronouns"
        name="pronouns"
        value={formik.values.pronouns}
        onChange={formik.handleChange}
        error={
          Boolean(formik.touched.pronouns && formik.errors.pronouns) &&
          formik.errors.pronouns
        }
        {...formik.getFieldProps("pronouns")}
      />
      <Input
        label="Email"
        name="email"
        value={formik.values.email}
        onChange={formik.handleChange}
        required={true}
        error={
          Boolean(formik.touched.email && formik.errors.email) &&
          formik.errors.email
        }
        {...formik.getFieldProps("email")}
      />
      <Input
        label="Title"
        required={true}
        name="title"
        value={formik.values.title}
        onChange={formik.handleChange}
        error={
          Boolean(formik.touched.title && formik.errors.title) &&
          formik.errors.title
        }
        {...formik.getFieldProps("title")}
      />
      <Input
        label="Phone Number"
        name="phoneNumber"
        value={formik.values.phoneNumber}
        onChange={formik.handleChange}
        error={
          Boolean(formik.touched.phoneNumber && formik.errors.phoneNumber) &&
          formik.errors.phoneNumber
        }
        {...formik.getFieldProps("phoneNumber")}
      />

      <Select
        name="access"
        label="Access"
        required={true}
        onChange={e => {
          formik.setFieldValue("access", e.value);
        }}
        value={ACCESS_LIST.find(
          access => access.value === formik.values.access
        )}
        error={
          Boolean(formik.touched.access && formik.errors.access) &&
          formik.errors.access
        }
        options={ACCESS_LIST}
      />

      <Select
        isClearable
        isCreateable
        label="Location"
        required={true}
        isLoading={locationListLoad}
        onCreateOption={async value => {
          setLocationModalStateOpen(true);
          setLocationName(value);
        }}
        options={locationList}
        value={
          locationList.find(
            location => location.value === formik.values.locationId
          ) || null
        }
        onChange={async opt => {
          await formik.setFieldValue("locationId", opt.value);
          await formik.setFieldTouched("locationId", true);
        }}
        name="locationId"
        id="locationId"
        placeholder="Select a Location"
        error={
          Boolean(formik.touched.locationId && formik.errors.locationId) &&
          formik.errors.locationId
        }
      />

      <div className="flex flex-col space-y-2">
        <Label>Welcome Email</Label>
        <SingleImageUploader
          type="welcome_email"
          formValue={formik.values}
          setFormValue={formik.setFieldValue}
        />
      </div>

      <div className="flex flex-col space-y-2">
        <Label>Headshot</Label>
        <SingleImageUploader
          type="hero"
          formValue={formik.values}
          setFormValue={formik.setFieldValue}
        />
      </div>

      <div className="flex flex-col space-y-2">
        <Label>Signature(.png only)</Label>
        <SingleImageUploader
          type="signature"
          formValue={formik.values}
          setFormValue={formik.setFieldValue}
          accept="image/png"
        />
      </div>
      <div className="flex flex-col space-y-2">
        <Label>Contact VCF Cards</Label>
        <div>
          <ContactUpload
            formValue={formik.values}
            setFormValue={formik.setFieldValue}
          />
        </div>
      </div>

      <div className="flex flex-col space-y-4">
        <Typography style="h5" className="font-semibold">
          Job Approvals
        </Typography>
        <Switch
          label="Management 1"
          checked={formik.values.management1}
          onChange={() => {
            formik.setFieldValue("management1", !formik.values.management1);
          }}
        />
        <Switch
          label="Management 2"
          checked={formik.values.management2}
          onChange={() => {
            formik.setFieldValue("management2", !formik.values.management2);
          }}
        />
      </div>

      <div className="flex flex-col space-y-4">
        <Typography style="h5" className="font-semibold">
          State
        </Typography>
        <Switch
          label="Archive"
          checked={formik.values.archive}
          onChange={() => {
            formik.setFieldValue("archive", !formik.values.archive);
          }}
        />
      </div>

      <Modal
        isOpen={locationModalStateOpen}
        onClose={() => setLocationModalStateOpen(false)}
      >
        <Modal.Header>
          <Typography style="h2" className="font-semibold">
            Add Location
          </Typography>
        </Modal.Header>
        <Modal.Body>
          <div className="flex flex-col space-y-6">
            <Input
              label="Name"
              name="name"
              required={true}
              value={locationName}
              onChange={e => setLocationName(e.target.value)}
            />

            <Input
              label="Short Name"
              name="shortName"
              required={true}
              value={locationShortName}
              onChange={e => setLocationShortName(e.target.value)}
            />

            <Select
              isCreateable
              name="Currency"
              id="currencyId"
              label="Currency"
              placeholder="Select a Currency"
              isLoading={currencyListLoad}
              onCreateOption={createCurrencyResponse}
              options={currencyList}
              value={currencyList.find(obj => obj.value === locationCurrencyId)}
              error={
                Boolean(
                  formik.touched.currencyId && formik.errors.currencyId
                ) && formik.errors.currencyId
              }
              onChange={opt => {
                setLocationCurrencyId(opt.value);
              }}
            />

            <Select
              label="Xero Tax Format"
              name="tag"
              id="tag"
              placeholder="Select a Xero Tax Format"
              options={XERO_TAX_FORMATS}
              value={XERO_TAX_FORMATS.find(
                format => format.value === locationTag
              )}
              required={true}
              onChange={opt => {
                setLocationTag(opt.value);
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="space-x-2">
          <Button
            label="Save Changes"
            onClick={() => createLocationResponse()}
          />
          <Button
            label="Cancel"
            style="text"
            onClick={() => setLocationModalStateOpen(false)}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default StaffForm;

import * as R from "ramda";

/*eslint-disable */
const EMAIL_VALIDATION_REGEX = /^([^@\s]+)@((?:[-a-z0-9A-Z]+\.)+[a-z]{2,})$/;

const FRONTEND_URL_REGEX = /^([a-zA-Z0-9]+(\.[a-zA-Z0-9]+)+.*)$/;
/*eslint-enable */

const MIN_PASSWORD_LENGTH = 6;
const MAX_PASSWORD_LENGTH = 8;

Number.prototype.between = function (a, b) {
  var min = Math.min(a, b),
    max = Math.max(a, b);

  return this >= min && this <= max;
};

export const mustBeEmail = (value, required) => {
  if (value && !EMAIL_VALIDATION_REGEX.test(value)) {
    return "Invalid email";
  } else if (required && !value) {
    return "Can't be empty";
  } else {
    return "";
  }
};

export const mustBeURL = (value, required) => {
  if (value && !FRONTEND_URL_REGEX.test(value)) {
    return "Invalid url";
  } else if (required && !value) {
    return "Can't be empty";
  } else {
    return "";
  }
};

export const cantBeEmpty = (value, required) =>
  (value && R.trim(value) !== "") || !required ? "" : "Can't be empty";

export const mustBeSame = (password, passwordConfirmation) =>
  password && passwordConfirmation && R.trim(passwordConfirmation) !== password
    ? "Must be same with password"
    : "";

export const isInputValid = error =>
  Object.values(error).every(error => error.length === 0);

export const isInputPasswordValid = password =>
  R.trim(password) !== ""
    ? password.length.between(MIN_PASSWORD_LENGTH, MAX_PASSWORD_LENGTH)
      ? ""
      : "Password length should be in between 6 to 8"
    : "Can't be empty";

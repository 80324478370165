import React from "react";
import { Footer, Button } from "../../Shared";

const Approval = ({
  jobDetail,
  illustratorAuthorLink,
  illustratorAuthorName,
  companyDetails,
  footerLogo,
  sendApprovalMailToProduction,
  sendApprovalQuestionMailToProduction,
  emailBackground,
}) => {
  return (
    <div className="flex flex-col items-start justify-between w-full pt-12 pb-24 lg:flex-row">
      <div
        className="flex flex-col items-start justify-start w-full h-full lg:w-1/3"
        style={{ fontFamily: "Modern Gothic" }}
      >
        <div className="flex flex-col items-start justify-end h-full">
          <div className="flex-col items-start justify-end hidden space-y-6 lg:flex">
            <Footer
              jobDetail={jobDetail}
              companyDetails={companyDetails}
              footerLogo={footerLogo}
              illustratorAuthorName={illustratorAuthorName}
              illustratorAuthorLink={illustratorAuthorLink}
            />
          </div>
        </div>
      </div>
      <div
        className="flex flex-col items-start justify-start w-full h-full lg:w-2/3"
        style={{ fontFamily: "Modern Gothic" }}
      >
        <div className="flex flex-col items-center justify-center w-full pb-12">
          <span className="mb-12" dangerouslySetInnerHTML={{ __html: emailBackground?.soundGoodDetails?.split("\n")?.join("<br />")}}>
          </span>
          <div className="flex flex-col w-full gap-6 lg:grid lg:grid-cols-2">
            <Button
              className="w-full px-12 py-6 text-2xl font-medium text-center text-black rounded_container"
              style={{ backgroundColor: "#FCB613" }}
              onClick={() => sendApprovalMailToProduction()}
            >
              I approve the Estimate & <br /> General Terms & Conditions
            </Button>
            <Button
              className="w-full px-12 py-6 text-2xl font-medium text-center text-black bg-white border-2 border-black border-solid rounded_container"
              onClick={() => sendApprovalQuestionMailToProduction()}
            >
              I still have questions
            </Button>
          </div>
        </div>
        <div className="flex flex-col items-center justify-end w-full pt-12 border-t border-black lg:hidden">
          <Footer
            jobDetail={jobDetail}
            companyDetails={companyDetails}
            footerLogo={footerLogo}
            illustratorAuthorName={illustratorAuthorName}
            illustratorAuthorLink={illustratorAuthorLink}
          />
        </div>
      </div>
    </div>
  );
};

export default Approval;

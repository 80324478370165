import axios from "axios";

const getTerritoriesUrl = id => {
  if (id) {
    return `/api/v1/settings/territories/${id}`;
  }

  return `/api/v1/settings/territories`;
};

export const getTerritories = (searchParams = "") => {
  let query = "";
  if (searchParams.length > 0) query = `q[name_i_cont_any]=${searchParams}`;
  return axios.get(`${getTerritoriesUrl()}?${query}`);
};

export const createTerritory = payload => {
  return axios.post(getTerritoriesUrl(), payload);
};

export const updateTerritory = (id, payload) => {
  return axios.put(getTerritoriesUrl(id), payload);
};

export const destroyTerritory = id => {
  return axios.delete(getTerritoriesUrl(id));
};

import React, { useState } from "react";
import { MenuHorizontal } from "@bigbinary/neeto-icons";
import { Dropdown, Table } from "@bigbinary/neetoui";
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_INDEX } from "common/constants";
import { displayData } from "common/helper";

const List = ({
  deliveryScopeList,
  setDeliveryScope,
  setModalState,
  setDeleteAlertState,
}) => {
  const [pageIndex, setPageIndex] = useState(DEFAULT_PAGE_INDEX);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const { Menu, MenuItem } = Dropdown;

  const renderDropdown = rowData => {
    return (
      <Dropdown
        autoWidth
        closeOnSelect
        buttonStyle="text"
        strategy="fixed"
        icon={MenuHorizontal}
      >
        <Menu>
          <MenuItem.Button
            onClick={() => {
              setDeliveryScope(rowData);
              setModalState(true);
            }}
          >
            Edit
          </MenuItem.Button>
          <MenuItem.Button
            style="danger"
            onClick={() => {
              setDeliveryScope(rowData);
              setDeleteAlertState(true);
            }}
          >
            Delete
          </MenuItem.Button>
        </Menu>
      </Dropdown>
    );
  };

  const COLUMN_DATA = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "90%",
      render: (_, rowData) => displayData(rowData, "name"),
      fixed: "left",
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: "10%",
      render: (_, rowData) => renderDropdown(rowData),
    },
  ];

  return (
    <Table
      fixedHeight
      columnData={COLUMN_DATA}
      rowData={deliveryScopeList}
      currentPageNumber={pageIndex}
      defaultPageSize={pageSize}
      handlePageChange={(page, pageSize) => {
        setPageIndex(page);
        setPageSize(pageSize);
      }}
    />
  );
};

export default List;

import React, { useState } from "react";
import { Down, User } from "@bigbinary/neeto-icons";
import * as dayjs from "dayjs";
import { Typography, Label, Tag, Dropdown, Alert } from "@bigbinary/neetoui";
import { destroyAttachment } from "apis/staffs/attachments";
import { showToastrError } from "common";
import { titleize, humanize } from "common/helper";
import ContactCard from "components/Common/ContactCard";

const Profile = ({
  staffDetail,
  setAccessChanged,
  loadStaffDetailResponse,
  category = null,
  user,
}) => {
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [deletingAttachmentId, setDeletingAttachmentId] = useState();
  const { Menu, MenuItem } = Dropdown;

  const onContactDelete = async id => {
    try {
      await destroyAttachment(id);
      loadStaffDetailResponse(staffDetail.id);
      setDeleteAlertOpen(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const attachments = staffDetail?.attachments_attributes;
  const heroImage = attachments?.find(
    attachment => attachment.attachable_kind === "hero"
  );
  const otherAttachments = attachments?.filter(attachment =>
    ["contact"].includes(attachment.attachable_kind)
  );

  return (
    <>
      <div
        className="flex flex-col overflow-y-auto"
        style={{ height: "calc(100vh - 134px)" }}
      >
        <div className="flex flex-col">
          <div className="max-w-5xl px-6">
            <div className="mt-6">
              <div
                className="flex w-32 h-32 overflow-hidden bg-white bg-cover border border-gray-200 rounded-full shadow-xs ring-4 ring-white"
                style={{
                  background: `url(${heroImage?.thumbSrc})`,
                }}
              >
                {!heroImage && (
                  <div className="flex items-center justify-center w-full h-full bg-gray-100">
                    <User size={54} className="text-gray-500" />
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-1 min-w-0 mt-6 2xl:hidden">
              <Typography style="h3" weight="bold">
                {`${staffDetail?.fullName} ${
                  staffDetail.pronouns ? `(${staffDetail.pronouns})` : ""
                }`}
              </Typography>
            </div>
          </div>
        </div>

        <div className="flex flex-col px-6 my-6">
          {!category && (
            <>
              <div className="grid grid-cols-3 gap-x-4 gap-y-6">
                <div className="flex flex-col space-y-1">
                  <Label>Title</Label>
                  <p className="text-sm font-semibold text-gray-800">
                    {staffDetail.title || "N/A"}
                  </p>
                </div>

                <div className="flex flex-col space-y-1">
                  <Label>Email</Label>
                  <p className="text-sm font-semibold text-gray-800">
                    {staffDetail.email || "N/A"}
                  </p>
                </div>

                <div className="flex flex-col space-y-1">
                  <Label>Phone Number</Label>
                  <p className="text-sm font-semibold text-gray-800">
                    {staffDetail.phoneNumber || "N/A"}
                  </p>
                </div>

                <div className="flex flex-col space-y-1">
                  <Label>DOB</Label>
                  <p className="text-sm font-semibold text-gray-800">
                    {staffDetail.dateOfBirth
                      ? dayjs(staffDetail.dateOfBirth).format("DD/MM/YYYY")
                      : "N/A"}
                  </p>
                </div>

                <div className="flex flex-col space-y-1">
                  <Label>Bank Details</Label>
                  <p className="text-sm font-semibold text-gray-800">
                    {staffDetail.bankDetails || "N/A"}
                  </p>
                </div>

                <div className="flex flex-col space-y-1">
                  <Label>Joining Date</Label>
                  <p className="text-sm font-semibold text-gray-800">
                    {staffDetail.joiningDate
                      ? dayjs(staffDetail.joiningDate).format("DD/MM/YYYY")
                      : "N/A"}
                  </p>
                </div>

                <div className="flex flex-col space-y-1">
                  <Label>Personal Email</Label>
                  <p className="text-sm font-semibold text-gray-800">
                    {staffDetail.personalEmail || "N/A"}
                  </p>
                </div>

                <div className="flex flex-col items-start space-y-2">
                  <Label>Management 1</Label>
                  {staffDetail.management1 ? (
                    <Tag label="Yes" style="success" type="solid" />
                  ) : (
                    <Tag label="No" style="danger" type="solid" />
                  )}
                </div>

                <div className="flex flex-col items-start space-y-2">
                  <Label>Management 2</Label>
                  {staffDetail.management2 ? (
                    <Tag label="Yes" style="success" type="solid" />
                  ) : (
                    <Tag label="No" style="danger" type="solid" />
                  )}
                </div>

                <div className="flex flex-col space-y-1">
                  <Label>Access</Label>
                  {user.access === "admin" ? (
                    <Dropdown
                      icon={Down}
                      label={titleize(humanize(staffDetail.access))}
                      buttonStyle="link"
                      position="bottom-start"
                      closeOnSelect
                    >
                      <Menu>
                        <MenuItem.Button
                          onClick={() => setAccessChanged({ access: "admin" })}
                        >
                          Admin
                        </MenuItem.Button>
                        <MenuItem.Button
                          onClick={() => setAccessChanged({ access: "team" })}
                        >
                          Team
                        </MenuItem.Button>
                      </Menu>
                    </Dropdown>
                  ) : (
                    titleize(humanize(staffDetail.access))
                  )}
                </div>
                <div className="flex flex-col space-y-1">
                  <Label>Location</Label>
                  <p className="text-sm font-semibold text-gray-800">
                    {staffDetail.location || "N/A"}
                  </p>
                </div>

                <div className="flex flex-col items-start space-y-2">
                  <Label>State</Label>
                  {staffDetail.archive ? (
                    <Tag label="Archived" style="danger" type="solid" />
                  ) : (
                    <Tag label="Active" style="success" type="solid" />
                  )}
                </div>

                <div className="flex flex-col space-y-1">
                  <Label>Home Address</Label>
                  <p
                    className="text-sm font-semibold text-gray-800"
                    dangerouslySetInnerHTML={{
                      __html: staffDetail?.homeAddress
                        ?.split("\n")
                        .join("<br>"),
                    }}
                  ></p>
                </div>

                <div className="flex flex-col space-y-1">
                  <Label>Amount Access in Counter</Label>
                  <p className="text-sm font-semibold text-gray-800">
                    {staffDetail.accessAmountInCounter ? (
                      <Tag label="Yes" style="success" type="solid" />
                    ) : (
                      <Tag label="No" style="danger" type="solid" />
                    )}
                  </p>
                </div>
              </div>
              <div className="flex flex-col mt-8 space-y-2">
                <Typography style="h5" weight="semibold">
                  Contact Cards
                </Typography>
                <div className="grid w-full grid-cols-3 gap-4">
                  {otherAttachments?.map((attachment, index) => {
                    return (
                      <ContactCard
                        key={index}
                        setDeleteAlertOpen={setDeleteAlertOpen}
                        type={attachment.type.split("/")[1]}
                        name={attachment.filename}
                        cardUrl={attachment.src}
                        attachmentId={attachment.id}
                        setDeletingAttachmentId={setDeletingAttachmentId}
                        showDelete={true}
                      />
                    );
                  })}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <Alert
        isOpen={deleteAlertOpen}
        title="Delete Contact Card"
        message="Are you sure you want to delete this contact card?"
        onClose={() => setDeleteAlertOpen(false)}
        onSubmit={() => onContactDelete(deletingAttachmentId)}
        submitButtonLabel="Yes, delete"
        cancelButtonLabel="No, cancel"
      />
    </>
  );
};

export default Profile;

import React from "react";
import { Pane, Typography, Input, Button, Spinner } from "@bigbinary/neetoui";
import MilestoneItem from "./MilestoneItem";

const KillFeePane = ({
  isOpen,
  onClose,
  title,
  newPhase,
  phaseTypeName,
  setPhaseTypeName,
  saveBtnLoad,
  setSaveBtnLoad,
  setPhaseList,
  phaseList,
  loadPhaseList,
  setSelectedPhaseTypeId,
  selectedPhaseTypeId,
  updatePhaseTypeEntry,
  createNewPhaseTypeEntry,
}) => {
  return (
    <Pane
      isOpen={isOpen}
      onClose={() => {
        setSelectedPhaseTypeId();
        onClose();
      }}
      size="large"
    >
      <Pane.Header>
        <Typography style="h2" weight="semibold">
          {title}
        </Typography>
      </Pane.Header>
      <Pane.Body>
        {selectedPhaseTypeId || newPhase ? (
          <div className="flex flex-col w-full space-y-6">
            <Input
              label="Milestone Type"
              value={phaseTypeName}
              onChange={e => {
                setSaveBtnLoad(false);
                setPhaseTypeName(e.target.value);
              }}
            />

            <MilestoneItem
              setPhaseList={setPhaseList}
              phaseList={phaseList}
              loadPhaseList={loadPhaseList}
              setSaveBtnLoad={setSaveBtnLoad}
            />
          </div>
        ) : (
          <div className="flex items-center justify-center h-full">
            <Spinner />
          </div>
        )}
      </Pane.Body>
      <Pane.Footer className="flex space-x-2">
        <Button
          label="Save changes"
          onClick={() => {
            if (selectedPhaseTypeId) {
              updatePhaseTypeEntry();
            } else {
              createNewPhaseTypeEntry();
            }
          }}
        />
        <Button
          label="Cancel"
          style="text"
          onClick={() => {
            setSelectedPhaseTypeId();
            onClose();
          }}
        />
      </Pane.Footer>
    </Pane>
  );
};

export default KillFeePane;

export const approvedAmount = items => {
  return items
    .filter(
      item =>
        item.kind !== "extra" ||
        (item.kind === "extra" && item.advance === true)
    )
    .reduce(
      (previousValue, item) =>
        item.advance === true
          ? previousValue - Number(item.amount)
          : previousValue + Number(item.amount),
      0
    );
};

export const approvedAmountWithExchangeRateWithoutAdditionalFees = (
  items,
  exchangeRate
) => {
  let amount = Number(approvedAmount(items));
  return amount / Number(exchangeRate);
};

export const approvedAmountWithExchangeRate = (items, exchangeRate) => {
  let amount =
    Number(approvedAmount(items)) + Number(additionalCost(items, exchangeRate));
  return amount / Number(exchangeRate);
};

export const approvedAmountWithPercentage = items => {
  return items
    .filter(
      item =>
        item.kind !== "extra" ||
        (item.kind === "extra" && item.advance === true)
    )
    .reduce(
      (previousValue, item) =>
        item.advance === true
          ? previousValue - Number(totalPercentageValue(item))
          : previousValue + Number(totalPercentageValue(item)),
      0
    );
};

export const approvedAmountWithExchangeRateAndPercentage = (
  items,
  exchangeRate
) => {
  let amount =
    Number(approvedAmountWithPercentage(items) / Number(exchangeRate)) +
    Number(additionalCost(items, exchangeRate));
  return amount;
};

export const approvedAmountWithExchangeRateAndPercentageWithoutadditionalCost = (
  items,
  exchangeRate
) => {
  let amount = Number(approvedAmountWithPercentage(items));
  return amount / Number(exchangeRate);
};

const totalPercentageValue = item => {
  return (Number(item.amount).toFixed(2) * Number(item.percentage)) / 100;
};

const additionalCost = (items, exchangeRate) => {
  return items
    .filter(item => item.kind === "extra")
    .reduce(
      (previousValue, item) =>
        previousValue + Number(item.amount) / Number(exchangeRate || 0),
      0
    );
};

export const additionalCostWithPercentage = items => {
  return items
    .filter(item => item.kind === "extra")
    .reduce(
      (previousValue, item) =>
        previousValue + Number(totalPercentageValue(item)),
      0
    );
};

export const approvedAmountWithPercentageAlreadyPaid = (
  items,
  exchangeRate
) => {
  return (
    items.reduce(
      (previousValue, item) =>
        previousValue + Number(totalPercentageValue(item)),
      0
    ) / exchangeRate
  );
};

export const additionalAmountWithExchangeRate = (amount, exchangeRate) => {
  return amount / exchangeRate;
};

import axios from "axios";

const getRcisUrl = (jobId, id) => {
  if (id) {
    return `/api/v1/job_module/jobs/${jobId}/rcis/${id}`;
  }

  return `/api/v1/job_module/jobs/${jobId}/rcis`;
};

export const getRcis = jobId => {
  return axios.get(getRcisUrl(jobId));
};

export const getRci = (jobId, id) => {
  return axios.get(getRcisUrl(jobId, id));
};

export const createRci = (jobId, payload) => {
  return axios.post(getRcisUrl(jobId), payload);
};

export const updateRci = (jobId, id, payload) => {
  return axios.put(getRcisUrl(jobId, id), payload);
};

export const destroyRci = (jobId, id) => {
  return axios.delete(getRcisUrl(jobId, id));
};

export const sendRciToArtist = (jobId, rciId, payload) => {
  return axios.post(
    `/api/v1/job_module/jobs/${jobId}/rcis/${rciId}/send_rcis`,
    payload
  );
};

import React from "react";
import { Dropdown } from "@bigbinary/neetoui";

const MoveItem = ({ apiCall, resource }) => {
  const { Menu, MenuItem } = Dropdown;
  return (
    <Dropdown
      trigger="hover"
      position="left"
      customTarget={<MenuItem.Button>Move Item</MenuItem.Button>}
    >
      <Menu>
        <MenuItem.Button
          onClick={() => {
            apiCall(resource.id, "higher", null);
          }}
        >
          Move Higher
        </MenuItem.Button>
        <MenuItem.Button
          onClick={() => {
            apiCall(resource.id, "lower", null);
          }}
        >
          Move Lower
        </MenuItem.Button>
        <MenuItem.Button
          onClick={() => {
            apiCall(resource.id, null, "top");
          }}
        >
          Move to Top
        </MenuItem.Button>
        <MenuItem.Button
          onClick={() => {
            apiCall(resource.id, null, "bottom");
          }}
        >
          Move to Bottom
        </MenuItem.Button>
      </Menu>
    </Dropdown>
  );
};

export default MoveItem;

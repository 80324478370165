import React from "react";
import { Typography } from "@bigbinary/neetoui";
import Chart from "react-apexcharts";

const NoChartData = () => {
  const chartData = {
    series: [
      {
        name: "2023",
        data: [31, 40, 28, 51, 42, 109, 100, 11, 32, 45, 32, 34, 52, 41],
      },
      {
        name: "2022",
        data: [11, 32, 45, 32, 34, 52, 41, 31, 40, 28, 51, 42, 109, 100],
      },
    ],
    options: {
      colors: ["#EBFF00", "#008FFB"],
      chart: {
        height: 350,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        show: false,
      },
      tooltip: {
        x: {
          format: "MM",
        },
      },
    },
  };

  return (
    <div className="relative flex-grow">
      <div className="absolute inset-0 z-10 flex flex-col items-center justify-center w-full h-full bg-gray-200 bg-opacity-50 rounded-lg">
        <Typography style="h4" weight="semibold" className="mt-6">
          No Historical Data Found
        </Typography>
      </div>
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="area"
        width="100%"
        height="85%"
      />
    </div>
  );
};

export default NoChartData;

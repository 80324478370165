import React, { useState } from "react";
import Trix from "trix";
import { Braces } from "@bigbinary/neeto-icons";
import { Dropdown } from "@bigbinary/neetoui";

export default function Placeholder(props) {
  const { name, placeholderFor, placeholderVariables, children } = props;
  const [popoverOpenFor, setPopoverOpenFor] = useState(null);
  const { Menu } = Dropdown;

  function insertOnCursorPosition(field, data) {
    let input = document.querySelector(`[name="${field}"]`);
    if (input == undefined) {
      return;
    }
    if (input.nodeName == "TRIX-EDITOR") {
      input.editor.insertString(" ");
      input.editor.insertHTML(data);
    } else if (input.nodeName == "INPUT") {
      const cursorPosition = input.selectionStart;
      const inputValue = input.value;
      input.value =
        inputValue.substring(0, cursorPosition) +
        data +
        inputValue.substring(cursorPosition);
      this.setCursorPosition(input, cursorPosition + data.length);
    }
  }

  function setCursorPosition(input, position) {
    if (input.setSelectionRange) {
      input.focus();
      input.setSelectionRange(position, position);
    } else if (input.createTextRange) {
      var range = input.createTextRange();
      range.collapse(true);
      range.moveEnd("character", position);
      range.moveStart("character", position);
      range.select();
    }
  }

  function addVariableToField(field, variable) {
    insertOnCursorPosition(field, `{{${variable.key}}}`);
  }

  function updatePopover() {
    setPopoverOpenFor(popoverOpenFor ? null : placeholderFor);
  }

  return (
    <Dropdown buttonStyle="text" icon={Braces} closeOnSelect={false}>
      <Menu>
        <div className="p-4" style={{ width: "380px" }}>
          {placeholderVariables.map((variableCategory, index) => (
            <div className="flex flex-column" key={`variable_${index}`}>
              <h5 className="mb-2 text-base font-semibold text-gray-700">
                {variableCategory.category}
              </h5>
              <span className="flex flex-row flex-wrap mb-4">
                {variableCategory.fields.map((variable, varIndex) => (
                  <span
                    key={`variable_field_${varIndex}`}
                    className="mb-1 mr-1 cursor-pointer badge badge-info"
                    onClick={() => addVariableToField(name, variable)}
                  >
                    {variable.value}
                  </span>
                ))}
              </span>
            </div>
          ))}
        </div>
      </Menu>
    </Dropdown>
  );
}

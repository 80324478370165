import axios from "axios";

const getEstimateTermsAndConditionsUrl = id => {
  if (id) {
    return `/api/v1/settings/estimate_terms_and_conditions/${id}`;
  }

  return `/api/v1/settings/estimate_terms_and_conditions`;
};

export const getEstimateTermsAndConditions = (searchParams = "") => {
  let query = "";
  if (searchParams.length > 0) query = `q[section_i_cont_any]=${searchParams}`;
  return axios.get(`${getEstimateTermsAndConditionsUrl()}?${query}`);
};

export const getEstimateTermsAndCondition = id => {
  return axios.get(getEstimateTermsAndConditionsUrl(id));
};

export const createEstimateTermsAndCondition = payload => {
  return axios.post(getEstimateTermsAndConditionsUrl(), payload);
};

export const updateEstimateTermsAndCondition = (id, payload) => {
  return axios.put(getEstimateTermsAndConditionsUrl(id), payload);
};

export const destroyEstimateTermsAndCondition = id => {
  return axios.delete(getEstimateTermsAndConditionsUrl(id));
};

import React, { useEffect, useState } from "react";
import { Input, Button } from "@bigbinary/neetoui";
import { useUserState } from "contexts/user";

const Profile = () => {
  const { user } = useUserState();

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");

  useEffect(() => {
    if (user) {
      setEmail(user.email);
      setFirstName(user.first_name);
      setLastName(user.last_name);
    }
  }, [user]);

  return (
    <div className="flex flex-grow w-full wrapper">
      <div className="w-full px-4">
        <div className="flex flex-col items-center justify-center flex-grow w-full h-full py-20 mx-auto lg:w-5/12">
          <h2 className="mb-5 text-2xl font-medium text-center text-gray-800">
            Basic Details
          </h2>
          <form
            className="w-full px-10 py-8 bg-white border rounded-lg shadow-sm"
            id="edit_user"
          >
            <Input
              label="Email"
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              name="email"
              className="mb-4"
              id="user_email"
              required
            />
            <Input
              label="First Name"
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
              name="first_name"
              id="user_first_name"
              className="mb-4"
              required
            />
            <Input
              label="Last name"
              value={lastName}
              onChange={e => setLastName(e.target.value)}
              name="last_name"
              id="user_last_name"
              className="mb-4"
              required
            />
            <Input
              label="Current password"
              type="password"
              value={currentPassword}
              onChange={e => setCurrentPassword(e.target.value)}
              className="mb-4"
              required
            />
            <Button
              type="submit"
              label="Update"
              className="flex items-center justify-center w-full"
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Profile;

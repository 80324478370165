import axios from "axios";

const login = payload => axios.post("api/v1/login", payload);

const logout = () => axios.delete("api/v1/logout");

const logout_all = () => axios.delete("api/v1/logout_all");

const auth = payload => axios.post("api/v1/auth", payload);

const signup = payload => axios.post("api/v1/users", payload);

const authenticationApi = {
  login,
  logout,
  logout_all,
  signup,
  auth,
};

export default authenticationApi;

import axios from "axios";

const getEmailBackgroundDetailsUrl = id => {
  if (id) {
    return `/api/v1/settings/email_background_details/${id}`;
  }

  return `/api/v1/settings/email_background_details`;
};

export const getEmailBackgroundDetails = () => {
  return axios.get(getEmailBackgroundDetailsUrl());
};

export const getEmailBackgroundDetail = id => {
  return axios.get(getEmailBackgroundDetailsUrl(id));
};

export const createEmailBackgroundDetail = payload => {
  return axios.post(getEmailBackgroundDetailsUrl(), payload);
};

export const updateEmailBackgroundDetail = (id, payload) => {
  return axios.put(getEmailBackgroundDetailsUrl(id), payload);
};

export const destroyEmailBackgroundDetail = id => {
  return axios.delete(getEmailBackgroundDetailsUrl(id));
};

import React from "react";
import { Redirect, Route } from "react-router-dom";
import PropTypes from "prop-types";
import MobileScreen from "../MobileScreen";

const PrivateRoute = ({
  component: Component,
  condition,
  path,
  redirectRoute,
  ...props
}) => {
  /* Storing user's device details in a variable*/
  let deviceDetails = navigator.userAgent;

  /* Creating a regular expression
  containing some mobile devices keywords
  to search it in details string*/
  let regexp = /android|iphone|kindle|ipad/i;

  /* Using test() method to search regexp in details
  it returns boolean value*/
  let isMobileDevice = regexp.test(deviceDetails);

  if (isMobileDevice) {
    return (
      <MobileScreen />
    )
  }

  return (
    <>
      {condition ? (
        <Route path={path} component={Component} {...props} />
      ) : (
        <Redirect to={{pathname: redirectRoute, from: props.location}} />
      )}
    </>
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func,
  condition: PropTypes.bool,
  path: PropTypes.string,
  redirectRoute: PropTypes.string,
  location: PropTypes.object,
};

export default PrivateRoute;

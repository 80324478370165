import React from "react";
import { Delete, Plus } from "@bigbinary/neeto-icons";
import { Button, Select, Checkbox } from "@bigbinary/neetoui";
import { Input } from "@bigbinary/neetoui/formik";
import { Field } from "formik";
import { artistModuleName } from "common/helper";

const ArtistBreakDown = ({
  form,
  remove,
  artistList,
  disabled,
  currency,
  markupRate,
  insert,
}) => {
  return (
    <>
      {form.values.Markup.artist.map((item, index) => {
        if (item._destroy !== 1) {
          !form?.touched?.Markup?.artist[index]?.artistName &&
            form.setFieldTouched(`Markup.artist[${index}].artistName`, true);
          !form?.touched?.Markup?.artist[index]?.percentage &&
            form.setFieldTouched(`Markup.artist[${index}].percentage`, true);
          !form?.touched?.Markup?.artist[index]?.rate &&
            form.setFieldTouched(`Markup.artist[${index}].rate`, true);

          return (
            <div className="relative w-full" key={index}>
              <div className="flex items-end w-full space-y-4">
                <div className="grid items-end w-full grid-cols-3 gap-4">
                  <Field name={`Markup.artist[${index}].artistName`}>
                    {({ field, form, meta }) => {
                      return (
                        <Select
                          options={artistList}
                          {...field}
                          onChange={value => {
                            form.setFieldValue(
                              `Markup.artist[${index}].artistName`,
                              value
                            );
                          }}
                          blurInputOnSelect={true}
                          label={`${artistModuleName()} Name`}
                          defaultOptions={{ label: "", value: "" }}
                          isDisabled={disabled}
                          error={meta.touched && meta.error?.label}
                        />
                      );
                    }}
                  </Field>
                  <div className="flex space-x-3">
                    <Input
                      label="Split Percentage"
                      type="number"
                      name={`Markup.artist[${index}].percentage`}
                      onChange={e => {
                        form.setFieldValue(
                          `Markup.artist[${index}].percentage`,
                          e.target.value
                        );
                      }}
                      disabled={true}
                    />
                    <Input
                      type="number"
                      label={`Total(${currency})`}
                      name={`Markup.artist[${index}].rate`}
                      onChange={e => {
                        form.setFieldValue(
                          `Markup.artist[${index}].rate`,
                          e.target.value
                        );

                        form.setFieldValue(
                          `Markup.artist[${index}].percentage`,
                          (Number(e.target.value) / Number(markupRate)) * 100
                        );
                      }}
                      disabled={disabled}
                    />
                  </div>
                  <div className="flex items-end space-x-2 mt-5">
                    <Checkbox
                      disabled={disabled}
                      label="Expense"
                      className="my-2"
                      checked={form.values.Markup.artist[index].artistExpense}
                      onClick={() =>
                        form.setFieldValue(
                          `Markup.artist[${index}].artistExpense`,
                          !form.values.Markup.artist[index].artistExpense
                        )
                      }
                    />
                    <Checkbox
                      disabled={
                        !form.values.Markup.artist[index].artistExpense ||
                        disabled
                      }
                      label="Advance"
                      className="my-2"
                      checked={form.values.Markup.artist[index].advance}
                      onClick={() =>
                        form.setFieldValue(
                          `Markup.artist[${index}].advance`,
                          !form.values.Markup.artist[index].advance
                        )
                      }
                    />
                    <Button
                      style="danger-text"
                      icon={Delete}
                      className="flex-none"
                      onClick={() => remove(index)}
                      disabled={disabled}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        }
      })}
      <div className="flex justify-end">
        <Button
          icon={Plus}
          iconPosition="left"
          label={`Add More ${artistModuleName()}`}
          style="link"
          onClick={() => {
            insert(form?.values?.Markup?.artist.length + 1, {
              artistName: "",
              percentage: "",
              rate: "",
            });
          }}
          disabled={disabled}
        />
      </div>
    </>
  );
};

export default ArtistBreakDown;

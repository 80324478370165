import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import {
  Spinner,
  Modal,
  Button,
  Textarea,
  Typography,
  Alert,
} from "@bigbinary/neetoui";
import { SubHeader } from "@bigbinary/neetoui/layouts";
import {
  getCatchupNotes,
  getCatchupNote,
  createCatchupNote,
  updateCatchupNote,
  destroyCatchupNote,
} from "apis/artists/catchup_notes";
import { showToastrError } from "common";
import EmptyState from "components/Common/EmptyState";
import { getRandomNotFoundImage } from "common/helper";
import { NOTE_INITIAL_VALUE, NOTE_VALIDATION_SCHEMA } from "./constants";
import List from "./List";

const CatchupNotes = ({ artistId }) => {
  const [addModalState, setAddModalState] = useState(false);
  const [deleteAlertState, setDeleteAlertState] = useState(false);
  const [catchupNoteDetail, setCatchupNote] = useState();
  const [catchupNoteId, setCatchupNoteId] = useState();
  const [catchupNoteList, setCatchupNoteList] = useState([]);
  const [catchupNoteListLoading, setCatchupNoteListLoading] = useState(true);
  const [catchupNoteDetailLoading, setCatchupNoteDetailLoading] = useState(
    false
  );
  const [emptyImage, setEmptyImage] = useState();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: catchupNoteDetail ? catchupNoteDetail : NOTE_INITIAL_VALUE,
    validationSchema: NOTE_VALIDATION_SCHEMA,
    onSubmit: () => {
      if (catchupNoteDetail) {
        updateCatchupNoteResponse();
      } else {
        createCatchupNoteResponse();
      }
    },
  });

  useEffect(() => {
    if (artistId) {
      loadCatchupNoteListResponse();
    } else {
      setCatchupNoteListLoading(false);
    }

    setEmptyImage(getRandomNotFoundImage());
  }, []);

  const loadCatchupNoteListResponse = async () => {
    try {
      const response = await getCatchupNotes(artistId);
      setCatchupNoteList(response.data.catchupNotes);
      setCatchupNoteListLoading(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadCatchupNoteDetailResponse = async noteId => {
    try {
      setCatchupNoteDetailLoading(true);
      const response = await getCatchupNote(artistId, noteId);
      setCatchupNote(response.data.catchupNote);
      setCatchupNoteDetailLoading(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const createCatchupNoteResponse = async () => {
    try {
      let payload = { catchup_note: { note: formik.values.note } };
      await createCatchupNote(artistId, payload);
      setAddModalState(false);
      setCatchupNoteListLoading(true);
      loadCatchupNoteListResponse(artistId);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const updateCatchupNoteResponse = async () => {
    try {
      let payload = { catchup_note: { note: formik.values.note } };
      await updateCatchupNote(artistId, catchupNoteDetail.id, payload);
      setAddModalState(false);
      setCatchupNoteListLoading(true);
      loadCatchupNoteListResponse(artistId);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const deleteCatchupNoteEntry = async () => {
    try {
      await destroyCatchupNote(artistId, catchupNoteId);
      setDeleteAlertState(false);
      setCatchupNoteListLoading(true);
      loadCatchupNoteListResponse(artistId);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  if (catchupNoteListLoading || catchupNoteDetailLoading) {
    return (
      <div
        className="flex items-center justify-center w-full"
        style={{ height: "calc(100vh - 134px)" }}
      >
        <Spinner />
      </div>
    );
  }

  if (!artistId) {
    return (
      <div
        className="flex items-center justify-center p-2"
        style={{ height: "calc(100vh - 134px)" }}
      >
        <EmptyState
          image={emptyImage}
          title="Please click on any artist to access the details."
        />
      </div>
    );
  }

  return (
    <div
      className="flex flex-col overflow-hidden"
      style={{ height: "calc(100vh - 134px)" }}
    >
      <SubHeader
        className="px-6 pt-4"
        rightActionBlock={
          <Button
            size="small"
            className="px-3"
            label="Add Note"
            onClick={() => {
              formik.resetForm();
              setCatchupNote();
              setAddModalState(true);
            }}
          />
        }
      />

      <div
        className="px-4 mb-6 overflow-hidden"
        style={{ height: "calc(100vh - 214px)" }}
      >
        <List
          setAddModalState={setAddModalState}
          setDeleteAlertState={setDeleteAlertState}
          catchupNoteList={catchupNoteList}
          loadCatchupNoteDetailResponse={loadCatchupNoteDetailResponse}
          setCatchupNoteId={setCatchupNoteId}
        />
      </div>

      <Modal isOpen={addModalState} onClose={() => setAddModalState(false)}>
        <Modal.Header>
          <Typography style="h2" weight="semibold">
            {catchupNoteDetail ? "Edit Note" : "Add Note"}
          </Typography>
        </Modal.Header>

        <Modal.Body>
          <Textarea
            label="Note"
            name="name"
            rows={3}
            value={formik.values.note}
            error={formik.errors.note}
            {...formik.getFieldProps("note")}
          />
        </Modal.Body>
        <Modal.Footer className="space-x-2">
          <Button
            label="Save Changes"
            onClick={() => {
              formik.handleSubmit();
            }}
          />
          <Button
            label="Cancel"
            style="text"
            onClick={() => setAddModalState(false)}
          />
        </Modal.Footer>
      </Modal>

      <Alert
        isOpen={deleteAlertState}
        title="Delete Note"
        message="Are you sure you want to delete this note?"
        onClose={() => setDeleteAlertState(false)}
        onSubmit={() => deleteCatchupNoteEntry()}
        submitButtonLabel="Yes, delete"
        cancelButtonLabel="No, cancel"
      />
    </div>
  );
};

export default CatchupNotes;

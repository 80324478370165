import React, { useState, useEffect } from "react";
import { Delete } from "@bigbinary/neeto-icons";
import { Select, Input, Button, Label, Typography } from "@bigbinary/neetoui";
import produce from "immer";

const SerialComponents = ({ formik, attr }) => {
  const [serialList, setSerialList] = useState([
    { content: "text", value: "" },
  ]);
  const [initial, setInitial] = useState(true);
  let contentOptions = [
    { label: "Serial", value: "serial" },
    { label: "Text", value: "text" },
  ];

  contentOptions = attr === "revisionSerial" ? [{ label: "Text", value: "text" }, { label: "Resource", value: "resource" }] : contentOptions

  const resourceOptions = [
    { label: "Job Serial", value: "*job_serial*" },
    { label: "Org Serial", value: "*org_serial*" },
  ]

  useEffect(() => {
    if (!initial) {
      formik.setFieldValue(
        attr,
        serialList.map(serial => serial.value).join("<>")
      );
    }
  }, [serialList]);

  useEffect(() => {
    if (initial) {
      const serialComponents = formik.values[attr]?.split("<>");
      let array = [];
      serialComponents?.map(component => {
        if (component.includes("[")) {
          array = [...array, { content: "serial", value: component }];
        } else if (component.includes("*")) {
          array = [...array, { content: "resource", value: component }];
        } else {
          array = [...array, { content: "text", value: component }];
        }
      });

      setSerialList(array);
    }
  }, [formik.values[attr]]);

  return (
    <div className="flex flex-col w-full space-y-4">
      <div className="flex flex-col w-full space-y-4">
        {serialList?.map((item, index) => {
          return (
            <div className="grid grid-cols-8 gap-4" key={index}>
              <Select
                label="Content"
                name="content"
                id="pageName"
                className="col-span-3"
                options={contentOptions}
                value={contentOptions.find(page => page.value === item.content)}
                required={true}
                onChange={e => {
                  setInitial(false);
                  setSerialList(
                    produce(draft => {
                      draft[index] = {
                        ...draft[index],
                        content: e.value,
                      };
                    })
                  );
                }}
              />

              {item.content === "text" ? (
                <Input
                  label="Enter your words"
                  value={item.value}
                  className="col-span-5"
                  onChange={e => {
                    setInitial(false);
                    setSerialList(
                      produce(draft => {
                        draft[index] = {
                          ...draft[index],
                          value: e.target.value,
                        };
                      })
                    );
                  }}
                  suffix={
                    <Button
                      onClick={() => {
                        setInitial(false);
                        setSerialList(state => {
                          let newState = [...state];
                          newState.splice(index, 1);
                          return newState;
                        });
                      }}
                      style="text"
                      size="small"
                      className="hover:bg-transparent"
                      disabled={serialList.length === 1}
                      icon={Delete}
                      tooltipProps={{
                        content: "Delete",
                        position: "top",
                      }}
                    />
                  }
                />
              ) : item.content === "serial" ? (
                <Input
                  type="number"
                  label="No. of Zeroes"
                  value={Number(item.value?.replace("[", "")?.replace("]", ""))}
                  className="col-span-5"
                  onChange={e => {
                    setInitial(false);
                    setSerialList(
                      produce(draft => {
                        draft[index] = {
                          ...draft[index],
                          value: `[${e.target.value}]`,
                        };
                      })
                    );
                  }}
                  suffix={
                    <Button
                      onClick={() => {
                        setInitial(false);
                        setSerialList(state => {
                          let newState = [...state];
                          newState.splice(index, 1);
                          return newState;
                        });
                      }}
                      style="text"
                      size="small"
                      disabled={serialList.length === 1}
                      className="hover:bg-transparent"
                      icon={Delete}
                      tooltipProps={{
                        content: "Delete",
                        position: "top",
                      }}
                    />
                  }
                />
              ) :
                <>
                  <Select
                    label="Resource"
                    name="resource"
                    id="pageName"
                    className="col-span-5"
                    options={resourceOptions}
                    value={resourceOptions.find(page => page.value === item.value)}
                    required={true}
                    components={{ DropdownIndicator:() => <Button
                      onClick={() => {
                        setInitial(false);
                        setSerialList(state => {
                          let newState = [...state];
                          newState.splice(index, 1);
                          return newState;
                        });
                      }}
                      className="p-0"
                      style="text"
                      disabled={serialList.length === 1}
                      icon={Delete}
                      tooltipProps={{
                        content: "Delete",
                        position: "top",
                      }}
                    />, IndicatorSeparator:() => null }}
                    onChange={e => {
                      setInitial(false);
                      setSerialList(
                        produce(draft => {
                          draft[index] = {
                            ...draft[index],
                            value: e.value,
                          };
                        })
                      );
                    }}
                  />
                </>
              }
            </div>
          );
        })}
      </div>
      <div className="flex justify-between w-full mt-3">
        <div className="flex items-center max-w-xs mr-4 space-x-2 overflow-hidden">
          {formik.values[attr] ? <Label>Preview:</Label> : null}
          <Typography style="body2">
            {formik.values[attr]?.split("<>").join("")}
          </Typography>
        </div>
        <Button
          style="link"
          label="Add Serial Component"
          onClick={() => {
            setSerialList(state => {
              return [
                ...state,
                {
                  content: "text",
                },
              ];
            });
          }}
        />
      </div>
    </div>
  );
};

export default SerialComponents;

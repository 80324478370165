import React, { useEffect, useState, useContext } from "react";
import { Typography, Switch, Accordion } from "@bigbinary/neetoui";
import { updateEstimateRevision } from "apis/jobs/estimate_revisions";
import { getKillFees } from "apis/jobs/kill_fees";
import { showToastrError } from "common";
import { OverviewDetailsContext } from ".";
import Scopes from "./Scopes";
import RevisionDetails from "./RevisionDetails";
import JobPhases from "./JobPhases";
import KillFee from "./KillFee";

const JobOverview = () => {
  const {
    jobDetail,
    revisionDetail,
    phaseTypeList,
    revisionId,
    loadEstimateRevision,
  } = useContext(OverviewDetailsContext);
  const [jobPhaseEnabled, setJobPhaseEnabled] = useState();
  const [killFeeList, setKillFeeList] = useState([]);
  const [killFeeLoading, setKillFeeLoading] = useState(false);

  useEffect(() => {
    if (revisionDetail) {
      setJobPhaseEnabled(revisionDetail.jobPhaseEnabled);
    }
  }, [revisionDetail]);

  useEffect(() => {
    loadJobKillFee();
  }, [jobDetail]);

  const loadJobKillFee = async () => {
    try {
      const response = await getKillFees(jobDetail.id);
      setKillFeeList(response.data.killFees);
      setKillFeeLoading(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const updateEstimateRevisionResponse = async (field, value) => {
    try {
      let payload = { estimate_revision: { [field]: value } };

      if (
        field === "jobPhaseEnabled" &&
        value &&
        revisionDetail?.revisionToggleEnabled
      ) {
        payload = {
          estimate_revision: {
            jobPhaseEnabled: true,
            revisionToggleEnabled: false,
          },
        };
      }
      await updateEstimateRevision(jobDetail.id, revisionId, payload);
      loadEstimateRevision();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const onRevisionRecordUpdate = async (field, value) => {
    try {
      let payload = { estimate_revision: { [field]: value } };
      await updateEstimateRevision(jobDetail.id, revisionId, payload);
      loadEstimateRevision();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  return (
    <div className="flex flex-col w-full space-y-6">
      <Scopes />

      <div className="flex flex-col space-y-4 transition-all">
        <div className="flex items-center space-x-2">
          <Switch
            checked={revisionDetail.revisionToggleEnabled}
            onChange={() => {
              onRevisionRecordUpdate(
                "revisionToggleEnabled",
                !revisionDetail.revisionToggleEnabled
              );
            }}
          />
          <Typography style="h4" weight="semibold">
            Revision
          </Typography>
        </div>
        <RevisionDetails
          jobPhaseEnabled={jobPhaseEnabled}
          onRevisionRecordUpdate={onRevisionRecordUpdate}
        />
      </div>
      <div className="flex flex-col space-y-4 transition-all">
        <div className="flex items-center space-x-2">
          <Switch
            checked={jobPhaseEnabled}
            onChange={() => {
              setJobPhaseEnabled(state => !state);
              updateEstimateRevisionResponse(
                "jobPhaseEnabled",
                !jobPhaseEnabled
              );
            }}
          />
          <Typography style="h4" weight="semibold">
            Milestones
          </Typography>
        </div>
        {jobPhaseEnabled && (
          <Accordion className="px-2 py-4 mt-4 border rounded-md neeto-ui-border-gray-400 neeto-ui-bg-gray-200">
            <JobPhases
              jobDetail={jobDetail}
              phaseTypeList={phaseTypeList}
              phaseTypeName={revisionDetail.phaseTypeName}
              phaseTypeId={revisionDetail.phaseTypeId}
              revisionDetail={revisionDetail}
              loadEstimateRevision={loadEstimateRevision}
            />
          </Accordion>
        )}
      </div>

      <div className="flex flex-col space-y-3">
        <Typography style="h4" weight="semibold">
          Kill Fee
        </Typography>
        <KillFee
          jobId={jobDetail.id}
          loadJobKillFee={loadJobKillFee}
          killFeeList={killFeeList}
          setKillFeeList={setKillFeeList}
          killFeeLoading={killFeeLoading}
          setKillFeeLoading={setKillFeeLoading}
        />
      </div>
    </div>
  );
};

export default JobOverview;

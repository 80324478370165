import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import classNames from "classnames";
import { Search } from "@bigbinary/neeto-icons";
import { Typography, Avatar, Input } from "@bigbinary/neetoui";
import useDebounce from "common/debounce";
import { groupByInitial, artistModuleName } from "common/helper";
import InfiniteScroll from "react-infinite-scroll-component";

const ArtistDirectory = ({
  artistList,
  loadArtistListResponse,
  artistDetail,
  totalRecords,
  list,
  setList,
}) => {
  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useState();
  const debouncedSearchTerm = useDebounce(searchParams, 500);

  useEffect(() => {
    if (debouncedSearchTerm !== undefined) {
      setList([]);
      setPage(1);
      loadArtistListResponse(searchParams, 1);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    setList([...list, ...artistList]);
  }, [artistList]);

  const loadNext = () => {
    setTimeout(() => {
      loadArtistListResponse(searchParams, page + 1);
      setPage(page + 1);
    }, 1000);
  };

  const removeDuplicacy = array => {
    var resArr = [];
    array.filter(item => {
      var i = resArr.findIndex(x => x.id == item.id);
      if (i <= -1) {
        resArr.push({ ...item });
      }
      return null;
    });

    return resArr;
  };

  const LoaderBlockItem = () => (
    <div className="relative flex gap-3 p-3 transition-colors border-b cursor-pointer neeto-ui-border-gray-200">
      <div className="flex-shrink-0 w-10 h-10 rounded-full shimmer-bg" />
      <div className="flex flex-col flex-grow gap-1">
        <div className="h-4 shimmer-bg" />
        <div className="h-6 shimmer-bg" />
      </div>
    </div>
  );

  const LoaderBlock = () => (
    <div className="flex flex-col w-full">
      <LoaderBlockItem />
      <LoaderBlockItem />
      <LoaderBlockItem />
      <LoaderBlockItem />
    </div>
  );

  return (
    <>
      <aside className="flex-shrink-0 hidden border-r neeto-ui-border-gray-200 xl:order-first xl:flex xl:flex-col w-80">
        <div className="px-6 py-4 space-y-3">
          <div className="flex flex-col space-y-1">
            <Typography style="h4" weight="semibold" lineHeight="none">
              Directory
            </Typography>
            <Typography style="body2" className="neeto-ui-text-gray-600">
              Search directory of {totalRecords} {artistModuleName()}
            </Typography>
          </div>
          <form
            onSubmit={e => {
              e.preventDefault();
            }}
          >
            <Input
              placeholder="Search name, address or contact"
              prefix={<Search size={16} />}
              value={searchParams}
              onChange={e => setSearchParams(e.target.value)}
            />
          </form>
        </div>
        <div
          id="scrollableDiv"
          style={{
            height: "100vh",
            overflow: "auto",
          }}
        >
          <InfiniteScroll
            dataLength={list.length}
            next={loadNext}
            loader={<LoaderBlock />}
            scrollableTarget="scrollableDiv"
            hasMore={totalRecords > list.length ? true : false}
          >
            <nav
              className="flex-1 min-h-0 pb-4 space-y-2"
              aria-label="Directory"
            >
              {Object.entries(
                groupByInitial(removeDuplicacy(list), "name")
              ).map((artistGroup, index) => {
                return (
                  <div className="px-3" key={index}>
                    <ul className="relative z-0 space-y-2">
                      {artistGroup[1].map((artist, index) => {
                        return (
                          <li key={index} className="cursor-pointer">
                            <Link
                              className="no-underline focus:outline-none"
                              to={`/${artistModuleName().toLowerCase()}/${artist.id}`}
                            >
                              <div
                                className={classNames(
                                  "relative flex items-center p-3 space-x-3 rounded-md hover:neeto-ui-bg-gray-200",
                                  {
                                    "neeto-ui-bg-gray-200":
                                      artist?.id === artistDetail?.id,
                                  }
                                )}
                              >
                                <div className="flex-shrink-0">
                                  <Avatar
                                    size="large"
                                    user={{
                                      name: artist.name,
                                      imageUrl: artist.smallSrc,
                                    }}
                                  />
                                </div>
                                <Typography
                                  className="block w-full break-words neeto-ui-text-gray-800"
                                  component="span"
                                  lineHeight="tight"
                                  style="h5"
                                  weight="medium"
                                >
                                  {`${artist?.name} ${
                                    artist.pronouns
                                      ? `(${artist.pronouns})`
                                      : ""
                                  }`}
                                </Typography>
                              </div>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
            </nav>
          </InfiniteScroll>
        </div>
      </aside>
    </>
  );
};

export default ArtistDirectory;

/* eslint-disable react/display-name */
import React from "react";
import { Button, Tag, Table, Typography } from "@bigbinary/neetoui";
import classnames from "classnames";
import * as dayjs from "dayjs";
import { humanize, numberWithCommas } from "common/helper";

const Jobs = ({ list, title }) => {
  const renderSerial = rowData => {
    return (
      <Button
        style="link"
        to={`/invoices/${rowData.id}`}
        label={rowData.invoiceSerial}
      />
    );
  };

  const renderJobTitle = rowData => {
    return (
      <Button
        style="link"
        to={`/jobs/${rowData.jobId}/overview`}
        label={rowData.jobTitle}
      />
    );
  };

  const renderOrganisation = rowData => {
    return (
      <Button
        style="link"
        to={`/organisations/${rowData.organisationId}`}
        label={rowData.organisationName}
      />
    );
  };

  const renderStaff = rowData => {
    return (
      <Button
        style="link"
        to={`/staff/${rowData.staffId}`}
        label={rowData.staffFullName}
      />
    );
  };

  const renderAmount = rowData => {
    return `${numberWithCommas(Number(rowData.amount).toFixed(2))} ${
      rowData.currency
    }`;
  };

  const renderContact = rowData => {
    return (
      <Button
        style="link"
        to={`/people/${rowData.contactId}`}
        label={rowData.contactFullName}
      />
    );
  };

  const renderOverdueDays = rowData => {
    return (
      <Tag
        label={rowData.overdueDays ? `${rowData.overdueDays} days` : "-"}
        className={classnames({
          "bg-red-200": rowData.overdueDays < 32,
          "bg-red-300 font-bold": rowData.overdueDays > 32,
          "bg-red-400 text-white font-bold": rowData.overdueDays > 64,
          "bg-red-500 text-white font-bold": rowData.overdueDays > 96,
        })}
        type="solid"
      />
    );
  };

  const renderStatus = rowData => {
    return (
      <Tag
        style={rowData.status === "paid" ? "success" : "danger"}
        label={humanize(rowData.status)}
      />
    );
  };

  const COLUMN_DATA = [
    {
      title: "Serial",
      key: "invoiceSerial",
      dataIndex: "invoiceSerial",
      width: 100,
      fixed: "left",
      render: (_, rowData) => renderSerial(rowData),
    },
    {
      title: "Job Name",
      key: "jobTitle",
      dataIndex: "jobTitle",
      width: 200,
      fixed: "left",
      render: (_, rowData) => renderJobTitle(rowData),
    },
    {
      title: "Organisation",
      dataIndex: "organisationName",
      key: "organisationName",
      width: 200,
      render: (_, rowData) => renderOrganisation(rowData),
    },
    {
      title: "Staff",
      dataIndex: "staffFullName",
      key: "staffFullName",
      width: 150,
      render: (_, rowData) => renderStaff(rowData),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: 150,
      render: (_, rowData) => renderAmount(rowData),
    },
    {
      title: "Issued On",
      dataIndex: "issueDate",
      key: "issueDate",
      width: 150,
      render: issueDate => dayjs(issueDate).format("DD/MM/YYYY"),
    },
    {
      title: "Contact",
      dataIndex: "contactFullName",
      key: "contactFullName",
      width: 150,
      render: (_, rowData) => renderContact(rowData),
    },
    {
      title: "# Days Overdue",
      dataIndex: "overdueDays",
      key: "overdueDays",
      width: 150,
      render: (_, rowData) => renderOverdueDays(rowData),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 150,
      fixed: "right",
      render: (_, rowData) => renderStatus(rowData),
    },
  ];

  return (
    <div className="flex flex-col w-full mt-4 p-4 rounded bg-white">
      <Typography
        style="h3"
        weight="semibold"
        className="mb-4 mt-2 text-gray-800 px-3"
      >
        {title}
      </Typography>
      <Table fixedHeight columnData={COLUMN_DATA} rowData={list} />
    </div>
  );
};

export default Jobs;

import axios from "axios";

const getAgencyGroupsUrl = id => {
  if (id) {
    return `/api/v1/settings/agency_groups/${id}`;
  }

  return `/api/v1/settings/agency_groups`;
};

export const getAgencyGroups = (searchParams = "") => {
  let query = "";
  if (searchParams.length > 0) query = `q[name_i_cont_any]=${searchParams}`;
  return axios.get(`${getAgencyGroupsUrl()}?${query}`);
};

export const createAgencyGroup = payload => {
  return axios.post(getAgencyGroupsUrl(), payload);
};

export const updateAgencyGroup = (id, payload) => {
  return axios.put(getAgencyGroupsUrl(id), payload);
};

export const destroyAgencyGroup = id => {
  return axios.delete(getAgencyGroupsUrl(id));
};

import React from "react";
import { parsePhoneNumber } from "libphonenumber-js";
import { Tag } from "@bigbinary/neetoui";
import Notfound1 from "images/common/404";
import Notfound2 from "images/common/notfound1";
import Notfound3 from "images/common/notfound2";
import Notfound4 from "images/common/notfound3";
import Notfound5 from "images/common/notfound4";
import { useUserState } from "contexts/user";

/*eslint-disable */
export const parsePhone = phoneNumber => {
  if (phoneNumber?.length > 1) {
    try {
      return parsePhoneNumber(`+${phoneNumber}`).formatInternational();
    } catch (error) {
      throw error;
    }
  }
};
/*eslint-enable */

export const humanize = str => {
  var i,
    frags = str
      ?.replace(/([A-Z])/g, " $1")
      .trim()
      ?.split("_");

  if (frags) {
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
  }

  return frags?.join(" ");
};

const RED_INVOICE_STATUS = ["unpaid"];

export const DEFAULT_PAGE_SIZE = 50;
// const INACTIVE_STATUS = ["inactive", "graveyard", "purgatory"]

export const jobStatusColor = str => {
  if (str === "estimate") {
    return "yellow";
  } else if (str === "in_progress") {
    return "green";
  } else if (str === "post") {
    return "orange";
  } else if (str === "inactive") {
    return "purple";
  } else if (str === "purgatory") {
    return "gray";
  } else if (str === "graveyard") {
    return "red";
  }
};

export const getJobsStatusColor = status => {
  switch (status) {
    case "estimate":
      return "primary";
    case "in_progress":
      return "success";
    case "post":
      return "warning";
    case "inactive":
      return "info";
    case "purgatory":
      return "secondary";
    case "graveyard":
      return "danger";
    default:
      return "primary";
  }
};

export const activityStatusColor = str => {
  if (str === "open") {
    return "red";
  } else if (str === "completed") {
    return "green";
  }
};

export const invoiceStatusBadgeColor = str => {
  return RED_INVOICE_STATUS.includes(str) ? "danger" : "success";
};

export const numberWithCommas = x => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const dropDownListGenerator = data => {
  return data.map(item => {
    return {
      label: item.shortName || item.name || item.fullName,
      value: item.id,
      ...item,
    };
  });
};

export const dropDownListGeneratorForXeroCodes = data => {
  return data.map(item => {
    return {
      label: `${item.name} - ${item.code}`,
      value: item.code,
      ...item,
    };
  });
};

export const dropDownListGeneratorForCompanyDetails = data => {
  return data.map(item => {
    return {
      label: `${item.name} - ${item.currencyName}`,
      value: item.id,
      ...item,
    };
  });
};

export const groupedDropDownListGenerator = (data, key) => {
  const groupedData = groupByInitial(data, key);
  return Object.keys(groupedData).map(key => {
    return {
      label: key,
      options: dropDownListGenerator(groupedData[key]),
    };
  });
};

export const groupByInitial = (array, key) => {
  return array.reduce((hash, obj) => {
    if (obj[key] === undefined) return hash;
    return Object.assign(hash, {
      [obj[key].charAt(0).toUpperCase()]: (
        hash[obj[key].charAt(0).toUpperCase()] || []
      ).concat(obj),
    });
  }, {});
};

export const titleize = str => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const groupByCategory = array => {
  return array.reduce(
    (item, { lineItemCategoryName, name, id, lineItemCategoryId }) => {
      if (!item[lineItemCategoryName]) item[lineItemCategoryName] = [];
      item[lineItemCategoryName].push({
        name: name,
        id: id,
        lineItemCategoryId: lineItemCategoryId,
      });
      return item;
    },
    {}
  );
};

export const sumAmount = (items, prop) => {
  return items.reduce(function (a, b) {
    return a + Number(b[prop]);
  }, 0);
};

export const truncateString = (str, num) => {
  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
};

export const removeDuplicacy = (array, attribute = "id") => {
  var resArr = [];
  array.filter(item => {
    var i = resArr.findIndex(x => x[attribute] == item[attribute]);
    if (i <= -1) {
      resArr.push({ ...item });
    }
    return null;
  });

  return resArr;
};

export const sleep = ms => {
  new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
};

export const sortedByCreation = data => {
  return data.slice(0).sort((a, b) => a.createdAt.localeCompare(b.createdAt));
};

export const sortByStringInt = (arr, field) => {
  return arr.sort((a, b) => (parseInt(a[field]) > parseInt(b[field]) ? 1 : -1));
};

export const subjectResourceList = () => {
  return [
    { label: "Paperwork Title", value: "[paperwork_title]" },
    { label: "Job Number", value: "[job_number]" },
    { label: "Job Name", value: "[job_name]" },
    { label: "Serial Number", value: "[serial_number]" },
    { label: "Invoice Number", value: "[invoice_number]" },
    { label: "Version Number", value: "[version_number]" },
    { label: "Organization Name", value: "[organisation_name]" },
  ];
};

export const subjectResourceListForWelcomeEmail = () => {
  return [
    { label: "Paperwork Title", value: "[paperwork_title]" },
    { label: "Job Number", value: "[job_number]" },
    { label: "Job Name", value: "[job_name]" },
    { label: "Organization Name", value: "[organisation_name]" },
  ];
};

export const paperworkResourceList = () => {
  return [
    { label: "Contact First Name", value: "[contact_first_name]" },
    { label: "Staff First Name", value: "[staff_first_name]" },
    { label: "Account Name", value: "[account_name]" },
    { label: "Staff Phone", value: "[staff_phone]" },
  ];
};

export const paperworkResourceListWithArtist = () => {
  return [
    { label: "Contact First Name", value: "[contact_first_name]" },
    { label: "Staff First Name", value: "[staff_first_name]" },
    { label: "Account Name", value: "[account_name]" },
    { label: "Staff Phone", value: "[staff_phone]" },
    { label: `${artistModuleName()} Name`, value: "[artist_name]" },
  ];
};

export const commencementInvoiceResourceList = () => {
  return [{ label: "Invoice Percentage", value: "[invoice_percentage]" }];
};

export const displayData = (obj, field) => {
  return obj[field] ? obj[field] : "-";
};

export const direction = dir => {
  if (dir === "descend") {
    return "desc";
  } else if (dir === "ascend") {
    return "asc";
  }
};

export const showEnabilityDisability = value => {
  return value ? (
    <Tag label="Enabled" style="success" />
  ) : (
    <Tag label="Disabled" style="danger" />
  );
};

export const getRandomNotFoundImage = () => {
  const emptyStateImage = [
    Notfound1,
    Notfound2,
    Notfound3,
    Notfound4,
    Notfound5,
  ];
  return emptyStateImage[Math.floor(Math.random() * emptyStateImage.length)];
};

export const urlify = text => {
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(
    urlRegex,
    '<a class="mt-5 underline font-bold" href="$1" style="color: rgb(45, 54, 212);">$1</a>'
  );
};

export const splitLastOccurrence = (str, substring) => {
  const lastIndex = str.lastIndexOf(substring);
  const before = str.slice(0, lastIndex);
  const after = str.slice(lastIndex + 1);
  return [before, after];
};

export const artistModuleName = () => {
  const { user } = useUserState();

  return user.account_studio ? "Suppliers" : "Artists";
};

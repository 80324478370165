import * as Yup from "yup";

const FRONTEND_URL_REGEX = /^([a-zA-Z0-9]+(\.[a-zA-Z0-9]+)+.*)$/;

export const EMAIL_BACKGROUND_VALIDATION_SCHEMA = Yup.object({
  pageName: Yup.string().required("Page name can't be empty"),
  welcomeEmailVideoUrl: Yup.string()
    .matches(FRONTEND_URL_REGEX, "Invalid URL")
    .nullable(),
  artistName: Yup.string().nullable(),
  artistUrl: Yup.string().nullable(),
});

export const EMAIL_BACKGROUND_INITIAL_VALUE = {
  pageName: null,
  artistName: "",
  artistUrl: "",
  emailSubject: "",
};

export const PAGE_LIST = [
  { label: "Please select a page", value: null },
  { label: "Booking Form", value: "Booking Form" },
  {
    label: "Commencement Invoice",
    value: "Commencement Invoice",
  },
  {
    label: "Final Invoice",
    value: "Final Invoice",
  },
  {
    label: "RCI Commencement",
    value: "RCI Commencement",
  },
  {
    label: "RCI Final",
    value: "RCI Final",
  },
  {
    label: "Welcome Email",
    value: "Welcome Email",
  },
  {
    label: "Extra",
    value: "Extra",
  },
  {
    label: "Estimate",
    value: "Estimate",
  },
  {
    label: "License",
    value: "License",
  },
  {
    label: "License Extension",
    value: "License Extension",
  },
];

export const pdfFilenameResourceListForBF = () => {
  return [
    { label: "Job Serial", value: "[job_serial]" },
    { label: "Org Serial", value: "[org_serial]" },
  ];
};

export const pdfFilenameResourceListForEstimate = () => {
  return [
    { label: "Job Serial", value: "[job_serial]" },
    { label: "Org Serial", value: "[org_serial]" },
    { label: "Paperwork Serial", value: "[paperwork_serial]" },
  ];
};

export const pdfFilenameResourceListForLicense = () => {
  return [
    { label: "Org Serial", value: "[org_serial]" },
    { label: "Paperwork Serial", value: "[paperwork_serial]" },
  ];
};

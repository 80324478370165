import React from "react";
import OverageEmailPreview from "./OverageEmailPreview";

const MailViewer = ({
  formik,
  jobDetail,
  host,
  token,
  companyDetails,
  revision,
  defaultTemplateSetting,
  emailBackground,
}) => {
  const docUrl = `${host}/overage?token=${token}`;

  return (
    <div className="w-3/5 overflow-y-scroll">
      <div
        className="p-6"
        style={{
          background: "#C2966F",
        }}
      >
        <OverageEmailPreview
          formik={formik}
          jobDetail={jobDetail}
          docUrl={docUrl}
          companyDetails={companyDetails}
          revision={revision}
          defaultTemplateSetting={defaultTemplateSetting}
          emailBackground={emailBackground}
        />
      </div>
    </div>
  );
};

export default MailViewer;

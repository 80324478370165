import React from "react";

import classNames from "classnames";

const TableWrapper = ({ children, className = "", hasPagination = false }) => (
  <div
    className={classNames(
      "flex-grow flex min-h-0 w-full flex-col pb-6",
      className
    )}
  >
    <div
      className={classNames("flex-grow min-h-0", {
        "pb-11": hasPagination,
      })}
    >
      {children}
    </div>
  </div>
);

export default TableWrapper;

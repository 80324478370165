import * as Yup from "yup";

export const VALIDATION_SCHEMA = Yup.object({
  organisationSerial: Yup.string().required("Organisation ID can't be empty"),
  name: Yup.string().required("Name can't be empty"),
  organisationTypeId: Yup.string().nullable(),
  streetAddress: Yup.string().nullable(),
  suburb: Yup.string().nullable(),
  state: Yup.string().nullable(),
  postcode: Yup.string().nullable(),
  country: Yup.string().nullable(),
  phoneNumber: Yup.string(),
});

export const INITIAL_VALUE = {
  name: "",
  organisationSerial: "",
  vendorId: "",
  organisationTypeId: "",
  streetAddress: "",
  suburb: "",
  state: "",
  postcode: "",
  country: "",
  paymentTerms: "",
  secondaryAddressDestroy: true,
  notes: "",
};

import React from "react";
import { Plus } from "@bigbinary/neeto-icons";
import { Label, Button, Typography } from "@bigbinary/neetoui";
import * as dayjs from "dayjs";
import { numberWithCommas } from "common/helper";

const EarlyBird = ({
  earlyBirdDueDate,
  taxPercentage,
  currency,
  taxTypeName,
  subtotal,
  setRciPercentageModal,
  earlyBirdFeePercentage,
}) => {
  const discountAmount = () => {
    return Number((subtotal * Number(earlyBirdFeePercentage)) / 100).toFixed(2);
  };

  const adjustedSubtotal = () => {
    return Number(subtotal - discountAmount()).toFixed(2);
  };

  const taxAmountOnSubtotal = () => {
    return Number((adjustedSubtotal() * taxPercentage) / 100).toFixed(2);
  };

  const totalAmount = () => {
    return (Number(adjustedSubtotal()) + Number(taxAmountOnSubtotal())).toFixed(
      2
    );
  };

  return (
    <div className="mb-12">
      <div className="flex justify-between mb-3">
        <Typography weight="semibold" style="h4">
          Early Bird Package
        </Typography>
        <Button
          style="link"
          icon={Plus}
          iconPosition="left"
          label="Add RCI Percentage"
          onClick={() => setRciPercentageModal(true)}
        />
      </div>

      <div className="p-6 bg-gray-100 rounded-t-md">
        <div className="flex justify-between p-5 my-4 bg-white rounded-md">
          <Label className="font-medium">Discount</Label>
          <Label className="pl-1 font-bold text-gray-800">
            {numberWithCommas(discountAmount())}
          </Label>
        </div>
        <div className="flex justify-between p-5 my-4 bg-white rounded-md">
          <Label className="font-medium">Adjusted Subtotal</Label>
          <Label className="pl-1 font-bold text-gray-800">
            {numberWithCommas(adjustedSubtotal())}
          </Label>
        </div>

        {taxTypeName?.length > 0 && (
          <div className="flex justify-between p-5 my-4 bg-white rounded-md">
            <Label className="font-medium">{taxTypeName}</Label>
            <Label className="pl-1 font-bold text-gray-800">
              {numberWithCommas(taxAmountOnSubtotal())}
            </Label>
          </div>
        )}
      </div>
      <div className="flex justify-end w-full px-6 py-3 bg-gray-200 rounded-b-md">
        <div className="flex flex-col flex-end">
          <div className="self-end pr-5 text-xl font-bold">
            Total : {numberWithCommas(totalAmount())} {currency}
          </div>

          <Label>
            (If paid by {dayjs(earlyBirdDueDate).format("MMMM DD, YYYY")})
          </Label>
        </div>
      </div>
    </div>
  );
};

export default EarlyBird;

import React, { useState, useEffect, useRef } from "react";
import {
  Delete,
  Edit,
  Repeat,
  Upload,
  Download,
  Loading,
} from "@bigbinary/neeto-icons";
import {
  Button,
  Spinner,
  Alert,
  Pane,
  Typography,
  Modal,
  Label,
  Toastr,
} from "@bigbinary/neetoui";
import * as dayjs from "dayjs";
import { Header, Container } from "@bigbinary/neetoui/layouts";
import { useParams, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import {
  getContacts,
  getContact,
  createContact,
  updateContact,
  destroyContact,
  importContacts,
  downloadContactsCsv,
} from "apis/contacts/contacts";
import { createOrganisationContact } from "apis/contacts/organisation_contacts";
import { getOrganisations } from "apis/organisations/organisations";
import { showToastrError } from "common";
import { dropDownListGenerator, removeDuplicacy, sleep } from "common/helper";
import {
  CONTACT_VALIDATION_SCHEMA,
  INITIAL_CONTACT_VALUE,
  IMPORT_CONTACT_VALIDATION_SCHEMA,
} from "./constants";
import AsyncPaginateSelect from "components/Common/AsyncPaginateSelect";
import AttachmentUploader from "components/Dashboard/Jobs/Details/Contracts/AttachmentUploader";
import ContactForm from "./ContactForm";
import Directory from "./Directory";
import Details from "./Details";

const People = () => {
  const { id } = useParams();
  const history = useHistory();
  const inputRef = useRef(null);
  const [newPane, setNewPane] = useState(false);
  const [editPane, setEditPane] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [contactListLoad, setContactListLoad] = useState(true);
  const [contactList, setContactList] = useState([]);
  const [contactDetail, setContactDetail] = useState({});
  const [contactDetailLoad, setContactDetailLoad] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [list, setList] = useState([]);
  const [deleteAlertOpen, serDeleteAlertOpen] = useState(false);
  const [organisationId, setOrganisationId] = useState("");
  const [orgPage, setOrgPage] = useState(1);
  const [organisationOptions, setOrganisationOptions] = useState([]);
  const [updateEmailAlertState, setUpdateEmailAlertState] = useState(false);
  const [importCsvAlert, setImportCsvAlert] = useState(false);
  const [initial, setInitial] = useState(true);
  const [btnLoader, setBtnLoader] = useState(false);
  const [downloadBtnLoader, setDownloadBtnLoader] = useState(false);

  useEffect(() => {
    loadContactListResponse();
  }, []);

  useEffect(() => {
    if (id) {
      loadContactDetailResponse(id);
    } else {
      setContactDetail();
    }
  }, [id]);

  useEffect(() => {
    if (!updateEmailAlertState && !initial) {
      loadContactDetailResponse(contactDetail.id);
    }
  }, [updateEmailAlertState]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_CONTACT_VALUE,
    validationSchema: CONTACT_VALIDATION_SCHEMA,
    onSubmit: values => onSubmit(values),
  });

  const importFormik = useFormik({
    initialValues: { file_data: "", filename: "" },
    validationSchema: IMPORT_CONTACT_VALIDATION_SCHEMA,
    onSubmit: () => importContactsResponse(),
  });

  const loadContactListResponse = async (searchParams = null, page = 1) => {
    try {
      const response = await getContacts(searchParams, page);
      setContactList(response.data.contacts);
      setTotalRecords(response.data.totalRecords);

      if (!id && response.data.contacts.length > 0) {
        history.push(`/people/${response.data.contacts[0].id}`);
      }

      setContactListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadContactDetailResponse = async contactId => {
    try {
      setContactDetailLoad(true);
      const response = await getContact(contactId);
      setContactDetail(response.data.contact);
      setContactDetailLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const onSubmit = async formValue => {
    try {
      const {
        firstName,
        lastName,
        pronouns,
        organisationContactId,
        showInCrm,
      } = formValue;
      let payload = {
        contact: {
          firstName: firstName,
          lastName: lastName,
          pronouns: pronouns,
          showInCrm: showInCrm,
          addedToCrmDate: showInCrm ? dayjs().format("YYYY-MM-DD") : null,
          organisation_contacts_attributes: [
            { ...formValue, id: organisationContactId },
          ],
        },
      };
      const response = await createContact(payload);
      setNewPane(false);
      loadContactDetailResponse(response.data.id);
      loadContactListResponse();
      formik.resetForm();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const onUpdate = async editingFormValue => {
    try {
      const {
        firstName,
        lastName,
        pronouns,
        organisationContactId,
        showInCrm,
      } = editingFormValue;
      let payload = {
        contact: {
          firstName: firstName,
          lastName: lastName,
          pronouns: pronouns,
          showInCrm: showInCrm,
          addedToCrmDate: showInCrm ? dayjs().format("YYYY-MM-DD") : null,
          organisation_contacts_attributes: [
            { ...editingFormValue, id: organisationContactId },
          ],
        },
      };
      await updateContact(contactDetail.id, payload);
      loadContactDetailResponse(contactDetail.id);
      setList([]);
      loadContactListResponse();
      setEditPane(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const editFormik = useFormik({
    enableReinitialize: true,
    initialValues: contactDetail
      ? {
          id: contactDetail.id,
          organisationId: contactDetail.organisationId,
          firstName: contactDetail.firstName,
          lastName: contactDetail.lastName,
          pronouns: contactDetail.pronouns,
          email: contactDetail.email,
          title: contactDetail.title,
          accountPayable: contactDetail.accountPayable,
          addToGooglePhonebook: contactDetail.addToGooglePhonebook,
          phoneNumber: contactDetail.phoneNumber,
          mobileNumber: contactDetail.mobileNumber,
          country: contactDetail.country,
          jobsCount: contactDetail.jobsCount,
          invoiceAmount: contactDetail.invoiceAmount,
          oldInvoiceTotal: contactDetail.oldInvoiceTotal,
          organisationContactId: contactDetail.organisationContactId,
          userId: contactDetail.userId,
          showInCrm: contactDetail.showInCrm,
          notes: contactDetail.notes,
        }
      : INITIAL_CONTACT_VALUE,
    validationSchema: CONTACT_VALIDATION_SCHEMA,
    onSubmit: values => onUpdate(values),
  });

  const onDelete = async contactId => {
    try {
      await destroyContact(contactId);
      setList([]);
      setContactDetail({});
      loadContactListResponse();
      history.push("/people");
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadOrganisationList = async search => {
    await sleep(1000);

    try {
      let filteredOptions;
      let totalRecords;

      if (!search) {
        const response = await getOrganisations("", orgPage);
        setOrganisationOptions([
          ...organisationOptions,
          ...response.data.organisations,
        ]);
        totalRecords = response.data.totalRecords;
        setOrgPage(orgPage + 1);
        filteredOptions = response.data.organisations;
      } else {
        const searchLower = search.toLowerCase();

        const response = await getOrganisations(searchLower, orgPage);
        setOrganisationOptions([
          ...organisationOptions,
          ...response.data.organisations,
        ]);
        setOrgPage(orgPage + 1);
        filteredOptions = response.data.organisations;
        totalRecords = filteredOptions.length;
      }
      return {
        options: dropDownListGenerator(removeDuplicacy(filteredOptions)),
        hasMore: filteredOptions.length === totalRecords ? false : true,
      };
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const moveContact = async () => {
    try {
      let payload = {
        organisation_contact: {
          ...contactDetail,
          organisationId: organisationId,
          contactId: contactDetail.id,
        },
      };
      await createOrganisationContact(payload);
      setModalState(false);
      setUpdateEmailAlertState(true);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const importContactsResponse = async () => {
    try {
      setBtnLoader(true);
      let payload = {
        import_contact: {
          ...importFormik.values,
        },
      };
      const { data } = await importContacts(payload);
      setImportCsvAlert(false);
      Toastr.info(data.message);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      setBtnLoader(false);
    }
  };

  const downloadContactsResponse = async () => {
    try {
      setDownloadBtnLoader(true);
      const { data } = await downloadContactsCsv();
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.download = `example_import_contacts.csv`;
      link.click();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      setDownloadBtnLoader(false);
    }
  };

  if (contactListLoad) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <Container isHeaderFixed>
      <Header
        title="People"
        actionBlock={
          <div className="flex items-center space-x-3">
            {contactDetail?.id && (
              <>
                <Button
                  style="danger-text"
                  label="Delete"
                  icon={Delete}
                  iconPosition="left"
                  onClick={() => serDeleteAlertOpen(true)}
                />
                <Button
                  style="secondary"
                  label="Move"
                  icon={Repeat}
                  iconPosition="left"
                  onClick={() => {
                    setModalState(true);
                  }}
                />
                <Button
                  style="secondary"
                  label="Edit"
                  icon={Edit}
                  iconPosition="left"
                  onClick={() => {
                    editFormik.resetForm();
                    setEditPane(true);
                  }}
                />
              </>
            )}
            <Button
              style="secondary"
              label="Import CSV"
              icon={Upload}
              iconPosition="left"
              onClick={() => setImportCsvAlert(true)}
            />
            <Button label="Add Contact" onClick={() => setNewPane(true)} />
          </div>
        }
      />
      <div className="flex w-full h-screen overflow-hidden border-t border-gray-200">
        {contactDetailLoad ? (
          <div className="flex items-center justify-center w-full">
            <Spinner />
          </div>
        ) : (
          <Details
            contactDetail={contactDetail}
            loadContactDetailResponse={loadContactDetailResponse}
          />
        )}
        <Directory
          contactList={contactList}
          loadContactListResponse={loadContactListResponse}
          contactDetail={contactDetail}
          list={list}
          setList={setList}
          totalRecords={totalRecords}
        />
      </div>
      <Pane
        isOpen={newPane || editPane}
        onClose={() => {
          editPane ? setEditPane(false) : setNewPane(false);
        }}
        initialFocusRef={inputRef}
      >
        <Pane.Header>
          <Typography style="h2" weight="semibold">
            {editPane ? "Edit Contact" : "Add Contact"}
          </Typography>
        </Pane.Header>
        <Pane.Body>
          <ContactForm
            inputRef={inputRef}
            formik={editPane ? editFormik : formik}
          />
        </Pane.Body>
        <Pane.Footer className="space-x-2">
          <Button
            label="Save Changes"
            onClick={editPane ? editFormik.handleSubmit : formik.handleSubmit}
          />
          <Button
            label="Cancel"
            style="text"
            onClick={() => {
              editPane ? setEditPane(false) : setNewPane(false);
            }}
          />
        </Pane.Footer>
      </Pane>

      <Modal isOpen={modalState} onClose={() => setModalState(false)}>
        <Modal.Header>
          <Typography style="h2" weight="semibold">
            Move Contact
          </Typography>
        </Modal.Header>

        <Modal.Body>
          <AsyncPaginateSelect
            label="Organisation"
            id="organisationId"
            placeholder="Select Organisation"
            value={
              dropDownListGenerator(removeDuplicacy(organisationOptions)).find(
                org => org.value === organisationId
              ) || null
            }
            isClearable={true}
            onChange={opt => {
              setOrganisationId(opt.value);
            }}
            loadOptions={loadOrganisationList}
          />
        </Modal.Body>
        <Modal.Footer className="space-x-2">
          <Button label="Move" onClick={() => moveContact()} />
          <Button
            label="Cancel"
            style="text"
            onClick={() => setModalState(false)}
          />
        </Modal.Footer>
      </Modal>

      <Modal
        isOpen={updateEmailAlertState}
        onClose={() => {
          setInitial(false);
          setUpdateEmailAlertState(false);
        }}
      >
        <Modal.Header>
          <Typography style="h2" weight="semibold">
            Reminder
          </Typography>
        </Modal.Header>
        <Modal.Body>
          <Typography style="body2" weight="normal">
            Contact has been successfully moved to new organisation. Don&apos;t
            forget to update the email address of new workplace.
          </Typography>
        </Modal.Body>
        <Modal.Footer>
          <Button
            label="Continue"
            onClick={() => {
              setInitial(false);
              setUpdateEmailAlertState(false);
            }}
          />
        </Modal.Footer>
      </Modal>

      <Modal
        isOpen={importCsvAlert}
        onClose={() => {
          setImportCsvAlert(false);
        }}
      >
        <Modal.Header>
          <Typography style="h2" weight="semibold">
            Import Contact CSV
          </Typography>
        </Modal.Header>
        <Modal.Body>
          <div className="flex flex-col space-y-2">
            <Label>Upload CSV(csv only).</Label>
            <AttachmentUploader
              name="CSV"
              type="csv"
              formValue={importFormik.values}
              setFormValue={importFormik.setFieldValue}
              accept="text/csv"
              kind="csv"
              initial={false}
            />
            {downloadBtnLoader ? (
              <div className="text-base font-medium hover:underline flex">
                <Loading size={20} className="mr-2 animate-spin-slow" />
                Download Sample Import CSV
              </div>
            ) : (
              <button
                className="text-base font-medium hover:underline flex"
                onClick={() => downloadContactsResponse()}
              >
                <Download size={20} className="mr-2" />
                Download Sample Import CSV
              </button>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            loading={btnLoader}
            label="Continue"
            onClick={() => importFormik.handleSubmit()}
          />
        </Modal.Footer>
      </Modal>

      <Alert
        isOpen={deleteAlertOpen}
        title="Delete Contact"
        message="Are you sure you want to delete this contact?"
        onClose={() => serDeleteAlertOpen(false)}
        onSubmit={() => {
          onDelete(contactDetail.id);
          serDeleteAlertOpen(false);
          setEditPane(false);
        }}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, delete"
      />
    </Container>
  );
};

export default People;

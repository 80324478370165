import React, { useState, useEffect } from "react";
import { Spinner, Button, Alert, Toastr } from "@bigbinary/neetoui";
import { Header, Container } from "@bigbinary/neetoui/layouts";
import { isEmpty } from "ramda";
import { useFormik } from "formik";
import {
  getTemplateSettings,
  getTemplateSetting,
  createTemplateSetting,
  updateTemplateSetting,
  destroyTemplateSetting,
} from "apis/settings/template_settings";
import { showToastrError } from "common";
import { getRandomNotFoundImage } from "common/helper";
import useDebounce from "common/debounce";
import { TEMPLATE_SETTINGS_VALIDATION_SCHEMA } from "../constants";
import List from "./List";
import FormPane from "./FormPane";
import EmptyState from "components/Common/EmptyState";

const TemplateSettings = ({ breadcrumbs }) => {
  const [templateSettingList, setTemplateSettingList] = useState([]);
  const [listLoader, setListLoader] = useState(true);
  const [templateSetting, setTemplateSetting] = useState({});
  const [modalState, setModalState] = useState(false);
  const [deleteAlertState, setDeleteAlertState] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [searchParams, setSearchParams] = useState();
  const debouncedSearchTerm = useDebounce(searchParams, 1000);
  const [emptyImage, setEmptyImage] = useState()

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: TEMPLATE_SETTINGS_VALIDATION_SCHEMA,
    initialValues: templateSetting?.id ? templateSetting : { default: false },
    onSubmit: () => {
      if (templateSetting?.id) {
        updateTemplateSettingEntry();
      } else {
        createTemplateSettingEntry();
      }
    },
  });

  useEffect(() => {
    loadtemplateSettingsList();
    setEmptyImage(getRandomNotFoundImage())
  }, []);

  useEffect(() => {
    if (debouncedSearchTerm != undefined) {
      loadtemplateSettingsList();
    }
  }, [debouncedSearchTerm]);

  const loadtemplateSettingsList = async () => {
    try {
      const response = await getTemplateSettings(searchParams);
      setTemplateSettingList(response.data.templateSettings || []);
      setListLoader(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const createTemplateSettingEntry = async () => {
    try {
      const response = await createTemplateSetting({
        template_setting: { ...formik.values },
      });
      setModalState(false);
      Toastr.info(response.data.notice);
      formik.resetForm();
      loadtemplateSettingsList();
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setButtonLoader(false);
    }
  };

  const updateTemplateSettingEntry = async () => {
    try {
      const response = await updateTemplateSetting(templateSetting?.id, {
        template_setting: { ...formik.values },
      });
      setModalState(false);
      Toastr.info(response.data.notice);
      formik.resetForm();
      loadtemplateSettingsList();
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setButtonLoader(false);
    }
  };

  const destroyTemplateSettingEntry = async () => {
    try {
      const response = await destroyTemplateSetting(templateSetting?.id);
      setDeleteAlertState(false);
      Toastr.info(response.data.notice);
      loadtemplateSettingsList();
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setButtonLoader(false);
    }
  };

  const getTemplateSettingEntry = async id => {
    try {
      const { data } = await getTemplateSetting(id);
      setTemplateSetting(data.templateSetting);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const handleNewButtonClick = async () => {
    setButtonLoader(false);
    formik.resetForm();
    setTemplateSetting({});
    setModalState(true);
  };

  const handleSubmitButtonClick = async () => {
    if (Object.keys(formik.errors).length === 0) {
      setButtonLoader(true);
      formik.handleSubmit();
    }
  };

  if (listLoader) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <Container>
      <Header
        title="Templates"
        breadcrumbs={breadcrumbs}
        searchProps={{
          value: searchParams,
          onChange: e => setSearchParams(e.target.value),
          clear: () => setSearchParams(),
        }}
        actionBlock={
          <Button label="Add New" onClick={() => handleNewButtonClick()} />
        }
      />

      <div className="w-full" style={{ height: "calc(100vh - 128px)" }}>
        {!isEmpty(templateSettingList) ? (
          <List
            templateSettingList={templateSettingList}
            setTemplateSetting={setTemplateSetting}
            getTemplateSettingEntry={getTemplateSettingEntry}
            setModalState={setModalState}
            setDeleteAlertState={setDeleteAlertState}
          />
        ) : (
          <EmptyState
            image={emptyImage}
            title="No Templates Found"
            description="We couldn’t find any templates. Try creating one."
            primaryButtonProps={{
              label: "Add New",
              onClick: () => handleNewButtonClick(),
            }}
          />
        )}
      </div>

      <FormPane
        isOpen={modalState}
        onClose={() => setModalState(false)}
        formik={formik}
        onSubmit={handleSubmitButtonClick}
        title={templateSetting?.id ? "Edit Template" : "Add Template"}
        buttonLoader={buttonLoader}
      />

      <Alert
        isOpen={deleteAlertState}
        title="Delete Template"
        message="Are you sure you want to delete the template?"
        onClose={() => setDeleteAlertState(false)}
        onSubmit={() => {
          setButtonLoader(true);
          destroyTemplateSettingEntry();
        }}
        isSubmitting={buttonLoader}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, delete"
      />
    </Container>
  );
};

export default TemplateSettings;

import React from "react";
import CobblerWhite from "images/CobblerWhite.png";

const Footer = ({
  companyDetails,
  account = {},
  footerLogo,
  emailBackground,
}) => {
  return (
    <div className="px-6 py-12 text-xs text-left text-white bg-black">
      <div className="footer-content">
        <div className="flex items-start justify-around">
          <div className="flex flex-col items-start mr-4">
            <div className="email-logo-container">
              <img src={footerLogo} alt="footer logo" />
            </div>
            {emailBackground?.companyNameInFooter && (
              <p className="text-xs break-word account-name-container">
                {account?.name}
              </p>
            )}
          </div>
          {companyDetails.map(company => {
            return (
              <div key={company.id} className="footer-center-item">
                <p className="text-xs">
                  <strong>{company.locationShortName}</strong>
                </p>
                <p
                  className="text-xs"
                  dangerouslySetInnerHTML={{
                    __html: company?.streetAddress?.split("\n").join("<br />"),
                  }}
                ></p>
                <p className="text-xs">
                  {[company?.suburb, company?.state, company?.postcode]
                    .filter(el => el !== null)
                    .join(", ")}
                </p>
                <p className="text-xs">{company?.locationName}</p>
                <p className="text-xs">{company.phoneNumber}</p>
              </div>
            );
          })}
        </div>
        <div className="flex flex-col items-center justify-center w-full mt-10">
          <p>Made With:</p>
          <a href="https://cobbler.app">
            <img src={CobblerWhite} className="w-32 mx-auto mt-2" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;

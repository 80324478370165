import React from "react";
import * as R from "ramda";
import { TermsTable, Table } from "../Shared";

const TermsandConditions = ({
  defaultTemplateSetting,
  templateTermsAndConditions,
  jobTypeSpecificTemplateSetting,
  cancellationFeeOverrideText,
  projectPhasesData,
  specialConditions,
}) => {
  const specialConditionData = specialConditions?.length > 0 && {
    section: "Special Conditions",
    description: specialConditions.join("<br/> <br />"),
  };
  return (
    <div className="flex flex-col items-start justify-between w-full py-12 lg:flex-row">
      <div
        className="static top-0 flex flex-col items-start justify-start w-full pr-0 lg:pr-12 lg:sticky lg:top-8 lg:w-1/3"
        style={{ fontFamily: "Modern Gothic" }}
      >
        <div className="flex flex-col items-start justify-start mb-8 lg:mb-0">
          <h2 className="text-xl font-medium">Terms and Conditions</h2>
          <div
            className="mt-6"
            dangerouslySetInnerHTML={{
              __html: defaultTemplateSetting?.termsHeaderText
                ?.split("\n")
                .join("<br>"),
            }}
          ></div>
        </div>
      </div>
      <div
        className="flex flex-col items-start justify-start w-full h-full space-y-8 lg:w-2/3"
        style={{ fontFamily: "Modern Gothic" }}
      >
        <div className="flex flex-col items-start justify-start w-full space-y-2 cancellation-fee-override">
          <h3 className="text-base font-medium text-black lg:text-xl lg:font-bold">
            Payment Terms
          </h3>
          <div
            className="text-base font-normal leading-6 text-black lg:text-xl"
            dangerouslySetInnerHTML={{
              __html: defaultTemplateSetting?.paymentTerms
                ?.split("\n")
                ?.join("<br>"),
            }}
          ></div>
        </div>

        {(R.isNil(jobTypeSpecificTemplateSetting) ||
          jobTypeSpecificTemplateSetting?.showKillFee) && (
          <div className="flex flex-col items-start justify-start w-full space-y-2">
            <h3 className="text-base font-medium text-black lg:text-xl lg:font-bold">
              Cancellation Fee Terms
            </h3>
            <div
              className="text-base font-normal leading-6 text-black cancellation-fee-override"
              dangerouslySetInnerHTML={{
                __html: cancellationFeeOverrideText?.split("\n")?.join("<br>"),
              }}
            ></div>

            <Table data={projectPhasesData()} type="bordered" />
          </div>
        )}
        <div className="flex flex-col w-full space-y-2 terms">
          {templateTermsAndConditions.map((term, index) => {
            return <TermsTable data={term} index={index + 1} key={index} />;
          })}
          {specialConditionData && (
            <TermsTable
              data={specialConditionData}
              index={templateTermsAndConditions?.length + 1}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TermsandConditions;

import axios from "axios";

const getDeliveryScopesUrl = id => {
  if (id) {
    return `/api/v1/settings/delivery_scopes/${id}`;
  }

  return `/api/v1/settings/delivery_scopes`;
};

export const getDeliveryScopes = (searchParams = "") => {
  let query = "";
  if (searchParams.length > 0) query = `q[name_i_cont_any]=${searchParams}`;
  return axios.get(`${getDeliveryScopesUrl()}?${query}`);
};

export const createDeliveryScope = payload => {
  return axios.post(getDeliveryScopesUrl(), payload);
};

export const updateDeliveryScope = (id, payload) => {
  return axios.put(getDeliveryScopesUrl(id), payload);
};

export const destroyDeliveryScope = id => {
  return axios.delete(getDeliveryScopesUrl(id));
};

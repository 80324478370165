import React from "react";
import { Button } from "../Shared";
import classNames from "classnames";

const ApproveQuestionButton = ({
  disabled,
  isVisible,
  sendApprovalMailToProduction,
  sendApprovalQuestionMailToProduction,
}) => {
  return (
    <div
      className={classNames(
        "fixed left-0 flex-col items-center justify-center w-full space-y-2 bottom-4",
        {
          "hidden ": !isVisible,
          "flex lg:hidden": isVisible,
        }
      )}
    >
      {disabled && (
        <div
          className="px-3 py-1 text-sm leading-4 text-black bg-white bg-opacity-75 rounded-full"
          style={{ fontFamily: "Modern Gothic Mono" }}
        >
          <span>Select usage option before approving</span>
        </div>
      )}
      <div
        className="grid w-full grid-cols-2 gap-4 px-4"
        style={{ fontFamily: "Modern Gothic Mono" }}
      >
        <Button
          className="w-full px-8 py-6 text-base font-medium text-center text-black opacity-100 rounded_container"
          style={{ backgroundColor: "#FCB613" }}
          onClick={() => sendApprovalMailToProduction()}
        >
          I Approve
        </Button>
        <Button
          className="w-full px-8 py-6 text-base font-medium text-center text-black bg-white border-2 border-black opacity-100 rounded_container"
          onClick={() => sendApprovalQuestionMailToProduction()}
        >
          Questions?
        </Button>
      </div>
    </div>
  );
};

export default ApproveQuestionButton;

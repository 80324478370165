import React from "react";
import { Input } from "@bigbinary/neetoui/formik";

const MarkupComponent = ({
  currency,
  isMarkupDisable,
  usageItemDisability,
  onMarkupPercentageChange,
  onMarkupTotalChange,
}) => (
  <>
    <Input
      type="number"
      label="Markup Percentage"
      name="Markup.percentage"
      disabled={isMarkupDisable() || usageItemDisability}
      onChange={e => onMarkupPercentageChange(e)}
    />
    <Input
      type="number"
      label={`Markup Total ${currency}`}
      name="Markup.rate"
      disabled={isMarkupDisable() || usageItemDisability}
      onChange={e => onMarkupTotalChange(e)}
    />
  </>
);

export default MarkupComponent;

import React from "react";
import * as dayjs from "dayjs";
import { Input, Label, Spinner, Switch, Typography } from "@bigbinary/neetoui";
import { numberWithCommas } from "common/helper";
import { PAYMENT_TYPE_VALUE } from "./constants";
import BillableComponent from "./BillableComponent";
import EarlyBird from "./EarlyBird";
import LatePayment from "./LatePayment";
import {
  totalInvoiceValueWithTax,
  taxAmount,
  filterAgencyItems,
  filterWithoutAgencyItems,
  filterExtraAgencyItems,
} from "./utils";

const Details = ({ invoice, invoiceDetailsLoad }) => {
  const asfEstimateItem = filterAgencyItems(invoice.invoiceItems || [])[0];
  const extraAsfItem = filterExtraAgencyItems(invoice.invoiceItems || [])[0];

  if (invoiceDetailsLoad) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex flex-col p-4 space-y-6 bg-white border rounded-md neeto-ui-border-gray-200 neeto-ui-shadow-xs">
      <div className="flex justify-between">
        <Typography style="h4" weight="semibold">
          Invoice Details
        </Typography>
      </div>
      <div className="grid grid-cols-3 gap-4">
        <Input
          label="Invoice Number"
          disabled={true}
          name="serialNumber"
          value={invoice.serialNumber}
        />
        <Input
          label="Issue Date"
          disabled={true}
          name="issueDate"
          value={dayjs(invoice.issueDate).format("DD/MM/YYYY")}
        />
        <Input
          label="Client Job Number"
          disabled={true}
          name="clientJobNumber"
          value={invoice.clientJobNumber}
        />
      </div>

      <div className="grid grid-cols-3 gap-4">
        <Input
          label="Payment Type"
          disabled={true}
          name="paymentType"
          value={
            PAYMENT_TYPE_VALUE.find(type => type.value === invoice.paymentType)
              ?.label
          }
        />
        <Input
          label="Processed Date"
          disabled={true}
          name="processedDate"
          value={
            invoice?.processedDate
              ? dayjs(invoice.processedDate).format("DD/MM/YYYY")
              : ""
          }
        />
        <Input
          label={invoice.final ? "Final %" : "Commencement %"}
          disabled={true}
          name="percentage"
          value={invoice.percentage}
        />
      </div>

      <div className="grid grid-cols-3 gap-4">
        <Input
          label="Purchase Order"
          disabled={true}
          name="purchaseOrder"
          value={invoice.purchaseOrder}
        />

        <Input
          label="Due Date"
          disabled={true}
          name="dueDate"
          value={dayjs(invoice.dueDate).format("DD/MM/YYYY")}
        />

        <Input
          label="Payment Terms"
          disabled={true}
          name="paymentTerms"
          value={invoice.paymentTerms === 0 ? "Due Now" : invoice.paymentTerms}
        />
      </div>

      <div className="grid grid-cols-3 gap-4">
        <Input
          className="col-span-1"
          label="Other Information"
          disabled={true}
          name="note"
          value={invoice.note}
        />

        {invoice.final && (
          <>
            <div className="flex items-end col-span-1 pb-1">
              <Switch
                label="Early Bird Package"
                checked={invoice.earlyBirdFee}
                disabled={true}
              />
            </div>

            <div className="flex items-end col-span-1 pb-1">
              <Switch
                label="Late Fee"
                checked={invoice.lateFee}
                disabled={true}
              />
            </div>
          </>
        )}
      </div>

      <div className="flex flex-col mb-6">
        <div className="w-full p-6 bg-gray-100 rounded-t-md">
          <div className="grid grid-cols-4 rounded-md">
            <div className="col-span-3"></div>
            <div className="col-span-1 text-right">
              <Typography weight="semibold" style="h4" className="mb-1">
                Total
              </Typography>
            </div>
          </div>

          {filterWithoutAgencyItems(invoice.invoiceItems || []).map(
            (item, index) => {
              return (
                <BillableComponent item={item} index={index} key={item.id} />
              );
            }
          )}

          {!invoice.final && asfEstimateItem && (
            <div className="flex justify-between p-5 my-4 bg-white rounded-md">
              <Label className="font-medium">Agency Service Fee</Label>
              <Label className="pl-1 font-bold text-gray-800">
                {numberWithCommas(
                  Number(asfEstimateItem?.amount || 0).toFixed(2)
                )}
              </Label>
            </div>
          )}

          {invoice.final && asfEstimateItem && (
            <div className="flex justify-between p-5 my-4 bg-white rounded-md">
              <Label className="font-medium">Agency Service Fee</Label>
              <Label className="pl-1 font-bold text-gray-800">
                {numberWithCommas(
                  (
                    Number(asfEstimateItem?.amount || 0) +
                    Number(extraAsfItem?.amount || 0)
                  ).toFixed(2)
                )}
              </Label>
            </div>
          )}

          <div className="flex justify-between p-5 my-4 bg-white rounded-md">
            <Label className="font-medium">Approved Fees</Label>
            <Label className="pl-1 font-bold text-gray-800">
              {numberWithCommas(Number(invoice.approvedFees).toFixed(2))}
            </Label>
          </div>

          {invoice.final && <hr />}

          {!invoice.final && (
            <div className="flex justify-between p-5 my-4 bg-white rounded-md">
              <Label className="font-medium">
                {invoice.percentage}% of Fees Due Now
              </Label>
              <Label className="pl-1 font-bold text-gray-800">
                {numberWithCommas(Number(invoice.total).toFixed(2))}
              </Label>
            </div>
          )}

          {invoice.final && (
            <div className="flex justify-between p-5 my-4 bg-white rounded-md">
              <Label className="font-medium">Fees Already Invoiced</Label>
              <Label className="pl-1 font-bold text-gray-800">
                -{numberWithCommas(Number(invoice.paidAmount).toFixed(2))}
              </Label>
            </div>
          )}

          {invoice.final && (
            <div className="flex justify-between p-5 my-4 bg-white rounded-md">
              <Label className="font-medium">Subtotal</Label>
              <Label className="pl-1 font-bold text-gray-800">
                {numberWithCommas(Number(invoice.total).toFixed(2))}
              </Label>
            </div>
          )}

          <div className="flex justify-between p-5 py-3 my-4 bg-white rounded-md">
            <div className="flex w-52">
              <Label className="pr-2 font-medium">Tax: </Label>
              <Input
                className="w-40 mr-2"
                disabled={true}
                name="taxType"
                isClearable={true}
                value={invoice.taxTypeName}
              />

              <Input
                className="w-36"
                disabled={true}
                name="tax"
                value={invoice.taxPercentage}
              />
            </div>

            <Label className="pl-1 font-bold text-gray-800">
              {numberWithCommas(
                Number(taxAmount(invoice.taxPercentage, invoice.total)).toFixed(
                  2
                )
              ) || 0.0}
            </Label>
          </div>
        </div>

        <div className="flex justify-end w-full px-6 py-3 bg-gray-200 rounded-b-md">
          <div className="flex flex-col flex-end">
            <div className="self-end text-xl font-bold">
              Total :{" "}
              {numberWithCommas(
                totalInvoiceValueWithTax(invoice.total, invoice.taxPercentage)
              )}
              &nbsp;
              {invoice.currency}
            </div>

            <Label>
              {invoice.final && (
                <>
                  (If paid by {dayjs(invoice.dueDate).format("MMMM DD, YYYY")})
                </>
              )}
            </Label>
          </div>
        </div>
      </div>

      {invoice.earlyBirdFee && (
        <EarlyBird
          earlyBirdDueDate={invoice.earlyBirdDueDate}
          taxPercentage={invoice.taxPercentage}
          subtotal={invoice.total}
          currency={invoice.currency}
          taxTypeName={invoice.taxTypeName}
          earlyBirdFeePercentage={invoice.earlyBirdFeePercentage}
        />
      )}

      {invoice.final && invoice.lateFee && (
        <LatePayment
          subtotal={invoice.total}
          taxPercentage={invoice.taxPercentage}
          currency={invoice.currency}
          taxTypeName={invoice.taxTypeName}
          dueDate={invoice.dueDate}
          lateFeePercentage={invoice.lateFeePercentage}
        />
      )}
    </div>
  );
};

export default Details;

import React, { useState, useEffect, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Edit, Delete } from "@bigbinary/neeto-icons";
import { Button, Spinner, Typography, Pane, Alert } from "@bigbinary/neetoui";
import { Header, Container } from "@bigbinary/neetoui/layouts";
import { useUserState } from "contexts/user";
import { useFormik } from "formik";
import {
  getStaffs,
  getStaff,
  createStaff,
  updateStaff,
  destroyStaff,
} from "apis/staffs/staffs";
import { showToastrError } from "common";
import StaffDetails from "./StaffDetails";
import StaffDirectory from "./StaffDirectory";
import StaffForm from "./StaffForm";
import { VALIDATION_SCHEMA, INITIAL_VALUE } from "./constants";

const Staffs = props => {
  const { user } = useUserState();
  const { id } = useParams();
  const history = useHistory();
  const inputRef = useRef(null);
  const [newPane, setNewPane] = useState(false);
  const [editPane, setEditPane] = useState(false);
  const [list, setList] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const [staffListLoading, setStaffListLoading] = useState(true);
  const [staffDetail, setStaffDetail] = useState({});
  const [staffDetailLoading, setStaffDetailLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [btnLoad, setBtnLoad] = useState(false);
  const [category, setCategory] = useState("");
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: newPane ? INITIAL_VALUE : staffDetail,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: () => {
      if (newPane) {
        onCreate();
      } else {
        onUpdate();
      }
    },
  });

  useEffect(() => {
    loadStaffListResponse();
  }, []);

  useEffect(() => {
    if (props.location.state?.category) {
      setCategory(props.location.state.category);
    }
  }, [props.location]);

  useEffect(() => {
    if (id) {
      loadStaffDetailResponse(id);
    } else {
      setStaffDetail({});
    }
  }, [id]);

  const loadStaffListResponse = async (searchParams = null, page = 1) => {
    try {
      const response = await getStaffs(searchParams, page);
      setStaffList(response.data.staffs);
      setTotalRecords(response.data.totalRecords);

      if (!id && response.data.staffs.length > 0) {
        history.push(`/staff/${response.data.staffs[0].id}`);
      }

      setCategory("");
      setStaffListLoading(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadStaffDetailResponse = async staffId => {
    try {
      setStaffDetailLoading(true);
      const response = await getStaff(staffId);
      setStaffDetail(response.data.staff);
      setStaffDetailLoading(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const onCreate = async () => {
    try {
      setBtnLoad(true);
      let payload = {
        staff: {
          ...formik.values,
        },
      };
      const response = await createStaff(payload);
      setNewPane(false);
      loadStaffDetailResponse(response.data.id);
      loadStaffListResponse();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      setBtnLoad(false);
    }
  };

  const onUpdate = async () => {
    try {
      setBtnLoad(true);
      let payload = {
        staff: {
          ...formik.values,
        },
      };
      await updateStaff(formik.values.id, payload);
      setList([]);
      loadStaffDetailResponse(formik.values.id);
      loadStaffListResponse("", 1);
      setEditPane(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      setBtnLoad(false);
    }
  };

  const onAccessUpdate = async access => {
    try {
      let payload = { staff: access };
      await updateStaff(staffDetail.id, payload);
      loadStaffDetailResponse(staffDetail.id);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const onDelete = async staffId => {
    try {
      await destroyStaff(staffId);
      setStaffDetail({});
      setList([]);
      loadStaffListResponse();
      history.push("/staff");
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  if (staffListLoading) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <Container isHeaderFixed>
      <Header
        title="Staff"
        actionBlock={
          <div className="flex items-center space-x-3">
            {(user.access === "admin" || user.access === "super_admin") && (
              <>
                <Button
                  style="danger-text"
                  label="Delete"
                  icon={Delete}
                  iconPosition="left"
                  onClick={() => setDeleteAlertOpen(true)}
                />
                <Button
                  style="secondary"
                  label="Edit"
                  icon={Edit}
                  iconPosition="left"
                  onClick={() => {
                    loadStaffDetailResponse(staffDetail.id);
                    setEditPane(true);
                  }}
                />
                <Button
                  label="Add Staff"
                  onClick={() => {
                    formik.resetForm();
                    formik.setFieldValue("location", "");
                    formik.setFieldValue("access", "");
                    setNewPane(true);
                  }}
                />
              </>
            )}
          </div>
        }
      />
      <div className="flex w-full h-screen overflow-hidden border-t border-gray-200">
        {staffDetailLoading ? (
          <div className="flex items-center justify-center w-full">
            <Spinner />
          </div>
        ) : (
          <StaffDetails
            staffDetail={staffDetail}
            loadStaffDetailResponse={loadStaffDetailResponse}
            setAccessChanged={onAccessUpdate}
            category={category}
            user={user}
          />
        )}
        <StaffDirectory
          staffList={staffList}
          loadStaffListResponse={loadStaffListResponse}
          staffDetail={staffDetail}
          totalRecords={totalRecords}
          setList={setList}
          list={list}
          setCategory={setCategory}
        />
      </div>

      <Pane
        isOpen={newPane || editPane}
        onClose={() => {
          editPane ? setEditPane(false) : setNewPane(false);
        }}
        initialFocusRef={inputRef}
      >
        <Pane.Header>
          <Typography style="h2" weight="semibold">
            {editPane ? "Edit Staff" : "Add Staff"}
          </Typography>
        </Pane.Header>
        <Pane.Body>
          <StaffForm inputRef={inputRef} formik={formik} />
        </Pane.Body>
        <Pane.Footer className="space-x-2">
          <Button
            label="Save Changes"
            onClick={() => formik.handleSubmit()}
            loading={btnLoad}
          />
          <Button
            label="Cancel"
            style="text"
            onClose={() => {
              editPane ? setEditPane(false) : setNewPane(false);
            }}
          />
        </Pane.Footer>
      </Pane>
      <Alert
        isOpen={deleteAlertOpen}
        onClose={() => setDeleteAlertOpen(false)}
        title="Delete Staff"
        message="Are you sure you want to delete this staff?"
        onSubmit={() => {
          onDelete(staffDetail?.id);
          setDeleteAlertOpen(false);
          setEditPane(false);
        }}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, delete"
      />
    </Container>
  );
};

export default Staffs;

import React, { useEffect, useState } from "react";
import { Spinner, Input, Select, Textarea, Label } from "@bigbinary/neetoui";
import SingleImageUploader from "components/Common/SingleImageUploader";
import { getTaxTypes } from "apis/settings/tax_types";
import { getXeroTrackingCategories } from "apis/artists/xero_tracking_categories";
import { showToastrError } from "common";
import { dropDownListGenerator } from "common/helper";

const EditInvoiceDetails = ({ formik }) => {
  const [taxTypes, setTaxTypes] = useState([]);
  const [taxTypeLoad, setTaxTypeLoad] = useState(true);
  const [xeroTokenExpired, setXeroTokenExpired] = useState(false);
  const [xeroTrackingCategoryList, setXeroTrackingCategoryList] = useState([]);
  const [
    xeroTrackingCategoryListLoad,
    setXeroTrackingCategoryListLoad,
  ] = useState(true);
  const [
    xeroRateTrackingCategoryOptions,
    setXeroRateTrackingCategoryOptions,
  ] = useState([]);
  const [
    xeroExpenseTrackingCategoryOptions,
    setXeroExpenseTrackingCategoryOptions,
  ] = useState([]);

  useEffect(() => {
    loadTaxTypes();
    loadXeroTrackingCategories();
  }, []);

  useEffect(() => {
    if (formik.values.xeroRateTrackingCategoryId && xeroTrackingCategoryList) {
      let rateTrackingCategoryOptions = xeroTrackingCategoryList.find(
        category => category.value === formik.values.xeroRateTrackingCategoryId
      )?.categoryOptions;

      if (rateTrackingCategoryOptions) {
        setXeroRateTrackingCategoryOptions(rateTrackingCategoryOptions);
      }
    }
  }, [formik.values.xeroRateTrackingCategoryId, xeroTrackingCategoryList]);

  useEffect(() => {
    if (
      formik.values.xeroExpenseTrackingCategoryId &&
      xeroTrackingCategoryList
    ) {
      let expenseTrackingCategoryOptions = xeroTrackingCategoryList.find(
        category =>
          category.value === formik.values.xeroExpenseTrackingCategoryId
      )?.categoryOptions;

      if (expenseTrackingCategoryOptions) {
        setXeroExpenseTrackingCategoryOptions(expenseTrackingCategoryOptions);
      }
    }
  }, [formik.values.xeroExpenseTrackingCategoryId, xeroTrackingCategoryList]);

  const loadTaxTypes = async () => {
    try {
      const { data } = await getTaxTypes();
      setTaxTypes(dropDownListGenerator(data.taxTypes));
      setTaxTypeLoad(false);
    } catch (error) {
      showToastrError(error);
    }
  };

  const loadXeroTrackingCategories = async () => {
    try {
      const { data } = await getXeroTrackingCategories();
      setXeroTokenExpired(data.xeroTokenExpired);
      setXeroTrackingCategoryList(
        dropDownListGenerator(data.xeroTrackingCategories)
      );
      setXeroTrackingCategoryListLoad(false);
    } catch (error) {
      showToastrError(error);
    }
  };

  return (
    <>
      {taxTypeLoad ? (
        <div
          className="flex items-center justify-center w-full"
          style={{ height: "calc(100vh - 208px)" }}
        >
          <Spinner />
        </div>
      ) : (
        <div
          className="px-6 pt-6 pb-12 space-y-6 overflow-y-auto"
          style={{ height: "calc(100vh - 208px)" }}
        >
          <Input
            label="Tax ID"
            name="abn"
            onChange={formik.handleChange}
            value={formik.values.abn}
            error={
              Boolean(formik.touched.abn && formik.errors.abn) &&
              formik.errors.abn
            }
            className="mb-3"
            {...formik.getFieldProps("abn")}
          />

          <Select
            label="Tax Type"
            isClearable={true}
            className="w-full"
            options={taxTypes}
            value={taxTypes.find(
              type =>
                formik.values.taxTypeId &&
                type.value === formik.values.taxTypeId
            )}
            onChange={option => {
              if (option) {
                formik.setFieldValue("taxTypeId", option.value);
                formik.setFieldValue("taxTypeName", option.label);
              } else {
                formik.setFieldValue("taxTypeId", null);
              }
            }}
          />

          <Input
            label="Name of Account"
            name="accountName"
            onChange={formik.handleChange}
            value={formik.values.accountName}
            error={
              Boolean(
                formik.touched.accountName && formik.errors.accountName
              ) && formik.errors.accountName
            }
            className="mb-3"
            {...formik.getFieldProps("accountName")}
          />

          <Textarea
            label="Account Number"
            name="accountNumber"
            onChange={formik.handleChange}
            value={formik.values.accountNumber}
            error={
              Boolean(
                formik.touched.accountNumber && formik.errors.accountNumber
              ) && formik.errors.accountNumber
            }
            className="mb-3"
            {...formik.getFieldProps("accountNumber")}
          />

          <Textarea
            label="House Accounts"
            name="houseAccounts"
            value={formik.values.houseAccounts}
            onChange={formik.handleChange}
            error={
              Boolean(
                formik.touched.houseAccounts && formik.errors.houseAccounts
              ) && formik.errors.houseAccounts
            }
            row="4"
            {...formik.getFieldProps("houseAccounts")}
          />

          <div className="flex flex-col space-y-2">
            <Label>HeadShot</Label>
            <SingleImageUploader
              name="Hero"
              type="hero"
              formValue={formik.values}
              setFormValue={formik.setFieldValue}
            />
          </div>

          <div className="flex flex-col space-y-2">
            <Label>Cover</Label>
            <SingleImageUploader
              name="Cover"
              type="cover"
              formValue={formik.values}
              setFormValue={formik.setFieldValue}
            />
          </div>

          <Input
            label="RCI Company Name"
            name="rciCompanyName"
            onChange={formik.handleChange}
            value={formik.values.rciCompanyName}
            error={
              Boolean(
                formik.touched.rciCompanyName && formik.errors.rciCompanyName
              ) && formik.errors.rciCompanyName
            }
            className="mb-3"
            {...formik.getFieldProps("rciCompanyName")}
          />

          <Select
            label="Xero Rate Tracking Category"
            name="xeroRateTrackingCategoryId"
            isLoading={xeroTrackingCategoryListLoad}
            isDisabled={xeroTrackingCategoryListLoad || xeroTokenExpired}
            id="xeroRateTrackingCategoryId"
            options={xeroTrackingCategoryList}
            value={xeroTrackingCategoryList.find(
              category =>
                category.value === formik.values.xeroRateTrackingCategoryId
            )}
            onChange={async opt => {
              await formik.setFieldValue(
                "xeroRateTrackingCategoryId",
                opt.value
              );
              setXeroRateTrackingCategoryOptions(opt.categoryOptions);
            }}
          />

          <Select
            label="Xero Rate Tracking Category Option"
            name="xeroRateTrackingCategoryOptionId"
            isLoading={xeroTrackingCategoryListLoad}
            isDisabled={xeroTrackingCategoryListLoad || xeroTokenExpired}
            id="xeroRateTrackingCategoryOptionId"
            options={dropDownListGenerator(xeroRateTrackingCategoryOptions)}
            value={dropDownListGenerator(xeroRateTrackingCategoryOptions).find(
              option =>
                option.value === formik.values.xeroRateTrackingCategoryOptionId
            )}
            onChange={async opt => {
              await formik.setFieldValue(
                "xeroRateTrackingCategoryOptionId",
                opt.value
              );
            }}
          />

          <Select
            label="Xero Expense Tracking Category"
            name="xeroExpenseTrackingCategory"
            isLoading={xeroTrackingCategoryListLoad}
            isDisabled={xeroTrackingCategoryListLoad || xeroTokenExpired}
            id="xeroAccountCode"
            options={dropDownListGenerator(xeroTrackingCategoryList)}
            value={dropDownListGenerator(xeroTrackingCategoryList).find(
              category =>
                category.value === formik.values.xeroExpenseTrackingCategoryId
            )}
            onChange={async opt => {
              await formik.setFieldValue(
                "xeroExpenseTrackingCategoryId",
                opt.value
              );
              setXeroExpenseTrackingCategoryOptions(opt.categoryOptions);
            }}
          />

          <Select
            label="Xero Expense Tracking Category Option"
            name="xeroExpenseTrackingCategoryOptionId"
            isLoading={xeroTrackingCategoryListLoad}
            isDisabled={xeroTrackingCategoryListLoad || xeroTokenExpired}
            id="xeroExpenseTrackingCategoryOptionId"
            options={dropDownListGenerator(xeroExpenseTrackingCategoryOptions)}
            value={dropDownListGenerator(
              xeroExpenseTrackingCategoryOptions
            ).find(
              option =>
                option.value ===
                formik.values.xeroExpenseTrackingCategoryOptionId
            )}
            onChange={async opt => {
              await formik.setFieldValue(
                "xeroExpenseTrackingCategoryOptionId",
                opt.value
              );
            }}
          />
        </div>
      )}
    </>
  );
};

export default EditInvoiceDetails;

import React from "react";
import { Label, Input, Select } from "@bigbinary/neetoui";
import RichText from "components/Common/RichText";

const MailEditor = ({ formik, host, token, licenseCount, jobDetail }) => {
  const docUrl =
    licenseCount > 1
      ? `${host}/estimate_multi?token=${token}`
      : `${host}/estimate?token=${token}`;

  return (
    <div className="w-2/5 h-full p-6 space-y-6 overflow-y-scroll">
      <Input
        label="From"
        name="from"
        disabled={true}
        value={formik.values.from?.fromName}
        required={true}
      />

      <Select
        options={jobDetail.allRecipients}
        value={formik.values.to}
        onChange={opt => formik.setFieldValue("to", opt)}
        required={true}
        placeholder="Select a recipient"
        label="To"
        error={
          Boolean(formik.touched.to && formik.errors.to) && formik.errors.to
        }
        isMulti
      />

      <Select
        options={jobDetail.allRecipients}
        value={formik.values.cc}
        onChange={opt => formik.setFieldValue("cc", opt)}
        placeholder="Select a recipient"
        label="CC"
        error={
          Boolean(formik.touched.cc && formik.errors.cc) && formik.errors.cc
        }
        isMulti
      />

      <Select
        options={jobDetail.allRecipients}
        value={formik.values.bcc}
        onChange={opt => formik.setFieldValue("bcc", opt)}
        placeholder="Select a recipient"
        label="BCC"
        error={
          Boolean(formik.touched.bcc && formik.errors.bcc) && formik.errors.bcc
        }
        isMulti
      />

      <Input
        label="Subject"
        name="subject"
        onChange={formik.handleChange}
        value={formik.values.subject}
        required={true}
        error={
          Boolean(formik.touched.subject && formik.errors.subject) &&
          formik.errors.subject
        }
        {...formik.getFieldProps("subject")}
      />

      <Input label="Attachment" name="attachment" value={docUrl} />

      <div className="flex flex-col space-y-2">
        <Label>Body</Label>
        <RichText
          value={formik.values.body}
          handleChange={e => formik.setFieldValue("body", e.target.value)}
        />
      </div>
    </div>
  );
};

export default MailEditor;

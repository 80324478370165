import * as Yup from "yup";

export const FILTER_SPECIFICATION_VALIDATION_SCHEMA = Yup.object({
  name: Yup.string().required("Name can't be empty"),
});

export const FILTER_SPECIFICATION_INITIAL_VALUE = {
  name: "",
};

export const ACTION_TAG_STATUS_LIST = [
  { label: "Open", value: "open" },
  {
    label: "Completed", value: "completed"
  }
]

import axios from "axios";

const getProjectScopesUrl = id => {
  if (id) {
    return `/api/v1/settings/project_scopes/${id}`;
  }

  return `/api/v1/settings/project_scopes`;
};

export const getProjectScopes = (searchParams = "") => {
  let query = "";
  if (searchParams.length > 0) query = `q[name_i_cont_any]=${searchParams}`;
  return axios.get(`${getProjectScopesUrl()}?${query}`);
};

export const createProjectScope = payload => {
  return axios.post(getProjectScopesUrl(), payload);
};

export const updateProjectScope = (id, payload) => {
  return axios.put(getProjectScopesUrl(id), payload);
};

export const destroyProjectScope = id => {
  return axios.delete(getProjectScopesUrl(id));
};

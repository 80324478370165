import * as Yup from "yup";
import { MAX_STRING_LENGTH } from "common/constants";

export const VALIDATION_SCHEMA = Yup.object({
  sendApprovalToProduction: Yup.bool(),
  name: Yup.string()
    .required("Name can't be empty.")
    .max(
      MAX_STRING_LENGTH,
      `Name must be at most ${MAX_STRING_LENGTH} characters.`
    )
    .nullable(),
  basecampAccountName: Yup.string()
    .max(
      MAX_STRING_LENGTH,
      `Basecamp account name must be at most ${MAX_STRING_LENGTH} characters.`
    )
    .nullable(),
  subdomain: Yup.string()
    .max(
      MAX_STRING_LENGTH,
      `Subdomain must be at most ${MAX_STRING_LENGTH} characters.`
    )
    .required("Subdomain can't be empty.")
    .nullable(),
  approvalEmail: Yup.string()
    .when(["sendApprovalToProduction"], (sendApprovalToProduction) => {
      return sendApprovalToProduction ? Yup.string().required("Approval email can't be empty") : Yup.string().nullable()
    }),
});

export const INITIAL_VALUE = {
  name: "",
  approvalEmail: "",
  basecampAccountName: "",
  subdomain: "",
  sendApprovalToProduction: false,
};

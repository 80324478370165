import React, { useState, useEffect, useContext } from "react";
import {
  Typography,
  Label,
  Modal,
  Button,
  Select,
  Spinner,
  Accordion,
} from "@bigbinary/neetoui";
import { toast } from "react-toastify";
import { showToastrError } from "common";
import { getBasecampTokenPresence } from "apis/settings/basecamp_sessions";
import { jobPhasesPushToBasecamp } from "apis/jobs/push_to_basecamps";
import { updateEstimateRevision } from "apis/jobs/estimate_revisions";
import PhaseItem from "./PhaseItem";
import { getPhaseTypes } from "apis/settings/phase_types";
import { dropDownListGenerator } from "common/helper";
import { OverviewDetailsContext } from "..";

const JobPhases = ({
  phaseTypeName,
  phaseTypeId,
  jobDetail,
  revisionDetail,
  loadEstimateRevision,
}) => {
  const { revisionId } = useContext(OverviewDetailsContext);
  const [selectedPhaseType, setSelectedPhaseType] = useState({
    name: phaseTypeName,
  });
  const [phaseTypeList, setPhaseTypeList] = useState([]);
  const [phaseTypeListLoad, setPhaseTypeListLoad] = useState(true);
  const [basecampTokenStatusLoad, setBasecampTokenStatusLoad] = useState(true);
  const [basecampTokenStatus, setBasecampTokenStatus] = useState();
  const [basecampPushLoad, setBasecampPushLoad] = useState(false);
  const [confirmBasecampPush, setConfirmBasecampPush] = useState(false);
  const [addPhase, setAddPhase] = useState(false);
  const [initial, setInitial] = useState(true);
  const [openedPhase, setOpenedPhase] = useState(false);
  const [phaseCount, setPhaseCount] = useState(0);

  useEffect(() => {
    loadPhaseTypeList();
    getBasecampTokenStatus();
  }, []);

  useEffect(() => {
    if (revisionDetail) {
      setPhaseCount(revisionDetail.phaseCount);
    }
  }, [revisionDetail]);

  useEffect(() => {
    setSelectedPhaseType({
      ...selectedPhaseType,
      name: phaseTypeName,
      id: phaseTypeId,
    });
  }, [phaseTypeName, phaseTypeId]);

  const updateRevisionPhase = async phaseTypeId => {
    try {
      let payload = {
        estimate_revision: {
          phaseTypeId: phaseTypeId,
        },
      };
      await updateEstimateRevision(jobDetail.id, revisionId, payload);
      setOpenedPhase(true);
      loadEstimateRevision();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadPhaseTypeList = async () => {
    try {
      const response = await getPhaseTypes();
      setPhaseTypeList(dropDownListGenerator(response.data.phaseTypes));
      setPhaseTypeListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const getBasecampTokenStatus = async () => {
    try {
      setBasecampTokenStatusLoad(true);
      const { data } = await getBasecampTokenPresence();
      setBasecampTokenStatus(data.basecampSessionPresence);
      setBasecampTokenStatusLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const phasesPushTobasecampResponse = async () => {
    try {
      setBasecampPushLoad(true);
      const { data } = await jobPhasesPushToBasecamp(revisionId, phaseTypeId);
      toast.info(data.notice, {
        position: "bottom-left",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      setConfirmBasecampPush(false);
      setBasecampPushLoad(false);
    }
  };

  if (phaseTypeListLoad || basecampPushLoad || basecampTokenStatusLoad) {
    return (
      <div className="flex items-center justify-center w-full h-20">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <div className="flex justify-between w-full mb-3">
        <Select
          name="phaseType"
          id="phaseType"
          label="Choose Milestone Type"
          isClearable={true}
          options={phaseTypeList}
          onChange={async selectedOption => {
            await updateRevisionPhase(
              selectedOption?.value ? selectedOption.value : null
            );
            setSelectedPhaseType({
              ...selectedPhaseType,
              name: selectedOption?.label,
              id: selectedOption?.value,
            });
          }}
          value={phaseTypeList.find(
            option => option.label === selectedPhaseType.name
          )}
        />
        {basecampTokenStatus && jobDetail.basecampProjectId && (
          <div className="flex items-end">
            <Button
              label="Push to Basecamp"
              className="ml-2"
              onClick={() => setConfirmBasecampPush(true)}
            />
          </div>
        )}
      </div>

      <Accordion.Item
        isOpen={openedPhase}
        onClick={() => setOpenedPhase(!openedPhase)}
        className="border-none"
        title={
          <div className="flex justify-between w-full mr-3">
            <Label className="font-bold">Milestones</Label>

            {phaseCount === 0 && !openedPhase && (
              <Button
                style="link"
                label="Add Milestone"
                onClick={() => {
                  setInitial(false);
                  setAddPhase(true);
                  setOpenedPhase(true);
                }}
              />
            )}
          </div>
        }
      >
        <PhaseItem
          jobDetail={jobDetail}
          revisionId={revisionId}
          selectedPhaseType={selectedPhaseType}
          addPhase={addPhase}
          setAddPhase={setAddPhase}
          initial={initial}
          loadEstimateRevision={loadEstimateRevision}
          setPhaseCount={setPhaseCount}
        />
      </Accordion.Item>

      <Modal
        isOpen={confirmBasecampPush}
        onClose={() => setConfirmBasecampPush(false)}
      >
        <Modal.Header>
          <Typography style="h2" weight="semibold">
            Basecamp Push!
          </Typography>
        </Modal.Header>
        <Modal.Body>Are you sure you want to push the phases to Basecamp?</Modal.Body>
        <Modal.Footer className="space-x-2">
          <Button
            label="Push!"
            onClick={() => phasesPushTobasecampResponse()}
          />
          <Button
            style="text"
            label="Cancel"
            onClick={() => setConfirmBasecampPush(false)}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default JobPhases;

export const BANK_FORMAT_LIST = [
  { label: "AU", value: "AU" }, 
  { label: "UK", value: "UK" }, 
  { label: "USA", value: "USA" }
]

export const INVOICE_STATUS_LIST = [
  { label: "Draft", value: "DRAFT" }, 
  { label: "Submitted", value: "SUBMITTED" }, 
  { label: "Approved", value: "APPROVED" }
]
import React, { Component } from "react";
import classnames from "classnames";
import { Label } from "@bigbinary/neetoui";
import Trix from "trix";

import "./ConfiguredTrix";
import EditorToolbar from "./EditorToolbar";
import MentionsController from "./MentionsController";

export default class RichText extends Component {
  state = {
    editorHeight: 200,
    isSticky: false,
    maxHeight: 300,
  };

  componentDidMount() {
    const {
      handleChange,
      handleFocus,
      handleBlur,
      focusEditor,
      editorHeight,
      maxEditorHeight,
      fixToolbar,
    } = this.props;

    if (focusEditor) this.editor.focus();
    if (fixToolbar) window.addEventListener("scroll", this.handleScroll, true);

    this.editor.addEventListener("trix-change", handleChange);
    this.editor.addEventListener("trix-focus", handleFocus);
    this.editor.addEventListener("trix-blur", handleBlur);
    this.editor.addEventListener("trix-attachment-add", this.uploadImage);
    this.editor.addEventListener("trix-initialize", this.handleTrixInit);

    this.setState({
      editorHeight: editorHeight
        ? editorHeight
        : this.getProposedEditorHeight() || this.state.editorHeight,
    });

    this.setState({
      maxHeight: maxEditorHeight ? maxEditorHeight : this.state.maxHeight,
    });

    this.editor.addEventListener("trix-action-invoke", event => {
      if (event.actionName == "x-horizontal-rule") {
        const { editor } = event.target;
        const attachment = new Trix.Attachment({
          content: "<hr />",
          contentType: "application/vnd.trix.horizontal-rule.html",
        });
        editor.insertAttachment(attachment);
      }
    });
  }

  componentWillUnmount() {
    const { handleChange, handleFocus, handleBlur } = this.props;

    this.editor.removeEventListener("trix-change", handleChange);
    this.editor.removeEventListener("trix-focus", handleFocus);
    this.editor.removeEventListener("trix-blur", handleBlur);
    this.editor.removeEventListener("trix-attachment-add", this.uploadImage);
    if (this.props.fixToolbar)
      window.removeEventListener("scroll", this.handleScroll);
  }

  handleTrixInit = event => {
    const { allowMentions, fetchUsers } = this.props;
    if (allowMentions) new MentionsController(event.target, fetchUsers);
  };

  handleScroll = () => {
    if (this.props.fixToolbar && this.trix) {
      const { top, bottom } = this.trix.getBoundingClientRect();
      const toolBarHeight = this.trix.firstElementChild.offsetHeight;
      const fixOffsetTop = this.props.fixOffsetTop || 0;

      if (
        !this.state.isSticky &&
        top < fixOffsetTop &&
        bottom > toolBarHeight
      ) {
        this.setState({ isSticky: true });
      } else if (
        this.state.isSticky &&
        (top > fixOffsetTop || bottom < toolBarHeight)
      ) {
        this.setState({ isSticky: false });
      }
    }
  };

  insertImage = () => {
    const editorNode = this.editor;
    const fileInput = document.createElement("input");

    fileInput.setAttribute("type", "file");
    fileInput.setAttribute("multiple", "");
    fileInput.setAttribute("accept", "image/*");

    fileInput.addEventListener("change", function () {
      const files = Array.from(this.files);

      return files.map(file => editorNode.editor.insertFile(file));
    });

    fileInput.click();
  };

  uploadImage = event => {
    const { attachment } = event;

    if (attachment.file) {
      // const upload = new AttachmentUpload(attachment, target);
      // upload.start();
    }
  };

  getProposedEditorHeight = () => {
    const editor = document.querySelector("trix-editor");

    if (editor) {
      const topOffset = editor.getBoundingClientRect().top;
      const viewportHeight = Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 0
      );
      const bottomMargin = 10;

      return viewportHeight - topOffset - bottomMargin;
    }
  };

  render() {
    const { editorHeight, maxHeight, isSticky } = this.state;
    const {
      id = id,
      name,
      value,
      label,
      labelProps,
      helpText,
      required,
      dataTestId,
      error,
      className,
      backgroundColor,
      placeholderFor,
      placeholderVariables,
      ...otherProps
    } = this.props;

    let editorStyles = {
      minHeight: editorHeight,
      maxHeight: maxHeight,
      overflow: "auto",
    };

    let toolbarStyles = {};
    if (isSticky) {
      toolbarStyles = {
        position: "fixed",
        zIndex: 1,
        top: this.props.fixOffsetTop || 0,
        left: this.trix?.getBoundingClientRect().x + 1,
        width: this.trix?.offsetWidth - 2,
        borderTop: "1px solid #e2e3e4",
        borderBottom: "1px solid #e2e3e4",
        boxShadow: "0 0 5px rgb(17 17 17 / 20%)",
        borderRadius: "0 0 5px 5px",
      };
    }

    return (
      <div
        className={classnames([
          "flex flex-col items-start justify-start flex-grow",
          className,
        ])}
      >
        {label && (
          <Label
            className="mb-2"
            required={required}
            helpText={helpText}
            {...labelProps}
          >
            {label}
          </Label>
        )}
        <div
          className={classnames(["trix w-full", backgroundColor])}
          ref={element => (this.trix = element)}
        >
          <EditorToolbar
            id={`editorToolbar_${id}`}
            toolbarStyles={toolbarStyles}
            insertImage={this.insertImage}
            name={name}
            placeholderFor={placeholderFor}
            placeholderVariables={placeholderVariables}
          />
          <input
            id={`editor_${id}`}
            type="hidden"
            value={value}
            name="content"
          />
          <trix-editor
            name={name}
            id={`trixEditor_${id}`}
            input={`editor_${id}`}
            style={editorStyles}
            toolbar={`editorToolbar_${id}`}
            ref={element => (this.editor = element)}
            data-test-id={dataTestId}
            {...otherProps}
          />
        </div>
        {!!error && <p className="mt-1 text-xs text-red-600">{error}</p>}
      </div>
    );
  }
}

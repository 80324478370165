import * as axios from "axios";

export const createContactActivityLogs = payload => {
  return axios.post(`api/v1/crms/contact_activity_logs`, payload);
};

export const destroyContactActivityLogs = (filterAttr = {}, crmActionTagIds) => {
  return axios.post(`api/v1/crms/contact_activity_logs/bulk_destroy?${filterAttr}`, {
    contact_activity_logs: { crm_action_tag_ids: crmActionTagIds }});
};

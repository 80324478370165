import React, { useState, useEffect, useCallback, useRef } from "react";
import { Search, MenuHorizontal } from "@bigbinary/neeto-icons";
import {
  Input,
  Dropdown,
  Button,
  Spinner,
  Typography,
  Textarea,
  Modal,
  Alert,
  Pagination,
  Tag,
} from "@bigbinary/neetoui";
import { SubHeader } from "@bigbinary/neetoui/layouts";
import { isEmpty } from "ramda";
import { showToastrError } from "common";
import useDebounce from "common/debounce";
import { useUserState } from "contexts/user";
import {
  DEFAULT_PAGE_SIZE,
  sortedByCreation,
  getRandomNotFoundImage,
  artistModuleName,
} from "common/helper";
import { getSocialJobs } from "apis/post_productions/socials";
import { getStaffs } from "apis/artists/staffs";
import { updateJob } from "apis/post_productions/jobs";
import { createPostProductionNote } from "apis/post_productions/post_production_notes";
import EmptyState from "components/Common/EmptyState";
import StaffDropdown from "./StaffDropdown";
import RenderNotes from "./RenderNotes";

const Social = () => {
  const { user } = useUserState();
  const inputRef = useRef(null);
  const [searchParams, setSearchParams] = useState();
  const debouncedSearchTerm = useDebounce(searchParams, 500);
  const [jobList, setJoblist] = useState([]);
  const [jobListLoading, setjobListLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [jobId, setJobId] = useState();
  const [addNote, setAddNote] = useState(false);
  const [note, setNote] = useState("");
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [userId, setUserId] = useState("");
  const [staffOptions, setStaffOptions] = useState([]);
  const [staffOptionLoad, setStaffOptionLoad] = useState(true);
  const [initial, setInitial] = useState(true);
  const [buttonLoader, setButtonLoader] = useState(false);
  const { Menu, MenuItem } = Dropdown;
  const [emptyImage, setEmptyImage] = useState();

  const keydownHandler = useCallback(event => {
    event.preventDefault();

    if (event.code === "Space") {
      setPageIndex(event.currentTarget.pageIndex);
      onUpdate(event.currentTarget.jobId, event.currentTarget.pageIndex);
    }
  }, []);

  useEffect(() => {
    setEmptyImage(getRandomNotFoundImage());
  }, []);

  useEffect(async () => {
    if (user) {
      setUserId(user.id);
      await loadStaffListResponse();
    }
  }, [user]);

  useEffect(() => {
    if (userId) {
      loadJobListResponse(userId, true);
    }
  }, [userId]);

  useEffect(() => {
    if (pageIndex && !initial) {
      loadJobListResponse();
    }
  }, [pageIndex]);

  useEffect(() => {
    if (debouncedSearchTerm != undefined) {
      loadJobListResponse();
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (!deleteAlertOpen) {
      window.removeEventListener("keydown", keydownHandler);
    }
  }, [deleteAlertOpen]);

  const loadStaffListResponse = async () => {
    try {
      const response = await getStaffs();
      setStaffOptions(response.data.staffs);
      setStaffOptionLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadJobListResponse = async (
    selectedUserId = userId,
    load = false,
    pageNo = pageIndex
  ) => {
    try {
      if (load) setjobListLoading(true);
      const response = await getSocialJobs(
        searchParams,
        pageNo,
        DEFAULT_PAGE_SIZE,
        selectedUserId
      );
      setJoblist(response.data.jobs);
      setTotalRecords(response.data.totalRecords);
      setjobListLoading(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const onUpdate = async (id, pageNo) => {
    try {
      setButtonLoader(true);
      await updateJob(id, { job: { socials: null } });
      setDeleteAlertOpen(false);
      setJobId();
      window.removeEventListener("keydown", keydownHandler);
      loadJobListResponse(userId, false, pageNo);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      setButtonLoader(false);
    }
  };

  const onNoteCreate = async () => {
    try {
      setButtonLoader(true);
      await createPostProductionNote(jobId, {
        post_production_note: { note: note },
      });
      setAddNote(false);
      setNote("");
      loadJobListResponse();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      setButtonLoader(false);
    }
  };

  if (jobListLoading || staffOptionLoad) {
    return (
      <div
        className="flex items-center justify-center w-full"
        style={{ height: "calc(100vh - 184px)" }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <SubHeader
        className="px-6 pt-4"
        leftActionBlock={
          <Typography style="h4" weight="semibold" component="h4">
            {totalRecords > 0 && totalRecords} Jobs
          </Typography>
        }
        rightActionBlock={
          <div className="flex items-center space-x-3">
            <Input
              type="search"
              placeholder="Search"
              className="neeto-ui-header__search-input"
              prefix={<Search size={16} />}
              value={searchParams}
              onChange={e => setSearchParams(e.target.value)}
              clear={() => setSearchParams("")}
            />
            <StaffDropdown
              setUserId={setUserId}
              userId={userId}
              staffOptions={staffOptions}
              loadJobListResponse={loadJobListResponse}
            />
          </div>
        }
      />
      <div
        className="w-full px-6 pb-6 overflow-y-auto"
        style={{ height: "calc(100vh - 184px - 70px)" }}
      >
        {!isEmpty(jobList) ? (
          <div className="grid w-full grid-cols-4 gap-4">
            {jobList.map(job => {
              const {
                id,
                serialNumber,
                name,
                organisationName,
                postProductionNotes,
                attachment,
              } = job;
              return (
                <div
                  key={serialNumber}
                  className="transition-all border rounded-md neeto-ui-bg-white neeto-ui-border-gray-300 hover:neeto-ui-border-gray-400 hover:neeto-ui-shadow-sm"
                >
                  <div className="flex flex-col w-full">
                    {attachment?.thumbnail ? (
                      <div className="w-full h-40 overflow-hidden bg-gray-100 rounded-t-md">
                        <img
                          className="w-full h-full transition-all rounded-t-md jw-pp-thumbnail-image"
                          src={attachment?.thumbnail}
                        />
                      </div>
                    ) : (
                      <div className="flex flex-col items-center justify-center w-full h-40 bg-gray-100 rounded rounded-t-md">
                        <Typography style="body2" className="text-center">
                          No Thumbnail
                        </Typography>
                      </div>
                    )}
                    <div className="relative flex flex-col p-4">
                      <Typography style="body2">{serialNumber}</Typography>
                      <Button
                        style="link"
                        to={`/jobs/${id}/overview`}
                        label={name}
                        className="mt-2"
                      />
                      <div className="block mt-2">
                        <Tag style="primary" label={organisationName} />
                      </div>

                      <Dropdown
                        autoWidth
                        closeOnSelect
                        trigger="hover"
                        position="top"
                        customTarget={
                          <p className="mt-2 w-48 truncate">
                            {job.artists.map((artist, index) => {
                              return (
                                <>
                                  {artist.name}
                                  {index !== job.artists.length - 1 ? ", " : ""}
                                </>
                              );
                            })}
                          </p>
                        }
                      >
                        <Menu>
                          {job.artists.map(artist => {
                            return (
                              <MenuItem.Button
                                className="no-underline"
                                key={artist.id}
                                to={`/${artistModuleName().toLowerCase()}/${artist.id}`}
                              >
                                {artist.name}
                              </MenuItem.Button>
                            );
                          })}
                        </Menu>
                      </Dropdown>

                      <div className="absolute flex justify-end flex-shrink-0 gap-x-2 top-2 right-2">
                        {postProductionNotes && (
                          <RenderNotes
                            notes={sortedByCreation(
                              postProductionNotes
                            ).reverse()}
                            setAddNote={setAddNote}
                            jobId={id}
                            setJobId={setJobId}
                          />
                        )}
                        <Dropdown
                          autoWidth
                          closeOnSelect
                          buttonProps={{
                            size: "small",
                            style: "text",
                          }}
                          strategy="fixed"
                          appendTo={() => document.body}
                          icon={MenuHorizontal}
                        >
                          <Menu>
                            <MenuItem.Button
                              onClick={() => {
                                setAddNote(true);
                                setJobId(id);
                              }}
                            >
                              Add Note
                            </MenuItem.Button>
                            <MenuItem.Button
                              onClick={() => {
                                setJobId(id);
                                setDeleteAlertOpen(true);
                                window.pageIndex = pageIndex;
                                window.jobId = id;
                                window.addEventListener(
                                  "keydown",
                                  keydownHandler
                                );
                              }}
                            >
                              Complete Job
                            </MenuItem.Button>
                          </Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <EmptyState image={emptyImage} title="No Jobs Found" />
        )}
      </div>

      <div className="fixed bottom-0 right-0  flex justify-between px-6 py-4 text-right bg-white neeto-ui-border-gray-200">
        <div className="flex items-center space-x-2">
          <Pagination
            count={totalRecords}
            navigate={index => {
              setInitial(false);
              setPageIndex(index);
            }}
            pageNo={pageIndex}
            pageSize={DEFAULT_PAGE_SIZE}
          />
        </div>
      </div>

      <Alert
        isOpen={deleteAlertOpen}
        title="Complete Job"
        message="Are you sure you want to complete this job?"
        onClose={() => setDeleteAlertOpen(false)}
        onSubmit={() => onUpdate(jobId)}
        submitButtonLabel="Yes, proceed"
        cancelButtonLabel="No, cancel"
        isSubmitting={buttonLoader}
      />

      <Modal
        isOpen={addNote}
        onClose={() => setAddNote(false)}
        initialFocusRef={inputRef}
      >
        <Modal.Header>
          <Typography style="h2" weight="semibold">
            Add Note
          </Typography>
        </Modal.Header>

        <Modal.Body>
          <Textarea
            label="Note"
            name="note"
            value={note}
            ref={inputRef}
            onChange={e => setNote(e.target.value)}
          />
        </Modal.Body>

        <Modal.Footer className="space-x-2">
          <Button
            label="Save"
            onClick={() => onNoteCreate()}
            loading={buttonLoader}
          />
          <Button
            style="text"
            label="Cancel"
            onClick={() => setAddNote(false)}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Social;

import React from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import { buildUrl } from "../utils";
import routes from "../routes";
import { Header, Container } from "@bigbinary/neetoui/layouts";
import { ESTIMATE_LINE_ITEMS_LIST } from "../constants";

import EstimateLineItem from "./EstimateLineItem";
import Metric from "./Metric";
import LineItemCategory from "./LineItemCategory";

import SettingsTile from "../SettingsTile";

const EstimateLineItems = ({ title, tab }) => {
  const { subTab } = useParams();
  const breadcrumbs = [
    { text: title, link: buildUrl(routes.settings.show, { tab }) },
  ];

  return (
    <>
      {subTab ? (
        <Switch>
          <Route
            path={routes.settings.lineItemCategories}
            render={() => <LineItemCategory breadcrumbs={breadcrumbs} />}
          />
          <Route
            path={routes.settings.estimateLineItemCategories}
            render={() => <EstimateLineItem breadcrumbs={breadcrumbs} />}
          />
          <Route
            path={routes.settings.metrics}
            render={() => <Metric breadcrumbs={breadcrumbs} />}
          />
          <Redirect to={buildUrl(routes.settings.show, { tab })} />
        </Switch>
      ) : (
        <Container>
          <Header title={title} />

          <div className="grid grid-cols-2 gap-3 lg:grid-cols-3 lg:gap-6 xl:grid-cols-4 2xl:grid-cols-4">
            {ESTIMATE_LINE_ITEMS_LIST.map(item => (
              <SettingsTile
                {...item}
                key={item.value}
                path={buildUrl(item.path, { tab })}
              />
            ))}
          </div>
        </Container>
      )}
    </>
  );
};

export default EstimateLineItems;

import React, { useState, useEffect } from "react";
import { Button, Table } from "@bigbinary/neetoui";
import { isEmpty } from "ramda";
import EmptyState from "components/Common/EmptyState";
import { getRandomNotFoundImage } from "common/helper";

const ActivityTagList = ({ activityTagList }) => {
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [emptyImage, setEmptyImage] = useState();

  useEffect(() => {
    setEmptyImage(getRandomNotFoundImage())
  }, [])

  const renderClient = rowData => {
    return (
      <Button
        style="link"
        to={`/people/${rowData.contactId}`}
        label={rowData.contact}
      />
    );
  };

  const renderOrganisation = rowData => {
    return (
      <Button
        style="link"
        to={`/organisations/${rowData.organisationId}`}
        label={rowData.client}
      />
    );
  };

  const renderStaff = rowData => {
    return (
      <Button
        style="link"
        to={`/staff/${rowData.staffId}`}
        label={rowData.staff}
      />
    );
  };

  const COLUMN_DATA = [
    {
      title: "Action Tag",
      dataIndex: "actionTagName",
      key: "actionTagName",
      width: 200,
    },
    {
      title: "Client",
      dataIndex: "contact",
      key: "contact",
      width: 200,
      render: (_, rowData) => renderClient(rowData),
    },
    {
      title: "Organisation",
      dataIndex: "client",
      key: "client",
      width: 200,
      render: (_, rowData) => renderOrganisation(rowData),
    },
    {
      title: "Staff Member",
      dataIndex: "staffMember",
      key: "staffMember",
      width: 200,
      render: (_, rowData) => renderStaff(rowData),
    },
  ];

  return (
    <div className="w-full overflow-y-auto" style={{ height: "calc(60vh)" }}>
      {!isEmpty(activityTagList) ? (
        <Table
          fixedHeight
          allowRowClick={false}
          columnData={COLUMN_DATA}
          rowData={activityTagList}
          currentPageNumber={pageIndex}
          defaultPageSize={pageSize}
          handlePageChange={(page, pageSize) => {
            setPageIndex(page);
            setPageSize(pageSize);
          }}
        />
      ) : (
        <EmptyState
          image={emptyImage}
          title="No Action Tags Found"
          description="We couldn’t find any action tag. Try creating one."
        />
      )}
    </div>
  );
};

export default ActivityTagList;

import axios from "axios";

export const updateMessage = (jobId, messageId, payload) => {
  return axios.put(
    `/api/v1/job_module/jobs/${jobId}/draft_messages/${messageId}`,
    payload
  );
};

export const getDraftMessage = (jobId, messageId) => {
  return axios.get(
    `/api/v1/job_module/jobs/${jobId}/draft_messages/${messageId}`
  );
};

export const destroyDraftMessage = (jobId, messageId) => {
  return axios.delete(
    `/api/v1/job_module/jobs/${jobId}/draft_messages/${messageId}`
  );
};

import axios from "axios";

export const getClientsByAmounts = (preferredLocationId, preferredPeriod) => {
  return axios.get(
    `/api/v1/reports/top_clients_by_amounts?start_date=${preferredPeriod}&location_id=${preferredLocationId}`
  );
};

export const exportClients = (preferredLocationId, preferredPeriod) => {
  return axios.get(
    `/api/v1/reports/top_clients_by_amounts/export?start_date=${preferredPeriod}&location_id=${preferredLocationId}`
  );
};

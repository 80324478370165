import React, { useState, useEffect } from "react";
import { Delete } from "@bigbinary/neeto-icons";
import {
  Pane,
  Button,
  Spinner,
  Label,
  Textarea,
  Tag,
  Alert,
} from "@bigbinary/neetoui";
import { useFormik } from "formik";
import SingleImageUploader from "components/Common/SingleImageUploader";
import { showToastrError } from "common";
import { getJob } from "apis/jobs/details";
import { updateJob, destroyJob } from "apis/jobs/jobs";
import { getInvoices } from "apis/jobs/invoices";
import { getRcis } from "apis/jobs/rcis";
import { humanize, invoiceStatusBadgeColor } from "common/helper";
import { getFinalInvoice, getCommencementInvoice } from "./common/helper";

const EditJob = ({ jobId, setEditPane, setJobId, loadJobListResponse }) => {
  const [jobDetail, setJobDetail] = useState({});
  const [jobDetailLoad, setJobDetailLoad] = useState(true);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [invoiceList, setInvoiceList] = useState([]);
  const [invoiceListLoad, setInvoiceListLoad] = useState(true);
  const [rciList, setRciList] = useState([]);
  const [rciListLoad, setRciListLoad] = useState(true);
  const commencementInvoice = getCommencementInvoice(invoiceList);
  const finalInvoice = getFinalInvoice(invoiceList);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: jobDetail,
    onSubmit: () => updateJobEntry(),
  });

  useEffect(() => {
    if (jobId) {
      loadJobDetailResponse();
      loadInvoiceListResponse();
      loadRciListResponse();
    } else {
      setJobDetail({});
    }
  }, [jobId]);

  const loadJobDetailResponse = async () => {
    try {
      const response = await getJob(jobId);
      await setJobDetail(response.data.job);
      await setJobDetailLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadInvoiceListResponse = async () => {
    try {
      const response = await getInvoices(jobId);
      setInvoiceList(response.data.invoices);
      setInvoiceListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadRciListResponse = async () => {
    try {
      const response = await getRcis(jobId);
      setRciList(response.data.rcis);
      setRciListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const updateJobEntry = async () => {
    try {
      await updateJob(jobDetail.id, {
        job: {
          thumbnail_attachment_attributes: formik.values.attachments_attributes,
          note: formik.values.note,
        },
      });
      setJobId();
      setEditPane(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const onDelete = async () => {
    try {
      await destroyJob(jobId);
      setJobDetail({});
      loadJobListResponse();
      setDeleteAlert(false);
      setEditPane(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  if (jobDetailLoad || invoiceListLoad || rciListLoad) {
    return (
      <Pane.Body className="flex items-center justify-center">
        <Spinner />
      </Pane.Body>
    );
  }

  return (
    <>
      <Pane.Body>
        {invoiceList.length > 0 && (
          <div className="w-full">
            <div className="flex flex-col space-y-2">
              <Label className="font-semibold">Invoices</Label>
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <Label>Commencement Invoice:</Label>
                  {commencementInvoice && (
                    <Button
                      style="link"
                      className="ml-2"
                      to={`/invoices/${commencementInvoice?.id}`}
                      label={commencementInvoice?.serialNumber}
                    />
                  )}
                </div>

                {commencementInvoice && (
                  <Tag
                    label={humanize(commencementInvoice.status)}
                    style={invoiceStatusBadgeColor(commencementInvoice.status)}
                  />
                )}
              </div>
            </div>

            <div className="flex items-center justify-between mt-2">
              <div className="flex items-center">
                <Label>Final Invoice:</Label>
                {finalInvoice && (
                  <Button
                    style="link"
                    className="ml-2"
                    to={`/invoices/${finalInvoice?.id}`}
                    label={finalInvoice?.serialNumber}
                  />
                )}
              </div>

              {finalInvoice && (
                <Tag
                  label={humanize(finalInvoice.status)}
                  style={invoiceStatusBadgeColor(finalInvoice.status)}
                />
              )}
            </div>
          </div>
        )}

        {rciList.length > 0 && (
          <div className="w-full mt-6 space-y-2">
            <Label className="font-semibold">RCIs</Label>
            {rciList.map(rci => {
              return (
                <div className="flex justify-between" key={rci.id}>
                  <div className="flex items-center">
                    <Label>{`${rci.artistName}(${rci.type})`}:</Label>
                    <Button
                      style="link"
                      className="ml-2"
                      to={`/jobs/${jobId}/rcis/${rci.id}`}
                      label={rci.serialNumber}
                    />
                  </div>

                  <Tag label="Paid" style="success" />
                </div>
              );
            })}
          </div>
        )}

        <div className="w-full mt-6 space-y-6">
          <div className="flex flex-col space-y-2">
            <Label>Job Thumbnail</Label>
            <SingleImageUploader
              name=".jpg, .png files"
              type="thumbnail"
              formValue={formik.values}
              setFormValue={formik.setFieldValue}
            />
          </div>
          <Textarea
            label="Job Notes"
            name="note"
            value={formik.values.note}
            onChange={e => formik.setFieldValue("note", e.target.value)}
            className="mt-3 mb-20"
            row="4"
          />
        </div>
      </Pane.Body>
      <Pane.Footer className="items-center justify-between w-full">
        <div className="flex items-center gap-x-2">
          <Button label="Save Changes" onClick={() => updateJobEntry()} />
          <Button
            label="Cancel"
            style="text"
            onClick={() => setEditPane(false)}
          />
        </div>
        <Button
          label="Delete"
          style="secondary"
          icon={Delete}
          iconPosition="left"
          onClick={() => setDeleteAlert(true)}
        />
      </Pane.Footer>

      <Alert
        isOpen={deleteAlert}
        title="Delete Job"
        message="You are going to delete a job. Once completed, this action cannot be undone."
        onClose={() => setDeleteAlert(false)}
        onSubmit={() => onDelete()}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, delete"
      />
    </>
  );
};

export default EditJob;

import React from "react";
import { Label } from "@bigbinary/neetoui";
import { numberWithCommas } from "common/helper";

const BillableComponent = ({ item, index }) => {
  return (
    <div
      className="flex justify-between p-5 my-4 bg-white rounded-md"
      key={index}
    >
      <Label className="font-medium">{item.name}</Label>
      <Label className="pl-1 font-bold text-gray-800">
        {item.rateTbc
          ? "TBC"
          : item.rateNa
          ? "NA"
          : numberWithCommas(Number(item.amount).toFixed(2))}
      </Label>
    </div>
  );
};

export default BillableComponent;

import React from "react";
import { ContactBook, File, Delete, Download } from "@bigbinary/neeto-icons";
import { Typography, Button } from "@bigbinary/neetoui";

const ContactCard = ({
  type = "",
  name = "",
  allowDownload = true,
  showDelete = false,
  cardUrl = "",
  smallUrl = "",
  setDeleteAlertOpen = () => {},
  setDeletingAttachmentId = () => {},
  attachmentId = "",
}) => {
  const fileName = name.split(".")[0];
  const fileExtension = name.split(".").pop();

  const downloadContactCardData = async () => {
    let link = document.createElement("a");
    link.href = cardUrl;
    link.download = name;
    link.click();
  };

  return (
    <div className="flex items-center w-full px-3 py-2 transition-all duration-300 ease-in-out border rounded-md cursor-pointer neeto-ui-border-gray-200 group hover:neeto-ui-shadow-sm hover:neeto-ui-border-gray-500">
      <div className="flex items-center p-3 mr-3 space-x-1 text-center rounded-md neeto-ui-bg-success-100">
        {type == "vcard" ? (
          <ContactBook size={24} />
        ) : type === "pdf" ? (
          <File size={24} />
        ) : (
          <img src={smallUrl || cardUrl} />
        )}
        {type == "vcf" && (
          <Typography style="body3" weight="semibold" className="uppercase">
            {type}
          </Typography>
        )}
      </div>
      <div className="flex flex-col items-start space-y-1">
        <Typography style="body2">
          <span className="mr-1 font-semibold">
            {fileName.length > 11
              ? `${fileName.substring(0, 11)}...${fileExtension}`
              : name}
          </span>
        </Typography>

        <div className="flex items-center space-x-2">
          {allowDownload && (
            <Button
              style="text"
              size="small"
              icon={Download}
              tooltipProps={{
                content: "Download",
                position: "bottom",
              }}
              onClick={() => downloadContactCardData()}
            />
          )}

          {showDelete && (
            <Button
              style="danger-text"
              size="small"
              icon={Delete}
              tooltipProps={{
                content: "Delete",
                position: "bottom",
              }}
              onClick={() => {
                setDeletingAttachmentId(attachmentId);
                setDeleteAlertOpen(true);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactCard;

import React, { useEffect } from "react";
import { BorderedTable } from "../../Shared";

import AOS from "aos";
import "aos/dist/aos.css";

const UsageSummary = ({
  usage,
  ammountWithCurrency,
  revision,
  description,
}) => {
  useEffect(() => {
    AOS.init();
  }, []);

  let getUsageLineItems = () => [
    {
      option: {
        hasCheckbox: false,
        id: usage.id,
        hasTitle: true,
        title: usage.title,
        description: usage.terms,
        price: ammountWithCurrency(revision.artistUsageFee || 0),
      },
      table: {
        heads: ["Category", "Qty", "Territory", "Duration", "Exclusivity"],
        rows: usage?.licenseItems.map(item => {
          return {
            values: [
              <>
                <strong>{item.category}</strong> <br />
                <div className="w-60">{item.detail}</div>
              </>,
              item.quantity,
              item.territory,
              <div className="whitespace-no-wrap" key={item.id}>
                {item.period}
              </div>,
              <div className="whitespace-no-wrap" key={item.id}>
                {item.exclusivityPeriod}
              </div>,
            ],
          };
        }),
      },
    },
  ];

  return (
    <div className="flex flex-col items-start justify-between w-full py-12 lg:flex-row">
      <div
        className="static top-0 flex flex-col items-start justify-start w-full pr-0 lg:pr-12 lg:sticky lg:top-8 lg:w-1/3"
        style={{ fontFamily: "Modern Gothic" }}
      >
        <div className="flex flex-col items-start justify-start mb-8 lg:mb-0">
          <h2 className="text-xl font-medium">Usage Summary</h2>
          <p
            className="mt-6"
            dangerouslySetInnerHTML={{
              __html: description?.split("\n").join("<br />"),
            }}
          ></p>
        </div>
      </div>
      <div
        className="flex flex-col items-start justify-start w-full h-full lg:w-2/3"
        style={{ fontFamily: "Modern Gothic" }}
      >
        <div className="flex flex-col items-start justify-start w-full space-y-2">
          <BorderedTable
            data={usage ? getUsageLineItems() : []}
            bgColor="#D6EEFF"
          />
          <div className="flex flex-col items-center justify-between w-full px-6 py-4 mt-2 space-y-2 font-medium text-white bg-black lg:flex-row lg:space-y-0 rounded_container">
            <span className="text-base lg:text-xl">Usage Total</span>
            <span className="text-xl">
              {ammountWithCurrency(revision.artistUsageFee || 0)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsageSummary;

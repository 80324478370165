import React from "react";
import { Label, Typography } from "@bigbinary/neetoui";
import SerialComponents from "./SerialComponents";

const Serials = ({ formik }) => {
  return (
    <div className="flex flex-col w-full pb-8 mt-6 space-y-6">
      <div className="flex flex-col">
        <Label className="mb-2 font-medium">Job Serial</Label>
        <SerialComponents formik={formik} attr="jobSerial" />
        {formik.errors.jobSerial && (
          <Typography style="body3" className="text-red-600 ">
            {formik.errors.jobSerial}
          </Typography>
        )}
      </div>
      <div className="flex flex-col">
        <Label className="mb-2 font-medium">Invoice Serial</Label>
        <SerialComponents formik={formik} attr="invoiceSerial" />
        {formik.errors.invoiceSerial && (
          <Typography style="body3" className="text-red-600 ">
            {formik.errors.invoiceSerial}
          </Typography>
        )}
      </div>
      <div className="flex flex-col">
        <Label className="mb-2 font-medium">RCI Serial</Label>
        <SerialComponents formik={formik} attr="rciSerial" />
        {formik.errors.rciSerial && (
          <Typography style="body3" className="text-red-600 ">
            {formik.errors.rciSerial}
          </Typography>
        )}
      </div>
      <div className="flex flex-col">
        <Label className="mb-2 font-medium">License Serial</Label>
        <SerialComponents formik={formik} attr="licenseSerial" />
        {formik.errors.licenseSerial && (
          <Typography style="body3" className="text-red-600 ">
            {formik.errors.licenseSerial}
          </Typography>
        )}
      </div>
      <div className="flex flex-col">
        <Label className="mb-2 font-medium">Revision Serial</Label>
        <SerialComponents formik={formik} attr="revisionSerial" />
        {formik.errors.licenseSerial && (
          <Typography style="body3" className="text-red-600 ">
            {formik.errors.revisionSerial}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default Serials;

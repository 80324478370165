import React, { useState, useEffect } from "react";
import { Spinner, Button, Toastr } from "@bigbinary/neetoui";
import { Header, Container } from "@bigbinary/neetoui/layouts";
import { useParams, useHistory } from "react-router-dom";
import { useUserState } from "contexts/user";
import { getEmailBackgroundDetails } from "apis/mailers/email_backgrounds";
import { getCompanyDetails } from "apis/mailers/company_details";
import { showToastrError } from "common";
import { sendRciToArtist } from "apis/jobs/rcis";
import { useFormik } from "formik";
import { getRci, getJob } from "apis/mailers/rcis";
import { getArtist } from "apis/mailers/artists";
import { getSettings } from "apis/mailers/template_settings";
import { INITIAL_VALUE, VALIDATION_SCHEMA, getEmails } from "../constants";
import { EMAIL_DISABLED_MESSAGE } from "common/constants";

import MailEditor from "./Editor";
import MailViewer from "./Viewer";

const RciMail = () => {
  const { id, artistId, rciId } = useParams();
  const { user, env_variables } = useUserState();
  const history = useHistory();
  const [jobDetail, setJobDetail] = useState();
  const [companyDetails, setCompanyDetails] = useState([]);
  const [companyDetailsLoad, setCompanyDetailsLoad] = useState(true);
  const [
    commencementEmailBackground,
    setCommencementEmailBackground,
  ] = useState();
  const [finalEmailBackground, setFinalEmailBackground] = useState();
  const [emailBackgroundLoad, setEmailBackgroundLoad] = useState(true);
  const [rciDetail, setRciDetail] = useState();
  const [artistDetail, setArtistDetail] = useState();
  const [jobDetailLoad, setjobDetailLoad] = useState(true);
  const [artistDetailLoad, setArtistDetailLoad] = useState(true);
  const [rciDetailLoad, setRciDetailLoad] = useState(true);
  const [mailLoader, setMailLoader] = useState(false);
  const [defaultTemplateSetting, setDefaultTemplateSetting] = useState();
  const [defaultTemplateSettingLoad, setDefaultTemplateSettingLoad] = useState(
    true
  );

  const formik = useFormik({
    initialValues: INITIAL_VALUE,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: values => {
      sendRciMailToArtist(values);
    },
  });

  useEffect(() => {
    getCompanyDetailsResponse();
    getTemplateSettings();
  }, []);

  useEffect(() => {
    if (id) {
      setJobDetailResponse();
    }
  }, [id]);

  useEffect(() => {
    if (artistId) {
      setArtistDetailResponse();
    }
  }, [artistId]);

  useEffect(() => {
    if (rciId) {
      setRciDetailResponse();
    }
  }, [rciId]);

  useEffect(() => {
    if (rciDetail) {
      getEmailBackgroundDetailsResponse();
    }
  }, [rciDetail]);

  useEffect(() => {
    if (jobDetail && artistDetail && rciDetail) {
      formik.setFieldValue("firstName", artistDetail.name);
      formik.setFieldValue(
        "from",
        getEmails(jobDetail?.emailFrom, jobDetail?.allRecipients)[0]
      );
      formik.setFieldValue(
        "to",
        getEmails(jobDetail?.emailTo, jobDetail?.allRecipients)
      );
      formik.setFieldValue(
        "cc",
        getEmails(jobDetail?.emailCc, jobDetail?.allRecipients)
      );
      formik.setFieldValue(
        "bcc",
        getEmails(jobDetail?.emailBcc, jobDetail?.allRecipients)
      );
      formik.setFieldValue("subject", mailSubject());
      formik.setFieldValue("body", mailBody());
    }
  }, [
    jobDetail,
    artistDetail,
    rciDetail,
    commencementEmailBackground,
    finalEmailBackground,
  ]);

  const setResourceForSubject = name => {
    switch (name) {
      case "[paperwork_title]":
        return `${rciDetail.rciType} RCI`;
      case "[job_number]":
        return jobDetail?.serialNumber;
      case "[job_name]":
        return jobDetail?.name;
      case "[serial_number]":
        return rciDetail.serialNumber;
      case "[invoice_number]":
        return rciDetail.serialNumber;
      case "[version_number]":
        return `${rciDetail.rciType} RCI`;
      case "[organisation_name]":
        return jobDetail?.organisationName;
    }
  };

  const setResourceForBody = name => {
    switch (name) {
      case "[contact_first_name]":
        return jobDetail?.contactFirstName;
      case "[staff_phone]":
        return jobDetail?.staffPhone;
      case "[staff_first_name]":
        return jobDetail?.staffFirstName;
      case "[account_name]":
        return jobDetail?.accountName;
      case "[artist_name]":
        return artistDetail?.salutation;
    }
  };

  const mailSubject = () => {
    let emailSubject = rciDetail?.final
      ? finalEmailBackground?.emailSubject
      : commencementEmailBackground?.emailSubject;
    let subject = "";
    const subjectComponents = emailSubject?.split("<>");

    subjectComponents?.map(component => {
      if (component.includes("[")) {
        subject += setResourceForSubject(component);
      } else {
        subject += component;
      }
    });

    return subject;
  };

  const mailBody = () => {
    let emailDetails = rciDetail?.final
      ? finalEmailBackground?.finalRciEmailDetails
      : commencementEmailBackground?.commencementRciEmailDetails;
    let body = "";
    const bodyComponents = emailDetails?.split("<>");

    bodyComponents?.map(component => {
      if (component.includes("[")) {
        body += setResourceForBody(component);
      } else {
        body += component;
      }
    });

    return body;
  };

  const setJobDetailResponse = async () => {
    try {
      const response = await getJob(id, artistId);
      let job = response.data.job;
      setJobDetail(job);
      setjobDetailLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const getTemplateSettings = async () => {
    try {
      const response = await getSettings();
      setDefaultTemplateSetting(response.data.templateSettings[0]);
      setDefaultTemplateSettingLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const setArtistDetailResponse = async () => {
    try {
      const response = await getArtist(artistId);
      let artist = response.data.artist;
      setArtistDetail(artist);
      setArtistDetailLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const setRciDetailResponse = async () => {
    try {
      const response = await getRci(rciId);
      let rci = response.data.rci;
      setRciDetail(rci);
      setRciDetailLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const getEmailBackgroundDetailsResponse = async () => {
    try {
      const response = await getEmailBackgroundDetails("rci");
      setCommencementEmailBackground(
        response.data.emailBackgrounds?.find(
          background => background.pageName === "RCI Commencement"
        )
      );
      setFinalEmailBackground(
        response.data.emailBackgrounds?.find(
          background => background.pageName === "RCI Final"
        )
      );
      setEmailBackgroundLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const getCompanyDetailsResponse = async () => {
    try {
      const response = await getCompanyDetails();
      setCompanyDetails(response.data.companyDetails);
      setCompanyDetailsLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const sendRciMailToArtist = async () => {
    setMailLoader(true);
    try {
      const response = await sendRciToArtist(jobDetail.id, rciId, {
        rci: { ...formik.values },
      });
      setMailLoader(false);

      if (user.demo_account) {
        Toastr.error(EMAIL_DISABLED_MESSAGE);
      } else {
        Toastr.info(response.data.notice);
      }

      history.push(`/jobs/${id}/rcis/${rciId}`);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  if (
    jobDetailLoad ||
    artistDetailLoad ||
    rciDetailLoad ||
    mailLoader ||
    emailBackgroundLoad ||
    companyDetailsLoad ||
    defaultTemplateSettingLoad
  ) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <Container isHeaderFixed>
      <Header
        title="RCI"
        breadcrumbs={[
          { text: jobDetail.name, link: `/jobs/${jobDetail.id}/overview` },
          {
            text: rciDetail.serialNumber,
            link: `/jobs/${jobDetail.id}/rcis/${rciDetail.id}`,
          },
        ]}
        actionBlock={
          <Button label="Send Email" onClick={() => formik.handleSubmit()} />
        }
      />
      <div
        className="flex overflow-hidden w-full"
        style={{ height: "calc(100vh - 94px)" }}
      >
        <MailEditor
          formik={formik}
          docUrl={`${window.location.protocol}//${user.account_subdomain}.${env_variables.root_domain}/rcis/${rciDetail?.rciType}?token=${rciDetail.slug}`}
          jobDetail={jobDetail}
        />
        <MailViewer
          formik={formik}
          docUrl={`${window.location.protocol}//${user.account_subdomain}.${env_variables.root_domain}/rcis/${rciDetail?.rciType}?token=${rciDetail.slug}`}
          jobDetail={jobDetail}
          final={rciDetail.final}
          companyDetails={companyDetails}
          rciDetail={rciDetail}
          artistDetail={artistDetail}
          defaultTemplateSetting={defaultTemplateSetting}
          emailBackground={
            rciDetail.final ? finalEmailBackground : commencementEmailBackground
          }
        />
      </div>
    </Container>
  );
};

export default RciMail;

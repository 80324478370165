import axios from "axios";

const getRevisionProjectScopesUrl = (revisionId, id) => {
  if (id) {
    return `/api/v1/job_module/estimate_revisions/${revisionId}/revision_project_scopes/${id}`;
  }

  return `/api/v1/job_module/estimate_revisions/${revisionId}/revision_project_scopes`;
};

export const getRevisionProjectScopes = revisionId => {
  return axios.get(getRevisionProjectScopesUrl(revisionId));
};

export const createRevisionProjectScope = (revisionId, payload) => {
  return axios.post(getRevisionProjectScopesUrl(revisionId), payload);
};

export const updateRevisionProjectScope = (revisionId, id, payload) => {
  return axios.put(getRevisionProjectScopesUrl(revisionId, id), payload);
};

export const destroyRevisionProjectScope = (revisionId, id) => {
  return axios.delete(getRevisionProjectScopesUrl(revisionId, id));
};

export const updateRevisionProjectScopePosition = (
  revisionId,
  id,
  type,
  difference
) => {
  return axios.put(
    `${getRevisionProjectScopesUrl(
      revisionId,
      id
    )}/change_position?type=${type}&difference=${difference}`
  );
};

import React, { useState } from "react";
import { MenuHorizontal } from "@bigbinary/neeto-icons";
import { Dropdown, Table, Tag } from "@bigbinary/neetoui";

const List = ({
  filterSpecificationList,
  setModalState,
  setDeleteModalState,
  setFilterSpecification,
  getFilterSpecificationEntry,
}) => {
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const { Menu, MenuItem } = Dropdown;

  const COLUMN_DATA = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "30%",
      render: (_, rowData) => <>{rowData.name ? rowData.name : "-"}</>,
      fixed: "left",
    },
    {
      title: "Producer",
      dataIndex: "userFullName",
      key: "userFullName",
      width: "30%",
      render: (_, rowData) => (
        <>{rowData.userFullName ? rowData.userFullName : "-"}</>
      ),
    },
    {
      title: "Default",
      dataIndex: "default",
      key: "default",
      width: "30%",
      render: (_, rowData) => (
        <Tag
          label={rowData.default ? "Enabled" : "Disabled"}
          style={rowData.default ? "success" : "danger"}
          type="solid"
        />
      ),
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: "10%",
      render: (_, rowData) => (
        <Dropdown
          autoWidth
          closeOnSelect
          buttonStyle="text"
          strategy="fixed"
          icon={MenuHorizontal}
        >
          <Menu>
            <MenuItem.Button
              onClick={() => {
                setFilterSpecification(rowData);
                getFilterSpecificationEntry(rowData.id);
                setModalState(true);
              }}
            >
              Edit
            </MenuItem.Button>
            <MenuItem.Button
              style="danger"
              onClick={() => {
                setFilterSpecification(rowData);
                setDeleteModalState(true);
              }}
            >
              Delete
            </MenuItem.Button>
          </Menu>
        </Dropdown>
      ),
    },
  ];
  return (
    <Table
      fixedHeight
      columnData={COLUMN_DATA}
      rowData={filterSpecificationList}
      currentPageNumber={pageIndex}
      defaultPageSize={pageSize}
      handlePageChange={(page, pageSize) => {
        setPageIndex(page);
        setPageSize(pageSize);
      }}
    />
  );
};

export default List;

import React, { useEffect, useState } from "react";
import { Button, Label, Typography } from "@bigbinary/neetoui";
import { countries } from "countries-list";
import EmptyState from "components/Common/EmptyState";
import { getRandomNotFoundImage } from "common/helper";
import { parsePhoneNumber, ParseError } from "libphonenumber-js";

const Profile = ({ organisationDetail }) => {
  const [emptyImage, setEmptyImage] = useState();

  useEffect(() => {
    setEmptyImage(getRandomNotFoundImage());
  }, []);

  const phoneNumberParsed = () => {
    try {
      let phoneNumber =
        organisationDetail.phoneNumber &&
        organisationDetail.phoneNumber[0] === "+"
          ? organisationDetail.phoneNumber
          : `+${organisationDetail.phoneNumber}`;
      if (parsePhoneNumber(phoneNumber)) {
        return parsePhoneNumber(phoneNumber).formatInternational();
      }
    } catch (error) {
      if (error instanceof ParseError) {
        let countryWithMatchName =
          Object.entries(countries).find(item => {
            return (
              item[0].toLowerCase() ===
                organisationDetail.country?.toLowerCase() ||
              item[1].name.toLowerCase() ===
                organisationDetail.country?.toLowerCase()
            );
          }) || "AU";

        try {
          return parsePhoneNumber(
            `${organisationDetail.phoneNumber}`,
            countryWithMatchName[0]
          ).formatInternational();
        } catch (error) {
          if (error instanceof ParseError) {
            // Code
          }
        }
      }
    }
  };

  if (!organisationDetail?.id) {
    return (
      <div
        className="flex items-center justify-center p-2"
        style={{ height: "calc(100vh - 134px)" }}
      >
        <EmptyState
          image={emptyImage}
          title="Please click on any organisation to access the details."
        />
      </div>
    );
  }

  return (
    <div className="px-6 mt-6">
      <div className="flex flex-col">
        <div className="grid grid-cols-3 mb-6 gap-x-4 gap-y-6">
          <div className="flex flex-col space-y-1">
            <Label>Client ID</Label>
            <p className="text-sm font-semibold text-gray-800">
              {organisationDetail.serialNumber
                ? organisationDetail.serialNumber
                : "N/A"}
            </p>
          </div>

          <div className="flex flex-col space-y-1">
            <Label>Organisation ID</Label>
            <p className="text-sm font-semibold text-gray-800">
              {organisationDetail.organisationSerial
                ? organisationDetail.organisationSerial
                : "N/A"}
            </p>
          </div>

          <div className="flex flex-col space-y-1">
            <Label>Organisation Name</Label>
            <p className="text-sm font-semibold text-gray-800">
              {organisationDetail.name ? organisationDetail.name : "N/A"}
            </p>
          </div>

          <div className="flex flex-col space-y-1">
            <Label>Vendor ID</Label>
            <p className="text-sm font-semibold text-gray-800">
              {organisationDetail.vendorId
                ? organisationDetail.vendorId
                : "N/A"}
            </p>
          </div>

          <div className="flex flex-col space-y-1">
            <Label>Phone</Label>
            <p className="text-sm font-semibold text-gray-800">
              {organisationDetail.phoneNumber
                ? phoneNumberParsed(organisationDetail.phoneNumber)
                : "N/A"}
            </p>
          </div>

          <div className="flex flex-col space-y-1">
            <Label>Website</Label>
            {organisationDetail.website ? (
              <Button
                style="link"
                className="break-word"
                href={organisationDetail.website}
                target="_blank"
                label={organisationDetail.website}
                otherProps={{ _target: "blank" }}
              />
            ) : (
              <p className="text-sm font-semibold text-gray-800">N/A</p>
            )}
          </div>

          <div className="flex flex-col space-y-1">
            <Label>Type</Label>
            <p className="text-sm font-semibold text-gray-800">
              {organisationDetail.type ? organisationDetail.type : "N/A"}
            </p>
          </div>

          <div className="flex flex-col space-y-1">
            <Label>Industry Sector</Label>
            <p className="text-sm font-semibold text-gray-800">
              {organisationDetail.industrySector
                ? organisationDetail.industrySector
                : "N/A"}
            </p>
          </div>
        </div>

        <Typography style="h4" weight="semibold" className="mb-3">
          Primary Address
        </Typography>
        <div className="grid grid-cols-3 mb-6 gap-x-4 gap-y-6">
          <div className="flex flex-col space-y-1">
            <Label>Street Address</Label>
            {organisationDetail?.primaryAddress?.streetAddress ? (
              <div
                className="text-sm font-semibold text-gray-800"
                dangerouslySetInnerHTML={{
                  __html: organisationDetail?.primaryAddress?.streetAddress
                    ?.split("\n")
                    ?.join("<br/>"),
                }}
              />
            ) : (
              <p className="text-sm font-semibold text-gray-800">N/A</p>
            )}
          </div>

          <div className="flex flex-col space-y-1">
            <Label>City/Suburb</Label>
            <p className="text-sm font-semibold text-gray-800">
              {organisationDetail.primaryAddress?.suburb
                ? organisationDetail.primaryAddress?.suburb
                : "N/A"}
            </p>
          </div>

          <div className="flex flex-col space-y-1">
            <Label>State</Label>
            <p className="text-sm font-semibold text-gray-800">
              {organisationDetail.primaryAddress?.state
                ? organisationDetail.primaryAddress?.state
                : "N/A"}
            </p>
          </div>

          <div className="flex flex-col space-y-1">
            <Label>Country</Label>
            <p className="text-sm font-semibold text-gray-800">
              {organisationDetail.primaryAddress?.country
                ? organisationDetail.primaryAddress?.country
                : "N/A"}
            </p>
          </div>

          <div className="flex flex-col space-y-1">
            <Label>Postcode</Label>
            <p className="text-sm font-semibold text-gray-800">
              {organisationDetail.primaryAddress?.postcode
                ? organisationDetail.primaryAddress?.postcode
                : "N/A"}
            </p>
          </div>
        </div>

        {organisationDetail?.secondaryAddress?.id && (
          <>
            <Typography style="h4" weight="semibold" className="mb-3">
              Secondary Address
            </Typography>
            <div className="grid grid-cols-3 mb-6 gap-x-4 gap-y-6">
              <div className="flex flex-col space-y-1">
                <Label>Street Address</Label>
                {organisationDetail.secondaryAddress?.streetAddress ? (
                  <div
                    className="text-sm font-semibold text-gray-800"
                    dangerouslySetInnerHTML={{
                      __html: organisationDetail.secondaryAddress?.streetAddress
                        ?.split("\n")
                        ?.join("<br/>"),
                    }}
                  />
                ) : (
                  <p className="text-sm font-semibold text-gray-800">N/A</p>
                )}
              </div>

              <div className="flex flex-col space-y-1">
                <Label>City/Suburb</Label>
                <p className="text-sm font-semibold text-gray-800">
                  {organisationDetail.secondaryAddress?.suburb
                    ? organisationDetail.secondaryAddress?.suburb
                    : "N/A"}
                </p>
              </div>

              <div className="flex flex-col space-y-1">
                <Label>State</Label>
                <p className="text-sm font-semibold text-gray-800">
                  {organisationDetail.secondaryAddress?.state
                    ? organisationDetail.secondaryAddress?.state
                    : "N/A"}
                </p>
              </div>

              <div className="flex flex-col space-y-1">
                <Label>Country</Label>
                <p className="text-sm font-semibold text-gray-800">
                  {organisationDetail.secondaryAddress?.country
                    ? organisationDetail.secondaryAddress?.country
                    : "N/A"}
                </p>
              </div>

              <div className="flex flex-col space-y-1">
                <Label>Postcode</Label>
                <p className="text-sm font-semibold text-gray-800">
                  {organisationDetail.secondaryAddress?.postcode
                    ? organisationDetail.secondaryAddress?.postcode
                    : "N/A"}
                </p>
              </div>
            </div>
          </>
        )}

        <div className="flex flex-col mb-8 gap-y-6">
          <div className="flex flex-col">
            <Label>Invoice Payment Terms</Label>
            <p className="text-sm font-semibold text-gray-800">
              {organisationDetail.invoicePaymentTerms
                ? organisationDetail.invoicePaymentTerms
                : "N/A"}
            </p>
          </div>

          <div className="flex flex-col">
            <Label>Payment Term Details</Label>
            {organisationDetail?.paymentTerms ? (
              <div
                className="text-sm font-semibold text-gray-800"
                dangerouslySetInnerHTML={{
                  __html: organisationDetail?.paymentTerms
                    ?.split("\n")
                    .join("<br/>"),
                }}
              />
            ) : (
              <p className="text-sm font-semibold text-gray-800">N/A</p>
            )}
          </div>

          <div className="flex flex-col">
            <Label>Notes</Label>
            {organisationDetail?.notes ? (
              <div
                className="text-sm font-semibold text-gray-800"
                dangerouslySetInnerHTML={{
                  __html: organisationDetail?.notes?.split("\n").join("<br/>"),
                }}
              />
            ) : (
              <p className="text-sm font-semibold text-gray-800">N/A</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;

import React, { useState, useEffect } from "react";
import {
  Typography,
  Spinner,
  Table,
  DatePicker,
  Dropdown,
} from "@bigbinary/neetoui";
import { SubHeader } from "@bigbinary/neetoui/layouts";
import * as R from "ramda";
import * as dayjs from "dayjs";
import { getActionTags, exportActionTags } from "apis/staffs/action_tags";
import { showToastrError } from "common";
import { displayData, getRandomNotFoundImage } from "common/helper";
import EmptyState from "components/Common/EmptyState";

const Report = ({ staffDetail }) => {
  const [actionTags, setActionTags] = useState([]);
  const [leadsAdded, setLeadsAdded] = useState();
  const [dataLoader, setDataLoader] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [startDate, setStartDate] = useState(dayjs().day(1));
  const [endDate, setEndDate] = useState(dayjs().day(7));
  const { Menu, MenuItem } = Dropdown;
  const [emptyImage, setEmptyImage] = useState();

  useEffect(() => {
    setEmptyImage(getRandomNotFoundImage());
  }, [])

  useEffect(() => {
    if (staffDetail && startDate && endDate) {
      loadActionTags();
    }
  }, [staffDetail, startDate, endDate]);

  const loadActionTags = async () => {
    setDataLoader(true);
    try {
      const { data } = await getActionTags(staffDetail?.id, startDate.format("YYYY-MM-DD"), endDate.format("YYYY-MM-DD"));
      setActionTags(data.summary.tags);
      setTotalRecords(data.summary.tags.length);
      setLeadsAdded(data.summary.leads_added);
      setDataLoader(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const getExportActionTags = async report_type => {
    setDataLoader(true);
    try {
      const { data } = await exportActionTags(
        staffDetail?.id,
        startDate.format("YYYY-MM-DD"),
        endDate.format("YYYY-MM-DD"),
        report_type
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.download = `${report_type}-action-tag-report-for-${
        staffDetail.fullName
      }-${dayjs().format("DD-MM-YYYY")}.csv`;
      link.click();
      setDataLoader(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const handleDateChange = range => {
    if (range[0]) {
      setStartDate(dayjs(range[0].toDate()));
    }

    if (range[1]) {
      setEndDate(dayjs(range[1].toDate()));
    }
  };

  const COLUMN_DATA = [
    {
      title: "Activity Tag",
      dataIndex: "name",
      key: "name",
      render: (_, rowData) => displayData(rowData, "name"),
    },
    {
      title: "Opened",
      dataIndex: "open_count",
      key: "open_count",
      render: (_, rowData) => displayData(rowData, "open_count"),
    },
    {
      title: "Closed",
      dataIndex: "close_count",
      key: "close_count",
      render: (_, rowData) => displayData(rowData, "close_count"),
    },
  ];

  if (dataLoader) {
    return (
      <div
        className="flex items-center justify-center w-full"
        style={{ height: "calc(100vh - 134px)" }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <SubHeader
        className="px-6 pt-4"
        leftActionBlock={
          <Typography style="h4" component="h4" weight="semibold">
            {totalRecords > 1
              ? `${totalRecords} Action Tags`
              : `${totalRecords} Action Tag`}
          </Typography>
        }
        rightActionBlock={
          <div className="flex items-center space-x-3">
            <DatePicker
              dateFormat="DD/MM/YYYY"
              type="range"
              value={[startDate, endDate]}
              onChange={date => handleDateChange(date)}
            />

            <Dropdown
              label="Download"
              buttonStyle="secondary"
              position="bottom-end"
              closeOnSelect
            >
              <Menu>
                <MenuItem.Button onClick={() => getExportActionTags("opened")}>
                  Opened Tag Report
                </MenuItem.Button>
                <MenuItem.Button onClick={() => getExportActionTags("closed")}>
                  Closed Tag Report
                </MenuItem.Button>
              </Menu>
            </Dropdown>
          </div>
        }
      />
      <div className="px-4 mb-6" style={{ height: "calc(100vh - 280px)" }}>
        {!R.isEmpty(actionTags) ? (
          <Table columnData={COLUMN_DATA} rowData={actionTags} fixedHeight />
        ) : (
          <EmptyState image={emptyImage} title="No Action Tags Found" />
        )}
      </div>

      <div className="fixed bottom-0 right-0  flex justify-between px-6 py-4 text-right bg-white border-t neeto-ui-border-gray-200" style={{ width: "calc(100vw - 550px)" }}>
        <div className="flex items-center space-x-2">
          <Typography style="h4" className="neeto-ui-text-gray-800">
            Tag Closed
          </Typography>
          <Typography
            style="h4"
            weight="bold"
            className="neeto-ui-text-error-600"
          >
            {actionTags.reduce((partialSum, tag) => partialSum + Number(tag.close_count), 0)}
          </Typography>
        </div>
        <div className="flex items-center space-x-2">
          <Typography style="h4" className="neeto-ui-text-gray-800">
            Leads Added
          </Typography>
          <Typography
            style="h4"
            weight="bold"
            className="neeto-ui-text-error-600"
          >
            {leadsAdded}
          </Typography>
        </div>
      </div>
    </>
  );
};

export default Report;

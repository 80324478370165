import React, { useState, useEffect } from "react";
import * as R from "ramda";
import { withRouter, useHistory } from "react-router-dom";
import { useUserState } from "contexts/user";
import useDebounce from "common/debounce";

import NavItem from "./NavItem";
import { MENU_ITEMS } from "./constants";

const NavBar = () => {
  const history = useHistory();
  const [searchParams, setSearchParams] = useState("");
  const debouncedSearchParams = useDebounce(searchParams, 500);
  const { user } = useUserState();

  useEffect(() => {
    if (!R.isEmpty(debouncedSearchParams)) {
      history.push(`/global_search`, { params: debouncedSearchParams });
    }
  }, [debouncedSearchParams]);

  useEffect(() => {
    if (history.location.pathname !== "/global_search") {
      setSearchParams("");
    }
  }, [history.location.pathname]);

  return (
    <div
      className="px-3 overflow-y-auto border-r border-gray-200 neeto-ui-bg-white jw-sidebar"
      key="sidebar"
    >
      <div className="flex items-center justify-center my-8 logo-container">
        <img src={user?.app_logo_url} alt="app logo" />
      </div>
      <div className="flex flex-col items-center justify-between w-full h-full">
        <div className="flex flex-col items-center justify-start w-full">
          {user &&
            MENU_ITEMS(user)
              .filter(item => item !== null)
              .map((item, index) => {
                return (
                  <NavItem
                    title={item.name}
                    link={item.link}
                    icon={item.icon}
                    key={index}
                  />
                );
              })}
        </div>
      </div>
    </div>
  );
};

export default withRouter(NavBar);

import React, { useState } from "react";
import { MenuHorizontal } from "@bigbinary/neeto-icons";
import { Dropdown, Table } from "@bigbinary/neetoui";
import { useUserState } from "contexts/user";

const List = ({
  crmActionTagList,
  setModalState,
  setArchiveAlertState,
  setDeleteModalState,
  setCrmActionTag,
}) => {
  const { user } = useUserState();
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const { Menu, MenuItem } = Dropdown;

  const COLUMN_DATA = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "70%",
      render: (_, rowData) => <>{rowData.name ? rowData.name : "-"}</>,
      fixed: "left",
    },
    {
      title: "Archive",
      dataIndex: "archive",
      key: "archive",
      width: "20%",
      render: (_, rowData) => <>{rowData.archive ? "Archived" : "Active"}</>,
      fixed: "left",
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: "10%",
      render: (_, rowData) => (
        <Dropdown
          autoWidth
          closeOnSelect
          buttonStyle="text"
          strategy="fixed"
          icon={MenuHorizontal}
        >
          <Menu>
            <MenuItem.Button
              onClick={() => {
                setCrmActionTag(rowData);
                setModalState(true);
              }}
            >
              Edit
            </MenuItem.Button>
            <MenuItem.Button
              onClick={() => {
                setCrmActionTag(rowData);
                setArchiveAlertState(true);
              }}
            >
              Archive
            </MenuItem.Button>
            {user.access === "admin"  && (
              <MenuItem.Button
                style="danger"
                onClick={() => {
                  setCrmActionTag(rowData);
                  setDeleteModalState(true);
                }}
              >
                Delete
              </MenuItem.Button>
            )}
          </Menu>
        </Dropdown>
      ),
    },
  ];

  return (
    <Table fixedHeight columnData={COLUMN_DATA} rowData={crmActionTagList} currentPageNumber={pageIndex}
    defaultPageSize={pageSize}
    handlePageChange={(page, pageSize) => {
      setPageIndex(page);
      setPageSize(pageSize);
    }} />
  );
};

export default List;

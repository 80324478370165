import React, { useEffect, useState, useContext } from "react";
import { Spinner, Button, Typography } from "@bigbinary/neetoui";
import * as R from "ramda";
import produce from "immer";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { getProjectScopes } from "apis/settings/project_scopes";
import { getDeliveryScopes } from "apis/settings/delivery_scopes";
import { getRevisionDeliveryScopes } from "apis/jobs/revision_delivery_scopes";
import { getRevisionProjectScopes } from "apis/jobs/revision_project_scopes";

import { showToastrError } from "common";
import { dropDownListGenerator } from "common/helper";
import { OverviewDetailsContext } from "./../index";
import ScopeItem from "./ScopeItem";
import {
  changeProjectScopeValue,
  destroyRevisionScopeEntry,
  changeDeliveryScopeValue,
  addScope,
  updateRevisionScopeEntry,
} from "./crudApiCalls";
import { scopeReorderFunction } from "./reorderApiCalls";

const Scopes = () => {
  const { jobDetail, revisionId } = useContext(OverviewDetailsContext);
  const [projectScope, setProjectScope] = useState([{ label: "", value: "" }]);
  const [deliveryScope, setDeliveryScope] = useState([
    { label: "", value: "" },
  ]);
  const [projectScopeListLoad, setProjectScopeListLoad] = useState(true);
  const [deliveryScopeListLoad, setDeliveryScopeListLoad] = useState(true);

  const [
    revisionSpecificProjectScope,
    setRevisionSpecificProjectScope,
  ] = useState([{ label: "", value: "" }]);
  const [
    revisionSpecificDeliveryScope,
    setRevisionSpecificDeliveryScope,
  ] = useState([{ label: "", value: "" }]);
  const [
    revisionSpecificProjectScopeLoad,
    setRevisionSpecificProjectScopeLoad,
  ] = useState(true);
  const [
    revisionSpecificDeliveryScopeLoad,
    setRevisionSpecificDeliveryScopeLoad,
  ] = useState(true);

  useEffect(() => {
    if (jobDetail) {
      fetchData();
    }
  }, [jobDetail]);

  useEffect(() => {
    if (revisionId) {
      loadRevisionSpecificProjectData();
      loadRevisionSpecificDeliveryData();
    }
  }, [revisionId]);

  const fetchData = async () => {
    try {
      const projectScopeResponse = await getProjectScopes();
      setProjectScope(
        dropDownListGenerator(projectScopeResponse.data.projectScopes)
      );
      setProjectScopeListLoad(false);

      const deliveryScopeResponse = await getDeliveryScopes();
      setDeliveryScope(
        dropDownListGenerator(deliveryScopeResponse.data.deliveryScopes)
      );
      setDeliveryScopeListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadRevisionSpecificProjectData = async () => {
    try {
      const response = await getRevisionProjectScopes(revisionId);
      setRevisionSpecificProjectScope(
        response.data.revisionProjectScopes?.length > 0
          ? dropDownListGenerator(response.data.revisionProjectScopes)
          : [{ label: "", value: "" }]
      );
      setRevisionSpecificProjectScopeLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadRevisionSpecificDeliveryData = async () => {
    try {
      const response = await getRevisionDeliveryScopes(revisionId);
      setRevisionSpecificDeliveryScope(
        response.data.revisionDeliveryScopes?.length > 0
          ? dropDownListGenerator(response.data.revisionDeliveryScopes)
          : [{ label: "", value: "" }]
      );
      setRevisionSpecificDeliveryScopeLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const isDisable = () => {
    return !R.isNil(jobDetail.partiallyApprovedRevisionId);
  };

  const updateScopeText = (index, item, scopeTerm, type) => {
    if (type === "project") {
      setRevisionSpecificProjectScope(
        produce(draft => {
          draft[index] = { ...item, label: scopeTerm };
        })
      );
    } else if (type === "delivery") {
      setRevisionSpecificDeliveryScope(
        produce(draft => {
          draft[index] = { ...item, label: scopeTerm };
        })
      );
    }
  };

  if (
    projectScopeListLoad ||
    deliveryScopeListLoad ||
    revisionSpecificProjectScopeLoad ||
    revisionSpecificDeliveryScopeLoad
  ) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="grid w-full grid-cols-2 gap-6 mb-6 divide-x divide-gray-300">
      <div className="flex flex-col col-span-1">
        <Typography style="h4" weight="semibold">
          Project Scope
        </Typography>
        <DragDropContext
          onDragEnd={result =>
            scopeReorderFunction(
              revisionId,
              result,
              "project",
              setRevisionSpecificProjectScope,
              setRevisionSpecificDeliveryScope,
              loadRevisionSpecificProjectData,
              loadRevisionSpecificDeliveryData
            )
          }
        >
          <Droppable droppableId="droppable">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {revisionSpecificProjectScope.map((item, index) => {
                  return (
                    <ScopeItem
                      key={index}
                      revisionId={revisionId}
                      selectOptions={projectScope}
                      index={index}
                      item={item}
                      scopeTerm={item.label}
                      newCreateableName={changeProjectScopeValue}
                      deleteFunction={destroyRevisionScopeEntry}
                      onChangeFunction={changeProjectScopeValue}
                      updateScopeEntry={updateScopeText}
                      type="project"
                      updateScopeEntryCall={updateRevisionScopeEntry}
                      isDisable={isDisable}
                      setRevisionSpecificProjectScope={
                        setRevisionSpecificProjectScope
                      }
                      loadRevisionSpecificProjectData={
                        loadRevisionSpecificProjectData
                      }
                    />
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className="flex justify-end mt-3">
          <Button
            style="link"
            disabled={isDisable()}
            label="Add Project Scope"
            onClick={() => {
              addScope(
                revisionId,
                "project",
                setRevisionSpecificProjectScope,
                () => {},
                loadRevisionSpecificProjectData
              );
            }}
          />
        </div>
      </div>
      <div className="flex flex-col col-span-1 pl-6">
        <Typography style="h4" weight="semibold">
          Delivery Scope
        </Typography>
        <DragDropContext
          onDragEnd={result =>
            scopeReorderFunction(
              revisionId,
              result,
              "delivery",
              setRevisionSpecificProjectScope,
              setRevisionSpecificDeliveryScope,
              loadRevisionSpecificProjectData,
              loadRevisionSpecificDeliveryData
            )
          }
        >
          <Droppable droppableId="droppable">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {revisionSpecificDeliveryScope.map((item, index) => {
                  return (
                    <ScopeItem
                      revisionId={revisionId}
                      key={index}
                      selectOptions={deliveryScope}
                      index={index}
                      item={item}
                      scopeTerm={item.label}
                      newCreateableName={changeDeliveryScopeValue}
                      deleteFunction={destroyRevisionScopeEntry}
                      onChangeFunction={changeDeliveryScopeValue}
                      updateScopeEntry={updateScopeText}
                      updateScopeEntryCall={updateRevisionScopeEntry}
                      isDisable={isDisable}
                      type="delivery"
                      setRevisionSpecificDeliveryScope={
                        setRevisionSpecificDeliveryScope
                      }
                      loadRevisionSpecificDeliveryData={
                        loadRevisionSpecificDeliveryData
                      }
                    />
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className="flex justify-end mt-3">
          <Button
            style="link"
            disabled={isDisable()}
            label="Add Delivery Scope"
            onClick={() => {
              addScope(
                revisionId,
                "delivery",
                () => {},
                setRevisionSpecificDeliveryScope,
                () => {},
                loadRevisionSpecificDeliveryData
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Scopes;

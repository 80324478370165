import axios from "axios";

const getCompanyDetailsUrl = id => {
  if (id) {
    return `/api/v1/job_module/company_details/${id}`;
  }

  return `/api/v1/job_module/company_details`;
};

export const getCompanyDetails = () => {
  return axios.get(getCompanyDetailsUrl());
};

import React from "react";
import { Input, Select } from "@bigbinary/neetoui/formik";

const CommissionComponent = ({
  artistOptions,
  isMarkupDisable,
  setFieldValue,
  value,
}) => (
  <>
    <Select
      isClearable
      label="Assigned To"
      options={artistOptions}
      name="Assigned"
      blurInputOnSelect
      isDisabled={isMarkupDisable()}
      value={artistOptions.find(artist => artist.value === value?.value)}
      onChange={opt => {
        setFieldValue("Assigned", opt);
      }}
    />

    <Input
      type="number"
      label="Commission %"
      name="CommissionPercentage"
      disabled={isMarkupDisable()}
    />
  </>
);

export default CommissionComponent;

import axios from "axios";

const getEstimateRevisionItemsUrl = (revisionId, id) => {
  if (id) {
    return `/api/v1/job_module/estimate_revisions/${revisionId}/estimate_revision_items/${id}`;
  }

  return `/api/v1/job_module/estimate_revisions/${revisionId}/estimate_revision_items`;
};

export const getEstimateRevisionItems = revisionId => {
  return axios.get(getEstimateRevisionItemsUrl(revisionId));
};

export const createEstimateRevisionItem = (revisionId, payload) => {
  return axios.post(getEstimateRevisionItemsUrl(revisionId), payload);
};

export const updateEstimateRevisionItem = (revisionId, id, payload) => {
  return axios.put(getEstimateRevisionItemsUrl(revisionId, id), payload);
};

export const destroyEstimateRevisionItem = (revisionId, id) => {
  return axios.delete(getEstimateRevisionItemsUrl(revisionId, id));
};

export const updateEstimateRevisionItemPosition = (
  revisionId,
  id,
  type,
  difference
) => {
  return axios.put(
    `${getEstimateRevisionItemsUrl(
      revisionId,
      id
    )}/change_position?type=${type}&difference=${difference}`
  );
};

import axios from "axios";

const getCompanyDetailsUrl = id => {
  if (id) {
    return `/api/v1/settings/company_details/${id}`;
  }

  return `/api/v1/settings/company_details`;
};

export const getCompanyDetails = () => {
  return axios.get(getCompanyDetailsUrl());
};

export const getCompanyDetail = id => {
  return axios.get(getCompanyDetailsUrl(id));
};

export const createCompanyDetail = payload => {
  return axios.post(getCompanyDetailsUrl(), payload);
};

export const updateCompanyDetail = (id, payload) => {
  return axios.put(getCompanyDetailsUrl(id), payload);
};

export const destroyCompanyDetail = id => {
  return axios.delete(getCompanyDetailsUrl(id));
};

import React from "react";
import classNames from "classnames";
import PrintSVG from "images/template/print.svg";
import SaveSVG from "images/template/save.svg";

const PrintSave = ({ isVisible = true, getPdfRecords, getPdfRecordsPrint }) => {
  return (
    <div
      className={classNames(
        "fixed items-center justify-center p-4 bg-white border border-black rounded-full bottom-8 left-8 gap-x-4",
        {
          "hidden ": !isVisible,
          "hidden lg:flex": isVisible,
        }
      )}
      style={{ fontFamily: "Modern Gothic Mono" }}
    >
      <div
        className="flex items-center justify-start cursor-pointer"
        onClick={() => getPdfRecordsPrint()} //Print Action
      >
        <img src={PrintSVG} alt="Print" className="w-8 h-8" />
        <span className="ml-4 text-sm font-light leading-4">Print</span>
      </div>
      <div
        className="flex items-center justify-start cursor-pointer"
        onClick={() => getPdfRecords()} //Save Action
      >
        <img src={SaveSVG} alt="Print" className="w-8 h-8" />
        <span className="ml-4 text-sm font-light leading-4">Save</span>
      </div>
    </div>
  );
};

export default PrintSave;

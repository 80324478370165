import React, { useState } from "react";
import { Plus, Notes } from "@bigbinary/neeto-icons";
import { Button, Modal, Table, Typography } from "@bigbinary/neetoui";
import * as dayjs from "dayjs";

const ShowNote = ({ notes }) => {
  const [showNote, setShowNote] = useState(false);

  const urlify = text => {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, '<a href="$1">$1</a>');
  };

  return (
    <div>
      {notes?.length > 0 ? (
        <div className="flex flex-col space-y-1">
          {showNote ? (
            <Typography
              style="body2"
              className="text-gray-800 w-96"
              dangerouslySetInnerHTML={{
                __html: urlify(notes?.split("\n").join(" <br>")),
              }}
            ></Typography>
          ) : (
            <Typography
              style="body2"
              className="text-gray-800 w-80"
              dangerouslySetInnerHTML={{
                __html:
                  notes?.length < 200
                    ? urlify(notes.split("\n").join("<br>"))
                    : urlify(notes.substring(0, 120)) + "...",
              }}
            ></Typography>
          )}
          {notes?.length > 200 && (
            <Button
              style="link"
              onClick={() => setShowNote(!showNote)}
              label={showNote ? "Less" : "More"}
            />
          )}
        </div>
      ) : (
        "No notes"
      )}
    </div>
  );
};

const RenderNotes = ({ notes, setAddNote, setJobId, jobId }) => {
  const [showNotes, setShowNotes] = useState(false);

  const COLUMN_DATA = [
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "20%",
      render: createdAt => dayjs(createdAt).format("DD/MM/YYYY"),
    },
    {
      title: "Note",
      dataIndex: "note",
      key: "note",
      width: "80%",
      render: note => <ShowNote notes={note} />,
    },
  ];

  return (
    <>
      {notes?.length > 0 && (
        <>
          <Button
            style="text"
            size="small"
            icon={Notes}
            onClick={() => setShowNotes(true)}
            tooltipProps={{
              content: "View notes",
            }}
          />
          <Modal
            size="large"
            className="mx-auto"
            style={{ height: "65vh" }}
            isOpen={showNotes}
            onClose={() => setShowNotes(false)}
          >
            <Modal.Header className="flex items-center space-x-6">
              <Typography style="h2" weight="semibold">
                Notes
              </Typography>
              <Button
                icon={Plus}
                style="link"
                label="Add Note"
                iconPosition="left"
                onClick={() => {
                  setJobId(jobId);
                  setAddNote(true);
                }}
              />
            </Modal.Header>
            <Modal.Body>
              <div
                className="flex flex-col overflow-hidden"
                style={{ height: "calc(65vh -  100px)" }}
              >
                <Table fixedHeight columnData={COLUMN_DATA} rowData={notes} />
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
};

export default RenderNotes;

import React from "react";
import { Tooltip } from "neetoui";
import { CloseCircle } from "@bigbinary/neeto-icons";
import { Dropdown, Button } from "@bigbinary/neetoui";

const StaffDropdown = ({
  staffOptions,
  setUserId,
  userId,
  loadJobListResponse,
}) => {
  const { Menu, MenuItem } = Dropdown;
  return (
    <div className="flex items-center space-x-2">
      <Dropdown
        label={
          staffOptions.find(staff => staff.id === userId)?.fullName ||
          "Select Staff"
        }
        buttonStyle="secondary"
        position="bottom-end"
        closeOnSelect
      >
        <Menu>
          {staffOptions.map(staff => {
            return (
              <MenuItem.Button
                key={staff.id}
                onClick={() => {
                  setUserId(staff.id);
                }}
              >
                {staff.fullName}
              </MenuItem.Button>
            );
          })}
        </Menu>
      </Dropdown>
      <Button
        icon={CloseCircle}
        style="text"
        disabled={!userId}
        onClick={async () => {
          setUserId(null);
          loadJobListResponse(null, true);
        }}
        tooltipProps={{
          content: "Remove Staff",
          position: "auto",
        }}
      />
    </div>
  );
};

export default StaffDropdown;

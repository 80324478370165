import React, { useState, useEffect } from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import { showToastrError } from "common";
import { useUserState } from "contexts/user";
import { buildUrl } from "../utils";
import routes from "../routes";
import { Spinner, Button, Callout, Alert } from "@bigbinary/neetoui";
import { Header, Container } from "@bigbinary/neetoui/layouts";
import {
  basecampLogin,
  getBasecampTokenPresence,
  basecampLogout,
} from "apis/settings/basecamp_sessions";
import SettingsTile from "../SettingsTile";
import { BUSINESS_DETAILS_LIST } from "../constants";

import CompanyDetails from "./CompanyDetails";
import EmailDetails from "./EmailBackgroundDetails";
import Accounts from "./Accounts";
import XeroSessions from "./XeroSessions";

const BusinessDetails = ({ title, tab }) => {
  const { user } = useUserState();
  const { subTab } = useParams();
  const breadcrumbs = [
    { text: title, link: buildUrl(routes.settings.show, { tab }) },
  ];

  const [basecampLogoutConfirm, setBasecampLogoutConfirm] = useState(false);
  const [basecampSessionLoad, setBasecampSessionLoad] = useState(true);
  const [basecampSessionPresence, setBasecampSessionPresence] = useState(false);

  useEffect(() => {
    getBasecampSessionResponse();
  }, []);

  const getBasecampSessionResponse = async () => {
    try {
      setBasecampSessionLoad(true);
      const { data } = await getBasecampTokenPresence();
      setBasecampSessionPresence(data.basecampSessionPresence);
      setBasecampSessionLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const basecampLoginEntry = async () => {
    try {
      const { data } = await basecampLogin();
      window.location.href = data.url;
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const basecampLogoutResponse = async () => {
    try {
      await basecampLogout();
      setBasecampLogoutConfirm(false);
      setBasecampSessionPresence(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  if (basecampSessionLoad) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      {subTab ? (
        <Switch>
          <Route
            path={routes.settings.companyDetails}
            render={() => <CompanyDetails breadcrumbs={breadcrumbs} />}
          />
          <Route
            path={routes.settings.emailDetails}
            render={() => <EmailDetails breadcrumbs={breadcrumbs} />}
          />
          <Route
            path={routes.settings.accounts}
            render={() => <Accounts breadcrumbs={breadcrumbs} />}
          />
          <Route
            path={routes.settings.xero_sessions}
            render={() => <XeroSessions breadcrumbs={breadcrumbs} />}
          />
          <Redirect to={buildUrl(routes.settings.show, { tab })} />
        </Switch>
      ) : (
        <Container>
          <Header
            title={title}
            actionBlock={
              <div className="flex items-center gap-x-3">
                {basecampSessionPresence ? (
                  <Button
                    style="danger"
                    label="Logout from Basecamp"
                    onClick={() => setBasecampLogoutConfirm(true)}
                  />
                ) : (
                  <Button
                    label="Login to Basecamp"
                    onClick={() => basecampLoginEntry()}
                  />
                )}
              </div>
            }
          />

          {basecampSessionPresence && (
            <Callout style="info" className="w-full mb-3">
              Basecamp Logged in!
            </Callout>
          )}

          <div className="grid grid-cols-2 gap-3 lg:grid-cols-3 lg:gap-6 xl:grid-cols-4 2xl:grid-cols-4">
            {BUSINESS_DETAILS_LIST(user)
              .filter(item => item !== null)
              .map(item => (
                <SettingsTile
                  {...item}
                  key={item.value}
                  path={buildUrl(item.path, { tab })}
                />
              ))}
          </div>

          <Alert
            isOpen={basecampLogoutConfirm}
            title="Logout from Basecamp"
            message="Are you sure you want to logout from basecamp?"
            onClose={() => setBasecampLogoutConfirm(false)}
            onSubmit={() => basecampLogoutResponse()}
          />
        </Container>
      )}
    </>
  );
};

export default BusinessDetails;

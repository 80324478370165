import React from "react";
import classnames from "classnames";

const UsageTable = ({ data, type, title, bgColor, showNotes, notes }) => {
  return (
    <>
      <div
        className={classnames(
          "w-full overflow-x-auto text-sm regular-table ",
          { "": type == "bordered" },
          { "px-8 my-8 md:my-6": type != "bordered" }
        )}
      >
        <div
          className={classnames(
            {
              "w-full overflow-x-auto rounded-none md:rounded-lg":
                type != "bordered",
            },
            { "py-6": bgColor }
          )}
          style={{
            backgroundColor: bgColor ? bgColor : "bg-white",
            borderRadius: "12px",
          }}
        >
          <table
            className="w-full text-left md:mr-0 bordered-table"
            style={{ minWidth: "600px" }}
          >
            <thead className="px-10">
              <tr>
                {type != "bordered" ? (
                  <th
                    className="hidden w-5 md:table-cell lg:w-8"
                    style={{ maxWidth: "40px" }}
                  ></th>
                ) : (
                  ""
                )}
                {data?.heads?.map((head, index) => (
                  <th
                    className={classnames(
                      "py-2 border-b border-black whitespace-no-wrap",
                      {
                        "text-right": index === data?.heads?.length - 1,
                      },
                      {
                        "pr-4": index != data?.heads?.length - 1,
                      },
                      {
                        "border-t": title,
                      }
                    )}
                    key={head}
                  >
                    {head}
                  </th>
                ))}

                {type != "bordered" ? (
                  <th
                    className="hidden w-5 md:table-cell lg:w-8"
                    style={{ maxWidth: "40px" }}
                  ></th>
                ) : (
                  ""
                )}
              </tr>
            </thead>
            <tbody>
              {data?.rows?.map((row, trIndex) => (
                <tr
                  key={row + trIndex}
                  style={{
                    backgroundColor: row?.backgroundColor
                      ? row?.backgroundColor
                      : "transparent",
                  }}
                >
                  {type != "bordered" ? (
                    <td
                      className="hidden w-5 md:table-cell lg:w-8"
                      style={{ maxWidth: "40px" }}
                    ></td>
                  ) : (
                    ""
                  )}
                  {row?.values?.map((value, tdIndex) => (
                    <td
                      className={classnames(
                        "py-2",
                        "w-36",
                        { "w-56": tdIndex === 1 },
                        {
                          "text-right pl-3":
                            tdIndex === row?.values?.length - 1,
                        },
                        {
                          "border-b border-black":
                            trIndex != data?.rows?.length - 1,
                        },
                        {
                          "pr-4": tdIndex != row?.values?.length - 1,
                        },
                        {
                          "border-t": !data?.heads && trIndex == 0 && !bgColor,
                        },
                        {
                          "border-b":
                            !data?.foots &&
                            trIndex == data?.rows?.length - 1 &&
                            !bgColor &&
                            type != "bordered",
                        }
                      )}
                      key={value + tdIndex}
                    >
                      {value}
                    </td>
                  ))}
                  {type != "bordered" ? (
                    <td
                      className="hidden w-5 md:table-cell lg:w-8"
                      style={{ maxWidth: "40px" }}
                    ></td>
                  ) : (
                    ""
                  )}
                </tr>
              ))}
            </tbody>
            <tbody>
              {data?.foots?.map((row, trIndex) => (
                <tr
                  key={row + trIndex}
                  style={{
                    backgroundColor: row?.backgroundColor
                      ? row?.backgroundColor
                      : "transparent",
                  }}
                  className="rounded-lg"
                >
                  {type != "bordered" ? (
                    <td
                      className="hidden w-5 md:table-cell lg:w-8"
                      style={{ maxWidth: "40px" }}
                    ></td>
                  ) : (
                    ""
                  )}
                  {row?.values?.map((value, tdIndex) => (
                    <td
                      className={classnames("py-6", {
                        "text-right": tdIndex === data?.foots?.length - 1,
                      })}
                      key={value + tdIndex}
                    >
                      {value}
                    </td>
                  ))}
                  {type != "bordered" ? (
                    <td
                      className="hidden w-5 md:table-cell lg:w-8"
                      style={{ maxWidth: "40px" }}
                    ></td>
                  ) : (
                    ""
                  )}
                </tr>
              ))}
            </tbody>
            {showNotes && (
              <tbody>
                <tr>
                  <td colSpan="7">
                    <hr className="border border-black" />
                  </td>
                </tr>
                <tr className="pt-2">
                  <td colSpan="7" className="font-bold">
                    Usage Notes:
                    <br />
                    {notes}
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
        <div className="h-3 "></div>
      </div>
    </>
  );
};

export default UsageTable;

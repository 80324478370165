import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { MenuBar } from "@bigbinary/neetoui/layouts";
import { SETTINGS_CATAGORIES, DEFAULT_TAB } from "./constants";
import { buildUrl } from "./utils";
import routes from "./routes";
import RenderTab from "./RenderTab";

const Settings = ({ history, match }) => {
  const { tab } = match.params;

  return (
    <div className="flex flex-row h-screen overflow-hidden jw-settings">
      <MenuBar showMenu={true} title="Settings">
        {SETTINGS_CATAGORIES.map((item, index) => (
          <MenuBar.Item
            active={item.value === tab}
            className="px-3 py-2"
            description={item.description}
            key={index}
            label={item.label}
            onClick={() =>
              history.push(buildUrl(routes.settings.show, { tab: item.value }))
            }
          />
        ))}
      </MenuBar>
      <Switch>
        {SETTINGS_CATAGORIES.map(item => (
          <Route
            exact={item.exact}
            key={item.value}
            path={item.path}
            render={() => (
              <RenderTab
                component={item.component}
                tab={item.value}
                title={item.label}
              />
            )}
          />
        ))}
        <Redirect to={buildUrl(routes.settings.show, { tab: DEFAULT_TAB })} />
      </Switch>
    </div>
  );
};

export default Settings;

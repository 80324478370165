import axios from "axios";
import { DEFAULT_PAGE_SIZE } from "common/helper";

const getArtistsUrl = (id, searchParams, page, idArray) => {
  if (id) {
    return `/api/v1/job_module/artists/${id}`;
  }

  if (searchParams || idArray) {
    let searchQuery = `q[first_name_i_cont_any]=${searchParams}&q[last_name_i_cont_any]=${searchParams}&q[full_name_i_cont_any]=${searchParams}&q[company_name_i_cont_any]=${searchParams}&q[m]=or&q[address_country_i_cont_any]=${searchParams}&q[address_state_i_cont_any]=${searchParams}&q[address_suburb_i_cont_any]=${searchParams}&q[id_array]=${idArray}`;
    return `/api/v1/job_module/artists?${searchQuery}&page=${page}&per_page=${DEFAULT_PAGE_SIZE}`;
  }
  return `/api/v1/job_module/artists?page=${page}&per_page=${DEFAULT_PAGE_SIZE}`;
};

export const getArtists = (searchParams, page = 1, idArray = "") => {
  return axios.get(getArtistsUrl("", searchParams, page, idArray));
};

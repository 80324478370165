import * as Yup from "yup";

export const TEAMMEMBER_VALIDATION_SCHEMA = Yup.object({
  firstName: Yup.string().required("First name can't be empty"),
  lastName: Yup.string().required("Last name can't be empty"),
  email: Yup.string().email("Invalid Email").required("Email can't be empty"),
});

export const INITIAL_TEAMMEMBER_VALUE = {
  firstName: "",
  lastName: "",
  email: "",
  pronouns: "",
  title: "",
  dateOfBirth: "",
  phoneNumber: "",
};

import React, { useState, useEffect } from "react";
import { Input, Spinner, Select, Typography, Switch } from "@bigbinary/neetoui";
import CountryPhone from "components/Common/CountryPhone";
import AsyncPaginateSelect from "components/Common/AsyncPaginateSelect";
import { getOrganisations } from "apis/contacts/organisations";
import { getStaffs } from "apis/artists/staffs";
import { dropDownListGenerator, removeDuplicacy, sleep } from "common/helper";
import { showToastrError } from "common";
import { countries } from "countries-list";

const AddContact = ({ inputRef, formik }) => {
  const [accountPayable, setAccountPayable] = useState(
    formik.values.accountPayable
  );
  const [showInCrm, setShowInCrm] = useState(formik.values.showInCrm);
  const [organisationOptions, setOrganisationOptions] = useState([]);
  const [orgPage, setOrgPage] = useState(1);
  const [orgInitial, setOrgInitial] = useState(true);
  const [staffOptions, setStaffOptions] = useState([]);
  const [staffOptionLoad, setStaffOptionLoad] = useState(true);
  const [organisationOptionLoad, setOrganisationOptionLoad] = useState(true);
  const [country, setCountry] = useState();
  const [selectedCountry, setSelectedCountry] = useState();

  const setAccountPayableValue = accountPayable => {
    setAccountPayable(accountPayable);
    formik.setFieldValue("accountPayable", accountPayable);
  };

  useEffect(() => {
    loadOrganisationList();
    loadStaffListResponse();
    handleCountryChange(formik.values.country);
  }, []);

  useEffect(() => {
    if (
      formik.values.organisationId?.length > 0 &&
      orgInitial &&
      organisationOptions.length > 0
    ) {
      searchOrganisation(formik.values.organisationId);
      setOrgInitial(false);
    }
  }, [organisationOptions]);

  useEffect(() => {
    if (country) {
      handleCountryChange(country);
    }
  }, [country]);

  const loadStaffListResponse = async () => {
    try {
      const response = await getStaffs();
      setStaffOptions(dropDownListGenerator(response.data.staffs));
      setStaffOptionLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const handleCountryChange = value => {
    let countryObj = Object.entries(countries).find(item => {
      return (
        item[0].toLowerCase() === value?.toLowerCase() ||
        item[1].name.toLowerCase() === value?.toLowerCase()
      );
    });

    countryObj && setSelectedCountry(countryObj[1]);
  };

  const loadOrganisationList = async search => {
    setOrganisationOptionLoad(true);
    await sleep(1000);

    try {
      let filteredOptions;
      let totalRecords;

      if (!search) {
        const response = await getOrganisations("", orgPage);
        setOrganisationOptions([
          ...organisationOptions,
          ...response.data.organisations,
        ]);
        totalRecords = response.data.totalRecords;
        setOrgPage(orgPage + 1);
        filteredOptions = response.data.organisations;
      } else {
        const searchLower = search.toLowerCase();

        const response = await getOrganisations(searchLower, orgPage);
        setOrganisationOptions([
          ...organisationOptions,
          ...response.data.organisations,
        ]);
        setOrgPage(orgPage + 1);
        filteredOptions = response.data.organisations;
        totalRecords = filteredOptions.length;
      }
      return {
        options: dropDownListGenerator(removeDuplicacy(filteredOptions)),
        hasMore: filteredOptions.length === totalRecords ? false : true,
      };
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      setOrganisationOptionLoad(false);
    }
  };

  const searchOrganisation = async organisationId => {
    try {
      const searchResponse = await getOrganisations(organisationId, 1);
      setOrganisationOptions([
        ...organisationOptions,
        ...searchResponse.data.organisations,
      ]);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  if (staffOptionLoad) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <Spinner />
      </div>
    );
  }

  return (
    <form className="pb-12 space-y-6">
      <AsyncPaginateSelect
        required
        innerRef={inputRef}
        label="Organisation"
        id="organisationId"
        placeholder="Select Organisation"
        isLoading={organisationOptionLoad}
        value={
          dropDownListGenerator(removeDuplicacy(organisationOptions)).find(
            org => org.value === formik.values.organisationId
          ) || null
        }
        isClearable={true}
        error={
          Boolean(
            formik.touched.organisationId && formik.errors.organisationId
          ) && formik.errors.organisationId
        }
        onChange={async opt => {
          await formik.setFieldValue("organisationId", opt.value);
          await formik.setFieldTouched("organisationId", true);
          setCountry(opt.country);
        }}
        loadOptions={loadOrganisationList}
      />

      <Input
        required
        label="First Name"
        name="firstName"
        onChange={formik.handleChange}
        value={formik.values.firstName}
        error={
          Boolean(formik.touched.firstName && formik.errors.firstName) &&
          formik.errors.firstName
        }
        {...formik.getFieldProps("firstName")}
      />

      <Input
        required
        label="Last Name"
        name="lastName"
        onChange={formik.handleChange}
        value={formik.values.lastName}
        error={
          Boolean(formik.touched.lastName && formik.errors.lastName) &&
          formik.errors.lastName
        }
        {...formik.getFieldProps("lastName")}
      />

      <Input
        label="Pronouns"
        name="pronouns"
        value={formik.values.pronouns}
        onChange={formik.handleChange}
        error={
          Boolean(formik.touched.pronouns && formik.errors.pronouns) &&
          formik.errors.pronouns
        }
        {...formik.getFieldProps("pronouns")}
      />

      <Input
        required
        label="Email"
        name="email"
        onChange={formik.handleChange}
        value={formik.values.email}
        error={
          Boolean(formik.touched.email && formik.errors.email) &&
          formik.errors.email
        }
        {...formik.getFieldProps("email")}
      />

      <Input
        label="Title"
        name="tite"
        onChange={formik.handleChange}
        value={formik.values.title}
        error={
          Boolean(formik.touched.title && formik.errors.title) &&
          formik.errors.title
        }
        {...formik.getFieldProps("title")}
      />

      <CountryPhone
        formik={formik}
        required={false}
        selectedCountry={selectedCountry}
      />

      <CountryPhone
        formik={formik}
        required={false}
        selectedCountry={selectedCountry}
        attribute="mobileNumber"
      />

      <Select
        label="Assigned Producer"
        name="userId"
        id="userId"
        placeholder="Select an Option"
        value={
          staffOptions.find(staff => staff.value === formik.values.userId) || ""
        }
        onBlur={() => formik.setFieldTouched("userId", true)}
        error={
          Boolean(formik.touched.userId && formik.errors.userId) &&
          formik.errors.userId
        }
        isClearable={true}
        touched={formik.touched.userId}
        options={staffOptions}
        onChange={opt => {
          if (opt) {
            formik.setFieldValue("userId", opt.value);
          } else {
            formik.setFieldValue("userId", "");
          }
        }}
      />

      <div className="flex flex-col space-y-2">
        <Switch
          label="Accounts Payable"
          checked={accountPayable}
          onChange={() => {
            setAccountPayableValue(!accountPayable);
          }}
        />
        <Typography style="body3" className="text-gray-600">
          Send a copy of invoice whenever generated to the above mentioned email
        </Typography>
      </div>

      <Switch
        label="Show in CRM"
        checked={showInCrm}
        onChange={() => {
          setShowInCrm(!showInCrm);
          formik.setFieldValue("showInCrm", !showInCrm);
        }}
      />
    </form>
  );
};

export default AddContact;

import React, { useState, useEffect } from "react";
import * as dayjs from "dayjs";
import { Avatar, Typography, Button, Alert } from "@bigbinary/neetoui";
import { Edit, Delete } from "@bigbinary/neeto-icons";
import AttachmentCard from "../AttachmentCard";
import {
  getDraftMessageThread,
  destroyDraftMessageThread,
} from "apis/jobs/draft_message_threads";
import { getDraftMessage, destroyDraftMessage } from "apis/jobs/draft_messages";
import { showToastrError } from "common";

const Viewer = ({
  setRefs,
  message,
  jobId,
  loadMessageThreadsResponse,
  threadDetail,
  setNewMessage,
  formik,
  allRecipients,
  setDownloadLoad,
  setMessageBody,
  setAttachments,
  setSelectedMessageId,
  loadThreadDetailResponse,
}) => {
  const [messageDetail, setMessageDetail] = useState();
  const [threadDeleteConfirmAlert, setThreadDeleteConfirmAlert] = useState(
    false
  );
  const [messageDeleteConfirmAlert, setMessageDeleteConfirmAlert] = useState(
    false
  );

  useEffect(() => {
    if (messageDetail) {
      formik.setFieldValue("messageId", messageDetail.id);
      formik.setFieldValue("subject", messageDetail.subject);
      formik.setFieldValue("htmlBody", messageDetail.htmlBody);
      formik.setFieldValue(
        "fileManagementLink",
        messageDetail.fileManagementLink
      );
      formik.setFieldValue("to", getEmails(messageDetail.to, allRecipients));
      formik.setFieldValue("cc", getEmails(messageDetail.cc, allRecipients));
      formik.setFieldValue("bcc", getEmails(messageDetail.bcc, allRecipients));
      formik.setFieldValue(
        "attachments_attributes",
        messageDetail.attachments_attributes
      );
    }
  }, [messageDetail]);

  const getEmails = (email, list) => {
    if (email.constructor === Array) {
      return list.filter(recipient => email.includes(recipient.email));
    } else {
      return list.filter(recipient => recipient.email === email);
    }
  };

  const getDraftMessageThreadResponse = async () => {
    try {
      const { data } = await getDraftMessageThread(jobId, message.id);
      setMessageDetail(data.message);
      setNewMessage(true);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const getDraftMessageResponse = async () => {
    try {
      const { data } = await getDraftMessage(jobId, message.id);
      setMessageBody(data.message.htmlBody);
      setAttachments(data.message.attachments);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const destroyDraftMessageThreadResponse = async () => {
    try {
      await destroyDraftMessageThread(jobId, message.id);
      setThreadDeleteConfirmAlert(false);
      loadMessageThreadsResponse();
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const destroyDraftMessageResponse = async () => {
    try {
      await destroyDraftMessage(jobId, message.id);
      setMessageDeleteConfirmAlert(false);
      loadThreadDetailResponse();
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  return (
    <div ref={setRefs} className="flex items-start pt-5" id={message.id}>
      <div className="mr-3">
        <Avatar
          size="large"
          user={{
            name: message.senderName,
            imageUrl: message.senderThumbUrl,
          }}
        />
      </div>
      <div className="flex flex-col w-full">
        <div className="flex justify-between">
          <div className="flex flex-col items-start py-1 mb-5 space-y-1">
            <Typography style="h5" weight="medium">
              {message.senderName} • {message.senderDesignation}
            </Typography>
            <Typography
              style="nano"
              weight="normal"
              className="neeto-ui-text-gray-600"
            >
              {dayjs(message.createdAt).format("dddd, DD MMM YYYY • hh:mm a")}
            </Typography>
          </div>

          {threadDetail.draft && (
            <div>
              <Button
                label="Edit"
                icon={Edit}
                iconPosition="left"
                onClick={() => getDraftMessageThreadResponse()}
                className="mr-2"
              />

              <Button
                label="Delete"
                icon={Delete}
                style="danger-text"
                iconPosition="left"
                onClick={() => setThreadDeleteConfirmAlert(true)}
              />
            </div>
          )}

          {message.draft && (
            <div>
              <Button
                label="Edit"
                icon={Edit}
                iconPosition="left"
                onClick={() => {
                  setSelectedMessageId(message.id);
                  getDraftMessageResponse();
                }}
                className="mr-2"
              />

              <Button
                label="Delete"
                icon={Delete}
                style="danger-text"
                iconPosition="left"
                onClick={() => setMessageDeleteConfirmAlert(true)}
              />
            </div>
          )}
        </div>
        <Typography
          style="body2"
          weight="normal"
          className="neeto-ui-text-gray-700"
        >
          <div
            className="message-editor-preview"
            dangerouslySetInnerHTML={{
              __html: [message.header, message.htmlBody, message.footer]
                .filter(el => el != null)
                .join(" <br> "),
            }}
          ></div>

          {message.fileManagementLink && (
            <Button
              style="link"
              className="mt-5"
              href={message.fileManagementLink}
              target="_blank"
              label={message.fileManagementLink}
            />
          )}
        </Typography>

        {message.attachments.length > 0 && (
          <div className="grid grid-cols-4 gap-2 mt-3">
            {message.attachments.map((attachment, index) => {
              return (
                <AttachmentCard
                  key={index}
                  cardUrl={attachment.url}
                  smallUrl={attachment.smallUrl}
                  name={attachment.name}
                  type={attachment.type.split("/")[1]}
                  attachmentId={attachment.id}
                  setDownloadLoad={setDownloadLoad}
                />
              );
            })}
          </div>
        )}
      </div>

      <Alert
        isOpen={threadDeleteConfirmAlert}
        title="Delete Message"
        message="Are you sure you want to delete this thread?"
        onClose={() => setThreadDeleteConfirmAlert(false)}
        onSubmit={() => {
          destroyDraftMessageThreadResponse();
        }}
        submitButtonLabel="Yes, delete"
        cancelButtonLabel="No, cancel"
      />

      <Alert
        isOpen={messageDeleteConfirmAlert}
        title="Delete Message"
        message="Are you sure you want to delete this message?"
        onClose={() => setMessageDeleteConfirmAlert(false)}
        onSubmit={() => {
          destroyDraftMessageResponse();
        }}
        submitButtonLabel="Yes, delete"
        cancelButtonLabel="No, cancel"
      />
    </div>
  );
};

export default Viewer;

import axios from "axios";

const getEstimateRevisionsUrl = (jobId, id, forcefullyDelete = false) => {
  if (id && forcefullyDelete) {
    return `/api/v1/job_module/jobs/${jobId}/estimate_revisions/${id}?forcefully_delete=${forcefullyDelete}`;
  }

  if (id) {
    return `/api/v1/job_module/jobs/${jobId}/estimate_revisions/${id}`;
  }

  return `/api/v1/job_module/jobs/${jobId}/estimate_revisions`;
};

export const getEstimateRevisions = jobId => {
  return axios.get(getEstimateRevisionsUrl(jobId));
};

export const getEstimateRevision = (jobId, id) => {
  return axios.get(getEstimateRevisionsUrl(jobId, id));
};

export const createEstimateRevision = (jobId, payload) => {
  return axios.post(getEstimateRevisionsUrl(jobId), payload);
};

export const updateEstimateRevision = (jobId, id, payload) => {
  return axios.put(getEstimateRevisionsUrl(jobId, id), payload);
};

export const destroyEstimateRevision = (
  jobId,
  id,
  forcefullyDelete = false
) => {
  return axios.delete(getEstimateRevisionsUrl(jobId, id, forcefullyDelete));
};

export const sendEstimate = (jobId, id, payload) => {
  return axios.post(
    `${getEstimateRevisionsUrl(jobId, id)}/send_estimates`,
    payload
  );
};

export const sendOverage = (jobId, id, payload) => {
  return axios.post(
    `${getEstimateRevisionsUrl(jobId, id)}/send_overages`,
    payload
  );
};

import React, { useState, useEffect } from "react";
import { Header, Container } from "@bigbinary/neetoui/layouts";
import { Spinner, Button, Alert, Toastr } from "@bigbinary/neetoui";
import { isEmpty } from "ramda";
import { useFormik } from "formik";
import {
  getTaxTypes,
  createTaxType,
  updateTaxType,
  destroyTaxType,
} from "apis/settings/tax_types";
import { getCurrencies } from "apis/settings/currencies";
import { showToastrError } from "common";
import { getRandomNotFoundImage } from "common/helper";
import useDebounce from "common/debounce";
import {
  TAX_TYPE_VALIDATION_SCHEMA,
  TAX_TYPE_INITIAL_VALUE,
} from "../constants";
import List from "./List";
import EmptyState from "components/Common/EmptyState";
import FormPane from "./FormPane";

const TaxTypes = ({ breadcrumbs }) => {
  const [taxTypeList, setTaxTypeList] = useState([]);
  const [listLoader, setListLoader] = useState(true);
  const [taxType, setTaxType] = useState({});
  const [modalState, setModalState] = useState(false);
  const [deleteAlertState, setDeleteAlertState] = useState(false);
  const [currencyList, setCurrencyList] = useState([]);
  const [currencyListLoader, setCurrencyListLoader] = useState(true);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [searchParams, setSearchParams] = useState();
  const debouncedSearchTerm = useDebounce(searchParams, 1000);
  const [emptyImage, setEmptyImage] = useState();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: taxType?.id ? taxType : TAX_TYPE_INITIAL_VALUE,
    validationSchema: TAX_TYPE_VALIDATION_SCHEMA,
    onSubmit: () => {
      if (taxType?.id) {
        updateTaxTypeEntry();
      } else {
        createTaxTypeEntry();
      }
    },
  });

  useEffect(() => {
    loadTaxTypeList();
    loadCurrencyList();
    setEmptyImage(getRandomNotFoundImage());
  }, []);

  useEffect(() => {
    if (debouncedSearchTerm != undefined) {
      loadTaxTypeList();
    }
  }, [debouncedSearchTerm]);

  const loadTaxTypeList = async () => {
    try {
      const response = await getTaxTypes(searchParams);
      setTaxTypeList(response.data.taxTypes || []);
      setListLoader(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const loadCurrencyList = async () => {
    try {
      const response = await getCurrencies();
      setCurrencyList(response.data.currencies || []);
      setCurrencyListLoader(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const createTaxTypeEntry = async () => {
    try {
      const response = await createTaxType({
        tax_type: {
          name: formik.values.name,
          rate: formik.values.rate,
          currencyId: formik.values.currencyId,
        },
      });
      setModalState(false);
      Toastr.info(response.data.notice);
      formik.resetForm();
      loadTaxTypeList();
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setButtonLoader(false);
    }
  };

  const updateTaxTypeEntry = async () => {
    try {
      const response = await updateTaxType(taxType?.id, {
        tax_type: {
          name: formik.values.name,
          rate: formik.values.rate,
          currencyId: formik.values.currencyId,
        },
      });
      setModalState(false);
      Toastr.info(response.data.notice);
      formik.resetForm();
      loadTaxTypeList();
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setButtonLoader(false);
    }
  };

  const destroyTaxTypeEntry = async () => {
    try {
      const response = await destroyTaxType(taxType?.id);
      setDeleteAlertState(false);
      Toastr.info(response.data.notice);
      loadTaxTypeList();
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setButtonLoader(false);
    }
  };

  const handleNewButtonClick = async () => {
    setButtonLoader(false);
    formik.resetForm();
    formik.setFieldValue("currencyId", null);
    setTaxType({});
    setModalState(true);
  };

  const handleSubmitButtonClick = async () => {
    if (Object.keys(formik.errors).length === 0) {
      setButtonLoader(true);
      formik.handleSubmit();
    }
  };

  if (listLoader || currencyListLoader) {
    return (
      <div className="flex items-center justify-center h-full">
        <Spinner />
      </div>
    );
  }

  return (
    <Container>
      <Header
        title="Default Tax Types"
        breadcrumbs={breadcrumbs}
        searchProps={{
          value: searchParams,
          onChange: e => setSearchParams(e.target.value),
          clear: () => setSearchParams(),
        }}
        actionBlock={
          <Button label="Add New" onClick={() => handleNewButtonClick()} />
        }
      />

      <div className="w-full" style={{ height: "calc(100vh - 128px)" }}>
        {!isEmpty(taxTypeList) ? (
          <List
            taxTypeList={taxTypeList}
            setTaxType={setTaxType}
            setModalState={setModalState}
            setDeleteAlertState={setDeleteAlertState}
          />
        ) : (
          <EmptyState
            image={emptyImage}
            title="No Default Tax Types Found"
            description="We couldn’t find any default tax types. Try creating one."
            primaryButtonProps={{
              label: "Add New",
              onClick: () => handleNewButtonClick(),
            }}
          />
        )}
      </div>

      <FormPane
        isOpen={modalState}
        onClose={() => setModalState(false)}
        formik={formik}
        onSubmit={handleSubmitButtonClick}
        title={taxType?.id ? "Edit Tax Type" : "Add Tax Type"}
        currencyList={currencyList}
        buttonLoader={buttonLoader}
      />

      <Alert
        isOpen={deleteAlertState}
        title="Delete Tax Type"
        message={
          <>
            Are you sure you want to delete the tax type{" "}
            <strong>{taxType?.name}</strong>?
          </>
        }
        onClose={() => setDeleteAlertState(false)}
        onSubmit={() => {
          setButtonLoader(true);
          destroyTaxTypeEntry();
        }}
        isSubmitting={buttonLoader}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, delete"
      />
    </Container>
  );
};

export default TaxTypes;

import React from "react";
import classNames from "classnames";
import { Table } from "../Shared";

const BorderedTable = ({
  id,
  title,
  data,
  setSelectedUsage = () => {},
  selectedUsage,
  bgColor,
  showNotes,
  notes,
  noNote = false,
}) => {
  return data ? (
    <div
      className="w-full text-base transition-all duration-300 ease-in-out regular-table"
      style={{ fontFamily: "Modern Gothic" }}
    >
      {data?.map(item => (
        <div
          key={item?.option?.title}
          className={classNames(`rounded_container w-full`, {
            "p-0": !bgColor,
            "py-5 lg:p-6 px-3": bgColor,
          })}
          style={{ backgroundColor: bgColor && bgColor }}
        >
          {item?.option?.hasTitle ? (
            <div className="flex items-start justify-start w-full mb-4 text-center lg:text-left">
              {item?.option?.hasCheckbox && (
                <div className="mt-1 mr-2 lg:m-0 lg:pr-4">
                  <label className="flex flex-row items-center justify-start space-x-3 cursor-pointer">
                    <input
                      type="checkbox"
                      name="checkbox"
                      className={`cursor-pointer rounded-full ${
                        item?.option?.id === selectedUsage
                          ? "bg-white border-2 border-black"
                          : "bg-white border-2 border-black"
                      }`}
                      checked={item?.option?.id === selectedUsage}
                      onChange={() => setSelectedUsage(item?.option?.id)}
                    />
                  </label>
                </div>
              )}
              {item?.option?.hasTitle && (
                <div
                  className={classNames("flex w-full", {
                    "flex-col items-start justify-start space-y-2 lg:space-y-0 lg:flex-row lg:items-center lg:justify-between":
                      item?.option?.price,
                    "flex-row items-center justify-between": !item?.option
                      ?.price,
                  })}
                >
                  <div className="pr-0 lg:pr-5">
                    <h2 className="text-xl font-medium text-left">
                      {title || item?.option?.title}
                      {item?.option?.description?.length > 0 && !noNote
                        ? ` - ${item?.option?.description}`
                        : ""}
                    </h2>
                  </div>
                  {item?.option?.price ? (
                    <h2 className="text-xl font-medium">
                      {item?.option?.price}
                    </h2>
                  ) : (
                    <div className="flex flex-row items-center self-center justify-center w-6 h-6 p-1 ml-2 text-lg font-bold text-center text-white whitespace-no-wrap bg-gray-900 rounded-full lg:ml-auto">
                      <span className="leading-none">{id || "1"}</span>
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            ""
          )}

          <Table
            data={item?.table}
            title={item?.option?.hasTitle}
            type="bordered"
            showNotes={showNotes}
            notes={notes}
          />
        </div>
      ))}
    </div>
  ) : (
    ""
  );
};

export default BorderedTable;

import React, { useState, useEffect } from "react";
import {
  Form,
  Customers,
  Goal,
  Location,
  UpArrow,
  DownArrow,
  RightArrow,
  Invoice,
  Suitcase,
} from "@bigbinary/neeto-icons";
import { Button, Typography, Spinner } from "@bigbinary/neetoui";
import classNames from "classnames";
import {
  getCurrentWeekStatistics,
  getActiveJobs,
  getApprovedJobs,
  getUnApprovedJobs,
  getJobEnquiries,
  getInvoices,
  getClosedActionTags,
} from "apis/dashboards";
import { showToastrError } from "common";
import ListModal from "./ListModal";

const Overview = ({ location }) => {
  const [statistics, setStatistics] = useState({});
  const [status, setStatus] = useState();
  const [dataLoader, setDataLoader] = useState(true);
  const [listDataLoader, setListDataLoader] = useState(false);
  const [showListModal, setShowListModal] = useState(false);
  const [jobList, setJobList] = useState();
  const [listType, setListType] = useState();
  const [invoiceList, setInvoiceList] = useState();
  const [noAgency, setNoAgency] = useState(true);
  const [activityTagList, setActivityTagList] = useState();

  useEffect(() => {
    getCurrentWeekStatisticsData();
  }, [location]);

  useEffect(() => {
    if (statistics) {
      setStatus([
        {
          id: 1,
          name: "Jobs Currently Active",
          stat: statistics?.active_jobs,
          type: "activeJobs",
          icon: Suitcase,
          arrowPresent: false,
        },
        {
          id: 2,
          name: "Estimates Waiting Approval",
          stat: statistics?.unapproved_jobs,
          type: "unapprovedJobs",
          icon: Form,
          arrowPresent: false,
        },
        {
          id: 3,
          name: "New Job Enquiries",
          stat: statistics?.enquired_jobs,
          type: "newJobs",
          icon: Customers,
          arrowPresent: true,
          change: statistics?.enquiry_change,
          changeType: increase_or_decreas(statistics?.enquiry_change),
        },
        {
          id: 4,
          name: "Total Invoice Reached",
          type: "invoices",
          stat: `${statistics?.invoice_amount_percentage}%`,
          icon: Invoice,
          arrowPresent: true,
          change: `${statistics?.invoice_change}%`,
          changeType: increase_or_decreas(statistics?.invoice_change),
        },
        {
          id: 5,
          name: "Target Approval Reached",
          type: "approvedJobs",
          stat: `${statistics?.approved_agency_fee_percentage}%`,
          icon: Goal,
          arrowPresent: true,
          change: `${statistics?.approval_change}%`,
          changeType: increase_or_decreas(statistics?.approval_change),
        },
        {
          id: 3,
          name: "Action Tags Closed",
          type: "actionTagClosed",
          number: `${statistics.tag_closure_number}`,
          stat: `${statistics?.tag_closure_percentage}%`,
          icon: Location,
          arrowPresent: true,
          change: statistics?.tag_closure_change,
          changeType: increase_or_decreas(statistics?.tag_closure_change),
        },
      ]);
    }
  }, [statistics]);

  const increase_or_decreas = value => {
    return value > 0 ? "increase" : "decrease";
  };

  const getCurrentWeekStatisticsData = async () => {
    setDataLoader(true);
    try {
      const response = await getCurrentWeekStatistics(location);
      setStatistics(response.data.statistics);
      setDataLoader(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const getActiveJobRecords = async () => {
    setListDataLoader(true);
    try {
      const response = await getActiveJobs(location);
      setListType("jobs");
      setJobList(response.data.activeJobs);
      setListDataLoader(false);
      setShowListModal(true);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const getApprovedJobRecords = async () => {
    setListDataLoader(true);
    try {
      const response = await getApprovedJobs(location);
      setListType("jobs");
      setJobList(response.data.approvedJobs);
      setListDataLoader(false);
      setShowListModal(true);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const getUnApprovedJobRecords = async () => {
    setListDataLoader(true);
    try {
      const response = await getUnApprovedJobs(location);
      setListType("jobs");
      setJobList(response.data.unapprovedJobs);
      setListDataLoader(false);
      setShowListModal(true);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const getInvoiceRecords = async () => {
    setListDataLoader(true);
    try {
      const response = await getInvoices(location);
      setListType("invoices");
      setInvoiceList(response.data.invoices);
      setListDataLoader(false);
      setShowListModal(true);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const getJobEnquiryRecords = async () => {
    setListDataLoader(true);
    try {
      const response = await getJobEnquiries(location);
      setJobList(response.data.enquiries);
      setListType("jobs");
      setListDataLoader(false);
      setShowListModal(true);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const getClosedActionTagsRecords = async () => {
    setListDataLoader(true);
    try {
      const response = await getClosedActionTags(location);
      setListType("action_tags");
      setActivityTagList(response.data.closedActionTags);
      setListDataLoader(false);
      setShowListModal(true);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const onButtonClick = type => {
    if (type === "invoices") {
      setNoAgency(true);
      getInvoiceRecords();
    } else if (type === "activeJobs") {
      setNoAgency(true);
      getActiveJobRecords();
    } else if (type === "unapprovedJobs") {
      setNoAgency(true);
      getUnApprovedJobRecords();
    } else if (type === "newJobs") {
      setNoAgency(true);
      getJobEnquiryRecords();
    } else if (type === "approvedJobs") {
      setNoAgency(false);
      getApprovedJobRecords();
    } else if (type === "actionTagClosed") {
      setNoAgency(true);
      getClosedActionTagsRecords();
    }
  };

  if (dataLoader) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex flex-col px-6 pt-6">
      <dl className="grid grid-cols-3 gap-4">
        {status?.map(item => (
          <div
            key={item.id}
            className="relative flex flex-col p-4 transition-all bg-white border rounded-lg neeto-ui-border-gray-300 hover:neeto-ui-border-primary-500 hover:shadow group"
          >
            <div className="flex flex-col">
              <Typography
                style="h5"
                weight="semibold"
                className="mb-2 text-gray-800"
              >
                {item.name}
              </Typography>
              <div className="flex items-baseline space-x-2">
                <Typography
                  style="h1"
                  weight="semibold"
                  className="mb-1 text-gray-800"
                >
                  {item.type === "actionTagClosed"
                    ? `${item.number}(${item.stat})`
                    : item.stat}
                </Typography>
                {item.arrowPresent && (
                  <p
                    className={classNames(
                      item.changeType === "increase"
                        ? "text-green-500"
                        : "text-red-500",
                      "flex items-baseline text-sm font-semibold"
                    )}
                  >
                    {item.changeType === "increase" ? (
                      <UpArrow
                        size={14}
                        strokeWidth={3}
                        className="self-center flex-shrink-0 text-green-500"
                        aria-hidden="true"
                      />
                    ) : (
                      <DownArrow
                        size={14}
                        strokeWidth={3}
                        className="self-center flex-shrink-0 text-red-500"
                        aria-hidden="true"
                      />
                    )}

                    {item.change}

                    <span className="ml-1 text-sm font-normal text-gray-600">
                      vs last week
                    </span>
                  </p>
                )}
              </div>
            </div>
            <div className="absolute top-0 right-0 hidden p-4 group-hover:block">
              <Button
                style="link"
                label="View list"
                icon={() => <RightArrow size={16} strokeWidth={2} />}
                onClick={() => onButtonClick(item.type)}
              />
            </div>
          </div>
        ))}
      </dl>

      <ListModal
        showListModal={showListModal}
        setShowListModal={setShowListModal}
        jobList={jobList}
        invoiceList={invoiceList}
        getApprovedJobs={getApprovedJobs}
        getInvoices={getInvoices}
        noAgency={noAgency}
        listDataLoader={listDataLoader}
        getCurrentWeekStatisticsData={getCurrentWeekStatisticsData}
        activityTagList={activityTagList}
        listType={listType}
      />
    </div>
  );
};

export default Overview;

import axios from "axios";

export const createLocation = (accountId, payload) => {
  return axios.post(
    `/api/v1/super_admins/accounts/${accountId}/locations`,
    payload
  );
};

export const getLocations = accountId => {
  return axios.get(`/api/v1/super_admins/accounts/${accountId}/locations`);
};

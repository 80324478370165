import React from "react";
import { Button, Typography } from "@bigbinary/neetoui";
import MessageSVG from "images/common/chat-message";

const EmptyMessage = ({ setNewMessage, formik }) => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <div style={{ height: 300, width: 450 }}>
        <img src={MessageSVG} alt="Message" className="w-auto" height={300} />
      </div>
      <Typography style="h4" weight="semibold" className="mb-5">
        Start the conversation with members of your job.
      </Typography>
      <div className="flex justify-center">
        <Button
          label="New message"
          onClick={() => {
            formik.resetForm();
            setNewMessage(true);
          }}
        />
      </div>
    </div>
  );
};

export default EmptyMessage;

import axios from "axios";
import { DEFAULT_PAGE_SIZE } from "common/helper";

export const getOrganisations = (searchParams, page) => {
  if (searchParams) {
    let searchQuery = `q[name_i_cont_any]=${searchParams}&q[id_eq]=${searchParams}&q[m]=or`;
    return axios.get(
      `/api/v1/contacts/organisations?${searchQuery}&page=${page}&per_page=${DEFAULT_PAGE_SIZE}`
    );
  }

  return axios.get(
    `/api/v1/contacts/organisations?page=${page}&per_page=${DEFAULT_PAGE_SIZE}`
  );
};

import React, { useEffect, useState } from "react";
import { components } from "react-select";
import { Down } from "@bigbinary/neeto-icons";
import { Select, Typography } from "@bigbinary/neetoui";
import { countries } from "countries-list";

const CountrySelect = ({
  label,
  onCountryChange,
  country,
  width,
  showCountryName = false,
  error = null,
  showCloseButton = false,
}) => {
  const [countryState, setCountryState] = useState({
    label: "",
    phone: "",
    emoji: "",
    value: "",
  });

  useEffect(() => {
    if (countries[country]) {
      setCountryState({
        label: countries[country].name,
        phone: countries[country].phone,
        emoji: countries[country].emoji,
        value: countries[country].name,
        labelWithEmoji: `${countries[country].emoji}${countries[country].name}`,
      });
    } else if (country === null) {
      setCountryState({
        label: "",
        phone: "",
        emoji: "",
        value: "",
      });
    }
  }, [country]);

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <Down size={16} />
      </components.DropdownIndicator>
    );
  };

  const CustomOption = ({ children, ...props }) => {
    return (
      <components.Option {...props}>
        {props.data.emoji} {children}
      </components.Option>
    );
  };

  const SingleValue = ({ ...props }) => {
    return (
      <components.SingleValue {...props}>
        <Typography style="body2" className="flex items-center space-x-1">
          <span className="text-base">{props.data.emoji}</span>
          {showCountryName && <span>{props.data.label}</span>}
        </Typography>
      </components.SingleValue>
    );
  };

  let CountryLabel = Object.keys(countries).map(item => {
    return {
      label: countries[item].name,
      phone: countries[item].phone,
      emoji: countries[item].emoji,
      value: countries[item].name,
      labelWithEmoji: `${countries[item].emoji}${countries[item].name}`,
    };
  });

  return (
    <Select
      label={label}
      name="country"
      className="text-sm"
      components={{
        Option: CustomOption,
        SingleValue: SingleValue,
        DropdownIndicator,
        IndicatorSeparator: () => null,
      }}
      onChange={value => {
        setCountryState(value);
        onCountryChange(value);
      }}
      isClearable={showCloseButton}
      value={countryState}
      options={CountryLabel}
      maxMenuHeight="300px"
      error={error}
      styles={{
        singleValue: base => ({
          ...base,
          margin: "0",
          overflow: "visible",
          display: "flex",
        }),
        container: base => ({
          ...base,
          minWidth: width && width,
          maxWidth: showCountryName ? "100%" : "80px",
        }),
        menu: base => ({
          ...base,
          minWidth: "200px",
        }),
      }}
    />
  );
};

export default CountrySelect;

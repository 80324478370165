import axios from "axios";

const getTimingsUrl = (revisionId, id) => {
  if (id) {
    return `/api/v1/job_module/estimate_revisions/${revisionId}/timings/${id}`;
  }

  return `/api/v1/job_module/estimate_revisions/${revisionId}/timings`;
};

export const getTimings = revisionId => {
  return axios.get(getTimingsUrl(revisionId));
};

export const destroyTiming = (revisionId, id) => {
  return axios.delete(getTimingsUrl(revisionId, id));
};

export const updateTimingPosition = (revisionId, id, type, difference) => {
  return axios.put(
    `${getTimingsUrl(
      revisionId,
      id
    )}/change_position?type=${type}&difference=${difference}`
  );
};

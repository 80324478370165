import React, { useState, useEffect } from "react";
import { Spinner, Dropdown } from "@bigbinary/neetoui";
import { getLocations } from "apis/settings/locations";
import { showToastrError } from "common";

const LocationDropdown = ({
  location,
  setLocation,
  setLocationName = () => {},
}) => {
  const [locationList, setLocationList] = useState([]);
  const [locationListLoad, setLocationListLoad] = useState(true);
  const { Menu, MenuItem } = Dropdown;

  useEffect(() => {
    loadLocationList();
  }, []);

  useEffect(() => {
    if (location) {
      setLocationName(locationList.find(loc => loc.id === location)?.shortName);
    }
  }, [locationList, location]);

  const loadLocationList = async () => {
    try {
      const response = await getLocations();
      setLocationList([
        ...response.data.locations,
        { name: "All", shortName: "All", tag: "all", id: "All" }
      ]);
      setLocationListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  if (locationListLoad) {
    return (
      <div className="flex items-center justify-center w-20 h-8">
        <Spinner />
      </div>
    );
  }

  return (
    <Dropdown
      label={locationList.find(loc => loc.id === location)?.shortName}
      buttonStyle="secondary"
      closeOnSelect
    >
      <Menu>
        {locationList.map(location => (
          <MenuItem.Button
            key={location.id}
            onClick={() => {
              setLocationName(location.shortName);
              setLocation(location.id);
            }}
          >
            {location.shortName}
          </MenuItem.Button>
        ))}
      </Menu>
    </Dropdown>
  );
};

export default LocationDropdown;

import React from "react";
import { Route, Switch } from "react-router";

import BookingFormMail from "./BookingForm";
import RciMail from "./Rci";
import WelcomeMail from "./Welcome";
import EstimateMail from "./Estimate";
import InvoiceMail from "./Invoice";
import LicenseMail from "./License";
import OverageMail from "./Overage";

const Mailer = () => {
  return (
    <div className="dashboard-email">
      <Switch>
        <Route
          exact
          path={"/mailer/jobs/:id/revisions/:revisionId/license"}
          component={LicenseMail}
        />
        <Route
          exact
          path={"/mailer/jobs/:id/revisions/:revisionId/booking_form/:artistId"}
          component={BookingFormMail}
        />
        <Route
          exact
          path={"/mailer/jobs/:id/rcis/:rciId/artists/:artistId"}
          component={RciMail}
        />
        <Route
          exact
          path={"/mailer/jobs/:id/welcome"}
          component={WelcomeMail}
        />
        <Route
          exact
          path={"/mailer/jobs/:id/revisions/:revisionId"}
          component={EstimateMail}
        />
        <Route
          exact
          path={"/mailer/jobs/:id/revisions/:revisionId/overage_mail"}
          component={OverageMail}
        />
        <Route
          exact
          path={"/mailer/jobs/:id/invoices/:invoiceId"}
          component={() => {
            return <InvoiceMail />;
          }}
        />
      </Switch>
    </div>
  );
};

export default Mailer;

import React, { useState, useEffect } from "react";
import { Spinner, Button, Toastr } from "@bigbinary/neetoui";
import { Container, Header } from "@bigbinary/neetoui/layouts";
import { useParams, useHistory } from "react-router-dom";
import { useUserState } from "contexts/user";
import { showToastrError } from "common";
import { sendWelcomeEmail } from "apis/jobs/jobs";
import { getEmailBackgroundDetails } from "apis/mailers/email_backgrounds";
import { getCompanyDetails } from "apis/mailers/company_details";
import { getSettings } from "apis/mailers/template_settings";
import { useFormik } from "formik";
import { getJob } from "apis/mailers/welcome";
import { INITIAL_VALUE, VALIDATION_SCHEMA, getEmails } from "../constants";
import { EMAIL_DISABLED_MESSAGE } from "common/constants";

import MailEditor from "./Editor";
import MailViewer from "./Viewer";

const WelcomeMail = () => {
  const { id } = useParams();
  const history = useHistory();
  const { user } = useUserState();
  const [companyDetails, setCompanyDetails] = useState([]);
  const [companyDetailsLoad, setCompanyDetailsLoad] = useState(true);
  const [jobDetail, setJobDetail] = useState();
  const [emailBackground, setEmailBackground] = useState();
  const [emailBackgroundLoad, setEmailBackgroundLoad] = useState(true);
  const [jobDetailLoad, setjobDetailLoad] = useState(true);
  const [defaultTemplateSetting, setDefaultTemplateSetting] = useState();
  const [defaultTemplateSettingLoad, setDefaultTemplateSettingLoad] = useState(
    true
  );
  const [mailLoader, setMailLoader] = useState(false);

  const formik = useFormik({
    initialValues: INITIAL_VALUE,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: values => {
      sendWelcomeEmailToClient(values);
    },
  });

  useEffect(() => {
    if (id) {
      setJobDetailResponse();
      getCompanyDetailsResponse();
      getTemplateSettings();
      getEmailBackgroundDetailsResponse();
    }
  }, [id]);

  useEffect(() => {
    if (jobDetail) {
      formik.setFieldValue("firstName", jobDetail.contactFullName);
      formik.setFieldValue(
        "from",
        getEmails(jobDetail?.emailFrom, jobDetail?.allRecipients)[0]
      );
      formik.setFieldValue(
        "to",
        getEmails(jobDetail?.emailTo, jobDetail?.allRecipients)
      );
      formik.setFieldValue(
        "cc",
        getEmails(jobDetail?.emailCc, jobDetail?.allRecipients)
      );
      formik.setFieldValue(
        "bcc",
        getEmails(jobDetail?.emailBcc, jobDetail?.allRecipients)
      );
      formik.setFieldValue("subject", mailSubject());
      formik.setFieldValue("body", mailBody());
      formik.setFieldValue("header", mailHead());
      formik.setFieldValue("footer", mailFoot());
      formik.setFieldValue("videoUrl", emailBackground?.welcomeEmailVideoUrl);
    }
  }, [jobDetail, emailBackground]);

  const setResourceForSubject = name => {
    switch (name) {
      case "[paperwork_title]":
        return "Welcome";
      case "[job_number]":
        return jobDetail?.serialNumber;
      case "[job_name]":
        return jobDetail?.name;
      case "[invoice_number]":
        return "NA";
      case "[organisation_name]":
        return jobDetail?.organisationName;
    }
  };

  const setResourceForBody = name => {
    switch (name) {
      case "[contact_first_name]":
        return jobDetail?.contactFirstName;
      case "[staff_phone]":
        return jobDetail?.staffPhone;
      case "[staff_first_name]":
        return jobDetail?.staffFirstName;
      case "[account_name]":
        return jobDetail?.accountName;
    }
  };

  // TODO: Move to a common helper or utils
  const mailSubject = () => {
    let subject = "";
    const subjectComponents = emailBackground?.emailSubject?.split("<>");

    subjectComponents?.map(component => {
      if (component.includes("[")) {
        subject += setResourceForSubject(component);
      } else {
        subject += component;
      }
    });

    return subject;
  };

  const mailBody = () => {
    let emailDetails = emailBackground?.welcomeEmailBodyDetails;
    let body = "";
    const bodyComponents = emailDetails?.split("<>");

    bodyComponents?.map(component => {
      if (component.includes("[")) {
        body += setResourceForBody(component);
      } else {
        body += component;
      }
    });

    return body;
  };

  const mailHead = () => {
    let emailDetails = emailBackground?.welcomeEmailHeaderDetails;
    let body = "";
    const bodyComponents = emailDetails?.split("<>");

    bodyComponents?.map(component => {
      if (component.includes("[")) {
        body += setResourceForBody(component);
      } else {
        body += component;
      }
    });

    return body;
  };

  const mailFoot = () => {
    let emailDetails = emailBackground?.welcomeEmailFooterDetails;
    let body = "";
    const bodyComponents = emailDetails?.split("<>");

    bodyComponents?.map(component => {
      if (component.includes("[")) {
        body += setResourceForBody(component);
      } else {
        body += component;
      }
    });

    return body;
  };

  const getCompanyDetailsResponse = async () => {
    try {
      const response = await getCompanyDetails();
      setCompanyDetails(response.data.companyDetails);
      setCompanyDetailsLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const getEmailBackgroundDetailsResponse = async () => {
    try {
      const response = await getEmailBackgroundDetails("welcome");
      setEmailBackground(
        response.data.emailBackgrounds?.find(
          background => background.pageName === "Welcome Email"
        )
      );
      setEmailBackgroundLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const getTemplateSettings = async () => {
    try {
      const response = await getSettings();
      setDefaultTemplateSetting(response.data.templateSettings[0]);
      setDefaultTemplateSettingLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const setJobDetailResponse = async () => {
    try {
      const response = await getJob(id);
      let job = response.data.job;
      setJobDetail(job);
      setjobDetailLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const sendWelcomeEmailToClient = async () => {
    setMailLoader(true);
    try {
      const response = await sendWelcomeEmail(jobDetail.id, {
        job: { ...formik.values },
      });
      setMailLoader(false);

      if (user.demo_account) {
        Toastr.error(EMAIL_DISABLED_MESSAGE);
      } else {
        Toastr.info(response.data.notice);
      }

      history.push(`/jobs/${id}/overview`);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  if (
    jobDetailLoad ||
    mailLoader ||
    companyDetailsLoad ||
    defaultTemplateSettingLoad ||
    emailBackgroundLoad
  ) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <Container isHeaderFixed>
      <Header
        title={"Welcome Email"}
        breadcrumbs={[
          { text: "Job", link: `/jobs` },
          {
            text: jobDetail.name,
            link: `/jobs/${jobDetail.id}/overview`,
          },
        ]}
        actionBlock={
          <Button label="Send Email" onClick={() => formik.handleSubmit()} />
        }
      />
      <div
        className="flex overflow-hidden"
        style={{ height: "calc(100vh - 94px)" }}
      >
        <MailEditor formik={formik} jobDetail={jobDetail} />
        <MailViewer
          formik={formik}
          jobDetail={jobDetail}
          companyDetails={companyDetails}
          defaultTemplateSetting={defaultTemplateSetting}
          emailBackground={emailBackground}
        />
      </div>
    </Container>
  );
};

export default WelcomeMail;

import React, { useState, useEffect } from "react";
import { Spinner, Button, Toastr } from "@bigbinary/neetoui";
import { Header, Container } from "@bigbinary/neetoui/layouts";
import { useUserState } from "contexts/user";
import { useParams, useHistory } from "react-router-dom";
import { showToastrError } from "common";
import { getEmailBackgroundDetails } from "apis/mailers/email_backgrounds";
import { getCompanyDetails } from "apis/mailers/company_details";
import { getEstimateRevision } from "apis/mailers/estimate_revisions";
import { sendOverage } from "apis/jobs/estimate_revisions";
import { useFormik } from "formik";
import { getJob } from "apis/mailers/overages";
import { getSettings } from "apis/mailers/template_settings";
import { INITIAL_VALUE, VALIDATION_SCHEMA, getEmails } from "../constants";
import { EMAIL_DISABLED_MESSAGE } from "common/constants";
import MailEditor from "./Editor";
import MailViewer from "./Viewer";

const OverageMail = () => {
  const { id, revisionId } = useParams();
  const { user, env_variables } = useUserState();
  const history = useHistory();
  const [companyDetails, setCompanyDetails] = useState([]);
  const [emailBackground, setEmailBackground] = useState();
  const [emailBackgroundLoad, setEmailBackgroundLoad] = useState(true);
  const [companyDetailsLoad, setCompanyDetailsLoad] = useState(true);
  const [jobDetail, setJobDetail] = useState();
  const [revision, setRevision] = useState();
  const [jobDetailLoad, setjobDetailLoad] = useState(true);
  const [revisionLoad, setRevisionLoad] = useState(true);
  const [mailLoader, setMailLoader] = useState(false);
  const [defaultTemplateSetting, setDefaultTemplateSetting] = useState();
  const [defaultTemplateSettingLoad, setDefaultTemplateSettingLoad] = useState(
    true
  );

  const formik = useFormik({
    initialValues: INITIAL_VALUE,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: values => {
      sendOverageToClient(values);
    },
  });

  useEffect(() => {
    getEmailBackgroundDetailsResponse();
    getCompanyDetailsResponse();
    getTemplateSettings();
  }, []);

  useEffect(() => {
    if (id) {
      setJobDetailResponse();
    }
  }, [id]);

  useEffect(() => {
    if (revisionId) {
      loadEstimateRevision();
    }
  }, [revisionId]);

  useEffect(() => {
    if (jobDetail && revision) {
      formik.setFieldValue("firstName", jobDetail.contactFullName);
      formik.setFieldValue(
        "from",
        getEmails(jobDetail?.emailFrom, jobDetail?.allRecipients)[0]
      );
      formik.setFieldValue(
        "to",
        getEmails(jobDetail?.emailTo, jobDetail?.allRecipients)
      );
      formik.setFieldValue(
        "cc",
        getEmails(jobDetail?.emailCc, jobDetail?.allRecipients)
      );
      formik.setFieldValue(
        "bcc",
        getEmails(jobDetail?.emailBcc, jobDetail?.allRecipients)
      );
      formik.setFieldValue("subject", mailSubject());
      formik.setFieldValue("body", mailBody());
    }
  }, [jobDetail && revision, emailBackground]);

  const setJobDetailResponse = async () => {
    try {
      const response = await getJob(id);
      let job = response.data.job;
      setJobDetail(job);
      setjobDetailLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const getTemplateSettings = async () => {
    try {
      const response = await getSettings();
      setDefaultTemplateSetting(response.data.templateSettings[0]);
      setDefaultTemplateSettingLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadEstimateRevision = async () => {
    try {
      const revisionResponse = await getEstimateRevision(revisionId);
      setRevision(revisionResponse.data.estimateRevision);
      setRevisionLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const getEmailBackgroundDetailsResponse = async () => {
    try {
      const response = await getEmailBackgroundDetails("overage");
      setEmailBackground(
        response.data.emailBackgrounds?.find(
          background => background.pageName === "Extra"
        )
      );
      setEmailBackgroundLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const getCompanyDetailsResponse = async () => {
    try {
      const response = await getCompanyDetails();
      setCompanyDetails(response.data.companyDetails);
      setCompanyDetailsLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const sendOverageToClient = async () => {
    setMailLoader(true);
    try {
      const response = await sendOverage(jobDetail.id, revisionId, {
        estimate_revision: { ...formik.values },
      });
      setMailLoader(false);

      if (user.demo_account) {
        Toastr.error(EMAIL_DISABLED_MESSAGE);
      } else {
        Toastr.info(response.data.notice);
      }

      history.push(`/jobs/${id}/revisions/${revisionId}/extras`);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const setResourceForSubject = name => {
    switch (name) {
      case "[paperwork_title]":
        return "Overage";
      case "[job_number]":
        return jobDetail?.serialNumber;
      case "[job_name]":
        return jobDetail?.name;
      case "[serial_number]":
        return revision?.estimateSerialNumber;
      case "[invoice_number]":
        return "NA";
      case "[version_number]":
        return String(revision?.serial).padStart(2, "0");
      case "[organisation_name]":
        return jobDetail?.organisationName;
    }
  };

  const setResourceForBody = name => {
    switch (name) {
      case "[contact_first_name]":
        return jobDetail?.contactFirstName;
      case "[staff_phone]":
        return jobDetail?.staffPhone;
      case "[staff_first_name]":
        return jobDetail?.staffFirstName;
      case "[account_name]":
        return jobDetail?.accountName;
    }
  };

  const mailSubject = () => {
    let subject = "";
    const subjectComponents = emailBackground?.emailSubject?.split("<>");

    subjectComponents?.map(component => {
      if (component.includes("[")) {
        subject += setResourceForSubject(component);
      } else {
        subject += component;
      }
    });

    return subject;
  };

  const mailBody = () => {
    let emailDetails = emailBackground?.overageEmailDetails;
    let body = "";
    const bodyComponents = emailDetails?.split("<>");

    bodyComponents?.map(component => {
      if (component.includes("[")) {
        body += setResourceForBody(component);
      } else {
        body += component;
      }
    });

    return body;
  };

  if (
    jobDetailLoad ||
    revisionLoad ||
    mailLoader ||
    emailBackgroundLoad ||
    companyDetailsLoad ||
    defaultTemplateSettingLoad
  ) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <Container isHeaderFixed>
      <Header
        title="Estimate Overage"
        breadcrumbs={[
          { text: jobDetail.name, link: `/jobs/${jobDetail.id}/overview` },
          {
            text: revision.estimateSerialNumber,
            link: `/jobs/${jobDetail.id}/revisions/${revision.id}/extras`,
          },
        ]}
        actionBlock={
          <Button label="Send Email" onClick={() => formik.handleSubmit()} />
        }
      />
      <div
        className="flex overflow-hidden w-full"
        style={{ height: "calc(100vh - 94px)" }}
      >
        <MailEditor
          formik={formik}
          host={`${window.location.protocol}//${user.account_subdomain}.${env_variables.root_domain}`}
          token={revision.slug}
          jobDetail={jobDetail}
        />
        <MailViewer
          formik={formik}
          host={`${window.location.protocol}//${user.account_subdomain}.${env_variables.root_domain}`}
          token={revision.slug}
          jobDetail={jobDetail}
          companyDetails={companyDetails}
          revision={revision}
          defaultTemplateSetting={defaultTemplateSetting}
          emailBackground={emailBackground}
        />
      </div>
    </Container>
  );
};

export default OverageMail;

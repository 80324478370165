import axios from "axios";

const getPostProductionNotesUrl = (jobId, id) => {
  if (id) {
    return `/api/v1/post_productions/jobs/${jobId}/post_production_notes/${id}`;
  }

  return `/api/v1/post_productions/jobs/${jobId}/post_production_notes`;
};

export const getPostProductionNotes = jobId => {
  return axios.get(getPostProductionNotesUrl(jobId));
};

export const createPostProductionNote = (jobId, payload) => {
  return axios.post(getPostProductionNotesUrl(jobId), payload);
};

export const updatePostProductionNote = (jobId, id, payload) => {
  return axios.put(getPostProductionNotesUrl(jobId, id), payload);
};

export const destroyPostProductionNote = (jobId, id) => {
  return axios.delete(getPostProductionNotesUrl(jobId, id));
};

import React, { useState } from "react";
import { Delete } from "@bigbinary/neeto-icons";
import { Typography, Button, Alert } from "@bigbinary/neetoui";
import { destroyInvoice } from "apis/jobs/invoices";
import { useUserState } from "contexts/user";
import classNames from "classnames";

const List = ({
  jobId,
  invoiceList,
  loadInvoiceListResponse,
  invoiceId,
  setInvoiceId,
}) => {
  const { user } = useUserState();
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [deletingInvoiceId, setDeletingInvoiceId] = useState("");
  const [btnLoad, setBtnLoad] = useState(false);

  const destroyInvoiceEntry = async () => {
    try {
      setBtnLoad(true);
      await destroyInvoice(jobId, deletingInvoiceId);
      setDeleteAlertOpen(false);
      loadInvoiceListResponse();
    } catch (error) {
      if (error.response.data.id) {
        setDeleteAlertOpen(false);
        loadInvoiceListResponse();
      }
    } finally {
      setBtnLoad(false);
    }
  };

  return (
    <div className="w-full h-full">
      <div className="flex flex-col space-y-2">
        <Typography style="h4" weight="semibold">
          Invoice List
        </Typography>

        <div className="flex flex-col space-y-3">
          {invoiceList.map(invoice => {
            return (
              <div
                className={classNames(
                  "relative p-4 bg-white border neeto-ui-shadow-xs rounded-md cursor-pointer hover:neeto-ui-border-gray-500 focus:neeto-ui-border-gray-500",
                  {
                    "neeto-ui-border-primary-600": invoice.id === invoiceId,
                    "neeto-ui-border-gray-200": invoice.id !== invoiceId,
                  }
                )}
                key={invoice.id}
                onClick={() => {
                  setInvoiceId(invoice.id);
                }}
              >
                <div className="flex items-center justify-between">
                  <Typography style="h5" weight="semibold">
                    {`${invoice.serialNumber}(${invoice.invoiceType?.substr(
                      0,
                      4
                    )}...)`}
                  </Typography>

                  <div className="flex space-x-2">
                    <Button
                      style="link"
                      label="Details"
                      to={`/invoices/${invoice.id}`}
                      onClick={e => {
                        e.stopPropagation();
                      }}
                    />

                    {(user.access === "admin" ||
                      user.access === "super_admin") && (
                      <Button
                        style="danger-text"
                        icon={Delete}
                        onClick={() => {
                          setDeleteAlertOpen(true);
                          setDeletingInvoiceId(invoice.id);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Alert
        isOpen={deleteAlertOpen}
        title="Delete Invoice"
        onClose={() => setDeleteAlertOpen(false)}
        message="Are you sure you want to delete this invoice?"
        onSubmit={() => destroyInvoiceEntry()}
        isSubmitting={btnLoad}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, delete"
      />
    </div>
  );
};

export default List;

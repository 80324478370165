import React, { useState, useEffect } from "react";
import { getCurrentArtists } from "apis/artists/current_artists";
import { Search } from "@bigbinary/neeto-icons";
import {
  Spinner,
  Input,
  Typography,
  Button,
  Table,
  Modal,
  Toastr
} from "@bigbinary/neetoui";
import { SubHeader } from "@bigbinary/neetoui/layouts";
import { CopyToClipboard } from "react-copy-to-clipboard";
import * as dayjs from "dayjs";
import { showToastrError } from "common";
import useDebounce from "common/debounce";
import { DEFAULT_PAGE_SIZE, artistModuleName } from "common/helper";
import Pluralize from "pluralize";

const CurrentArtistListModal = ({ setCurrentArtistModalState }) => {
  const [currentArtistList, setCurrentArtistList] = useState([]);
  const [currentArtistListLoading, setCurrentArtistListLoading] = useState(
    true
  );
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [searchParams, setSearchParams] = useState();
  const debouncedSearchTerm = useDebounce(searchParams, 500);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sortProps, setSortProps] = useState();
  const [copieadAllEmail, setCopiedAllEmail] = useState(false);
  const [initial, setInitial] = useState(true);
  const [artistEmails, setArtistEmails] = useState()

  useEffect(() => {
    loadCurrentArtistResponse();
  }, []);

  useEffect(() => {
    if (sortProps?.column && !initial) {
      loadCurrentArtistResponse(searchParams);
    }
  }, [sortProps]);

  useEffect(() => {
    if (debouncedSearchTerm != undefined) {
      loadCurrentArtistResponse();
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (!initial) {
      loadCurrentArtistResponse();
    }
  }, [page])

  useEffect(() => {
    if (copieadAllEmail) {
      Toastr.info("Copied to Clipboard!")
      setCopiedAllEmail(false);
    }
  }, [copieadAllEmail]);

  const loadCurrentArtistResponse = async () => {
    try {
      const response = await getCurrentArtists(
        searchParams,
        sortProps,
        page || 1,
        DEFAULT_PAGE_SIZE
      );
      setTotalRecords(response.data.totalRecords);
      setCurrentArtistList(response.data.artists);
      setArtistEmails(response.data.artistEmails)
      setCurrentArtistListLoading(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const handleTableChange = (_, __, sorter) => {
    setSortProps(sorter);
  };

  const COLUMN_DATA = [
    {
      title: "Serial",
      dataIndex: "serialNumber",
      key: "serialNumber",
      sorter: true,
      field: "serial",
      width: 120,
      render: (_, rowData) => {
        return (
          <Button
            style="link"
            to={`/${artistModuleName().toLowerCase()}/${rowData.id}`}
            label={rowData.serialNumber}
            onClick={() => setCurrentArtistModalState(false)}
          />
        );
      },
    },
    {
      title: "Name",
      dataIndex: "fullName",
      key: "fullName",
      width: 220,
      sorter: true,
      field: "full_name",
      render: (_, rowData) => {
        return (
          <Button
            style="link"
            to={`/${artistModuleName().toLowerCase()}/${rowData.id}`}
            label={rowData.fullName}
            onClick={() => setCurrentArtistModalState(false)}
          />
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: true,
      field: "email",
      width: 200,
      render: (_, rowData) => <>{rowData.email ? rowData.email : "N/A"}</>,
    },
    {
      title: "Assigned Producer",
      dataIndex: "staffFullName",
      key: "staffFullName",
      sorter: true,
      field: "user_full_name",
      render: (_, rowData) => {
        return (
          <Button
            style="link"
            to={`/staff/${rowData.staffId}`}
            label={rowData.staffFullName}
            target="_blank"
          />
        );
      },
    },
    {
      title: "Agency Group",
      dataIndex: "agencyGroupNames",
      key: "agencyGroupNames",
      width: 150,
      sorter: true,
      field: "agency_groups_name",
      render: (_, rowData) => (
        <>{rowData.agencyGroupNames ? rowData.agencyGroupNames : "N/A"}</>
      ),
    },
    {
      title: "Catch Up Notes",
      dataIndex: "catchupNotes",
      key: "catchupNotes",
      width: 200,
      render: (_, rowData) => {
        return <RenderNotes notes={rowData.catchupNotes} />;
      },
    },
    {
      title: "Contacted Since",
      dataIndex: "catchupUpAt",
      sorter: true,
      field: "days_since_contact",
      key: "catchupUpAt",
      width: 150,
      render: (_, rowData) => (
        <>
          {rowData.catchupUpAt
            ? `${dayjs().diff(dayjs(rowData.catchupUpAt), "day")} ${Pluralize('day', dayjs().diff(dayjs(rowData.catchupUpAt), "day"))}`
            : "-"}
        </>
      ),
    },
  ];

  const ShowNote = ({ notes }) => {
    const [showNote, setShowNote] = useState(false);

    return (
      <div>
        {notes?.length > 0 ? (
          <div className="flex flex-col space-y-1">
            {showNote ? (
              <Typography style="body2" className="text-gray-800 w-96" dangerouslySetInnerHTML={{
                __html: notes?.split("\n").join("<br>"),
              }}>
              </Typography>
            ) : (
              <Typography style="body2" className="text-gray-800 truncate w-80" dangerouslySetInnerHTML={{
                __html: notes?.length < 50 ? notes.split("\n").join("<br>") : notes.substring(0, 120) + "...",
              }}>
              </Typography>
            )}
            {notes?.length > 50 && (
              <Button
                style="link"
                onClick={() => setShowNote(!showNote)}
                label={showNote ? "Less" : "More"}
              />
            )}
          </div>
        ) : (
          "No notes"
        )}
      </div>
    );
  };

  const RenderNotes = ({ notes }) => {
    const [showNotes, setShowNotes] = useState(false);

    const COLUMN_DATA = [
      {
        title: "Note",
        dataIndex: "note",
        key: "note",
        width: "50%",
        render: note => <ShowNote notes={note} />,
      },
      {
        title: "Created By",
        dataIndex: "createdBy",
        key: "createdBy",
        width: "20%",
        render: createdBy => <>{createdBy}</>,
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        key: "createdAt",
        width: "20%",
        render: createdAt => dayjs(createdAt).format("DD/MM/YYYY"),
      },
    ];

    return (
      <>
        {notes?.length > 0 ? (
          <>
            <Button
              style="link"
              onClick={() => setShowNotes(true)}
              label="Show notes"
            />
            <Modal
              size="large"
              className="w-3/4 mx-auto"
              style={{ height: "65vh" }}
              isOpen={showNotes}
              onClose={() => setShowNotes(false)}
            >
              <Modal.Header>
                <Typography style="h2" weight="semibold">
                  Notes
                </Typography>
              </Modal.Header>
              <Modal.Body>
                <div
                  className="flex flex-col overflow-hidden"
                  style={{ height: "calc(65vh -  100px)" }}
                >
                  <Table fixedHeight columnData={COLUMN_DATA} rowData={notes} />
                </div>
              </Modal.Body>
            </Modal>
          </>
        ) : (
          "No notes"
        )}
      </>
    );
  };

  if (currentArtistListLoading) {
    return (
      <div
        className="flex items-center justify-center w-full"
        style={{ height: "calc(100vh - 40px)" }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <SubHeader
        leftActionBlock={
          <div className="flex items-center">
            <Typography style="h4" component="h4" weight="semibold">
              {totalRecords > 1
                ? `${totalRecords} ${artistModuleName()}`
                : `${totalRecords} ${artistModuleName()}`}
            </Typography>
            <CopyToClipboard
              text={artistEmails}
              onCopy={() => setCopiedAllEmail(true)}
            >
              <Button
                style="secondary"
                label="Copy Emails"
                className="ml-2"
                tooltipProps={{
                  content: "Click to copy email",
                  position: "top",
                }}
              />
            </CopyToClipboard>
          </div>
        }
        rightActionBlock={
          <div className="flex justify-end space-x-3">
            <Input
              size="small"
              type="search"
              placeholder="Search"
              className="neeto-ui-header__search-input"
              prefix={<Search size={16} />}
              value={searchParams}
              onChange={e => setSearchParams(e.target.value)}
              clear={() => setSearchParams("")}
            />
          </div>
        }
      />

      <div
        style={{
          height: "calc(100vh - 195px)",
        }}
      >
        <Table
          fixedHeight
          loading={currentArtistListLoading}
          columnData={COLUMN_DATA}
          rowData={currentArtistList}
          totalCount={totalRecords}
          currentPageNumber={page}
          defaultPageSize={pageSize}
          handlePageChange={(page, pageSize) => {
            setInitial(false);
            setPage(page);
            setPageSize(pageSize);
          }}
          onChange={(pagination, filters, sorter) => {
            setInitial(false);
            handleTableChange(pagination, filters, sorter);
          }}
          paginationProps={{
            showSizeChanger: false,
          }}
        />
      </div>
    </>
  );
};

export default CurrentArtistListModal;

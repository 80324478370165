import React from "react";
import { Input, Callout, Select, Switch, Typography } from "@bigbinary/neetoui";
import { INVOICE_STATUS_LIST } from "./constants";

const XeroDetail = ({ formik }) => {
  return (
    <div className="flex flex-col w-full pb-8 mt-6 space-y-6">
      <Callout style="info">
        We need these details for pushing invoices to xero!
      </Callout>
      <Input
        label="Organisation Name"
        name="xeroTenantName"
        onChange={formik.handleChange}
        value={formik.values.xeroTenantName}
        error={
          Boolean(
            formik.touched.xeroTenantName && formik.errors.xeroTenantName
          ) && formik.errors.xeroTenantName
        }
        {...formik.getFieldProps("xeroTenantName")}
      />

      <div className="grid grid-cols-2 gap-4">
        <Input
          label="Invoice Type"
          helpText="Xero invoice type can be like 'ACCREC'."
          name="xeroInvoiceType"
          onChange={formik.handleChange}
          value={formik.values.xeroInvoiceType}
          error={
            Boolean(
              formik.touched.xeroInvoiceType && formik.errors.xeroInvoiceType
            ) && formik.errors.xeroInvoiceType
          }
          {...formik.getFieldProps("xeroInvoiceType")}
        />

        <Input
          label="RCI Type"
          helpText="Xero RCI type can be like 'ACCPAY'."
          name="xeroRciType"
          onChange={formik.handleChange}
          value={formik.values.xeroRciType}
          error={
            Boolean(formik.touched.xeroRciType && formik.errors.xeroRciType) &&
            formik.errors.xeroRciType
          }
          {...formik.getFieldProps("xeroRciType")}
        />
      </div>

      <div className="grid grid-cols-2 gap-4">
        <Select
          isClearable
          label="Default Invoice Status"
          options={INVOICE_STATUS_LIST}
          value={INVOICE_STATUS_LIST.find(
            status => status.value === formik.values.xeroInvoiceDefaultStatus
          )}
          onChange={option => {
            if (option) {
              formik.setFieldValue("xeroInvoiceDefaultStatus", option.value);
            } else {
              formik.setFieldValue("xeroInvoiceDefaultStatus", null);
            }
          }}
        />

        <Select
          isClearable
          label="Default RCI Status"
          options={INVOICE_STATUS_LIST}
          value={INVOICE_STATUS_LIST.find(
            status => status.value === formik.values.xeroRciDefaultStatus
          )}
          onChange={option => {
            if (option) {
              formik.setFieldValue("xeroRciDefaultStatus", option.value);
            } else {
              formik.setFieldValue("xeroRciDefaultStatus", null);
            }
          }}
        />
      </div>

      <div className="grid grid-cols-2 gap-4">
        <Input
          label="Default Invoice Account Code"
          name="defaultXeroInvoiceAccountCode"
          onChange={formik.handleChange}
          value={formik.values.defaultXeroInvoiceAccountCode}
          error={
            Boolean(
              formik.touched.defaultXeroInvoiceAccountCode &&
                formik.errors.defaultXeroInvoiceAccountCode
            ) && formik.errors.defaultXeroInvoiceAccountCode
          }
          {...formik.getFieldProps("defaultXeroInvoiceAccountCode")}
        />

        <Input
          label="Default RCI Account Code"
          name="defaultXeroRciAccountCode"
          onChange={formik.handleChange}
          value={formik.values.defaultXeroRciAccountCode}
          error={
            Boolean(
              formik.touched.defaultXeroRciAccountCode &&
                formik.errors.defaultXeroRciAccountCode
            ) && formik.errors.defaultXeroRciAccountCode
          }
          {...formik.getFieldProps("defaultXeroRciAccountCode")}
        />

        <Input
          label="Default Invoice Commencement Fee Account Code"
          name="defaultXeroAsfAccountCode"
          onChange={formik.handleChange}
          value={formik.values.defaultXeroInvoiceCommencementFeeAccountCode}
          error={
            Boolean(
              formik.touched.defaultXeroInvoiceCommencementFeeAccountCode &&
                formik.errors.defaultXeroInvoiceCommencementFeeAccountCode
            ) && formik.errors.defaultXeroInvoiceCommencementFeeAccountCode
          }
          {...formik.getFieldProps(
            "defaultXeroInvoiceCommencementFeeAccountCode"
          )}
        />

        <Input
          label="Default ASF Account Code"
          name="defaultXeroAsfAccountCode"
          onChange={formik.handleChange}
          value={formik.values.defaultXeroAsfAccountCode}
          error={
            Boolean(
              formik.touched.defaultXeroAsfAccountCode &&
                formik.errors.defaultXeroAsfAccountCode
            ) && formik.errors.defaultXeroAsfAccountCode
          }
          {...formik.getFieldProps("defaultXeroAsfAccountCode")}
        />

        <Input
          label="Artist Rate Account Code"
          name="artistRateAccountCode"
          onChange={formik.handleChange}
          value={formik.values.artistRateAccountCode}
          error={
            Boolean(
              formik.touched.artistRateAccountCode &&
                formik.errors.artistRateAccountCode
            ) && formik.errors.artistRateAccountCode
          }
          {...formik.getFieldProps("artistRateAccountCode")}
        />

        <Input
          label="Artist Expense Account Code"
          name="artistExpenseAccountCode"
          onChange={formik.handleChange}
          value={formik.values.artistExpenseAccountCode}
          error={
            Boolean(
              formik.touched.artistExpenseAccountCode &&
                formik.errors.artistExpenseAccountCode
            ) && formik.errors.artistExpenseAccountCode
          }
          {...formik.getFieldProps("artistExpenseAccountCode")}
        />
      </div>

      <div className="flex justify-between">
        <Typography style="body2">Enable Job Number Tracking</Typography>
        <Switch
          checked={formik.values.enableJobNumberTracking}
          onChange={() =>
            formik.setFieldValue(
              "enableJobNumberTracking",
              !formik.values.enableJobNumberTracking
            )
          }
        />
      </div>
    </div>
  );
};

export default XeroDetail;

import React, { useEffect, useState } from "react";
import {
  Label,
  Button,
  Input,
  Select,
  Spinner,
  Typography,
} from "@bigbinary/neetoui";
import * as R from "ramda";
import { numberWithCommas, dropDownListGenerator } from "common/helper";
import { showToastrError } from "common";
import { getTaxTypes } from "apis/settings/tax_types";
import {
  approvedAmount,
  approvedAmountWithExchangeRateAndPercentageWithoutadditionalCost,
  approvedAmountWithExchangeRateAndPercentage,
  approvedAmountWithPercentageAlreadyPaid,
  approvedAmountWithExchangeRateWithoutAdditionalFees,
} from "./utils";

const Calculations = ({
  estimateRevisionItems,
  jobDetail,
  rciDetail,
  formik,
  rciItems,
  setRciItems,
  taxOnExchange,
  exchangeTaxRate,
  exchangeTaxType,
  commencementRci,
  buttonLoad,
  exchangeRateEnabled,
  artist,
}) => {
  const [estimateItemList, setEstimateItemList] = useState([]);
  const [taxTypes, setTaxTypes] = useState([]);
  const [taxTypeLoad, setTaxTypeLoad] = useState(true);

  useEffect(() => {
    loadTaxTypes();
  }, []);

  useEffect(() => {
    if (!rciDetail) {
      setEstimateItemList(
        estimateRevisionItems.filter(
          item =>
            item.artistId === formik.values.artistId ||
            item.artistMarkupPercentages.find(
              el => el.artistId === formik.values.artistId
            )
        )
      );
    }
  }, [estimateRevisionItems, formik.values.artistId]);

  useEffect(() => {
    if (!rciDetail) {
      prepareRciItemList();
    }
  }, [estimateItemList, formik.values.percentage]);

  useEffect(() => {
    if (!rciDetail) {
      prepareRciItemList();
    }
  }, [formik.values.exchangeRate]);

  useEffect(() => {
    if (artistTaxable() || taxOnExchange) {
      formik.setFieldValue("taxTypeId", exchangeTaxType());

      if (formik.values.currencyId) {
        formik.setFieldValue("taxPercentage", exchangeTaxRate());
      }
    }
  }, [formik.values.final, formik.values.currencyId]);

  useEffect(() => {
    if (formik.values.artistId && artistTaxable()) {
      formik.setFieldValue("taxTypeId", artist.taxTypeId);
      formik.setFieldValue("taxPercentage", artist.taxTypeRate);
    }
  }, [formik.values.artistId, formik.values.final]);

  useEffect(() => {
    if (rciItems.length > 0) {
      formik.setFieldValue("rci_items_attributes", rciItems);
    }
  }, [rciItems]);

  const loadTaxTypes = async () => {
    try {
      const { data } = await getTaxTypes();
      setTaxTypes(dropDownListGenerator(data.taxTypes));
      setTaxTypeLoad(false);
    } catch (error) {
      showToastrError(error);
    }
  };

  const checkCommencementPresence = itemId => {
    return commencementRci?.rciItems.find(
      item => item.estimateRevisionItemId === itemId
    );
  };

  const prepareRciItemList = () => {
    const lineItems = formik.values.final
      ? estimateItemList
      : estimateItemList.filter(item => item.kind === "estimate");
    const newRciItems = lineItems.map(item => {
      if (item.artistId) {
        return {
          amount: item.artistFee,
          name: item.estimateLineItem.name,
          percentage:
            item.kind === "extra" &&
            !checkCommencementPresence(item.id) &&
            formik.values.final
              ? 100
              : formik.values.percentage,
          estimateRevisionItemId: item.id,
          extraType: "addition",
          kind: "estimate",
          exchangeRate: formik.values.exchangeRate,
        };
      } else {
        return item.artistMarkupPercentages
          .filter(
            artistMarkupPercentage =>
              artistMarkupPercentage.artistId === formik.values.artistId
          )
          .map(artistMarkupPercentage => {
            let itemHash = {
              amount: artistMarkupPercentage.artistFee,
              name: `${item.estimateLineItem.name} - ${artistMarkupPercentage.artistName}`,
              percentage:
                item.kind === "extra" &&
                !checkCommencementPresence(item.id) &&
                formik.values.final
                  ? 100
                  : formik.values.percentage,
              estimateRevisionItemId: item.id,
              extraType: "addition",
              kind: "estimate",
              exchangeRate: formik.values.exchangeRate,
            };
            if (artistMarkupPercentage.advance && formik.values.final) {
              itemHash = {
                ...itemHash,
                name: `${item.estimateLineItem.name} - ${artistMarkupPercentage.artistName} Expense`,
                amount: -1 * artistMarkupPercentage.artistFee,
                expense: true,
                advance: true,
                kind: "extra",
                percentage: 100,
                extraType: "deduction",
                exchangeRate: formik.values.exchangeRate,
              };
            } else if (
              artistMarkupPercentage.artistExpense &&
              formik.values.final
            ) {
              itemHash = {
                ...itemHash,
                name: `${item.estimateLineItem.name} - ${artistMarkupPercentage.artistName} Expense`,
                amount: artistMarkupPercentage.artistFee,
                expense: true,
                kind: "extra",
                percentage: 100,
                exchangeRate: formik.values.exchangeRate,
              };
            } else if (
              !formik.values.final &&
              (artistMarkupPercentage.artistExpense ||
                artistMarkupPercentage.advance)
            ) {
              return;
            } else {
              itemHash = { ...itemHash };
            }
            return itemHash;
          });
      }
    });
    setRciItems([...newRciItems].flat().filter(item => item !== undefined));
  };

  const artistTaxable = () => {
    return jobDetail.taxable && jobDetail.defaultTaxTypeId === artist.taxTypeId;
  };

  const taxAmount = () => {
    const subtotal = approvedAmountWithExchangeRateAndPercentage(
      rciItems,
      formik.values.exchangeRate
    );

    if (rciDetail) {
      let amount = formik.values.taxTypeId
        ? Number(
            (Number(subtotal).toFixed(2) *
              Number(formik.values.taxPercentage).toFixed(2)) /
              100
          )
        : 0;
      return amount;
    } else if (artistTaxable() && taxOnExchange) {
      let tax =
        Number(formik.values.taxPercentage) || Number(exchangeTaxRate());
      return Number((Number(subtotal) * Number(tax)) / 100).toFixed(2);
    } else {
      return Number(
        (Number(subtotal) * Number(formik.values.taxPercentage || 0)) / 100
      ).toFixed(2);
    }
  };

  const totalItemAmountWithTax = () => {
    return Number(
      approvedAmountWithExchangeRateAndPercentage(
        rciItems,
        formik.values.exchangeRate
      ) + Number(Number(taxAmount()).toFixed(2))
    ).toFixed(2);
  };

  const rciItemDetail = (item, currency, exchangeRate) => {
    return (
      <div className="flex justify-between p-5 my-4 bg-white rounded-md">
        <Label className="font-medium">{item.name}</Label>
        {item.advance ? (
          <Label className="pl-1 font-bold text-gray-800">{`${numberWithCommas(
            (Number(-item.amount) / Number(exchangeRate)).toFixed(2)
          )} ${currency}`}</Label>
        ) : (
          <Label className="pl-1 font-bold text-gray-800">{`${numberWithCommas(
            (Number(item.amount) / Number(exchangeRate)).toFixed(2)
          )} ${currency}`}</Label>
        )}
      </div>
    );
  };

  const extraRciItemDetail = (item, currency, exchangeRate) => {
    return (
      <div className="flex justify-between p-5 my-4 bg-white rounded-md">
        <Label className="font-medium">{item.name}</Label>
        <Label className="pl-1 font-bold text-gray-800">{`${numberWithCommas(
          (Number(item.amount) / Number(exchangeRate)).toFixed(2)
        )} ${currency}`}</Label>
      </div>
    );
  };

  if (taxTypeLoad) {
    <div className="flex items-center justify-center h-20">
      <Spinner />
    </div>;
  }

  return (
    <div className="flex flex-col mb-6">
      <div className="w-full p-6 bg-gray-100 rounded-t-md">
        <div className="grid grid-cols-4 rounded-md">
          <div className="col-span-3"></div>
          <div className="col-span-1 text-right">
            <Typography weight="semibold" style="h4" className="mb-1">
              Total
            </Typography>
          </div>
        </div>

        {jobDetail.account.showRciItemsDetail &&
          rciItems
            ?.filter(
              item =>
                item.kind !== "extra" ||
                (item.kind === "extra" && item.advance === true)
            )
            ?.map(item => {
              return rciItemDetail(item, jobDetail.currency, 1);
            })}

        <div className="flex justify-between p-5 my-4 bg-white rounded-md">
          <Label className="font-medium">Total Approved Fees</Label>
          <Label className="pl-1 font-bold text-gray-800">
            {numberWithCommas(Number(approvedAmount(rciItems)).toFixed(2))}
            &nbsp;{jobDetail.currency}
          </Label>
        </div>

        {exchangeRateEnabled && (
          <div className="flex justify-between p-5 my-4 bg-white rounded-md">
            <Label className="font-medium">
              Fees due in {formik.values.currencyName} (at exchange rate of{" "}
              {formik.values.exchangeRate}): &nbsp;
            </Label>
            <Label className="pl-1 font-bold text-gray-800">
              {numberWithCommas(
                approvedAmountWithExchangeRateWithoutAdditionalFees(
                  rciItems,
                  formik.values.exchangeRate
                ).toFixed(2)
              )}{" "}
              {formik.values.currencyName}
            </Label>
          </div>
        )}

        {!formik.values.final && (
          <div className="flex justify-between p-5 my-4 bg-white rounded-md">
            <Label className="font-medium neeto-ui-text-primary-600">
              {formik.values.percentage || 0} % of fees due now
            </Label>
            <Label className="pl-1 font-bold neeto-ui-text-primary-600">
              {numberWithCommas(
                Number(
                  approvedAmountWithExchangeRateAndPercentageWithoutadditionalCost(
                    rciItems,
                    formik.values.exchangeRate
                  )
                ).toFixed(2)
              )}{" "}
              {formik.values.currencyName || jobDetail.currency}
            </Label>
          </div>
        )}

        {formik.values.final && (
          <div className="flex justify-between p-5 my-4 bg-white rounded-md">
            <Label className="font-medium neeto-ui-text-success-600">
              Fees paid to date
            </Label>
            <Label className="pl-1 font-bold neeto-ui-text-success-600">
              {`-${numberWithCommas(
                Number(
                  approvedAmountWithPercentageAlreadyPaid(
                    commencementRci?.rciItems.filter(
                      item => item.kind !== "extra"
                    ) || [],
                    formik.values.exchangeRate
                  )
                ).toFixed(2)
              )} ${formik.values.currencyName || jobDetail.currency}`}
            </Label>
          </div>
        )}

        {formik.values.final && (
          <div className="flex justify-between p-5 my-4 bg-white rounded-md">
            <Label className="font-medium">Subtotal</Label>
            <Label className="pl-1 font-bold text-gray-800">
              {numberWithCommas(
                Number(
                  approvedAmountWithExchangeRateAndPercentageWithoutadditionalCost(
                    rciItems,
                    formik.values.exchangeRate
                  )
                ).toFixed(2)
              )}{" "}
              {formik.values.currencyName || jobDetail.currency}
            </Label>
          </div>
        )}

        {rciItems
          ?.filter(item => item.kind === "extra")
          ?.map(item => {
            return extraRciItemDetail(
              item,
              formik.values.currencyName || jobDetail.currency,
              formik.values.exchangeRate
            );
          })}

        {!formik.values.final && (
          <div className="flex justify-between p-5 my-4 bg-white rounded-md">
            <Label className="font-medium">Subtotal</Label>
            <Label className="pl-1 font-bold text-gray-800">
              {numberWithCommas(
                Number(
                  approvedAmountWithExchangeRateAndPercentage(
                    rciItems,
                    formik.values.exchangeRate
                  )
                ).toFixed(2)
              )}{" "}
              {formik.values.currencyName || jobDetail.currency}
            </Label>
          </div>
        )}

        {formik.values.final && (
          <div className="flex justify-between p-5 my-4 bg-white rounded-md">
            <Label className="font-medium">Final Balance of fees due now</Label>
            <Label className="pl-1 font-bold text-gray-800">
              {numberWithCommas(
                Number(
                  approvedAmountWithExchangeRateAndPercentage(
                    rciItems,
                    formik.values.exchangeRate
                  )
                ).toFixed(2)
              )}{" "}
              {formik.values.currencyName || jobDetail.currency}
            </Label>
          </div>
        )}
        <div className="flex justify-between p-5 my-4 bg-white rounded-md">
          <div className="flex w-52">
            <Label className="pr-2 font-medium">Tax: </Label>
            <Select
              isClearable={true}
              className="w-40 mr-2"
              options={taxTypes}
              value={taxTypes.find(
                type =>
                  formik.values.taxTypeId &&
                  type.value === formik.values.taxTypeId
              )}
              onChange={e => {
                if (R.isNil(e)) {
                  formik.setFieldValue("taxTypeId", null);
                } else {
                  formik.setFieldValue("taxTypeId", e.value);
                }
              }}
            />
            <Input
              type="number"
              name="tax"
              className="w-36"
              id="tax"
              value={formik.values.taxPercentage || 0}
              onChange={e => {
                formik.setFieldValue("taxPercentage", e.target.value);
              }}
            />
          </div>
          <Label className="pl-1 font-bold text-gray-800">{`${Number(
            taxAmount()
          ).toFixed(2)} ${
            exchangeRateEnabled
              ? formik.values.currencyName
              : jobDetail.currency
          }`}</Label>
        </div>
      </div>

      {exchangeRateEnabled && (
        <div className="flex justify-end w-full px-6 py-3 bg-gray-200 rounded-b-md">
          <div className="flex flex-col flex-end">
            <div className="self-end text-xl font-bold">
              Total:&nbsp;
              {`${numberWithCommas(
                Number(totalItemAmountWithTax()).toFixed(2)
              )} ${
                exchangeRateEnabled
                  ? formik.values.currencyName
                  : jobDetail.currency
              }`}
            </div>
          </div>
        </div>
      )}

      {!exchangeRateEnabled && (
        <div className="flex justify-end w-full px-6 py-3 bg-gray-200 rounded-b-md">
          <div className="flex flex-col flex-end">
            <div className="self-end text-xl font-bold">
              Total:&nbsp;
              {`${numberWithCommas(
                Number(totalItemAmountWithTax()).toFixed(2)
              )} ${
                exchangeRateEnabled
                  ? formik.values.currencyName
                  : jobDetail.currency
              }`}
            </div>
          </div>
        </div>
      )}

      {!R.isNil(formik.values.percentage) && (
        <div className="flex justify-end mt-8 gap-x-2">
          <Button
            label="Save Changes"
            loading={buttonLoad}
            onClick={() => formik.handleSubmit()}
          />
          {!rciDetail && (
            <Button
              label="Cancel"
              style="secondary"
              onClick={() => {
                formik.resetForm();
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Calculations;

import axios from "axios";

export const getContactsByAmounts = (preferredLocationId, preferredPeriod) => {
  return axios.get(
    `/api/v1/reports/top_contacts_by_amounts?start_date=${preferredPeriod}&location_id=${preferredLocationId}`
  );
};

export const exportContacts = (preferredLocationId, preferredPeriod) => {
  return axios.get(
    `/api/v1/reports/top_contacts_by_amounts/export?start_date=${preferredPeriod}&location_id=${preferredLocationId}`
  );
};

import React, { useState, useEffect } from "react";
import { Copy } from "@bigbinary/neeto-icons";
import * as dayjs from "dayjs";
import { Pane, Button, Typography, Input, Select } from "@bigbinary/neetoui";
import { sendOldThreads } from "apis/jobs/messages";
import { showToastrError } from "common";
import { useFormik } from "formik";
import { INITIAL_VALUE, VALIDATION_SCHEMA } from "./constants";
import ForwardPreview from "./ForwardPreview";

const ShareThreadPane = ({
  threadDetail,
  isOpen,
  onClose,
  jobId,
  allRecipients,
  setShowRecipientsPane,
  setThreadDetail,
  setCurrentTab,
  setActiveThred,
}) => {
  const [recipientEmail, setRecipientEmail] = useState();
  const [shareOldThreadAlertOpen, setShareOldThreadAlertOpen] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [addRecipientAlertOpen, setAddRecipientAlertOpen] = useState(false);
  const [newThreadId, setNewThreadId] = useState();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_VALUE,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: () => {
      sendOldThreadsToNewRecipient();
    },
  });

  useEffect(() => {
    if (shareOldThreadAlertOpen) {
      formik.setFieldValue("htmlBody", generateMessageBody());
      formik.setFieldValue(
        "attachments_attributes",
        getAttachmentsOfAllMessages()
      );
      formik.setFieldValue("subject", threadDetail.subject);
    }
  }, [shareOldThreadAlertOpen]);

  const generateMessageBody = () => {
    const messageMody = threadDetail.messages
      .map(message => {
        return `<u>${message.senderName} wrote on ${dayjs(
          message.createdAt
        ).format("dddd, DD MMM YYYY")}
        at ${dayjs(message.createdAt).format("hh:mm a")}:</u><br /><br />${
          message.htmlBody
        }<br />`;
      })
      .join("");

    return `<br /><br />---------- Forwarded message ---------<br /><br />${messageMody}`;
  };

  const getAttachmentsOfAllMessages = () => {
    return threadDetail.messages
      .map(msg => prepareAttachment(msg.attachments))
      .flat();
  };

  const prepareAttachment = files => {
    return files.map(file => {
      return {
        ...file,
        id: null,
        file_url: file.url,
        attachable_kind: "chat",
        filename: file.name,
      };
    });
  };

  const sendOldThreadsToNewRecipient = async () => {
    try {
      setButtonLoader(true);
      const response = await sendOldThreads(jobId, {
        send_old_thread: { ...formik.values, to: [recipientEmail] },
      });
      setButtonLoader(false);
      setAddRecipientAlertOpen(true);
      setNewThreadId(response.data.thread_id)
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setButtonLoader(false);
    }
  };

  return (
    <Pane
      isOpen={isOpen}
      onClose={() => {
        setRecipientEmail();
        onClose()
      }}
    >
      <Pane.Header>
        <Typography style="h2" weight="semibold">
          Forward Email Thread
        </Typography>
      </Pane.Header>
      <Pane.Body>
        <div className="flex flex-col w-full overflow-y-auto h-full">
          <Typography
            style="body3"
            weight="normal"
            className="mb-3 neeto-ui-text-gray-700"
          >
            This person will receive a wrap up of the entire message thread.
          </Typography>
          <div className="flex flex-col w-full pb-6 mb-6 space-y-4 border-b neeto-ui-border-gray-400">
            <Select
              isClearable={true}
              options={allRecipients}
              onChange={opt => setRecipientEmail(opt)}
              required={true}
              placeholder="Select a recipient"
              label="Recipient"
              value={allRecipients.find(
                recipient => recipient.value === recipientEmail?.value
              )}
            />

            <Button
              label="Forward Thread"
              className="self-end"
              onClick={() => setShareOldThreadAlertOpen(true)}
              disabled={threadDetail.draft || !recipientEmail}
            />
          </div>
          <Input
            disabled
            label="Unique reply to address for this thread"
            value={threadDetail.replyTo}
            helpText="Send emails to this address to have them added to the message thread in Cobbler."
            suffix={
              <Button
                icon={Copy}
                style="text"
                size="small"
                className="neeto-ui-text-gray-700"
              />
            }
          />
        </div>
      </Pane.Body>

      <ForwardPreview
        shareOldThreadAlertOpen={shareOldThreadAlertOpen}
        setShareOldThreadAlertOpen={setShareOldThreadAlertOpen}
        formik={formik}
        sendOldThreadsToNewRecipient={sendOldThreadsToNewRecipient}
        buttonLoader={buttonLoader}
        setShowRecipientsPane={setShowRecipientsPane}
        recipientEmail={recipientEmail}
        jobId={jobId}
        threadDetail={threadDetail}
        setActiveThred={setActiveThred}
        setCurrentTab={setCurrentTab}
        setThreadDetail={setThreadDetail}
        setAddRecipientAlertOpen={setAddRecipientAlertOpen}
        addRecipientAlertOpen={addRecipientAlertOpen}
        setRecipientEmail={setRecipientEmail}
        onClose={onClose}
        newThreadId={newThreadId}
      />
    </Pane>
  );
};

export default ShareThreadPane;

import React from "react";
import { Label } from "@bigbinary/neetoui";
import { numberWithCommas } from "common/helper";

const MarkupFooter = ({
  values,
  markupSplitUp,
  currency,
  totalMarkupValueForAgency,
  revisionItemTotal,
}) => (
  <div className="py-2">
    {values.Markup.artist
      ?.filter(artist => artist._destroy !== 1)
      ?.map((markup, index) => {
        return markupSplitUp(markup, index, values);
      })}

    <div className="flex justify-between py-2 border-b border-gray-200">
      <Label className="font-medium">Agency</Label>
      <Label className="font-bold">
        {`${numberWithCommas(
          totalMarkupValueForAgency(values) || 0
        )} ${currency}`}
      </Label>
    </div>
    <div className="flex justify-between pt-2">
      <Label className="font-bold">Total</Label>
      <Label className="font-bold">
        {`${numberWithCommas(revisionItemTotal(values))} ${currency}`}
      </Label>
    </div>
  </div>
);

export default MarkupFooter;

import React, { useState, useEffect, useRef } from "react";
import { Edit, Delete } from "@bigbinary/neeto-icons";
import { Button, Spinner, Pane, Typography, Alert } from "@bigbinary/neetoui";
import { Container, Header } from "@bigbinary/neetoui/layouts";
import {
  getOrganisations,
  getOrganisation,
  createOrganisation,
  updateOrganisation,
  detroyOrganisation,
} from "apis/organisations/organisations";
import { showToastrError } from "common";
import { useFormik } from "formik";
import { useParams, useHistory } from "react-router-dom";
import { VALIDATION_SCHEMA, INITIAL_VALUE } from "./constants";
import OrganisationDirectory from "./OrganisationDirectory";
import PaneContent from "./EditOrganisation";
import OrganisationDetails from "./OrganisationDetails";

const Organisations = () => {
  const { id } = useParams();
  const history = useHistory();
  const inputRef = useRef(null);
  const [newPane, setNewPane] = useState(false);
  const [editPane, setEditPane] = useState(false);
  const [organisationList, setOrganisationList] = useState([]);
  const [organisationListLoad, setOrganisationListLoad] = useState(true);
  const [organisationDetail, setOrganisationDetail] = useState({});
  const [organisationDetailLoad, setOrganisationDetailLoad] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [list, setList] = useState([]);
  const [btnLoad, setBtnLoad] = useState(false);

  useEffect(() => {
    loadOrganisationListResponse();
  }, []);

  useEffect(() => {
    if (id) {
      loadOrganisationDetailResponse(id);
    } else {
      setOrganisationDetail();
    }
  }, [id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_VALUE,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: values => onSubmit(values),
  });

  const editFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...organisationDetail,
      primaryAddressId: organisationDetail?.primaryAddress?.id,
      primaryStreetAddress: organisationDetail?.primaryAddress?.streetAddress
        ?.split("\\n")
        .join("\n"),
      primarySuburb: organisationDetail?.primaryAddress?.suburb,
      primaryState: organisationDetail?.primaryAddress?.state,
      primaryPostcode: organisationDetail?.primaryAddress?.postcode,
      primaryCountry: organisationDetail?.primaryAddress?.country,
      secondaryAddressId: organisationDetail?.secondaryAddress?.id,
      secondaryStreetAddress: organisationDetail?.secondaryAddress?.streetAddress
        ?.split("\\n")
        .join("\n"),
      secondarySuburb: organisationDetail?.secondaryAddress?.suburb,
      secondaryState: organisationDetail?.secondaryAddress?.state,
      secondaryPostcode: organisationDetail?.secondaryAddress?.postcode,
      secondaryCountry: organisationDetail?.secondaryAddress?.country,
      website: organisationDetail?.website?.replace(/(^\w+:|^)\/\//, ""),
    },
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: values => onUpdate(values),
  });

  const onUpdate = async editingFormValue => {
    try {
      setBtnLoad(true);
      const {
        primaryStreetAddress,
        primarySuburb,
        primaryState,
        primaryCountry,
        primaryPostcode,
        secondaryStreetAddress,
        secondarySuburb,
        secondaryState,
        secondaryCountry,
        secondaryPostcode,
        primaryAddressId,
        secondaryAddressId,
        secondaryAddressDestroy,
        website,
      } = editingFormValue;
      let addressPayload = {
        website: website?.length > 0 ? `https://${website}` : null,
        addresses_attributes: [
          {
            id: primaryAddressId,
            street_address: primaryStreetAddress,
            suburb: primarySuburb,
            state: primaryState,
            country: primaryCountry,
            postcode: primaryPostcode,
            kind: "primary",
          },
          {
            id: secondaryAddressId,
            street_address: secondaryStreetAddress,
            suburb: secondarySuburb,
            state: secondaryState,
            country: secondaryCountry,
            postcode: secondaryPostcode,
            kind: "secondary",
            _destroy: secondaryAddressDestroy ? 1 : 0,
          },
        ],
      };

      let payload = {
        organisation: { ...editingFormValue, ...addressPayload },
      };
      await updateOrganisation(organisationDetail.id, payload);
      loadOrganisationDetailResponse(organisationDetail.id);
      setList([]);
      loadOrganisationListResponse();
      setBtnLoad(false);
      setEditPane(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadOrganisationListResponse = async (
    searchParams = null,
    page = 1
  ) => {
    try {
      const response = await getOrganisations(searchParams, page);
      setOrganisationList(response.data.organisations);
      setTotalRecords(response.data.totalRecords);

      if (!id && response.data.organisations.length > 0) {
        history.push(`/organisations/${response.data.organisations[0].id}`);
      }

      setOrganisationListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadOrganisationDetailResponse = async organisationId => {
    try {
      setOrganisationDetailLoad(true);
      const response = await getOrganisation(organisationId);
      setOrganisationDetail(response.data.organisation);
      setOrganisationDetailLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const onSubmit = async formValue => {
    try {
      setBtnLoad(true);
      const {
        primaryStreetAddress,
        primarySuburb,
        primaryState,
        primaryCountry,
        primaryPostcode,
        secondaryStreetAddress,
        secondarySuburb,
        secondaryState,
        secondaryCountry,
        secondaryPostcode,
        website,
        secondaryAddressDestroy,
        secondaryAddressId,
      } = formValue;
      let addressPayload = {
        website: website?.length > 0 ? `https://${website}` : null,
        addresses_attributes: [
          {
            street_address: primaryStreetAddress,
            suburb: primarySuburb,
            state: primaryState,
            country: primaryCountry,
            postcode: primaryPostcode,
            kind: "primary",
          },
          {
            id: secondaryAddressId,
            street_address: secondaryStreetAddress,
            suburb: secondarySuburb,
            state: secondaryState,
            country: secondaryCountry,
            postcode: secondaryPostcode,
            kind: "secondary",
            _destroy: secondaryAddressDestroy ? 1 : 0,
          },
        ],
      };

      let payload = { organisation: { ...formValue, ...addressPayload } };
      const response = await createOrganisation(payload);
      setNewPane(false);
      loadOrganisationDetailResponse(response.data.id);
      loadOrganisationListResponse();
      formik.resetForm();
      setBtnLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const onDelete = async organisationId => {
    try {
      await detroyOrganisation(organisationId);
      setOrganisationDetail({});
      loadOrganisationListResponse();
      history.push("/organisations");
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  if (organisationListLoad) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <Container isHeaderFixed>
      <Header
        title="Organisations"
        actionBlock={
          <div className="flex justify-end space-x-3">
            {organisationDetail?.id && (
              <>
                <Button
                  label="Delete"
                  style="danger-text"
                  icon={Delete}
                  iconPosition="left"
                  onClick={() => setDeleteAlertOpen(true)}
                />
                <Button
                  style="secondary"
                  label="Edit"
                  icon={Edit}
                  iconPosition="left"
                  onClick={() => {
                    loadOrganisationDetailResponse(organisationDetail.id);
                    setEditPane(true);
                  }}
                />
              </>
            )}
            <Button label="Add Organisation" onClick={() => setNewPane(true)} />
          </div>
        }
      />
      <div className="flex w-full h-screen overflow-hidden border-t border-gray-200">
        {organisationDetailLoad ? (
          <div className="flex items-center justify-center w-full">
            <Spinner />
          </div>
        ) : (
          <OrganisationDetails
            organisationDetail={{
              ...organisationDetail,
            }}
          />
        )}
        <OrganisationDirectory
          organisationList={organisationList}
          loadOrganisationListResponse={loadOrganisationListResponse}
          organisationDetail={organisationDetail}
          list={list}
          setList={setList}
          totalRecords={totalRecords}
        />
      </div>
      <Pane
        isOpen={newPane || editPane}
        onClose={() => {
          editPane ? setEditPane(false) : setNewPane(false);
        }}
        initialFocusRef={inputRef}
      >
        <Pane.Header>
          <Typography style="h2" weight="semibold">
            {editPane ? "Edit Organisation" : "Add Organisation"}
          </Typography>
        </Pane.Header>
        <Pane.Body>
          <PaneContent
            inputRef={inputRef}
            formik={editPane ? editFormik : formik}
          />
        </Pane.Body>
        <Pane.Footer className="gap-2">
          <Button
            label="Save Changes"
            loading={btnLoad}
            onClick={editPane ? editFormik.handleSubmit : formik.handleSubmit}
          />
          <Button
            style="text"
            label="Cancel"
            onClick={() => {
              editPane ? setEditPane(false) : setNewPane(false);
            }}
          />
        </Pane.Footer>
      </Pane>
      <Alert
        isOpen={deleteAlertOpen}
        title="Delete Organisation"
        message="Are you sure you want to delete this organisation?"
        onClose={() => setDeleteAlertOpen(false)}
        onSubmit={() => {
          onDelete(organisationDetail.id);
          setDeleteAlertOpen(false);
        }}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, delete"
      />
    </Container>
  );
};

export default Organisations;

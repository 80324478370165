import * as Yup from "yup";

export const ACCORDIAN_TITLE = [
  "Kick Off",
  "Concept Sketches",
  "Color Draft",
  "Proposed Draft",
];

export const PROJECT_SCOPE = [
  {
    label: "1x Illustration for Home Page",
    value: "1x Illustration for Home Page",
    isEditable: false,
  },
  {
    label: "2x Illustration for Home Page",
    value: "2x Illustration for Home Page",
    isEditable: false,
  },
  {
    label: "3x Illustration for Home Page",
    value: "3x Illustration for Home Page",
    isEditable: false,
  },
];

export const DELIVERY_SCOPE = [
  {
    label: "1x Illustration for Home Page",
    value: "1x Illustration for Home Page",
    isEditable: false,
  },
  {
    label: "2x Illustration for Home Page",
    value: "2x Illustration for Home Page",
    isEditable: false,
  },
  {
    label: "3x Illustration for Home Page",
    value: "3x Illustration for Home Page",
    isEditable: false,
  },
];

export const INITIAL_VALUE = {
  name: "",
};

export const VALIDATION_SCHEMA = Yup.object({
  name: Yup.string().required("Name can't be empty"),
});

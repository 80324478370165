import axios from "axios";

const getPeriodsUrl = id => {
  if (id) {
    return `/api/v1/settings/periods/${id}`;
  }

  return `/api/v1/settings/periods`;
};

export const getPeriods = (searchParams = "") => {
  let query = "";
  if (searchParams.length > 0) query = `q[name_i_cont_any]=${searchParams}`;
  return axios.get(`${getPeriodsUrl()}?${query}`);
};

export const createPeriod = payload => {
  return axios.post(getPeriodsUrl(), payload);
};

export const updatePeriod = (id, payload) => {
  return axios.put(getPeriodsUrl(id), payload);
};

export const destroyPeriod = id => {
  return axios.delete(getPeriodsUrl(id));
};

import React from "react";
import classnames from "classnames";

const Button = ({ children, className, onClick, style, disabled }) => {
  return (
    <button
      className={classnames(
        "flex items-center justify-center btn",
        {
          [className]: className,
        },
        {
          "cursor-not-allowed bg-gray-200 text-gray-600": disabled,
        }
      )}
      onClick={onClick}
      style={style ? style : {}}
    >
      {children}
    </button>
  );
};

export default Button;

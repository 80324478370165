import axios from "axios";

export const getPhases = phaseTypeId => {
  return axios.get(`/api/v1/settings/phase_types/${phaseTypeId}/phases`);
};

export const destroyPhase = (phaseTypeId, id) => {
  return axios.delete(
    `/api/v1/settings/phase_types/${phaseTypeId}/phases/${id}`
  );
};

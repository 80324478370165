import React, { useState, useEffect } from "react";
import { InView } from "react-intersection-observer";
import { Spinner, Toastr } from "@bigbinary/neetoui";
import { useUserState } from "contexts/user";
import {
  overageApprovalMailToProduction,
  overageApprovalQuestionMail,
} from "apis/email_templates/estimate_approvals";
import { getEstimateOverageBuilder } from "apis/email_templates/builders";
import { getOveragePdf } from "apis/email_templates/pdfs";
import prntr from "prntr";
import { useLocation } from "react-router-dom";
import { showToastrError } from "common";
import { numberWithCommas } from "common/helper";
import "../Styles/common";
import {
  OverageApproval,
  TermsandConditions,
  ProjectPhases,
  Investment,
  OverageCostSummary,
  OverageWelcome,
  OverageOverview,
} from "../Pages";
import PrintSave from "../Shared/PrintSave";
import ApproveQuestionButton from "../Shared/ApproveQuestionButton";
import "../Styles/common";
import { CURRENCY_SYMBOL } from "../constants";
import { EMAIL_DISABLED_MESSAGE } from "common/constants";

const ChangeOrder = () => {
  document.title = "Overage";
  const location = useLocation();
  const { user } = useUserState();
  const paramToken = location?.search?.split("?token=")[1];
  const [inView, setInView] = useState(false);
  const [jobDetail, setJobDetail] = useState();
  const [companyDetails, setCompanyDetails] = useState();
  const [revision, setRevision] = useState();
  const [jobPhases, setJobPhases] = useState([]);
  const [killFees, setKillFees] = useState([]);
  const [revisionItems, setRevisionItems] = useState([]);
  const [extraItems, setExtraItems] = useState([]);
  const [emailBackground, setEmailBackground] = useState();
  const [defaultTemplateSetting, setDefaultTemplateSetting] = useState();
  const [defaultTemplateSettingLoad, setDefaultTemplateSettingLoad] = useState(
    false
  );
  const [templateTermsAndConditions, setTemplateTermsAndConditions] = useState(
    []
  );
  const [
    templateTermsAndConditionsLoad,
    setTemplateTermsAndConditionsLoad,
  ] = useState(true);
  const [
    jobTypeSpecificTemplateSetting,
    setJobTypeSpecificTemplateSetting,
  ] = useState();
  const [
    jobTypeSpecificTemplateSettingLoad,
    setJobTypeSpecificTemplateSettingLoad,
  ] = useState();

  const [companyDetailLoad, setCompanyDetailLoad] = useState(true);
  const [jobDetailLoad, setJobDetailLoad] = useState();
  const [revisionLoad, setRevisionLoad] = useState();
  const [jobPhasesLoad, setJobPhasesLoad] = useState([]);
  const [killFeeLoad, setKillFeeLoad] = useState(true);
  const [revisionItemsLoad, setRevisionItemsLoad] = useState([]);
  const [extraItemsLoad, setExtraItemsLoad] = useState(true);
  const [emailBackgroundLoad, setEmailBackgroundLoad] = useState(true);
  const [pdfLoad, setPdfLoad] = useState(false);
  const [mailLoader, setMailLoader] = useState(false);

  useEffect(async () => {
    if (paramToken) {
      getEstimateOverageBuilderRecords();
    }
  }, [paramToken]);

  const getEstimateOverageBuilderRecords = async () => {
    try {
      const response = await getEstimateOverageBuilder(paramToken);
      setJobDetail(response.data.job);
      setRevision(response.data.estimateRevision);
      setJobPhases(response.data.jobPhases);
      setRevisionItems(
        response.data.estimateRevisionItems.filter(
          item => item.kind === "estimate"
        )
      );
      setExtraItems(
        response.data.estimateRevisionItems.filter(
          item => item.kind === "extra"
        )
      );
      setEmailBackground(response.data.emailBackgroundDetail);
      setCompanyDetails(response.data.companyDetails);
      setCompanyDetailLoad(false);
      setDefaultTemplateSetting(
        response.data.templateSettings.find(
          template => template.default === true
        )
      );
      setDefaultTemplateSettingLoad(false);
      setTemplateTermsAndConditions(response.data.templateTermAndConditions);
      setTemplateTermsAndConditionsLoad(false);
      setJobTypeSpecificTemplateSetting(
        response.data.templateSettings.find(
          template => template.jobTypeId === response.data.job.jobTypeId
        )
      );
      setJobTypeSpecificTemplateSettingLoad(false);
      setKillFees(response.data.killFees);
      setJobDetailLoad(false);
      setRevisionLoad(false);
      setJobPhasesLoad(false);
      setKillFeeLoad(false);
      setRevisionItemsLoad(false);
      setExtraItemsLoad(false);
      setEmailBackgroundLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const setResourceForPdfFilename = name => {
    switch (name) {
      case "[job_serial]":
        return jobDetail?.serialNumber;
      case "[org_serial]":
        return jobDetail?.organisationSerial;
      case "[paperwork_serial]":
        return revision?.serialNumber;
    }
  };

  const pdfFileName = () => {
    let fileName = "";
    const filenameComponents = emailBackground?.pdfFileName?.split("<>");

    filenameComponents?.map(component => {
      if (component.includes("[")) {
        fileName += setResourceForPdfFilename(component);
      } else {
        fileName += component;
      }
    });

    return fileName;
  };

  const currencySymbol = currency => {
    if (CURRENCY_SYMBOL[currency] !== undefined) {
      return CURRENCY_SYMBOL[currency];
    } else {
      return "";
    }
  };

  const ammountWithCurrency = amount => {
    return `${currencySymbol(revision?.currency)}${numberWithCommas(
      Number(amount).toFixed(2)
    )} ${revision?.currency}`;
  };

  const estimateTotal = () => {
    return revisionItems.reduce((acc, item) => acc + Number(item.total), 0);
  };

  const extraTotal = () => {
    return extraItems.reduce((acc, item) => acc + Number(item.total), 0);
  };

  const estimateAsfFees = () => {
    return revisionItems
      .filter(item => item.feeType === "agency_service_fee")
      .reduce((acc, item) => acc + Number(item.total), 0);
  };

  const extraAsfFees = () => {
    return extraItems
      .filter(item => item.feeType === "agency_service_fee")
      .reduce((acc, item) => acc + Number(item.total), 0);
  };

  const getOveragePdfRecordsPrint = async () => {
    setPdfLoad(true);
    try {
      const response = await getOveragePdf(paramToken);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

      if (isSafari) {
        const link = document.createElement("a");
        link.href = url;
        link.download = `${pdfFileName() || "Overage"}.pdf`;
        link.click();
      } else {
        prntr({
          printable: url,
          type: "pdf",
          showModal: true,
        });
      }
      setPdfLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const isIE11 = navigator.userAgent.indexOf("Trident/") > -1;

  document.addEventListener(
    "keydown",
    function keyDownHandler(event) {
      // Watch for `metaKey` too (⌘ in Mac)
      if ((event.ctrlKey || event.metaKey) && event.keyCode === 80) {
        // Is the browser IE11?
        if (isIE11) {
          const end = Date.now() + 4000; // block the main thread for 4s.
          while (Date.now() < end) {
            // Deliberately no-op
          }
        }

        event.preventDefault();
        event.stopImmediatePropagation();
        getOveragePdfRecordsPrint();
      }
    },
    isIE11 ? { capture: true } : true
  );

  const getOveragePdfRecords = async () => {
    setPdfLoad(true);
    try {
      const response = await getOveragePdf(paramToken);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.download = `${pdfFileName() || "Overage"}.pdf`;
      link.click();
      setPdfLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const percentageValue = percentage => {
    if (percentage === null) {
      return 0;
    } else {
      return `${percentage}%`;
    }
  };

  const phasePercentage = percentage => {
    if (percentage === "N/A") {
      return 0;
    } else {
      return (
        (Number(estimateTotal() + extraTotal()) * Number(percentage)) / 100
      );
    }
  };

  const projectPhasesData = () => {
    return {
      heads: ["Project Phase", "% of total", "Cost"],
      rows: killFees?.map((phase, index) => {
        return {
          values: [
            <div key="1" className="flex items-center">
              <div className="flex flex-none">
                <div className="flex items-center justify-center w-6 h-6 text-white bg-black rounded-full">
                  {index + 1}
                </div>
              </div>
              <div className="pl-5">{phase.name}</div>
            </div>,
            <div key="2" className="font-bold">
              {percentageValue(phase.percentage)}
            </div>,
            ammountWithCurrency(phasePercentage(phase.percentage)),
          ],
        };
      }),
    };
  };

  const templateLogo = () => {
    return jobTypeSpecificTemplateSetting?.attachments?.find(
      attachment => attachment.attachable_kind === "template_logo"
    );
  };

  const jobLogo = () => {
    if (templateLogo()) {
      return templateLogo().src;
    } else {
      return defaultTemplateSetting?.attachments?.find(
        attachment => attachment.attachable_kind === "pdf_footer_logo"
      )?.src;
    }
  };

  const sendApprovalMailToProduction = async () => {
    try {
      setMailLoader(true);
      const response = await overageApprovalMailToProduction(paramToken);
      setMailLoader(false);

      if (user.demo_account) {
        Toastr.error(EMAIL_DISABLED_MESSAGE);
      } else {
        Toastr.info(response.data.notice);
      }
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const sendApprovalQuestionMailToProduction = async () => {
    try {
      setMailLoader(true);
      const response = await overageApprovalQuestionMail(paramToken);
      setMailLoader(false);

      if (user.demo_account) {
        Toastr.error(EMAIL_DISABLED_MESSAGE);
      } else {
        Toastr.info(response.data.notice);
      }
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const cancellationFeeOverrideText =
    jobTypeSpecificTemplateSetting?.cancellationFeeOverride?.length > 0
      ? jobTypeSpecificTemplateSetting.cancellationFeeOverride
      : defaultTemplateSetting?.defaultCancellationText;

  if (
    jobDetailLoad ||
    revisionLoad ||
    jobPhasesLoad ||
    revisionItemsLoad ||
    extraItemsLoad ||
    emailBackgroundLoad ||
    mailLoader ||
    pdfLoad ||
    killFeeLoad ||
    jobTypeSpecificTemplateSettingLoad ||
    defaultTemplateSettingLoad ||
    companyDetailLoad ||
    templateTermsAndConditionsLoad
  ) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="relative w-full px-4 py-8 bg-white lg:p-8 jw-template">
      <div className="flex flex-col w-full h-full">
        <OverageWelcome
          jobDetail={jobDetail}
          revision={revision}
          emailBackground={emailBackground}
          templateLogo={templateLogo}
          jobLogo={jobLogo}
        />
        <div className="flex flex-col w-full divide-y divide-black">
          <OverageOverview jobDetail={jobDetail} revision={revision} emailBackground={emailBackground} />
          <InView onChange={setInView}>
            {({ ref }) => (
              <div
                ref={ref}
                className="flex flex-col w-full divide-y divide-black"
              >
                <OverageCostSummary
                  title="Previously Approved Cost"
                  desc={emailBackground?.approvedFeeDetails}
                  lineItemCategories={revision?.revisionLineItemCategories}
                  parentLessItemCount={revision?.parentLessItemCount}
                  revisionItems={revisionItems}
                  ammountWithCurrency={ammountWithCurrency}
                  totalBeforeTax={estimateTotal}
                  asfFees={estimateAsfFees}
                  usageEnabled={revision?.usageEnabled}
                  taxTypeId={revision?.taxTypeId}
                  taxType={revision?.taxType}
                  taxAmount={revision?.taxOnEstimate}
                  totalCost={revision?.estimateTotal}
                />

                <OverageCostSummary
                  title="Updated Cost"
                  desc={emailBackground?.updatedCostDetails}
                  extra={true}
                  lineItemCategories={revision?.revisionLineItemCategories}
                  parentLessItemCount={revision?.parentLessItemCount}
                  revisionItems={[...revisionItems, ...extraItems]}
                  ammountWithCurrency={ammountWithCurrency}
                  totalBeforeTax={() => estimateTotal() + extraTotal()}
                  asfFees={() => estimateAsfFees() + extraAsfFees()}
                  usageEnabled={revision?.usageEnabled}
                  taxTypeId={revision?.taxTypeId}
                  taxType={revision?.taxType}
                  taxAmount={revision?.taxOnEstimateExtra}
                  totalCost={revision?.estimateExtraTotal}
                />

                <Investment
                  updatedTotal={ammountWithCurrency(
                    revision?.estimateExtraTotal
                  )}
                  emailBackground={emailBackground}
                />
                {revision?.jobPhaseEnabled && jobPhases?.length > 0 && (
                  <ProjectPhases
                    data={revision?.jobPhaseEnabled ? jobPhases : []}
                    description={emailBackground?.phasesDetails}
                  />
                )}
                <TermsandConditions
                  defaultTemplateSetting={defaultTemplateSetting}
                  templateTermsAndConditions={templateTermsAndConditions}
                  jobTypeSpecificTemplateSetting={
                    jobTypeSpecificTemplateSetting
                  }
                  projectPhasesData={projectPhasesData}
                  cancellationFeeOverrideText={cancellationFeeOverrideText}
                  specialConditions={jobDetail.specialConditions}
                />
              </div>
            )}
          </InView>
        </div>
        <OverageApproval
          jobDetail={jobDetail}
          illustratorAuthorLink={emailBackground?.artistUrl}
          illustratorAuthorName={emailBackground?.artistName}
          footerLogo={defaultTemplateSetting?.attachments?.find(
            attachment => attachment.attachable_kind === "pdf_footer_logo"
          )}
          companyDetails={companyDetails}
          sendApprovalMailToProduction={sendApprovalMailToProduction}
          sendApprovalQuestionMailToProduction={
            sendApprovalQuestionMailToProduction
          }
          emailBackground={emailBackground}
        />
      </div>
      <PrintSave
        isVisible={inView}
        getPdfRecordsPrint={getOveragePdfRecordsPrint}
        getPdfRecords={getOveragePdfRecords}
      />
      <ApproveQuestionButton
        isVisible={inView}
        sendApprovalMailToProduction={sendApprovalMailToProduction}
        sendApprovalQuestionMailToProduction={
          sendApprovalQuestionMailToProduction
        }
      />
    </div>
  );
};

export default ChangeOrder;

import React, { useState, useRef, useEffect } from "react";
import {
  Spinner,
  Button,
  Alert,
  Input,
  Modal,
  Typography,
  Toastr,
} from "@bigbinary/neetoui";
import { Header, Container } from "@bigbinary/neetoui/layouts";
import { isEmpty } from "ramda";
import { useFormik } from "formik";
import {
  getCurrencies,
  createCurrency,
  updateCurrency,
  destroyCurrency,
} from "apis/settings/currencies";
import { showToastrError } from "common";
import { getRandomNotFoundImage } from "common/helper";
import useDebounce from "common/debounce";
import {
  CURRENCY_VALIDATION_SCHEMA,
  CURRENCY_INITIAL_VALUE,
} from "../constants";
import List from "./List";
import EmptyState from "components/Common/EmptyState";

const Currencies = ({ breadcrumbs }) => {
  const inputRef = useRef();
  const [currencyList, setCurrencyList] = useState([]);
  const [listLoader, setListLoader] = useState(true);
  const [currency, setCurrency] = useState({});
  const [modalState, setModalState] = useState(false);
  const [deleteAlertState, setDeleteAlertState] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [searchParams, setSearchParams] = useState();
  const debouncedSearchTerm = useDebounce(searchParams, 1000);
  const [emptyImage, setEmptyImage] = useState();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: currency?.id ? currency : CURRENCY_INITIAL_VALUE,
    validationSchema: CURRENCY_VALIDATION_SCHEMA,
    onSubmit: () => {
      if (currency?.id) {
        updateCurrencyEntry();
      } else {
        createCurrencyEntry();
      }
    },
  });

  useEffect(() => {
    loadCurrencyList();
    setEmptyImage(getRandomNotFoundImage())
  }, []);

  useEffect(() => {
    if (debouncedSearchTerm != undefined) {
      loadCurrencyList();
    }
  }, [debouncedSearchTerm]);

  const loadCurrencyList = async () => {
    try {
      const response = await getCurrencies(searchParams);
      setCurrencyList(response.data.currencies || []);
      setListLoader(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const createCurrencyEntry = async () => {
    try {
      const response = await createCurrency({
        currency: { name: formik.values.name },
      });
      setModalState(false);
      Toastr.info(response.data.notice);
      formik.resetForm();
      loadCurrencyList();
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setButtonLoader(false);
    }
  };

  const updateCurrencyEntry = async () => {
    try {
      const response = await updateCurrency(currency?.id, {
        currency: { name: formik.values.name },
      });
      setModalState(false);
      Toastr.info(response.data.notice);
      formik.resetForm();
      loadCurrencyList();
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setButtonLoader(false);
    }
  };

  const destroyCurrencyEntry = async () => {
    try {
      const response = await destroyCurrency(currency?.id);
      setDeleteAlertState(false);
      Toastr.info(response.data.notice);
      loadCurrencyList();
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setButtonLoader(false);
    }
  };

  const handleNewButtonClick = async () => {
    setButtonLoader(false);
    formik.resetForm();
    setCurrency({});
    setModalState(true);
  };

  const handleSubmitButtonClick = async () => {
    if (Object.keys(formik.errors).length === 0) {
      setButtonLoader(true);
      formik.handleSubmit();
    }
  };

  if (listLoader) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <Container>
      <Header
        title="Currencies"
        breadcrumbs={breadcrumbs}
        searchProps={{
          value: searchParams,
          onChange: e => setSearchParams(e.target.value),
          clear: () => setSearchParams(),
        }}
        actionBlock={
          <Button label="Add New" onClick={() => handleNewButtonClick()} />
        }
      />
      <div className="w-full" style={{ height: "calc(100vh - 128px)" }}>
        {!isEmpty(currencyList) ? (
          <List
            currencyList={currencyList}
            setCurrency={setCurrency}
            setModalState={setModalState}
            setDeleteAlertState={setDeleteAlertState}
          />
        ) : (
          <EmptyState
            image={emptyImage}
            title="No Currencies Found"
            description="We couldn’t find any currencies. Try creating one."
            primaryButtonProps={{
              label: "Add New",
              onClick: () => handleNewButtonClick(),
            }}
          />
        )}
      </div>

      <Modal
        isOpen={modalState}
        onClose={() => setModalState(false)}
        initialFocusRef={inputRef}
      >
        <Modal.Header>
          <Typography style="h2" weight="semibold">
            {currency?.id ? "Edit Currency" : "Add Currency"}
          </Typography>
        </Modal.Header>
        <Modal.Body>
          <div className="flex flex-col w-full space-y-6">
            <Input
              label="Name"
              ref={inputRef}
              required={true}
              onChange={formik.handleChange}
              value={formik.values.name}
              error={
                Boolean(formik.touched.name && formik.errors.name) &&
                formik.errors.name
              }
              {...formik.getFieldProps("name")}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="flex gap-x-2">
          <Button
            label="Save changes"
            onClick={() => handleSubmitButtonClick()}
            loading={buttonLoader}
          />
          <Button
            label="Cancel"
            style="text"
            onClick={() => setModalState(false)}
          />
        </Modal.Footer>
      </Modal>

      <Alert
        isOpen={deleteAlertState}
        title="Delete Currency"
        message={
          <>
            Are you sure you want to delete the currency{" "}
            <strong>{currency?.name}</strong>?
          </>
        }
        onClose={() => setDeleteAlertState(false)}
        onSubmit={() => {
          setButtonLoader(true);
          destroyCurrencyEntry();
        }}
        isSubmitting={buttonLoader}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, delete"
      />
    </Container>
  );
};

export default Currencies;

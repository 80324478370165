import React, { useState, useEffect } from "react";
import { Notification, CheckCircle } from "@bigbinary/neeto-icons";
import * as dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import { showToastrError } from "common";
import { updateNotificationBulk } from "apis/dashboards";
import {
  Dropdown,
  Button,
  Typography,
  Avatar,
  Spinner,
  Alert,
} from "@bigbinary/neetoui";

const NotificationDropdown = ({
  notificationListLoad,
  loadNotificationsResponse,
  notificationList,
  unseenNotificationCount,
}) => {
  const history = useHistory();
  const [notification, setNotification] = useState();
  const [updateConfirmAlert, setUpdateConfirmAlert] = useState(false);

  useEffect(() => {
    if (notification) {
      history.push({
        pathname: `jobs/${notification.jobId}/messages`,
        state: { threadId: notification.threadId },
      });
    }
  }, [notification]);

  const updateNotificationOnBulk = async () => {
    try {
      await updateNotificationBulk();
      loadNotificationsResponse();
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  return (
    <Dropdown
      customTarget={
        <div className="relative">
          <Button icon={Notification} style="text" />
          {unseenNotificationCount > 0 && (
            <div
              className="absolute w-2 h-2 ml-2 rounded-full top-1.5 right-1.5"
              style={{
                background: "#FC5555",
              }}
            ></div>
          )}
        </div>
      }
      position="bottom-start"
      className="notification-dropdown"
      onClick={() => loadNotificationsResponse()}
    >
      <div className="relative flex flex-col w-96 neeto-ui-bg-white p-5">
        {notificationListLoad ? (
          <div className="flex flex-col w-full h-56 justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <>
            {notificationList?.length > 0 ? (
              <div className="h-80">
                <div className="sticky top-0 z-30 flex items-center justify-between mb-4 neeto-ui-bg-white">
                  <Typography
                    style="h5"
                    weight="semibold"
                    className="uppercase neeto-ui-text-gray-800"
                  >
                    Notifications
                  </Typography>

                  <Button
                    label="Mark all as read"
                    size="small"
                    style="text"
                    className="flex-shrink-0 ml-2 text-gray-600"
                    onClick={() => updateNotificationOnBulk()}
                  />
                </div>

                <div className="overflow-y-auto divide-y divide-gray-300">
                  {notificationList.map((notification, index) => (
                    <div
                      key={index}
                      className="flex items-start w-full p-2 space-x-2 cursor-pointer hover:neeto-ui-bg-gray-100"
                      onClick={() => setNotification(notification)}
                    >
                      <Avatar
                        className="flex-shrink-0"
                        size="medium"
                        user={{
                          name: notification.senderName,
                          imageUrl: notification.profile,
                        }}
                      />
                      <div className="flex flex-col items-start space-y-1 w-full">
                        <div className="flex justify-between w-full">
                          <Typography style="body3" weight="semibold">
                            {`${notification.senderName} • ${notification.senderOrganisation}`}
                          </Typography>
                          <Typography
                            style="nano"
                            className="neeto-ui-text-gray-600 flex-shrink-0"
                          >
                            {dayjs(notification.createdAt).format(
                              "MMM D, h:mm A"
                            )}
                          </Typography>
                        </div>

                        <Typography
                          style="body3"
                          className="neeto-ui-text-gray-800 line-clamp-1"
                        >
                          {notification.subject}
                        </Typography>

                        <Typography
                          style="nano"
                          className="neeto-ui-text-gray-600 line-clamp-2"
                        >
                          {notification.description}
                        </Typography>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center w-full h-56">
                <CheckCircle color="#1e1e20" size={24} className="mb-4" />
                <Typography style="body3" className="neeto-ui-text-gray-800">
                  You&apos;re all caught up!
                </Typography>
              </div>
            )}
          </>
        )}
      </div>

      <Alert
        isOpen={updateConfirmAlert}
        title="Mark all message as read!"
        message={"Are you sure you want to mark all the messages read?"}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, Sure!"
        onClose={() => setUpdateConfirmAlert(false)}
        onSubmit={() => updateNotificationOnBulk()}
      />
    </Dropdown>
  );
};

export default NotificationDropdown;

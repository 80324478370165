import * as Yup from "yup";
import { Message, Forward } from "@bigbinary/neeto-icons";
import { MAX_STRING_LENGTH } from "common/constants";

export const THREADS_LIST = {
  Messages: {
    icon: Message,
    label: "Messages",
  },
  Forward: {
    icon: Forward,
    label: "Forward",
  },
};

export const INITIAL_VALUE = {
  subject: "",
  htmlBody: "",
};

export const VALIDATION_SCHEMA = Yup.object({
  subject: Yup.string()
    .required("Subject can't be empty.")
    .max(
      MAX_STRING_LENGTH,
      `Subject must be at most ${MAX_STRING_LENGTH} characters.`
    ),
  htmlBody: Yup.string().required("Message can't be empty."),
});

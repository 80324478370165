import React, { useState, useEffect, useRef } from "react";
import { Plus, MenuHorizontal } from "@bigbinary/neeto-icons";
import {
  Button,
  Textarea,
  Spinner,
  Label,
  Dropdown,
  Table,
  Typography,
  Modal,
  Alert,
} from "@bigbinary/neetoui";
import * as dayjs from "dayjs";
import * as R from "ramda";
import {
  updateContactNote,
  createContactNote,
  destroyContactNote,
  getContactNotes,
  getContactNote,
} from "apis/contacts/contact_notes";
import { showToastrError } from "common";
import { getRandomNotFoundImage, urlify } from "common/helper";
import { useFormik } from "formik";
import { NOTE_VALIDATION_SCHEMA, INITIAL_NOTE_VALUE } from "./constants";
import EmptyState from "components/Common/EmptyState";

const RenderNotes = ({ notes }) => {
  const [showNote, setShowNote] = useState(false);

  return (
    <div>
      {notes?.length > 0 ? (
        <div className="flex flex-col space-y-1">
          {showNote ? (
            <Typography style="body2" className="text-gray-800 w-96" dangerouslySetInnerHTML={{ __html:   urlify(notes?.split("\n").join("<br>"))}}>
            </Typography>
          ) : (
            <Typography style="body2" className="text-gray-800 w-80" dangerouslySetInnerHTML={{ __html: notes?.length < 200 ? urlify(notes.split("\n").join("<br>")) : urlify(notes.substring(0, 120) + "...")}}>
            </Typography>
          )}
          {notes?.length > 200 && (
            <Button
              style="link"
              onClick={() => setShowNote(!showNote)}
              label={showNote ? "Less" : "More"}
            />
          )}
        </div>
      ) : (
        "No notes"
      )}
    </div>
  );
};

const ContactNotes = ({ contactDetail }) => {
  const inputRef = useRef(null);
  const [modalState, setModalState] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [contactNoteDetail, setContactNoteDetail] = useState();
  const [contactNoteId, setContactNoteId] = useState();
  const [contactNoteList, setContactNoteList] = useState([]);
  const [contactNoteListLoading, setContactNoteListLoading] = useState(true);
  const [contactNoteDetailLoading, setContactNoteDetailLoading] = useState(
    false
  );
  const { Menu, MenuItem } = Dropdown;
  const [emptyImage, setEmptyImage] = useState()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: contactNoteDetail ? contactNoteDetail : INITIAL_NOTE_VALUE,
    validationSchema: NOTE_VALIDATION_SCHEMA,
    onSubmit: () => {
      if (contactNoteDetail) {
        updateContactNoteResponse();
      } else {
        createContactNoteResponse();
      }
    },
  });

  useEffect(() => {
    setEmptyImage(getRandomNotFoundImage());
  }, [])

  useEffect(() => {
    if (contactDetail) {
      loadContactNoteListResponse();
    }
  }, [contactDetail]);

  const loadContactNoteListResponse = async () => {
    try {
      const response = await getContactNotes(contactDetail.id);
      setContactNoteList(response.data.contactNotes);
      setContactNoteListLoading(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadContactNoteDetailResponse = async noteId => {
    try {
      setContactNoteDetailLoading(true);
      const response = await getContactNote(contactDetail.id, noteId);
      setContactNoteDetail(response.data.contactNote);
      setContactNoteDetailLoading(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const createContactNoteResponse = async () => {
    try {
      let payload = { contact_note: { note: formik.values.note } };
      await createContactNote(contactDetail.id, payload);
      setModalState(false);
      setContactNoteListLoading(true);
      loadContactNoteListResponse();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const updateContactNoteResponse = async () => {
    try {
      let payload = { contact_note: { note: formik.values.note } };
      await updateContactNote(contactDetail.id, contactNoteDetail.id, payload);
      setModalState(false);
      setContactNoteListLoading(true);
      loadContactNoteListResponse();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const deleteContactNoteEntry = async () => {
    try {
      await destroyContactNote(contactDetail.id, contactNoteId);
      setDeleteAlertOpen(false);
      setContactNoteListLoading(true);
      loadContactNoteListResponse();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const COLUMN_DATA = [
    {
      title: "Note",
      dataIndex: "note",
      key: "note",
      width: "50%",
      render: note => <RenderNotes notes={note} />,
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      width: "20%",
      render: createdBy => <>{createdBy}</>,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "20%",
      render: createdAt => dayjs(createdAt).format("DD/MM/YYYY"),
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      fixed: "right",
      width: "10%",
      render: (_, rowData) => (
        <Dropdown
          autoWidth
          closeOnSelect
          buttonStyle="text"
          strategy="fixed"
          appendTo={() => document.body}
          icon={MenuHorizontal}
        >
          <Menu>
            <MenuItem.Button
              onClick={() => {
                loadContactNoteDetailResponse(rowData.id);
                setModalState(true);
              }}
            >
              Edit
            </MenuItem.Button>
            <MenuItem.Button
              style="danger"
              onClick={() => {
                setContactNoteId(rowData.id);
                setDeleteAlertOpen(true);
              }}
            >
              Delete
            </MenuItem.Button>
          </Menu>
        </Dropdown>
      ),
    },
  ];

  if (contactNoteListLoading || contactNoteDetailLoading) {
    return (
      <div className="flex items-center justify-center w-full h-10">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex flex-col pt-3 border-t border-gray-200">
      <div className="flex items-center justify-between pb-3 mb-3 space-x-3 border-b border-gray-200">
        <Label className="font-semibold text-gray-800">Notes / Sources</Label>

        <Button
          style="link"
          icon={Plus}
          iconPosition="left"
          label="Add Note"
          onClick={() => {
            setContactNoteDetail();
            formik.resetForm();
            setModalState(true);
          }}
        />
      </div>

      {!R.isEmpty(contactNoteList) ? (
        <Table columnData={COLUMN_DATA} rowData={contactNoteList} />
      ) : (
        <EmptyState image={emptyImage} title="No Notes Found" />
      )}

      <Modal
        isOpen={modalState}
        onClose={() => setModalState(false)}
        initialFocusRef={inputRef}
      >
        <Modal.Header>
          <Typography style="h2" weight="semibold">
            {contactNoteDetail ? "Edit Note" : "Add Note"}
          </Typography>
        </Modal.Header>
        <Modal.Body>
          <Textarea
            label="Notes"
            name="notes"
            ref={inputRef}
            value={formik.values.note}
            onChange={formik.handleChange}
            error={
              Boolean(formik.touched.note && formik.errors.note) &&
              formik.errors.note
            }
            rows={4}
            {...formik.getFieldProps("note")}
          />
        </Modal.Body>
        <Modal.Footer className="space-x-2">
          <Button
            label="Add Note"
            onClick={() => {
              formik.handleSubmit();
            }}
          />
          <Button
            style="text"
            label="Cancel"
            onClick={() => {
              setModalState(false);
            }}
          />
        </Modal.Footer>
      </Modal>

      <Alert
        isOpen={deleteAlertOpen}
        title="Delete Note"
        message="Are you sure you want to delete this note?"
        onClose={() => setDeleteAlertOpen(false)}
        onSubmit={() => deleteContactNoteEntry()}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, delete"
      />
    </div>
  );
};

export default ContactNotes;

import React, { useState, useRef, useEffect } from "react";
import { Spinner, Button, Alert, Typography, Pane } from "@bigbinary/neetoui";
import { Header, Container } from "@bigbinary/neetoui/layouts";
import { isEmpty } from "ramda";
import { useFormik } from "formik";
import {
  getFilterSpecifications,
  createFilterSpecification,
  updateFilterSpecification,
  destroyFilterSpecification,
  getFilterSpecification,
} from "apis/settings/filter_specifications";
import { showToastrError } from "common";
import { getRandomNotFoundImage } from "common/helper";
import {
  FILTER_SPECIFICATION_VALIDATION_SCHEMA,
  FILTER_SPECIFICATION_INITIAL_VALUE,
} from "./constants";
import List from "./List";
import Form from "./Form";
import EmptyState from "components/Common/EmptyState";

const FilterSpecifications = ({ breadcrumbs }) => {
  const inputRef = useRef();
  const [filterSpecificationList, setFilterSpecificationList] = useState([]);
  const [
    filterSpecificationListLoad,
    setFilterSpecificationListLoad,
  ] = useState(true);
  const [filterSpecification, setFilterSpecification] = useState();
  const [modalState, setModalState] = useState(false);
  const [deleteModalState, setDeleteModalState] = useState(false);
  const [emptyImage, setEmptyImage] = useState();

  useEffect(() => {
    loadData();
    setEmptyImage(getRandomNotFoundImage());
  }, []);

  const loadData = async () => {
    loadFilterSpecificationListResponse();
  };

  const loadFilterSpecificationListResponse = async () => {
    try {
      const filterSpecificationListResponse = await getFilterSpecifications();
      setFilterSpecificationList(
        filterSpecificationListResponse.data.filterSpecifications || []
      );
      setFilterSpecificationListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterSpecification
      ? filterSpecification
      : FILTER_SPECIFICATION_INITIAL_VALUE,
    validationSchema: FILTER_SPECIFICATION_VALIDATION_SCHEMA,
    onSubmit: () => {
      if (filterSpecification) {
        updateFilterSpecificationEntry();
      } else {
        createFilterSpecificationEntry();
      }
    },
  });

  const getFilterSpecificationEntry = async filterSpecificationId => {
    try {
      const { data } = await getFilterSpecification(filterSpecificationId);
      setFilterSpecification(data.filterSpecification);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const createFilterSpecificationEntry = async () => {
    try {
      await createFilterSpecification({
        filter_specification: {
          ...formik.values,
        },
      });
      loadFilterSpecificationListResponse();
      setModalState(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const updateFilterSpecificationEntry = async () => {
    try {
      await updateFilterSpecification(filterSpecification.id, {
        filter_specification: {
          ...formik.values,
        },
      });
      formik.resetForm();
      loadFilterSpecificationListResponse();
      setModalState(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const destroyFilterSpecificationEntry = async () => {
    try {
      await destroyFilterSpecification(filterSpecification.id);
      loadFilterSpecificationListResponse();
      setDeleteModalState(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  if (filterSpecificationListLoad) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <Container>
      <Header
        title="Filter Specifications"
        breadcrumbs={breadcrumbs}
        actionBlock={
          <Button
            label="Add Filter Specification"
            onClick={() => {
              formik.resetForm();
              setFilterSpecification();
              setModalState(true);
            }}
          />
        }
      />
      <div className="w-full" style={{ height: "calc(100vh - 128px)" }}>
        {!isEmpty(filterSpecificationList) ? (
          <List
            setFilterSpecification={setFilterSpecification}
            setModalState={setModalState}
            setDeleteModalState={setDeleteModalState}
            filterSpecificationList={filterSpecificationList}
            getFilterSpecificationEntry={getFilterSpecificationEntry}
          />
        ) : (
          <EmptyState
            image={emptyImage}
            title="No Filter Specifications Found"
            description="We couldn’t find any filter specifications. Try creating one."
            primaryButtonProps={{
              label: "Add Filter Specification",
              onClick: () => {
                formik.resetForm();
                setFilterSpecification();
                setModalState(true);
              },
            }}
          />
        )}
      </div>

      <Pane
        isOpen={modalState}
        onClose={() => setModalState(false)}
        initialFocusRef={inputRef}
      >
        <Pane.Header>
          <Typography style="h2" weight="semibold">
            {filterSpecification ? "Edit Specification" : "Add Specification"}
          </Typography>
        </Pane.Header>
        <Pane.Body>
          <Form ref={inputRef} formik={formik} />
        </Pane.Body>
        <Pane.Footer className="flex justify-between w-full">
          <div className="flex items-center gap-x-2">
            <Button
              label="Save Changes"
              onClick={() => formik.handleSubmit()}
            />
            <Button
              label="Cancel"
              style="text"
              onClick={() => setModalState(false)}
            />
          </div>
        </Pane.Footer>
      </Pane>

      <Alert
        isOpen={deleteModalState}
        title="Delete Filter Specification"
        message="Are you sure you want to delete the filter specification?"
        onClose={() => setDeleteModalState(false)}
        onSubmit={() => destroyFilterSpecificationEntry()}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, delete"
      />
    </Container>
  );
};

export default FilterSpecifications;

import axios from "axios";

const getCatchupNotesUrl = (artistId, id) => {
  if (id) {
    return `/api/v1/artists/artists/${artistId}/catchup_notes/${id}`;
  }

  return `/api/v1/artists/artists/${artistId}/catchup_notes`;
};

export const getCatchupNotes = artistId => {
  return axios.get(getCatchupNotesUrl(artistId));
};

export const getCatchupNote = (artistId, id) => {
  return axios.get(getCatchupNotesUrl(artistId, id));
};

export const createCatchupNote = (artistId, payload) => {
  return axios.post(getCatchupNotesUrl(artistId), payload);
};

export const updateCatchupNote = (artistId, id, payload) => {
  return axios.put(getCatchupNotesUrl(artistId, id), payload);
};

export const destroyCatchupNote = (artistId, id) => {
  return axios.delete(getCatchupNotesUrl(artistId, id));
};

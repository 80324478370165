import React from "react";
import videoFile from "images/mailers/welcome_email/video.png";
import Footer from "../Shared/footer";

const WelcomeEmail = ({
  formik,
  jobDetail,
  companyDetails,
  defaultTemplateSetting,
  emailBackground,
}) => {
  return (
    <div className="p-0 font-sans text-base bg-white email-body">
      <div className="w-full px-10 pt-12">
        <h2 className="mb-12 text-6xl font-medium">Welcome!</h2>
        <p className="flex justify-center">
          <img src={jobDetail.staffWelcomeImageUrl} className="w-full" />
        </p>
        <br />
        <p
          className="text-base"
          dangerouslySetInnerHTML={{ __html: formik.values.header }}
        ></p>
        <hr className="my-6" />
        <table
          role="presentation"
          border="0"
          cellPadding="0"
          cellSpacing="0"
          className="w-full"
        >
          <tbody>
            <tr>
              <td>
                <table
                  role="presentation"
                  border="0"
                  cellPadding="0"
                  cellSpacing="0"
                  className="w-full"
                >
                  <tbody>
                    <tr>
                      <td>
                        <h6 className="mb-6 text-2xl font-bold text-left">
                          Meet your talent!
                        </h6>
                        <div className="flex flex-wrap justify-around">
                          {jobDetail.artists.map((artist, index) => {
                            return (
                              <div
                                key={index}
                                className="px-3 py-0 mb-4 text-center flex flex-col"
                              >
                                <div className="flex items-center justify-center w-32 h-32 mb-3 rounded-full">
                                  <img
                                    src={artist.heroImageUrl}
                                    className="block w-full h-full artist-headshot object-cover"
                                  />
                                </div>
                                <a
                                  className="text-base font-semibold text-black"
                                  href={artist.artistUrl}
                                >
                                  {artist.fullName}
                                </a>
                                <a
                                  className="text-base font-semibold text-black"
                                  href={`https://www.instagram.com/${artist.instagramUserName}`}
                                >
                                  {artist.instagramUserName}
                                </a>
                              </div>
                            );
                          })}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <hr className="my-6" />
        <p
          className="text-base"
          dangerouslySetInnerHTML={{ __html: formik.values.body }}
        ></p>

        <a href={formik.values.videoUrl} target="_blank" rel="noreferrer">
          <img src={videoFile} className="w-full mx-0 my-6" />
        </a>

        <p
          className="mb-6 text-base"
          dangerouslySetInnerHTML={{ __html: formik.values.footer }}
        ></p>

        <p className="flex mb-6 text-left">
          <img src={jobDetail.staffSignature} className="w-32 mx-0" />
        </p>
      </div>
      <Footer
        companyDetails={companyDetails}
        account={jobDetail.account}
        footerLogo={
          defaultTemplateSetting?.attachments.find(
            attachment => attachment.attachable_kind === "email_footer_logo"
          )?.src
        }
        emailBackground={emailBackground}
      />
    </div>
  );
};

export default WelcomeEmail;

import React, { useState, useEffect } from "react";
import { Spinner, Input, Select, Textarea, Switch } from "@bigbinary/neetoui";
import { getCurrencies } from "apis/settings/currencies";
import { getLocations } from "apis/settings/locations";
import { getTaxTypes } from "apis/settings/tax_types";
import { showToastrError } from "common";
import { dropDownListGenerator } from "common/helper";

const CompanyDetail = ({ formik, setTaxTypeAlertOpen }) => {
  const [currencyList, setCurrencyList] = useState([]);
  const [currencyListLoad, setCurrencyListLoad] = useState(true);
  const [locationList, setLocationList] = useState([]);
  const [locationListLoad, setLocationListLoad] = useState(true);
  const [displayInTemplate, setDisplayInTemplate] = useState(false);
  const [taxTypes, setTaxTypes] = useState([]);
  const [taxTypeLoad, setTaxTypeLoad] = useState(true);

  useEffect(() => {
    loadCurrencyList();
    loadLocationList();
    loadTaxTypes();
  }, []);

  useEffect(() => {
    setDisplayInTemplate(formik.values.displayInTemplate);
  }, [formik.values.displayInTemplate]);

  const loadTaxTypes = async () => {
    try {
      const { data } = await getTaxTypes();
      setTaxTypes(dropDownListGenerator(data.taxTypes));
      setTaxTypeLoad(false);
    } catch (error) {
      showToastrError(error);
    }
  };

  const loadCurrencyList = async () => {
    try {
      const response = await getCurrencies();
      setCurrencyList(response.data.currencies);
      setCurrencyListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadLocationList = async () => {
    try {
      const response = await getLocations();
      setLocationList(dropDownListGenerator(response.data.locations));
      setLocationListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  if (currencyListLoad || locationListLoad || taxTypeLoad) {
    return (
      <div className="flex items-center justify-center h-full">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full pb-8 mt-6 space-y-6">
      <Input
        required={true}
        label="Name"
        name="Name"
        onChange={formik.handleChange}
        value={formik.values.name}
        error={
          Boolean(formik.touched.name && formik.errors.name) &&
          formik.errors.name
        }
        {...formik.getFieldProps("name")}
      />

      <div className="grid grid-cols-2 gap-4">
        <Select
          isClearable
          label="Tax Type"
          options={taxTypes}
          value={taxTypes.find(type => type.value === formik.values.taxTypeId)}
          error={
            Boolean(formik.touched.taxTypeId && formik.errors.taxTypeId) &&
            formik.errors.taxTypeId
          }
          onChange={async option => {
            if (option) {
              await formik.setFieldValue("taxTypeId", option.value);
              formik.setFieldValue("taxTypeName", option.label);
            } else {
              formik.setFieldValue("taxTypeId", null);
            }
          }}
        />

        <Input
          type="number"
          label="Tax Rate"
          name="taxRate"
          required={formik.values.taxTypeId !== null}
          onChange={formik.handleChange}
          value={formik.values.taxRate}
          error={
            Boolean(formik.touched.taxRate && formik.errors.taxRate) &&
            formik.errors.taxRate
          }
          {...formik.getFieldProps("taxRate")}
        />
      </div>

      <div className="grid grid-cols-2 gap-4">
        <Input
          type="number"
          label="Early Bird Fee Percentage"
          name="earlyBirdFeePercentage"
          onChange={formik.handleChange}
          value={formik.values.earlyBirdFeePercentage}
          error={
            Boolean(
              formik.touched.earlyBirdFeePercentage &&
                formik.errors.earlyBirdFeePercentage
            ) && formik.errors.earlyBirdFeePercentage
          }
          {...formik.getFieldProps("earlyBirdFeePercentage")}
        />

        <Input
          type="number"
          label="Late Fee Percentage"
          name="lateFeePercentage"
          onChange={formik.handleChange}
          value={formik.values.lateFeePercentage}
          error={
            Boolean(
              formik.touched.lateFeePercentage &&
                formik.errors.lateFeePercentage
            ) && formik.errors.lateFeePercentage
          }
          {...formik.getFieldProps("lateFeePercentage")}
        />
      </div>

      <div className="grid grid-cols-2 gap-4">
        <Input
          type="number"
          label="Comm. Invoice Payment Term"
          name="commencementInvoicePaymentTerm"
          onChange={formik.handleChange}
          value={formik.values.commencementInvoicePaymentTerm}
          error={
            Boolean(
              formik.touched.commencementInvoicePaymentTerm &&
                formik.errors.commencementInvoicePaymentTerm
            ) && formik.errors.commencementInvoicePaymentTerm
          }
          {...formik.getFieldProps("commencementInvoicePaymentTerm")}
        />

        <Input
          type="number"
          label="Early Bird Payment Terms"
          name="earlyBirdPaymentTerm"
          onChange={formik.handleChange}
          value={formik.values.earlyBirdPaymentTerm}
          error={
            Boolean(
              formik.touched.earlyBirdPaymentTerm &&
                formik.errors.earlyBirdPaymentTerm
            ) && formik.errors.earlyBirdPaymentTerm
          }
          {...formik.getFieldProps("earlyBirdPaymentTerm")}
        />
      </div>

      <div className="grid grid-cols-2 gap-4">
        <Input
          type="number"
          label="Early Bird Fee Limit"
          name="earlyBirdFeeLimit"
          onChange={formik.handleChange}
          value={formik.values.earlyBirdFeeLimit}
          error={
            Boolean(
              formik.touched.earlyBirdFeeLimit &&
                formik.errors.earlyBirdFeeLimit
            ) && formik.errors.earlyBirdFeeLimit
          }
          {...formik.getFieldProps("earlyBirdFeeLimit")}
        />

        <Input
          type="number"
          label="Revision Expiry Terms"
          name="defaultRevisionExpiryTerms"
          onChange={formik.handleChange}
          value={formik.values.defaultRevisionExpiryTerms}
          error={
            Boolean(
              formik.touched.defaultRevisionExpiryTerms &&
                formik.errors.defaultRevisionExpiryTerms
            ) && formik.errors.defaultRevisionExpiryTerms
          }
          {...formik.getFieldProps("defaultRevisionExpiryTerms")}
        />
      </div>

      <Textarea
        label="Street Address"
        name="streetAddress"
        onChange={formik.handleChange}
        value={formik.values.streetAddress}
        error={
          Boolean(
            formik.touched.streetAddress && formik.errors.streetAddress
          ) && formik.errors.streetAddress
        }
        {...formik.getFieldProps("streetAddress")}
      />

      <div className="grid grid-cols-2 gap-4">
        <Input
          label="City/Suburb"
          name="suburb"
          onChange={formik.handleChange}
          value={formik.values.suburb}
          error={
            Boolean(formik.touched.suburb && formik.errors.suburb) &&
            formik.errors.suburb
          }
          {...formik.getFieldProps("suburb")}
        />

        <Input
          label="State"
          name="state"
          onChange={formik.handleChange}
          value={formik.values.state}
          error={
            Boolean(formik.touched.state && formik.errors.state) &&
            formik.errors.state
          }
          {...formik.getFieldProps("state")}
        />
      </div>

      <div className="grid grid-cols-2 gap-4">
        <Input
          label="Postcode"
          name="postcode"
          onChange={formik.handleChange}
          value={formik.values.postcode}
          error={
            Boolean(formik.touched.postcode && formik.errors.postcode) &&
            formik.errors.postcode
          }
          {...formik.getFieldProps("postcode")}
        />

        <Input
          label="Phone Number"
          name="phoneNumber"
          onChange={formik.handleChange}
          value={formik.values.phoneNumber}
          error={
            Boolean(formik.touched.phoneNumber && formik.errors.phoneNumber) &&
            formik.errors.phoneNumber
          }
          {...formik.getFieldProps("phoneNumber")}
        />
      </div>

      <div className="grid grid-cols-2 gap-4">
        <Select
          label="Location"
          name="location"
          id="location"
          placeholder="Select a Location"
          options={locationList}
          value={locationList.find(
            location => location.value === formik.values.locationId
          )}
          required={true}
          error={
            Boolean(formik.touched.locationId && formik.errors.locationId) &&
            formik.errors.locationId
          }
          onChange={async opt => {
            formik.setFieldValue("location", opt.label);
            await formik.setFieldValue("locationId", opt.value);
            formik.setFieldTouched("locationId", true);
            await formik.setFieldValue("locationTag", opt.tag);

            if (["AU", "UK"].includes(opt.tag)) {
              setTaxTypeAlertOpen(true);
            }
          }}
        />

        <Select
          label="Currency"
          name="currency"
          id="currency"
          placeholder="Select a Currency"
          options={dropDownListGenerator(currencyList)}
          value={dropDownListGenerator(currencyList).find(
            currency => currency.value === formik.values.currencyId
          )}
          required={true}
          error={
            Boolean(formik.touched.currencyId && formik.errors.currencyId) &&
            formik.errors.currencyId
          }
          onChange={async opt => {
            await formik.setFieldValue("currencyId", opt.value);
            await formik.setFieldTouched("currencyId", true);
          }}
        />

        <Input
          label="Serial"
          name="serial"
          onChange={formik.handleChange}
          value={formik.values.serial}
          error={
            Boolean(formik.touched.serial && formik.errors.serial) &&
            formik.errors.serial
          }
          {...formik.getFieldProps("serial")}
        />
      </div>
      <Switch
        label="Display In Template"
        checked={displayInTemplate}
        onChange={() => {
          setDisplayInTemplate(!displayInTemplate);
          formik.setFieldValue("displayInTemplate", !displayInTemplate);
        }}
      />
    </div>
  );
};

export default CompanyDetail;

export const jobWithNameNumber = job => {
  return `${job?.name} (${job?.serialNumber})`;
};

export const getRevisionId = revisions => {
  const approvedRevision = revisions.find(item => item.approved);

  if (approvedRevision) {
    return approvedRevision.id;
  } else {
    const partiallyApprovedRevision = revisions.find(
      item => item.partiallyApproved
    );

    if (partiallyApprovedRevision) {
      return partiallyApprovedRevision.id;
    } else {
      return revisions.slice(-1)[0]?.id;
    }
  }
};

export const getApprovedRevisionId = revisions => {
  return revisions.find(item => item.approved || item.partiallyApproved)?.id;
};

export const getFinalInvoice = data => {
  return data.find(item => item.final);
};

export const getCommencementInvoice = data => {
  return data.find(item => !item.final);
};

export const filterEstimateItems = data => {
  return data.filter(item => item.kind === "estimate").sort((a,b) => a.position - b.position);
};

export const filterExtraItems = data => {
  return data.filter(item => item.kind === "extra").sort((a,b) => a.position - b.position);
};

export const filterEstimateListItems = data => {
  return data.filter(item => item.feeType !== "agency_service_fee").sort((a,b) => a.position - b.position);
};

export const filterAgencyItems = data => {
  return data.filter(item => item.feeType === "agency_service_fee");
};

export const filterCreativeItems = data => {
  return data.filter(item => item.feeType === "artwork");
};

export const filterUsageItems = data => {
  return data.filter(item => item.feeType === "usage");
};

const getArtistList = estimateRevisionItems => {
  return estimateRevisionItems
    .map(item => {
      if (item.artistId) {
        return {
          artistId: item.artistId,
          name: item.artistName,
          taxStatus: item.artistTaxStatus,
          fee: item.artistFee,
        };
      } else {
        return item.artistMarkupPercentages.map(item => {
          return {
            artistId: item.artistId,
            name: item.artistName,
            taxStatus: item.artistTaxStatus,
            fee: item.artistFee,
          };
        });
      }
    })
    .flat();
};

export const totalAmountGroupedByArtist = () => {
  let artists = getArtistList();

  let amountGroupedByArtistId = artists.reduce(
    (artists, { artistId, name, fee, taxStatus }) => {
      if (!artists[artistId]) artists[artistId] = [];
      artists[artistId].push({
        artistId: artistId,
        name: name,
        taxStatus: taxStatus,
        fee: fee,
      });
      return artists;
    },
    {}
  );

  return Object.keys(amountGroupedByArtistId).map(artistId => {
    const sum = amountGroupedByArtistId[artistId].reduce(
      (partialSum, data) => partialSum + Number(data.fee),
      0
    );

    return {
      name: amountGroupedByArtistId[artistId][0].name,
      artistId: artistId,
      fee: sum,
      taxStatus: amountGroupedByArtistId[artistId][0].taxStatus,
    };
  });
};

import axios from "axios";

export const basecampLogin = () => {
  return axios.get(`/api/v1/settings/basecamp_sessions/new`);
};

export const basecampLogout = () => {
  return axios.delete(`/api/v1/settings/basecamp_sessions/1`);
};

export const getBasecampTokenPresence = () => {
  return axios.get(`/api/v1/settings/basecamp_sessions/1`);
};

import React, { useState, useRef, useEffect } from "react";
import { Down, Search } from "@bigbinary/neeto-icons";
import {
  Input,
  Dropdown,
  Button,
  Avatar,
  Typography,
} from "@bigbinary/neetoui";
import * as dayjs from "dayjs";
import { useUserState } from "contexts/user";
import authenticationApi from "apis/authentication";
import { getNotifications } from "apis/dashboards";
import { toast } from "react-toastify";
import { resetAuthTokens } from "apis/axios";
import { useAuthDispatch } from "contexts/auth";
import { showToastrError } from "common";

import LocationDropdown from "../LocationDropdown";
import NewJob from "../Jobs/NewJob";
import NotificationDropdown from "./NotificationDropdown";

const Header = ({ location, setLocation, searchTerm, setSearchTerm }) => {
  const { Menu, MenuItem } = Dropdown;
  const initialFocusRef = useRef(null);
  const [newJobPane, setNewJobPane] = useState(false);
  const authDispatch = useAuthDispatch();
  const { user } = useUserState();
  const [notificationList, setNotificationList] = useState([]);
  const [notificationListLoad, setNotificationListLoad] = useState(true);
  const [unseenNotificationCount, setUnseenNotificationCount] = useState();

  useEffect(() => {
    loadNotificationsResponse();
  }, []);

  const handleLogout = async () => {
    try {
      const {
        data: { root_domain },
      } = await authenticationApi.logout();
      authDispatch({ type: "LOGOUT" });
      resetAuthTokens();
      window.location.href = `${window.location.protocol}//auth.${root_domain}/login`;
    } catch (error) {
      toast.error(error, { theme: "colored" });
    }
  };

  const handleLogoutFromAllDevices = async () => {
    try {
      const {
        data: { root_domain },
      } = await authenticationApi.logout_all();
      authDispatch({ type: "LOGOUT" });
      resetAuthTokens();
      window.location.href = `${window.location.protocol}//auth.${root_domain}/login`;
    } catch (error) {
      toast.error(error, { theme: "colored" });
    }
  };

  const loadNotificationsResponse = async () => {
    try {
      setNotificationListLoad(true);
      const response = await getNotifications();
      setNotificationList(response.data.notifications);
      setUnseenNotificationCount(response.data.notificationCount);
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setNotificationListLoad(false);
    }
  };

  const getGreetings = () => {
    const hours = dayjs().hour();

    if (hours < 12) {
      return "Good morning";
    } else if (hours >= 12 && hours <= 17) {
      return "Good afternoon";
    } else if (hours >= 17 && hours <= 24) {
      return "Good evening";
    }
  };

  return (
    <>
      <div className="flex flex-col w-full px-6 shadow neeto-ui-bg-white">
        <div className="flex flex-shrink-0 h-16 border-b neeto-ui-border-gray-200">
          <div className="flex items-center justify-between flex-1">
            <div className="flex flex-1">
              <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                <div
                  className="absolute inset-y-0 left-0 flex items-center pointer-events-none"
                  aria-hidden="true"
                >
                  <Search size={20} aria-hidden="true" />
                </div>
                <Input
                  nakedInput
                  className="block w-full h-full py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 border-transparent focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                  placeholder="Search Job, Invoice or RCI"
                  type="search"
                  value={searchTerm}
                  onChange={e => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
            <div className="flex items-center ml-4 space-x-3">
              <NotificationDropdown
                notificationListLoad={notificationListLoad}
                loadNotificationsResponse={loadNotificationsResponse}
                notificationList={notificationList}
                unseenNotificationCount={unseenNotificationCount}
              />
              <Dropdown
                customTarget={
                  <div className="flex items-center cursor-pointer">
                    <Avatar
                      size="medium"
                      user={{
                        name: user?.full_name,
                        imageUrl: user?.hero_small_image_url,
                      }}
                    />
                    <span className="ml-3 text-sm font-medium text-gray-700">
                      {user?.first_name}
                    </span>
                    <Down
                      className="flex-shrink-0 ml-1 text-gray-400 lg:block"
                      size={20}
                    />
                  </div>
                }
              >
                <Menu>
                  <MenuItem.Button onClick={() => handleLogout()}>
                    <div className="flex items-center justify-center space-x-2">
                      <Typography
                        style="body2"
                        weight="normal"
                        component="span"
                      >
                        Logout
                      </Typography>
                    </div>
                  </MenuItem.Button>
                  <MenuItem.Button onClick={() => handleLogoutFromAllDevices()}>
                    <div className="flex items-center justify-center space-x-2">
                      <Typography
                        style="body2"
                        weight="normal"
                        component="span"
                      >
                        Logout from all devices
                      </Typography>
                    </div>
                  </MenuItem.Button>
                </Menu>
              </Dropdown>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between py-6">
          <div className="flex items-center space-x-3">
            <Avatar
              size="extraLarge"
              user={{
                name: user?.full_name,
                imageUrl: user?.hero_small_image_url,
              }}
            />
            <Typography
              style="h2"
              weight="bold"
              className="leading-7 text-gray-900"
            >
              {getGreetings()}, {user?.first_name}!
            </Typography>
          </div>
          <div className="flex items-center justify-end space-x-3">
            <LocationDropdown location={location} setLocation={setLocation} />
            <Button label="Add Job" onClick={() => setNewJobPane(true)} />
          </div>
        </div>
      </div>

      <NewJob
        isOpen={newJobPane}
        onClose={() => setNewJobPane(false)}
        initialFocusRef={initialFocusRef}
      />
    </>
  );
};

export default Header;

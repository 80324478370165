import React, { useState } from "react";
import { Tab } from "@bigbinary/neetoui";
import ThreadForward from "./ThreadForward";
import Messages from "./Messages";
import MessageList from "./MessageList";
import { THREADS_LIST } from "./constants";

const renderTabContents = (
  Tab,
  setNewMessage,
  messageThreads,
  jobId,
  jobDetail,
  formik,
  activeThread,
  setActiveThred,
) => {
  switch (Tab.label) {
    case "Messages":
      return (
        <Messages
          setNewMessage={setNewMessage}
          messageThreads={messageThreads}
          activeThread={activeThread}
          setActiveThred={setActiveThred}
        />
      );
    case "Forward":
      return (
        <ThreadForward
          jobId={jobId}
          activeThread={activeThread}
          setActiveThred={setActiveThred}
        />
      )
  }
};

const MessageThreads = ({
  setNewMessage,
  messageThreads,
  jobId,
  jobDetail,
  formik,
  activeThread,
  setActiveThred,
  loadMessageThreadsResponse,
}) => {
  const [currentTab, setCurrentTab] = useState(THREADS_LIST.Messages);
  const [threadDetail, setThreadDetail] = useState({});

  return (
    <div className="flex w-full h-full">
      <div className="flex flex-col w-3/12 neeto-ui-bg-gray-100">
        <div className="w-full overflow-auto">
          <div className="flex items-center">
            <Tab className="px-6 border-b">
              {Object.keys(THREADS_LIST).map((key, index) => {
                const tab = THREADS_LIST[key];
                const { label, icon } = tab;
                return (
                  <Tab.Item
                    icon={icon}
                    key={index}
                    className="px-3 py-4"
                    onClick={() => {
                      setThreadDetail();
                      setActiveThred();
                      setCurrentTab(tab);
                    }}
                    active={currentTab === tab}
                  >
                    {label}
                  </Tab.Item>
                );
              })}
            </Tab>
          </div>
          {renderTabContents(
            currentTab,
            setNewMessage,
            messageThreads,
            jobId,
            jobDetail,
            formik,
            activeThread,
            setActiveThred,
            loadMessageThreadsResponse,
          )}
        </div>
      </div>

      <div className="flex flex-col w-9/12">
        <MessageList
          threadId={activeThread}
          jobId={jobId}
          jobDetail={jobDetail}
          loadMessageThreadsResponse={loadMessageThreadsResponse}
          setNewMessage={setNewMessage}
          formik={formik}
          threadDetail={threadDetail}
          setThreadDetail={setThreadDetail}
          setCurrentTab={setCurrentTab}
          setActiveThred={setActiveThred}
        />
      </div>
    </div>
  );
};

export default MessageThreads;

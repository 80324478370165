import axios from "axios";

export const getJobAttachments = jobId => {
  return axios.get(`/api/v1/job_module/contracts/jobs/${jobId}/job_attachments`);
};

export const createJobAttachment = (jobId, payload) => {
  return axios.post(`/api/v1/job_module/contracts/jobs/${jobId}/job_attachments`, payload);
};

export const updateJobAttachment = (jobId, id, payload) => {
  return axios.put(`/api/v1/job_module/contracts/jobs/${jobId}/job_attachments/${id}`, payload);
};

export const destroyJobAttachment = (jobId, id) => {
  return axios.delete(`/api/v1/job_module/contracts/jobs/${jobId}/job_attachments/${id}`);
};

export const getJob = jobId => {
  return axios.get(`/api/v1/job_module/contracts/jobs/${jobId}`);
};

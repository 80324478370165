import React, { useState, useEffect } from "react";
import { Search, MenuHorizontal } from "@bigbinary/neeto-icons";
import {
  Typography,
  Input,
  Tag,
  Table,
  Spinner,
  Button,
  Dropdown,
  Modal,
  Textarea,
  Radio,
  Alert,
  Select,
  DatePicker,
} from "@bigbinary/neetoui";
import { SubHeader } from "@bigbinary/neetoui/layouts";
import { useFormik } from "formik";
import * as dayjs from "dayjs";
import * as R from "ramda";
import { showToastrError } from "common";
import { dropDownListGenerator, getRandomNotFoundImage } from "common/helper";
import useDebounce from "common/debounce";
import { humanize } from "common/helper";
import { DEFAULT_PAGE_SIZE } from "common/constants";
import {
  getContactActivityLogs,
  createContactActivityLog,
  getContactActivityLog,
  destroyContactActivityLog,
  updateContactActivityLog,
} from "apis/contacts/contact_activity_logs";
import { createCrmActionTag } from "apis/settings/crm_action_tags";
import { getActiveCrmActionTags } from "apis/settings/crm_action_tags";
import EmptyState from "components/Common/EmptyState";
import {
  ACTIVITY_LOG_VALIDATION_SCHEMA,
  INITIAL_ACTIVITY_LOG_VALUE,
} from "./constants";

const History = ({ contactDetail }) => {
  const [searchParams, setSearchParams] = useState();
  const debouncedSearchTerm = useDebounce(searchParams, 500);
  const [activityLogList, setActivityLogList] = useState([]);
  const [activityLogListLoading, setActivityLogListLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [sortProps, setSortProps] = useState();
  const [entryFormState, setEntryFormState] = useState(false);
  const [crmActionTagList, setCrmActionTagList] = useState([]);
  const [activityLogDetail, setActivityLogDetail] = useState("");
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [initial, setInitial] = useState(true);
  const { Menu, MenuItem } = Dropdown;
  const [emptyImage, setEmptyImage] = useState();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: activityLogDetail
      ? activityLogDetail
      : INITIAL_ACTIVITY_LOG_VALUE,
    validationSchema: ACTIVITY_LOG_VALIDATION_SCHEMA,
    onSubmit: () => {
      if (activityLogDetail) {
        onUpdate();
      } else {
        onSubmit();
      }
    },
  });

  useEffect(() => {
    setEmptyImage(getRandomNotFoundImage());
  }, [])

  useEffect(() => {
    if (contactDetail.id) {
      loadContactActivityListResponse();
    }
  }, [contactDetail.id]);

  useEffect(() => {
    if (entryFormState) {
      loadCrmActionTagListResponse();
    }
  }, [entryFormState]);

  useEffect(() => {
    if (pageIndex && !initial) {
      loadContactActivityListResponse();
    }
  }, [pageIndex]);

  useEffect(() => {
    if (sortProps && !initial) {
      loadContactActivityListResponse();
    }
  }, [sortProps]);

  useEffect(() => {
    if (debouncedSearchTerm != undefined) {
      loadContactActivityListResponse(searchParams);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (!activityLogDetail.id) {
      if (formik.values.status === "open") {
        formik.setFieldValue("closureDate", null);
      } else if (formik.values.status === "completed") {
        formik.setFieldValue("issueDate", null);
      } else {
        formik.setFieldValue("issueDate", null);
        formik.setFieldValue("closureDate", null);
      }
    }
  }, [formik.values.status])

  const createCrmActionTagEntry = async tagName => {
    try {
      const response = await createCrmActionTag({
        crm_action_tag: {
          name: tagName,
          status: "open",
        },
      });
      loadCrmActionTagListResponse();
      formik.setFieldValue("crmActionTagId", response.data.id);
      formik.setFieldValue("status", response.data.status);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadContactActivityListResponse = async () => {
    try {
      const response = await getContactActivityLogs(
        contactDetail.id,
        searchParams,
        sortProps,
        pageIndex || 1,
        DEFAULT_PAGE_SIZE
      );
      setActivityLogList(response.data.contactActivityLogs);
      setTotalRecords(response.data.totalRecords);
      setActivityLogListLoading(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadCrmActionTagListResponse = async () => {
    try {
      const crmActionTagListResponse = await getActiveCrmActionTags();
      setCrmActionTagList(crmActionTagListResponse.data.crmActionTags || []);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadActivityLogDetailResponse = async activityLogId => {
    try {
      const activityLogDetailResponse = await getContactActivityLog(
        contactDetail.id,
        activityLogId
      );
      setActivityLogDetail(activityLogDetailResponse.data.contactActivityLog);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const onSubmit = async () => {
    try {
      await createContactActivityLog(contactDetail.id, {
        contact_activity_log: { ...formik.values },
      });
      setEntryFormState(false);
      loadContactActivityListResponse();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const onUpdate = async () => {
    try {
      await updateContactActivityLog(contactDetail.id, activityLogDetail.id, {
        contact_activity_log: { ...formik.values },
      });
      setEntryFormState(false);
      loadContactActivityListResponse();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const onStatusUpdate = async (activityId, payload) => {
    try {
      await updateContactActivityLog(contactDetail.id, activityId, {
        contact_activity_log: { ...payload },
      });
      loadContactActivityListResponse();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const onDelete = async () => {
    try {
      await destroyContactActivityLog(contactDetail.id, activityLogDetail.id);
      setDeleteAlertOpen(false);
      loadContactActivityListResponse();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const COLUMN_DATA = [
    {
      title: "Date",
      dataIndex: "latestDate",
      key: "latestDate",
      render: latestDate => dayjs(latestDate).format("DD/MM/YYYY"),
    },
    {
      title: "Action Tag",
      dataIndex: "crmActionTagName",
      key: "crmActionTagName",
      sorter: true,
      field: "crm_action_tag_name",
      render: crmActionTagName => <>{crmActionTagName || "N/A"}</>,
    },
    {
      title: "Note",
      dataIndex: "note",
      key: "note",
      render: note => <>{note || "N/A"}</>,
    },
    {
      title: "Created By",
      dataIndex: "createrName",
      key: "createrName",
      sorter: true,
      field: "created_by_first_name",
      render: (_, rowData) => {
        return (
          <>
            {rowData.createdById ? (
              <Button
                style="link"
                to={`/staff/${rowData.createdById}`}
                label={rowData.createrName}
              />
            ) : (
              <>N/A</>
            )}
          </>
        );
      },
    },
    {
      title: "Job",
      dataIndex: "resourceName",
      key: "resourceName",
      render: (_, rowData) => {
        return (
          <>
            {rowData.resourceId ? (
              <Button
                style="link"
                to={`/jobs/${rowData.resourceId}/overview`}
                label={rowData.resourceName}
              />
            ) : (
              <>N/A</>
            )}
          </>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: true,
      field: "status",
      render: (status, rowData) => {
        return (
          <Dropdown
            autoWidth
            closeOnSelect
            buttonStyle="text"
            buttonProps={{
              className: "p-0",
            }}
            strategy="fixed"
            appendTo={() => document.body}
            label={
              <Tag
                type="solid"
                label={humanize(status)}
                style={status === "open" ? "danger" : "success"}
              />
            }
          >
            <Menu>
              <MenuItem.Button
                onClick={() => {
                  onStatusUpdate(rowData.id, {
                    status: "open",
                    issueDate: dayjs().format("YYYY-MM-DD"),
                    closureDate: null,
                  });
                }}
              >
                Open
              </MenuItem.Button>
              <MenuItem.Button
                onClick={() => {
                  onStatusUpdate(rowData.id, {
                    status: "completed",
                    closureDate: dayjs().format("YYYY-MM-DD"),
                  });
                }}
              >
                Completed
              </MenuItem.Button>
            </Menu>
          </Dropdown>
        );
      },
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      fixed: "right",
      render: (_, rowData) => (
        <Dropdown
          autoWidth
          closeOnSelect
          buttonStyle="text"
          strategy="fixed"
          appendTo={() => document.body}
          icon={MenuHorizontal}
        >
          <Menu>
            <MenuItem.Button
              onClick={() => {
                loadActivityLogDetailResponse(rowData.id);
                setEntryFormState(true);
              }}
            >
              Edit
            </MenuItem.Button>
            <MenuItem.Button
              style="danger"
              onClick={() => {
                setActivityLogDetail(rowData);
                setDeleteAlertOpen(true);
              }}
            >
              Delete
            </MenuItem.Button>
          </Menu>
        </Dropdown>
      ),
    },
  ];

  const handleTableChange = (_, __, sorter) => {
    setInitial(false);
    setSortProps(sorter);
  };

  if (activityLogListLoading) {
    return (
      <div
        className="flex items-center justify-center w-full"
        style={{ height: "calc(100vh - 134px)" }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div
      className="flex flex-col overflow-hidden"
      style={{ height: "calc(100vh - 134px)" }}
    >
      <SubHeader
        className="px-6 pt-4"
        leftActionBlock={
          <Typography style="h4" component="h4" weight="semibold">
            {totalRecords > 1 ? `${totalRecords} Logs` : `${totalRecords} Log`}
          </Typography>
        }
        rightActionBlock={
          <div className="flex items-center space-x-3">
            <Input
              size="small"
              type="search"
              placeholder="Search"
              className="neeto-ui-header__search-input"
              prefix={<Search size={16} />}
              value={searchParams}
              onChange={e => setSearchParams(e.target.value)}
              clear={() => setSearchParams("")}
            />
            <Button
              size="small"
              label="Add Log"
              className="px-3"
              onClick={() => {
                formik.resetForm();
                setActivityLogDetail("");
                setEntryFormState(true);
              }}
            />
          </div>
        }
      />
      <div
        className="px-4 mb-6"
        style={{ height: "calc(100vh - 270px)" }}
      >
        {!R.isEmpty(activityLogList) ? (
          <Table
            columnData={COLUMN_DATA}
            rowData={activityLogList}
            fixedHeight
            totalCount={totalRecords}
            currentPageNumber={pageIndex}
            defaultPageSize={pageSize}
            handlePageChange={(page, pageSize) => {
              setInitial(false);
              setPageIndex(page);
              setPageSize(pageSize);
            }}
            onChange={(pagination, filters, sorter) =>
              handleTableChange(pagination, filters, sorter)
            }
          />
        ) : (
          <EmptyState image={emptyImage} title="No Activity Logs Found" />
        )}
      </div>

      <Modal isOpen={entryFormState} onClose={() => setEntryFormState(false)}>
        <Modal.Header>
          <Typography style="h2" weight="semibold">
            {activityLogDetail ? "Edit Log" : "Add Log"}
          </Typography>
        </Modal.Header>

        <Modal.Body>
          <div className="flex flex-col space-y-6">
            <Select
              isClearable
              isSearchable
              isCreateable
              label="CRM Action Tag"
              name="crmActionTagId"
              id="crmActionTagId"
              placeholder="Select CRM Action Tag"
              value={dropDownListGenerator(crmActionTagList).find(
                crmActionTag =>
                  crmActionTag.value === formik.values.crmActionTagId
              )}
              error={
                Boolean(
                  formik.touched.crmActionTagId && formik.errors.crmActionTagId
                ) && formik.errors.crmActionTagId
              }
              options={dropDownListGenerator(crmActionTagList)}
              onChange={option => {
                if (option) {
                  formik.setFieldValue("crmActionTagId", option.value);
                  formik.setFieldValue("status", option.status);
                } else {
                  formik.setFieldValue("crmActionTagId", null);
                }

                formik.setFieldTouched("crmActionTagId", true);
              }}
              onCreateOption={inputValue => {
                createCrmActionTagEntry(inputValue);
              }}
            />

            <Radio label="Status">
              <Radio.Item
                label="Open"
                name="status"
                checked={formik.values.status === "open"}
                onChange={() => formik.setFieldValue("status", "open")}
              />
              <Radio.Item
                label="Completed"
                name="status"
                checked={formik.values.status === "completed"}
                onChange={() => formik.setFieldValue("status", "completed")}
              />
            </Radio>

            {formik.values.status === "open" && (
              <DatePicker
                label="Issue Date"
                name="issueDate"
                dateFormat="DD/MM/YYYY"
                allowClear={false}
                disabledDate={current =>
                  current && current.valueOf() > dayjs().toDate()
                }
                value={formik.values.issueDate
                  ? dayjs(formik.values.issueDate)
                  : null}
                onChange={date => {
                  formik.setFieldValue("issueDate", date.format("YYYY-MM-DD"));
                }}
                getPopupContainer={triggerNode => triggerNode.parentNode}
              />
            )}

            {formik.values.status === "completed" && (
              <DatePicker
                label="Closure Date"
                name="closureDate"
                dateFormat="DD/MM/YYYY"
                allowClear={false}
                value={formik.values.closureDate
                  ? dayjs(formik.values.closureDate)
                  : null}
                onChange={date => {
                  formik.setFieldValue("closureDate", date.format("YYYY-MM-DD"));
                }}
                getPopupContainer={triggerNode => triggerNode.parentNode}
              />
            )}

            <Textarea
              label="Notes"
              name="notes"
              value={formik.values.note}
              onChange={formik.handleChange}
              error={
                Boolean(formik.touched.note && formik.errors.note) &&
                formik.errors.note
              }
              row="4"
              {...formik.getFieldProps("note")}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="space-x-2">
          <Button
            label="Save Changes"
            onClick={() => {
              formik.handleSubmit();
            }}
          />
          <Button
            style="text"
            label="Cancel"
            onClick={() => {
              setEntryFormState(false);
            }}
          />
        </Modal.Footer>
      </Modal>

      <Alert
        isOpen={deleteAlertOpen}
        title="Delete Log"
        message="Are you sure you want to delete this log?"
        onClose={() => setDeleteAlertOpen(false)}
        onSubmit={() => {
          onDelete(activityLogDetail.id);
          setDeleteAlertOpen(false);
        }}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, delete"
      />
    </div>
  );
};

export default History;

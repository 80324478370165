import React, { useState, useEffect } from "react";
import { Tooltip } from "@bigbinary/neetoui";
import { Copy } from "@bigbinary/neeto-icons";
import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Footer } from "../../Shared";

const Payment = ({
  jobDetail,
  companyDetails,
  footerLogo,
  illustratorAuthorLink,
  illustratorAuthorName,
  bankData,
  paymentTerm,
}) => {
  const [copyEmail, setCopyEmail] = useState(false);

  useEffect(() => {
    if (copyEmail) {
      toast.info("Copied to Clipboard!", {
        position: "bottom-left",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setCopyEmail(false);
    }
  }, [copyEmail]);

  return (
    <div className="flex flex-col items-start justify-between w-full pt-12 pb-12 lg:pb-24 lg:flex-row">
      <div
        className="flex flex-col items-start justify-start w-full pr-0 lg:pr-12 lg:w-1/3 lg:h-full"
        style={{ fontFamily: "Modern Gothic" }}
      >
        <div className="flex flex-col items-start justify-between h-full space-y-20">
          <div className="flex flex-col items-start justify-start mb-10 lg:mb-0">
            <h2 className="text-xl font-medium">Payment</h2>
            <p
              className="mt-6"
              dangerouslySetInnerHTML={{
                __html: paymentTerm?.split("\n").join("<br />"),
              }}
            ></p>
          </div>
          <div className="flex-col items-start justify-end hidden space-y-6 lg:flex">
            <Footer
              jobDetail={jobDetail}
              companyDetails={companyDetails}
              footerLogo={footerLogo}
              illustratorAuthorLink={illustratorAuthorLink}
              illustratorAuthorName={illustratorAuthorName}
            />
          </div>
        </div>
      </div>
      <div
        className="flex flex-col items-center justify-between w-full h-full lg:items-start lg:w-2/3"
        style={{ fontFamily: "Modern Gothic" }}
      >
        <div className="flex flex-col items-center w-full divide-y divide-black lg:items-start">
          {bankData.map((item, index) => (
            <div
              key={index}
              className="flex items-center justify-between w-full py-2"
            >
              <div className="flex flex-col items-start justify-center space-y-2 text-xl font-normal leading-6">
                <span>{item.title}</span>
                <span>{item.value}</span>
              </div>
              <Tooltip content="Copy to clipboard" position="left">
                <div className="cursor-pointer">
                  <CopyToClipboard
                    text={item.value}
                    onCopy={() => setCopyEmail(true)}
                  >
                    <Copy size={24} color="#000000" />
                  </CopyToClipboard>
                </div>
              </Tooltip>
            </div>
          ))}
        </div>
        <div className="flex flex-col items-center justify-end w-full pt-12 lg:hidden">
          <Footer
            jobDetail={jobDetail}
            companyDetails={companyDetails}
            footerLogo={footerLogo}
            illustratorAuthorLink={illustratorAuthorLink}
            illustratorAuthorName={illustratorAuthorName}
          />
        </div>
      </div>
    </div>
  );
};

export default Payment;

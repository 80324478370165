export const POST_PRODUCTION_TABS = [
  "Final Artwork Requests",
  "Finished Art Filed",
  "Social",
  "Folio",
  "Showpiece",
  "Challenge Coin",
  "Claim Royalty",
  "License Tracker",
];

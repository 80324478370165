import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import {
  Settings,
  Edit,
  Delete,
  Copy,
  Warning,
  CloseCircle,
  Info,
} from "@bigbinary/neeto-icons";
import {
  Button,
  Alert,
  Modal,
  Typography,
  Callout,
  Spinner,
} from "@bigbinary/neetoui";
import { Container, Header } from "@bigbinary/neetoui/layouts";
import { showToastrError } from "common";
import { useParams, useHistory } from "react-router-dom";
import { getJob } from "apis/jobs/details";
import { destroyJob, bulkClone } from "apis/jobs/jobs";
import { getBasecampTokenPresence } from "apis/settings/basecamp_sessions";
import { jobDetailsPushToBasecamp } from "apis/jobs/push_to_basecamps";
import { getEstimateRevisions } from "apis/jobs/estimate_revisions";
import { getInvoices } from "apis/jobs/invoices";
import { getRcis } from "apis/jobs/rcis";
import JobThumbnail from "./JobThumbnail";
import JobDetails from "./JobDetails";
import InvoiceDetails from "./InvoiceDetails";
import RciDetails from "./RciDetails";
import EstimateRevisions from "../../common/EstimateRevisions";
import EstimateSettingPane from "../../common/EstimateSettingPane";
import Tabs from "../Tabs";
import NewJob from "../../NewJob";
import { jobWithNameNumber, getRevisionId } from "../../common/helper";

const Details = () => {
  const { id } = useParams();
  const history = useHistory();
  const initialFocusRef = useRef(null);
  const [jobDetail, setJobDetail] = useState({});
  const [newJobPane, setNewJobPane] = useState(false);
  const [jobDetailLoad, setJobDetailLoad] = useState(true);
  const [estimateRevisionList, setestimateRevisionList] = useState([]);
  const [estimateRevisionListLoad, setEstimateRevisionListLoad] = useState(
    true
  );
  const [invoiceList, setInvoiceList] = useState([]);
  const [invoiceListLoad, setInvoiceListLoad] = useState(true);
  const [rciList, setRciList] = useState([]);
  const [rciListLoad, setRciListLoad] = useState(true);
  const [estimateSettingsOpen, setEstimateSettingsOpen] = useState(false);
  const [basecampTokenStatusLoad, setBasecampTokenStatusLoad] = useState(true);
  const [basecampTokenStatus, setBasecampTokenStatus] = useState();
  const [basecampPushLoad, setBasecampPushLoad] = useState(false);
  const [confirmBasecampPush, setConfirmBasecampPush] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [cloneAlert, setCloneAlert] = useState(false);
  const [currentRevisionId, setCurrentRevisionId] = useState();

  useEffect(() => {
    if (id) {
      loadJobDetailResponse();
      loadEstimateRevisionListResponse();
      loadInvoiceListResponse();
      loadRciListResponse();
      getBasecampTokenStatus();
    }
  }, [id]);

  const getBasecampTokenStatus = async () => {
    try {
      setBasecampTokenStatusLoad(true);
      const { data } = await getBasecampTokenPresence();
      setBasecampTokenStatus(data.basecampSessionPresence);
      setBasecampTokenStatusLoad(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const loadJobDetailResponse = async () => {
    try {
      const response = await getJob(id);
      setJobDetail(response.data.job);
      setJobDetailLoad(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const loadEstimateRevisionListResponse = async () => {
    try {
      const response = await getEstimateRevisions(id);
      setestimateRevisionList(response.data.estimateRevisions);
      setEstimateRevisionListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const loadInvoiceListResponse = async () => {
    try {
      const response = await getInvoices(id);
      setInvoiceList(response.data.invoices);
      setInvoiceListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const loadRciListResponse = async () => {
    try {
      const response = await getRcis(id);
      setRciList(response.data.rcis);
      setRciListLoad(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const detailsPushToBasecampResponse = async jobId => {
    try {
      setBasecampPushLoad(true);
      const { data } = await jobDetailsPushToBasecamp(jobId);
      setConfirmBasecampPush(false);
      setBasecampPushLoad(false);
      toast.info(data.notice, {
        position: "bottom-left",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const onDelete = async () => {
    try {
      await destroyJob(id);
      history.push(`/jobs`);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const onClone = async () => {
    try {
      await bulkClone({ jobIds: [id] });
      history.push(`/jobs`);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  if (
    jobDetailLoad ||
    estimateRevisionListLoad ||
    invoiceListLoad ||
    rciListLoad ||
    basecampPushLoad ||
    basecampTokenStatusLoad
  ) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  const actionBlockComponent = () => {
    return (
      <div className="flex space-x-3">
        {basecampTokenStatus && (
          <Button
            style="secondary"
            label="Push to Basecamp"
            onClick={() => {
              setConfirmBasecampPush(true);
            }}
          />
        )}

        <Button
          style="secondary"
          icon={Settings}
          tooltipProps={{
            position: "bottom",
            content: "Estimate Settings",
          }}
          onClick={() => setEstimateSettingsOpen(true)}
        />
        <Button
          style="secondary"
          icon={Edit}
          tooltipProps={{
            position: "bottom",
            content: "Edit",
          }}
          onClick={() => {
            setNewJobPane(true);
          }}
        />

        <Button
          style="secondary"
          icon={Delete}
          tooltipProps={{
            position: "bottom",
            content: "Delete",
          }}
          onClick={() => setDeleteAlert(true)}
        />

        <Button
          style="secondary"
          icon={Copy}
          tooltipProps={{
            position: "bottom",
            content: "Clone",
          }}
          onClick={() => setCloneAlert(true)}
        />

        <Button
          label="Welcome Email"
          to={`/mailer/jobs/${jobDetail.id}/welcome`}
        />
      </div>
    );
  };

  return (
    <Container isHeaderFixed>
      <Header
        title={jobWithNameNumber(jobDetail)}
        breadcrumbs={[{ text: "Jobs", link: "/jobs" }]}
        actionBlock={actionBlockComponent()}
      />

      <Tabs jobId={id} revisionId={getRevisionId(estimateRevisionList)} />

      <div
        className="flex w-full overflow-hidden"
        style={{ height: "calc(100vh - 134px)" }}
      >
        <div className="w-8/12 p-6 space-y-4 overflow-y-scroll border-r neeto-ui-bg-gray-100 neeto-ui-border-gray-300">
          {jobDetail.cancellationReason && (
            <Callout style="danger" icon={CloseCircle}>
              <span>
                In <b>Graveyard</b> because of &ldquo;
                {jobDetail.cancellationReason}
                &rdquo;
              </span>
            </Callout>
          )}

          {basecampTokenStatus && (
            <Callout style="info" icon={Info}>
              Basecamp Logged in!
            </Callout>
          )}
          {jobDetail.note && (
            <Callout style="warning" icon={Warning}>
              <div dangerouslySetInnerHTML={{
                __html: jobDetail.note.split("\n").join("<br/>"),
              }}/>
            </Callout>
          )}
          <JobDetails
            jobDetail={jobDetail}
            loadJobDetailResponse={loadJobDetailResponse}
          />
          <InvoiceDetails
            invoiceList={invoiceList}
            rciList={rciList}
            loadInvoiceListResponse={loadInvoiceListResponse}
            jobDetail={jobDetail}
            loadJobDetailResponse={loadJobDetailResponse}
          />

          <RciDetails
            rciList={rciList}
            loadRciListResponse={loadRciListResponse}
            jobDetail={jobDetail}
          />
        </div>
        <div className="w-4/12 p-6 space-y-6 overflow-y-scroll neeto-ui-bg-white">
          <JobThumbnail
            jobDetail={jobDetail}
            loadJobDetailResponse={loadJobDetailResponse}
          />
          <EstimateRevisions
            estimateRevisionList={estimateRevisionList}
            loadEstimateRevisionListResponse={loadEstimateRevisionListResponse}
            jobDetail={jobDetail}
            invoiceList={invoiceList}
            loadJobDetailResponse={loadJobDetailResponse}
            page="overview"
            currentRevisionId={getRevisionId(estimateRevisionList)}
            setCurrentRevisionId={setCurrentRevisionId}
            loadRciList={loadRciListResponse}
          />
        </div>
      </div>

      <EstimateSettingPane
        estimateSettingsOpen={estimateSettingsOpen}
        setEstimateSettingsOpen={setEstimateSettingsOpen}
        jobDetail={jobDetail}
        loadJobDetailResponse={loadJobDetailResponse}
        invoiceList={invoiceList}
      />

      <Modal
        isOpen={confirmBasecampPush}
        onClose={() => setConfirmBasecampPush(false)}
      >
        <Modal.Header>
          <Typography style="h2" weight="semibold">
            Basecamp Push!
          </Typography>
        </Modal.Header>
        <Modal.Body>Are you sure you want to push this project to Basecmap?</Modal.Body>
        <Modal.Footer className="space-x-2">
          <Button
            label="Push!"
            onClick={() => detailsPushToBasecampResponse(jobDetail.id)}
          />
          <Button
            style="text"
            label="Cancel"
            onClick={() => setConfirmBasecampPush(false)}
          />
        </Modal.Footer>
      </Modal>

      <NewJob
        isOpen={newJobPane}
        jobId={id}
        onClose={() => setNewJobPane(false)}
        initialFocusRef={initialFocusRef}
        setNewJobPane={setNewJobPane}
        detailsLoad={loadJobDetailResponse}
      />

      <Alert
        isOpen={deleteAlert}
        title="Delete Job"
        message="Are you sure you want to delete this job?"
        onClose={() => setDeleteAlert(false)}
        onSubmit={() => onDelete()}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, delete"
      />

      <Alert
        isOpen={cloneAlert}
        title="Clone Job"
        message="Are you sure you want to clone this job?"
        onClose={() => setCloneAlert(false)}
        onSubmit={() => onClone()}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, clone"
      />
    </Container>
  );
};

export default Details;

import React from "react";

const Overview = ({
  jobDetail,
  projectScopes,
  deliveryScopes,
  revision,
  timingList,
  artist,
  emailBackground,
}) => {
  const companyDetail = jobDetail?.companyDetail;

  const setResourceForDetails = name => {
    switch (name) {
      case "[artist_salutation]":
        return artist?.salutation || artist?.firstName || artist?.companyName;
      case "[artist_first_name]":
        return artist?.firstName;
      case "[artist_company_name]":
        return artist?.companyName;
      case "[staff_first_name]":
        return jobDetail.staffFirstName;
      case "[company_name]":
        return jobDetail.companyDetail.name;
      case "[job_serial_number]":
        return jobDetail.serialNumber;
      case "[job_name]":
        return jobDetail.name;
    }
  };

  const headerDetails = () => {
    const detailComponents = emailBackground.headerDetails?.split("&lt;&gt;");
    let details = "";

    detailComponents?.map(component => {
      if (component.includes("[")) {
        details += setResourceForDetails(component);
      } else {
        details += component;
      }
    });

    return details;
  };

  return (
    <div className="flex flex-col items-start justify-between w-full py-12 lg:flex-row">
      <div
        className="flex flex-col items-start justify-start w-full h-full lg:w-1/3"
        style={{ fontFamily: "Modern Gothic" }}
      >
        <div className="flex flex-col items-start justify-start">
          <h2 className="mb-8 text-xl font-medium lg:mb-0">Project Overview</h2>
        </div>
      </div>
      <div className="flex justify-end w-full h-full lg:w-2/3">
        <div className="relative flex flex-col items-end justify-start w-full h-full">
          <div className="grid w-full grid-cols-2 gap-2 lg:gap-6">
            <div
              className="flex flex-col justify-start text-sm font-light divide-y divide-black"
              style={{ fontFamily: "Modern Gothic Mono" }}
            >
              <span className="mb-2">To</span>
              <div className="flex flex-col pt-4">
                {artist?.fullName}
                <br />
                <p
                  dangerouslySetInnerHTML={{
                    __html: artist?.streetAddress?.split("\n").join("<br />"),
                  }}
                ></p>
                <p>
                  {[artist?.suburb, artist?.state, artist?.postcode]
                    .filter(el => el !== null)
                    .join(", ")}
                </p>
                {artist?.country}
                <br />
                ABN: {artist?.abn}
              </div>
            </div>
            <div
              className="flex flex-col justify-start text-sm font-light divide-y divide-black"
              style={{ fontFamily: "Modern Gothic Mono" }}
            >
              <span className="mb-2">From</span>
              <div className="flex flex-col pt-4">
                <p>
                  {companyDetail?.name}
                  <br />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: companyDetail?.streetAddress
                        ?.split("\n")
                        .join("<br />"),
                    }}
                  ></p>
                  <p>
                    {[
                      companyDetail?.suburb,
                      companyDetail?.state,
                      companyDetail?.postcode,
                    ]
                      .filter(el => el !== null)
                      .join(", ")}
                  </p>
                  {companyDetail?.fullLocationName}
                  <br />
                  {companyDetail?.addressCode
                    ? `${companyDetail?.addressCode}: ${companyDetail?.addressId}`
                    : ""}
                </p>
              </div>
            </div>
          </div>
          <div className="w-full mt-24" style={{ fontFamily: "Modern Gothic" }}>
            <p>
              <div dangerouslySetInnerHTML={{
                __html: headerDetails()?.split("\n").join("<br />"),
              }}/>
              <br />
              <div
                className="w-full"
                style={{ fontFamily: "Modern Gothic" }}
              >
                <div className="text-xl font-normal leading-6 text-black">
                  {projectScopes?.length > 0 && (
                    <>
                      <br />
                      <p className="font-bold text-2xl">PROJECT SCOPE</p>
                      <ul>
                        {projectScopes.map(scope => {
                          return (
                            <li key={scope.id} className="list-disc">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: scope.name?.split("\n")?.join("<br>"),
                                }}
                              ></div>
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  )}
                  {deliveryScopes?.length > 0 && (
                    <>
                      <br />
                      <p className="font-bold text-2xl">
                        DELIVERABLES AND SPECS
                      </p>
                      <ul>
                        {deliveryScopes.map(scope => {
                          return (
                            <li key={scope.id} className="list-disc">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: scope.name?.split("\n")?.join("<br>"),
                                }}
                              ></div>
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  )}
                  <br />
                  {revision.revisionToggleEnabled && (
                    <>
                      <p className="font-bold text-2xl">REVISIONS</p>
                      {revision.revisionRound !== 0 && (
                        <span>
                          This includes up to {revision.revisionRound} rounds.
                        </span>
                      )}{" "}
                      <br />
                      <br />
                    </>
                  )}
                  {!revision.jobPhaseEnabled && timingList?.length > 0 && (
                    <>
                      <p className="font-bold text-2xl">TIMINGS</p>
                      <ul>
                        {timingList.map(timing => {
                          return (
                            <li key={timing.id} className="list-disc">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: timing.note
                                    ?.split("\n")
                                    ?.join("<br>"),
                                }}
                              ></div>
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  )}
                  <br />
                </div>

                <p
                  dangerouslySetInnerHTML={{
                    __html: emailBackground.additionalHeaderDetails?.split("\n")?.join("<br />"),
                  }}
                ></p>
              </div>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;

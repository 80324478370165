import React, { useEffect } from "react";
import { numberWithCommas, sumAmount } from "common/helper";
import Scrollspy from "react-scrollspy";
import { BorderedTable } from "../../Shared";
import AOS from "aos";
import "aos/dist/aos.css";

const CostSummary = ({
  title,
  desc,
  lineItemCategories,
  parentLessItemCount,
  revisionItems,
  ammountWithCurrency,
  totalBeforeTax,
  asfFees,
  extra,
  usageEnabled,
  taxTypeId,
  taxType,
  taxAmount,
  totalCost,
}) => {
  useEffect(() => {
    AOS.init({
      once: false,
    });
    AOS.refresh();
  }, []);

  let samt = 0;
  window.addEventListener("scroll", function () {
    samt <= 10 ? samt++ : AOS.refresh();
  });

  const filteredData = [
    ...lineItemCategories.map(cat => {
      return { lineItemCategoryName: cat.lineItemCategoryName, id: cat.id };
    }),
    parentLessItemCount > 0
      ? { id: "estimateLineItem", lineItemCategoryName: "Others" }
      : null,
  ].filter(item => item !== null);

  const usageFee = revisionItems.find(item => item.feeType === "usage")?.total;

  return (
    <div className="flex flex-col items-start justify-between w-full py-12 lg:flex-row">
      <div
        className="flex flex-col items-start justify-start w-full h-full pr-0 lg:pr-12 lg:w-1/3"
        style={{ fontFamily: "Modern Gothic" }}
      >
        <div className="flex flex-col items-start justify-start mb-8 lg:mb-0">
          <h2 className="text-xl font-medium">{title}</h2>
          <p
            className="mt-6"
            dangerouslySetInnerHTML={{
              __html: desc?.split("\n").join("<br />"),
            }}
          ></p>
        </div>

        {lineItemCategories?.length > 0 && (
          <div className="flex-col items-start justify-start hidden w-full mt-16 lg:flex">
            <Scrollspy
              items={filteredData}
              currentClassName="jw-navbar-link--active"
              style={{ width: "100%" }}
              className="flex flex-col items-start justify-start w-full border-t border-b border-gray-400 divide-y divide-gray-400"
              offset={-300}
            >
              {filteredData.map(({ id, lineItemCategoryName }, index) => (
                <li key={index} className="w-full">
                  <a
                    href={`#${id}`}
                    data-to-scrollspy-id={`${id}`}
                    className="flex items-center justify-start w-full py-2 text-base font-normal no-underline cursor-pointer hover:text-black focus:text-black jw-navbar-link"
                  >
                    <span className="w-5">{index + 1}.</span>
                    <span className="ml-4">{lineItemCategoryName}</span>
                  </a>
                </li>
              ))}
            </Scrollspy>
          </div>
        )}
      </div>
      <div className="flex flex-col items-start justify-start w-full lg:w-2/3">
        <div
          className="flex flex-col items-start justify-start w-full space-y-2"
          style={{ fontFamily: "Modern Gothic" }}
        >
          <div className="relative flex flex-col items-start justify-start w-full h-full space-y-2">
            {filteredData.map(({ id, lineItemCategoryName }, index) => {
              let children = revisionItems.filter(
                item =>
                  (item.revisionLineItemCategoryId === id ||
                    (id === "estimateLineItem" &&
                      item.revisionLineItemCategoryId === null)) &&
                  item.feeType === "artwork"
              );
              let totalOfParent = sumAmount(children, "total");

              let lineItemTable = [
                {
                  option: {
                    hasCheckbox: false,
                    hasTitle: lineItemCategories?.length > 0,
                    title: lineItemCategoryName,
                    description: "",
                  },
                  table: {
                    heads: [
                      "Item",
                      "Notes",
                      "Qty",
                      "Metric",
                      "Unit Price",
                      "Cost",
                    ],
                    rows: children.map(item => {
                      return {
                        backgroundColor:
                          item.kind === "extra" ? "#FFFF00" : "transparent",
                        values: [
                          <>
                            <strong>{item.name}</strong> <br />
                          </>,
                          <>{item.note}</>,
                          <>
                            {numberWithCommas(Number(item.quantity).toFixed(2))}
                          </>,
                          <>{item.metric}</>,
                          <>
                            {item.rateTbc
                              ? "TBC"
                              : item.rateNa
                              ? "NA"
                              : ammountWithCurrency(item.customRate)}{" "}
                          </>,
                          <>
                            {item.rateTbc
                              ? "TBC"
                              : item.rateNa
                              ? "NA"
                              : ammountWithCurrency(item.total)}
                          </>,
                        ],
                      };
                    }),
                  },
                },
              ];

              return (
                <>
                  {children?.length > 0 && (
                    <div
                      data-aos="fade-down"
                      data-aos-easing="ease-in-out"
                      key={index + 1}
                      id={`${id}`}
                      className="flex flex-col w-full"
                    >
                      <BorderedTable
                        id={index + 1}
                        title={lineItemCategoryName}
                        data={lineItemTable}
                        bgColor="#FFEFFF"
                      />
                      {lineItemCategories?.length > 0 && (
                        <div
                          className="flex flex-col items-center justify-between w-full px-6 py-4 mt-2 space-y-2 font-medium leading-6 text-black lg:flex-row lg:space-y-0 rounded_container"
                          style={{
                            fontFamily: "Modern Gothic",
                            backgroundColor: "#FFC9FF",
                          }}
                        >
                          <span className="text-base lg:text-xl">
                            {lineItemCategoryName} Subtotal
                          </span>
                          <span className="text-xl">
                            {ammountWithCurrency(totalOfParent)}
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                </>
              );
            })}
          </div>
          {usageEnabled && (
            <div
              className="flex flex-col items-center justify-between w-full px-6 py-4 space-y-2 font-medium text-black lg:space-y-0 lg:flex-row rounded_container"
              style={{ backgroundColor: "#D6EEFF" }}
            >
              <span className="text-base lg:text-xl">Usage</span>
              <span className="text-xl">{ammountWithCurrency(usageFee)}</span>
            </div>
          )}

          <div
            className="flex flex-col items-center justify-between w-full px-6 py-4 space-y-2 font-medium text-black lg:space-y-0 lg:flex-row rounded_container"
            style={{ backgroundColor: "#EDE0D4" }}
          >
            <span className="text-base lg:text-xl">Agency Service Fee</span>
            <span className="text-xl">{ammountWithCurrency(asfFees())}</span>
          </div>

          {taxTypeId?.length > 0 && (
            <>
              <div
                className="flex flex-col items-center justify-between w-full px-6 py-4 space-y-2 font-medium text-black lg:space-y-0 lg:flex-row rounded_container"
                style={{ backgroundColor: "#D1D3D4" }}
              >
                <span className="text-base lg:text-xl">Subtotal</span>
                <span className="text-xl">
                  {ammountWithCurrency(totalBeforeTax())}
                </span>
              </div>

              <div
                className="flex flex-col items-center justify-between w-full px-6 py-4 space-y-2 font-medium text-black lg:space-y-0 lg:flex-row rounded_container"
                style={{ backgroundColor: "#D1D3D4" }}
              >
                <span className="text-base lg:text-xl">{taxType}</span>
                <span className="text-xl">
                  {ammountWithCurrency(taxAmount)}
                </span>
              </div>
            </>
          )}

          <div className="flex flex-col items-center justify-between w-full px-6 py-4 mt-2 space-y-2 font-medium text-white bg-black lg:space-y-0 lg:flex-row rounded_container">
            <span className="text-base lg:text-xl">
              {extra
                ? "Updated Total Investment"
                : "Previously Approved Total Investment"}
            </span>
            <span className="text-xl">{ammountWithCurrency(totalCost)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CostSummary;

import React, { useState, useEffect, useRef } from "react";
import { numberWithCommas } from "common/helper";
import { Warning } from "@bigbinary/neeto-icons";
import {
  Spinner,
  Button,
  Input,
  Label,
  Select,
  Typography,
  Callout,
  DatePicker,
  Switch,
  Alert,
  Modal,
} from "@bigbinary/neetoui";
import { Header, Container } from "@bigbinary/neetoui/layouts";
import { getInvoice, updateInvoice } from "apis/invoices/invoices";
import { getTaxTypes } from "apis/settings/tax_types";
import { pushInvoiceToXero, destroyXeroSession } from "apis/settings/xero";
import { useParams } from "react-router-dom";
import { showToastrError } from "common";
import { dropDownListGenerator } from "common/helper";
import { useUserState } from "contexts/user";
import * as R from "ramda";
import * as dayjs from "dayjs";
import BillableComponent from "./BillableComponent";
import EarlyBird from "./EarlyBird";
import LatePayment from "./LatePayment";
import {
  PAYMENT_TYPE_VALUE,
  COMMENCEMENT_PAYMENT_TYPE_VALUE,
} from "./constants";
import {
  totalInvoiceValueWithTax,
  taxAmount,
  filterAgencyItems,
  filterWithoutAgencyItems,
  filterExtraAgencyItems,
} from "./utils";

const InvoiceDetails = () => {
  const { id } = useParams();
  const { user } = useUserState();
  const inputRef = useRef(null);
  const [invoice, setInvoice] = useState();
  const [invoiceLoading, setInvoiceLoading] = useState(true);
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [clientJobNumber, setClientJobNumber] = useState();
  const [paymentType, setPaymentType] = useState();
  const [processedDate, setProcessedDate] = useState();
  const [issueDate, setIssueDate] = useState();
  const [purchaseOrder, setPurchaseOrder] = useState();
  const [paymentTerms, setPaymentTerms] = useState();
  const [note, setNote] = useState();
  const [earlyBirdFee, setEarlyBirdFee] = useState(false);
  const [lateFee, setLateFee] = useState();
  const [taxTypeId, setTaxTypeId] = useState();
  const [taxPercentage, setTaxPercentage] = useState();
  const [pushToXeroAlert, setPushToXeroAlert] = useState(false);
  const [xeroLogoutAlert, setXeroLogoutAlert] = useState(false);
  const [rciPercentage, setRciPercentage] = useState(0);
  const [rciPercentageModal, setRciPercentageModal] = useState(false);
  const [earlyBirdDiscountAlert, setEarlyBirdDiscountAlert] = useState(false);
  const [paymentTypeOptions, setPaymentTypeOptions] = useState(
    PAYMENT_TYPE_VALUE
  );
  const [taxTypes, setTaxTypes] = useState([]);
  const [taxTypeLoad, setTaxTypeLoad] = useState(true);

  const asfEstimateItem = filterAgencyItems(invoiceItems)[0];
  const extraAsfItem = filterExtraAgencyItems(invoiceItems)[0];
  const [submitBtnLoader, setSubmitBtnLoader] = useState(false);

  useEffect(() => {
    if (id) {
      loadInvoiceResponse();
      loadTaxTypes();
    }
  }, []);

  useEffect(() => {
    if (!invoice?.earlyBirdFee) {
      setPaymentTypeOptions([
        { label: "Standard", value: "standard" },
        { label: "Late Fee", value: "late_fee" },
      ]);
    } else {
      setPaymentTypeOptions(PAYMENT_TYPE_VALUE);
    }
  }, [invoice]);

  const updateInvoiceEntry = async () => {
    try {
      const payload = {
        invoice: {
          clientJobNumber: clientJobNumber,
          paymentType: paymentType,
          processedDate: processedDate,
          issueDate: issueDate,
          purchaseOrder: purchaseOrder,
          paymentTerms: paymentTerms,
          note: note,
          earlyBirdFee: earlyBirdFee,
          taxTypeId: taxTypeId,
          taxPercentage: taxPercentage,
          rciPercentage: rciPercentage,
        },
      };

      await updateInvoice(id, payload);
      loadInvoiceResponse();
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const updateEarlyBirdEntry = async earlyBird => {
    try {
      const payload = { invoice: { earlyBirdFee: earlyBird } };
      await updateInvoice(id, payload);
      setEarlyBirdDiscountAlert(false);
      setEarlyBirdFee(!earlyBirdFee);
      loadInvoiceResponse();
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const updateLateFeeEntry = async lateFee => {
    try {
      const payload = { invoice: { lateFee: lateFee } };
      await updateInvoice(id, payload);
      loadInvoiceResponse();
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const updatePaymentTypeEntry = async paymentType => {
    try {
      const payload = { invoice: { paymentType: paymentType } };
      await updateInvoice(id, payload);
      loadInvoiceResponse();
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const updateTaxTypeEntry = async taxTypeId => {
    try {
      const payload = { invoice: { taxTypeId: taxTypeId } };
      await updateInvoice(id, payload);
      loadInvoiceResponse();
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const updateTaxPercentageEntry = async taxPercentage => {
    try {
      const payload = { invoice: { taxPercentage: taxPercentage } };
      await updateInvoice(id, payload);
      loadInvoiceResponse();
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const updateProcessedDateEntry = async processedDate => {
    try {
      const payload = { invoice: { processedDate: processedDate } };
      await updateInvoice(id, payload);
      loadInvoiceResponse();
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const updateIssueDateEntry = async issueDate => {
    try {
      const payload = { invoice: { issueDate: issueDate } };
      await updateInvoice(id, payload);
      loadInvoiceResponse();
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const loadInvoiceResponse = async () => {
    try {
      const { data } = await getInvoice(id);
      let invoice = data.invoice;
      setInvoice(invoice);
      setInvoiceItems(invoice.invoiceItems);
      setClientJobNumber(invoice.clientJobNumber);
      setPaymentType(invoice.paymentType);
      setProcessedDate(invoice.processedDate);
      setIssueDate(invoice.issueDate);
      setPurchaseOrder(invoice.purchaseOrder);
      setPaymentTerms(invoice.paymentTerms);
      setNote(invoice.note);
      setEarlyBirdFee(invoice.earlyBirdFee);
      setTaxTypeId(invoice.taxTypeId);
      setTaxPercentage(invoice.taxPercentage);
      setRciPercentage(invoice.rciPercentage);
      setLateFee(invoice.lateFee);
      setInvoiceLoading(false);
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const loadTaxTypes = async () => {
    try {
      const { data } = await getTaxTypes();
      setTaxTypes(dropDownListGenerator(data.taxTypes));
      setTaxTypeLoad(false);
    } catch (error) {
      showToastrError(error);
    }
  };

  const actionBlockComponent = () => {
    return (
      <div className="flex items-center space-x-3">
        {invoice.xeroTokenExpired === false && (
          <>
            <Button
              style="secondary"
              label="Push to Xero"
              onClick={() => setPushToXeroAlert(true)}
            />

            {user.access !== "team" && (
              <Button
                style="secondary"
                label="Logout from Xero"
                onClick={() => setXeroLogoutAlert(true)}
              />
            )}
          </>
        )}
        <Button
          to={`/mailer/jobs/${invoice.jobId}/invoices/${invoice.id}`}
          label="Send Invoice"
        />
      </div>
    );
  };

  const xeroLogoutEntry = async () => {
    try {
      await destroyXeroSession();
      loadInvoiceResponse(id);
      setXeroLogoutAlert(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const pushToXeroEntry = async () => {
    try {
      setSubmitBtnLoader(true);
      await pushInvoiceToXero(id);
      loadInvoiceResponse(id);
      setPushToXeroAlert(false);
    } catch (error) {
      if (error.response.data.id) {
        setPushToXeroAlert(false);
      }
    } finally {
      setSubmitBtnLoader(false);
    }
  };

  if (invoiceLoading || taxTypeLoad) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <Container isHeaderFixed>
      <Header
        title={invoice.invoiceType}
        breadcrumbs={[
          { text: "Invoices", link: "/invoices" },
          {
            text: invoice.jobName,
            link: `/jobs/${invoice.jobId}/overview`,
          },
        ]}
        actionBlock={actionBlockComponent()}
      />

      {invoice.xeroIntegrated && (
        <div className="w-full px-6">
          <Callout style="warning" icon={Warning}>
            Successfully Pushed to Xero.
          </Callout>
        </div>
      )}

      <div
        className="flex items-start justify-center w-full px-6 py-6 overflow-auto"
        style={
          invoice.xeroIntegrated
            ? { height: "calc(100vh - 80px - 36px - 16px)" }
            : { height: "calc(100vh - 80px)" }
        }
      >
        <div className="w-full max-w-3xl xl:max-w-4xl">
          <Typography style="h3" weight="semibold" className="mb-6">
            Invoice Details
          </Typography>

          <div className="grid grid-cols-3 mb-12 gap-x-4 gap-y-6">
            <Input
              label="Invoice Number"
              disabled={true}
              name="serialNumber"
              value={invoice.serialNumber}
            />

            <Input
              label="Client Job Number"
              value={clientJobNumber}
              name="clientJobNumber"
              onBlur={() => updateInvoiceEntry()}
              onChange={e => setClientJobNumber(e.target.value)}
            />

            <Input
              label="Purchase Order"
              value={purchaseOrder}
              name="purchaseOrder"
              onBlur={() => updateInvoiceEntry()}
              onChange={e => setPurchaseOrder(e.target.value)}
            />

            <DatePicker
              label="Issue Date"
              name="issueDate"
              dateFormat="DD/MM/YYYY"
              value={dayjs(issueDate)}
              allowClear={false}
              onChange={date => updateIssueDateEntry(date.format("YYYY-MM-DD"))}
            />

            <Input
              label="Payment Terms"
              value={paymentTerms === 0 ? "Due Now" : paymentTerms}
              name="paymentTerms"
              onBlur={() => updateInvoiceEntry()}
              onChange={e => setPaymentTerms(e.target.value)}
            />

            <Input
              label="Due Date"
              disabled={true}
              name="dueDate"
              value={dayjs(invoice.dueDate).format("DD/MM/YYYY")}
            />

            <Input
              type="number"
              disabled={true}
              label={invoice.final ? "Final %" : "Commencement %"}
              name="percentage"
              value={invoice.percentage}
            />

            <Input
              className="col-span-2"
              label="Other Information"
              value={note}
              name="note"
              onBlur={() => updateInvoiceEntry()}
              onChange={e => setNote(e.target.value)}
            />
          </div>

          <Typography style="h3" weight="semibold" className="mb-6">
            Payments
          </Typography>

          <div className="grid items-end grid-cols-4 gap-x-4 gap-y-6">
            <Select
              options={
                invoice.final
                  ? paymentTypeOptions
                  : COMMENCEMENT_PAYMENT_TYPE_VALUE
              }
              isClearable={true}
              label="Payment Type"
              name="paymentType"
              onChange={option => {
                if (option) {
                  setPaymentType(option?.value);
                  updatePaymentTypeEntry(option.value);
                } else {
                  setPaymentType(null);
                  updatePaymentTypeEntry(null);
                }
              }}
              value={PAYMENT_TYPE_VALUE.find(
                type => type.value === paymentType
              )}
            />

            <DatePicker
              label="Processed Date"
              name="processedDate"
              dateFormat="DD/MM/YYYY"
              disabledDate={current =>
                current && current.valueOf() > dayjs().toDate()
              }
              value={processedDate ? dayjs(processedDate) : null}
              onChange={date => {
                if (date) {
                  updateProcessedDateEntry(date.format("YYYY-MM-DD"));
                } else {
                  updateProcessedDateEntry(null);
                }
              }}
            />

            {invoice.final && (
              <>
                <Switch
                  label="Early Bird Package"
                  checked={earlyBirdFee}
                  className="py-1.5"
                  onChange={() => {
                    if (!earlyBirdFee) {
                      setEarlyBirdDiscountAlert(true);
                    } else {
                      setEarlyBirdFee(!earlyBirdFee);
                      updateEarlyBirdEntry(!earlyBirdFee);
                    }
                  }}
                />

                <Switch
                  label="Late Fee"
                  checked={lateFee}
                  className="py-1.5"
                  onChange={() => {
                    setLateFee(!lateFee);
                    updateLateFeeEntry(!lateFee);
                  }}
                />
              </>
            )}
          </div>

          <div className="w-full mt-6 mb-12">
            <div className="w-full p-6 bg-gray-100 rounded-t-md">
              <div className="grid grid-cols-4 rounded-md">
                <div className="col-span-3"></div>
                <div className="col-span-1 pr-5 text-right">
                  <Typography weight="semibold" style="h4" className="mb-1">
                    Total
                  </Typography>
                </div>
              </div>
              {filterWithoutAgencyItems(invoiceItems).map((item, index) => {
                return (
                  <BillableComponent item={item} index={index} key={index} />
                );
              })}

              {!invoice.final && asfEstimateItem && (
                <div className="flex justify-between p-5 my-4 bg-white rounded-md">
                  <Label className="font-medium">Agency Service Fee</Label>
                  <Label className="pl-1 font-bold text-gray-800">
                    {numberWithCommas(
                      Number(asfEstimateItem.amount).toFixed(2)
                    )}
                  </Label>
                </div>
              )}

              {invoice.final && asfEstimateItem && (
                <div className="flex justify-between p-5 my-4 bg-white rounded-md">
                  <Label className="font-medium">Agency Service Fee</Label>
                  <Label className="pl-1 font-bold text-gray-800">
                    {numberWithCommas(
                      (
                        Number(asfEstimateItem.amount) +
                        Number(extraAsfItem?.amount || 0)
                      ).toFixed(2)
                    )}
                  </Label>
                </div>
              )}

              <div className="flex justify-between p-5 my-4 bg-white rounded-md">
                <Label className="font-medium">Approved Fees</Label>
                <Label className="pl-1 font-bold text-gray-800">
                  {numberWithCommas(Number(invoice.approvedFees).toFixed(2))}
                </Label>
              </div>

              {invoice.final && <hr />}

              {!invoice.final && (
                <div className="flex justify-between p-5 my-4 bg-white rounded-md">
                  <Label className="font-medium">
                    {invoice.percentage}% of Fees Due Now
                  </Label>
                  <Label className="pl-1 font-bold text-gray-800">
                    {numberWithCommas(Number(invoice.total).toFixed(2))}
                  </Label>
                </div>
              )}

              {invoice.final && (
                <div className="flex justify-between p-5 my-4 bg-white rounded-md">
                  <Label className="font-medium">Fees Already Invoiced</Label>
                  <Label className="pl-1 font-bold text-gray-800">
                    -{numberWithCommas(Number(invoice.paidAmount).toFixed(2))}
                  </Label>
                </div>
              )}

              {invoice.final && (
                <div className="flex justify-between p-5 my-4 bg-white rounded-md">
                  <Label className="font-medium">Subtotal</Label>
                  <Label className="pl-1 font-bold text-gray-800">
                    {numberWithCommas(Number(invoice.total).toFixed(2))}
                  </Label>
                </div>
              )}

              <div className="flex justify-between p-5 py-3 my-4 bg-white rounded-md">
                <div className="flex w-52">
                  <Label className="pr-2 font-medium">Tax: </Label>
                  <Select
                    isClearable={true}
                    className="w-56 mr-2"
                    options={taxTypes}
                    value={taxTypes.find(type => type.value === taxTypeId)}
                    onChange={opt => {
                      if (R.isNil(opt?.value)) {
                        setTaxTypeId("");
                        setTaxPercentage(0);
                        updateTaxTypeEntry("");
                        updateTaxPercentageEntry(0);
                      } else {
                        setTaxTypeId(opt.value);
                        updateTaxTypeEntry(opt.value);
                      }
                    }}
                  />

                  <Input
                    id="tax"
                    name="tax"
                    type="number"
                    className="w-36"
                    value={taxPercentage}
                    onChange={e => {
                      setTaxPercentage(e.target.value);
                    }}
                    onBlur={() => updateInvoiceEntry()}
                  />
                </div>

                <Label className="pl-1 font-bold text-gray-800">
                  {numberWithCommas(
                    Number(taxAmount(taxPercentage, invoice.total)).toFixed(2)
                  ) || 0.0}
                </Label>
              </div>
            </div>

            <div className="flex justify-end w-full px-6 py-3 bg-gray-200 rounded-b-md">
              <div className="flex flex-col flex-end">
                <div className="self-end pr-5 text-xl font-bold">
                  Total :{" "}
                  {numberWithCommas(
                    totalInvoiceValueWithTax(invoice.total, taxPercentage)
                  )}
                  &nbsp;
                  {invoice.currency}
                </div>

                <Label>
                  {invoice.final && (
                    <>
                      (If paid by{" "}
                      {dayjs(invoice.dueDate).format("MMMM DD, YYYY")})
                    </>
                  )}
                </Label>
              </div>
            </div>
          </div>

          {invoice.earlyBirdFee && (
            <EarlyBird
              earlyBirdDueDate={invoice.earlyBirdDueDate}
              taxPercentage={invoice.taxPercentage}
              subtotal={invoice.total}
              currency={invoice.currency}
              taxTypeName={invoice.taxTypeName}
              setRciPercentageModal={setRciPercentageModal}
              earlyBirdFeePercentage={invoice.earlyBirdFeePercentage}
            />
          )}

          {invoice.final && invoice.lateFee && (
            <LatePayment
              subtotal={invoice.total}
              taxPercentage={invoice.taxPercentage}
              currency={invoice.currency}
              taxTypeName={invoice.taxTypeName}
              dueDate={invoice.dueDate}
              lateFeePercentage={invoice.lateFeePercentage}
            />
          )}
        </div>
      </div>

      <Alert
        isOpen={pushToXeroAlert}
        title="Push to Xero"
        message="Are you sure you want to push this invoice to Xero?"
        onClose={() => setPushToXeroAlert(false)}
        onSubmit={() => pushToXeroEntry()}
        submitButtonLabel="Yes, Push to Xero"
        cancelButtonLabel="No, Cancel"
        isSubmitting={submitBtnLoader}
      />

      <Alert
        isOpen={xeroLogoutAlert}
        title="Logout"
        message="Are you sure you want to logout from Xero?"
        onClose={() => setXeroLogoutAlert(false)}
        onSubmit={() => xeroLogoutEntry()}
        submitButtonLabel="Yes, Logout"
        cancelButtonLabel="No, Cancel"
      />

      <Alert
        isOpen={earlyBirdDiscountAlert}
        title="Early Bird Discount"
        message="Are you sure you want to apply early bird discount?"
        onClose={() => setEarlyBirdDiscountAlert(false)}
        onSubmit={() => updateEarlyBirdEntry(!earlyBirdFee)}
        submitButtonLabel="Yes, Apply"
        cancelButtonLabel="No, Cancel"
      />

      <Modal
        isOpen={rciPercentageModal}
        onClose={() => setRciPercentageModal(false)}
        initialFocusRef={inputRef}
      >
        <Modal.Header>
          <Typography style="h2" weight="semibold">
            Add RCI Percentage
          </Typography>
        </Modal.Header>
        <Modal.Body>
          <Input
            label="RCI Percentage"
            name="rciPercentage"
            type="number"
            ref={inputRef}
            value={rciPercentage}
            onChange={e => {
              setRciPercentage(e.target.value);
            }}
          />
        </Modal.Body>
        <Modal.Footer className="space-x-2">
          <Button
            label="Save Changes"
            onClick={() => {
              updateInvoiceEntry();
              setRciPercentageModal(false);
            }}
          />
          <Button
            style="text"
            label="Cancel"
            onClick={() => setRciPercentageModal(false)}
          />
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default InvoiceDetails;

import axios from "axios";
import { DEFAULT_PAGE_SIZE } from "common/helper";

const getStaffsUrl = (id, searchParams, page, id_array) => {
  if (id) {
    return `/api/v1/staffs/staffs/${id}`;
  }

  if (searchParams || id_array) {
    let searchQuery = `q[first_name_i_cont_any]=${searchParams}&q[last_name_i_cont_any]=${searchParams}&q[full_name_i_cont_any]=${searchParams}&q[title_i_cont_any]=${searchParams}&q[m]=or&q[id_eq]=${searchParams}&q[id_array]=${id_array}`;
    return `/api/v1/staffs/staffs?${searchQuery}&page=${page}&per_page=${DEFAULT_PAGE_SIZE}`;
  }

  return `/api/v1/staffs/staffs?page=${page}&per_page=${DEFAULT_PAGE_SIZE}`;
};

export const getStaffs = (searchParams, page = 1, id_array) => {
  return axios.get(getStaffsUrl(null, searchParams, page, id_array));
};

export const getStaff = id => {
  return axios.get(getStaffsUrl(id));
};

export const createStaff = payload => {
  return axios.post(getStaffsUrl(), payload);
};

export const updateStaff = (id, payload) => {
  return axios.put(getStaffsUrl(id), payload);
};

export const destroyStaff = id => {
  return axios.delete(getStaffsUrl(id));
};

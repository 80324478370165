import React from "react";
import { Plus } from "@bigbinary/neeto-icons";
import { Button, Typography, Tag } from "@bigbinary/neetoui";
import * as dayjs from "dayjs";
import classNames from "classnames";

const Messages = ({
  setNewMessage,
  messageThreads,
  activeThread,
  setActiveThred,
}) => {
  return (
    <>
      <div className="flex flex-row items-center justify-between px-6 py-5">
        <Typography style="h4" weight="semibold">
          Messages
        </Typography>
        <Button
          icon={Plus}
          style="secondary"
          tooltipProps={{
            content: "New Message",
            position: "bottom",
          }}
          onClick={() => setNewMessage(true)}
        />
      </div>
      <div className="flex flex-col flex-grow p-3 space-y-2 overflow-y-auto">
        {messageThreads.map((thread, index) => {
          return (
            <div
              key={index}
              className={classNames(
                "flex flex-col w-full p-4 rounded cursor-pointer",
                {
                  "border neeto-ui-bg-white neeto-ui-border-gray-300":
                    thread.id == activeThread,
                  "hover:neeto-ui-bg-gray-200": thread.id != activeThread,
                }
              )}
              onClick={() => setActiveThred(thread.id)}
            >
              <div className="flex items-center justify-between w-full mb-1">
                <Typography
                  style="h5"
                  weight="medium"
                  className="line-clamp-1"
                >
                  {thread.subject}
                </Typography>
                <Typography
                  style="body3"
                  weight="normal"
                  className="neeto-ui-text-gray-600 ml-2"
                >
                  {dayjs(thread.lastMessagedAt).format("DD/MM/YY")}
                </Typography>
              </div>
              <div className="flex items-start justify-between space-x-4">
                <Typography
                  style="body3"
                  weight="normal"
                  className="neeto-ui-text-gray-700 line-clamp-1"
                >
                  {thread.description}
                </Typography>

                <div className="flex">
                  {thread.draft && (
                    <Tag label="Draft" style="info" type="outline" />
                  )}

                  {thread.unseenMessageCount > 0 && (
                    <div
                      className="flex items-center justify-center w-4 h-4 px-1 rounded-full ml-2"
                      style={{
                        background: "#ef4444",
                        minWidth: "16px",
                        color: "#fff",
                      }}
                    >
                      <Typography
                        style="nano"
                        weight="normal"
                        className={classNames("leading-4")}
                      >
                        {thread.unseenMessageCount}
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Messages;

import React, { useState } from "react";
import { Delete } from "@bigbinary/neeto-icons";
import { Modal, Button } from "@bigbinary/neetoui";
import { splitLastOccurrence } from "common/helper";

const Attachment = ({
  index,
  attachment,
  setDeleteConfirmAlert,
  setDeletingAttachmentIndex,
}) => {
  const [attachmentModal, setAttachmentModal] = useState(false);
  const [fileName, fileExtension] = splitLastOccurrence(
    attachment.filename,
    "."
  );

  const downloadAttachment = async () => {
    let link = document.createElement("a");
    link.href = attachment?.url || attachment?.file_data;
    link.download = attachment.filename;
    link.click();
  };

  const getFileName = () => {
    if (fileName.length > 30) {
      return `${fileName.substring(0, 20)}...${fileExtension}`;
    } else {
      return attachment.filename;
    }
  };

  return (
    <>
      <div className="flex justify-between items-center transition-all duration-300 ease-in-out border rounded-md cursor-pointer neeto-ui-border-gray-200 hover:neeto-ui-shadow-sm hover:neeto-ui-border-gray-500 px-3 py-1 mb-2">
        <Button
          style="link"
          size="small"
          className="w-5/6 break-all mr-3"
          onClick={() =>
            ["pdf", "vcf"].includes(fileExtension)
              ? downloadAttachment()
              : setAttachmentModal(true)
          }
          label={getFileName()}
        />

        <Button
          icon={Delete}
          style="danger-text"
          size="small"
          onClick={() => {
            setDeletingAttachmentIndex(index);
            setDeleteConfirmAlert(true);
          }}
        />
      </div>

      <Modal
        isOpen={attachmentModal}
        onClose={() => setAttachmentModal(false)}
        size="fullScreen"
      >
        <Modal.Body className="flex justify-center h-screen p-0">
          <img
            src={attachment?.url || attachment?.file_data}
            className="object-contain h-screen w-screen"
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Attachment;

import React, { useEffect, useState, useRef } from "react";
import { MenuHorizontal } from "@bigbinary/neeto-icons";
import {
  Button,
  Table,
  Typography,
  Tag,
  Dropdown,
  Alert,
  Pane,
  Spinner,
} from "@bigbinary/neetoui";
import { Header, Container } from "@bigbinary/neetoui/layouts";
import * as R from "ramda";
import * as dayjs from "dayjs";
import { showToastrError } from "common";
import useDebounce from "common/debounce";
import { useHistory } from "react-router-dom";
import { humanize, getRandomNotFoundImage, artistModuleName, DEFAULT_PAGE_SIZE } from "common/helper";
import { getJobs, getClosedTagsInCurrentWeek, getContactsAddedInCurrentWeek } from "apis/my_jobs";
import TagListModal from "./TagListModal";
import EditJob from "./EditJob";
import NewJob from "../Jobs/NewJob";
import EmptyState from "components/Common/EmptyState";

const JobListing = () => {
  const history = useHistory()
  const initialFocusRef = useRef(null);
  const [searchParams, setSearchParams] = useState();
  const debouncedSearchTerm = useDebounce(searchParams, 500);
  const [jobId, setJobId] = useState();
  const [jobList, setJoblist] = useState([]);
  const [jobListLoading, setJobListLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [editPaneOpen, setEditPaneOpen] = useState(false);
  const [newJobPane, setNewJobPane] = useState(false);
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const [jobName, setJobName] = useState("");
  const [initialLoad, setInitialLoad] = useState(true);
  const [sortProps, setSortProps] = useState();
  const { Menu, MenuItem } = Dropdown;
  const [leadsAdded, setLeadsAdded] = useState();
  const [logClosed, setLogClosed] = useState();
  const [logClosedList, setLogClosedList] = useState([]);
  const [leadsAddedList, setLeadsAddedList] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [tagListModal, setTagListModal] = useState(false);
  const [listType, setListType] = useState("closed");
  const [emptyImage, setEmptyImage] = useState();

  useEffect(() => {
    loadJobListResponse();
    setEmptyImage(getRandomNotFoundImage());
  }, []);

  useEffect(() => {
    if (sortProps?.column && !initialLoad) {
      loadJobListResponse(false);
    }
  }, [sortProps]);

  useEffect(() => {
    if (pageIndex && !initialLoad) {
      loadJobListResponse();
    }
  }, [pageIndex]);

  useEffect(() => {
    if (debouncedSearchTerm != undefined) {
      loadJobListResponse();
    }
  }, [debouncedSearchTerm]);

  const loadJobListResponse = async () => {
    try {
      const response = await getJobs(
        searchParams,
        sortProps,
        pageIndex || 1,
        DEFAULT_PAGE_SIZE
      );

      setJoblist(response.data.jobs);
      setTotalRecords(response.data.totalRecords);
      setJobListLoading(false);
      setLeadsAdded(response.data.leadsAdded);
      setLogClosed(response.data.logClosed);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const getClosedTagsInCurrentWeekList = async () => {
    try {
      setDataLoaded(true);
      const response = await getClosedTagsInCurrentWeek();
      setLogClosedList(response.data.closedActionTags);
      setListType("closed");
      setDataLoaded(false);
      setTagListModal(true);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  }

  const getNewLeadesAddedInCurrentWeekList = async () => {
    try {
      setDataLoaded(true);
      const response = await getContactsAddedInCurrentWeek();
      setLeadsAddedList(response.data.contacts);
      setListType("opened");
      setDataLoaded(false);
      setTagListModal(true);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  }

  const getJobsStatusColor = status => {
    switch (status) {
      case "estimate":
        return "primary";
      case "in_progress":
        return "success";
      case "post":
        return "warning";
      case "inactive":
        return "info";
      case "purgatory":
        return "secondary";
      case "graveyard":
        return "danger";
      default:
        return "primary";
    }
  };

  const COLUMN_DATA = [
    {
      dataIndex: "notificationCount",
      key: "notificationCount",
      width: 70,
      render: (_, rowData) => {
        return(
          <>
            {rowData.notificationCount > 0 && (
              <Tag
                indicatorStyle="danger"
                label={`${rowData.notificationCount} unread`}
                style="secondary"
                onClick={() => history.push(`jobs/${rowData.id}/messages`)}
              />
            )}
          </>
        )
      }
    },
    {
      title: "Job No.",
      dataIndex: "serialNumber",
      key: "serialNumber",
      width: 120,
      fixed: "left",
      sorter: true,
      field: "serial",
      render: serialNumber => <>{serialNumber}</>,
    },
    {
      title: "Title",
      dataIndex: "name",
      key: "name",
      width: 240,
      sorter: true,
      field: "name",
      fixed: "left",
      render: (_, rowData) => {
        return (
          <Button
            style="link"
            to={`/jobs/${rowData.id}/overview`}
            label={rowData.name}
          />
        );
      },
    },
    {
      title: "Organisation",
      dataIndex: "organisationName",
      key: "organisationName",
      field: "organisation_name",
      width: 240,
      render: (_, rowData) => {
        return (
          <Button
            style="link"
            to={`/organisations/${rowData.organisationId}`}
            label={rowData.organisationName}
          />
        );
      },
    },

    {
      title: artistModuleName(),
      dataIndex: "artists",
      key: "artists",
      field: "artists_full_name",
      width: 192,
      render: (_, rowData) => {
        return (
          <Dropdown
            autoWidth
            closeOnSelect
            trigger="hover"
            position="top"
            customTarget={
              <p className="w-48 truncate">
                {rowData.artists.map((artist, index) => {
                  return (
                    <>
                      {artist.name}
                      {index !== rowData.artists.length - 1 ? ", " : ""}
                    </>
                  );
                })}
              </p>
            }
          >
            <Menu>
              {rowData.artists.map(artist => {
                return (
                  <MenuItem.Button
                    className="no-underline"
                    key={artist.id}
                    to={`/${artistModuleName().toLowerCase()}/${artist.id}`}
                  >
                    {artist.name}
                  </MenuItem.Button>
                );
              })}
            </Menu>
          </Dropdown>
        );
      },
    },
    {
      title: "Enquired On",
      dataIndex: "enquiryDate",
      key: "enquiryDate",
      sorter: true,
      field: "enquiry_date",
      width: 130,
      render: enquiryDate => dayjs(enquiryDate).format("DD/MM/YYYY"),
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: true,
      field: "status",
      key: "status",
      width: 130,
      render: status => {
        return (
          <Tag
            type="solid"
            style={getJobsStatusColor(status)}
            label={humanize(status)}
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 75,
      fixed: "right",
      render: (_, rowData) => (
        <Dropdown
          autoWidth
          closeOnSelect
          buttonStyle="text"
          strategy="fixed"
          appendTo={() => document.body}
          icon={MenuHorizontal}
        >
          <Menu>
            <MenuItem.Button
              onClick={() => {
                setEditPaneOpen(true);
                setJobId(rowData.id);
                setJobName(rowData.name);
              }}
            >
              Info
            </MenuItem.Button>
            <MenuItem.Button
              className="no-underline"
              onClick={() => {
                setJobId(rowData.id);
                setNewJobPane(true);
              }}
            >
              Edit
            </MenuItem.Button>
            <MenuItem.Button
              style="danger"
              onClick={() => setIsDeleteAlertOpen(true)}
            >
              Delete
            </MenuItem.Button>
          </Menu>
        </Dropdown>
      ),
    },
  ];

  if (jobListLoading) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <Container>
      <Header
        title="My Jobs"
        searchProps={{
          value: searchParams,
          onChange: e => setSearchParams(e.target.value),
          clear: () => setSearchParams(""),
        }}
        actionBlock={
          <Button label="Add Job" onClick={() => setNewJobPane(true)} />
        }
      />
      <div className="grid w-full grid-cols-2 gap-4 mb-6">
        <div className="relative flex flex-col w-full p-4 transition-all bg-white border rounded-lg neeto-ui-border-gray-300 hover:neeto-ui-border-primary-500 hover:shadow group cursor-pointer" onClick={() => getClosedTagsInCurrentWeekList()}>
          <div className="flex items-center justify-between w-full space-x-3">
            <Typography style="h4" weight="semibold" className="text-gray-800 ">
              Action tags closed this week
            </Typography>
            <Typography style="h1" weight="semibold" className="text-gray-800 ">
              {logClosed}
            </Typography>
          </div>
        </div>
        <div className="relative flex flex-col w-full p-4 transition-all bg-white border rounded-lg neeto-ui-border-gray-300 hover:neeto-ui-border-primary-500 hover:shadow group cursor-pointer" onClick={() => getNewLeadesAddedInCurrentWeekList()}>
          <div className="flex items-center justify-between w-full space-x-3">
            <Typography style="h4" weight="semibold" className="text-gray-800 ">
              New leads added this week
            </Typography>
            <Typography style="h1" weight="semibold" className="text-gray-800 ">
              {leadsAdded}
            </Typography>
          </div>
        </div>
      </div>

      <div
        className="w-full"
        style={{
          height: "calc(100vh - 148px - 102px)", // 148px = header, 102px = Card UI
        }}
      >
        {!R.isEmpty(jobList) ? (
          <Table
            loading={jobListLoading}
            fixedHeight
            columnData={COLUMN_DATA}
            rowData={jobList}
            totalCount={totalRecords}
            currentPageNumber={pageIndex}
            defaultPageSize={pageSize}
            handlePageChange={(page, pageSize) => {
              setInitialLoad(false);
              setPageIndex(page);
              setPageSize(pageSize);
            }}
            onChange={(pagination, filters, sorter) => {
              setInitialLoad(false);
              setSortProps(sorter);
            }}
          />
        ) : (
          <EmptyState
            image={emptyImage}
            title="No Jobs Found"
            description="We couldn’t find any jobs. Try creating one."
            primaryButtonProps={{
              label: "Add Job",
              onClick: () => setNewJobPane(true),
            }}
          />
        )}
      </div>

      <Pane isOpen={editPaneOpen} onClose={() => setEditPaneOpen(false)}>
        <Pane.Header>
          <Typography style="h2" weight="semibold">
            {jobName}
          </Typography>
        </Pane.Header>
        <EditJob
          jobId={jobId}
          setEditPane={setEditPaneOpen}
          setJobId={setJobId}
          loadJobListResponse={loadJobListResponse}
        />
      </Pane>

      <NewJob
        isOpen={newJobPane}
        jobId={jobId}
        onClose={() => setNewJobPane(false)}
        initialFocusRef={initialFocusRef}
      />

      <Alert
        isOpen={isDeleteAlertOpen}
        title="Delete Job"
        message="Are you sure you want to delete this job?"
        onClose={() => setIsDeleteAlertOpen(false)}
        onSubmit={() => {
          setIsDeleteAlertOpen(false);
        }}
        cancelButtonLabel="No, cancel"
        submitButtonLabel="Yes, delete"
      />

      <TagListModal
        list={listType === "closed" ? logClosedList : leadsAddedList}
        dataLoaded={dataLoaded}
        tagListModal={tagListModal}
        setTagListModal={setTagListModal}
        listType={listType}
      />
    </Container>
  );
};

export default JobListing;

import * as Yup from "yup";
import { MAX_STRING_LENGTH } from "common/constants";

export const KILL_FEE_VALIDATION_SCHEMA = Yup.object({
  name: Yup.string()
    .required("Name can't be empty.")
    .max(
      MAX_STRING_LENGTH,
      `Name must be at most ${MAX_STRING_LENGTH} characters.`
    ),
  percentage: Yup.number()
    .required("Percentage can't be empty.")
    .moreThan(0, "Percentage must be greater than 0."),
});

export const KILL_FEE_INITIAL_VALUE = {
  name: "",
  percentage: 0,
};

export const LOCATION_VALIDATION_SCHEMA = Yup.object({
  name: Yup.string()
    .required("Name can't be empty.")
    .max(
      MAX_STRING_LENGTH,
      `Name must be at most ${MAX_STRING_LENGTH} characters.`
    ),
  shortName: Yup.string()
    .required("Short name can't be empty.")
    .max(
      MAX_STRING_LENGTH,
      `Short name must be at most ${MAX_STRING_LENGTH} characters.`
    ),
  currencyId: Yup.string().required("Currency can't be empty."),
  tag: Yup.string().required("Xero tax format can't be empty."),
});

export const LOCATION_INITIAL_VALUE = {
  name: "",
  shortName: "",
  currencyId: "",
  tag: "",
};

export const TAX_TYPE_VALIDATION_SCHEMA = Yup.object({
  name: Yup.string()
    .required("Name can't be empty.")
    .max(
      MAX_STRING_LENGTH,
      `Name must be at most ${MAX_STRING_LENGTH} characters.`
    ),
  rate: Yup.number()
    .required("Rate can't be empty.")
    .moreThan(0, "Rate must be greater than 0."),
  currencyId: Yup.string().required("Currency can't be empty."),
});

export const TAX_TYPE_INITIAL_VALUE = {
  name: "",
  rate: 0,
  currencyId: "",
};

export const XERO_TAX_FORMATS = [
  { label: "AU", value: "AU" },
  { label: "UK", value: "UK" },
  { label: "USA", value: "USA" },
];

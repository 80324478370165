import axios from "axios";

const getTeamMembersUrl = (artistId, id) => {
  if (id) {
    return `/api/v1/artists/artists/${artistId}/team_members/${id}`;
  }

  return `/api/v1/artists/artists/${artistId}/team_members`;
};

export const getTeamMembers = artistId => {
  return axios.get(getTeamMembersUrl(artistId));
};

export const getTeamMember = (artistId, id) => {
  return axios.get(getTeamMembersUrl(artistId, id));
};

export const createTeamMember = (artistId, payload) => {
  return axios.post(getTeamMembersUrl(artistId), payload);
};

export const updateTeamMember = (artistId, id, payload) => {
  return axios.put(getTeamMembersUrl(artistId, id), payload);
};

export const destroyTeamMember = (artistId, id) => {
  return axios.delete(getTeamMembersUrl(artistId, id));
};

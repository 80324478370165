import React, { useState, useEffect } from "react";
import { Tab } from "@bigbinary/neetoui";
import { getRandomNotFoundImage } from "common/helper";
import { STAFF_DETAILS_TABS } from "../constants";
import EmptyState from "components/Common/EmptyState";
import Profile from "./Profile";
import Jobs from "./Jobs";
import Report from "./Report";

const renderTabs = (
  tabs,
  staffDetail,
  setAccessChanged,
  loadStaffDetailResponse,
  category,
  user
) => {
  switch (tabs.label) {
    case "Profile":
      return (
        <Profile
          staffDetail={staffDetail}
          setAccessChanged={setAccessChanged}
          loadStaffDetailResponse={loadStaffDetailResponse}
          category={category}
          user={user}
        />
      );
    case "Jobs":
      return <Jobs staffId={staffDetail.id} category={category} />;
    case "Report":
      return <Report staffDetail={staffDetail} />;
  }
};

const StaffDetails = ({
  staffDetail,
  setAccessChanged,
  loadStaffDetailResponse,
  category,
  user,
}) => {
  const [currentTab, setcurrentTab] = useState(STAFF_DETAILS_TABS.Profile);
  const [emptyImage, setEmptyImage] = useState();

  useEffect(() => {
    setEmptyImage(getRandomNotFoundImage())
  }, [])

  if (!staffDetail.id) {
    return (
      <div className="p-2 m-auto" style={{ height: "calc(100vh - 134px)" }}>
        <EmptyState
          image={emptyImage}
          title="Please click on any staff to access the details."
        />
      </div>
    );
  }

  return (
    <>
      <div className="w-full overflow-hidden">
        <div className="flex items-center">
          <Tab className="px-6 border-b">
            {Object.keys(STAFF_DETAILS_TABS).map((tab, index) => {
              const { label, icon } = STAFF_DETAILS_TABS[tab];
              return (
                <Tab.Item
                  icon={icon}
                  key={index}
                  className="px-3 py-4"
                  active={currentTab.label === label}
                  onClick={() => setcurrentTab(STAFF_DETAILS_TABS[tab])}
                >
                  {label}
                </Tab.Item>
              );
            })}
          </Tab>
        </div>

        {renderTabs(
          currentTab,
          staffDetail,
          setAccessChanged,
          loadStaffDetailResponse,
          category,
          user
        )}
      </div>
    </>
  );
};

export default StaffDetails;

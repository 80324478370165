import React, { useState, useEffect } from "react";
import { Container, Header } from "@bigbinary/neetoui/layouts";
import {
  Spinner,
  Button,
  Callout,
  Alert,
  Typography,
  Switch,
} from "@bigbinary/neetoui";
import { showToastrError } from "common";
import WindowOpener from "../../Common/WindowOpener";
import {
  getXeroConnections,
  updateXeroConnection,
} from "apis/settings/xero_connections";
import {
  newXeroSession,
  getXeroSession,
  createXeroSession,
  destroyXeroSession,
} from "apis/settings/xero";

const XeroSessions = ({ breadcrumbs }) => {
  const [xeroLogoutConfirm, setXeroLogoutConfirm] = useState(false);
  const [xeroSessionLoad, setXeroSessionLoad] = useState(true);
  const [xeroSessionPresence, setXeroSessionPresence] = useState(false);
  const [browserPopUpOpen, setBrowserPopUpOpen] = useState(false);
  const [browserPopUpUrl, setBrowserPopUpUrl] = useState();
  const [xeroConnections, setXeroConnections] = useState([]);

  useEffect(() => {
    getXeroSessionResponse();
  }, []);

  useEffect(() => {
    if (xeroSessionPresence) {
      getXeroConnectionsResponse();
    }
  }, [xeroSessionPresence]);

  const getXeroSessionResponse = async () => {
    try {
      setXeroSessionLoad(true);
      const { data } = await getXeroSession();
      setXeroSessionPresence(data.xeroSessionPresence);
      setXeroSessionLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const getXeroConnectionsResponse = async () => {
    try {
      const { data } = await getXeroConnections();
      setXeroConnections(data.xeroConnections);
      setXeroSessionLoad(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const handleConnectionSwitchClick = async (id, checked) => {
    if (checked) return;

    try {
      await updateXeroConnection(id);
      getXeroConnectionsResponse();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const handleNewXeroSession = async () => {
    try {
      const { data } = await newXeroSession();
      setBrowserPopUpUrl(data.url);
      setBrowserPopUpOpen(true);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const handleCreateXeroSession = async data => {
    try {
      setXeroSessionLoad(true);
      await createXeroSession({ code: data.code });
      setXeroSessionPresence(true);
    } catch (error) {
      showToastrError(error.data.errors[0]);
      setXeroSessionPresence(false);
    }
  };

  const handleDestroyXeroSession = async () => {
    try {
      await destroyXeroSession();
      setXeroLogoutConfirm(false);
      setXeroSessionPresence(false);
      setXeroConnections([]);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  if (xeroSessionLoad) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <Container>
      {browserPopUpOpen && (
        <WindowOpener
          url={browserPopUpUrl}
          bridge={data => handleCreateXeroSession(data)}
          handleOnClose={setBrowserPopUpOpen}
        />
      )}

      <Header
        title="Xero Account"
        breadcrumbs={breadcrumbs}
        actionBlock={
          <div className="flex items-center gap-x-3">
            {xeroSessionPresence ? (
              <Button
                style="danger"
                label="Logout from Xero"
                onClick={() => setXeroLogoutConfirm(true)}
              />
            ) : (
              <Button
                label="Login to Xero"
                onClick={() => handleNewXeroSession()}
              />
            )}
          </div>
        }
      />

      {xeroSessionPresence ? (
        <Callout style="info" className="w-full mb-3">
          Xero Logged in!
        </Callout>
      ) : (
        <Callout style="danger" className="w-full mb-3">
          Xero Logged out! Please login to continue.
        </Callout>
      )}

      {xeroConnections.length > 1 && (
        <Callout style="warning" className="w-full mb-3">
          Select any organisation to continue.
        </Callout>
      )}

      <div className="w-full mt-4">
        {xeroConnections.map(item => (
          <div
            className="flex items-center justify-between p-3 border outline-none neeto-ui-border-gray-300 neeto-ui-shadow-xs neeto-ui-rounded-lg mb-3"
            key={item.id}
          >
            <Typography style="body2">{item.tenantName}</Typography>

            <Switch
              checked={item.active}
              onChange={() => handleConnectionSwitchClick(item.id, item.active)}
            />
          </div>
        ))}
      </div>

      <Alert
        isOpen={xeroLogoutConfirm}
        title="Logout from Xero"
        message="Are you sure you want to logout from xero?"
        onClose={() => setXeroLogoutConfirm(false)}
        onSubmit={() => handleDestroyXeroSession()}
      />
    </Container>
  );
};

export default XeroSessions;

import React, { useState, useEffect } from "react";
import * as dayjs from "dayjs";
import { CopyToClipboard } from "react-copy-to-clipboard";
import * as R from "ramda";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { Search } from "@bigbinary/neeto-icons";
import {
  Spinner,
  Button,
  Input,
  Typography,
  Table,
  Pane,
  Switch,
  Alert,
  Tooltip
} from "@bigbinary/neetoui";
import { SubHeader } from "@bigbinary/neetoui/layouts";
import { showToastrError } from "common";
import { getRandomNotFoundImage } from "common/helper";
import { getContacts, createContact } from "apis/organisations/contacts";
import { updateContact } from "apis/contacts/contacts";
import { DEFAULT_PAGE_SIZE } from "common/constants";
import useDebounce from "common/debounce";
import EmptyState from "components/Common/EmptyState";
import { CONTACT_VALIDATION_SCHEMA, INITIAL_CONTACT_VALUE } from "./constants";
import AddContact from "../AddContact";

const Contacts = ({ organisationDetail }) => {
  const history = useHistory();
  const [searchParams, setSearchParams] = useState();
  const debouncedSearchTerm = useDebounce(searchParams, 500);
  const [contactList, setContactList] = useState([]);
  const [contactListLoading, setContactListLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState();
  const [sortProps, setSortProps] = useState();
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [addPane, setAddPane] = useState(false);
  const [copyEmail, setCopyEmail] = useState(false);
  const [initial, setInitial] = useState(true);
  const [additionalCrmAlert, setAdditionalCrmAlert] = useState(false);
  const [newContactId, setNewContactId] = useState("");
  const [emptyImage, setEmptyImage] = useState();

  const formik = useFormik({
    initialValues: INITIAL_CONTACT_VALUE,
    validationSchema: CONTACT_VALIDATION_SCHEMA,
    onSubmit: values => onContactCreate(values),
  });

  useEffect(() => {
    setEmptyImage(getRandomNotFoundImage())
  }, [])

  useEffect(() => {
    if (organisationDetail.id) {
      loadContactListResponse();
    }
  }, [organisationDetail.id]);

  useEffect(() => {
    if (sortProps && !initial) {
      loadContactListResponse();
    }
  }, [sortProps]);

  useEffect(() => {
    if (pageIndex && !initial) {
      loadContactListResponse();
    }
  }, [pageIndex]);

  useEffect(() => {
    if (debouncedSearchTerm != undefined) {
      loadContactListResponse(searchParams, {});
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (copyEmail) {
      toast.info("Copied to Clipboard!", {
        position: "bottom-left",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setCopyEmail(false);
    }
  }, [copyEmail]);

  const onContactCreate = async formValue => {
    try {
      const {
        firstName,
        lastName,
        pronouns,
        organisationContactId,
        showInCrm,
      } = formValue;
      let payload = {
        contact: {
          firstName: firstName,
          lastName: lastName,
          pronouns: pronouns,
          showInCrm: showInCrm,
          addedToCrmDate: showInCrm ? dayjs().format("YYYY-MM-DD") : null,
          organisation_contacts_attributes: [
            {
              ...formValue,
              id: organisationContactId,
              organisationId: organisationDetail.id,
            },
          ],
        },
      };
      const { data } = await createContact(organisationDetail.id, payload);
      setAddPane(false);
      loadContactListResponse();
      formik.resetForm();
      setNewContactId(data.id);

      if (showInCrm) {
        setAdditionalCrmAlert(true);
      }
    } catch (error) {
      showToastrError(error.data.errors);
    }
  };

  const loadContactListResponse = async () => {
    try {
      const response = await getContacts(
        organisationDetail.id,
        searchParams,
        sortProps,
        pageIndex || 1,
        DEFAULT_PAGE_SIZE
      );
      setContactList(response.data.contacts);
      setContactListLoading(false);
      setTotalRecords(response.data.totalRecords);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const onUpdate = async (contactId, showInCrm) => {
    try {
      let payload = {
        contact: {
          showInCrm: showInCrm,
          addedToCrmDate: showInCrm ? dayjs().format("YYYY-MM-DD") : null,
        },
      };
      setContactListLoading(true);
      await updateContact(contactId, payload);
      loadContactListResponse();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const handleTableChange = (_, __, sorter) => {
    setInitial(false);
    setSortProps(sorter);
  };

  const COLUMN_DATA = [
    {
      title: "Name",
      dataIndex: "full_name",
      key: "full_name",
      sorter: true,
      field: "full_name",
      width: 200,
      fixed: "left",
      render: (_, rowData) => {
        return (
          <Button
            style="link"
            to={`/people/${rowData.id}`}
            label={
              rowData.pronouns
                ? `${rowData.fullName}(${rowData.pronouns})`
                : rowData.fullName
            }
          />
        );
      },
    },
    {
      title: "Email",
      dataIndex: "copyEmail",
      key: "copyEmail",
      width: 200,
      className: "word-break",
      render: (_, rowData) => (
        <Tooltip
          content="Click to Copy"
          followCursor="horizontal"
          position="top"
        >
          <div onClick={e => e.stopPropagation()}>
            <CopyToClipboard
              text={rowData.email}
              onCopy={() => setCopyEmail(true)}
            >
            <span>{rowData.email}</span>
            </CopyToClipboard>
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      sorter: true,
      field: "active_organisation_contact_title",
      render: (_, rowData) => <>{rowData.title ? rowData.title : "N/A"}</>,
    },
    {
      title: "Phone No.",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (_, rowData) => (
        <>{rowData.phoneNumber ? rowData.phoneNumber : "N/A"}</>
      ),
    },
    {
      title: "Mobile No.",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
      render: (_, rowData) => (
        <>{rowData.mobileNumber ? rowData.mobileNumber : "N/A"}</>
      ),
    },
    {
      title: "Contacted Since",
      dataIndex: "lastAccessedAt",
      key: "lastAccessedAt",
      sorter: true,
      field: "last_accessed",
      render: (_, rowData) => (
        <>
          {rowData.lastAccessedAt
            ? dayjs().diff(dayjs(rowData.lastAccessedAt), "day") + " days"
            : "-"}
        </>
      ),
    },
    {
      title: "CRM",
      key: "showInCrm",
      render: (_, rowData) => (
        <>
          <Switch
            checked={rowData.showInCrm}
            onChange={() => {
              onUpdate(rowData.id, !rowData.showInCrm);
            }}
          />
        </>
      ),
    }
  ];

  if (!organisationDetail.id) {
    return (
      <div
        className="flex items-center justify-center p-2"
        style={{ height: "calc(100vh - 134px)" }}
      >
        <EmptyState
          image={emptyImage}
          title="Please click on any organisation to access the details."
        />
      </div>
    );
  }

  if (contactListLoading) {
    return (
      <div
        className="flex items-center justify-center w-full"
        style={{ height: "calc(100vh - 134px)" }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div
      className="flex flex-col overflow-hidden"
      style={{ height: "calc(100vh - 134px)" }}
    >
      <SubHeader
        className="px-6 pt-4"
        leftActionBlock={
          <Typography style="h4" component="h4" weight="semibold">
            {totalRecords > 1
              ? `${totalRecords} Contacts`
              : `${totalRecords} Contact`}
          </Typography>
        }
        rightActionBlock={
          <div className="flex justify-end space-x-3">
            <Input
              size="small"
              type="search"
              placeholder="Search"
              className="neeto-ui-header__search-input"
              prefix={<Search size={16} />}
              value={searchParams}
              onChange={e => setSearchParams(e.target.value)}
              clear={() => setSearchParams("")}
            />
            <Button
              size="small"
              label="Add Contact"
              className="px-3"
              onClick={() => {
                setAddPane(true);
                formik.resetForm();
              }}
            />
          </div>
        }
      />
      <div
        className="px-4"
        style={{ height: "calc(100vh - 280px)" }}
      >
        {!R.isEmpty(contactList) ? (
          <Table
            columnData={COLUMN_DATA}
            rowData={contactList}
            fixedHeight
            loading={contactListLoading}
            currentPageNumber={pageIndex}
            totalCount={totalRecords}
            defaultPageSize={pageSize}
            handlePageChange={(page, pageSize) => {
              setInitial(false);
              setPageIndex(page);
              setPageSize(pageSize);
            }}
            onChange={(pagination, filters, sorter) =>
              handleTableChange(pagination, filters, sorter)
            }
            scroll={{ x: 1300 }}
            paginationProps={{
              showSizeChanger: false,
            }}
          />
        ) : (
          <EmptyState image={emptyImage} title="No Contacts Found" />
        )}
      </div>

      <Pane
        title="Contact"
        isOpen={addPane}
        onClose={() => setAddPane(false)}
        cancelButtonProps={{
          onClick: () => setAddPane(false),
        }}
      >
        <Pane.Header>
          <Typography style="h2" weight="semibold">
            Add Contact
          </Typography>
        </Pane.Header>
        <Pane.Body>
          <AddContact formik={formik} organisationDetail={organisationDetail} />
        </Pane.Body>
        <Pane.Footer className="space-x-2">
          <Button label="Save Changes" onClick={() => formik.handleSubmit()} />
          <Button
            label="Cancel"
            style="text"
            onClick={() => setAddPane(false)}
          />
        </Pane.Footer>
      </Pane>

      <Alert
        isOpen={additionalCrmAlert}
        title="Additional CRM Information"
        message="Please add assigned producer to the contact and add activity logs or notes for the contact!"
        onClose={() => setAdditionalCrmAlert(false)}
        onSubmit={() => history.push(`/people/${newContactId}`)}
        submitButtonLabel="Sure!"
        cancelButtonLabel="Not Now"
      />
    </div>
  );
};

export default Contacts;

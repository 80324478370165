import produce from "immer";
import { showToastrError } from "common";
import { updateRevisionProjectScopePosition } from "apis/jobs/revision_project_scopes";
import { updateRevisionDeliveryScopePosition } from "apis/jobs/revision_delivery_scopes";

const revisionScopePositionUpdated = async (
  revisionId,
  id,
  type,
  difference,
  scope
) => {
  try {
    if (scope === "project") {
      await updateRevisionProjectScopePosition(
        revisionId,
        id,
        type,
        difference
      );
    } else if (scope === "delivery") {
      await updateRevisionDeliveryScopePosition(
        revisionId,
        id,
        type,
        difference
      );
    }
  } catch (error) {
    showToastrError(error.data.errors[0]);
  }
};

const placeDifference = result => {
  let sourceIndex = result.source.index;
  let destinationIndex = result.destination.index;
  let placeChangeType = "";
  let difference = 0;

  if (sourceIndex > destinationIndex) {
    placeChangeType = "up";
    difference = sourceIndex - destinationIndex;
  } else {
    placeChangeType = "down";
    difference = destinationIndex - sourceIndex;
  }

  return { placeChangeType: placeChangeType, difference: difference };
};

export const scopeReorderFunction = (
  revisionId,
  result,
  scope,
  setRevisionSpecificProjectScope,
  setRevisionSpecificDeliveryScope
) => {
  if (!result.destination) {
    return;
  }

  const { placeChangeType, difference } = placeDifference(result);

  if (scope === "project") {
    setRevisionSpecificProjectScope(
      produce(draft => {
        const [removed] = draft.splice(result.source.index, 1);
        draft.splice(result.destination.index, 0, removed);
      })
    );

    revisionScopePositionUpdated(
      revisionId,
      result.draggableId,
      placeChangeType,
      difference,
      "project"
    );
  } else if (scope === "delivery") {
    setRevisionSpecificDeliveryScope(
      produce(draft => {
        const [removed] = draft.splice(result.source.index, 1);
        draft.splice(result.destination.index, 0, removed);
      })
    );

    revisionScopePositionUpdated(
      revisionId,
      result.draggableId,
      placeChangeType,
      difference,
      "delivery"
    );
  }
};

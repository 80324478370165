import React from "react";

export const Logo = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 60 60"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M29.9993 0C13.3329 0 0 13.3343 0 30.0007C0 46.6671 13.3329 60 29.9993 60C46.6657 60 60 46.6671 60 30.0007C60 13.3343 46.6671 0 29.9993 0ZM29.9993 50.0007C27.5964 50.0019 25.2132 49.5671 22.9655 48.7175C22.4621 50.937 21.1225 51.9763 18.3332 51.6675V48.3339C19.278 48.4622 19.7885 48.1577 20 47.3073C15.6283 44.7893 12.3721 40.7095 10.8861 35.8883C9.40018 31.0671 9.79472 25.862 11.9904 21.3199L10.0007 18.3332H13.7757C15.6258 15.758 18.0619 13.6597 20.8827 12.2114C23.7036 10.7631 26.8284 10.0063 29.9993 10.0035C35.2972 10.0035 40.416 12.1469 43.3336 15.599V19.8294C40.3568 16.022 35.6554 13.103 29.9993 13.103C20.7742 13.103 13.2186 20.7178 13.2186 30.0078C13.222 32.663 13.8491 35.2803 15.0493 37.6488C16.2494 40.0173 17.9892 42.0708 20.1283 43.6438V26.6671H23.2236V45.4502C25.3569 46.4101 27.6698 46.9061 30.0092 46.9055C38.6265 46.9055 46.3259 39.9394 46.6784 29.7624H43.2236C43.2236 37.0838 37.6296 44.2262 26.7955 43.3336V26.6671H29.8978V40.2383C31.1128 40.1932 32.3154 39.9762 33.4696 39.5939V26.6671H36.5649V37.9003C39.051 35.8584 40.6444 32.3232 40.1354 26.6671H49.8385C49.9552 27.7745 50.0145 28.8872 50.0162 30.0007C49.9993 41.6076 40.4766 50.0007 29.9993 50.0007ZM23.7496 22.08C23.7493 22.4918 23.6269 22.8944 23.3979 23.2367C23.1688 23.579 22.8435 23.8458 22.4629 24.0032C22.0823 24.1606 21.6635 24.2017 21.2596 24.1211C20.8557 24.0406 20.4847 23.8421 20.1935 23.5508C19.9024 23.2595 19.7042 22.8883 19.6239 22.4844C19.5437 22.0804 19.585 21.6617 19.7427 21.2812C19.9004 20.9007 20.1673 20.5755 20.5098 20.3467C20.8523 20.1179 21.2549 19.9958 21.6668 19.9958C21.9426 19.989 22.217 20.0384 22.4731 20.141C22.7293 20.2436 22.9618 20.3973 23.1567 20.5927C23.3515 20.7881 23.5046 21.021 23.6065 21.2775C23.7084 21.5339 23.7571 21.8084 23.7496 22.0842V22.08Z" />
  </svg>
);

export const PrintIcon = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 10H4V16H12V10Z" />
    <path d="M12 0H4V4H12V0Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 7.5V6H16V7.5V12H14V8H2V12H0V7.5Z"
    />
  </svg>
);

export const SaveIcon = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16 14.0671H0V16H16V14.0671Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0231 6.06711V0H5.97834V6.06711H1.93359L8.00071 12.1342L14.0678 6.06711H10.0231Z"
    />
  </svg>
);

export const CalendarIcon = (
  <svg
    width="85"
    height="103"
    viewBox="0 0 85 103"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1.5"
      y="9.5"
      width="82"
      height="92"
      fill="white"
      stroke="black"
      strokeWidth="3"
    />
    <rect
      x="14"
      y="2"
      width="7"
      height="13"
      rx="3.5"
      fill="white"
      stroke="black"
      strokeWidth="3"
    />
    <rect
      x="31"
      y="2"
      width="7"
      height="13"
      rx="3.5"
      fill="white"
      stroke="black"
      strokeWidth="3"
    />
    <rect
      x="48"
      y="2"
      width="7"
      height="13"
      rx="3.5"
      fill="white"
      stroke="black"
      strokeWidth="3"
    />
    <rect
      x="65"
      y="2"
      width="7"
      height="13"
      rx="3.5"
      fill="white"
      stroke="black"
      strokeWidth="3"
    />
  </svg>
);